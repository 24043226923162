import React, { useContext } from 'react';
import Guid from '../../services/Guid';
import { displayBackToManageComplaintsLink, displayBackToPortalLink } from '../../utils/AppUtils';
import AppContext from '../../stateManagement/context/AppContext';
import { WizardStepCurrentStateEnum } from '../../components/wizard/wizardEnums';
import { progressIcon } from '../../components/wizard/wizardMenuIcons';
import { WizardMenuItem } from '../../components/wizard/wizardMenuItem';
import styles from './site.module.scss';

const { desktopSiteMenu, editFormMenuNavBar, editFormMenuContainer, editFormMenuList, backToPortalLink, menuItemLink } = styles;

interface EditFormMenuContainerProps {
    menuItemDisplayName: string;
    showBackToManageComplaints?: boolean;
    backLinkUrl?: string;
    backLinkText?: string;
}

// Hosts the EditForm menu items shown for Medium/Large views
const EditFormMenuContainer = (props: EditFormMenuContainerProps) => {
    const { menuItemDisplayName, showBackToManageComplaints, backLinkUrl, backLinkText } = props;
    const appContext = useContext(AppContext);

    const displayMenuItems = () => {
        const iconElement = progressIcon(WizardStepCurrentStateEnum.initial);

        return (
            <li
                key={Guid.newGuid().toString()}
                className={`${menuItemLink} nav-item ${styles.active}`}
                data-testid='detailsMenuitem'
                role='menuitem'
                tabIndex={0}
            >
                <WizardMenuItem iconElement={iconElement} displayName={menuItemDisplayName} />
            </li>
        );
    };

    return (
        <>
            <div className={`${desktopSiteMenu}`}>
                <nav className={`${editFormMenuNavBar} navbar`} aria-label='FDRR edit menu' role='navigation'>
                    <div className={`${editFormMenuContainer}`} id='side-menu'>
                        <ul className={`${editFormMenuList} navbar-nav`} id='navbarNavDropdown' role='menubar'>
                            {showBackToManageComplaints === true
                                ? displayBackToManageComplaintsLink(backToPortalLink, appContext)
                                : displayBackToPortalLink(backToPortalLink, appContext, backLinkUrl, backLinkText)}
                            {displayMenuItems()}
                        </ul>
                    </div>
                </nav>
            </div>
        </>
    );
};

export default EditFormMenuContainer;
