import React from 'react';
import { confirmable, createConfirmation } from 'react-confirm';
import { Button, Modal } from 'react-bootstrap';
import style from './Controls.module.scss';
import { exclamationIcon } from '../utils/Icons';

const {
    modalContainer,
    modalContents,
    modalHeader,
    modalBody,
    modalBodyTextLeft,
    modalBodyTextCenter,
    modalYesButtonPrimary,
    modalYesButtonDestructive,
    modalNoButton,
    warningSignConfirmable,
} = style;

type ModalDialogConfirmableComponentProps = {
    okLabel: string;
    title: string;
    confirmation: string;
    confirmationP2?: string;
    confirmationP3?: string;
    modalBodyPositionTextLeft?: boolean;
    show: boolean;
    proceed: any; // called when ok button is clicked.
    enableEscape: boolean;
    cancelLabel: string;
    proceedLabel: string;
    dismiss: () => void;
    cancel: any;
    okOnly: boolean;
    isDestructive?: boolean;
    dataTestId?: string;
};

class ModalDialogConfirmableComponent extends React.Component<ModalDialogConfirmableComponentProps> {
    render() {
        const {
            proceedLabel,
            // dismiss,
            // cancel,
            cancelLabel,
            title,
            confirmation,
            confirmationP2,
            confirmationP3,
            modalBodyPositionTextLeft = true,
            show,
            proceed,
            okOnly,
            enableEscape = true,
            isDestructive = false,
            dataTestId,
        } = this.props;

        const okButtonOnly = () => {
            const handleClickOk = () => {
                proceed(true);
            };

            return (
                <>
                    <div className='col'>
                        <Button data-testid={`${dataTestId}_buttonOK`} className={`btn ${modalYesButtonPrimary}`} variant='primary' onClick={handleClickOk}>
                            {proceedLabel ? proceedLabel : 'OK'}
                        </Button>
                    </div>
                </>
            );
        };

        const yesNoButtons = () => {
            const yesButtonClass = isDestructive ? `btn ${modalYesButtonDestructive}` : `btn ${modalYesButtonPrimary}`;
            const yesButtonVariant = isDestructive ? 'danger' : 'primary';
            const handleClickNo = () => {
                proceed(false);
            };
            const handleClickYes = () => {
                proceed(true);
            };

            return (
                <>
                    <div className='col' style={{ maxWidth: '50%' }}>
                        <Button data-testid={`${dataTestId}_buttonNo`} className={`btn ${modalNoButton}`} variant='secondary' onClick={handleClickNo}>
                            {cancelLabel ? cancelLabel : 'No'}
                        </Button>
                    </div>
                    <div className='col' style={{ maxWidth: '50%' }}>
                        <Button data-testid={`${dataTestId}_buttonYes`} className={yesButtonClass} variant={yesButtonVariant} onClick={handleClickYes}>
                            {proceedLabel ? proceedLabel : 'Yes'}
                        </Button>
                    </div>
                </>
            );
        };

        const handleHide = () => {
            proceed(false);
        };

        return (
            <div className='static-modal'>
                <Modal
                    animation={false}
                    show={show}
                    // dismiss={dismiss}
                    onHide={handleHide}
                    backdrop={enableEscape ? true : 'static'}
                    keyboard={enableEscape}
                    className={modalContainer}
                    data-testid={dataTestId}
                >
                    <div className={`m-0 p-4 text-center ${modalContents}`}>
                        <Modal.Header closeButton={false} className={modalHeader}>
                            <Modal.Title>
                                {isDestructive && <div className={`${warningSignConfirmable} alert-danger rounded-circle`}>{exclamationIcon()}</div>}
                                {title}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className={`${modalBody} ${modalBodyPositionTextLeft ? modalBodyTextLeft : modalBodyTextCenter}`}>
                            <p>{confirmation}</p>
                            {confirmationP2 && <p>{confirmationP2}</p>}
                            {confirmationP3 && <p>{confirmationP3}</p>}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className='container'>
                                <div className='row'>{okOnly ? okButtonOnly() : yesNoButtons()}</div>
                            </div>
                        </Modal.Footer>
                    </div>
                </Modal>
            </div>
        );
    }
}

export function ModalDialogConfirmable(confirmation: string, proceedLabel = 'OK', cancelLabel = 'cancel', options = {}) {
    return createConfirmation(confirmable(ModalDialogConfirmableComponent))({
        confirmation,
        proceedLabel,
        cancelLabel,
        ...options,
    });
}
