import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError, endAjaxCall } from './ajaxStatusActions';
import ApplicationApi from '../api/applicationApi';
import AttachmentApi from '../api/attachmentApi';
import { IApplication, IApplicationSubmit, IComplaintMechanism, IDifferences, IOutlet, IReferenceData } from '../api/interfacesApi';
import { toastSuccessMessage } from './toastrMessages';
import AuthService from '../services/AuthService';
import { VALIDATION_DISCARD_OK_POPUP_MESSAGE, VALIDATION_SUCCESS_POPUP_MESSAGE } from '../services/Validation';
import Guid from '../services/Guid';
import {
    MODALHEADER_WITHDRAW_APPLICATION_REQUEST,
    MODALTEXT_WITHDRAW_APPLICATION_P2_REQUEST,
    MODALTEXT_WITHDRAW_APPLICATION_REQUEST,
    UNINITIALISED,
} from '../services/Constants';
import { AdditionalServiceDeliveryType, ApplicationStatusEnum, ClientGroupType, OtherServiceType, ScopeType, ServiceDeliveryType } from '../services/Enums';
import { formatDateUTC } from '../utils/Dates';
import { CopyFromActiveToComplaintCollection, IsValidBusinessAddress, IsValidOpeningHours, OutletExistsAndIsValid } from '../utils/ComplaintsUtils';
import { outletPractitionerExists } from '../utils/AppUtils';
import { ModalDialogConfirmable } from '../controls/ModalDialogConfirmable';

class ApplicationActions {
    applicationApi: ApplicationApi;
    attachmentApi: AttachmentApi;

    constructor(authService: AuthService) {
        this.applicationApi = new ApplicationApi(authService);
        this.attachmentApi = new AttachmentApi(authService);
    }

    GetApplicationSuccess(app: IApplication, doNotCopyActiveComplaint?: boolean, keepOriginalCMsAndNewCMsOnly?: boolean) {
        return { type: types.GET_APPLICATION_SUCCESS, application: app, doNotCopyActiveComplaint, keepOriginalCMsAndNewCMsOnly };
    }

    GetAllApplicationsSuccess(apps: IApplication[]) {
        return { type: types.GET_ALL_APPLICATIONS_SUCCESS, applications: apps };
    }

    CreateApplicationSuccess(app: IApplication) {
        return { type: types.CREATE_APPLICATION_SUCCESS, application: app };
    }

    UpdateApplicationSuccess(app: IApplication) {
        return { type: types.UPDATE_APPLICATION_SUCCESS, application: app };
    }

    SuspendApplicationSuccess(app: IApplication) {
        return { type: types.SUSPEND_APPLICATION_SUCCESS, application: app };
    }

    CancelApplicationSuccess(app: IApplication) {
        return { type: types.CANCEL_APPLICATION_SUCCESS, application: app };
    }

    DiscardApplicationSuccess(res: Response | string) {
        return { type: types.DISCARD_APPLICATION_SUCCESS };
    }

    SubmitApplicationSuccess(res: Response) {
        return { type: types.SUBMIT_APPLICATION_SUCCESS };
    }

    SubmitChangesSuccess(app: IApplicationSubmit) {
        return { type: types.SUBMIT_CHANGES_SUCCESS, applicationSubmit: app };
    }

    DeleteComplaintsDataSuccess() {
        return { type: types.DELETE_COMPLAINTSDATA_SUCCESS };
    }

    GetComplaintsMechanismSuccess(complaintsMechanism: IComplaintMechanism) {
        return { type: types.GET_COMPLAINTSMECHANISM_SUCCESS, complaintsMechanism };
    }

    GetComplaintsMechanismChangesSuccess(differences: IDifferences<IComplaintMechanism>) {
        return { type: types.GET_COMPLAINTSMECHANISM_CHANGES_SUCCESS, differences };
    }

    GetAllComplaintsMechanismChangesSuccess(differencesList: IDifferences<IComplaintMechanism>[]) {
        return { type: types.GET_ALL_COMPLAINTSMECHANISM_CHANGES_SUCCESS, differencesList };
    }

    UpdateComplaintsMechanismSuccess(complaintsMechanism: IComplaintMechanism) {
        return { type: types.UPDATE_COMPLAINTSMECHANISM_SUCCESS, complaintsMechanism };
    }

    AddComplaintsMechanismSuccess(complaintsMechanism: IComplaintMechanism) {
        return { type: types.ADD_COMPLAINTSMECHANISM_SUCCESS, complaintsMechanism };
    }

    RemoveOutletThenPublishSuccess(application: IApplication) {
        return { type: types.REMOVE_OUTLET_THENPUBLISH_SUCCESS, application };
    }

    RemoveComplaintsMechanismSuccess(complaintsMechanism: IComplaintMechanism) {
        return { type: types.REMOVE_COMPLAINTSMECHANISM_SUCCESS, complaintsMechanism };
    }

    DiscardChangesComplaintsMechanismSuccess(complaintsMechanism: IComplaintMechanism) {
        return { type: types.DISCARDCHANGES_COMPLAINTSMECHANISM_SUCCESS, complaintsMechanism };
    }

    GetApplication(doNotCopyActiveComplaint?: boolean, keepOriginalCMsAndNewCMsOnly?: boolean) {
        const useCurrentApplication = (dispatch: any) => {
            let runEndAjaxCall = false;

            dispatch(beginAjaxCall());

            this.applicationApi
                .getApplication()
                .then(app => {
                    // inject empty [] for attachments as we get them as required later but need [] for validation
                    if (app.attachments === undefined || app.attachments === null) {
                        app.attachments = [];

                        this.attachmentApi
                            .getAllAttachmentsMetadata()
                            .then((response: any) => {
                                app.attachments = response;
                                // wait for this second call to succeed then dispatch full app object
                                dispatch(this.GetApplicationSuccess(app, doNotCopyActiveComplaint, keepOriginalCMsAndNewCMsOnly));
                                return app;
                            })
                            .catch(error => {
                                dispatch(ajaxCallError(error));
                                return null;
                            })
                            .finally(() => {
                                runEndAjaxCall = true;
                            });
                    } else {
                        runEndAjaxCall = true;
                    }
                })
                .catch(error => {
                    dispatch(ajaxCallError(error));
                    return null;
                })
                .finally(() => {
                    // have to handle this as part of the extra attachments call
                    if (runEndAjaxCall === true) {
                        dispatch(endAjaxCall());
                    }
                });
        };
        return useCurrentApplication;
    }

    GetAllApplications() {
        const useCurrentApplication = (dispatch: any) => {
            dispatch(beginAjaxCall());

            this.applicationApi
                .getAllApplications()
                .then(apps => {
                    dispatch(this.GetAllApplicationsSuccess(apps));
                    return apps;
                })
                .catch(error => {
                    dispatch(ajaxCallError(error));
                    return null;
                })
                .finally(() => {
                    dispatch(endAjaxCall());
                });
        };
        return useCurrentApplication;
    }

    CreateApplication = (application: IApplication) => (dispatch: any) => {
        // Correct Dates for storage into UTC
        if (formatDateUTC(application.nmasExpiryDate)) {
            application.nmasExpiryDate = formatDateUTC(application.nmasExpiryDate)!;
        }

        dispatch(beginAjaxCall());
        this.applicationApi
            .CreateApplication(application)
            .then(returnApplication => {
                dispatch(this.CreateApplicationSuccess(returnApplication));
                toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);
                return returnApplication;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    pushRefDataToServiceDelivery = (serviceDelivery: IReferenceData[], id: string, desc: string, sortOrder: number) => {
        const newReferenceData: IReferenceData = { id, description: desc, sortOrder };
        serviceDelivery.push(newReferenceData);
    };

    pushRefDataToAdditionalServiceDelivery = (additionalServiceDelivery: IReferenceData[], id: string, desc: string, sortOrder: number) => {
        const newReferenceData: IReferenceData = { id, description: desc, sortOrder };
        additionalServiceDelivery.push(newReferenceData);
    };

    pushRefDataToOutletPractitionerScope = (outletPractitionerScope: IReferenceData[], id: string, desc: string, sortOrder: number) => {
        const newReferenceData: IReferenceData = { id, description: desc, sortOrder };
        outletPractitionerScope.push(newReferenceData);
    };

    pushRefDataToOutletPractitionerClientGroup = (outletPractitionerClientGroup: IReferenceData[], id: string, desc: string, sortOrder: number) => {
        const newReferenceData: IReferenceData = { id, description: desc, sortOrder };
        outletPractitionerClientGroup.push(newReferenceData);
    };

    pushRefDataToOutletPractitionerOtherService = (outletPractitionerOtherService: IReferenceData[], id: string, desc: string, sortOrder: number) => {
        const newReferenceData: IReferenceData = { id, description: desc, sortOrder };
        outletPractitionerOtherService.push(newReferenceData);
    };

    AddOutletFromOutletPractitionerDetails = (application: IApplication) => {
        if (application.outletPractitioner) {
            const guid = new Guid();

            // find the object if exists and remove then replace it with the newly updated one
            if (application.outletPractitioner.outletId !== '' && application.outlets && application.outlets.length > 0) {
                // go backwards through collection and knock the found record out if not initialised
                for (let idx = application.outlets.length - 1; idx >= 0; idx--) {
                    if (application.outlets[idx].outletId === UNINITIALISED || application.outlets[idx].outletId === guid.empty) {
                        // remove this record at index
                        application.complaintsMechanism.splice(idx, 1);
                    }
                }
            }

            // set the initial id to Guid.empty
            if (
                application.outletPractitioner.outletId === undefined ||
                application.outletPractitioner.outletId === null ||
                application.outletPractitioner.outletId === ''
            ) {
                application.outletPractitioner.outletId = guid.empty;
            }

            // Map outletPractitioner/serviceDelivery
            // MUST reset this collection as it is initially populated by API to UI and now might have changed
            application.outletPractitioner.serviceDelivery = [];
            if (application.outletPractitioner.isServiceDeliveryFaceToFace === true) {
                this.pushRefDataToServiceDelivery(application.outletPractitioner.serviceDelivery, ServiceDeliveryType.FaceToFace.toString(), 'Face-To-Face', 1);
            }
            if (application.outletPractitioner.isServiceDeliveryPhone === true) {
                this.pushRefDataToServiceDelivery(application.outletPractitioner.serviceDelivery, ServiceDeliveryType.Telephone.toString(), 'By Telephone', 2);
            }
            if (application.outletPractitioner.isServiceDeliveryOnline === true) {
                this.pushRefDataToServiceDelivery(application.outletPractitioner.serviceDelivery, ServiceDeliveryType.Online.toString(), 'Online', 4);
            }

            // if Face to Face is not set, then isRoomsToBeArranged is set to FALSE
            if (application.outletPractitioner.isRoomsToBeArranged === true && application.outletPractitioner.isServiceDeliveryFaceToFace !== true) {
                application.outletPractitioner.isRoomsToBeArranged = false;
            }
            // if Face to Face is set AND isRoomsToBeArranged is set to FALSE, then ensure roomExpense is cleared to NULL
            if (
                (application.outletPractitioner.isRoomsToBeArranged === null || application.outletPractitioner.isRoomsToBeArranged === false) &&
                application.outletPractitioner.isServiceDeliveryFaceToFace === true
            ) {
                application.outletPractitioner.roomExpense = null;
            }

            if (application.outletPractitioner.businessAddress !== null) {
                // set to null as its an OPTIONAL FIELD if "Face to Face" is not set
                if (application.outletPractitioner.isServiceDeliveryFaceToFace !== true) {
                    application.outletPractitioner.businessAddress = null;
                } else {
                    if (
                        application.outletPractitioner.businessAddress.addressId === UNINITIALISED ||
                        application.outletPractitioner.businessAddress.addressId === ''
                    ) {
                        application.outletPractitioner.businessAddress.addressId = guid.empty;
                    }
                }
            }

            // MUST reset this collection as it is initially populated by API to UI and now might have changed
            application.outletPractitioner.additionalServiceDelivery = [];
            if (application.outletPractitioner.isAdditionalComediation === true) {
                this.pushRefDataToAdditionalServiceDelivery(
                    application.outletPractitioner.additionalServiceDelivery,
                    AdditionalServiceDeliveryType.Comediation.toString(),
                    '',
                    1,
                );
            }
            if (application.outletPractitioner.isAdditionalGenderBalanced === true) {
                this.pushRefDataToAdditionalServiceDelivery(
                    application.outletPractitioner.additionalServiceDelivery,
                    AdditionalServiceDeliveryType.GenderBalanced.toString(),
                    '',
                    2,
                );
            }
            if (application.outletPractitioner.isAdditionalLegallyAssist === true) {
                this.pushRefDataToAdditionalServiceDelivery(
                    application.outletPractitioner.additionalServiceDelivery,
                    AdditionalServiceDeliveryType.LegallyAssisted.toString(),
                    '',
                    3,
                );
            }
            if (application.outletPractitioner.isAdditionalChildInclusive === true) {
                this.pushRefDataToAdditionalServiceDelivery(
                    application.outletPractitioner.additionalServiceDelivery,
                    AdditionalServiceDeliveryType.ChildInclusive.toString(),
                    '',
                    4,
                );
            }

            // MUST reset this collection as it is initially populated by API to UI and now might have changed
            application.outletPractitioner.scope = [];
            if (application.outletPractitioner.isScopeChildSupport === true) {
                this.pushRefDataToOutletPractitionerScope(application.outletPractitioner.scope, ScopeType.ChildSupport.toString(), '', 1);
            }
            if (application.outletPractitioner.isScopeProperty === true) {
                this.pushRefDataToOutletPractitionerScope(application.outletPractitioner.scope, ScopeType.Property.toString(), '', 2);
            }
            if (application.outletPractitioner.isScopeTheHague === true) {
                this.pushRefDataToOutletPractitionerScope(application.outletPractitioner.scope, ScopeType.TheHague.toString(), '', 3);
            }

            // MUST reset this collection as it is initially populated by API to UI and now might have changed
            application.outletPractitioner.clientGroup = [];
            if (application.outletPractitioner.isClientGroupLgbtqi === true) {
                this.pushRefDataToOutletPractitionerClientGroup(application.outletPractitioner.clientGroup, ClientGroupType.Lgbtqi.toString(), '', 1);
            }
            if (application.outletPractitioner.isClientGroupCarers === true) {
                this.pushRefDataToOutletPractitionerClientGroup(application.outletPractitioner.clientGroup, ClientGroupType.Carers.toString(), '', 2);
            }
            if (application.outletPractitioner.isClientGroupAboriginal === true) {
                this.pushRefDataToOutletPractitionerClientGroup(application.outletPractitioner.clientGroup, ClientGroupType.Aboriginal.toString(), '', 4);
            }
            if (application.outletPractitioner.isClientGroupTorresStrait === true) {
                this.pushRefDataToOutletPractitionerClientGroup(application.outletPractitioner.clientGroup, ClientGroupType.TorresStrait.toString(), '', 5);
            }
            if (application.outletPractitioner.isClientGroupCulturallyDiverse === true) {
                this.pushRefDataToOutletPractitionerClientGroup(
                    application.outletPractitioner.clientGroup,
                    ClientGroupType.CulturallyDiverse.toString(),
                    '',
                    6,
                );
            }

            // MUST reset this collection as it is initially populated by API to UI and now might have changed
            application.outletPractitioner.otherService = [];
            if (application.outletPractitioner.isOtherServicePractitioners === true) {
                this.pushRefDataToOutletPractitionerOtherService(application.outletPractitioner.otherService, OtherServiceType.Practitioner.toString(), '', 1);
            }
            if (application.outletPractitioner.isOtherServiceStudents === true) {
                this.pushRefDataToOutletPractitionerOtherService(application.outletPractitioner.otherService, OtherServiceType.Student.toString(), '', 2);
            }

            // mark nullable certain booleans and data
            if (application.outletPractitioner.isClientGroupCulturallyDiverse !== true) {
                application.outletPractitioner.isCultureGroupSpecific = null;
                application.outletPractitioner.isLanguageSpecific = null;
                application.outletPractitioner.isReligionSpecific = null;
                application.outletPractitioner.cultureGroupSpecificDetail = '';
                application.outletPractitioner.languageSpecificDetail = '';
                application.outletPractitioner.religionSpecificDetail = '';
            }

            // treat outlet opening hours collection with our supplied and possible DB ones edited or not
            if (
                application.outletPractitioner &&
                application.outletPractitioner.openingHours !== null &&
                application.outletPractitioner.openingHours.length > 0
            ) {
                // go backwards through collection and knock the found record out if not initialised and no data in it
                for (let idx = application.outletPractitioner.openingHours.length - 1; idx >= 0; idx--) {
                    const dataExists =
                        application.outletPractitioner.openingHours[idx].isOpen !== null &&
                        application.outletPractitioner.openingHours[idx].openTime !== null &&
                        application.outletPractitioner.openingHours[idx].closeTime !== null;

                    if (
                        application.outletPractitioner.openingHours[idx].outletId === '' ||
                        application.outletPractitioner.openingHours[idx].outletId === UNINITIALISED ||
                        application.outletPractitioner.openingHours[idx].openingHoursId === '' ||
                        application.outletPractitioner.openingHours[idx].openingHoursId === UNINITIALISED
                    ) {
                        if (!dataExists) {
                            // remove this record at index
                            application.outletPractitioner.openingHours.splice(idx, 1);
                        } else {
                            // data exists so we need to ensure Ids are set
                            if (
                                application.outletPractitioner.openingHours[idx].outletId === UNINITIALISED ||
                                application.outletPractitioner.openingHours[idx].outletId === ''
                            ) {
                                application.outletPractitioner.openingHours[idx].outletId = application.outletPractitioner.outletId;
                            }
                            if (
                                application.outletPractitioner.openingHours[idx].openingHoursId === UNINITIALISED ||
                                application.outletPractitioner.openingHours[idx].openingHoursId === ''
                            ) {
                                // new record so send up empty Guid
                                application.outletPractitioner.openingHours[idx].openingHoursId = guid.empty;
                            }
                        }
                    }
                }
            }

            // if exists then we remove that and place ours in, or just place in
            if (application.outlets && application.outlets.length > 0) {
                const foundItem = application.outlets.filter(item => item.outletId === application.outletPractitioner?.outletId);
                if (foundItem) {
                    // go backwards through collection and knock the found record out if not initialised
                    for (let idx = application.outlets.length - 1; idx >= 0; idx--) {
                        // remove this record at index
                        application.outlets.splice(idx, 1);
                    }
                }
            }

            application.outlets?.push(application.outletPractitioner);
        }
    };

    ModifyComplaintsMechanismForUpdate(complaintMechanism: IComplaintMechanism) {
        complaintMechanism.professionalIndemnityInsuranceFromDate =
            complaintMechanism.professionalIndemnityInsuranceFromDate && formatDateUTC(complaintMechanism.professionalIndemnityInsuranceFromDate);
        complaintMechanism.professionalIndemnityInsuranceToDate =
            complaintMechanism.professionalIndemnityInsuranceToDate && formatDateUTC(complaintMechanism.professionalIndemnityInsuranceToDate);
        complaintMechanism.membershipFromDate = complaintMechanism.membershipFromDate && formatDateUTC(complaintMechanism.membershipFromDate);
        complaintMechanism.membershipToDate = complaintMechanism.membershipToDate && formatDateUTC(complaintMechanism.membershipToDate);

        // if businessAddress or outlet hours are empty remove them
        if (OutletExistsAndIsValid(complaintMechanism) === true) {
            if (complaintMechanism.outlet && IsValidBusinessAddress(complaintMechanism.outlet.businessAddress) === false) {
                complaintMechanism.outlet.businessAddress = null; // remove address as it is not yet populated
            }
            if (complaintMechanism.outlet && complaintMechanism.outlet.openingHours) {
                for (let idxOH = complaintMechanism.outlet.openingHours.length - 1; idxOH >= 0; idxOH--) {
                    if (IsValidOpeningHours(complaintMechanism.outlet.openingHours[idxOH]) === false) {
                        complaintMechanism.outlet.openingHours.splice(idxOH, 1); // remove opening hours as it not yet populated
                    }
                }
            }
        } else {
            // remove outlet record
            complaintMechanism.outlet = null;
        }

        if (
            complaintMechanism.professionalAssociation &&
            (complaintMechanism.professionalAssociation.professionalAssociationId === null ||
                complaintMechanism.professionalAssociation.professionalAssociationId === '')
        ) {
            // remove profAssoc
            complaintMechanism.professionalAssociation = null;
        }

        return complaintMechanism;
    }

    ModifyApplicationForUpdate(application: IApplication) {
        // Correct Dates for storage into UTC
        if (formatDateUTC(application.nmasExpiryDate)) {
            application.nmasExpiryDate = formatDateUTC(application.nmasExpiryDate)!;
        }

        if (application.activeComplaintsMechanismObj && application.activeComplaintsMechanismObj.complaintsMechanismType !== null) {
            // MUST copy the active CM into the collection first before adjusting anything
            CopyFromActiveToComplaintCollection(application);
        }

        if (application.complaintsMechanism) {
            application.complaintsMechanism.forEach((complaint: IComplaintMechanism) => {
                complaint.membershipFromDate = formatDateUTC(complaint.membershipFromDate);
                complaint.membershipToDate = formatDateUTC(complaint.membershipToDate);
                complaint.professionalIndemnityInsuranceFromDate = formatDateUTC(complaint.professionalIndemnityInsuranceFromDate);
                complaint.professionalIndemnityInsuranceToDate = formatDateUTC(complaint.professionalIndemnityInsuranceToDate);
            });
        }

        // as we add a new complaints record if user has an attachment uploaded but no record to DB yet (due to browser crash etc)
        // we need to strip out any complaints where the outlet is not (yet) set
        const guid = new Guid();
        if (application.complaintsMechanism && application.complaintsMechanism.length > 0) {
            // go backwards through collection and knock the last record out if not initialised
            for (let idx = application.complaintsMechanism.length - 1; idx >= 0; idx--) {
                if (application.complaintsMechanism[idx].complaintsMechanismId === UNINITIALISED) {
                    // remove this record at index
                    application.complaintsMechanism.splice(idx, 1);
                } else {
                    // or newer records created using UI on the employee page could cause '', set a guid now
                    if (application.complaintsMechanism[idx].complaintsMechanismId === '') {
                        application.complaintsMechanism[idx].complaintsMechanismId = guid.empty;
                    }

                    // clear profAssoc if empty
                    if (
                        application.complaintsMechanism[idx].professionalAssociation !== null &&
                        (application.complaintsMechanism[idx].professionalAssociation?.professionalAssociationId === null ||
                            application.complaintsMechanism[idx].professionalAssociation?.professionalAssociationId === '')
                    ) {
                        application.complaintsMechanism[idx].professionalAssociation = null;
                    }
                }
            }
        }

        // if only pending we ensure clean outlets within complaints only, not the new outlet edits
        if (application.applicationStatus === ApplicationStatusEnum.Pending) {
            if (application.outletPractitioner !== null) {
                application.outletPractitioner = null;
            }
            if (application.complaintsMechanism && application.complaintsMechanism.length > 0) {
                // go backwards through collection and knock the found record out if not initialised
                for (let idx = application.complaintsMechanism.length - 1; idx >= 0; idx--) {
                    // confirm outlet is good
                    const complaintMechanism = application.complaintsMechanism[idx];
                    if (OutletExistsAndIsValid(complaintMechanism) === true) {
                        // if businessAddress or outlet hours are empty remove them
                        if (complaintMechanism.outlet && IsValidBusinessAddress(complaintMechanism.outlet?.businessAddress) === false) {
                            complaintMechanism.outlet!.businessAddress = null;
                        }
                        if (complaintMechanism.outlet && complaintMechanism.outlet.openingHours) {
                            for (let idxOH = complaintMechanism.outlet.openingHours.length - 1; idxOH >= 0; idxOH--) {
                                if (IsValidOpeningHours(complaintMechanism.outlet.openingHours[idxOH]) === false) {
                                    complaintMechanism.outlet.openingHours.splice(idxOH, 1); // remove opening hours as it not yet populated
                                }
                            }
                        }
                        /*
                        if (complaintMechanism.outlet && complaintMechanism.outlet?.openingHours && complaintMechanism.outlet!.openingHours!.length > 0) {
                            complaintMechanism.outlet!.openingHours = [];
                        } */
                    } else {
                        // remove outlet record
                        complaintMechanism.outlet = null;
                    }
                }
            }
        }

        // CM outlet final check (ie if a prof assoc member this will be empty)
        if (application.applicationStatus !== ApplicationStatusEnum.Pending) {
            if (application.complaintsMechanism && application.complaintsMechanism.length > 0) {
                // go backwards through collection and knock the found record out if not initialised
                for (let idx = application.complaintsMechanism.length - 1; idx >= 0; idx--) {
                    // confirm outlet is good
                    const complaintMechanism = application.complaintsMechanism[idx];
                    if (OutletExistsAndIsValid(complaintMechanism) === false) {
                        // remove outlet record
                        complaintMechanism.outlet = null;
                    } else if (complaintMechanism.outlet) {
                        // tidy up any business address or openingHours entries for a CM record (entirely possible only an Id/name)
                        if (IsValidBusinessAddress(complaintMechanism.outlet.businessAddress) === false) {
                            complaintMechanism.outlet.businessAddress = null;
                        }
                        if (complaintMechanism.outlet.openingHours && complaintMechanism.outlet.openingHours.length > 0) {
                            for (let indexOH = complaintMechanism.outlet.openingHours.length - 1; indexOH >= 0; indexOH--) {
                                if (IsValidOpeningHours(complaintMechanism.outlet.openingHours[indexOH]) === false) {
                                    complaintMechanism.outlet.openingHours.splice(indexOH, 1); // remove opening hours as it not yet populated
                                }
                            }
                        }
                    }
                }
            }
        }

        // bullet proof outlet collection for app
        if (application.outlets && application.outlets.length > 0) {
            application.outlets.forEach((outlet: IOutlet) => {
                if (IsValidBusinessAddress(outlet.businessAddress) === false) {
                    outlet.businessAddress = null;
                }
                if (outlet.openingHours && outlet.openingHours.length > 0) {
                    for (let indexOH = outlet.openingHours.length - 1; indexOH >= 0; indexOH--) {
                        if (IsValidOpeningHours(outlet.openingHours[indexOH]) === false) {
                            outlet.openingHours.splice(indexOH, 1); // remove opening hours as it not yet populated
                        }
                    }
                }
            });
        }

        return application;
    }

    ModifyApplicationForOutletUpdate(application: IApplication) {
        // we map the outletPractitioner into the collection of outlets as the object model supports 0..many IOutlets
        // if NOT a Pending (new) application
        if (application.applicationStatus !== ApplicationStatusEnum.Pending && outletPractitionerExists(application) === true) {
            this.AddOutletFromOutletPractitionerDetails(application);
        }

        return application;
    }

    UpdateApplication = (application: IApplication, updateOutlet: boolean) => (dispatch: any) => {
        dispatch(beginAjaxCall());

        // MUST place this AFTER the first dispatch() call otherwise the UI is mapping to the index of opening hours, for example, and leaving
        // us with one empty item in the collection mapped to the isOpen checkbox, so instead of 1 item OK we get 2 items, one containing ONLY isOpen true!
        application = this.ModifyApplicationForUpdate(application);

        // this isolates Outlet updates only
        if (updateOutlet === true) {
            application = this.ModifyApplicationForOutletUpdate(application);
        }

        this.applicationApi
            .UpdateApplication(application)
            .then(returnApplication => {
                dispatch(this.UpdateApplicationSuccess(returnApplication));
                toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);
                return returnApplication;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    SubmitApplication = (applicationSubmit: IApplicationSubmit) => (dispatch: any) => {
        dispatch(beginAjaxCall());
        this.applicationApi
            .SubmitApplication(applicationSubmit)
            .then(returnApplication => {
                dispatch(this.SubmitApplicationSuccess(returnApplication));
                return returnApplication;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    SubmitChanges = (applicationSubmit: IApplicationSubmit) => (dispatch: any) => {
        dispatch(beginAjaxCall());
        this.applicationApi
            .SubmitChanges(applicationSubmit)
            .then(returnApplication => {
                dispatch(this.SubmitChangesSuccess(returnApplication));
                return returnApplication;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    DiscardApplication = () => (dispatch: any) => {
        dispatch(beginAjaxCall());
        this.applicationApi
            .DiscardApplication()
            .then(response => {
                dispatch(this.DiscardApplicationSuccess(response));
                toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);
                return response;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    WithdrawApplication = (application: IApplication) => (dispatch: any) => {
        application = this.ModifyApplicationForUpdate(application);

        dispatch(beginAjaxCall());
        this.applicationApi
            .WithdrawApplication(application)
            .then(returnApplication => {
                dispatch(this.UpdateApplicationSuccess(returnApplication));
                toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);
                ModalDialogConfirmable(MODALTEXT_WITHDRAW_APPLICATION_REQUEST, 'OK', 'OK', {
                    title: MODALHEADER_WITHDRAW_APPLICATION_REQUEST,
                    confirmationP2: MODALTEXT_WITHDRAW_APPLICATION_P2_REQUEST,
                    okOnly: true,
                    dataTestId: 'withdrawApplication',
                });
                return returnApplication;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    SuspendApplication = (application: IApplication) => (dispatch: any) => {
        application = this.ModifyApplicationForUpdate(application);

        dispatch(beginAjaxCall());
        this.applicationApi
            .SuspendApplication(application)
            .then(returnApplication => {
                dispatch(this.SuspendApplicationSuccess(returnApplication));
                toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);
                return returnApplication;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    CancelApplication = (application: IApplication) => (dispatch: any) => {
        application = this.ModifyApplicationForUpdate(application);

        dispatch(beginAjaxCall());
        this.applicationApi
            .CancelApplication(application)
            .then(returnApplication => {
                dispatch(this.CancelApplicationSuccess(returnApplication));
                toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);
                return returnApplication;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    RemoveComplaintsData = () => (dispatch: any) => {
        dispatch(beginAjaxCall());
        this.applicationApi
            .DeleteComplaintsData()
            .then(returnApplication => {
                dispatch(this.DeleteComplaintsDataSuccess());
                return returnApplication;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    GetComplaintsMechanism = (complaintsMechId: string) => (dispatch: any) => {
        dispatch(beginAjaxCall());
        this.applicationApi
            .GetComplaintsMechanism(complaintsMechId)
            .then(returnComplaintsMechanism => {
                dispatch(this.GetComplaintsMechanismSuccess(returnComplaintsMechanism));
                return returnComplaintsMechanism;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    GetComplaintsMechanismChanges = (complaintsMechId: string) => {
        const useDifferences = (dispatch: any) => {
            dispatch(beginAjaxCall());
            this.applicationApi
                .GetComplaintsMechanismChanges(complaintsMechId)
                .then(differences => {
                    dispatch(this.GetComplaintsMechanismChangesSuccess(differences));
                    return differences;
                })
                .catch(error => {
                    dispatch(ajaxCallError(error));
                    return null;
                })
                .finally(() => {
                    dispatch(endAjaxCall());
                });
        };
        return useDifferences;
    };

    GetAllComplaintsMechanismChanges = (complaintsMechIds: string[]) => {
        const useDifferences = (dispatch: any) => {
            dispatch(beginAjaxCall());
            this.applicationApi
                .GetAllComplaintsMechanismChanges(complaintsMechIds)
                .then(differencesList => {
                    dispatch(this.GetAllComplaintsMechanismChangesSuccess(differencesList));
                    return differencesList;
                })
                .catch(error => {
                    dispatch(ajaxCallError(error));
                    return null;
                })
                .finally(() => {
                    dispatch(endAjaxCall());
                });
        };
        return useDifferences;
    };

    UpdateComplaintsMechanism = (complaintsMechanism: IComplaintMechanism) => (dispatch: any) => {
        dispatch(beginAjaxCall());

        // MUST place this AFTER the first dispatch() call otherwise the UI is mapping to the index of opening hours, for example, and leaving
        // us with one empty item in the collection mapped to the isOpen checkbox, so instead of 1 item OK we get 2 items, one containing ONLY isOpen true!
        complaintsMechanism = this.ModifyComplaintsMechanismForUpdate(complaintsMechanism);

        this.applicationApi
            .UpdateComplaintsMechanism(complaintsMechanism)
            .then(returnComplaintsMechanism => {
                dispatch(this.UpdateComplaintsMechanismSuccess(returnComplaintsMechanism));
                // do this within calling code: toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);
                return returnComplaintsMechanism;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    AddComplaintsMechanism = (complaintsMechanism: IComplaintMechanism) => (dispatch: any) => {
        dispatch(beginAjaxCall());

        // MUST place this AFTER the first dispatch() call otherwise the UI is mapping to the index of opening hours, for example, and leaving
        // us with one empty item in the collection mapped to the isOpen checkbox, so instead of 1 item OK we get 2 items, one containing ONLY isOpen true!
        complaintsMechanism = this.ModifyComplaintsMechanismForUpdate(complaintsMechanism);

        this.applicationApi
            .AddComplaintsMechanism(complaintsMechanism)
            .then(returnComplaintsMechanism => {
                dispatch(this.AddComplaintsMechanismSuccess(returnComplaintsMechanism));
                // do this within calling code: toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);
                return returnComplaintsMechanism;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    RemoveOutletThenPublish = (applicationId: string) => (dispatch: any) => {
        dispatch(beginAjaxCall());

        this.applicationApi
            .RemoveOutletThenPublish(applicationId)
            .then(retApplication => {
                dispatch(this.RemoveOutletThenPublishSuccess(retApplication));
                // do this within calling code: toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);
                return retApplication;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    RemoveComplaintsMechanism = (complaintsMechId: string) => (dispatch: any) => {
        dispatch(beginAjaxCall());

        this.applicationApi
            .RemoveComplaintsMechanism(complaintsMechId)
            .then(returnComplaintsMechanism => {
                dispatch(this.RemoveComplaintsMechanismSuccess(returnComplaintsMechanism));
                // do this within calling code: toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);
                return returnComplaintsMechanism;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    DiscardChangesComplaintsMechanism = (complaintsMechanism: IComplaintMechanism) => (dispatch: any) => {
        dispatch(beginAjaxCall());

        complaintsMechanism = this.ModifyComplaintsMechanismForUpdate(complaintsMechanism);

        this.applicationApi
            .DiscardComplaintsMechanism(complaintsMechanism)
            .then(returnComplaintsMechanism => {
                dispatch(this.DiscardChangesComplaintsMechanismSuccess(returnComplaintsMechanism));

                toastSuccessMessage(VALIDATION_DISCARD_OK_POPUP_MESSAGE);
                return returnComplaintsMechanism;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };
}

export default ApplicationActions;
