import React, { useState, useEffect } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import { DISPLAY_FDDR_PAU_CONTACT_EMAIL } from '../services/Constants';
import { chevronUpIcon, chevronDownIcon } from '../utils/Icons';
import { DisplayTextWithContactPauEmailLink } from '../utils/AppUtils';
import style from './Controls.module.scss';

const { helpTextContainer, expandableHelpButton, helpButtonContainer, helpContent } = style;

const getIcon = (isOpen: boolean) => {
    return isOpen ? chevronUpIcon() : chevronDownIcon();
};

interface HelpTextProps {
    helpHeaderText?: string;
    helpText?: string;
    fieldName?: string;
}

export const HelpText = (props: HelpTextProps) => {
    const { helpHeaderText, helpText, fieldName } = props;

    // const refButton = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const expandable = helpText !== null;
    const helpTextContainsPauEmail = helpText && helpText.includes(`${DISPLAY_FDDR_PAU_CONTACT_EMAIL}`);

    const chevronIcon = getIcon(isOpen);
    const id = fieldName ? `h_${fieldName}` : `h_${helpHeaderText!.substring(0, Math.max(helpHeaderText!.length, 10))}`;

    const stripButtonStyles = (buttonList: NodeListOf<HTMLButtonElement>) => {
        buttonList.forEach((button: HTMLButtonElement) => {
            // strip the class names from the button
            if (button!.classList.contains('btn')) {
                button!.classList.remove('btn');
            }
            if (button!.classList.contains('btn-primary')) {
                button!.classList.remove('btn-primary');
            }
        });
    };

    //  search by class as that is after render and finds and applies correctly where by id does not!
    const idSelector = "[class^='Controls_expandableHelpButton']"; // Controls_expandableHelpButton  [id^='someId'] will match all ids starting with someId.

    const buttonLoaded = () => {
        // works to find it but still does NOT render: "[id$='_expandableButton']"; // did not work! '#' + id.toString().replaceAll('.', '\\\\.') + '_expandableButton';
        const currButtonList: NodeListOf<HTMLButtonElement> | null = document.querySelectorAll(`${idSelector}`); // [id$='someId'] ending with
        // document.querySelectorAll(`.${expandableHelpButton}`); // refButton.current;
        if (currButtonList !== undefined && currButtonList !== null) {
            stripButtonStyles(currButtonList);
        }
    };

    useEffect(() => {
        buttonLoaded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!helpHeaderText) return null;

    // expandable help text button with content
    if (expandable) {
        const handleClick = () => {
            setIsOpen(!isOpen);
        };

        return (
            <div id={id} className={helpTextContainer}>
                <div className={helpButtonContainer}>
                    <Button
                        // ref={refButton!}
                        id={`${id}_expandableHelpButton`}
                        className={expandableHelpButton}
                        aria-controls={`${id}_helpText`}
                        onClick={handleClick}
                        aria-expanded={isOpen}
                    >
                        <div>
                            <span>{helpHeaderText}</span>
                            {chevronIcon}
                        </div>
                    </Button>
                </div>
                <Collapse className={helpContent} in={isOpen}>
                    <div id={`${id}_helpText`}>
                        {helpText && helpTextContainsPauEmail === true ? DisplayTextWithContactPauEmailLink(helpText, false) : helpText}
                    </div>
                </Collapse>
            </div>
        );
    }

    // simple help text only
    return (
        <div id={id} className={helpTextContainer}>
            <span>{helpHeaderText}</span>
        </div>
    );
};

export default HelpText;
