import React from 'react';
import NavLink from '../../../controls/NavLink';
import { checkCircleIcon } from '../../../utils/Icons';
import styles from '../../Pages.module.scss';

const { introductionPageList } = styles;

export const IntroductionContentArray = [
    { text: 'Working with Children Check(s) if applicable to your intended FDR service', link: '' },
    { text: 'A current National Police Check (not older than 4 months)', link: '' },
    {
        text: 'Appropriate competencies and qualifications',
        link: 'https://www.ag.gov.au/families-and-marriage/families/family-dispute-resolution/becoming-family-dispute-resolution-practitioner#qual',
    },
    {
        text: 'Suitable complaints mechanism(s) (i.e membership or employment evidence)',
        link: 'https://www.ag.gov.au/families-and-marriage/families/family-dispute-resolution#complaints',
    },
    { text: 'Professional indemnity insurance cover to provide FDR services (unless applying for conditional accreditation)', link: '' },
    { text: 'Official change of name documentation e.g marriage certificate (if any documents you upload are in different names)', link: '' },
];

export class IntroductionContent extends React.Component {
    render() {
        return (
            <>
                <h2>To complete this application you will need:</h2>
                <hr />
                <ul className={introductionPageList}>
                    <li>
                        {checkCircleIcon()}
                        <span>{IntroductionContentArray[0].text}</span>
                    </li>
                    <li>
                        {checkCircleIcon()}
                        <span>{IntroductionContentArray[1].text}</span>
                    </li>
                    <li>
                        {checkCircleIcon()}
                        <NavLink displayText={IntroductionContentArray[2].text} hrefLink={IntroductionContentArray[2].link} openInNewTab={true} />
                    </li>
                    <li>
                        {checkCircleIcon()}
                        <NavLink displayText={IntroductionContentArray[3].text} hrefLink={IntroductionContentArray[3].link} openInNewTab={true} />
                    </li>
                    <li>
                        {checkCircleIcon()}
                        <span>{IntroductionContentArray[4].text}</span>
                    </li>
                    <li>
                        {checkCircleIcon()}
                        <span>{IntroductionContentArray[5].text}</span>
                    </li>
                </ul>
            </>
        );
    }
}
