import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Inquiry } from '../../../api/interfacesApi';
import AppContext from '../../../stateManagement/context/AppContext';
import { DisplayQualification } from '../../../utils/EnumMappings';
import { AttachmentDocumentType, QualificationOrNMASDetailsType, WizardMode, WizardStepNumber } from '../../../services/Enums';
import { EMPTY_DATE, NOTE_NMAS_ACCREDITATION_MESSAGE } from '../../../services/Constants';
import NoteText from '../../../controls/NoteText';
import { EmptyRow } from '../../../controls/EmptyRow';
import LabelField from '../../../components/fields/LabelField';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import FileUploadDisplay from './fileUploadDisplay';
import { formatDateForDisplay } from 'utils/Dates';

interface CompetenciesDisplayProps {
    inquiry: Inquiry;
    formatForPDF: boolean;
}

const CompetenciesDisplay = (props: CompetenciesDisplayProps) => {
    const { inquiry, formatForPDF } = props;

    const appContext = useContext(AppContext);
    const showLinkToPage = formatForPDF === true ? false : appContext.wizardState.wizardMode === WizardMode.New ? true : false;
    const isNMASAccreditation = inquiry.application.qualificationOrNMASDetails === QualificationOrNMASDetailsType.CurrentNMASAccreditation ? true : false;
    const isVocGradDipOrQual = inquiry.application.qualificationOrNMASDetails === QualificationOrNMASDetailsType.AppropriateQualification ? true : false;

    return (
        <>
            <PageFieldsTitle
                title='Competencies and qualifications'
                showLinkToPage={showLinkToPage}
                wizardStepNumber={WizardStepNumber.CompetenciesAndQuals}
                smallerHeader={true}
                underlineTitle={formatForPDF}
            />
            <Row>
                <Col>
                    <LabelField
                        id='reviewHasPreviouslyApplied'
                        displayName='Your competencies/qualifications'
                        value={DisplayQualification(inquiry.application.qualification)}
                        isMandatory={false}
                        isTitleHtag={formatForPDF}
                    />
                </Col>
            </Row>
            {formatForPDF === true ? <EmptyRow /> : null}
            <FileUploadDisplay
                inquiry={inquiry}
                showThisDocType={AttachmentDocumentType.FullGraduateDiplomaOfFDR}
                title='Full Graduate Diploma (or equivalent) evidence'
                formatForPDF={formatForPDF}
            />
            <FileUploadDisplay
                inquiry={inquiry}
                showThisDocType={AttachmentDocumentType.SixUnits}
                title='Six core units (or equivalent) evidence'
                formatForPDF={formatForPDF}
            />
            <FileUploadDisplay
                inquiry={inquiry}
                showThisDocType={AttachmentDocumentType.ThreeUnits}
                title='Three specified units evidence'
                formatForPDF={formatForPDF}
            />
            {inquiry.application.nmasExpiryDate && inquiry.application.nmasExpiryDate !== EMPTY_DATE && (
                <>
                    <Row>
                        <Col>
                            <LabelField
                                id='reviewNMASExpiryDate'
                                displayName='NMAS Expiry Date'
                                value={formatDateForDisplay(inquiry.application.nmasExpiryDate)}
                                isMandatory={false}
                                isTitleHtag={formatForPDF}
                            />
                        </Col>
                    </Row>
                    {formatForPDF === true ? <EmptyRow /> : null}
                </>
            )}
            {isVocGradDipOrQual && (
                <FileUploadDisplay
                    inquiry={inquiry}
                    showThisDocType={AttachmentDocumentType.SixUnitsAndQualification}
                    title='Appropriate qualification evidence'
                    formatForPDF={formatForPDF}
                />
            )}
            {isNMASAccreditation && (
                <FileUploadDisplay
                    inquiry={inquiry}
                    showThisDocType={AttachmentDocumentType.SixUnitsAndNMAS}
                    title='NMAS evidence'
                    formatForPDF={formatForPDF}
                />
            )}
            {isNMASAccreditation && (
                <>
                    <Row>
                        <Col md='11'>
                            <NoteText displayText={NOTE_NMAS_ACCREDITATION_MESSAGE} formatForPDF={formatForPDF} />
                        </Col>
                        <Col md='1' className='justify-content-end' />
                    </Row>
                    <EmptyRow />
                </>
            )}
        </>
    );
};

export default CompetenciesDisplay;
