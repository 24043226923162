import { WizardStepCurrentStateEnum } from '../../../../components/wizard/wizardEnums';
import { WizardStepProps } from '../../../../components/wizard/wizardInterfaces';
import { WizardStateActionTypeEnums } from '../../../../components/wizard/wizardStateManager';
import { ScrollToTop } from '../../../PageFunctions';
import { WizardStepNumberComplaintsMech } from '../../../../services/Enums';
import { AppContextInterface } from '../../../../stateManagement/context/AppContext';

export function ShowOrHidePage(currentFormStep: number, proposedNewStep: number) {
    return currentFormStep === proposedNewStep ? 'd-block' : 'd-none';
}

export const RepaintWizard = (appContext: AppContextInterface) => {
    appContext.dispatchWizardState({ type: WizardStateActionTypeEnums.repaint });
};

export function UpdateWizardStepsAndRepaint(appContext: AppContextInterface, wizardSteps: WizardStepProps[]) {
    if (appContext && wizardSteps) {
        // store changes to Wizard Steps
        appContext.setWizardSteps(wizardSteps);

        // and repaint wizard immediately
        RepaintWizard(appContext);
    }
}

export const ActivateStep = (currIndex: number, activateIndex: number, wizardSteps: WizardStepProps[]) => {
    if (currIndex > -1) {
        wizardSteps[currIndex].isActive = false;

        if (activateIndex > -1) {
            wizardSteps[activateIndex].isActive = true;
        } else {
            // we have finished
        }
    }

    return wizardSteps;
};

// used when user resumes at a particular step
export const SetAllPriorStepsToComplete = (currIndex: number, wizardSteps: WizardStepProps[]) => {
    for (let idx = 0; idx < currIndex; idx++) {
        if (wizardSteps[idx] && wizardSteps[idx].stepCompleted === false) {
            wizardSteps[idx].stepCompleted = true;
            wizardSteps[idx].currentState = WizardStepCurrentStateEnum.completed;
            if (wizardSteps[idx].loadedState) {
                wizardSteps[idx].loadedState = WizardStepCurrentStateEnum.completed;
            }
        }
    }
    return wizardSteps;
};

export const SetAllFutureStepsIncomplete = (currIndex: number, wizardSteps: WizardStepProps[]) => {
    const maxIndex = WizardStepNumberComplaintsMech.ComplaintsMechanismInsurance;

    for (let idx = currIndex; idx <= maxIndex; idx++) {
        if (wizardSteps[idx] && wizardSteps[idx].stepCompleted === true) {
            wizardSteps[idx].stepCompleted = false;
            wizardSteps[idx].currentState = WizardStepCurrentStateEnum.initial;
            wizardSteps[idx].loadedState = WizardStepCurrentStateEnum.initial;
        }
    }
    return wizardSteps;
};

export const GetActiveWizardStepIndex = (wizardSteps: WizardStepProps[]) => {
    let index = 0;
    let node = wizardSteps[0];
    while (node) {
        if (node.isActive) return index;
        index += 1;
        node = wizardSteps[index];
    }
    // not found or nothing active
    return -1;
};

export const MarkThisStepCompleted = (goingForward: boolean, wizardStep: WizardStepProps, isValid?: boolean, isDirty?: boolean, keepChanges?: boolean) => {
    if (wizardStep.stepCompleted === true) return;

    if (goingForward) {
        // this flag is used to distinguish if user navigates into a page and backwards without completing it
        wizardStep.stepCompleted = true;
    } else {
        // if going backward we only set Flag to completed IF either:
        //  - the user has made a valid change and also got prompted and agreed to save changes
        // or
        //  - the user has made a change and got prompted and discarded the changes (where originally the step was completed), so we set it back to completed here
        const setStepToCompleted = isValid && isDirty && keepChanges; // || (isValid && isDirty && !keepChanges && wizardStep.stepCompleted === true);

        if (setStepToCompleted) {
            // this flag is used to distinguish if user navigates into a page and backwards without completing it
            wizardStep.stepCompleted = true;
        }
    }
};
// Check if form (Step) is valid AND if we have any children which affects whether this node is set to Completed or not
export const GetStepStateUponCompletion = (isValid: boolean, wizardSteps: WizardStepProps[], currIndex: number, keepChanges?: boolean) => {
    if (isValid) {
        // check if this node is Flag completed
        if (wizardSteps[currIndex].stepCompleted) {
            return WizardStepCurrentStateEnum.completed;
        }

        // user is going backwards, status remains the same
        if (!keepChanges) {
            // stored original state and revert back to that, for now just blank back to initial state
            return wizardSteps[currIndex].loadedState ?? WizardStepCurrentStateEnum.initial;
        }

        return wizardSteps[currIndex].currentState;
    } else {
        return WizardStepCurrentStateEnum.invalidError;
    }
};

const FindNextIncompleteStep = (wizardSteps: WizardStepProps[], currIndex: number) => {
    // find any steps not completed and not the current index
    let nextIncompleteStepIndex = -1;

    // tslint:disable-next-line: only-arrow-functions
    const list = wizardSteps.filter(function (e) {
        return e.stepCompleted === false && e.index !== currIndex;
    });
    if (list && list.length > 0) {
        nextIncompleteStepIndex = list[0].index;
    }
    return nextIncompleteStepIndex;
};

// can only move forward if the current index is an active node, and next is another node
export const IncrementAndActivateNextStep = (currIndex: number, wizardSteps: WizardStepProps[], isResubmitFlow: boolean = false) => {
    if (currIndex > -1) {
        wizardSteps[currIndex].isActive = false;

        let nextIndex = -1;
        if (isResubmitFlow === true) {
            nextIndex = FindNextIncompleteStep(wizardSteps, currIndex);
            // if no more we default to last step
            if (nextIndex === -1 && currIndex !== WizardStepNumberComplaintsMech.ComplaintsMechanismInsurance) {
                nextIndex = WizardStepNumberComplaintsMech.ComplaintsMechanismInsurance;
            }
        } else {
            nextIndex = wizardSteps[currIndex].nextIndex;
        }

        if (nextIndex > -1) {
            wizardSteps[nextIndex].isActive = true;
        } else {
            // we have finished
        }
    }

    return wizardSteps;
};

// first find the node that has my index as its nextIndex, starting from my node - 1 and working our way backwards
const getIndexOfActiveNodePointingToMe = (myIndex: number, wizardSteps: WizardStepProps[]) => {
    let indexPointingToMe = -1;

    for (let idx = myIndex - 1; idx >= 0; idx--) {
        if (wizardSteps[idx] && wizardSteps[idx].nextIndex === myIndex && wizardSteps[idx].isDisplayed === true) {
            indexPointingToMe = idx;
            break;
        }
    }

    return indexPointingToMe;
};

export const DecrementAndActivatePriorStep = (currIndex: number, wizardSteps: WizardStepProps[]) => {
    if (currIndex > -1) {
        // de-activate current step
        wizardSteps[currIndex].isActive = false;

        // disable this index // ALREADY DONE THIS: OR if user is clicking Back and Savings changes we do the currIndex-1 index instead
        const myIndex = currIndex; // keepChanges ? currIndex - 1 : currIndex;

        // find the prior wizardstep that has this index as its nextIndex value
        const idx = getIndexOfActiveNodePointingToMe(myIndex, wizardSteps);
        if (idx > -1) {
            wizardSteps[idx].isActive = true;
        }

        // if not changed, we have finished
    }

    return wizardSteps;
};

// If OK to move forward then move step and set wizardStep
export const MoveComplaintsWizardForward = (appContext: AppContextInterface, isValid: boolean) => {
    // adjust the wizard step by using the CurrentStep and moving forward once or twice (pending whether the item is displayed or not)
    const currIndex = GetActiveWizardStepIndex(appContext.wizardSteps);

    // setFormStep(cur => cur + numberOfSteps);
    // the numberOfSteps to move forward is determined by either we are at start or the item's nextIndex minus currIndex (could be 2 away)
    const numberOfSteps = currIndex > -1 ? appContext.wizardSteps[currIndex].nextIndex - currIndex : currIndex === 0 ? 1 : 0;
    appContext.dispatchWizardState({ type: WizardStateActionTypeEnums.incrementFormStep, numberOfSteps });

    if (currIndex > -1) {
        // Set the state AND Flag to completed ONLY if the page is Valid
        if (isValid) {
            MarkThisStepCompleted(true, appContext.wizardSteps[currIndex]);
        }

        appContext.wizardSteps[currIndex].currentState = GetStepStateUponCompletion(isValid, appContext.wizardSteps, currIndex);

        const newWizardSteps = IncrementAndActivateNextStep(currIndex, appContext.wizardSteps);
        // setApplicationForm(applicationForm); // store changes to Wizard Steps
        // store changes to Wizard Steps - as we hand them around for mobile/header view
        UpdateWizardStepsAndRepaint(appContext, newWizardSteps);
    }

    ScrollToTop();
};

export const MoveComplaintsWizardBackward = (appContext: AppContextInterface, isValid: boolean) => {
    // adjust the wizard step by using the CurrentStep and moving backward once
    const currIndex = GetActiveWizardStepIndex(appContext.wizardSteps);

    // the number of steps backward is either back to start or the prior item's nextIndex matches the currIndex and is displayed, or the one before that (up to 3 away)
    // upon back btn where user agrees to save changes, causes submit, so we go forward automatically.  In this case we need to go one/two more back (the 2-3 steps back!)
    const numberOfIndexStepsToGoBack = 1;

    appContext.dispatchWizardState({ type: WizardStateActionTypeEnums.decrementFormStep, numberOfSteps: numberOfIndexStepsToGoBack });

    if (currIndex > -1) {
        // Set the state AND Flag to completed ONLY if the page is Valid
        if (isValid) {
            // mark the index that we saved at before going back as completed, if keepChanges=true
            const goingForward = false;
            MarkThisStepCompleted(goingForward, appContext.wizardSteps[currIndex]);
        }

        appContext.wizardSteps[currIndex].currentState = GetStepStateUponCompletion(isValid, appContext.wizardSteps, currIndex);
    }

    if (currIndex > -1) {
        // we MUST decrement the current index (if keepChanges=true then it is the one that has advanced as a result of the save)
        const newWizardSteps = DecrementAndActivatePriorStep(currIndex, appContext.wizardSteps);

        // store changes to Wizard Steps - as we hand them around for mobile/header view
        UpdateWizardStepsAndRepaint(appContext, newWizardSteps);
    }

    ScrollToTop();
};
