import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import AppContext from '../stateManagement/context/AppContext';
import { WizardMode, WizardStepNumber } from '../services/Enums';
import { formatDateForDisplay } from '../utils/Dates';
import { DisplayFieldValueOrDefaultText } from '../utils/AppUtils';
import { DisplayGender } from '../utils/EnumMappings';
import { Inquiry } from '../api/interfacesApi';
import LabelField from '../components/fields/LabelField';
import PageFieldsTitle from '../components/fields/PageFieldsTitle';
import { EmptyRow } from 'controls/EmptyRow';

interface PersonalDetailsNameDisplayProps {
    inquiry: Inquiry;
    formatForPDF: boolean;
    displayFormerName?: boolean;
}

const PersonalDetailsNameDisplay = (props: PersonalDetailsNameDisplayProps) => {
    const { inquiry, formatForPDF, displayFormerName } = props;

    const appContext = useContext(AppContext);
    const showLinkToPage = formatForPDF === true ? false : appContext.wizardState.wizardMode === WizardMode.New ? true : false;

    return (
        <>
            <PageFieldsTitle
                title='Name details'
                showLinkToPage={showLinkToPage}
                wizardStepNumber={WizardStepNumber.PersonalDetailsName}
                smallerHeader={true}
                underlineTitle={formatForPDF}
            />
            <Row>
                <Col>
                    <LabelField
                        id='reviewPersonalNameTitle'
                        isTitleHtag={formatForPDF}
                        displayName='Title'
                        value={DisplayFieldValueOrDefaultText(inquiry.personalDetails.title)}
                        isMandatory={false}
                        textCapitalizeOn={true}
                    />
                </Col>
            </Row>
            {formatForPDF === true ? <EmptyRow /> : null}
            <Row>
                <Col>
                    <LabelField
                        id='reviewPersonalNameFirstname'
                        isTitleHtag={formatForPDF}
                        displayName='First name'
                        value={inquiry.personalDetails.firstName}
                        isMandatory={false}
                        textCapitalizeOn={true}
                    />
                </Col>
            </Row>
            {formatForPDF === true ? <EmptyRow /> : null}
            <Row>
                <Col>
                    <LabelField
                        id='reviewPersonalNamePreferredname'
                        isTitleHtag={formatForPDF}
                        displayName='Preferred name (if different to first name)'
                        value={DisplayFieldValueOrDefaultText(inquiry.personalDetails.preferredName)}
                        isMandatory={false}
                        textCapitalizeOn={true}
                    />
                </Col>
            </Row>
            {formatForPDF === true ? <EmptyRow /> : null}
            <Row>
                <Col>
                    <LabelField
                        id='reviewPersonalNameMiddlename'
                        isTitleHtag={formatForPDF}
                        displayName='Middle name(s)'
                        value={DisplayFieldValueOrDefaultText(inquiry.personalDetails.middleName)}
                        isMandatory={false}
                        textCapitalizeOn={true}
                    />
                </Col>
            </Row>
            {formatForPDF === true ? <EmptyRow /> : null}
            <Row>
                <Col>
                    <LabelField
                        id='reviewPersonalNameFamilyname'
                        isTitleHtag={formatForPDF}
                        displayName='Family name'
                        value={inquiry.personalDetails.familyName}
                        isMandatory={false}
                        textCapitalizeOn={true}
                    />
                </Col>
            </Row>
            {displayFormerName === true && (
                <>
                    {formatForPDF === true ? <EmptyRow /> : null}
                    <Row>
                        <Col>
                            <LabelField
                                id='reviewPersonalNameFormername'
                                isTitleHtag={formatForPDF}
                                displayName='Former name'
                                value={DisplayFieldValueOrDefaultText(inquiry.personalDetails.formerName)}
                                isMandatory={false}
                                textCapitalizeOn={true}
                            />
                        </Col>
                    </Row>
                </>
            )}
            {formatForPDF === true ? <EmptyRow /> : null}
            <Row>
                <Col>
                    <LabelField
                        id='reviewPersonalNameGender'
                        isTitleHtag={formatForPDF}
                        displayName='Gender'
                        value={DisplayGender(inquiry.personalDetails.gender)}
                        isMandatory={false}
                        textCapitalizeOn={true}
                    />
                </Col>
            </Row>
            {formatForPDF === true ? <EmptyRow /> : null}
            <Row>
                <Col>
                    <LabelField
                        id='reviewPersonalNameDateofbirth'
                        isTitleHtag={formatForPDF}
                        displayName='Date of birth'
                        value={formatDateForDisplay(inquiry.personalDetails.dateOfBirth)}
                        isMandatory={false}
                    />
                </Col>
            </Row>
        </>
    );
};

export default PersonalDetailsNameDisplay;
