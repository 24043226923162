import { Inquiry, InquiryDefaultObj, IOutlet, IAttachmentMetadata, IComplaintMechanism } from '../../../api/interfacesApi';
import { WizardStepProps } from '../../../components/wizard/wizardInterfaces';
import { WizardStepCurrentStateEnum } from '../../../components/wizard/wizardEnums';
import { AttachmentDocumentType, ReworkSectionType } from '../../../services/Enums';
// import Guid from '../../../services/Guid';
import {
    defaultApplicationData,
    defaultApplicationSubmitData,
    defaultComplaintsMechanismData,
    defaultOutletPractitionerData,
    defaultUserData,
} from '../../../api/defaultData';
import { ComplaintsArrayIndexes } from '../../../utils/ComplaintsUtils';
// import { defaultOutletPractitionerData } from '../../pages/OutletDetails/OutletDetailsForm';

export interface FormPropertiesProps {
    // inquiry: any; // matches mapped data object for Wizard/all-Forms
    InitialData: InquiryDefaultObj; // DefaultObj;
    WizardSteps: WizardStepProps[];
    StepIds: number[];
    CurrentStep: WizardStepProps;
}

// RegistrationApplicationForm
//  - Needs to have a 1..many collection of WizardStep, each of which contains a Page
//  - Needs to supply 1..many WizardMenuItem, each of which *might* contain 1..many WizardMenuItemChild items
//

/*
const fullgraddiplomaevidence: IAttachmentMetadata = {
    attachmentId: '',
    fileName: '',
    documentType: AttachmentDocumentType.FullGraduateDiplomaOfFDR,
    applicationId: '',
    isDeleted: null,
    azureBlobName: '',
};

const threeunitsevidence: IAttachmentMetadata = {
    attachmentId: '',
    fileName: '',
    documentType: AttachmentDocumentType.ThreeUnits,
    applicationId: '',
    isDeleted: null,
    azureBlobName: '',
};

export const defaultAttachmentsData: IAttachmentMetadata[] = [fullgraddiplomaevidence, threeunitsevidence];
*/

//    const newOutlets: SelectListOption[] = [];
//    newOutlets.push({ value: '', text: '' });

const defaultOutletsData: IOutlet[] = [];

// used externally
export const initAttachment: IAttachmentMetadata = {
    attachmentId: '',
    fileName: '',
    applicationId: '',
    azureBlobName: '',
    documentType: AttachmentDocumentType.OrgEmployment,
    isDeleted: false,
};

export const GetNewComplaintMechanismObject = () => {
    // const guid = new Guid();

    const newComplaint: IComplaintMechanism = {
        ...defaultComplaintsMechanismData,
        complaintsProcessType: undefined,
        evidence: [],
        attachmentExists: initAttachment.attachmentId,
        professionalMembershipType: null, // ProfessionalMembershipType.FullMembership,
    };
    // must reset this:
    newComplaint.outlet = { ...defaultOutletPractitionerData, outletId: '', name: '', organisationFundingType: null };
    return newComplaint;
};

export const ResetComplaintsMechanismCollection = (startAtIndexOneInstead: boolean, setValue: any, inquiry: Inquiry) => {
    const newComplaint: IComplaintMechanism = GetNewComplaintMechanismObject();
    let indexCollection = [];

    // startAtIndexOneInstead ? (indexCollection = [1, 2, 3, 4]) : (indexCollection = [0, 1, 2, 3, 4]);
    // splice will remove 1 item at index 0
    startAtIndexOneInstead ? (indexCollection = ComplaintsArrayIndexes.splice(0, 1)) : (indexCollection = ComplaintsArrayIndexes);

    // eslint-disable-next-line array-callback-return
    indexCollection.map(index => {
        setValue(`inquiry.application.complaintsMechanism[${index}]`, newComplaint);
        inquiry.application.complaintsMechanism[index] = newComplaint;
    });
};

const defaultData: Inquiry = {
    // introductionText: '',
    application: defaultApplicationData,
    personalDetails: defaultUserData,
    outlets: defaultOutletsData,
    applicationSubmit: defaultApplicationSubmitData,
    differencesUser: null,
    differencesComplaintsCollection: null,
    // complaintsMechanism: defaultComplaintsMechanismData,
};

const step1: WizardStepProps = {
    index: 0,
    nextIndex: 1,
    dataTestId: 'WizardStepIntroduction',
    displayName: 'Introduction',
    currentState: WizardStepCurrentStateEnum.initial,
    stepCompleted: false,
    isDisplayed: true,
    isActive: true,
};

const step2: WizardStepProps = {
    index: 1,
    nextIndex: 2,
    dataTestId: 'WizardStepPersonalNameDetails',
    displayName: 'Name details',
    currentState: WizardStepCurrentStateEnum.initial,
    stepCompleted: false,
    isDisplayed: true,
    isActive: false,
    isChild: true,
    parentDisplayName: 'Personal details',
    isLastChild: false,
};

const step3: WizardStepProps = {
    index: 2,
    nextIndex: 3,
    dataTestId: 'WizardStepPersonalContactDetails',
    displayName: 'Contact details',
    isDisplayed: true,
    isActive: false,
    currentState: WizardStepCurrentStateEnum.initial,
    stepCompleted: false,
    isChild: true,
    parentDisplayName: 'Personal details',
    isLastChild: true,
};

const step4: WizardStepProps = {
    index: 3,
    nextIndex: 4,
    dataTestId: 'WizardStepPastAppHistory',
    displayName: 'Past application history',
    currentState: WizardStepCurrentStateEnum.initial,
    stepCompleted: false,
    isDisplayed: true,
    isActive: false,
};

const step5: WizardStepProps = {
    index: 4,
    nextIndex: 5,
    dataTestId: 'WizardStepCompetencies',
    displayName: 'Competencies and qualifications',
    currentState: WizardStepCurrentStateEnum.initial,
    stepCompleted: false,
    isDisplayed: true,
    isActive: false,
    reworkSectionType: [
        ReworkSectionType.Qualification,
        ReworkSectionType.QualificationFullGraduateDiploma,
        ReworkSectionType.QualificationSixUnits,
        ReworkSectionType.QualificationNMAS,
        ReworkSectionType.QualificationThreeUnits,
    ],
};

const step6: WizardStepProps = {
    index: 5,
    nextIndex: 6,
    dataTestId: 'WizardStepChildRelated',
    displayName: 'Child-related employment',
    currentState: WizardStepCurrentStateEnum.initial,
    stepCompleted: false,
    isDisplayed: true,
    isActive: false,
    reworkSectionType: [ReworkSectionType.WorkingWithChildrenCheck],
};

const step7: WizardStepProps = {
    index: 6,
    nextIndex: 7,
    dataTestId: 'WizardStepCriminal',
    displayName: 'Criminal convictions',
    currentState: WizardStepCurrentStateEnum.initial,
    stepCompleted: false,
    isDisplayed: true,
    isActive: false,
    reworkSectionType: [ReworkSectionType.NationalPoliceCheck],
};

const step8: WizardStepProps = {
    index: 7,
    nextIndex: 8,
    dataTestId: 'WizardStepComplaintsType',
    displayName: 'Type of mechanism',
    currentState: WizardStepCurrentStateEnum.initial,
    stepCompleted: false,
    isDisplayed: true,
    isActive: false,
    isChild: true,
    parentDisplayName: 'Complaints mechanism',
    isLastChild: false,
};

const step9: WizardStepProps = {
    index: 8,
    nextIndex: 9,
    dataTestId: 'WizardStepComplaintsEmployee',
    displayName: 'Employee or panel member',
    currentState: WizardStepCurrentStateEnum.initial,
    stepCompleted: false,
    isDisplayed: false,
    isActive: false,
    isChild: true,
    parentDisplayName: 'Complaints mechanism',
    isLastChild: false,
    reworkSectionType: [ReworkSectionType.ComplaintsMechanismOrganisation],
};

const step10: WizardStepProps = {
    index: 9,
    nextIndex: 10,
    dataTestId: 'WizardStepComplaintsMember',
    displayName: 'Member of professional association',
    currentState: WizardStepCurrentStateEnum.initial,
    stepCompleted: false,
    isDisplayed: false,
    isActive: false,
    isChild: true,
    parentDisplayName: 'Complaints mechanism',
    isLastChild: true,
    reworkSectionType: [ReworkSectionType.ComplaintsMechanismProfessionalAssociation],
};

const step11: WizardStepProps = {
    index: 10,
    nextIndex: 11,
    dataTestId: 'WizardStepComplaintsInsurance',
    displayName: 'Professional indemnity insurance',
    currentState: WizardStepCurrentStateEnum.initial,
    stepCompleted: false,
    isDisplayed: false,
    isActive: false,
    reworkSectionType: [ReworkSectionType.ProfessionalIndemnityInsurance],
};

const step12: WizardStepProps = {
    index: 11,
    nextIndex: 12,
    dataTestId: 'WizardStepChangeOfName',
    displayName: 'Change of name',
    currentState: WizardStepCurrentStateEnum.initial,
    stepCompleted: false,
    isDisplayed: true,
    isActive: false,
    reworkSectionType: [ReworkSectionType.ChangeOfName],
};

const step13: WizardStepProps = {
    index: 12,
    nextIndex: 13,
    dataTestId: 'WizardStepDeclaration',
    displayName: 'Declaration and consent',
    currentState: WizardStepCurrentStateEnum.initial,
    stepCompleted: false,
    isDisplayed: true,
    isActive: false,
};

const step14: WizardStepProps = {
    index: 13,
    nextIndex: -1,
    dataTestId: 'WizardStepReview',
    displayName: 'Review and submit',
    currentState: WizardStepCurrentStateEnum.initial,
    stepCompleted: false,
    isDisplayed: true,
    isActive: false,
};

const steps: WizardStepProps[] = [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12, // change of name step
    step13, // delcaration
    step14,
];

const inquiryData: InquiryDefaultObj = {
    inquiry: defaultData,
};

const formProperties: FormPropertiesProps = {
    // inquiry: defaultData,
    InitialData: inquiryData,
    WizardSteps: steps,
    StepIds: steps.map(s => s.index),
    CurrentStep: step1,
};

export default class RegistrationApplicationForm {
    public properties: FormPropertiesProps = formProperties;
}
