import React from 'react';
import { validateAndSecureURL } from '../../utils/Common';

interface NavLinkProps {
    hrefLink: string;
    children: any;
    dataTestId: string;
    handleOnClick: any;
    className?: string;
    openInNewTab?: boolean;
    disabled?: boolean;
    altText?: string;
}

const NavLinkActionCard = (props: NavLinkProps) => {
    const { hrefLink, children, className, openInNewTab, handleOnClick, dataTestId, disabled, altText } = props;

    const hrefString = validateAndSecureURL(hrefLink.toString());
    let classNameModded = className ? className : '';
    classNameModded = classNameModded + (disabled === true ? ' disabled' : '');

    const attributes = {
        href: hrefString !== '' ? hrefString : undefined,
        target: '_self', // default
        alt: '',
    };

    if (openInNewTab) {
        attributes.target = '_blank';
    }

    if (altText) {
        attributes.alt = altText;
    }

    return (
        <a {...attributes} className={classNameModded} rel='noreferrer' onClick={handleOnClick} data-testid={dataTestId}>
            {children}
        </a>
    );
};

export default NavLinkActionCard;
