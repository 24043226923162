// Complaints mechanism-Member page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the Complaints mechanism-Employee page
//  - allows navigation forward to the Professional Indemnity page
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { get } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { RegoApplicationPageProps } from '../../PageInterfaces';
import { EnsureData } from '../RegistrationApplicationWizard/wizardHelperFunctions';
import { MoveWizardForward, MoveWizardForwardForResubmit } from '../../../components/wizard/wizardStepFunctions';
import { GetTextForSaveAndContinueButton, HideTheBackButton, SubmitThisPageIfActive } from '../../PageFunctions';
import Guid from '../../../services/Guid';
import {
    HEADER_COMPLAINTS,
    HELPHEADERTEXT_PROFESSIONALASSOCNAME,
    HELPTEXT_COMPLAINTSMECHANISM_FULLMEMBERSHIP,
    HELPTEXT_PROFESSIONALASSOCNAME,
    NOTE_COMPLAINTS_TYPEOFCOMPLAINTS_DEFERMESSAGE,
    NOTE_MEMBERSHIP_START_DATE_IN_FUTURE,
    PLACEHOLDER_DATE,
    UNINITIALISED,
} from '../../../services/Constants';
import { VALIDATION_ERROR_POPUP_MESSAGE, VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../../services/Validation';
import {
    AjaxCallStateEnum,
    AttachmentDocumentType,
    ComplaintsMechanismType,
    ComplaintsProcessType,
    ProfessionalMembershipType,
    WizardMode,
    WizardStepNumber,
} from '../../../services/Enums';
import { IAttachmentMetadata, IProfessionalAssociation } from '../../../api/interfacesApi';
import ReferenceApi from '../../../api/referenceApi';
import ApplicationActions from '../../../actions/applicationActions';
import { toastErrorMessage } from '../../../actions/toastrMessages';
import { Sleep } from '../../../utils/Common';
import {
    GetActiveMembershipComplaintAttachmentIdCollection,
    InsertAttachmentToLocalInquiryObject,
    RemoveAttachmentFromLocalInquiryObject,
} from '../../../utils/ComplaintsUtils';
import { ErrorOnSubmitForm } from '../../../utils/AppUtils';
import ErrorSummary from '../../../controls/ErrorSummary';
import NoteText from '../../../controls/NoteText';
import { EmptyRow } from '../../../controls/EmptyRow';
import Loader from '../../../controls/Loader';
import AppContext from '../../../stateManagement/context/AppContext';
import PageWrapper from '../../../components/pageWrapper';
import PageTitle from '../../../components/pageTitle';
import PageMandatoryLabelText from '../../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import FileUploadField from '../../../components/fields/FileUploadField';
import SelectListField, { SelectListOption } from '../../../components/fields/SelectListField';
import InputDateField from '../../../components/fields/InputDateField';
import LabelField from '../../../components/fields/LabelField';
import styles from '../../Pages.module.scss';

const { childItems, startDateWarningNote } = styles;

const ComplaintsMechanismMemberPage = (props: RegoApplicationPageProps) => {
    const {
        inquiry,
        authService,
        goingBackwards,
        dispatch,
        watch,
        handleClickPrevious,
        handleSubmit,
        register,
        setError,
        setValue,
        control,
        errors,
        trigger,
        isValid,
        isActive,
        // isDirty,
        clearErrors,
        ajaxCallStateForPage,
        backEndValidationErrors,
        triggerSubmit,
        setTriggerSubmit,
    } = props;

    const today = new Date();
    const appContext = useContext(AppContext);
    const disableField = appContext.wizardState.wizardMode === WizardMode.Resubmit ? true : false;
    const formRef = useRef();
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    // even though we have redux states, we HAVE to capture the attempt due to navigation and Get and Save user calls on the page
    const [attemptingSave, setAttemptingSave] = useState(false);
    const referenceApi = useMemo(() => {
        return new ReferenceApi(authService);
    }, [authService]);
    const [profAssocSelectList, setProfAssocSelectList] = useState<SelectListOption[]>([]);
    const [filesExist, setFilesExist] = useState(false);
    const [loadingListData, setLoadingListData] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(true); // needs to be TRUE for when this page is loaded as part of wizard pages collection
    const [dataInquiry, setDataInquiry] = useState(inquiry);
    const [attemptingSaveApp, setAttemptingSaveApp] = useState(false);
    const idFileUpload = 'inquiry.application.activeComplaintsMechanismObj.attachmentExists';

    const membershipFromDateWatched: Date = watch(
        'inquiry.application.activeComplaintsMechanismObj.membershipFromDate',
        inquiry.application.activeComplaintsMechanismObj?.membershipFromDate,
    );
    const membershipToDateWatched: Date = watch(
        'inquiry.application.activeComplaintsMechanismObj.membershipToDate',
        inquiry.application.activeComplaintsMechanismObj?.membershipToDate,
    );
    const [showStartDateInFutureWarning, setShowStartDateInFutureWarning] = useState(false);
    /*const professionalMembershipTypeWatched: ProfessionalMembershipType = watch(
        'inquiry.application.activeComplaintsMechanismObj.professionalMembershipType',
        inquiry.application.activeComplaintsMechanismObj?.professionalMembershipType,
    );*/
    const [isFullMembership, setIsFullMembership] = useState(true);

    const loadProfessionalAssociationsList = () => {
        setIsDataLoaded(false);

        // reference data load
        referenceApi
            .getAllProfessionalAssociations()
            .then((response: any) => {
                // construct a list including empty first item which we can validate against
                const profAssociations = response as IProfessionalAssociation[];
                const newProfAssociations: SelectListOption[] = [];
                newProfAssociations.push({ value: '', text: '' });
                profAssociations.map(data => newProfAssociations.push({ value: data.professionalAssociationId, text: data.name }));

                setLoadingListData(true);
                setProfAssocSelectList(newProfAssociations);
                // refresh UI to load values properly
                trigger();
            })
            .catch((error: any) => {
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            });
    };

    // initial load of any existing data
    useEffect(() => {
        // ONLY call this API if active page
        if (isActive === true) {
            loadProfessionalAssociationsList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // if goingBackwards may need to reload this list
    useEffect(() => {
        if (isActive === true && goingBackwards === true && profAssocSelectList.length === 0) {
            loadProfessionalAssociationsList();
            // if this routine is called from going backwards then add tiny delay to allow processing
            Sleep(2000).then(() => {
                setIsDataLoaded(true);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, goingBackwards]);

    // need to trigger() if disabled list, otherwise the UI is 'locked' when user first navs to it under resubmit flow
    useEffect(() => {
        if (disableField === true && profAssocSelectList && profAssocSelectList.length > 0) {
            Sleep(400).then(() => {
                // immediately remove any errors as UI and validation not aligning as expected
                removeErrorsAndReValidate();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profAssocSelectList]);

    useEffect(() => {
        // show/hide the Note for Start Date in future
        if (membershipFromDateWatched && showStartDateInFutureWarning === false && membershipFromDateWatched > today) {
            setShowStartDateInFutureWarning(true);
        }
        if (membershipFromDateWatched && showStartDateInFutureWarning === true && membershipFromDateWatched < today) {
            setShowStartDateInFutureWarning(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [membershipFromDateWatched, showStartDateInFutureWarning]);

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors.errors?.length > 0) {
            backEndValidationErrors.errors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'Outlet':
                        setError('inquiry.application.outletId', { type: 'manual', message: validationError.errorMessage });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    // check dispatch success state
    useEffect(() => {
        if (isActive === true && ajaxCallStateForPage && ajaxCallStateForPage.pageNumber === WizardStepNumber.ComplaintsMechanismMember) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                setAttemptingSave(false);
                setAttemptingSaveApp(false);
                setLoadingListData(false);
                setIsDataLoaded(true);
                displayBackEndValidations();
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setAttemptingSave(false);
                setAttemptingSaveApp(false);
                setLoadingListData(false);
                setIsDataLoaded(true);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (attemptingSave === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK && !appContext.wizardState.triggerSubmit) {
                    // IF VALID (or OK to move forward):
                    setAttemptingSave(false);

                    setAttemptingSaveApp(true);
                    // so we can set the current application step (use stored data object so we update the currentStep)
                    const updateOutlet = false;
                    dispatch(appActions.UpdateApplication(dataInquiry!.application, updateOutlet));
                } else if (
                    attemptingSaveApp === true &&
                    ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK &&
                    !appContext.wizardState.triggerSubmit
                ) {
                    setAttemptingSaveApp(false);

                    // refresh data set now after changes saved
                    dispatch(appActions.GetApplication());

                    // Move wizard forward to next step pending whether we are navigating normally or for the resubmit flow
                    if (appContext.wizardState.wizardMode === WizardMode.New) {
                        MoveWizardForward(appContext, isValid);
                    } else {
                        MoveWizardForwardForResubmit(appContext, isValid);
                    }
                }

                if (loadingListData === true) {
                    setLoadingListData(false);
                    setIsDataLoaded(true);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const submitForm = (data: any) => {
        if (isValid && data && data.inquiry.application.activeComplaintsMechanismObj) {
            // check if valid and selection of Yes to question 'Association Membership' then we need to also check files here
            // TODO: Map our entries from this page into (each) of the application.complaintsMechanism[]
            const complaintsMechId = data.inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId;
            if (
                complaintsMechId === undefined ||
                complaintsMechId === null ||
                complaintsMechId === '' ||
                complaintsMechId === UNINITIALISED ||
                complaintsMechId?.length === 0
            ) {
                const guid = new Guid();
                data.inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId = guid.empty;
            }

            // if field is disabled we need to set the data Id properly for the list item for Prof Associations
            if (disableField === true) {
                if (
                    (data.inquiry.application.activeComplaintsMechanismObj?.professionalAssociation === null &&
                        inquiry.application.activeComplaintsMechanismObj?.professionalAssociation !== null) ||
                    (data.inquiry.application.activeComplaintsMechanismObj?.professionalAssociation.professionalAssociationId === undefined &&
                        data.inquiry.application.activeComplaintsMechanismObj?.professionalAssociation.name !== '' &&
                        inquiry.application.activeComplaintsMechanismObj?.professionalAssociation !== null)
                ) {
                    // professionalAssoc will be a guid not a profAssoc interface so need to convert it now
                    const profAssocId = inquiry.application.activeComplaintsMechanismObj?.professionalAssociation.professionalAssociationId;
                    data.inquiry.application.activeComplaintsMechanismObj.professionalAssociation = { professionalAssociationId: profAssocId, name: '' };
                }
            } else {
                // professionalAssoc will be a guid not a profAssoc interface so need to convert it now
                const profAssocId = data.inquiry.application.activeComplaintsMechanismObj.professionalAssociation.professionalAssociationId;
                data.inquiry.application.activeComplaintsMechanismObj.professionalAssociation = { professionalAssociationId: profAssocId, name: '' };
            }

            data.inquiry.application.activeComplaintsMechanismObj.complaintsProcessType = ComplaintsProcessType.ProfessionalAssociationMembership;

            if (
                data.inquiry.application.activeComplaintsMechanismObj.professionalMembershipType === undefined ||
                data.inquiry.application.activeComplaintsMechanismObj.professionalMembershipType === null
            ) {
                data.inquiry.application.activeComplaintsMechanismObj.professionalMembershipType =
                    data.inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType.toString() ===
                    ComplaintsMechanismType.FullMembershipProfessionalAssoc.toString()
                        ? ProfessionalMembershipType.FullMembership
                        : null;
            }

            /* no longer needed as handled by the active CM obj
            // inject this object into a collection, if that exists, replacing existing collection item only
            const membershipExists = IsAnyComplaintProfAssocMember(data.inquiry.application.complaintsMechanism);

            if (membershipExists === false) {
                data.inquiry.application.complaintsMechanism.push(data.inquiry.application.activeComplaintsMechanismObj);
            } */

            // set existing data now as we dont capture it in this page
            EnsureData(appContext, WizardStepNumber.ComplaintsMechanismMember, data, inquiry);
            // Save changes to application (attempt save, if that fails we do NOT move the Wizard step)
            setAttemptingSave(true);

            setDataInquiry(data.inquiry);

            // determine if a new complaints mech or update existing
            if (
                data.inquiry.application.complaintsMechanism.length === 0 &&
                data.inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId === Guid.empty
            ) {
                dispatch(appActions.AddComplaintsMechanism(data.inquiry.application.activeComplaintsMechanismObj));
            } else {
                dispatch(appActions.UpdateComplaintsMechanism(data.inquiry.application.activeComplaintsMechanismObj));
            }
            // dispatch(appActions.UpdateApplication(data.inquiry.application));
        } else {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    SubmitThisPageIfActive(isActive, triggerSubmit, formRef, setTriggerSubmit);

    const removeErrorsAndReValidate = () => {
        // do the trigger only once
        let runTrigger = false;
        const errorAttachments: { [key: string]: any[] } = get(errors, 'inquiry.application.attachments');

        if (errorAttachments && errorAttachments !== undefined) {
            if (errorAttachments.associationmembership) {
                clearErrors('inquiry.application.attachments.associationmembership');
                runTrigger = true;
            }
        }

        // run the below ONLY if disabled
        if (disableField === true) {
            const selectListErrorExists = get(errors, 'inquiry.application.activeComplaintsMechanismObj.professionalAssociation.professionalAssociationId');
            if (selectListErrorExists !== undefined) {
                clearErrors('inquiry.application.activeComplaintsMechanismObj.professionalAssociation.professionalAssociationId');
            }
        }

        if (runTrigger === true) {
            trigger(); // reset validation so we dont get a form isValid=false causing inability to save
        }
    };

    // update attachments collection as that is seperate from other data application object call
    const updatedFilesList = (files: any) => {
        if (files && files.length > 0) {
            if (filesExist === false) {
                setFilesExist(true);
                // immediately remove any errors of type file not uploaded (for parent nodes)
                removeErrorsAndReValidate();
            }
        } else {
            if (filesExist === true) {
                setFilesExist(false);
            }
        }
    };

    const removedFile = (attachmentId: string) => {
        // refresh CM object/active obj
        RemoveAttachmentFromLocalInquiryObject(attachmentId, setValue, inquiry);
        trigger(); // re-validate
    };

    const addedFile = (newAttachment: IAttachmentMetadata) => {
        // refresh CM object/active obj (NOTE: CANNOT get App in case user simply adds/removes files, it will clear the form!)
        InsertAttachmentToLocalInquiryObject(newAttachment, setValue, inquiry);
        trigger(); // re-validate
    };

    const startDateInFutureWarningNote = () => {
        return (
            <Row className={startDateWarningNote}>
                <Col md='11'>
                    <NoteText displayText={NOTE_MEMBERSHIP_START_DATE_IN_FUTURE} />
                </Col>
                <Col md='1' className='justify-content-end' />
            </Row>
        );
    };

    const showDeferNote = () => {
        return (
            <>
                <EmptyRow />
                <Row>
                    <Col md='11'>
                        <NoteText displayText={NOTE_COMPLAINTS_TYPEOFCOMPLAINTS_DEFERMESSAGE} />
                    </Col>
                    <Col md='1' className='justify-content-end' />
                </Row>
            </>
        );
    };

    // hard-set value for UI only
    if (
        inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType?.toString() ===
            ComplaintsMechanismType.FullMembershipProfessionalAssoc.toString() &&
        isFullMembership === false
    ) {
        setValue('inquiry.application.activeComplaintsMechanismObj.professionalMembershipType', ProfessionalMembershipType.FullMembership);
        setIsFullMembership(true);
    }

    // tslint:disable-next-line: prefer-const
    let nextYear = new Date();
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    const openingMembershipFromDate = membershipFromDateWatched ? membershipFromDateWatched : today;
    const openingMembershipToDate = membershipToDateWatched ? membershipToDateWatched : nextYear;

    // this needs to be set based off the collection of CM now as attachments are treated separately
    const attachmentIdCollection = GetActiveMembershipComplaintAttachmentIdCollection(inquiry?.application);
    // report on any error for attachments.fundedOrg field
    // const errorFieldAttachmentThisIndex = errors.inquiry?.application?.activeComplaintsMechanismObj; // complaintsMechanism?.find((_element: any, i: number) => i === 0); // errors.inquiry?.application?.attachments?.fundedOrg?.find((_element: any, i: number) => i === index);
    // const errorFieldAttachmentFundedOrg = errorFieldAttachmentThisIndex && errorFieldAttachmentThisIndex?.activeAttachmentId;

    return (
        <>
            <PageTitle title='Complaints mechanism' description={HEADER_COMPLAINTS} />
            <PageWrapper
                pageName='ComplaintsMember'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={handleClickPrevious}
                hideBackButton={HideTheBackButton(appContext)}
                textForSaveContinueButton={GetTextForSaveAndContinueButton(appContext)}
            >
                {(isDataLoaded === false || inquiry.application?.activeComplaintsMechanismObj?.complaintsMechanismId === UNINITIALISED) && (
                    <Loader isLoading={true} loaderText='Loading data...' />
                )}
                {isDataLoaded === true && inquiry.application?.activeComplaintsMechanismObj?.complaintsMechanismId !== UNINITIALISED && (
                    <>
                        <PageFieldsTitle title='Complaints mechanism - Member of professional association' />
                        <PageMandatoryLabelText />
                        <ErrorSummary errors={errors} />
                        <input
                            type='hidden'
                            className='form-control'
                            name='inquiry.application.applicationId'
                            {...register('inquiry.application.applicationId')}
                            defaultValue={inquiry.application.applicationId}
                        />
                        <input
                            type='hidden'
                            className='form-control'
                            name='inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType'
                            {...register('inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType')}
                            defaultValue={inquiry.application?.activeComplaintsMechanismObj?.complaintsMechanismType}
                        />
                        <input
                            type='hidden'
                            className='form-control'
                            name='inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId'
                            {...register('inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId')}
                            defaultValue={inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId}
                        />
                        <input
                            type='hidden'
                            className='form-control'
                            name='inquiry.application.CMMdocumentAttachmentIsMandatory'
                            {...register('inquiry.application.CMMdocumentAttachmentIsMandatory')}
                            defaultValue={true}
                        />
                        {disableField && (
                            <input
                                type='hidden'
                                className='form-control'
                                name='inquiry.application.activeComplaintsMechanismObj.professionalAssociation.professionalAssociationId'
                                {...register('inquiry.application.activeComplaintsMechanismObj.professionalAssociation.professionalAssociationId')}
                                defaultValue={inquiry.application.activeComplaintsMechanismObj?.professionalAssociation?.professionalAssociationId}
                            />
                        )}
                        <Row>
                            <Col>
                                <SelectListField
                                    id='inquiry.application.activeComplaintsMechanismObj.professionalAssociation.professionalAssociationId'
                                    displayName='Name of professional association'
                                    defaultValue={
                                        inquiry.application.activeComplaintsMechanismObj &&
                                        inquiry.application.activeComplaintsMechanismObj.professionalAssociation
                                            ? inquiry.application.activeComplaintsMechanismObj?.professionalAssociation.professionalAssociationId
                                            : ''
                                    }
                                    options={profAssocSelectList}
                                    disabled={disableField}
                                    isOneAndHalfWidth={true}
                                    isMandatory={true}
                                    register={register}
                                    helpHeaderText={HELPHEADERTEXT_PROFESSIONALASSOCNAME}
                                    helpText={HELPTEXT_PROFESSIONALASSOCNAME}
                                    errorsField={errors.inquiry?.application?.activeComplaintsMechanismObj?.professionalAssociation?.professionalAssociationId}
                                    guidanceText={HELPTEXT_COMPLAINTSMECHANISM_FULLMEMBERSHIP}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <LabelField
                                    id='typeOfMembershipValueLabel'
                                    displayName='Type of membership'
                                    value={isFullMembership ? 'Full Membership' : 'Limited Membership (student, restricted, non-practicing)'}
                                    isMandatory={false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <LabelField
                                    id='periodMembershipLabel'
                                    displayName='Current membership dates'
                                    value=''
                                    isMandatory={false}
                                    isDisplayNameFontSizeLarger={true}
                                    hideFinalEmptyRow={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputDateField
                                    id='inquiry.application.activeComplaintsMechanismObj.membershipFromDate'
                                    displayName='Start date'
                                    defaultValue={
                                        inquiry.application.activeComplaintsMechanismObj
                                            ? inquiry.application.activeComplaintsMechanismObj.membershipFromDate
                                            : undefined
                                    }
                                    isMandatory={true}
                                    openToDate={openingMembershipFromDate}
                                    yearDropdownRange={5}
                                    dropdownMode='select'
                                    register={register}
                                    control={control}
                                    disabled={disableField}
                                    placeHolder={PLACEHOLDER_DATE}
                                    errorsField={errors.inquiry?.application?.activeComplaintsMechanismObj?.membershipFromDate}
                                    hideFinalEmptyRow={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputDateField
                                    id='inquiry.application.activeComplaintsMechanismObj.membershipToDate'
                                    displayName='End date'
                                    defaultValue={
                                        inquiry.application.activeComplaintsMechanismObj
                                            ? inquiry.application.activeComplaintsMechanismObj.membershipToDate
                                            : undefined
                                    }
                                    isMandatory={true}
                                    openToDate={openingMembershipToDate}
                                    yearDropdownRange={5}
                                    yearDropdownRangeStartingYear={nextYear.getFullYear()}
                                    dropdownMode='select'
                                    register={register}
                                    control={control}
                                    disabled={disableField}
                                    placeHolder={PLACEHOLDER_DATE}
                                    errorsField={errors.inquiry?.application?.activeComplaintsMechanismObj?.membershipToDate}
                                    hideFinalEmptyRow={true}
                                />
                            </Col>
                        </Row>
                        <EmptyRow />
                        <Row className={childItems}>
                            <Col>
                                <FileUploadField
                                    id={idFileUpload} // we need to render this when relationship is undefined or other, as when handing it the docType if it dynamically changes the FileUpload is not updating
                                    data-id={0}
                                    attachmentIdCollection={attachmentIdCollection}
                                    refreshIfAttachmentIdNotFound={true} // will trigger empty fileInfo if the above Id not yet uploaded/exists
                                    maxFilesCount={3}
                                    applicationId={inquiry?.application.applicationId}
                                    complaintsProcessId={inquiry?.application.activeComplaintsMechanismObj?.complaintsMechanismId}
                                    authService={authService}
                                    displayNameSuffix='professional association membership'
                                    errorsFieldSuffix='professional association membership'
                                    documentType={AttachmentDocumentType.AssociationMembership}
                                    isMandatory={true}
                                    updatedFilesList={updatedFilesList}
                                    register={register}
                                    goingBackwards={goingBackwards}
                                    dispatch={dispatch}
                                    errorsField={errors.inquiry?.application?.attachments}
                                    // errorsField={errorFieldAttachmentFundedOrg}
                                    removedFile={removedFile}
                                    addedFile={addedFile}
                                />
                            </Col>
                        </Row>
                        {showStartDateInFutureWarning && startDateInFutureWarningNote()}
                        {showDeferNote()}
                    </>
                )}
            </PageWrapper>
        </>
    );
};

export default ComplaintsMechanismMemberPage;
