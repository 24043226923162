import React, { useState } from 'react';
import DevilsToolboxButton from '../../controls/DevilsToolboxButton';
import DevilsToolboxModal from './devilsToolboxModal';

interface DevilsToolboxButtonComponentProps {
    isPre: boolean;
    authService: any;
}

/* component that wraps the button to open the modal DevilsToolbox*/
export const DevilsToolboxButtonComponent = (props: DevilsToolboxButtonComponentProps) => {
    const { isPre, authService } = props;
    const [showModal, setShowModal] = useState(false);

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const closeModalEvent = () => {
        setShowModal(false);
    };

    return (
        <>
            {showModal === true && <DevilsToolboxModal authService={authService} isPre={isPre} closeModalEvent={closeModalEvent} />}
            <DevilsToolboxButton handleButtonClick={handleButtonClick} />
        </>
    );
};

export default DevilsToolboxButtonComponent;
