import React from 'react';

export interface PageTitleProps {
    title: string;
    description: string;
    children?: any; // if children supplied it means using HTML (and rather than inject unsafe HTML just do this manually for now)
}

export const PageTitle = (props: PageTitleProps) => {
    const { title, description, children } = props;

    return (
        <div className='jumbotron jumbotron-fluid'>
            <div className='container col col-md-11'>
                <h1 className='display-6'>{title}</h1>
                <p className='lead w-90'>{children ? children : description}</p>
            </div>
        </div>
    );
};

export default PageTitle;
