// The AddressComponent is built to do the following:
//   - Host (residential/postal) address:
//       * Fields: Street Address, Suburb, State, Postcode
//   - Will hook into Google API to do an address lookup so that fields get auto-filled once user begins typing in Street Address
//
import React, { useEffect, useState } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { Col, Row } from 'react-bootstrap';
import GooglePlacesAutocomplete, { geocodeByPlaceId, geocodeByAddress } from 'react-google-places-autocomplete';
import { IAddress } from '../../api/interfacesApi';
import { AustralianStatesList, PLACEHOLDER_ADDRESS_LOOKUP, UNINITIALISED } from '../../services/Constants';
import { AddressTypeEnum } from '../../services/Enums';
import { Sleep } from '../../utils/Common';
import GuidanceText from '../../controls/GuidanceText';
import InputTextField from '../fields/InputTextField';
import SelectListField from '../fields/SelectListField';
import LabelField from '../fields/LabelField';
import styles from './address.module.scss';

const { addressOrgWrapper, addressOrgDetailHeader, addressOrgDetailBody, googleWrapper, manualAddressWrapper, guidanceTextBody } = styles;

export interface AddressComponentProps {
    currItem: IAddress;
    errors?: any;
    errorFieldsPrefix: any;
    fieldName: string;
    headerPrefix?: string;
    register: any;
    inquiry: any;
    control: any;
    setValue: any;
    watch: any;
    isValid: any;
    trigger: any;
    setTriggerValidation?: any;
    guidanceText?: string;
    streetAddressMandatory?: boolean;
    isReadOnly?: boolean;
}

interface Suggestion {
    label: string;
    value: google.maps.places.AutocompletePrediction;
}

const initPlace: Suggestion = {
    label: UNINITIALISED,
    value: {
        description: '',
        distance_meters: 0,
        matched_substrings: [],
        place_id: '',
        structured_formatting: { main_text: '', secondary_text: '', main_text_matched_substrings: [] },
        terms: [],
        types: [],
        reference: '',
    },
};

const isNullOrEmpty = (val: string): boolean => {
    if (!val) {
        return true;
    }

    return val.trim().length === 0;
};

const AddressComponent = (props: AddressComponentProps) => {
    const {
        currItem,
        errors,
        errorFieldsPrefix,
        fieldName,
        headerPrefix,
        register,
        inquiry,
        setValue,
        watch,
        // isValid,
        // trigger,
        setTriggerValidation,
        guidanceText,
        streetAddressMandatory = true,
        isReadOnly = false,
    } = props;

    const [placeValue, setPlaceValue] = useState(initPlace);

    const isPostalAddress = currItem && currItem.addressType && currItem.addressType === AddressTypeEnum.Postal;
    const headerPrefixModded = headerPrefix && headerPrefix !== '' ? headerPrefix : isPostalAddress ? 'Postal' : 'Residential';

    const watchMinLookupAddressFields: any[] = watch([`${fieldName}.setByAutocomplete`, `${fieldName}.town`, `${fieldName}.state`, `${fieldName}.postcode`]);
    const watchStreetAddressAndCoordinates: any[] = watch([`${fieldName}.streetAddress`]); // , `${fieldName}.latitude`, `${fieldName}.longitude`]);

    const streetAddressEmptiedWithMinFieldsFilled = () => {
        const setByAutoComplete = watchMinLookupAddressFields[0];
        const addressParts = watchMinLookupAddressFields.slice(1);

        return setByAutoComplete === false && addressParts.every(a => !isNullOrEmpty(a)) && watchStreetAddressAndCoordinates[0] === '';
    };

    const setCoordinates = (cancel: boolean) => {
        const setByAutoComplete = watchMinLookupAddressFields[0];
        const addressParts = watchMinLookupAddressFields.slice(1);

        if (setByAutoComplete === false && addressParts.every(a => !isNullOrEmpty(a))) {
            const address = addressParts.join(', ');

            try {
                geocodeByAddress(address).then(results => {
                    if (cancel) return; // handle unmount prior to call returning

                    if (results.length > 0) {
                        const addr = results[0];
                        setValue(`${fieldName}.latitude`, addr.geometry.location.lat());
                        setValue(`${fieldName}.longitude`, addr.geometry.location.lng());
                    }
                });
            } catch {
                // if we emit as a dialog box it will appear multiple times throughout each field as we tab through them, so just emit to console window.
                console.log('It looks like google maps API is blocked. Please unblock (or allow) https://maps.googleapis.com in your browser.');
            }
        }
    };

    useEffect(() => {
        let cancel = false;

        setCoordinates(cancel);

        // MUST clear on unmount
        return () => {
            cancel = true;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchMinLookupAddressFields]);

    /* do not want immediate validation upon load:
    useEffect(() => {
        if (!isValid) {
            trigger(); // re-validate as this component gets the call from above - otherwise it doesn't do anything
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]); */

    useEffect(() => {
        let cancel = false;

        if (!placeValue || placeValue.label === UNINITIALISED) {
            return;
        }

        geocodeByPlaceId(placeValue.value.place_id)
            .then(results => {
                if (cancel) return; // handle unmount prior to call returning

                if (results.length > 0) {
                    // match address parts to fields - looking for subpremise, street_number, route (street), locality (suburb), administrative_area_level_1 (state), postal_code
                    const parts: Partial<Record<string, string>> = {};
                    parts.route = `${fieldName}.streetAddress`;
                    parts.postal_code = `${fieldName}.postcode`;
                    parts.administrative_area_level_1 = `${fieldName}.state`;
                    parts.locality = `${fieldName}.town`;

                    const keys = Object.keys(parts);

                    let streetAddress = '';
                    const addr = results[0];
                    addr.address_components.forEach(c => {
                        keys.forEach(k => {
                            if (c.types.includes(k)) {
                                setValue(parts[k], k === 'administrative_area_level_1' ? c.short_name : c.long_name);

                                if (k === 'route') {
                                    streetAddress = c.long_name;
                                }
                            }
                        });
                    });

                    const subpremise = addr.address_components.filter(c => c.types.includes('subpremise'));
                    const streetNumber = addr.address_components.filter(c => c.types.includes('street_number'));

                    if (streetNumber.length > 0) {
                        streetAddress = streetNumber[0].long_name + ' ' + streetAddress;
                    }

                    if (subpremise.length > 0) {
                        streetAddress = subpremise[0].long_name + '/' + streetAddress;
                    }

                    setValue(`${fieldName}.streetAddress`, streetAddress);

                    // set latitude and longitude
                    setValue(`${fieldName}.latitude`, addr.geometry.location.lat());
                    setValue(`${fieldName}.longitude`, addr.geometry.location.lng());
                    setValue(`${fieldName}.setByAutocomplete`, true);
                    // re-validate
                    // trigger();
                }
            })
            .catch(error => console.log(error));

        // MUST clear on unmount
        return () => {
            cancel = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placeValue]);

    const autocompleteWasOverridden = () => {
        setValue(`${fieldName}.setByAutocomplete`, false);
    };

    const onKeyUpStreetAddress = () => {
        // MUST set the flag to false prior to attempt set of coords here
        autocompleteWasOverridden();

        // need to capture if user does a lookup using address lookup part, then removes only address field (for their anonymity)
        if (streetAddressEmptiedWithMinFieldsFilled() === true) {
            setCoordinates(false);
        }
    };

    const onChangeAddressState = (event: any) => {
        setValue(`${fieldName}.state`, event.target.value); // MUST setValue here as we now have 'controlled' input
        // trigger(); // re-validate
    };

    const errorFieldStreetAddress = errorFieldsPrefix && errorFieldsPrefix?.streetAddress;
    const errorFieldTown = errorFieldsPrefix && errorFieldsPrefix?.town;
    const errorFieldPostcode = errorFieldsPrefix && errorFieldsPrefix?.postcode;
    const errorFieldState = errorFieldsPrefix && errorFieldsPrefix?.state;

    if (inquiry === undefined || inquiry?.personalDetails.addresses?.length === 0 || currItem === null) {
        return null;
    }

    if (errors && errors.inquiry && errors.inquiry?.application?.outletPractitioner?.isRoomsToBeArranged !== undefined) {
        if (errorFieldStreetAddress === undefined && errorFieldTown === undefined && errorFieldPostcode === undefined && errorFieldState === undefined) {
            if (setTriggerValidation !== undefined) {
                Sleep(400).then(() => {
                    setTriggerValidation(true);
                });
            }
        }
    }

    return (
        <div id={fieldName}>
            <Row className={addressOrgWrapper}>
                <Col>
                    <Row className={addressOrgDetailHeader}>
                        <Col>
                            <label>
                                <h4>{headerPrefixModded} Address</h4>
                            </label>
                        </Col>
                    </Row>
                    {guidanceText && (
                        <Row className={guidanceTextBody}>
                            <Col>
                                <GuidanceText guidanceText={guidanceText} fieldName={fieldName} />
                            </Col>
                        </Row>
                    )}
                    <Row className={addressOrgDetailBody}>
                        <Col>
                            <input
                                type='hidden'
                                className='form-control'
                                name={`${fieldName}.addressId`}
                                {...register(`${fieldName}.addressId`)}
                                defaultValue={currItem?.addressId}
                            />
                            <input
                                type='hidden'
                                className='form-control'
                                name={`${fieldName}.country`}
                                {...register(`${fieldName}.country`)}
                                defaultValue={currItem?.country}
                            />
                            <input
                                type='hidden'
                                className='form-control'
                                name={`${fieldName}.addressType`}
                                {...register(`${fieldName}.addressType`)}
                                defaultValue={currItem?.addressType}
                            />
                            <input
                                type='hidden'
                                className='form-control'
                                name={`${fieldName}.latitude`}
                                {...register(`${fieldName}.latitude`)}
                                defaultValue='-1'
                            />
                            <input
                                type='hidden'
                                className='form-control'
                                name={`${fieldName}.longitude`}
                                {...register(`${fieldName}.longitude`)}
                                defaultValue='-1'
                            />
                            <LabelField id='findYourAddressLabel' displayName='Find your address:' value='' hideFinalEmptyRow={true} isMandatory={false} />
                            <div data-testid='googleAddressLookup' className={googleWrapper}>
                                <Wrapper
                                    apiKey={window.__RUNTIME_CONFIG__.REACT_APP_GOOGLE_API_KEY ?? ''}
                                    libraries={['places']}
                                    language='en'
                                    region='au'
                                    retries={3}
                                >
                                    <GooglePlacesAutocomplete
                                        selectProps={{
                                            placeValue,
                                            onChange: setPlaceValue,
                                            placeholder: PLACEHOLDER_ADDRESS_LOOKUP,
                                            isClearable: true,
                                            backspaceRemovesValue: true,
                                            isReadOnly,
                                        }}
                                        autocompletionRequest={{ componentRestrictions: { country: ['au'] } }}
                                    />
                                </Wrapper>
                            </div>
                            <LabelField
                                id='enterManuallyAddressLabel'
                                displayName='Or enter your address manually:'
                                hideFinalEmptyRow={true}
                                value=''
                                isMandatory={false}
                            />
                            <div className={manualAddressWrapper}>
                                <InputTextField
                                    id={`${fieldName}.streetAddress`}
                                    displayName={`${isPostalAddress ? 'Street address/PO Box number' : 'Street address'}`}
                                    defaultValue={currItem?.streetAddress}
                                    isReadOnly={isReadOnly}
                                    isMandatory={streetAddressMandatory}
                                    register={register}
                                    maxlength={150}
                                    errorsField={errorFieldStreetAddress}
                                    onKeyUp={onKeyUpStreetAddress} // must trap non-printable chars like backspace and delete key
                                />
                                <InputTextField
                                    id={`${fieldName}.town`}
                                    displayName='Suburb'
                                    defaultValue={currItem?.town}
                                    isReadOnly={isReadOnly}
                                    isMandatory={true}
                                    register={register}
                                    errorsField={errorFieldTown}
                                    onKeyPress={autocompleteWasOverridden}
                                />
                                {isReadOnly === true && (
                                    <input
                                        type='hidden'
                                        className='form-control'
                                        name={`${fieldName}.state`}
                                        {...register(`${fieldName}.state`)}
                                        defaultValue={`${fieldName}.state`}
                                    />
                                )}
                                <SelectListField
                                    id={`${fieldName}.state`}
                                    displayName='State'
                                    defaultValue={currItem?.state}
                                    disabled={isReadOnly}
                                    options={AustralianStatesList}
                                    isMandatory={true}
                                    register={register}
                                    errorsField={errorFieldState}
                                    handleOnChange={onChangeAddressState}
                                />
                                <InputTextField
                                    id={`${fieldName}.postcode`}
                                    displayName='Postcode'
                                    defaultValue={currItem?.postcode}
                                    isReadOnly={isReadOnly}
                                    isMandatory={true}
                                    register={register}
                                    errorsField={errorFieldPostcode}
                                    onKeyPress={autocompleteWasOverridden}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default AddressComponent;
