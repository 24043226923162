import React from 'react';
import { NavigationDestination } from '../../services/Enums';
import { initialWizardState, WizardState } from '../../components/wizard/wizardStateManager';
import { EditFormState, initialEditFormState } from '../../pages/manageDetails/EditFormStateManager';
import { WizardStepProps } from '../../components/wizard/wizardInterfaces';
import { UNINITIALISED } from '../../services/Constants';

export interface AppContextInterface {
    wizardSteps: WizardStepProps[];
    setWizardSteps: any;
    isUserInWizard: boolean;
    setIsUserInWizard: any;
    destinationNavActionOnSuccess: NavigationDestination | null;
    setDestinationNavActionOnSuccess: any;
    wizardState: WizardState;
    dispatchWizardState: any;
    applicationNumber: string;
    setApplicationNumber: any;
    editFormState: EditFormState;
    dispatchEditFormState: any;
    isUserInOutletEditForm: boolean;
    setIsUserInOutletEditForm: any;
    currentComplaintsMechId: string;
    setCurrentComplaintsMechId: any;
}

// Set Up The Initial Context
const AppContext: React.Context<AppContextInterface> = React.createContext<AppContextInterface>({
    wizardSteps: [],
    // tslint:disable-next-line: no-empty
    setWizardSteps: () => {},
    isUserInWizard: false,
    // tslint:disable-next-line: no-empty
    setIsUserInWizard: () => {},
    destinationNavActionOnSuccess: null,
    // tslint:disable-next-line: no-empty
    setDestinationNavActionOnSuccess: () => {},
    wizardState: initialWizardState,
    // tslint:disable-next-line: no-empty
    dispatchWizardState: () => {},
    applicationNumber: UNINITIALISED,
    // tslint:disable-next-line: no-empty
    setApplicationNumber: () => {},
    editFormState: initialEditFormState,
    // tslint:disable-next-line: no-empty
    dispatchEditFormState: () => {},
    isUserInOutletEditForm: false,
    // tslint:disable-next-line: no-empty
    setIsUserInOutletEditForm: () => {},
    currentComplaintsMechId: '',
    // tslint:disable-next-line: no-empty
    setCurrentComplaintsMechId: () => {},
});

export default AppContext;
