import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { IComplaintMechanism, Inquiry } from '../../../api/interfacesApi';
import AppContext from '../../../stateManagement/context/AppContext';
import { DisplayMechanismTypeInitialApplication, DisplayRelationshipToOrgType } from '../../../utils/EnumMappings';
import { formatDateForDisplay } from '../../../utils/Dates';
import { GetComplaintsEmployeeCollection, GetComplaintsMechanismName, GetComplaintsMembership } from '../../../utils/ComplaintsUtils';
import { AttachmentDocumentType, RelationshipToEmployerType, WizardMode, WizardStepNumber } from '../../../services/Enums';
import LabelField from '../../../components/fields/LabelField';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import FileUploadDisplay from './fileUploadDisplay';
import { EmptyRow } from 'controls/EmptyRow';

interface ComplaintsMechanismDisplayProps {
    inquiry: Inquiry;
    formatForPDF: boolean;
}

const ComplaintsMechanismDisplay = (props: ComplaintsMechanismDisplayProps) => {
    const { inquiry, formatForPDF } = props;

    const appContext = useContext(AppContext);
    const showLinkToPage = formatForPDF === true ? false : appContext.wizardState.wizardMode === WizardMode.New ? true : false;

    // get complaints collection
    const complaintsEmployee = GetComplaintsEmployeeCollection(inquiry.application.complaintsMechanism);
    const complaintsMembership = GetComplaintsMembership(inquiry.application.complaintsMechanism);

    // show employee govt funded details
    const employeeDetails = (complaint: IComplaintMechanism, inquiryIn: Inquiry) => {
        const orgName = GetComplaintsMechanismName(complaint) ?? '';
        const relationshipToOrg = DisplayRelationshipToOrgType(complaint?.relationshipToOrganisation ?? null);
        const docType =
            complaint.relationshipToOrganisation === RelationshipToEmployerType.OnPanel
                ? AttachmentDocumentType.OrgPanel
                : AttachmentDocumentType.OrgEmployment;
        // appears multiple does not display using the below technique, just show all of the correct type instead (only 1 CM record could exist at this ponin)
        // if the active attachment isnt of the correct type then blank it out (ie on final review UI this is common)
        // const activeAttachment = inquiryIn.application.attachments.find(a => a.attachmentId === complaint.activeAttachmentId);
        // const attachmentIdToShow = activeAttachment !== undefined && activeAttachment.documentType === docType ? complaint.activeAttachmentId : null;

        return (
            <>
                <Row>
                    <Col>
                        <LabelField
                            id='reviewOrganisationName'
                            displayName='Organisation name'
                            value={orgName}
                            isMandatory={false}
                            isTitleHtag={formatForPDF}
                        />
                    </Col>
                </Row>
                {formatForPDF === true ? <EmptyRow /> : null}
                <Row>
                    <Col>
                        <LabelField
                            id='reviewRelationshipToOrg'
                            displayName='Relationship to organisation'
                            value={relationshipToOrg}
                            isMandatory={false}
                            isTitleHtag={formatForPDF}
                        />
                    </Col>
                </Row>
                {formatForPDF === true ? <EmptyRow /> : null}
                <FileUploadDisplay
                    inquiry={inquiryIn}
                    // attachmentIdToShow={attachmentIdToShow}
                    showThisDocType={docType}
                    title='Evidence of employment of panel inclusion'
                    formatForPDF={formatForPDF}
                />
            </>
        );
    };

    // show membership details
    const membershipDetails = (complaint: IComplaintMechanism, inquiryIn: Inquiry) => {
        const professionalAssociation = complaint.professionalAssociation?.professionalAssociationId ? complaint.professionalAssociation?.name : '';

        return (
            <>
                <Row>
                    <Col>
                        <LabelField
                            id='reviewProfessionalAssociationName'
                            displayName='Name of professional association'
                            value={professionalAssociation}
                            isMandatory={false}
                            isTitleHtag={formatForPDF}
                        />
                    </Col>
                </Row>
                {formatForPDF === true ? <EmptyRow /> : null}
                <FileUploadDisplay
                    inquiry={inquiryIn}
                    showThisDocType={AttachmentDocumentType.AssociationMembership}
                    title='Professional association evidence'
                    formatForPDF={formatForPDF}
                />
                <Row>
                    <Col>
                        <LabelField
                            id='reviewMembershipStartDate'
                            displayName='Period of current membership - Start date'
                            value={formatDateForDisplay(complaint.membershipFromDate)}
                            isMandatory={false}
                            isTitleHtag={formatForPDF}
                        />
                    </Col>
                </Row>
                {formatForPDF === true ? <EmptyRow /> : null}
                <Row>
                    <Col>
                        <LabelField
                            id='reviewMembershipEndDate'
                            displayName='Period of current membership - End date'
                            value={formatDateForDisplay(complaint.membershipToDate)}
                            isMandatory={false}
                            isTitleHtag={formatForPDF}
                        />
                    </Col>
                </Row>
                {formatForPDF === true ? <EmptyRow /> : null}
            </>
        );
    };

    /*    
        <Row>
            <Col>
                <LabelField
                    id='reviewMembershipType'
                    displayName='Type of membership'
                    value={DisplayProfessionalMembershipType(complaint.professionalMembershipType)}
                    isMandatory={false}
                />
            </Col>
        </Row>
    */

    return (
        <>
            <PageFieldsTitle
                title='Complaints mechanism'
                showLinkToPage={showLinkToPage}
                wizardStepNumber={WizardStepNumber.ComplaintsMechanismType}
                smallerHeader={true}
                underlineTitle={formatForPDF}
            />
            <Row>
                <Col>
                    <LabelField
                        id='reviewTypeOfComplaintsMechanism'
                        displayName='Type of complaints mechanism'
                        value={DisplayMechanismTypeInitialApplication(inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType)}
                        isMandatory={false}
                        isTitleHtag={formatForPDF}
                    />
                </Col>
            </Row>
            {formatForPDF === true ? <EmptyRow /> : null}
            {complaintsEmployee &&
                complaintsEmployee.map((complaint: IComplaintMechanism) => {
                    // employee 1..5 items
                    return employeeDetails(complaint, inquiry);
                })}
            {complaintsMembership && membershipDetails(complaintsMembership, inquiry)}
        </>
    );
};

export default ComplaintsMechanismDisplay;
