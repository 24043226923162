import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { AddressTypeEnum } from '../services/Enums';
import { IAddress, Inquiry } from '../api/interfacesApi';
import LabelField from '../components/fields/LabelField';
import { EmptyRow } from '../controls/EmptyRow';
import style from './fieldLayout.module.scss';

const { altRow } = style;

interface PersonalDetailsContactDiffProps {
    inquiry: Inquiry;
}

// by this stage the FROM contains the originalVersion and the TO contains the differences objects
const PersonalDetailsContactDiff = (props: PersonalDetailsContactDiffProps) => {
    const { inquiry } = props;

    const numberOfAddresses = inquiry.differencesUser?.originalVersion?.addresses?.length;
    // take the number and create an array of indexes such as [0,1,2]
    const indexes = Array.from({ length: numberOfAddresses }, (_, i) => i);

    if (inquiry.differencesUser === undefined || inquiry.differencesUser === null) {
        return null;
    }

    return (
        <>
            {indexes.map(index => {
                const fieldNameOrig = `inquiry.differencesUser.originalVersion.addresses[${index}]`;
                const fieldNameDiff = `inquiry.differencesUser.differences`; // flat-structure for diffs // .addresses[${index}]`;
                // get the item using [index] as filter() returns an array of n items!
                const currItemOrig: IAddress = inquiry.differencesUser!.originalVersion.addresses[index];
                // const currItemDiff: IAddress =
                //    inquiry.differencesUser!.differences.addresses !== undefined ? inquiry.differencesUser!.differences.addresses[index] : null;
                const isPostalAddress = currItemOrig && currItemOrig.addressType && currItemOrig.addressType === AddressTypeEnum.Postal;

                return (
                    <fieldset name={fieldNameOrig} key={fieldNameOrig}>
                        <legend className='sr-only'>Postal address details</legend>
                        <EmptyRow />
                        <Row>
                            <Col>
                                <LabelField
                                    id={`${fieldNameOrig}.streetAddress.label`}
                                    displayName={`${isPostalAddress ? 'Street address/PO Box number' : 'Street address'}`}
                                    value=''
                                    isMandatory={false}
                                    hideFinalEmptyRow={true}
                                />
                            </Col>
                            <Col>
                                <LabelField
                                    id={`${fieldNameOrig}.streetAddress`}
                                    displayName=''
                                    value={currItemOrig.streetAddress}
                                    isMandatory={false}
                                    textCapitalizeOn={true}
                                    hideFinalEmptyRow={true}
                                />
                            </Col>
                            <Col>
                                <LabelField
                                    id={`${fieldNameDiff}.streetAddress`}
                                    displayName=''
                                    value={inquiry.differencesUser!.differences.streetAddress}
                                    isMandatory={false}
                                    textCapitalizeOn={true}
                                    hideFinalEmptyRow={true}
                                />
                            </Col>
                        </Row>
                        <Row className={altRow}>
                            <Col>
                                <LabelField id={`${fieldNameOrig}.town.label`} displayName='Suburb' value='' isMandatory={false} hideFinalEmptyRow={true} />
                            </Col>
                            <Col>
                                <LabelField
                                    id={`${fieldNameOrig}.town`}
                                    displayName=''
                                    value={currItemOrig.town}
                                    isMandatory={false}
                                    textCapitalizeOn={true}
                                    hideFinalEmptyRow={true}
                                />
                            </Col>
                            <Col>
                                <LabelField
                                    id={`${fieldNameDiff}.town`}
                                    displayName=''
                                    value={inquiry.differencesUser!.differences.town}
                                    isMandatory={false}
                                    textCapitalizeOn={true}
                                    hideFinalEmptyRow={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <LabelField id={`${fieldNameOrig}.state.label`} displayName='State' value='' isMandatory={false} hideFinalEmptyRow={true} />
                            </Col>
                            <Col>
                                <LabelField
                                    id={`${fieldNameOrig}.state`}
                                    displayName=''
                                    value={currItemOrig.state}
                                    isMandatory={false}
                                    hideFinalEmptyRow={true}
                                />
                            </Col>
                            <Col>
                                <LabelField
                                    id={`${fieldNameDiff}.state`}
                                    displayName=''
                                    value={inquiry.differencesUser!.differences.state}
                                    isMandatory={false}
                                    hideFinalEmptyRow={true}
                                />
                            </Col>
                        </Row>
                        <Row className={altRow}>
                            <Col>
                                <LabelField
                                    id={`${fieldNameOrig}.postcode.label`}
                                    displayName='Postcode'
                                    value=''
                                    isMandatory={false}
                                    hideFinalEmptyRow={true}
                                />
                            </Col>
                            <Col>
                                <LabelField
                                    id={`${fieldNameOrig}.postcode`}
                                    displayName=''
                                    value={currItemOrig.postcode}
                                    isMandatory={false}
                                    hideFinalEmptyRow={true}
                                />
                            </Col>
                            <Col>
                                <LabelField
                                    id={`${fieldNameDiff}.postcode`}
                                    displayName=''
                                    value={inquiry.differencesUser!.differences.postcode}
                                    isMandatory={false}
                                    hideFinalEmptyRow={true}
                                />
                            </Col>
                        </Row>
                    </fieldset>
                );
            })}
            <EmptyRow />
            <Row>
                <Col>
                    <LabelField
                        id='reviewPersonalContactSecondaryEmailAddressLabel'
                        displayName='Secondary email address'
                        value=''
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalContactSecondaryEmailAddressOrig'
                        displayName=''
                        value={inquiry.differencesUser.originalVersion.secondaryEmailAddress}
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalContactSecondaryEmailAddressDiff'
                        displayName=''
                        value={inquiry.differencesUser.differences.secondaryEmailAddress}
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                    />
                </Col>
            </Row>
            <Row className={altRow}>
                <Col>
                    <LabelField
                        id='reviewPersonalContactPrimaryPhoneNumberLabel'
                        displayName='Primary phone'
                        value=''
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalContactPrimaryPhoneNumberOrig'
                        displayName=''
                        value={inquiry.differencesUser.originalVersion.primaryPhoneNumber}
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalContactPrimaryPhoneNumberDiff'
                        displayName=''
                        value={inquiry.differencesUser.differences.primaryPhoneNumber}
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <LabelField
                        id='reviewPersonalContactSecondaryPhoneLabel'
                        displayName='Secondary phone'
                        value=''
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalContactSecondaryPhoneOrig'
                        displayName=''
                        value={inquiry.differencesUser.originalVersion.secondaryPhoneNumber}
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalContactSecondaryPhoneDiff'
                        displayName=''
                        value={inquiry.differencesUser.differences.secondaryPhoneNumber}
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                    />
                </Col>
            </Row>
            <EmptyRow />
        </>
    );
};

export default PersonalDetailsContactDiff;
