import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Inquiry } from '../../../api/interfacesApi';
import { AttachmentDocumentType, CompleteWorkingWithChildrenCheckType, WizardMode, WizardStepNumber } from '../../../services/Enums';
import { DisplayBool } from '../../../utils/Common';
import LabelField from '../../../components/fields/LabelField';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import FileUploadDisplay from './fileUploadDisplay';
import AppContext from '../../../stateManagement/context/AppContext';
import { EmptyRow } from 'controls/EmptyRow';

interface ChildRelatedDisplayProps {
    inquiry: Inquiry;
    formatForPDF: boolean;
}

const ChildRelatedDisplay = (props: ChildRelatedDisplayProps) => {
    const { inquiry, formatForPDF } = props;

    const appContext = useContext(AppContext);
    const showLinkToPage = formatForPDF === true ? false : appContext.wizardState.wizardMode === WizardMode.New ? true : false;

    const completeWorkingWithChildrenCheckValue =
        inquiry.application.completeWorkingWithChildrenCheck === null
            ? ''
            : inquiry.application.completeWorkingWithChildrenCheck === CompleteWorkingWithChildrenCheckType.Required
            ? 'Yes'
            : 'No';

    return (
        <>
            <PageFieldsTitle
                title='Child-related employment'
                showLinkToPage={showLinkToPage}
                wizardStepNumber={WizardStepNumber.ChildRelatedEmployment}
                smallerHeader={true}
                underlineTitle={formatForPDF}
            />
            <Row>
                <Col>
                    <LabelField
                        id='reviewIsProhibitedFromWorkingWithChildren'
                        displayName='Are you prohibited under a law of a State or Territory from working with children?'
                        value={DisplayBool(inquiry.application.isProhibitedFromWorkingWithChildren)}
                        isMandatory={false}
                        isTitleHtag={formatForPDF}
                    />
                </Col>
            </Row>
            {formatForPDF === true ? <EmptyRow /> : null}
            <Row>
                <Col>
                    <LabelField
                        id='reviewCompleteWorkingWithChildrenCheck'
                        displayName='For the type of FDR service you are offering, are you required to complete a child-related employment check?'
                        value={completeWorkingWithChildrenCheckValue}
                        isMandatory={false}
                        isTitleHtag={formatForPDF}
                    />
                </Col>
            </Row>
            {completeWorkingWithChildrenCheckValue === 'No' && (
                <Row>
                    <Col>
                        <LabelField
                            id='completeWorkingWithChildrenCheckValueNoLabelText'
                            displayName='I have reviewed the relevant legislation and have determined I do not require a working with children check or equivalent'
                            value=''
                            isMandatory={false}
                        />
                    </Col>
                </Row>
            )}
            {formatForPDF === true ? <EmptyRow /> : null}
            <FileUploadDisplay
                inquiry={inquiry}
                showThisDocType={AttachmentDocumentType.ChildRelatedEmploymentCheck}
                title='Working With Children (or equivalent) check/s'
                formatForPDF={formatForPDF}
            />
        </>
    );
};

export default ChildRelatedDisplay;
