import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
// tslint:disable-next-line: no-submodule-imports
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { IApplication, Inquiry, InquiryDefaultObj, IUser } from '../../../../api/interfacesApi';
import { defaultComplaintsMechanismData } from '../../../../api/defaultData';
import AppContext, { AppContextInterface } from '../../../../stateManagement/context/AppContext';
import { AjaxCallStateForPage } from '../../../PageInterfaces';
import AuthService from '../../../../services/AuthService';
import { AjaxCallStateEnum, ComplaintsMechanismType, RelationshipToEmployerType, WizardStepNumberComplaintsMech } from '../../../../services/Enums';
import {
    validationSchemaComplaintsMechanismEmployee,
    validationSchemaComplaintsMechanismMembership,
    validationSchemaComplaintsMechanismOutletType,
    validationSchemaProfessionalIndemnityEditCMPageInsuranceNotRequired,
    validationSchemaProfessionalIndemnityEditCMPageInsuranceIsRequired,
} from '../../../../services/Validation';

import * as types from '../../../../actions/actionTypes';
import UserActions from '../../../../actions/userActions';
import ApplicationActions from '../../../../actions/applicationActions';
import {
    ActivateChosenComplaintsMechanism,
    CopyFromComplaintCollectionToActive,
    CopyFromComplaintToActive,
    FindIndexOfComplaint,
} from '../../../../utils/ComplaintsUtils';
import { ApplicationIsLoaded, UserIsLoaded } from '../../../../utils/AppUtils';
import ComplaintsMechanismForm from '../complaintsMechanismForm';
import ComplaintsMechanismTypePage from '../pages/complaintsMechanismTypePage';
import ComplaintsMechanismDetailsPage from '../pages/complaintsMechanismDetailsPage';
import ComplaintsMechanismInsurancePage from '../pages/complaintsMechanismInsurancePage';

import WizardMenuContainer from '../../../../components/site/WizardMenuContainer';
import { WizardStepCurrentStateEnum } from '../../../../components/wizard/wizardEnums';
import { MarkStepIncompleteAndUpdateStatus, MarkStepOriginalStatus, ShowOrHidePage } from '../../../../components/wizard/wizardStepFunctions';
import { SetIsUserInWizardFlag, SetValidationSchema, WizardStateActionTypeEnums } from '../../../../components/wizard/wizardStateManager';
import Loader from '../../../../controls/Loader';
import { UNINITIALISED } from '../../../../services/Constants';
import { Sleep } from '../../../../utils/Common';
import { JumpToWizardStep } from '../../../../pages/PageFunctions';
import styles from './ComplaintsMechanismWizard.module.scss';

const { pageDetails } = styles;

export const GetValidationSchemaForCurrentComplaintsMechPage = (currentStepNumber: number, inquiry: Inquiry) => {
    switch (currentStepNumber) {
        case WizardStepNumberComplaintsMech.ComplaintsMechanismType:
            return validationSchemaComplaintsMechanismOutletType;
        case WizardStepNumberComplaintsMech.ComplaintsMechanismDetails:
            const isComplaintsTypeFull =
                inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc;

            if (isComplaintsTypeFull === true) {
                return validationSchemaComplaintsMechanismMembership;
            } else {
                return validationSchemaComplaintsMechanismEmployee;
            }
        case WizardStepNumberComplaintsMech.ComplaintsMechanismInsurance:
            // there is nothing to validate if relationship is Employee and type is GovtFunded
            const noInsuranceNeeded =
                inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType === ComplaintsMechanismType.GovernmentFundedFDRService &&
                inquiry.application.activeComplaintsMechanismObj?.relationshipToOrganisation === RelationshipToEmployerType.Employee;

            return noInsuranceNeeded === true
                ? validationSchemaProfessionalIndemnityEditCMPageInsuranceNotRequired
                : validationSchemaProfessionalIndemnityEditCMPageInsuranceIsRequired;
        default:
            return undefined;
    }
};

interface ComplaintsMechanismWizardProps {
    authService: AuthService;
    addNewComplaint: boolean;
    resumeAddingNew?: boolean;
    skipToStepNumber?: number;
}

const ComplaintsMechanismWizard = (props: ComplaintsMechanismWizardProps): JSX.Element => {
    const { authService, addNewComplaint, resumeAddingNew, skipToStepNumber } = props;

    // const history = useHistory();
    const appContext = useContext<AppContextInterface>(AppContext);
    const dispatchWizardState = appContext.dispatchWizardState;
    const wizardState = appContext.wizardState;
    // store the form in state as we update the steps constantly after user actions
    const applicationForm = useRef(new ComplaintsMechanismForm());
    const userActions = useMemo(() => {
        return new UserActions(authService);
    }, [authService]);
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);

    const user: IUser = useSelector((stateUser: any) => stateUser.user);
    const application: IApplication = useSelector((stateApp: any) => stateApp.application);
    // const attachmentsTypeArray: IAttachmentTypeArray = useSelector((stateAttachmentTypeArray: any) => stateAttachmentTypeArray.attachmentsTypeArray);
    const ajaxCallsInProgress: types.ICallStatus = useSelector((stateAjax: any) => stateAjax.ajaxCallsInProgress);
    const initCallStateForPage: AjaxCallStateForPage = { ajaxCallState: AjaxCallStateEnum.NotStarted, pageNumber: 0 };
    const [ajaxCallStateForPage, setAjaxCallStateForPage] = useState(initCallStateForPage);
    const [backEndValidationErrors, setBackEndValidationErrors] = useState([]);
    const [inquiryDefObj, setInquiry] = useState<InquiryDefaultObj>({ ...applicationForm.current.properties.InitialData });
    const dispatch = useDispatch();
    const [isAttemptingToLoadApp, setIsAttemptingtoLoadApp] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [triggerChildFormDiscardChanges, setTriggerChildFormDiscardChanges] = useState(0);

    // used by children to set this object at parent-level
    const setInquiryObj = (inquiry: Inquiry) => {
        inquiryDefObj.inquiry = inquiry;
        setInquiry(inquiryDefObj);
    };

    const loadAllData = () => {
        // get latest user and app details so we can map them to our inquiry object upon load of this page
        setIsAttemptingtoLoadApp(true);

        dispatch(userActions.GetUser());

        const doNotCopyActiveComplaint = true;
        dispatch(appActions.GetApplication(doNotCopyActiveComplaint));
    };
    /*
    // reset to false
    useEffect(() => {
        setActivatedCurrentComplaintMechanism(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); */

    // set the overall application flag to indicate user is in wizard
    useEffect(() => {
        SetIsUserInWizardFlag(appContext);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadAllData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isAttemptingToLoadApp === true && isDataLoaded === false && ApplicationIsLoaded(application, application.applicationId)) {
            if (application.applicationId !== inquiryDefObj.inquiry.application.applicationId) {
                // done below now
                // inquiryDefObj.inquiry.application = application;
                // setInquiry(inquiryDefObj);
                // reset would make the values update for the useForm functionality
                // reset(inquiryDefObj);
            } else {
                // MUST set activeCM again here unless an Add only
                if (addNewComplaint === true && resumeAddingNew === false) {
                    // do nothing
                } else {
                    const indexOfCMid = FindIndexOfComplaint(appContext.currentComplaintsMechId, application);
                    const activeCM = application.complaintsMechanism[indexOfCMid];

                    if (activeCM.complaintsMechanismId !== UNINITIALISED) {
                        CopyFromComplaintToActive(application, activeCM);

                        // done below now
                        // // finally update inquiry minus any attachments and changes then go backwards
                        // inquiryDefObj.inquiry.application = application;
                        // setInquiry(inquiryDefObj); // TODO: Check CM and activeCM within this app
                        // setValue('inquiry', inquiryDefObj.inquiry); // weld it to the form
                    }
                }
            }

            // must set this here:
            inquiryDefObj.inquiry.application = application;

            if (addNewComplaint === true && resumeAddingNew === false) {
                // ActivateEmptyComplaintsMechanism(appContext, inquiryDefObj.inquiry.application);
                // simply set an empty CM as the activeCM
                inquiryDefObj.inquiry.application.activeComplaintsMechanismObj = { ...defaultComplaintsMechanismData };
                appContext.setCurrentComplaintsMechId = UNINITIALISED;
            } else {
                if (appContext.currentComplaintsMechId === UNINITIALISED) {
                    // need to initialise to 0-th index in collection as we get API reverse-sorted by most-recent in collection
                    const updatedCMid = inquiryDefObj.inquiry.application.complaintsMechanism[0].complaintsMechanismId;
                    appContext.setCurrentComplaintsMechId(updatedCMid);
                    CopyFromComplaintCollectionToActive(inquiryDefObj.inquiry.application, updatedCMid);
                } else {
                    ActivateChosenComplaintsMechanism(appContext, inquiryDefObj.inquiry.application, appContext.currentComplaintsMechId);
                }
            }

            setInquiry(inquiryDefObj);

            // reset would make the values update for the useForm functionality
            reset(inquiryDefObj);
            // UNSURE IF NEEDED: setValue('inquiry', inquiryDefObj.inquiry); // weld it to the form
            setIsDataLoaded(true);
            setIsAttemptingtoLoadApp(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application]);

    useEffect(() => {
        if (UserIsLoaded(user) && user.id !== inquiryDefObj.inquiry.personalDetails.id) {
            inquiryDefObj.inquiry.personalDetails = user;
            setInquiry(inquiryDefObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (ajaxCallsInProgress && ajaxCallsInProgress.callState) {
            setAjaxCallStateForPage({ ajaxCallState: ajaxCallsInProgress.callState, pageNumber: wizardState.formStep });
        }
        if (ajaxCallsInProgress && ajaxCallsInProgress.callState === AjaxCallStateEnum.CompletedWithValidation) {
            setBackEndValidationErrors(ajaxCallsInProgress.errors);
        }
        // TODO: set Errors here:
    }, [ajaxCallsInProgress, wizardState.formStep]);

    const setTriggerSubmit = (triggerSubmit: boolean) => {
        if (triggerSubmit) {
            dispatchWizardState({ type: WizardStateActionTypeEnums.setTriggerSubmit });
        } else {
            dispatchWizardState({ type: WizardStateActionTypeEnums.unsetTriggerSubmit });
        }
    };

    // each step change change validation
    useEffect(() => {
        SetValidationSchema(appContext, GetValidationSchemaForCurrentComplaintsMechPage(wizardState.formStep, inquiryDefObj.inquiry));
        // MUST re-run trigger to trip validation ifuser moves back and forth, for example
        if (wizardState?.goingBackwards === true) {
            Sleep(500).then(() => {
                trigger();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wizardState.formStep, wizardState.validationSchema]);

    const validationSchema = wizardState.validationSchema ? yupResolver(wizardState.validationSchema) : undefined;

    // watch lets us keep track of state to keep an eye on it
    // formState lets us validate
    const {
        watch,
        register,
        unregister,
        reset,
        trigger,
        getValues,
        setValue,
        setError,
        control,
        handleSubmit,
        formState: { errors, isValid, isDirty, dirtyFields, touchedFields },
        clearErrors,
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        // [
        defaultValues: useMemo(() => {
            return inquiryDefObj;
        }, [inquiryDefObj]),
        // useMemo(() => {
        // return inquiry;
        // }, [inquiry]),
        resolver: useMemo(() => {
            return validationSchema;
        }, [validationSchema]),
    }); // mode:all is to validate after every keypress

    const setCurrentWizardStepToInvalid = () => {
        if (appContext && appContext.wizardSteps && appContext.wizardSteps.length > 0 && appContext.wizardState.formStep > -1) {
            const currIndex = appContext.wizardState.formStep;
            // this was triggered by invalidation of form (ie the user!)
            MarkStepIncompleteAndUpdateStatus(appContext, isValid, currIndex);
        }
    };

    const setCurrentWizardStepToOriginalState = () => {
        if (appContext && appContext.wizardSteps && appContext.wizardSteps.length > 0 && appContext.wizardState.formStep > -1) {
            const currIndex = appContext.wizardState.formStep;
            // only set to orig if not already completed
            if (
                appContext.wizardSteps[currIndex].currentState !== WizardStepCurrentStateEnum.completed ||
                appContext.wizardSteps[currIndex].currentState !== WizardStepCurrentStateEnum.substepcompleted
            ) {
                MarkStepOriginalStatus(appContext, isValid, currIndex);
            }
        }
    };

    useEffect(() => {
        // if form is now invalid we need to mark its step incomplete
        if (!isValid) {
            setCurrentWizardStepToInvalid();
        } else {
            // if form is now valid we need to mark the step back to its original state
            setCurrentWizardStepToOriginalState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    const isActive = false; // default all to inactive and activate them later

    const pageProps = {
        // application,
        // user,
        addNewComplaint,
        resumeAddingNew,
        inquiry: inquiryDefObj.inquiry,
        setInquiryObj,
        attachments: inquiryDefObj.inquiry?.application?.attachments,
        goingBackwards: wizardState?.goingBackwards,
        authService,
        dispatch,
        // handleClickPrevious,
        handleSubmit,
        register,
        unregister,
        trigger,
        setValue,
        getValues,
        setError,
        watch,
        clearErrors,
        control,
        errors,
        isValid,
        isActive,
        isDirty,
        reset,
        dirtyFields,
        touchedFields,
        ajaxCallStateForPage,
        backEndValidationErrors,
        triggerSubmit: wizardState.triggerSubmit,
        setTriggerSubmit, // use dispatcher now
        triggerChildFormDiscardChanges,
    };

    // init/update these as we hand them around for mobile/header view
    useEffect(() => {
        if (appContext.wizardSteps === undefined || (appContext.wizardSteps && appContext.wizardSteps.length === 0)) {
            appContext?.setWizardSteps(applicationForm.current.properties.WizardSteps);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // await for wizardSteps to load
        const isWizardStepsLoaded = (appContext.wizardSteps === undefined || (appContext.wizardSteps && appContext.wizardSteps.length === 0)) === false;

        if (isWizardStepsLoaded === true && skipToStepNumber !== undefined) {
            Sleep(2000).then(() => {
                if (skipToStepNumber === 2) {
                    JumpToWizardStep(appContext.wizardState.formStep, WizardStepNumberComplaintsMech.ComplaintsMechanismDetails, appContext, true);
                }
                if (skipToStepNumber === 3) {
                    JumpToWizardStep(appContext.wizardState.formStep, WizardStepNumberComplaintsMech.ComplaintsMechanismInsurance, appContext, true);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appContext.wizardSteps]);

    const callChildForDiscardChanges = () => {
        // call child form to deal with it so we get consistency with Back button (where this is from link Back click)
        setTriggerChildFormDiscardChanges(counter => counter + 1);
    };

    const EmitPage = (currentFormStep: number, formStepToCheck: number, PageComponent: any) => {
        // we use the formStep and only hide a page so that we can keep the values
        // for all forms in the "register" object for of the react-hook-form
        return (
            <>
                {currentFormStep >= formStepToCheck && (
                    <div className={`${ShowOrHidePage(currentFormStep, formStepToCheck)} ${pageDetails}`}>
                        <PageComponent
                            {...pageProps}
                            isActive={currentFormStep === formStepToCheck}
                            triggerSubmit={wizardState.triggerSubmit}
                            hideBackButton={(skipToStepNumber ?? 0) > 0}
                        />
                    </div>
                )}
            </>
        );
    };

    if (isDataLoaded === false) {
        return <Loader isLoading={true} loaderText='Loading, please wait...' />;
    }

    const showLoaderDueToSkipStep =
        (skipToStepNumber === 2 &&
            appContext.wizardState.formStep !== WizardStepNumberComplaintsMech.ComplaintsMechanismDetails &&
            skipToStepNumber === 2 &&
            appContext.wizardState.formStep !== WizardStepNumberComplaintsMech.ComplaintsMechanismInsurance) ||
        (skipToStepNumber === 3 && appContext.wizardState.formStep !== WizardStepNumberComplaintsMech.ComplaintsMechanismInsurance);

    return (
        <div className='container-fluid'>
            <div className='row pb-5 ml-2'>
                <WizardMenuContainer
                    appContext={appContext}
                    showBackToManageComplaints={true}
                    throwEventToChildForm={true}
                    callChildFormEvent={callChildForDiscardChanges}
                />
                {showLoaderDueToSkipStep === true && <Loader isLoading={true} loaderText='Please wait while we load your data...' />}
                {EmitPage(wizardState.formStep, WizardStepNumberComplaintsMech.ComplaintsMechanismType, ComplaintsMechanismTypePage)}
                {EmitPage(wizardState.formStep, WizardStepNumberComplaintsMech.ComplaintsMechanismDetails, ComplaintsMechanismDetailsPage)}
                {EmitPage(wizardState.formStep, WizardStepNumberComplaintsMech.ComplaintsMechanismInsurance, ComplaintsMechanismInsurancePage)}
                <hr />
            </div>
        </div>
    );
};

// to see the form values put this immediately before the </form> closing tag:
// <pre>{JSON.stringify(watch(), null, 2)}</pre>
// shows the full inquiry object due to watch(), place this after that last EmitPage:
// <CollapseContent id='DevDataOutput' headerText='Dev-Data-Output' content={JSON.stringify(watch(), null, 2)} />

export default ComplaintsMechanismWizard;

/*
                <pre>{JSON.stringify(touchedFields, null, 2)}</pre>
                <pre>{JSON.stringify(dirtyFields, null, 2)}</pre>
                <pre>{JSON.stringify(inquiryDefObj.inquiry.application.activeComplaintsMechanismObj, null, 2)}</pre>

                <ModalDialog
                    dataTestId='DiscardChangesDialog'
                    modalTitle={MODALHEADER_UNSAVEDCHANGES_DATALOSSPOTENTIAL}
                    modalBodyText={MODALTEXT_UNSAVEDCHANGES_DATALOSSPOTENTIAL}
                    showMe={wizardState.showDiscardChangesDialog}
                    handleClickNo={closeDiscardChangesDialog}
                    handleClickYes={discardChanges}
                    confirmLabel={MODALBUTTON_YES_DISCARDCHANGES}
                    abortLabel={MODALBUTTON_NO_DISCARDCHANGES}
                    isDestructive={true}
                />
*/
