import * as React from 'react';
import BackGroundImage from '../backgroundImage.png';
import styles from '../components/site/site.module.scss';
import AppContext from '../stateManagement/context/AppContext';
import BackToDashboardPillButton from '../controls/BackToDashboardPillButton';

const { disclaimerContent } = styles;

function Disclaimer() {
    const appContext = React.useContext(AppContext);

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 p-0'>
                    <div
                        className='jumbotron jumbotron-fluid bg-info d-flex flex-column justify-content-center d-none d-sm-none d-md-block'
                        style={{ backgroundImage: `url("${BackGroundImage}")`, backgroundSize: 'cover', marginBottom: '-1px' }}
                    >
                        <div className={`${disclaimerContent} w-50 mx-auto`}>
                            <h1 className='display-3 font-weight-bold mb-0'>Disclaimer</h1>
                            <h2 className='mb-4 mt-3'>Family Dispute Resolution Register</h2>
                            <p>
                                This website is presented by the Commonwealth for the purpose of disseminating information free of charge for the benefit of the
                                public.
                            </p>
                            <p>
                                The Commonwealth monitors the quality of the information available on this website and updates the information regularly.
                                However, the Commonwealth does not guarantee, and accepts no legal liability whatsoever arising from or connected to, the
                                accuracy, reliability, currency or completeness of any material contained on this website or on any linked site.
                            </p>
                            <p>
                                The Commonwealth recommends that users exercise their own skill and care with respect to their use of this website and that
                                users carefully evaluate the accuracy, currency, completeness and relevance of the material on the website for their purposes.
                            </p>
                            <p>
                                This website is not a substitute for independent professional advice and users should obtain any appropriate professional advice
                                relevant to their particular circumstances.
                            </p>
                            <h3 className='mb-4 mt-5'>Links to External Websites</h3>
                            <p>
                                This website may contain links to other websites that are external to the site. This site takes reasonable care in linking
                                websites but has no direct control over the content of the linked sites, or the changes that may occur to the content on those
                                sites. It is the responsibility of the user to make their own decisions about the accuracy, currency, reliability and
                                correctness of information contained in linked external websites.
                            </p>
                            <p>
                                Links to external websites do not constitute an endorsement or a recommendation of any material on those sites or of any third
                                party products or services offered by, from or through those sites. Users of links provided by this website are responsible for
                                being aware of which organisation is hosting the website they visit.
                            </p>
                            <h3 className='mb-4 mt-5'>Security of the Site</h3>
                            <p>
                                This site applies a range of security controls to protect its website from unauthorised access. However, users should be aware
                                that the World Wide Web is an insecure public network that gives rise to a potential risk that a user's transactions are being
                                viewed, intercepted or modified by third parties or that files which the user downloads may contain computer viruses, disabling
                                codes, worms or other devices or defects.
                            </p>
                            <p>
                                The Commonwealth accepts no liability for any interference with or damage to a user's computer system, software or data
                                occurring in connection with or relating to this website or its use. Users are encouraged to take appropriate and adequate
                                precautions to ensure that whatever is selected from this site is free of viruses or other contamination that may interfere with
                                or damage the user's computer system, software or data.
                            </p>
                            <BackToDashboardPillButton appContext={appContext} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Disclaimer;
