import * as React from 'react';
import { AccreditationStatusEnum } from '../../services/Enums';
import {
    LINK_AG_BECOMINGPRACTITIONER,
    LINK_AG_FAMILYLAW_ACT1975,
    LINK_AG_FAMILYLAW_REGULATIONS2008,
    LINK_AG_INFOPRACTITIONER,
    LINK_FAMILYLAW_TERMFINDER,
    LINK_FAMILY_RELATIONSHIPS,
    LINK_FAMILY_RELATIONSHIPS_ADVICELINE,
    LINK_FAMMILYCOURT_WA,
    LINK_FEDCIRCUIT_FAMILYCOURT,
    LINK_TRANSLATION_SERVICE,
} from '../../services/Constants';
import NavLink from '../../controls/NavLink';

interface DashboardLinksMenuProps {
    accreditationStatus: AccreditationStatusEnum | null;
}

const DashboardLinksMenu = (props: DashboardLinksMenuProps) => {
    const { accreditationStatus } = props;

    const NavLinkListItem = (displayText: string, hrefLink: string) => {
        return (
            <li role='menuitem' className={`list-group-item d-flex justify-content-between align-items-flex-start`}>
                <NavLink displayText={displayText} hrefLink={hrefLink} openInNewTab={true} />
            </li>
        );
    };

    return (
        <div className='container'>
            <h2>Useful links</h2>
            <div data-testid='dashboardUsefulLinks'>
                {accreditationStatus &&
                    (accreditationStatus === AccreditationStatusEnum.New ||
                        accreditationStatus === AccreditationStatusEnum.Pending ||
                        accreditationStatus === AccreditationStatusEnum.Cancelled) && (
                        <ul data-testid='dashboardUsefulLinksBecomingPractitioner' className='list-group list-group-flush'>
                            {NavLinkListItem('Becoming an FDR practitioner', LINK_AG_BECOMINGPRACTITIONER)}
                        </ul>
                    )}
                {accreditationStatus &&
                    (accreditationStatus === AccreditationStatusEnum.Accredited ||
                        accreditationStatus === AccreditationStatusEnum.ConditionalAccreditation ||
                        accreditationStatus === AccreditationStatusEnum.Suspended) && (
                        <ul className='list-group list-group-flush'>{NavLinkListItem('Information for FDR practitioners', LINK_AG_INFOPRACTITIONER)}</ul>
                    )}
                <h3>Legislation</h3>
                <ul data-testid='dashboardUsefulLinksLegislation' className='list-group list-group-flush'>
                    {NavLinkListItem('Family Law (Family Dispute Resolution Practitioners) Regulations 2008', LINK_AG_FAMILYLAW_REGULATIONS2008)}
                    {NavLinkListItem('Family Law Act 1975', LINK_AG_FAMILYLAW_ACT1975)}
                </ul>
                <h3>Resources</h3>
                <ul className='list-group list-group-flush'>
                    {NavLinkListItem('Family Relationships Online', LINK_FAMILY_RELATIONSHIPS)}
                    {NavLinkListItem('Family Relationship Advice Line', LINK_FAMILY_RELATIONSHIPS_ADVICELINE)}
                    {NavLinkListItem('Family Law TermFinder', LINK_FAMILYLAW_TERMFINDER)}
                    {NavLinkListItem('View the FDR Register', window.__RUNTIME_CONFIG__.REACT_APP_REGISTER_URL ?? 'https://fdrr.ag.gov.au/')}
                    {NavLinkListItem('Translating and Interpreting Service', LINK_TRANSLATION_SERVICE)}
                </ul>
                <h3>Courts</h3>
                <ul className='list-group list-group-flush'>
                    {NavLinkListItem('Federal Circuit and Family Court of Australia', LINK_FEDCIRCUIT_FAMILYCOURT)}
                    {NavLinkListItem('Family Court of Western Australia', LINK_FAMMILYCOURT_WA)}
                </ul>
            </div>
        </div>
    );
};

export default DashboardLinksMenu;
