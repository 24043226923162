import * as types from './actionTypes';

export function beginAjaxCall() {
    return {
        type: types.BEGIN_AJAX_CALL,
        loading: true,
    };
}

export function endAjaxCall() {
    return {
        type: types.END_AJAX_CALL,
        loading: false,
    };
}

export function ajaxCallError(err: any) {
    // caller must be responsible to show error: if (err.message) toastErrorMessage(err.message);
    return {
        type: types.AJAX_CALL_ERROR,
        payload: err,
        loading: false,
    };
}
