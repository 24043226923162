import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import { ErrorBoundary } from 'react-error-boundary';
import * as serviceWorker from './serviceWorker';
import App from './App';
import configureStore from './stateManagement/store/configureStore';
import AuthService from './services/AuthService';
import { ErrorFallback } from './utils/AppUtils';
import './index.css';

/* function areWeTestingWithJest() {
    return process.env.JEST_WORKER_ID !== undefined;
} */

const store = configureStore();

const authService = new AuthService();

// google analytics for page changes only captured in PreProd or Production
const env = window.__RUNTIME_CONFIG__.REACT_APP_TARGET_ENVIRONMENT;

if (env === 'Pre' || env === 'Production') {
    const trackingId = window.__RUNTIME_CONFIG__.REACT_APP_GOOGLE_ANALYTICS_KEY;
    ReactGA.initialize(trackingId);
}

authService
    .HandlePageLoadEvent()
    .then(() => {
        if (!authService.account) {
            authService.SignIn();
        } else {
            ReactDOM.render(
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <React.StrictMode>
                        <Provider store={store}>
                            <App authService={authService} />
                        </Provider>
                    </React.StrictMode>
                </ErrorBoundary>,
                document.getElementById('root'),
            );
        }
    })
    .catch(error => {
        // tslint:disable-next-line: no-console
        console.log(error);

        ReactDOM.render(ErrorFallback({ error }), document.getElementById('root'));
    });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
