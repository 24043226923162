// Child-related employment page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the Competencies and qualifications page
//  - allows navigation forward to the Criminal convictions page
import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { get } from 'react-hook-form';
import { RegoApplicationPageProps } from '../../PageInterfaces';
import { EnsureData } from '../RegistrationApplicationWizard/wizardHelperFunctions';
import { GetTextForSaveAndContinueButton, HideTheBackButton, SubmitThisPageIfActive } from '../../PageFunctions';
import { MoveWizardForward, MoveWizardForwardForResubmit } from '../../../components/wizard/wizardStepFunctions';
import { ParseBool, Sleep } from '../../../utils/Common';
import { GUIDANCETEXT_INFOCHILDRELATED, HEADER_CHILDRELATED } from '../../../services/Constants';
import {
    AjaxCallStateEnum,
    ApplicationStatusEnum,
    AttachmentDocumentType,
    CompleteWorkingWithChildrenCheckType,
    WizardMode,
    WizardStepNumber,
} from '../../../services/Enums';
import {
    VALIDATION_ERROR_POPUP_MESSAGE,
    VALIDATION_ERROR_UNKNOWN_MESSAGE,
    VALIDATION_PROMPT_CHILDRELATEDPROHIBITED_AWAY_MESSAGE,
    VALIDATION_PROMPT_CHILDRELATEDPROHIBITED_AWAY_MESSAGE_P2,
    VALIDATION_PROMPT_CHILDRELATEDPROHIBITED_AWAY_TITLE,
} from '../../../services/Validation';
import { toastErrorMessage } from '../../../actions/toastrMessages';
import ApplicationActions from '../../../actions/applicationActions';
import AttachmentActions from '../../../actions/attachmentsActions';
import ErrorSummary from '../../../controls/ErrorSummary';
import ModalDialog from '../../../controls/ModalDialog';
import AppContext from '../../../stateManagement/context/AppContext';
import PageWrapper from '../../../components/pageWrapper';
import PageTitle from '../../../components/pageTitle';
import PageMandatoryLabelText from '../../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import LabelField from '../../../components/fields/LabelField';
import FileUploadField from '../../../components/fields/FileUploadField';
import RadioButtonGroupField, { RadioButtonOption } from '../../../components/fields/RadioButtonGroupField';
import CheckBoxGroupField, { CheckBoxOption } from '../../../components/fields/CheckBoxGroupField';
import styles from '../../Pages.module.scss';
import { ErrorOnSubmitForm } from '../../../utils/AppUtils';
import { InsertAttachmentToLocalInquiryObject, RemoveAttachmentFromLocalInquiryObject } from '../../../utils/ComplaintsUtils';
import { IAttachmentMetadata } from '../../../api/interfacesApi';

const { childItems, controlMaxWidth } = styles;

const ChildRelatedEmploymentPage = (props: RegoApplicationPageProps) => {
    const {
        inquiry,
        authService,
        goingBackwards,
        dispatch,
        handleClickPrevious,
        handleSubmit,
        clearErrors,
        register,
        // unregister,
        trigger,
        setError,
        setValue,
        watch,
        control,
        errors,
        isValid,
        isActive,
        ajaxCallStateForPage,
        backEndValidationErrors,
        triggerSubmit,
        setTriggerSubmit,
    } = props;

    const appContext = useContext(AppContext);
    const disableField = appContext.wizardState.wizardMode === WizardMode.Resubmit ? true : false;
    const formRef = useRef();
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const attachmentActions = useMemo(() => {
        return new AttachmentActions(authService);
    }, [authService]);
    // even though we have redux states, we HAVE to capture the attempt due to navigation and Get and Save user calls on the page
    const [attemptingSave, setAttemptingSave] = useState(false);
    const [showWorkingWithChildrenFileUpload, setShowWorkingWithChildrenFileUpload] = useState(false);
    const [showWorkingWithChildrenCheckBox, setShowWorkingWithChildrenCheckBox] = useState(false);
    const [filesExist, setFilesExist] = useState(false);
    const [showFailToSaveDialog, setShowFailToSaveDialog] = useState(false);

    const completeWorkingWithChildrenCheckWatched: CompleteWorkingWithChildrenCheckType = watch(
        'inquiry.application.completeWorkingWithChildrenCheck',
        inquiry?.application.completeWorkingWithChildrenCheck,
    );

    const isWorkingWithChildrenCheckNotApplicable = watch(
        'inquiry.application.isWorkingWithChildrenCheckNotApplicable',
        inquiry?.application.isWorkingWithChildrenCheckNotApplicable,
    );

    const isProhibitedFromWorkingWithChildren: boolean = watch(
        'inquiry.application.isProhibitedFromWorkingWithChildren',
        inquiry?.application.isProhibitedFromWorkingWithChildren,
    );

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors.errors?.length > 0) {
            backEndValidationErrors.errors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'CompleteWorkingWithChildrenCheck':
                        setError('inquiry.application.completeworkingwithchildrencheck', { type: 'manual', message: validationError.errorMessage });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    const redirectToDashboard = () => {
        setShowFailToSaveDialog(false);
        window.location.href = '/';
    };

    // check dispatch success state
    useEffect(() => {
        if (isActive === true && ajaxCallStateForPage && ajaxCallStateForPage.pageNumber === WizardStepNumber.ChildRelatedEmployment) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                setAttemptingSave(false);
                displayBackEndValidations();
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setAttemptingSave(false);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (attemptingSave === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK && !appContext.wizardState.triggerSubmit) {
                    setAttemptingSave(false);

                    // if user has selected YES to the question on being prohibited, we pop up a dialog and return them to the Dashboard
                    if (ParseBool(isProhibitedFromWorkingWithChildren) === true) {
                        setShowFailToSaveDialog(true);
                    } else {
                        // IF VALID (or OK to move forward):
                        // refresh data set now after changes saved
                        dispatch(appActions.GetApplication());

                        // Move wizard forward to next step pending whether we are navigating normally or for the resubmit flow
                        if (appContext.wizardState.wizardMode === WizardMode.New) {
                            MoveWizardForward(appContext, isValid);
                        } else {
                            MoveWizardForwardForResubmit(appContext, isValid);
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const submitForm = (data: any) => {
        if (isValid) {
            // check if valid and selection of Yes to question 'type of FDR service' then we need to also check files here
            // if (data.inquiry.application.completeWorkingWithChildrenCheck.toString() === CompleteWorkingWithChildrenCheckType.Required && !filesExist) {
            //    setFileUploadValidationError();
            // } else {
            // valid, process save

            // set existing data now as we dont capture it in this page
            EnsureData(appContext, WizardStepNumber.ChildRelatedEmployment, data, inquiry);

            // Save changes to application (attempt save, if that fails we do NOT move the Wizard step)
            setAttemptingSave(true);

            // if the answer is YES for Prohibited children check we stop this application and update its status to Ineligible
            if (ParseBool(data.inquiry.application.isProhibitedFromWorkingWithChildren) === true) {
                data.inquiry.application.applicationStatus = ApplicationStatusEnum.Ineligible;
            }

            // if user had changed mind on check box it is easiest to just set value now instead of dynamically (i.e. they switch from No to Yes on parent radio btn)
            if (
                ParseBool(data.inquiry.application.completeWorkingWithChildrenCheck) === true &&
                ParseBool(data.inquiry.application.isProhibitedFromWorkingWithChildren) === true
            ) {
                data.inquiry.application.isWorkingWithChildrenCheckNotApplicable = false;
            }

            const updateOutlet = false;
            dispatch(appActions.UpdateApplication(data.inquiry.application, updateOutlet));

            // wait for 0.5 seconds to allow update to occur then we get updated details for final check on save and re-render
            Sleep(500).then(() => {
                dispatch(appActions.GetApplication());
            });
            // }
            // } else if (!filesExist) {
            //    setFileUploadValidationError();
        } else {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    SubmitThisPageIfActive(isActive, triggerSubmit, formRef, setTriggerSubmit);

    const removeErrorsAndReValidate = () => {
        // do the trigger only once
        let runTrigger = false;
        const errorAttachments: { [key: string]: any[] } = get(errors, 'inquiry.application.attachments');

        if (errorAttachments && errorAttachments !== undefined) {
            if (errorAttachments.childrelatedemploymentcheck) {
                clearErrors('inquiry.application.attachments.childrelatedemploymentcheck');
                runTrigger = true;
            }
        }

        if (runTrigger === true) {
            trigger(); // reset validation so we dont get a form isValid=false causing inability to save
        }
    };

    if (completeWorkingWithChildrenCheckWatched && completeWorkingWithChildrenCheckWatched.toString() === CompleteWorkingWithChildrenCheckType.Required) {
        // toggle each of these one after the other due to renders
        if (!showWorkingWithChildrenFileUpload) {
            setShowWorkingWithChildrenFileUpload(true);
        }

        if (showWorkingWithChildrenCheckBox) {
            setShowWorkingWithChildrenCheckBox(false);
        }
    }

    if (completeWorkingWithChildrenCheckWatched && completeWorkingWithChildrenCheckWatched.toString() === CompleteWorkingWithChildrenCheckType.NotRequired) {
        // toggle each of these one after the other due to renders
        if (showWorkingWithChildrenFileUpload) {
            setShowWorkingWithChildrenFileUpload(false);
        }

        if (!showWorkingWithChildrenCheckBox) {
            setShowWorkingWithChildrenCheckBox(true);
        }
    }

    const onChangeIsProhibitedFromWorkingWithChildren = (event: { target: { value: any } }) => {
        // manage the value manually as ref did not work for the react hook form
        setValue('inquiry.application.isProhibitedFromWorkingWithChildren', event.target.value, { shouldDirty: true, shouldValidate: true });
        // trigger(); // re-validate
    };

    const onChangeCompleteWorkingWithChildrenCheck = (event: { target: { value: any } }) => {
        const selectedValue = event.target.value;

        // if Files have been uploaded but the user has changed their mind we remove those files
        if (filesExist && ParseBool(selectedValue) === false) {
            // TODO: confirm user wants to remove any file data then remove it

            // Remove any file data if exists for the other option, to help prevent all sorts of validation issues
            dispatch(attachmentActions.RemoveAttachmentsByType(AttachmentDocumentType.ChildRelatedEmploymentCheck));

            // refresh data set now so we dont get entity issues??
            // dispatch(appActions.GetApplication());

            // unregister('inquiry.application.attachments.childrelatedemploymentcheck');
        }

        if (selectedValue === CompleteWorkingWithChildrenCheckType.Required && ParseBool(isWorkingWithChildrenCheckNotApplicable) === true) {
            // set option back to FALSE if we select Yes (as No will show it, a Yes will hide it and set to false)
            setValue('inquiry.application.isWorkingWithChildrenCheckNotApplicable', false, { shouldDirty: true });
        }

        // manage the value manually as ref did not work for the react hook form
        setValue('inquiry.application.completeWorkingWithChildrenCheck', selectedValue, { shouldDirty: true });
        trigger(); // re-validate, could use { shouldValidate: true }
    };

    // update attachments collection as that is seperate from other data application object call
    const updatedFilesList = (files: any) => {
        if (files && files.length > 0) {
            if (filesExist === false) {
                setFilesExist(true);
                // immediately remove any errors of type file not uploaded (for parent nodes)
                removeErrorsAndReValidate();
            }
        } else {
            if (filesExist === true) {
                setFilesExist(false);
            }
        }
    };

    const removedFile = (attachmentId: string) => {
        // refresh CM object/active obj
        RemoveAttachmentFromLocalInquiryObject(attachmentId, setValue, inquiry);
        trigger(); // re-validate
    };

    const addedFile = (newAttachment: IAttachmentMetadata) => {
        // refresh CM object/active obj (NOTE: CANNOT get App in case user simply adds/removes files, it will clear the form!)
        InsertAttachmentToLocalInquiryObject(newAttachment, setValue, inquiry);
        trigger(); // re-validate
    };

    const workingWithChildrenFileUploadDisplay = () => {
        if (showWorkingWithChildrenFileUpload === false) {
            return null;
        }

        return (
            <Row className={childItems}>
                <Row className={controlMaxWidth}>
                    <Col>
                        <FileUploadField
                            id='inquiry.application.attachments.childrelatedemploymentcheck'
                            maxFilesCount={10}
                            applicationId={inquiry?.application.applicationId}
                            authService={authService}
                            displayNameSuffix='Working With Children (or equivalent) check/s'
                            errorsFieldSuffix='Working With Children'
                            documentType={AttachmentDocumentType.ChildRelatedEmploymentCheck}
                            isMandatory={true}
                            updatedFilesList={updatedFilesList}
                            register={register}
                            dispatch={dispatch}
                            goingBackwards={goingBackwards}
                            errorsField={errors.inquiry?.application?.attachments}
                            removedFile={removedFile}
                            addedFile={addedFile}
                        />
                    </Col>
                </Row>
            </Row>
        );
    };

    const checkBoxOptionsWorkingWithChildren: CheckBoxOption[] = [
        {
            label: 'I have reviewed the relevant legislation and have determined I do not require a working with children check or equivalent',
            value: inquiry?.application.isWorkingWithChildrenCheckNotApplicable, // isWorkingWithChildrenCheckNotApplicable,
            disabled: disableField,
            toggleCheckIndex: -1,
        },
    ];

    const onChangeIsWorkingWithChildrenCheckNotApplicable = (event: { target: { checked: boolean } }) => {
        // manage the value manually as ref did not work for the react hook form
        setValue('inquiry.application.isWorkingWithChildrenCheckNotApplicable', event.target.checked, { shouldDirty: true, shouldValidate: true });
    };

    const workingWithChildrenCheckBoxDisplay = () => {
        if (showWorkingWithChildrenCheckBox === false) {
            return null;
        }

        const defValue =
            inquiry === undefined || inquiry?.application.isWorkingWithChildrenCheckNotApplicable === null
                ? undefined
                : inquiry.application.isWorkingWithChildrenCheckNotApplicable;

        return (
            <Row className={childItems}>
                <Row className={controlMaxWidth}>
                    <Col>
                        <CheckBoxGroupField
                            id='inquiry.application.isWorkingWithChildrenCheckNotApplicable'
                            displayName='Reason for not providing a child-related employment check'
                            fieldLabel=''
                            defaultValue={defValue} // only set default if value exists
                            isMandatory={false}
                            options={checkBoxOptionsWorkingWithChildren}
                            control={control}
                            register={register}
                            errorsField={errors.inquiry?.application?.isWorkingWithChildrenCheckNotApplicable}
                            onChange={onChangeIsWorkingWithChildrenCheckNotApplicable}
                        />
                    </Col>
                </Row>
            </Row>
        );
    };

    const radioOptionsWorkingWithChildrenProhibited: RadioButtonOption[] = [
        { label: 'Yes', value: true, disabled: disableField },
        { label: 'No', value: false, disabled: disableField },
    ];

    const radioOptionsWorkingWithChildren: RadioButtonOption[] = [
        {
            label: 'Yes',
            value: CompleteWorkingWithChildrenCheckType.Required,
            disabled: disableField,
            children: workingWithChildrenFileUploadDisplay(),
        },
        {
            label: 'No',
            value: CompleteWorkingWithChildrenCheckType.NotRequired,
            disabled: disableField,
            children: workingWithChildrenCheckBoxDisplay(),
        },
    ];

    return (
        <>
            <PageTitle title='Child-related employment' description={HEADER_CHILDRELATED} />
            <PageWrapper
                pageName='ChildRelated'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={handleClickPrevious}
                hideBackButton={HideTheBackButton(appContext)}
                textForSaveContinueButton={GetTextForSaveAndContinueButton(appContext)}
            >
                <PageFieldsTitle title='Child-related employment' />
                <PageMandatoryLabelText />
                <ErrorSummary errors={errors} />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.applicationId'
                    {...register('inquiry.application.applicationId')}
                    defaultValue={inquiry?.application.applicationId}
                />
                <ModalDialog
                    modalTitle={VALIDATION_PROMPT_CHILDRELATEDPROHIBITED_AWAY_TITLE}
                    modalBodyText={VALIDATION_PROMPT_CHILDRELATEDPROHIBITED_AWAY_MESSAGE}
                    modalBodyTextP2={VALIDATION_PROMPT_CHILDRELATEDPROHIBITED_AWAY_MESSAGE_P2}
                    showMe={showFailToSaveDialog}
                    showOkOnly={true}
                    handleClickOk={redirectToDashboard}
                />
                <LabelField
                    id='infoLabel'
                    displayName='Information regarding child-related employment'
                    value={GUIDANCETEXT_INFOCHILDRELATED}
                    isMandatory={false}
                />
                <RadioButtonGroupField
                    options={radioOptionsWorkingWithChildrenProhibited}
                    id='inquiry.application.isProhibitedFromWorkingWithChildren'
                    displayName='Are you prohibited under a law of a State or Territory from working with children?'
                    fieldLabel=''
                    defaultValue={inquiry?.application.isProhibitedFromWorkingWithChildren} // only set default if value exists
                    isMandatory={true}
                    control={control}
                    register={register}
                    errorsField={errors.inquiry?.application?.isProhibitedFromWorkingWithChildren}
                    onChange={onChangeIsProhibitedFromWorkingWithChildren}
                />
                <RadioButtonGroupField
                    options={radioOptionsWorkingWithChildren}
                    id='inquiry.application.completeWorkingWithChildrenCheck'
                    displayName='For the type of FDR service you are offering, are you required to complete a child-related employment check?'
                    fieldLabel=''
                    defaultValue={inquiry?.application.completeWorkingWithChildrenCheck} // only set default if value exists
                    isMandatory={true}
                    control={control}
                    register={register}
                    errorsField={errors.inquiry?.application?.completeWorkingWithChildrenCheck}
                    onChange={onChangeCompleteWorkingWithChildrenCheck}
                />
            </PageWrapper>
        </>
    );
};

export default ChildRelatedEmploymentPage;
