import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NOTE_INTRODUCTION_EVIDENCE } from '../../../services/Constants';
import { NOTE_INTRODUCTION_PRIVACY } from '../../../services/Constants';
import NoteText from '../../../controls/NoteText';
import { IntroductionContent } from './IntroductionContent';

/* As we want the print out to have a header, footer and styles 
  different to the page we create another component for print 
*/
export class IntroductionContentPrint extends React.Component {
    render() {
        return (
            <Container className='ml-5'>
                <Row className='mt-3 mb-3'>
                    <Col className='col-3'>
                        <img src='./crest.png' alt='' style={{ height: '48px' }} />
                    </Col>
                    <Col className='col-8'>
                        <h1>Registration Application</h1>
                        <p style={{ fontSize: '24px' }}>Family Dispute Resolution Register</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr />
                        <IntroductionContent />
                    </Col>
                </Row>
                <Row className='mt-4 mb-4'>
                    <Col>
                        <NoteText displayText={NOTE_INTRODUCTION_EVIDENCE} />
                    </Col>
                    <Col className='col-md-1 justify-content-end' />
                </Row>
                <Row className='mt-4 mb-4'>
                    <Col>
                        <NoteText displayText={NOTE_INTRODUCTION_PRIVACY} />
                    </Col>
                    <Col className='col-md-1 justify-content-end' />
                </Row>
                <Row>
                    <Col>
                        <hr />
                    </Col>
                </Row>
            </Container>
        );
    }
}
