import * as React from 'react';
import { DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER, LINK_AG_BECOMINGPRACTITIONER } from '../services/Constants';
import BackGroundImage from '../backgroundImage.png';
import NavLink from '../controls/NavLink';
import AppContext from '../stateManagement/context/AppContext';
import BackToDashboardPillButton from '../controls/BackToDashboardPillButton';
import styles from '../components/site/site.module.scss';
import NavLinkEmailPau from '../components/fields/NavLinkEmailPau';

const { aboutContent } = styles;

function About() {
    const appContext = React.useContext(AppContext);

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 p-0'>
                    <div
                        className='jumbotron jumbotron-fluid bg-info d-flex flex-column justify-content-center d-none d-sm-none d-md-block'
                        style={{ backgroundImage: `url("${BackGroundImage}")`, backgroundSize: 'cover', marginBottom: '-1px' }}
                    >
                        <div className={`${aboutContent} w-50 mx-auto`}>
                            <h1 className='display-3 font-weight-bold mb-0'>About</h1>
                            <h2 className='mb-4 mt-3'>Family Dispute Resolution Register</h2>
                            <p>
                                The Family Dispute Resolution Register provides the public with information about individuals who meet the requirements under
                                the <i>Family Law Act 1975</i> as well as the Family Law (Family Dispute Resolution Practitioners) Regulations 2008 to provide
                                family dispute resolution (FDR), issue section 60I certificates and who agree to have their name appear on a list of FDR
                                practitioners. Some of these FDR practitioners have also agreed to their service details being made available to the public.
                            </p>
                            <p>
                                The FDR Register is maintained by the Attorney-General’s Department, as a part of the regulatory function it manages for FDR
                                practitioners. An FDR practitioner is required to meet and maintain specific accreditation criteria and service delivery
                                standards when providing FDR services.
                            </p>
                            <p>
                                The FDR Register is the only official source of information relating to FDR practitioners. If a search for an FDR practitioner
                                does not provide a response, clients are encouraged to confirm the accreditation of a practitioner by contacting the
                                Practitioner Accreditation Unit by email to {<NavLinkEmailPau whiteTextOnBlueBackground={true} />} or by phone on{' '}
                                {DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER}.
                            </p>
                            <h3 className='mb-4 mt-5'>How to use the Family Dispute Resolution Register</h3>
                            <p>
                                You can search the FDR Register for an accredited FDR practitioner by name, location, service delivery type or specific details
                                such as languages spoken. FDR practitioners may have also provided information for students or existing practitioners in
                                relation to their availability to provide supervision.
                            </p>
                            <p>
                                Once you have entered your search criteria, click on the ‘search’ button to conduct the search. The search results will display
                                all accredited FDR practitioners that match the location search criteria and/or any words in the extra fields. The pins
                                appearing on the map show practitioners closest to your location. If you have not enabled your location in your device, please
                                check your settings.
                            </p>
                            <p>
                                You can search again either by clicking on the ‘clear’ button and entering new search criteria, or, by typing over the original
                                search criteria and clicking on the search button again.
                            </p>
                            <p>
                                The search result will include the name of accredited FDR practitioners and their business name (if they have provided one). If
                                you click on any of the underlined links in a search result, you will see more details about that provider including their
                                contact details, opening hours, areas of service, client groups, methods of service delivery and general cost information.
                            </p>
                            <p>All accredited FDR practitioners are responsible for their own information displayed in the FDR Register.</p>
                            <h3 className='mb-4 mt-5'>How to apply for inclusion on the Family Dispute Resolution Register</h3>
                            <p>
                                Information about applying for accreditation as an FDR practitioner is available at{` '`}
                                {
                                    <NavLink
                                        displayText={'Becoming a family dispute resolution practitioner'}
                                        hrefLink={LINK_AG_BECOMINGPRACTITIONER}
                                        openInNewTab={true}
                                    />
                                }
                                {`' `}
                                or by calling the Practitioner Accreditation Unit on {DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER}.
                            </p>
                            <p>
                                Once accredited, a practitioner can include their details on the FDR Register through the FDR Practitioner Portal. This
                                information can then be searched by the public.
                            </p>
                            <BackToDashboardPillButton appContext={appContext} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
