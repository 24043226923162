import React, { useContext, useRef, useState, useMemo, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { get } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Guid from '../../../../services/Guid';
import {
    CLEANINGUP_PRIORDATA,
    HEADER_COMPLAINTS,
    HELPHEADERTEXT_PROFESSIONALASSOCNAME,
    HELPTEXT_PROFESSIONALASSOCNAME,
    MODALBUTTON_NO_DISCARDCHANGES,
    MODALBUTTON_YES_DISCARDCHANGES,
    MODALHEADER_UNSAVEDCHANGES_DATALOSSPOTENTIAL,
    MODALTEXT_UNSAVEDCHANGES_DATALOSSPOTENTIAL,
    PLACEHOLDER_DATE,
    TIP_COMPLAINTSMECHANISM_DETAILS_EDIT_SAVE,
    TIP_COMPLAINTSMECHANISM_DETAILS_EMPLOYEEFILEUPLOAD,
    TIP_COMPLAINTSMECHANISM_DETAILS_PRIVATEFILEUPLOAD,
    WHATISTHISTEXT_PROFESSIONALASSOCNAME,
} from '../../../../services/Constants';
import {
    AjaxCallStateEnum,
    AttachmentDocumentType,
    ComplaintsMechanismType,
    ComplaintsProcessType,
    NavigationDestination,
    RelationshipToEmployerType,
    WizardMode,
    WizardStepNumberComplaintsMech,
} from '../../../../services/Enums';
import { ComplaintsMechanismEditPageProps } from '../../../PageInterfaces';
import {
    VALIDATION_DISCARD_OK_POPUP_MESSAGE,
    VALIDATION_ERROR_POPUP_MESSAGE,
    VALIDATION_ERROR_UNKNOWN_MESSAGE,
    VALIDATION_SUCCESS_POPUP_MESSAGE,
} from '../../../../services/Validation';
import { IApplication, IAttachmentMetadata, IOutlet, IProfessionalAssociation } from '../../../../api/interfacesApi';
import OutletApi from '../../../../api/outletApi';
import ReferenceApi from '../../../../api/referenceApi';
import { toastErrorMessage, toastSuccessMessage } from '../../../../actions/toastrMessages';
import ApplicationActions from '../../../../actions/applicationActions';
import AttachmentActions from '../../../../actions/attachmentsActions';
import AppContext from '../../../../stateManagement/context/AppContext';
import { Sleep } from '../../../../utils/Common';
import { ApplicationIsLoaded, ErrorOnSubmitForm } from '../../../../utils/AppUtils';
import { MoveComplaintsWizardBackward, MoveComplaintsWizardForward } from '../wizard/complaintsMechWizardFunctions';
import { SubmitThisPageIfActive } from '../../../../pages/PageFunctions';
import FundedOrgComponent from '../../../../pages/Registration/ComplaintsMechanism/FundedOrgComponent';

import {
    ActivateChosenComplaintsMechanism,
    GetActiveMembershipComplaintAttachmentIdCollection,
    InsertAttachmentToLocalInquiryObject,
    RemoveAttachmentFromLocalInquiryObject,
    RemovePriorAttachmentsForOrgNameOrRelationshipToEmpChanged,
    RemovePriorDataRelationshipChanged,
} from '../../../../utils/ComplaintsUtils';
import PageTitle from '../../../../components/pageTitle';
import InputDateField from '../../../../components/fields/InputDateField';
import PageFieldsTitle from '../../../../components/fields/PageFieldsTitle';
import PageMandatoryLabelText from '../../../../components/fields/PageMandatoryLabelText';
import FileUploadField from '../../../../components/fields/FileUploadField';
import PageWrapper from '../../../../components/pageWrapper';
import SelectListField, { SelectListOption } from '../../../../components/fields/SelectListField';
import LabelField from '../../../../components/fields/LabelField';
import { GoingBackwardsAndDiscarding, SetDestinationNavActionOnSuccess } from '../../../../components/wizard/wizardStateManager';

import ErrorSummary from '../../../../controls/ErrorSummary';
import Loader from '../../../../controls/Loader';
import TipsControl from '../../../../controls/TipsControl';
import { EmptyRow } from '../../../../controls/EmptyRow';
import { ModalDialogConfirmable } from '../../../../controls/ModalDialogConfirmable';
import stylesPage from '../../../Pages.module.scss';

const { childItems } = stylesPage;

interface UIState {
    attemptingSave: boolean;
    attemptingSaveThenRemovePriorData: boolean;
    discardingChangesFlow: boolean;
    getAppForInitialDiscardProcess: boolean;
}

const initUiState: UIState = {
    attemptingSave: false,
    attemptingSaveThenRemovePriorData: false,
    discardingChangesFlow: false,
    getAppForInitialDiscardProcess: false,
};

const ComplaintsMechanismDetailsPage = (props: ComplaintsMechanismEditPageProps) => {
    const {
        addNewComplaint,
        resumeAddingNew,
        inquiry,
        setInquiryObj,
        // attachments,
        goingBackwards,
        authService,
        dispatch,
        control,
        // handleClickPrevious,
        handleSubmit,
        register,
        // unregister,
        trigger,
        setValue,
        // getValues,
        setError,
        watch,
        ajaxCallStateForPage,
        backEndValidationErrors,
        clearErrors,
        errors,
        isValid,
        isDirty,
        isActive,
        triggerSubmit,
        setTriggerSubmit,
        reset,
        dirtyFields,
        touchedFields,
        triggerChildFormDiscardChanges,
        hideBackButton,
    } = props;

    const appContext = useContext(AppContext);
    const formRef = useRef();
    // const history = useHistory();
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const attachmentActions = useMemo(() => {
        return new AttachmentActions(authService);
    }, [authService]);
    const referenceApi = useMemo(() => {
        return new ReferenceApi(authService);
    }, [authService]);
    const outletApi = useMemo(() => {
        return new OutletApi(authService);
    }, [authService]);
    const application: IApplication = useSelector((stateApp: any) => stateApp.application);

    const disableFieldProfAssocList =
        appContext.wizardState.wizardMode === WizardMode.Resubmit ? true : addNewComplaint !== true && resumeAddingNew !== true ? true : false;
    const disableFieldDates = appContext.wizardState.wizardMode === WizardMode.Resubmit ? true : false;
    const idFileUpload = 'inquiry.application.activeComplaintsMechanismObj.attachmentExists';
    const membershipFromDateWatched: Date = watch(
        'inquiry.application.activeComplaintsMechanismObj.membershipFromDate',
        inquiry.application.activeComplaintsMechanismObj?.membershipFromDate,
    );
    const membershipToDateWatched: Date = watch(
        'inquiry.application.activeComplaintsMechanismObj.membershipToDate',
        inquiry.application.activeComplaintsMechanismObj?.membershipToDate,
    );

    const [outletsSelectList, setOutletsSelectList] = useState<SelectListOption[]>([]); // employee OrgName list
    const [profAssocSelectList, setProfAssocSelectList] = useState<SelectListOption[]>([]); // prof assoc name list
    const [filesExist, setFilesExist] = useState(false); // prof assoc files uploaded list
    const [loadingListData, setLoadingListData] = useState(false);
    const [displayState, setDisplayState] = useState(initUiState);
    const [isDataLoaded, setIsDataLoaded] = useState(true); // needs to be TRUE for when this page is loaded as part of wizard pages collection

    const isComplaintsTypeFull =
        inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc;
    const isComplaintstTypeGovtFunded =
        inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType === ComplaintsMechanismType.GovernmentFundedFDRService;
    const today = new Date();

    const loadProfessionalAssociationsList = () => {
        // reference data load
        referenceApi
            .getAllProfessionalAssociations()
            .then((response: any) => {
                // construct a list including empty first item which we can validate against
                const profAssociations = response as IProfessionalAssociation[];
                const newProfAssociations: SelectListOption[] = [];
                newProfAssociations.push({ value: '', text: '' });
                profAssociations.map(data => newProfAssociations.push({ value: data.professionalAssociationId, text: data.name }));

                setProfAssocSelectList(newProfAssociations);
                //// refresh UI to load values properly
                // trigger();
                // setLoadingListData(true);
            })
            .catch((error: any) => {
                setLoadingListData(true);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            });
    };

    const loadOutletsList = () => {
        outletApi
            .getAllOutlets()
            .then((response: any) => {
                // construct a list including empty first item which we can validate against
                const outlets = response as IOutlet[];
                const newOutlets: SelectListOption[] = [];
                newOutlets.push({ value: '', text: '' });
                outlets.map(dataIn => newOutlets.push({ value: dataIn.outletId, text: dataIn.name, type: dataIn.organisationFundingType?.toString() }));

                setOutletsSelectList(newOutlets);
            })
            .catch((error: any) => {
                setLoadingListData(true);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            });
    };

    // reset state
    useEffect(() => {
        if (isActive === true) {
            setDisplayState({ ...initUiState });
            setIsDataLoaded(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // initial load of any existing data
    useEffect(() => {
        // ONLY call APIs if active page
        if (isActive === true && goingBackwards === false && profAssocSelectList.length === 0) {
            setIsDataLoaded(false);

            loadProfessionalAssociationsList();
            loadOutletsList();

            setLoadingListData(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (triggerChildFormDiscardChanges && isActive === true) {
            handleClickPreviousButton(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerChildFormDiscardChanges]);

    // if goingBackwards may need to reload this list
    useEffect(() => {
        if (isActive === true && goingBackwards === true && profAssocSelectList.length === 0) {
            setIsDataLoaded(false);

            loadProfessionalAssociationsList();
            loadOutletsList();
            // // if this routine is called from going backwards then add tiny delay to allow processing
            // Sleep(1500).then(() => {
            //    setIsDataLoaded(true);
            // });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, goingBackwards]);

    // need to trigger() if disabled list, otherwise the UI is 'locked' when user first navs to it under resubmit flow
    useEffect(() => {
        if (isActive === true && disableFieldProfAssocList === true && profAssocSelectList && profAssocSelectList.length > 0) {
            Sleep(400).then(() => {
                // immediately remove any errors as UI and validation not aligning as expected
                removeErrorsAndReValidate();
            });
        }

        // also if lists are now loaded after refresh but not caught in the ajax response do it now
        if (profAssocSelectList && profAssocSelectList.length > 0 && isDataLoaded === false) {
            setLoadingListData(false);
            // NOTE: we MUST check CMId of activeCM before we set this: setIsDataLoaded(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profAssocSelectList]);

    const removePriorDataRelationshipChanged = (relationshipToEmployerChangedTo: RelationshipToEmployerType) => {
        setDisplayState({ ...displayState, attemptingSaveThenRemovePriorData: true });

        RemovePriorDataRelationshipChanged(attachmentActions, dispatch, inquiry, setInquiryObj, relationshipToEmployerChangedTo);

        reset();

        // wait for attachaments to remove could be 0..many then reset state
        Sleep(3000).then(() => {
            setDisplayState({ ...displayState, attemptingSaveThenRemovePriorData: false });
        });
    };

    // based upon the original value, we remove data as the user has changed their option whilst the data already exists
    const removePriorDataOrgNameChanged = () => {
        setDisplayState({ ...displayState, attemptingSaveThenRemovePriorData: true });

        RemovePriorDataRelationshipChanged(attachmentActions, dispatch, inquiry, setInquiryObj);

        reset();

        // wait for attachaments to remove could be 0..many then reset state
        Sleep(3000).then(() => {
            setDisplayState({ ...displayState, attemptingSaveThenRemovePriorData: false });
        });
    };

    const goBackOneWizardStep = () => {
        // can move backwards - but we MUST clearErrors in case user clicks to attempt to save without the isDirty being set
        clearErrors();

        // dispatchWizardState({ type: WizardStateActionTypeEnums.unsetFormIsDirty });
        SetDestinationNavActionOnSuccess(appContext, NavigationDestination.PreviousWizardStep);

        // const overrideIsDirty = false; // moving back without any changes
        // const overrideIsValid = true; // moving back without any changes
        MoveComplaintsWizardBackward(appContext, appContext.wizardState.triggerSubmit);
    };

    const discardChangesAndGoBack = (backToLandingPage?: boolean) => {
        // Pattern for discard changes is based off reset() not behaving, and attachments which bake straight into the CM and activeCM object
        //  and do not get to the CMProcessAttachment table until a save is done (yes we have to cater for everything)
        //  Need to do the following upon user agreement to remove the 'old' data, as user may go to end of wizard, save some, then come back to
        //  middle page and just change one thing, click back, and so on.
        // Here goes:
        //  - put up a spinner to indicate currently removing old data
        //  - remove attachment if just added (if user removed files they are already gone!)
        //  - read the latest stored App from DB, and put that into our inquiry obj
        //  - go back a page

        // this will prevent the wizard from re-loading whilst we adjust the inquiry object
        GoingBackwardsAndDiscarding(appContext);

        setDisplayState({
            ...displayState,
            discardingChangesFlow: true,
            getAppForInitialDiscardProcess: true,
            // discardAttachments: true, // hasAddedNewFileButNotSaved,
        });

        // if we do a GET first then we end up removing the 0th index CM file which is INCORRECT!  Need to simply remove the activeCM evidence
        RemovePriorAttachmentsForOrgNameOrRelationshipToEmpChanged(
            attachmentActions,
            dispatch,
            inquiry,
            undefined, // remove them all for this CM - inquiry.application.activeComplaintsMechanismObj?.relationshipToOrganisation,
            true,
        );

        inquiry.application.activeComplaintsMechanismObj = null;
        setInquiryObj(inquiry);

        // allow time to remove any files
        Sleep(200).then(() => {
            if (backToLandingPage === true) {
                window.location.href = '/managecomplaintsmechanism?reload=true'; // reload all data (window.location.href removes state)
            } else {
                // call API that will refresh the form
                const doNotCopyActiveComplaint = false;
                // const originalApplication = await
                dispatch(appActions.GetApplication(doNotCopyActiveComplaint));
            }
        });
    };

    const handleClickPreviousButton = async (backToLandingPage?: boolean) => {
        let goBack = false;
        const isDirtyAlt = Object.keys(dirtyFields).length > 0 || Object.keys(touchedFields).length > 0;
        // files are not updating the dirty flag so do this manually per form:
        // DOESNT WORK ON EDIT AS FILE IS NOW COPIED IN CLONE: const hasAddedNewFileButNotSaved = IsValidGuidIdentifier(inquiry.application.activeComplaintsMechanismObj?.activeAttachmentId);

        if (!isDirty && !isDirtyAlt) {
            goBack = true;
        } else {
            if (
                await ModalDialogConfirmable(MODALTEXT_UNSAVEDCHANGES_DATALOSSPOTENTIAL, MODALBUTTON_YES_DISCARDCHANGES, MODALBUTTON_NO_DISCARDCHANGES, {
                    title: MODALHEADER_UNSAVEDCHANGES_DATALOSSPOTENTIAL,
                    isDestructive: true,
                    modalBodyPositionTextLeft: false,
                    dataTestId: 'modalPromptToRemoveData_ComplaintsDetailsBack',
                })
            ) {
                discardChangesAndGoBack(backToLandingPage);
            }
        }

        if (goBack === true) {
            if (backToLandingPage === true) {
                window.location.href = '/managecomplaintsmechanism?reload=true'; // reload all data (window.location.href removes state)
            } else {
                goBackOneWizardStep();
            }
        }
    };

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors.errors?.length > 0) {
            backEndValidationErrors.errors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'ComplaintsProcessType':
                        setError('inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType', {
                            type: 'manual',
                            message: validationError.errorMessage,
                        });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    const processOriginalApplicationForDiscardChanges = () => {
        //        if (displayState.getAppForInitialDiscardProcess === true && ApplicationIsLoaded(origApplication, origApplication.applicationId)) {
        // discard changes flow
        setDisplayState({ ...displayState, getAppForInitialDiscardProcess: false });

        // finally update inquiry minus any attachments and changes then go backwards
        inquiry.application = application;

        // handles when adding new CMand it gets an Id by this page
        // setValue('inquiry.application.activeComplaintsMechanismObj', inquiry.application.activeComplaintsMechanismObj);

        // if (addNewComplaint === true && resumeAddingNew === true) {
        ActivateChosenComplaintsMechanism(appContext, inquiry.application, appContext.currentComplaintsMechId);
        /* } else {
                if (addNewComplaint === true && resumeAddingNew === false) {
                    ActivateEmptyComplaintsMechanism(appContext, inquiry.application);
                } else {
                    ActivateChosenComplaintsMechanism(appContext, inquiry.application, appContext.currentComplaintsMechIndex);
                }
            } */

        setInquiryObj(inquiry); // TODO: Check CM and activeCM within this app
        setValue('inquiry', inquiry); // weld it to the form

        // reset would make the values update for the useForm functionality
        reset();

        Sleep(1200).then(() => {
            //// allow time for attachments to be removed, ... (DO NOT: then update application now with removed attachments data)
            // setDisplayState({ ...displayState, updateAppForDiscardProcess: true });
            // after removal of attachments and updating the inquiry obj we can now go backwards for discard flow and end it
            setDisplayState({
                ...displayState,
                getAppForInitialDiscardProcess: false,
                discardingChangesFlow: false,
            });

            toastSuccessMessage(VALIDATION_DISCARD_OK_POPUP_MESSAGE);
            goBackOneWizardStep();
        });
    };

    /* // handle this application update here in a useEffect() as the state return call is a little more random
    useEffect(() => {
        if (isActive === true) {
            if (displayState.getAppForInitialDiscardProcess === true && ApplicationIsLoaded(application, application.applicationId)) {
                // processOriginalApplicationForDiscardChanges();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application]); */

    // check dispatch success state
    useEffect(() => {
        if (isActive === true && ajaxCallStateForPage && ajaxCallStateForPage.pageNumber === WizardStepNumberComplaintsMech.ComplaintsMechanismDetails) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                setDisplayState({ ...displayState, attemptingSave: false, attemptingSaveThenRemovePriorData: false, discardingChangesFlow: false });
                setIsDataLoaded(true);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                displayBackEndValidations();
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setDisplayState({ ...displayState, attemptingSave: false, attemptingSaveThenRemovePriorData: false, discardingChangesFlow: false });
                setLoadingListData(false);
                setIsDataLoaded(true);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK && !appContext.wizardState.triggerSubmit) {
                    // attempting submit OK
                    if (displayState.attemptingSave === true) {
                        // IF VALID (or OK to move forward):
                        setDisplayState({ ...displayState, attemptingSave: false, attemptingSaveThenRemovePriorData: false });

                        // refresh data set now after changes saved (otherwise Ids are empty on Add flow)
                        const doNotCopyActiveComplaint = true;
                        dispatch(appActions.GetApplication(doNotCopyActiveComplaint));

                        toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);

                        // refresh data set now after changes saved
                        // allow seconds to see save changes dialog then go back to dashboard
                        Sleep(1200).then(() => {
                            MoveComplaintsWizardForward(appContext, isValid);
                        });
                    } else if (displayState.attemptingSaveThenRemovePriorData === true) {
                        // removal of prior data from change mind
                        setDisplayState({ ...displayState, attemptingSave: false, attemptingSaveThenRemovePriorData: false });
                        // NOTE: user changed selection so we CANNOT refresh data here!
                        toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);
                        Sleep(500).then(() => {
                            trigger(); // refresh validation
                        });
                    } else if (displayState.getAppForInitialDiscardProcess === true && ApplicationIsLoaded(application, application.applicationId)) {
                        // discard changes flow
                        processOriginalApplicationForDiscardChanges();
                    }
                } // end check for OK return

                // lists are loaded
                if (loadingListData === true && profAssocSelectList.length > 0) {
                    setLoadingListData(false);
                    setIsDataLoaded(true);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const submitForm = (data: any) => {
        if (isValid) {
            // Save changes to active complaints mech (attempt save, if that fails we do NOT move the Wizard step)
            setDisplayState({ ...displayState, attemptingSave: true });

            // if field is disabled we need to set the data Id properly for the list item for Prof Associations
            if (disableFieldProfAssocList === true) {
                if (
                    (data.inquiry.application.activeComplaintsMechanismObj?.professionalAssociation === null &&
                        inquiry.application.activeComplaintsMechanismObj?.professionalAssociation !== null) ||
                    (data.inquiry.application.activeComplaintsMechanismObj?.professionalAssociation.professionalAssociationId === undefined &&
                        data.inquiry.application.activeComplaintsMechanismObj?.professionalAssociation.name !== '' &&
                        inquiry.application.activeComplaintsMechanismObj?.professionalAssociation !== null)
                ) {
                    // professionalAssoc will be a guid not a profAssoc interface so need to convert it now
                    const profAssocId = inquiry.application.activeComplaintsMechanismObj?.professionalAssociation.professionalAssociationId;
                    data.inquiry.application.activeComplaintsMechanismObj.professionalAssociation = { professionalAssociationId: profAssocId, name: '' };
                }
            }

            // if the outlet has been selected via FundedOrgComponent it is not welded to the form properly we check now and update that\
            if (
                data.inquiry.application.activeComplaintsMechanismObj?.outlet === null &&
                inquiry.application.activeComplaintsMechanismObj?.outlet !== null &&
                inquiry.application.activeComplaintsMechanismObj?.outlet.outletId !== ''
            ) {
                data.inquiry.application.activeComplaintsMechanismObj.outlet = inquiry.application.activeComplaintsMechanismObj?.outlet;
            }

            // set "data" (form value) here for CP Type - as the FundedOrgComponent sets it for LegalAid/Other etc in lower component
            if (
                data.inquiry.application.activeComplaintsMechanismObj &&
                data.inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc &&
                data.inquiry.application.activeComplaintsMechanismObj.professionalAssociation?.professionalAssociationId !== undefined &&
                data.inquiry.application.activeComplaintsMechanismObj.professionalAssociation?.professionalAssociationId !== ''
            ) {
                data.inquiry.application.activeComplaintsMechanismObj.complaintsProcessType = ComplaintsProcessType.ProfessionalAssociationMembership;
                // store this in form data for next wizard step
                if (inquiry.application.activeComplaintsMechanismObj) {
                    inquiry.application.activeComplaintsMechanismObj.complaintsProcessType = ComplaintsProcessType.ProfessionalAssociationMembership;
                    setInquiryObj(inquiry);
                }
            }

            dispatch(appActions.UpdateComplaintsMechanism(data.inquiry?.application?.activeComplaintsMechanismObj));
        } else {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    const removeErrorsAndReValidate = () => {
        // do the trigger only once
        let runTrigger = false;
        const errorAttachments: { [key: string]: any[] } = get(errors, 'inquiry.application.attachments');

        if (errorAttachments && errorAttachments !== undefined) {
            if (errorAttachments.associationmembership) {
                clearErrors('inquiry.application.attachments.associationmembership');
                runTrigger = true;
            }
        }

        // run the below ONLY if disabled
        if (disableFieldProfAssocList === true) {
            const selectListErrorExists = get(errors, 'inquiry.application.activeComplaintsMechanismObj.professionalAssociation.professionalAssociationId');
            if (selectListErrorExists !== undefined) {
                clearErrors('inquiry.application.activeComplaintsMechanismObj.professionalAssociation.professionalAssociationId');
            }
        }

        if (runTrigger === true) {
            trigger(); // reset validation so we dont get a form isValid=false causing inability to save
        }
    };

    // update attachments collection as that is seperate from other data application object call
    const updatedFilesList = (files: any) => {
        if (files && files.length > 0) {
            if (filesExist === false) {
                setFilesExist(true);
                // immediately remove any errors of type file not uploaded (for parent nodes)
                removeErrorsAndReValidate();
            }
        } else {
            if (filesExist === true) {
                setFilesExist(false);
            }
        }
    };

    /*
    const removedFile = (attachmentId: string) => {
        // refresh CM object/active obj
        if (inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId) {
            // dispatch(appActions.GetComplaintsMechanism(inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId));

            const doNotCopyActiveComplaint = true;
            dispatch(appActions.GetApplication(doNotCopyActiveComplaint));
        }
    };*/

    const removedFile = (attachmentId: string) => {
        // refresh CM object/active obj
        RemoveAttachmentFromLocalInquiryObject(attachmentId, setValue, inquiry);
        trigger(); // re-validate
    };

    const addedFile = (newAttachment: IAttachmentMetadata) => {
        // refresh CM object/active obj (NOTE: CANNOT get App in case user simply adds/removes files, it will clear the form!)
        InsertAttachmentToLocalInquiryObject(newAttachment, setValue, inquiry);
        trigger(); // re-validate
    };

    const employeeInputFields = () => {
        const fieldName = 'inquiry.application.activeComplaintsMechanismObj';

        return (
            <>
                <fieldset name={fieldName} key={fieldName}>
                    <legend className='sr-only'>Select Organisation and upload evidence</legend>
                    <FundedOrgComponent
                        isInitialApplication={false}
                        key={fieldName}
                        // index={0}
                        dispatch={dispatch}
                        // fieldName={fieldName}
                        outletsSelectList={outletsSelectList}
                        register={register}
                        watch={watch}
                        authService={authService}
                        errors={errors}
                        inquiry={inquiry}
                        setInquiry={setInquiryObj}
                        // clearErrors={clearErrors}
                        control={control}
                        trigger={trigger}
                        setError={setError}
                        goingBackwards={goingBackwards}
                        setValue={setValue}
                        isVisible={true}
                        removePriorDataRelationshipChanged={removePriorDataRelationshipChanged}
                        removePriorDataOrgNameChanged={removePriorDataOrgNameChanged}
                        isEditableCM={addNewComplaint === false && resumeAddingNew === false} // on Edit UI disable
                        removedFile={removedFile}
                        addedFile={addedFile}
                        showDisclaimerText={true}
                    />
                    <EmptyRow />
                </fieldset>
            </>
        );
    };

    const membershipInputFields = () => {
        // tslint:disable-next-line: prefer-const
        let nextYear = new Date();
        nextYear.setFullYear(nextYear.getFullYear() + 1);
        const openingMembershipFromDate = membershipFromDateWatched ? membershipFromDateWatched : today;
        const openingMembershipToDate = membershipToDateWatched ? membershipToDateWatched : nextYear;

        // this needs to be set based off the collection of CM now as attachments are treated separately
        const attachmentIdCollection = GetActiveMembershipComplaintAttachmentIdCollection(inquiry?.application);

        return (
            <>
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.CMMdocumentAttachmentIsMandatory'
                    {...register('inquiry.application.CMMdocumentAttachmentIsMandatory')}
                    defaultValue={true}
                />
                {disableFieldProfAssocList && (
                    <input
                        type='hidden'
                        className='form-control'
                        name='inquiry.application.activeComplaintsMechanismObj.professionalAssociation.professionalAssociationId'
                        {...register('inquiry.application.activeComplaintsMechanismObj.professionalAssociation.professionalAssociationId')}
                        defaultValue={inquiry.application.activeComplaintsMechanismObj?.professionalAssociation?.professionalAssociationId}
                    />
                )}
                <Row>
                    <Col>
                        <SelectListField
                            id='inquiry.application.activeComplaintsMechanismObj.professionalAssociation.professionalAssociationId'
                            displayName='Name of professional association'
                            guidanceText='Select the name of the professional association that will provide you with a complaints mechanism for your private FDR services.'
                            defaultValue={
                                inquiry.application.activeComplaintsMechanismObj && inquiry.application.activeComplaintsMechanismObj.professionalAssociation
                                    ? inquiry.application.activeComplaintsMechanismObj?.professionalAssociation.professionalAssociationId
                                    : ''
                            }
                            options={profAssocSelectList}
                            disabled={disableFieldProfAssocList}
                            isOneAndHalfWidth={true}
                            isMandatory={true}
                            register={register}
                            whatIsThisText={WHATISTHISTEXT_PROFESSIONALASSOCNAME}
                            helpHeaderText={HELPHEADERTEXT_PROFESSIONALASSOCNAME}
                            helpText={HELPTEXT_PROFESSIONALASSOCNAME}
                            errorsField={errors.inquiry?.application?.activeComplaintsMechanismObj?.professionalAssociation?.professionalAssociationId}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <LabelField
                            id='periodMembershipLabel'
                            displayName='Current membership dates'
                            value=''
                            isMandatory={false}
                            isDisplayNameFontSizeLarger={true}
                            hideFinalEmptyRow={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputDateField
                            id='inquiry.application.activeComplaintsMechanismObj.membershipFromDate'
                            displayName='Start date'
                            defaultValue={
                                inquiry.application.activeComplaintsMechanismObj
                                    ? inquiry.application.activeComplaintsMechanismObj.membershipFromDate
                                    : undefined
                            }
                            isMandatory={true}
                            openToDate={openingMembershipFromDate}
                            yearDropdownRange={3}
                            applyYearRangeToMaxMin={true}
                            dropdownMode='select'
                            register={register}
                            control={control}
                            disabled={disableFieldDates}
                            placeHolder={PLACEHOLDER_DATE}
                            errorsField={errors.inquiry?.application?.activeComplaintsMechanismObj?.membershipFromDate}
                            hideFinalEmptyRow={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputDateField
                            id='inquiry.application.activeComplaintsMechanismObj.membershipToDate'
                            displayName='End date'
                            defaultValue={
                                inquiry.application.activeComplaintsMechanismObj ? inquiry.application.activeComplaintsMechanismObj.membershipToDate : undefined
                            }
                            isMandatory={true}
                            openToDate={openingMembershipToDate}
                            yearDropdownRange={3}
                            yearDropdownRangeStartingYear={nextYear.getFullYear()}
                            applyYearRangeToMaxMin={true}
                            dropdownMode='select'
                            register={register}
                            control={control}
                            disabled={disableFieldDates}
                            placeHolder={PLACEHOLDER_DATE}
                            errorsField={errors.inquiry?.application?.activeComplaintsMechanismObj?.membershipToDate}
                            hideFinalEmptyRow={true}
                        />
                    </Col>
                </Row>
                <EmptyRow />
                <Row className={childItems}>
                    <Col>
                        <FileUploadField
                            id={idFileUpload}
                            refreshIfAttachmentIdNotFound={true} // will trigger empty fileInfo if the above Id not yet uploaded/exists
                            attachmentIdCollection={attachmentIdCollection}
                            maxFilesCount={3}
                            applicationId={inquiry?.application.applicationId}
                            complaintsProcessId={inquiry?.application.activeComplaintsMechanismObj?.complaintsMechanismId}
                            authService={authService}
                            displayNameSuffix='professional association membership'
                            errorsFieldSuffix='professional association membership'
                            documentType={AttachmentDocumentType.AssociationMembership}
                            isMandatory={true}
                            updatedFilesList={updatedFilesList}
                            register={register}
                            goingBackwards={goingBackwards}
                            dispatch={dispatch}
                            errorsField={errors.inquiry?.application?.attachments}
                            removedFile={removedFile}
                            addedFile={addedFile}
                            showDisclaimerText={true}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    const displayInputFields = () => {
        return isComplaintsTypeFull === true ? membershipInputFields() : employeeInputFields();
    };

    const pageTitleText =
        addNewComplaint === true || resumeAddingNew === true
            ? 'Add a complaints mechanism for an FDR service outlet'
            : 'Update complaints mechanism for FDR service outlet';
    const tipsStringEditSave = addNewComplaint === false && resumeAddingNew === false ? TIP_COMPLAINTSMECHANISM_DETAILS_EDIT_SAVE : '';
    const tipsStringFileUpload = isComplaintsTypeFull
        ? TIP_COMPLAINTSMECHANISM_DETAILS_PRIVATEFILEUPLOAD
        : isComplaintstTypeGovtFunded
        ? TIP_COMPLAINTSMECHANISM_DETAILS_EMPLOYEEFILEUPLOAD
        : '';

    SubmitThisPageIfActive(isActive, triggerSubmit, formRef, setTriggerSubmit);

    if (
        isDataLoaded === false &&
        inquiry.application.activeComplaintsMechanismObj !== undefined &&
        inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId !== undefined &&
        inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId !== null &&
        inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId !== Guid.empty &&
        profAssocSelectList &&
        profAssocSelectList.length > 0 &&
        outletsSelectList &&
        outletsSelectList.length > 0
    ) {
        // NOTE: we MUST check CMId of actveCM before we set this:
        setIsDataLoaded(true);
        // must refresh inquiry obj for attachment collection and active CM
        // setInquiryObj(inquiry);
        // doing this now throws up the render() error
        // // handles when adding new CMand it gets an Id by this page
        // setValue('inquiry.application.activeComplaintsMechanismObj', inquiry.application.activeComplaintsMechanismObj);
    }

    return (
        <>
            <PageTitle title={pageTitleText} description={HEADER_COMPLAINTS} />
            <PageWrapper
                pageName='ComplaintsDetails'
                formRef={formRef}
                hideBackButton={hideBackButton} // hide the Back button if user has clicked update button and been moved to this page, no need to go back
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={handleClickPreviousButton}
            >
                <PageFieldsTitle title='Complaints mechanism for outlet' />
                <PageMandatoryLabelText />
                {tipsStringEditSave !== '' && <TipsControl tipString={tipsStringEditSave} />}
                <TipsControl tipString={tipsStringFileUpload} />
                <ErrorSummary errors={errors} />
                {isDataLoaded === false && isActive === true && <Loader isLoading={true} loaderText='Loading data...' />}
                {(displayState.discardingChangesFlow === true || displayState.attemptingSaveThenRemovePriorData === true) && isActive === true && (
                    <Loader isLoading={true} loaderText={CLEANINGUP_PRIORDATA} />
                )}
                {isDataLoaded === true && (
                    <>
                        <input
                            type='hidden'
                            className='form-control'
                            name='inquiry.application.applicationId'
                            {...register('inquiry.application.applicationId')}
                            defaultValue={inquiry.application.applicationId}
                        />
                        <input
                            type='hidden'
                            className='form-control'
                            name='inquiry.application.accreditationStatus'
                            {...register('inquiry.application.accreditationStatus')}
                            defaultValue={inquiry.application.accreditationStatus}
                        />
                        <input
                            type='hidden'
                            className='form-control'
                            name='inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId'
                            {...register('inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId')}
                            defaultValue={inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId}
                        />
                        <input
                            type='hidden'
                            className='form-control'
                            name='inquiry.application.activeComplaintsMechanismObj.activeAttachmentId' // to track for dirty flag
                            {...register('inquiry.application.activeComplaintsMechanismObj.activeAttachmentId')}
                            defaultValue={inquiry.application.activeComplaintsMechanismObj?.activeAttachmentId}
                        />
                        <input
                            type='hidden'
                            className='form-control'
                            name='inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation' // to track for dirty flag
                            {...register('inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation')}
                            defaultValue={inquiry.application.activeComplaintsMechanismObj?.relationshipToOrganisation}
                        />
                        <input
                            type='hidden'
                            className='form-control'
                            name='inquiry.application.activeComplaintsMechanismObj.evidence' // to track for dirty flag
                            {...register('inquiry.application.activeComplaintsMechanismObj.evidence')}
                            defaultValue={inquiry.application.activeComplaintsMechanismObj?.evidence}
                        />
                        {displayInputFields()}
                    </>
                )}
            </PageWrapper>
        </>
    );
};

export default ComplaintsMechanismDetailsPage;
