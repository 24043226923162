import React from 'react';

const SiteLogo = () => {
    return (
        <div className='navbar-brand' id='header-brand' data-testid='header-brand'>
            <a className='text-center' href='/'>
                <img src={'./crest.png'} style={{ height: '48px' }} alt='Attorney-General Logo navigates back to dashboard' />
            </a>
        </div>
    );
};

export default SiteLogo;
