// Outlet Details-Contact Details page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the Outlet Details-Public Information page
//  - allows navigation forward to the Outlet Details-Type of Service page
import React, { useEffect } from 'react';
import { EditPageProps } from '../PageInterfaces';
// import AppContext from '../../components/context/AppContext';
import PageMandatoryLabelText from '../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../components/fields/PageFieldsTitle';
import LabelField from '../../components/fields/LabelField';
import InputTextField from '../../components/fields/InputTextField';
import NavLink from '../../controls/NavLink';
import { EmptyRow } from '../../controls/EmptyRow';
import {
    GUIDANCETEXT_OUTLET_BUSINESSCONTACT,
    HELPHEADERTEXT_OUTLET_BUSINESSNAME,
    HELPTEXT_OUTLET_BUSINESSNAME,
    PLACEHOLDER_OUTLET_BUSINESSNAME,
    PLACEHOLDER_EMAIL,
    PLACEHOLDER_OUTLET_TOLLFREENUMBER,
    PLACEHOLDER_OUTLET_MOBILE,
    PLACEHOLDER_OUTLET_LANDLINE,
    PLACEHOLDER_OUTLET_WEBADDRESS,
} from '../../services/Constants';

const OutletContactDetailsPage = (props: EditPageProps) => {
    const { inquiry, setInquiryObj, register, errors, setValue, watch } = props;

    // const mobileWatched: string = watch('inquiry.application.outletPractitioner.mobile', inquiry.application.outletPractitioner?.mobile);
    // const tollFreePhoneWatched: string = watch('inquiry.application.outletPractitioner.tollFreePhone', inquiry.application.outletPractitioner?.tollFreePhone);
    // const emailAddressWatched: string = watch('inquiry.application.outletPractitioner.registerEmail', inquiry.application.outletPractitioner?.registerEmail);
    // const webAddressWatched: string = watch('inquiry.application.outletPractitioner.webAddress', inquiry.application.outletPractitioner?.webAddress);

    const webAddressWatched = watch('inquiry.application.outletPractitioner.webAddress', inquiry.application.outletPractitioner?.webAddress);
    // watch('inquiry.application.outletPractitioner.webAddress', inquiry.application.outletPractitioner?.webAddress);
    const prefOrFirstName =
        inquiry.personalDetails?.preferredName !== null && inquiry.personalDetails?.preferredName !== ''
            ? inquiry.personalDetails.preferredName
            : inquiry.personalDetails.firstName;
    const nameDisplayLabel = prefOrFirstName + ' ' + inquiry.personalDetails.familyName;

    useEffect(() => {
        // set this so other panels get the update
        setValue('inquiry.application.outletPractitioner.webAddress', webAddressWatched, { shouldDirty: true, shouldValidate: false });
        inquiry.application.outletPractitioner!.webAddress = webAddressWatched;

        setInquiryObj(inquiry);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webAddressWatched]);

    /* this causes all sorts of trouble for usability as it trips validation and does not allow data entry
    useEffect(() => {
        // re-validate as four fields are collectively mandatory
        if (!mobileWatched || !tollFreePhoneWatched || !emailAddressWatched) {
            trigger();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mobileWatched, tollFreePhoneWatched, emailAddressWatched]);
    */

    /*const updateWebAddress = (event: any) => {
        const newValue = event.target.value;
        setValue('inquiry.application.outletPractitioner.webAddress', newValue, { shouldDirty: true, shouldValidate: true });
        inquiry.application.outletPractitioner!.webAddress = newValue;
    };
*/
    /* const updateWebAddress = (event: { target: { value: any } }) => {
        // manage the value manually as ref did not work for the react hook form
        const valueEntered = event.target.value;
        setValue('inquiry.application.outletPractitioner.webAddress', valueEntered, { shouldDirty: true, shouldValidate: true });
    }; */
    // {webAddressWatched && webAddressWatched !== '' && (
    // (errors.inquiry === undefined || errors.inquiry?.application?.outletPractitioner?.webAddress === undefined) &&

    /* const webAddressWatched =
        inquiry.application.outletPractitioner !== undefined || inquiry.application.outletPractitioner !== null
            ? inquiry.application.outletPractitioner!.webAddress
            : ''; 
    */

    /*
    const onChangeWebsiteAddress = (event: any) => {
        // manage the value manually as ref did not work for the react hook form
        const newWebAddress: string = event?.target?.value;
        setValue('inquiry.application.outletPractitioner.webAddress', newWebAddress, { shouldDirty: true, shouldValidate: false });

        // set this so other panels get the update
        inquiry.application.outletPractitioner!.webAddress = newWebAddress;
        setInquiryObj(inquiry);
        // trigger(); // re-validate
    };

    const handleChangeWebsiteAddress = (e: any) => {
        onChangeWebsiteAddress(e);
    }; */

    return (
        <>
            <EmptyRow />
            <PageFieldsTitle title='Outlet Contact Details' />
            <PageMandatoryLabelText />
            <LabelField id='namedisplaylabel' displayName='Name display for public register' value={nameDisplayLabel} isMandatory={false} />
            <LabelField
                id='businessnameheaderlabel'
                displayName='Business name'
                value=''
                isMandatory={false}
                helpHeaderText={HELPHEADERTEXT_OUTLET_BUSINESSNAME}
                helpText={HELPTEXT_OUTLET_BUSINESSNAME}
            />
            <InputTextField
                id='inquiry.application.outletPractitioner.name'
                displayName='What is your Business/practice name?'
                defaultValue={inquiry.application.outletPractitioner?.name}
                isMandatory={false}
                placeHolder={PLACEHOLDER_OUTLET_BUSINESSNAME}
                register={register}
                errorsField={errors.inquiry?.application?.outletPractitioner?.name}
            />
            <LabelField
                id='businesscontactsheaderlabel'
                displayName='Business contacts'
                value=''
                isMandatory={true}
                guidanceText={GUIDANCETEXT_OUTLET_BUSINESSCONTACT}
            />
            <InputTextField
                id='inquiry.application.outletPractitioner.phone'
                displayName='Landline'
                defaultValue={inquiry.application.outletPractitioner?.phone}
                isMandatory={false}
                register={register}
                placeHolder={PLACEHOLDER_OUTLET_LANDLINE}
                errorsField={errors.inquiry?.application?.outletPractitioner?.phone}
            />
            <InputTextField
                id='inquiry.application.outletPractitioner.mobile'
                displayName='Mobile'
                defaultValue={inquiry.application.outletPractitioner?.mobile}
                isMandatory={false}
                register={register}
                placeHolder={PLACEHOLDER_OUTLET_MOBILE}
                errorsField={errors.inquiry?.application?.outletPractitioner?.mobile}
            />
            <InputTextField
                id='inquiry.application.outletPractitioner.tollFreePhone'
                displayName='Toll free number'
                defaultValue={inquiry.application.outletPractitioner?.tollFreePhone}
                isMandatory={false}
                register={register}
                placeHolder={PLACEHOLDER_OUTLET_TOLLFREENUMBER}
                errorsField={errors.inquiry?.application?.outletPractitioner?.tollFreePhone}
            />
            <InputTextField
                id='inquiry.application.outletPractitioner.registerEmail'
                displayName='Email address'
                defaultValue={inquiry.application.outletPractitioner?.registerEmail}
                isMandatory={false}
                placeHolder={PLACEHOLDER_EMAIL}
                capitaliseTextOff={true}
                register={register}
                errorsField={errors.inquiry?.application?.outletPractitioner?.registerEmail}
            />
            <InputTextField
                id='inquiry.application.outletPractitioner.webAddress'
                displayName='Web address'
                defaultValue={inquiry.application.outletPractitioner?.webAddress}
                isMandatory={false}
                capitaliseTextOff={true}
                register={register}
                placeHolder={PLACEHOLDER_OUTLET_WEBADDRESS}
                errorsField={errors.inquiry?.application?.outletPractitioner?.webAddress}
                // onKeyUp={handleChangeWebsiteAddress} // does not play nicely cursor moves out of field
                // onBlur={updateWebAddress} NOTE: do NOT use onBlur() it messes up the form tech and save of the inquiry object
                // onKeyUp={(event: any) => updateWebAddress(event)}
            />
            <NavLink
                displayText='Test web address'
                className='btn btn-outline-secondary'
                hrefLink={inquiry.application.outletPractitioner?.webAddress ?? ''}
                openInNewTab={true}
            />
            <EmptyRow />
        </>
    );
};

export default OutletContactDetailsPage;
