// Declaration and Consent page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the Complaints Mech or Public Indemnity page
//  - allows navigation forward to the Review and Submit page
import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { RegoApplicationPageProps } from '../../PageInterfaces';
import { GetTextForSaveAndContinueButton, HideTheBackButton } from '../../PageFunctions';
import { EnsureData } from '../RegistrationApplicationWizard/wizardHelperFunctions';
import { MoveWizardForward } from '../../../components/wizard/wizardStepFunctions';
import { AjaxCallStateEnum, WizardMode, WizardStepNumber } from '../../../services/Enums';
import { VALIDATION_ERROR_POPUP_MESSAGE, VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../../services/Validation';
import { ParseBool, Sleep } from '../../../utils/Common';
import { ErrorOnSubmitForm, IsConsentToPublishOnPublicRegisterVisible } from '../../../utils/AppUtils';
import { toastErrorMessage } from '../../../actions/toastrMessages';
import ApplicationActions from '../../../actions/applicationActions';
import ErrorSummary from '../../../controls/ErrorSummary';
import AppContext from '../../../stateManagement/context/AppContext';
import PageWrapper from '../../../components/pageWrapper';
import PageTitle from '../../../components/pageTitle';
import PageMandatoryLabelText from '../../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import {
    DECLARATION_PARAGRAPH1,
    DECLARATION_PARAGRAPH2,
    DECLARATION_PARAGRAPH3,
    DECLARATION_PARAGRAPH4,
    DECLARATION_PARAGRAPH5,
    DECLARATION_PARAGRAPH6,
    HEADER_DECLARATION,
    HELPHEADERTEXT_NAMEONPUBLICREGISTER,
    HELPTEXT_NAMEONPUBLICREGISTER,
} from '../../../services/Constants';
import CheckBoxGroupField, { CheckBoxOption } from '../../../components/fields/CheckBoxGroupField';
import RadioButtonGroupField, { RadioButtonOption } from '../../../components/fields/RadioButtonGroupField';
import styles from './DeclarationAndConsent.module.scss';
import Loader from '../../../controls/Loader';

const { declarationList } = styles;

const DeclarationAndConsentPage = (props: RegoApplicationPageProps) => {
    const {
        inquiry,
        authService,
        dispatch,
        handleClickPrevious,
        handleSubmit,
        // clearErrors,
        register,
        // unregister,
        trigger,
        setError,
        setValue,
        // watch,
        control,
        errors,
        isValid,
        isActive,
        ajaxCallStateForPage,
        backEndValidationErrors,
        // triggerSubmit,
        // setTriggerSubmit,
    } = props;

    const appContext = useContext(AppContext);
    const isResubmit = appContext.wizardState.wizardMode === WizardMode.Resubmit ? true : false;
    const disableField = isResubmit;
    const formRef = useRef();
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);

    // even though we have redux states, we HAVE to capture the attempt due to navigation and Get and Save user calls on the page
    const [attemptingSave, setAttemptingSave] = useState(false);
    // const hasDeclaredWatched: boolean | null = watch('inquiry.application.hasDeclaredAndConsented', inquiry.application.hasDeclaredAndConsented);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const [_refresh, setRefresh] = useState({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const shouldShowConsentToPublishOnPublicRegister = IsConsentToPublishOnPublicRegisterVisible(inquiry);

    useEffect(() => {
        Sleep(2500).then(() => {
            setIsLoading(false);
        });
    }, []);

    /*
    useEffect(() => {
        // if not sync'd up then we do so now
        if (isActive === true && inquiry.application.hasDeclaredAndConsented === false && hasDeclaredWatched === true) {
            // place a delay so we load everything then load this change
            // Sleep(2500).then(() => {

            // it is false however the check box on local dev works however in envs does not, let's redo it here
            const idHtmlDom = 'inquiry.application.hasDeclaredAndConsented-0';
            const checkBox = window.document.getElementById(idHtmlDom);
            if (checkBox) {
                //  && checkBox?.getAttribute('checked') !== '') {
                // click the check box forcing render
                checkBox.click();
            }
            // checkBox?.setAttribute('checked', '');

            // });
        }

        // force simple refresh when declare check box has been marked by external page update
        if (isActive === true && inquiry.application.hasDeclaredAndConsented === false) {
            // place a delay so we load everything then load this change
            Sleep(2000).then(() => {
                // if user goes backwards once then changes anything we set consent back to empty, and we need another refresh on this page
                dispatch(appActions.GetApplication());

                trigger();
            });

            // by calling this method react re-renders the component
            setRefresh({});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inquiry.application.hasDeclaredAndConsented, isActive, hasDeclaredWatched]);
    */

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors.errors?.length > 0) {
            backEndValidationErrors.errors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'CompleteWorkingWithChildrenCheck':
                        setError('inquiry.application.completeworkingwithchildrencheck', { type: 'manual', message: validationError.errorMessage });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    // check dispatch success state
    useEffect(() => {
        if (isActive === true && ajaxCallStateForPage && ajaxCallStateForPage.pageNumber === WizardStepNumber.DeclarationAndConsent) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                setAttemptingSave(false);
                displayBackEndValidations();
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setAttemptingSave(false);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (attemptingSave === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK && !appContext.wizardState.triggerSubmit) {
                    setAttemptingSave(false);

                    // IF VALID (or OK to move forward):
                    // refresh data set now after changes saved
                    dispatch(appActions.GetApplication());
                    MoveWizardForward(appContext, isValid);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const submitForm = (data: any) => {
        if (isValid) {
            // Save changes to application (attempt save, if that fails we do NOT move the Wizard step)

            // user may have gone back and changed any option that prevents this from being shown, hence we must set it to NULL now
            if (shouldShowConsentToPublishOnPublicRegister === false) {
                data.inquiry.application.consentToPublishOnPublicRegister = null;
            }
            // set existing data now as we dont capture it in this page
            EnsureData(appContext, WizardStepNumber.DeclarationAndConsent, data, inquiry);

            // Save changes to application (attempt save, if that fails we do NOT move the Wizard step)
            setAttemptingSave(true);
            const updateOutlet = false;
            dispatch(appActions.UpdateApplication(data.inquiry.application, updateOutlet));
        } else {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    const onChangeDeclaration = (event: { target: { checked: boolean } }) => {
        // manage the value manually as ref did not work for the react hook form
        const isChecked: boolean = event.target.checked;
        setValue('inquiry.application.hasDeclaredAndConsented', isChecked, { shouldDirty: true, shouldValidate: true });
        trigger(); // re-validate
    };

    const onChangeConsentToPublishOnPublicRegister = (event: any) => {
        const consentToPublishOnPublicRegister: boolean = ParseBool(event.target.value);
        // manage the value manually as ref did not work for the react hook form (use event.target.value to set value here)
        setValue('inquiry.application.consentToPublishOnPublicRegister', consentToPublishOnPublicRegister, { shouldDirty: true, shouldValidate: true });
    };

    const checkBoxOptionsDeclaration: CheckBoxOption[] = [
        {
            label: 'I consent',
            value: inquiry?.application.hasDeclaredAndConsented,
            disabled: false,
            toggleCheckIndex: -1,
        },
    ];

    const radioOptions: RadioButtonOption[] = [
        { label: 'Yes', value: true, disabled: disableField },
        { label: 'No', value: false, disabled: disableField },
    ];

    // const checkDefaultValueDeclared = hasDeclaredWatched && hasDeclaredWatched === true ? true : false;
    const checkDefaultValueDeclared =
        inquiry === undefined || inquiry?.application.hasDeclaredAndConsented === null ? undefined : inquiry.application.hasDeclaredAndConsented;

    return (
        <>
            <PageTitle title='Declaration and consent' description={HEADER_DECLARATION} />
            <PageWrapper
                pageName='Declaration'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={handleClickPrevious}
                hideBackButton={HideTheBackButton(appContext)}
                textForSaveContinueButton={GetTextForSaveAndContinueButton(appContext)}
            >
                <Loader isLoading={isLoading} loaderText='Loading your information...' />
                <PageFieldsTitle title='Declaration and consent' />
                <PageMandatoryLabelText />
                <ErrorSummary errors={errors} />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.applicationId'
                    {...register('inquiry.application.applicationId')}
                    defaultValue={inquiry.application.applicationId}
                />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.showConsentToPublishOnPublicRegister'
                    {...register('inquiry.application.showConsentToPublishOnPublicRegister')}
                    defaultValue={shouldShowConsentToPublishOnPublicRegister}
                />
                <p>
                    I <b>declare</b> the following:
                </p>
                <ul className={declarationList}>
                    <li>
                        <label id='declarationTextParagraph1'>{DECLARATION_PARAGRAPH1}</label>
                    </li>
                    <li>
                        <label id='declarationTextParagraph2'>{DECLARATION_PARAGRAPH2}</label>
                    </li>
                    <li>
                        <label id='declarationTextParagraph3'>{DECLARATION_PARAGRAPH3}</label>
                    </li>
                    <li>
                        <label id='declarationTextParagraph4'>{DECLARATION_PARAGRAPH4}</label>
                    </li>
                    <li>
                        <label id='declarationTextParagraph5'>{DECLARATION_PARAGRAPH5}</label>
                    </li>
                    <li>
                        <label id='declarationTextParagraph6'>{DECLARATION_PARAGRAPH6}</label>
                    </li>
                </ul>
                <CheckBoxGroupField
                    id='inquiry.application.hasDeclaredAndConsented'
                    displayName='Declaration and consent'
                    fieldLabel='I consent to the Practitioner Accreditation Unit verifying any information, documents or other matters I provide in, with, or to support, my Application for Accreditation as a Family Dispute Resolution Practitioner.'
                    defaultValue={checkDefaultValueDeclared} // only set default if value exists
                    isMandatory={true}
                    options={checkBoxOptionsDeclaration}
                    control={control}
                    register={register}
                    errorsField={errors.inquiry?.application?.hasDeclaredAndConsented}
                    onChange={onChangeDeclaration}
                />
                {shouldShowConsentToPublishOnPublicRegister === true && (
                    <RadioButtonGroupField
                        options={radioOptions}
                        id='inquiry.application.consentToPublishOnPublicRegister'
                        displayName='Name appearing on public searchable list'
                        fieldLabel='When approved as a fully accredited FDR practitioner, do you consent to your name appearing on a public list of accredited FDR practitioners?'
                        defaultValue={inquiry.application.consentToPublishOnPublicRegister} // only set default if value exists
                        isMandatory={true}
                        control={control}
                        register={register}
                        helpHeaderText={HELPHEADERTEXT_NAMEONPUBLICREGISTER}
                        helpText={HELPTEXT_NAMEONPUBLICREGISTER}
                        errorsField={errors.inquiry?.application?.consentToPublishOnPublicRegister}
                        // autoFocus={true}
                        // onChange={(e: { target: { value: string | boolean | null } }) => onChangeHasPreviouslyApplied(ParseBool(e.target.value))}
                        onChange={onChangeConsentToPublishOnPublicRegister}
                    />
                )}
            </PageWrapper>
        </>
    );
};

export default DeclarationAndConsentPage;
