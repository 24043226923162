import React from 'react';
import { Col, Row } from 'react-bootstrap';
import CheckBoxGroupField, { CheckBoxOption } from '../../components/fields/CheckBoxGroupField';
import { Inquiry } from '../../api/interfacesApi';
import {
    GUIDANCETEXT_OUTLET_ADDITIONALMODESDELIVERY,
    GUIDANCETEXT_OUTLET_CLIENTGROUPS,
    GUIDANCETEXT_OUTLET_CLIENTSERVICES,
    GUIDANCETEXT_OUTLET_MODESOFDELIVERY,
    GUIDANCETEXT_OUTLET_OTHERSERVICES,
    HELPHEADERTEXT_OUTLET_CULTUREGROUP_SPECIFIC,
    HELPHEADERTEXT_OUTLET_LANGUAGE_SPECIFIC,
    HELPHEADERTEXT_OUTLET_RELIGION_SPECIFIC,
    HELPTEXT_OUTLET_CULTUREGROUP_SPECIFIC,
    HELPTEXT_OUTLET_LANGUAGE_SPECIFIC,
    HELPTEXT_OUTLET_RELIGION_SPECIFIC,
    PLACEHOLDER_OUTLET_CULTUREGROUPSPECIFICDETAIL,
    PLACEHOLDER_OUTLET_LANGUAGESPECIFICDETAIL,
    PLACEHOLDER_OUTLET_RELIGIONSPECIFICDETAIL,
} from '../../services/Constants';
import LabelField from '../../components/fields/LabelField';
import InputTextField from '../../components/fields/InputTextField';
import styles from '../Pages.module.scss';
import stylesField from '../../components/fields/Fields.module.scss';

const { controlMaxWidth, checkBoxGroupLegend } = styles;

function getDefaultCheckBoxOption(label: string) {
    return { label, value: false, disabled: false, toggleCheckIndex: -1 };
}

// const checkBoxOptionsServiceDeliveryFaceToFace: CheckBoxOption[] = [{ label: 'Face-to-face', value: false, disabled: false, toggleCheckIndex: -1 }];
const checkBoxOptionsServiceDeliveryFaceToFace: CheckBoxOption[] = [getDefaultCheckBoxOption('Face-to-face')];
const checkBoxOptionsServiceDeliveryPhone: CheckBoxOption[] = [getDefaultCheckBoxOption('Phone')];
const checkBoxOptionsServiceDeliveryOnline: CheckBoxOption[] = [getDefaultCheckBoxOption('Online conferencing')];

const checkBoxOptionsAdditionalDeliveryComediation: CheckBoxOption[] = [getDefaultCheckBoxOption('Co-mediation')];
const checkBoxOptionsAdditionalDeliveryGenderBalanced: CheckBoxOption[] = [getDefaultCheckBoxOption('Gender-balanced mediation')];
const checkBoxOptionsAdditionalDeliveryLegallyAssist: CheckBoxOption[] = [getDefaultCheckBoxOption('Legally assisted family dispute resolution')];
const checkBoxOptionsAdditionalDeliveryChildInclusive: CheckBoxOption[] = [getDefaultCheckBoxOption('Child Inclusive Practice')];

const checkBoxOptionsScopeChildSupport: CheckBoxOption[] = [getDefaultCheckBoxOption('Child Support')];
const checkBoxOptionsScopeProperty: CheckBoxOption[] = [getDefaultCheckBoxOption('Property')];
const checkBoxOptionsScopeTheHague: CheckBoxOption[] = [getDefaultCheckBoxOption('The Hague convention')];
const checkBoxOptionsClientGroupLgbtqi: CheckBoxOption[] = [getDefaultCheckBoxOption('LGBTQI+ community')];
const checkBoxOptionsClientGroupCarers: CheckBoxOption[] = [getDefaultCheckBoxOption('Carers and other significant persons i.e. Grandparents')];
const checkBoxOptionsClientGroupAboriginal: CheckBoxOption[] = [getDefaultCheckBoxOption('Aboriginal people')];
const checkBoxOptionsClientGroupTorresStrait: CheckBoxOption[] = [getDefaultCheckBoxOption('Torres Strait Islander people')];
const checkBoxOptionsClientGroupCulturallyDiverse: CheckBoxOption[] = [getDefaultCheckBoxOption('Culturally and linguistically diverse people')];

const checkBoxOptionsOtherServicePractitioner: CheckBoxOption[] = [getDefaultCheckBoxOption('Supervision for practitioners')];
const checkBoxOptionsOtherServiceStudent: CheckBoxOption[] = [getDefaultCheckBoxOption('Supervision for students')];

const onChangeModeOfDeliveryFaceToFace = (setValue: any) => (event: { target: { checked: boolean; id: any } }) => {
    // manage the value manually as ref did not work for the react hook form
    const isChecked: boolean = event?.target?.checked;

    if (isChecked === false) {
        // if this was selected then unchecked, then we have to remove service coverage data
        setValue('inquiry.application.outletPractitioner.serviceCoverageKm', undefined, { shouldDirty: true, shouldValidate: false });
        setValue('inquiry.application.outletPractitioner.serviceCoverageOwnExpense', null, { shouldDirty: true, shouldValidate: false });
    }

    onChangeCheckBox('inquiry.application.outletPractitioner.isServiceDeliveryFaceToFace', setValue, event);
};

const onChangeCheckBox = (fieldFullName: string, setValue: any, event: { target: { checked: boolean; id: any } }) => {
    // manage the value manually as ref did not work for the react hook form
    const isChecked: boolean = event?.target?.checked;
    setValue(fieldFullName, isChecked, { shouldDirty: true, shouldValidate: true });
};

const onChangeModeOfDeliveryPhone = (setValue: any) => (event: { target: { checked: boolean; id: any } }) => {
    onChangeCheckBox('inquiry.application.outletPractitioner.isServiceDeliveryPhone', setValue, event);
};

const onChangeModeOfDeliveryOnline = (setValue: any) => (event: { target: { checked: boolean; id: any } }) => {
    onChangeCheckBox('inquiry.application.outletPractitioner.isServiceDeliveryOnline', setValue, event);
};

const onChangeModeOfAdditionalDeliveryComediation = (setValue: any) => (event: { target: { checked: boolean; id: any } }) => {
    onChangeCheckBox('inquiry.application.outletPractitioner.isAdditionalComediation', setValue, event);
};

const onChangeModeOfAdditionalDeliveryGenderBalanced = (setValue: any) => (event: { target: { checked: boolean; id: any } }) => {
    onChangeCheckBox('inquiry.application.outletPractitioner.isAdditionalGenderBalanced', setValue, event);
};

const onChangeModeOfDeliveryLegallyAssist = (setValue: any) => (event: { target: { checked: boolean; id: any } }) => {
    onChangeCheckBox('inquiry.application.outletPractitioner.isAdditionalLegallyAssist', setValue, event);
};

const onChangeModeOfAdditionalDeliveryChildInclusive = (setValue: any) => (event: { target: { checked: boolean; id: any } }) => {
    onChangeCheckBox('inquiry.application.outletPractitioner.isAdditionalChildInclusive', setValue, event);
};

const onChangeModeOfScopeChildSupport = (setValue: any) => (event: { target: { checked: boolean; id: any } }) => {
    onChangeCheckBox('inquiry.application.outletPractitioner.isScopeChildSupport', setValue, event);
};

const onChangeModeOfScopeProperty = (setValue: any) => (event: { target: { checked: boolean; id: any } }) => {
    onChangeCheckBox('inquiry.application.outletPractitioner.isScopeProperty', setValue, event);
};

const onChangeModeOfScopeTheHague = (setValue: any) => (event: { target: { checked: boolean; id: any } }) => {
    onChangeCheckBox('inquiry.application.outletPractitioner.isScopeTheHague', setValue, event);
};

const onChangeClientGroupLgbtqi = (setValue: any) => (event: { target: { checked: boolean; id: any } }) => {
    onChangeCheckBox('inquiry.application.outletPractitioner.isClientGroupLgbtqi', setValue, event);
};

const onChangeClientGroupCarers = (setValue: any) => (event: { target: { checked: boolean; id: any } }) => {
    onChangeCheckBox('inquiry.application.outletPractitioner.isClientGroupCarers', setValue, event);
};

const onChangeClientGroupAboriginal = (setValue: any, trigger: any) => (event: { target: { checked: boolean; id: any } }) => {
    onChangeCheckBox('inquiry.application.outletPractitioner.isClientGroupAboriginal', setValue, event);
    trigger(); // re-validate
};

const onChangeClientGroupTorresStrait = (setValue: any, trigger: any) => (event: { target: { checked: boolean; id: any } }) => {
    onChangeCheckBox('inquiry.application.outletPractitioner.isClientGroupTorresStrait', setValue, event);
    trigger(); // re-validate
};

const onChangeClientGroupCulturallyDiverse = (setValue: any, trigger: any) => (event: { target: { checked: boolean; id: any } }) => {
    onChangeCheckBox('inquiry.application.outletPractitioner.isClientGroupCulturallyDiverse', setValue, event);
    trigger(); // re-validate
};

const onChangeOtherServicePractitioner = (setValue: any) => (event: { target: { checked: boolean; id: any } }) => {
    onChangeCheckBox('inquiry.application.outletPractitioner.isOtherServicePractitioners', setValue, event);
};

const onChangeOtherServiceStudent = (setValue: any) => (event: { target: { checked: boolean; id: any } }) => {
    onChangeCheckBox('inquiry.application.outletPractitioner.isOtherServiceStudents', setValue, event);
};

export const serviceDeliveryCheckBoxDisplay = (inquiry: Inquiry, setValue: any, control: any, register: any, errors: any) => {
    return (
        <Row>
            <Row className={controlMaxWidth}>
                <Col className={checkBoxGroupLegend}>
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isServiceDeliveryFaceToFace'
                        displayName='Mode of delivery'
                        fieldLabel={GUIDANCETEXT_OUTLET_MODESOFDELIVERY}
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isServiceDeliveryFaceToFace
                                ? inquiry.application.outletPractitioner.isServiceDeliveryFaceToFace
                                : undefined
                        } // only set default if value exists
                        isMandatory={true}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsServiceDeliveryFaceToFace}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isServiceDeliveryFaceToFace}
                        onChange={onChangeModeOfDeliveryFaceToFace(setValue)}
                    />
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isServiceDeliveryPhone'
                        displayName=''
                        fieldLabel=''
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isServiceDeliveryPhone
                                ? inquiry.application.outletPractitioner.isServiceDeliveryPhone
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsServiceDeliveryPhone}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isServiceDeliveryPhone}
                        onChange={onChangeModeOfDeliveryPhone(setValue)}
                    />
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isServiceDeliveryOnline'
                        displayName=''
                        fieldLabel=''
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isServiceDeliveryOnline
                                ? inquiry.application.outletPractitioner.isServiceDeliveryOnline
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsServiceDeliveryOnline}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isServiceDeliveryOnline}
                        onChange={onChangeModeOfDeliveryOnline(setValue)}
                    />
                </Col>
            </Row>
        </Row>
    );
};

export const additionalDeliveryCheckBoxDisplay = (inquiry: Inquiry, setValue: any, control: any, register: any, errors: any) => {
    return (
        <Row>
            <Row className={controlMaxWidth}>
                <Col className={checkBoxGroupLegend}>
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isAdditionalComediation'
                        displayName='Additional modes of delivery'
                        fieldLabel={GUIDANCETEXT_OUTLET_ADDITIONALMODESDELIVERY}
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isAdditionalComediation
                                ? inquiry.application.outletPractitioner.isAdditionalComediation
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsAdditionalDeliveryComediation}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isAdditionalComediation}
                        onChange={onChangeModeOfAdditionalDeliveryComediation(setValue)}
                    />
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isAdditionalGenderBalanced'
                        displayName=''
                        fieldLabel=''
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isAdditionalGenderBalanced
                                ? inquiry.application.outletPractitioner.isAdditionalGenderBalanced
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsAdditionalDeliveryGenderBalanced}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isAdditionalGenderBalanced}
                        onChange={onChangeModeOfAdditionalDeliveryGenderBalanced(setValue)}
                    />
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isAdditionalLegallyAssist'
                        displayName=''
                        fieldLabel=''
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isAdditionalLegallyAssist
                                ? inquiry.application.outletPractitioner.isAdditionalLegallyAssist
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsAdditionalDeliveryLegallyAssist}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isAdditionalLegallyAssist}
                        onChange={onChangeModeOfDeliveryLegallyAssist(setValue)}
                    />
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isAdditionalChildInclusive'
                        displayName=''
                        fieldLabel=''
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isAdditionalChildInclusive
                                ? inquiry.application.outletPractitioner.isAdditionalChildInclusive
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsAdditionalDeliveryChildInclusive}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isAdditionalChildInclusive}
                        onChange={onChangeModeOfAdditionalDeliveryChildInclusive(setValue)}
                    />
                </Col>
            </Row>
        </Row>
    );
};

export const clientServicesCheckBoxDisplay = (inquiry: Inquiry, setValue: any, control: any, register: any, errors: any) => {
    return (
        <Row>
            <Row className={controlMaxWidth}>
                <Col className={checkBoxGroupLegend}>
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isScopeChildSupport'
                        displayName='Additional client services'
                        fieldLabel=''
                        guidanceText={GUIDANCETEXT_OUTLET_CLIENTSERVICES}
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isScopeChildSupport
                                ? inquiry.application.outletPractitioner.isScopeChildSupport
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsScopeChildSupport}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isScopeChildSupport}
                        onChange={onChangeModeOfScopeChildSupport(setValue)}
                    />
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isScopeProperty'
                        displayName=''
                        fieldLabel=''
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isScopeProperty
                                ? inquiry.application.outletPractitioner.isScopeProperty
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsScopeProperty}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isScopeProperty}
                        onChange={onChangeModeOfScopeProperty(setValue)}
                    />
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isScopeTheHague'
                        displayName=''
                        fieldLabel=''
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isScopeTheHague
                                ? inquiry.application.outletPractitioner.isScopeTheHague
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsScopeTheHague}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isScopeTheHague}
                        onChange={onChangeModeOfScopeTheHague(setValue)}
                    />
                </Col>
            </Row>
        </Row>
    );
};

export const clientGroupsCheckBoxDisplay = (inquiry: Inquiry, setValue: any, trigger: any, control: any, register: any, errors: any) => {
    return (
        <Row>
            <Row className={controlMaxWidth}>
                <Col className={checkBoxGroupLegend}>
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isClientGroupLgbtqi'
                        displayName='Client Groups'
                        fieldLabel=''
                        guidanceText={GUIDANCETEXT_OUTLET_CLIENTGROUPS}
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isClientGroupLgbtqi
                                ? inquiry.application.outletPractitioner.isClientGroupLgbtqi
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsClientGroupLgbtqi}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isClientGroupLgbtqi}
                        onChange={onChangeClientGroupLgbtqi(setValue)}
                    />
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isClientGroupCarers'
                        displayName=''
                        fieldLabel=''
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isClientGroupCarers
                                ? inquiry.application.outletPractitioner.isClientGroupCarers
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsClientGroupCarers}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isClientGroupCarers}
                        onChange={onChangeClientGroupCarers(setValue)}
                    />
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isClientGroupAboriginal'
                        displayName=''
                        fieldLabel=''
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isClientGroupAboriginal
                                ? inquiry.application.outletPractitioner.isClientGroupAboriginal
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsClientGroupAboriginal}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isClientGroupAboriginal}
                        onChange={onChangeClientGroupAboriginal(setValue, trigger)}
                    />
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isClientGroupTorresStrait'
                        displayName=''
                        fieldLabel=''
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isClientGroupTorresStrait
                                ? inquiry.application.outletPractitioner.isClientGroupTorresStrait
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsClientGroupTorresStrait}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isClientGroupTorresStrait}
                        onChange={onChangeClientGroupTorresStrait(setValue, trigger)}
                    />
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isClientGroupCulturallyDiverse'
                        displayName=''
                        fieldLabel=''
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isClientGroupCulturallyDiverse
                                ? inquiry.application.outletPractitioner.isClientGroupCulturallyDiverse
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsClientGroupCulturallyDiverse}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isClientGroupCulturallyDiverse}
                        onChange={onChangeClientGroupCulturallyDiverse(setValue, trigger)}
                    />
                </Col>
            </Row>
        </Row>
    );
};

const errorFieldCulturalLanguageReligionMandatory = (errors: any) => {
    // const { fieldTitle, fieldValidationError, validationErrorRow, fieldFinalRow, labelNextToTextInput } = stylesField;
    const { fieldValidationError } = stylesField;
    const errorsField = errors.inquiry?.application?.outletPractitioner.culturalLanguageReligion;

    return (
        <Row>
            <Col className={fieldValidationError}>{errorsField && <span role='alert'>{errorsField.message}</span>}</Col>
        </Row>
    );
};

export const cultureDiversityCheckBoxDisplay = (inquiry: Inquiry, register: any, errors: any) => {
    // const isCultureGroupSpecific = inquiry.application.outletPractitioner?.isCultureGroupSpecific;
    // const isLanguageSpecific = inquiry.application.outletPractitioner?.isLanguageSpecific;
    // const isReligionSpecific = inquiry.application.outletPractitioner?.isReligionSpecific;

    return (
        <Row>
            <Row className={controlMaxWidth}>
                <Col className={checkBoxGroupLegend}>
                    <LabelField
                        id='culturalLanguageReligiousHeaderLabel'
                        displayName='Cultural, linguistic and religious diversity'
                        isMandatory={true}
                        value=''
                        children={errorFieldCulturalLanguageReligionMandatory(errors)}
                    />
                    <InputTextField
                        id='inquiry.application.outletPractitioner.cultureGroupSpecificDetail'
                        displayName='Culture group specific'
                        defaultValue={inquiry.application.outletPractitioner?.cultureGroupSpecificDetail}
                        isMandatory={false}
                        capitaliseTextOff={true}
                        placeHolder={PLACEHOLDER_OUTLET_CULTUREGROUPSPECIFICDETAIL}
                        register={register}
                        helpHeaderText={HELPHEADERTEXT_OUTLET_CULTUREGROUP_SPECIFIC}
                        helpText={HELPTEXT_OUTLET_CULTUREGROUP_SPECIFIC}
                        maxlength={255}
                        // onKeyUp={(event: any) => toggleCultureGroupCheckBox(isCultureGroupSpecific, setValue, event)} // onKeyUp is not covering all cases for example select from previous in browser, and it then doesnt always paint check box
                        errorsField={errors.inquiry?.application?.outletPractitioner?.cultureGroupSpecificDetail}
                    />
                    <InputTextField
                        id='inquiry.application.outletPractitioner.languageSpecificDetail'
                        displayName='Language specific'
                        defaultValue={inquiry.application.outletPractitioner?.languageSpecificDetail}
                        isMandatory={false}
                        capitaliseTextOff={true}
                        placeHolder={PLACEHOLDER_OUTLET_LANGUAGESPECIFICDETAIL}
                        register={register}
                        helpHeaderText={HELPHEADERTEXT_OUTLET_LANGUAGE_SPECIFIC}
                        helpText={HELPTEXT_OUTLET_LANGUAGE_SPECIFIC}
                        maxlength={255}
                        // onKeyUp={(event: any) => toggleLanguageCheckBox(isLanguageSpecific, trigger, setValue, event)} // onKeyUp is not covering all cases for example select from previous in browser, and it then doesnt always paint check box
                        errorsField={errors.inquiry?.application?.outletPractitioner?.languageSpecificDetail}
                    />
                    <InputTextField
                        id='inquiry.application.outletPractitioner.religionSpecificDetail'
                        displayName='Religion specific or spiritual beliefs'
                        defaultValue={inquiry.application.outletPractitioner?.religionSpecificDetail}
                        isMandatory={false}
                        capitaliseTextOff={true}
                        placeHolder={PLACEHOLDER_OUTLET_RELIGIONSPECIFICDETAIL}
                        register={register}
                        helpHeaderText={HELPHEADERTEXT_OUTLET_RELIGION_SPECIFIC}
                        helpText={HELPTEXT_OUTLET_RELIGION_SPECIFIC}
                        maxlength={255}
                        // onKeyUp={(event: any) => toggleReligionCheckBox(isReligionSpecific, setValue, event)} // onKeyUp is not covering all cases for example select from previous in browser, and it then doesnt always paint check box
                        errorsField={errors.inquiry?.application?.outletPractitioner?.religionSpecificDetail}
                    />
                </Col>
            </Row>
        </Row>
    );
};

export const otherServicesCheckBoxDisplay = (inquiry: Inquiry, setValue: any, control: any, register: any, errors: any) => {
    return (
        <Row>
            <Row className={controlMaxWidth}>
                <Col className={checkBoxGroupLegend}>
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isOtherServicePractitioners'
                        displayName='Other Services'
                        guidanceText={GUIDANCETEXT_OUTLET_OTHERSERVICES}
                        fieldLabel=''
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isOtherServicePractitioners
                                ? inquiry.application.outletPractitioner.isOtherServicePractitioners
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsOtherServicePractitioner}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isOtherServicePractitioners}
                        onChange={onChangeOtherServicePractitioner(setValue)}
                    />
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isOtherServiceStudents'
                        displayName=''
                        fieldLabel=''
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isOtherServiceStudents
                                ? inquiry.application.outletPractitioner.isOtherServiceStudents
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsOtherServiceStudent}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isOtherServiceStudents}
                        onChange={onChangeOtherServiceStudent(setValue)}
                    />
                </Col>
            </Row>
        </Row>
    );
};
