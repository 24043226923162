import { AuthenticationResult } from '@azure/msal-browser';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
// import FormData from 'form-data';
import AuthService from '../services/AuthService';
import { IAttachmentMetadata } from './interfacesApi';

class FileApiService {
    authService: AuthService;
    instance: AxiosInstance;

    constructor(authService: AuthService) {
        this.authService = authService;
        // Set config defaults when creating the instance
        const api = process.env.NODE_ENV === 'development' ? 'https://localhost:44369/' : '/';
        this.instance = axios.create({
            baseURL: api,
        });
    }

    makeFetchFileRequest = async (instance: AxiosInstance, requestUrl: string, config: AxiosRequestConfig) => {
        try {
            const result = await instance.get(requestUrl, config);
            return result;
        } catch (err) {
            throw err;
            // tslint:disable-next-line: no-empty
        } finally {
        }
    };

    makeStandardGetRequest = (endpoint: string) => {
        const options: AxiosRequestConfig = {
            method: 'GET',
            headers: new Headers({
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json;charset=utf-8',
            }),
            // `timeout` specifies the number of milliseconds before the request times out.
            // If the request takes longer than `timeout`, the request will be aborted.
            timeout: 10000, // default is `0` (no timeout)
            // // `withCredentials` indicates whether or not cross-site Access-Control requests
            // // should be made using credentials
            // // withCredentials: true, // default
            // `responseType` indicates the type of data that the server will respond with
            // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
            //   browser only: 'blob'
            responseType: 'blob', // default = json
        };

        return this.authService
        .GetToken()
           .then((response: AuthenticationResult) => {
        // Alter defaults after instance has been created
        const AUTH_TOKEN = 'Bearer ' + response.accessToken;
        // see: https://github.com/axios/axios/issues/341
        this.instance.defaults.headers.common.Authorization = AUTH_TOKEN;
        options.headers.authorization = AUTH_TOKEN;

        return this.makeFetchFileRequest(this.instance, endpoint, options)
            .then(result => {
                return result.data;
            })
            .catch((error: any) => {
                throw error;
            });
        })
        .catch((error: any) => {
            throw Error('An error has occurred: ' + error);
        });
    };

    makeStandardPostRequest<T>(endpoint: string, metadata: IAttachmentMetadata, body: Blob, onUploadProgress: any): Promise<AxiosResponse<T>> {
        // or see: https://stackoverflow.com/questions/57398438/axios-wont-let-me-set-a-content-type-when-uploading-a-file
        const data = new FormData();
        data.append('filetoUpload', body);
        data.append('key', JSON.stringify(metadata));

        const options: AxiosRequestConfig = {
            method: 'POST',
            // data: body,
            headers: new Headers({
                'Content-Type': 'multipart/form-data', // see: https://github.com/axios/axios/issues/318
            }),
            // `timeout` specifies the number of milliseconds before the request times out.
            // If the request takes longer than `timeout`, the request will be aborted.
            // timeout: 10000, // default is `0` (no timeout)
            // `responseType` indicates the type of data that the server will respond with
            // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
            //   browser only: 'blob'
            responseType: 'json', // default = json
            onUploadProgress,
        };

        return this.authService
          .GetToken()
          .then((response: AuthenticationResult) => {
        // Alter defaults after instance has been created
              const AUTH_TOKEN = 'Bearer ' + response.accessToken;
              this.instance.defaults.headers.common.Authorization = AUTH_TOKEN;
              options.headers.authorization = AUTH_TOKEN;

        return this.instance
            .post(endpoint, data, options)
            .then((res: any) => {
                if (res.status === 200) {
                    return res.data as T;
                } else {
                    return res.data.then((err: any) => {
                        throw err;
                    });
                }
            })
            .then(result => {
                return result;
            })
            .catch((error: any) => {
                throw error;
            });
           })
           .catch((error: any) => {
               throw Error('An error has occurred: ' + error);
           });
    }

    /* 
    makeStandardDeleteRequest = (endpoint: string) => {
        const request = new Request(endpoint, {
            method: 'DELETE',
            mode: 'cors',
            headers: new Headers({
                'Content-Type': 'application/json;charset=utf-8',
            }),
        });
        return this.makeFetchRequest(request);
    };

    makeStandardPutRequest<T>(endpoint: string, body: object) {
        const request = new Request(endpoint, {
            method: 'PUT',
            mode: 'cors',
            body: JSON.stringify(body),
            headers: new Headers({
                'Content-Type': 'application/json;charset=utf-8',
            }),
        });
        return this.makeFetchRequest<T>(request);
    }*/
}

export default FileApiService;
