import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import SiteLogo from '../site/SiteLogo';
import AuthService from '../../services/AuthService';
import AppContext from '../../stateManagement/context/AppContext';
import HeaderMenuContainer from '../../components/site/HeaderMenuContainer';
import MobileMenuContainer from '../site/MobileMenuContainer';
import BannerComponent from './bannerComponent';
import './SiteHeader.module.scss';

interface SiteHeaderProps {
    authService: AuthService;
}

function SiteHeader(props: SiteHeaderProps) {
    const appContext = useContext(AppContext);

    return (
        <Router>
            <nav className='navbar navbar-expand-md' id='header' data-testid='header'>
                <SiteLogo />
                <HeaderMenuContainer />
                <MobileMenuContainer appContext={appContext} />
            </nav>
            <BannerComponent authService={props.authService} />
        </Router>
    );
}

export default SiteHeader;
