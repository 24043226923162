// Review and Submit page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the Declaration and Consent page
//  - allows SUBMIT of form, plus navigation to any other page
import React, { useContext, useEffect, useMemo, useState } from 'react';
// tslint:disable-next-line: no-submodule-imports
import { renderToString } from 'react-dom/server';
import { RegoApplicationPageProps } from '../../PageInterfaces';
import AppContext from '../../../stateManagement/context/AppContext';
import { toastErrorMessage } from '../../../actions/toastrMessages';
import ApplicationActions from '../../../actions/applicationActions';
import Loader from '../../../controls/Loader';
import ModalDialog from '../../../controls/ModalDialog';
import { EmptyRow } from '../../../controls/EmptyRow';
import PageWrapper from '../../../components/pageWrapper';
import PageTitle from '../../../components/pageTitle';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import {
    HEADER_REVIEWANDSUBMIT,
    MODALHEADER_PRESUBMITAPPLICATION_ERROR,
    MODALHEADER_RESUBMITAPPLICATION_ERROR,
    MODALHEADER_RESUBMITAPPLICATION_OK,
    MODALHEADER_SUBMITAPPLICATION_ERROR,
    MODALHEADER_SUBMITAPPLICATION_OK,
    MODALHEADER_SUBMITAPPLICATION_TIMEOUT,
    MODALTEXT_PRESUBMITAPPLICATION_ERROR,
    MODALTEXT_RESUBMITAPPLICATION_ERROR,
    MODALTEXT_RESUBMITAPPLICATION_OK,
    MODALTEXT_SUBMITAPPLICATION_ERROR,
    MODALTEXT_SUBMITAPPLICATION_OK,
    MODALTEXT_SUBMITAPPLICATION_TIMEOUT_P1,
    MODALTEXT_SUBMITAPPLICATION_TIMEOUT_P2,
    MODALTEXT_RESUBMITAPPLICATION_TIMEOUT_P1,
    MODALTEXT_RESUBMITAPPLICATION_TIMEOUT_P2,
    MODALHEADER_RESUBMITAPPLICATION_TIMEOUT,
    MODALTEXT_SUBMITAPPLICATION_OK_P2,
} from '../../../services/Constants';
import { VALIDATION_ERROR_SUBMIT_TIMEOUT, VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../../services/Validation';
import { AjaxCallStateEnum, WizardMode, WizardStepNumber } from '../../../services/Enums';
import PersonalDetailsNameDisplay from '../../../fieldLayout/personalDetailsNameDisplay';
import PersonalDetailsContactDisplay from '../../../fieldLayout/personalDetailsContactDisplay';
import PastApplicationHistoryDisplay from './pastApplicationHistoryDisplay';
import CompetenciesDisplay from './competenciesDisplay';
import ChildRelatedDisplay from './childRelatedDisplay';
import CriminalConvictionsDisplay from './criminalConvictionsDisplay';
import ComplaintsMechanismDisplay from './complaintsMechanismDisplay';
import ProfessionalIndemnityDisplay from './professionalIndemnityDisplay';
import ConsentDisplay from './ConsentDisplay';
import { ErrorOnSubmitForm } from '../../../utils/AppUtils';
import ChangeOfNameDisplay from './changeOfNameDisplay';
import ConsentPublishDisplay from './consentPublishDisplay';

interface AttemptingSubmitState {
    attemptingSubmit: boolean;
    attemptingResubmit: boolean;
    showSubmittedDialogOK: boolean;
    showSubmittedDialogError: boolean;
    showPreSubmittedDialogError: boolean;
    showResubmittedDialogOK: boolean;
    showResubmittedDialogError: boolean;
    showTimeoutDialogSubmit: boolean;
    showTimeoutDialogResubmit: boolean;
}

const ReviewAndSubmitPage = (props: RegoApplicationPageProps) => {
    const { inquiry, authService, dispatch, handleClickPrevious, handleSubmit, register, isActive, ajaxCallStateForPage, backEndValidationErrors, setError } =
        props;

    const appContext = useContext(AppContext);
    const isResubmit = appContext.wizardState.wizardMode === WizardMode.Resubmit ? true : false;
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const initialAttemptingSubmitState: AttemptingSubmitState = {
        attemptingSubmit: false,
        attemptingResubmit: false,
        showSubmittedDialogOK: false,
        showSubmittedDialogError: false,
        showPreSubmittedDialogError: false,
        showResubmittedDialogOK: false,
        showResubmittedDialogError: false,
        showTimeoutDialogSubmit: false,
        showTimeoutDialogResubmit: false,
    };
    const [attemptingSubmitState, setAttemptingSubmitState] = useState<AttemptingSubmitState>(initialAttemptingSubmitState);

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors.errors?.length > 0) {
            backEndValidationErrors.errors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'CompleteWorkingWithChildrenCheck':
                        setError('inquiry.application.completeworkingwithchildrencheck', { type: 'manual', message: validationError.errorMessage });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
        // error - get out of here!
    };

    // check dispatch success state
    useEffect(() => {
        if (isActive === true && ajaxCallStateForPage && ajaxCallStateForPage.pageNumber === WizardStepNumber.ReviewAndSubmit) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation

                setAttemptingSubmitState(prevState => ({
                    ...prevState,
                    attemptingResubmit: false,
                    attemptingSubmit: false,
                    showResubmittedDialogError: isResubmit,
                    showSubmittedDialogError: !isResubmit,
                    showTimeoutDialogSubmit: false, // explicit
                    showTimeoutDialogResubmit: false, // explicit
                }));

                displayBackEndValidations();
            } else if (
                ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.Timeout ||
                ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError
            ) {
                // show potential issue feedback
                setAttemptingSubmitState(prevState => ({
                    ...prevState,
                    attemptingResubmit: false,
                    attemptingSubmit: false,
                    showResubmittedDialogError: false,
                    showSubmittedDialogError: false,
                    showPreSubmittedDialogError: false,
                    showTimeoutDialogSubmit: !isResubmit,
                    showTimeoutDialogResubmit: isResubmit,
                }));

                toastErrorMessage(VALIDATION_ERROR_SUBMIT_TIMEOUT);
                /* } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // show error feedback
                setAttemptingSubmitState(prevState => ({
                    ...prevState,
                    attemptingResubmit: false,
                    attemptingSubmit: false,
                    showResubmittedDialogError: isResubmit,
                    showSubmittedDialogError: !isResubmit,
                    showPreSubmittedDialogError: false,
                    showTimeoutDialog: false,
                }));

                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE); */
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (attemptingSubmitState.attemptingSubmit === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK) {
                    // pop up success dialog and then decide what flow to go to next
                    setAttemptingSubmitState(prevState => ({
                        ...prevState,
                        attemptingResubmit: false,
                        attemptingSubmit: false,
                        showResubmittedDialogOK: isResubmit,
                        showSubmittedDialogOK: !isResubmit,
                        showPreSubmittedDialogError: false,
                        showSubmittedDialogError: false, // explicit
                        showResubmittedDialogError: false, // explicit
                        showTimeoutDialogSubmit: false, // explicit
                        showTimeoutDialogResubmit: false, // explicit
                    }));
                }
                if (attemptingSubmitState.attemptingResubmit === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK) {
                    // pop up success dialog and then decide what flow to go to next
                    setAttemptingSubmitState(prevState => ({
                        ...prevState,
                        attemptingResubmit: false,
                        attemptingSubmit: false,
                        showResubmittedDialogOK: isResubmit,
                        showSubmittedDialogOK: !isResubmit,
                        showPreSubmittedDialogError: false,
                        showSubmittedDialogError: false, // explicit
                        showResubmittedDialogError: false, // explicit
                        showTimeoutDialogSubmit: false, // explicit
                        showTimeoutDialogResubmit: false, // explicit
                    }));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const submitForm = (data: any) => {
        try {
            // output content to HTML string to be able to submit and thus generate to PDF on server-side
            const formatForPDF = true;
            const htmlOutput = renderToString(applicationSummaryContent(formatForPDF, isResubmit)); // hide linkToPage component
            data.inquiry.applicationSubmit.htmlSummary = htmlOutput.toString(); // seth the HtmlSummary field then submit the object so they line up with API
            data.inquiry.applicationSubmit.isResubmitted = isResubmit;

            try {
                // submit now that we have the HTML output for PDF generation
                setAttemptingSubmitState(prevState => ({ ...prevState, attemptingResubmit: isResubmit, attemptingSubmit: !isResubmit }));
                dispatch(appActions.SubmitApplication(data.inquiry.applicationSubmit));
            } catch (err) {
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                setAttemptingSubmitState(prevState => ({
                    ...prevState,
                    attemptingResubmit: false,
                    attemptingSubmit: false,
                    showResubmittedDialogOK: isResubmit,
                    showSubmittedDialogError: !isResubmit,
                    showPreSubmittedDialogError: false,
                    showTimeoutDialogSubmit: false, // explicit
                    showTimeoutDialogResubmit: false, // explicit
                }));
            }
        } catch (err) {
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            setAttemptingSubmitState(prevState => ({
                ...prevState,
                attemptingResubmit: false,
                attemptingSubmit: false,
                showResubmittedDialogOK: false,
                showResubmittedDialogError: false,
                showSubmittedDialogOK: false,
                showSubmittedDialogError: false,
                showPreSubmittedDialogError: true, // ensure we only show this one, we did NOT attempt a submit!
                showTimeoutDialogSubmit: false, // explicit
                showTimeoutDialogResubmit: false, // explicit
            }));
        }
    };

    const redirectToDashboard = () => {
        setAttemptingSubmitState(initialAttemptingSubmitState);
        window.location.href = '/';
    };

    const applicationSummaryContent = (formatForPDF: boolean, isResubmit: boolean) => {
        return (
            <>
                {formatForPDF === true && (
                    <>
                        <EmptyRow />
                        <p>Your application to become an FDR practitioner has been received. Details submitted in your application are as follows.</p>
                    </>
                )}
                <PageFieldsTitle smallerHeader={formatForPDF} title='Application for accreditation as an FDR practitioner' />
                <PersonalDetailsNameDisplay inquiry={inquiry} formatForPDF={formatForPDF} />
                <PersonalDetailsContactDisplay inquiry={inquiry} formatForPDF={formatForPDF} />
                <PastApplicationHistoryDisplay inquiry={inquiry} formatForPDF={formatForPDF} />
                <CompetenciesDisplay inquiry={inquiry} formatForPDF={formatForPDF} />
                <ChildRelatedDisplay inquiry={inquiry} formatForPDF={formatForPDF} />
                <CriminalConvictionsDisplay inquiry={inquiry} formatForPDF={formatForPDF} />
                <ComplaintsMechanismDisplay inquiry={inquiry} formatForPDF={formatForPDF} />
                <ProfessionalIndemnityDisplay inquiry={inquiry} formatForPDF={formatForPDF} />
                <ConsentPublishDisplay inquiry={inquiry} formatForPDF={formatForPDF} />
                <ChangeOfNameDisplay inquiry={inquiry} formatForPDF={formatForPDF} />
                {formatForPDF === true && (
                    <>
                        <EmptyRow />
                        <EmptyRow />
                        <EmptyRow />
                        <hr />
                        <EmptyRow />
                        <EmptyRow />
                        <EmptyRow />
                    </>
                )}
                {formatForPDF === true && <ConsentDisplay inquiry={inquiry} isInitialApplication={true} showDateOnFileList={isResubmit} />}
            </>
        );
    };

    const formatForPDF = false;

    return (
        <>
            <PageTitle title='Review and submit' description={HEADER_REVIEWANDSUBMIT} />
            <PageWrapper
                pageName='Review and submit'
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={handleClickPrevious}
                textForSaveContinueButton='submit'
                textForBackButton={isResubmit === true ? 'Discard' : undefined}
            >
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.applicationId'
                    {...register('inquiry.application.applicationId')}
                    defaultValue={inquiry.application.applicationId}
                />
                <Loader
                    isLoading={attemptingSubmitState.attemptingSubmit === true || attemptingSubmitState.attemptingResubmit === true}
                    loaderText='Submitting your application. Please wait...'
                />
                {applicationSummaryContent(formatForPDF, isResubmit)}
                <ModalDialog
                    modalTitle={MODALHEADER_SUBMITAPPLICATION_OK}
                    modalBodyText={MODALTEXT_SUBMITAPPLICATION_OK}
                    modalBodyTextP2={MODALTEXT_SUBMITAPPLICATION_OK_P2}
                    showMe={attemptingSubmitState.showSubmittedDialogOK}
                    showOkOnly={true}
                    handleClickOk={redirectToDashboard}
                    dataTestId='ModalSubmittedOk'
                />
                <ModalDialog
                    modalTitle={MODALHEADER_SUBMITAPPLICATION_ERROR}
                    modalBodyText={MODALTEXT_SUBMITAPPLICATION_ERROR}
                    showMe={attemptingSubmitState.showSubmittedDialogError}
                    showOkOnly={true}
                    handleClickOk={redirectToDashboard}
                />
                <ModalDialog
                    modalTitle={MODALHEADER_SUBMITAPPLICATION_TIMEOUT}
                    modalBodyText={MODALTEXT_SUBMITAPPLICATION_TIMEOUT_P1}
                    modalBodyTextP2={MODALTEXT_SUBMITAPPLICATION_TIMEOUT_P2}
                    showMe={attemptingSubmitState.showTimeoutDialogSubmit}
                    showOkOnly={true}
                    handleClickOk={redirectToDashboard}
                />
                <ModalDialog
                    modalTitle={MODALHEADER_RESUBMITAPPLICATION_TIMEOUT}
                    modalBodyText={MODALTEXT_RESUBMITAPPLICATION_TIMEOUT_P1}
                    modalBodyTextP2={MODALTEXT_RESUBMITAPPLICATION_TIMEOUT_P2}
                    showMe={attemptingSubmitState.showTimeoutDialogResubmit}
                    showOkOnly={true}
                    handleClickOk={redirectToDashboard}
                />
                <ModalDialog
                    modalTitle={MODALHEADER_PRESUBMITAPPLICATION_ERROR}
                    modalBodyText={MODALTEXT_PRESUBMITAPPLICATION_ERROR}
                    showMe={attemptingSubmitState.showPreSubmittedDialogError}
                    showOkOnly={true}
                    handleClickOk={redirectToDashboard}
                />
                <ModalDialog
                    modalTitle={MODALHEADER_RESUBMITAPPLICATION_OK}
                    modalBodyText={MODALTEXT_RESUBMITAPPLICATION_OK}
                    showMe={attemptingSubmitState.showResubmittedDialogOK}
                    showOkOnly={true}
                    handleClickOk={redirectToDashboard}
                    dataTestId='ModalResubmittedOk'
                />
                <ModalDialog
                    modalTitle={MODALHEADER_RESUBMITAPPLICATION_ERROR}
                    modalBodyText={MODALTEXT_RESUBMITAPPLICATION_ERROR}
                    showMe={attemptingSubmitState.showResubmittedDialogError}
                    showOkOnly={true}
                    handleClickOk={redirectToDashboard}
                />
            </PageWrapper>
        </>
    );
};

export default ReviewAndSubmitPage;
