import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import LabelField from '../components/fields/LabelField';
import styles from './Controls.module.scss';

const {
    buttonsRegionContainer,
    buttonsRegionTitleCol,
    buttonsRegionPrimaryCol,
    buttonWide,
    buttonPrimary,
    buttonPrimaryHover,
    buttonDestructiveHover,
    buttonSecondary,
    buttonSecondaryHover,
} = styles;

interface ButtonsRegionControlProps {
    title: string;
    description?: string;
    primaryButtonText: string;
    primaryButtonClickEvent: any;
    showSecondaryButton1?: boolean;
    secondaryButton1Text?: string;
    secondaryButton1ClickEvent?: any;
    secondaryButton1IsDestructive?: boolean;
    showSecondaryButton2?: boolean;
    secondaryButton2Text?: string;
    secondaryButton2ClickEvent?: any;
    secondaryButton2IsDestructive?: boolean;
}

const ButtonsRegionControl = (props: ButtonsRegionControlProps) => {
    const {
        title,
        description,
        primaryButtonText,
        primaryButtonClickEvent,
        showSecondaryButton1,
        secondaryButton1Text,
        secondaryButton1ClickEvent,
        secondaryButton1IsDestructive,
        showSecondaryButton2,
        secondaryButton2Text,
        secondaryButton2ClickEvent,
        secondaryButton2IsDestructive,
    } = props;

    const descText = description ?? '';
    const secondaryButton1Class = secondaryButton1IsDestructive
        ? `btn ${buttonWide} ${buttonSecondary} ${buttonDestructiveHover}`
        : `btn ${buttonWide} ${buttonSecondary} ${buttonSecondaryHover}`;
    const secondaryButton2Class = secondaryButton2IsDestructive
        ? `btn ${buttonWide} ${buttonSecondary} ${buttonDestructiveHover}`
        : `btn ${buttonWide} ${buttonSecondary} ${buttonSecondaryHover}`;

    return (
        <div className={buttonsRegionContainer}>
            <Row>
                <Col className={buttonsRegionTitleCol}>
                    <LabelField
                        id='buttonsRegionControlTitle'
                        displayName={title}
                        value={descText}
                        isMandatory={false}
                        isDisplayNameFontSizeLarger={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col className={buttonsRegionPrimaryCol}>
                    <Button
                        variant='primary'
                        onClick={primaryButtonClickEvent}
                        className={`btn ${buttonPrimary} ${buttonPrimaryHover} ${buttonWide}`}
                        data-testid={`primaryBtn`}
                    >
                        {primaryButtonText}
                    </Button>
                </Col>
                {showSecondaryButton1 === true && (
                    <Col>
                        <Button onClick={secondaryButton1ClickEvent} className={`${secondaryButton1Class}`} data-testid={`secondaryButton1`}>
                            {secondaryButton1Text}
                        </Button>
                    </Col>
                )}
                {showSecondaryButton2 === true && (
                    <Col>
                        <Button onClick={secondaryButton2ClickEvent} className={`${secondaryButton2Class}`} data-testid={`secondaryButton2`}>
                            {secondaryButton2Text}
                        </Button>
                    </Col>
                )}
                <Col md={6} />
            </Row>
        </div>
    );
};

export default ButtonsRegionControl;
