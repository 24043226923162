// Criminal convictions page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the Child-related employment page
//  - allows navigation forward to the Complaints mechanism-Type page
import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { get } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { RegoApplicationPageProps } from '../../PageInterfaces';
import { EnsureData } from '../RegistrationApplicationWizard/wizardHelperFunctions';
import { GetTextForSaveAndContinueButton, HideTheBackButton, SubmitThisPageIfActive } from '../../PageFunctions';
import { MoveWizardForward, MoveWizardForwardForResubmit } from '../../../components/wizard/wizardStepFunctions';
import { VALIDATION_ERROR_POPUP_MESSAGE, VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../../services/Validation';
import ApplicationActions from '../../../actions/applicationActions';
import { toastErrorMessage } from '../../../actions/toastrMessages';
import { ParseBool } from '../../../utils/Common';
import { HEADER_CRIMINALCONVICTIONS, NOTE_CRIMINAL_MESSAGE, NOTE_CRIMINAL_MESSAGE_P2 } from '../../../services/Constants';
import { AjaxCallStateEnum, AttachmentDocumentType, WizardMode, WizardStepNumber } from '../../../services/Enums';
import ErrorSummary from '../../../controls/ErrorSummary';
import NoteText from '../../../controls/NoteText';
import AppContext from '../../../stateManagement/context/AppContext';
import PageWrapper from '../../../components/pageWrapper';
import PageTitle from '../../../components/pageTitle';
import PageMandatoryLabelText from '../../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import FileUploadField from '../../../components/fields/FileUploadField';
import RadioButtonGroupField, { RadioButtonOption } from '../../../components/fields/RadioButtonGroupField';
import styles from '../../Pages.module.scss';
import { ErrorOnSubmitForm } from '../../../utils/AppUtils';
import { InsertAttachmentToLocalInquiryObject, RemoveAttachmentFromLocalInquiryObject } from '../../../utils/ComplaintsUtils';
import { IAttachmentMetadata } from '../../../api/interfacesApi';

const { convictionsWarningNote } = styles;

const CriminalConvictionsPage = (props: RegoApplicationPageProps) => {
    const {
        inquiry,
        authService,
        goingBackwards,
        dispatch,
        handleClickPrevious,
        handleSubmit,
        clearErrors,
        register,
        setError,
        setValue,
        watch,
        trigger,
        control,
        errors,
        isValid,
        isActive,
        ajaxCallStateForPage,
        backEndValidationErrors,
        triggerSubmit,
        setTriggerSubmit,
    } = props;

    const appContext = useContext(AppContext);
    const disableField = appContext.wizardState.wizardMode === WizardMode.Resubmit ? true : false;
    const formRef = useRef();
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    // even though we have redux states, we HAVE to capture the attempt due to navigation and Get and Save user calls on the page
    const [attemptingSave, setAttemptingSave] = useState(false);

    const [filesExist, setFilesExist] = useState(false);
    const hasOffenceConvictions: boolean = watch('inquiry.application.hasOffenceConvictions', inquiry?.application.hasOffenceConvictions);
    const [showConvictionsWarning, setShowConvictionsWarning] = useState(false);

    const onChangeHasOffenceConvictions = (event: { target: { value: any } }) => {
        // manage the value manually as ref did not work for the react hook form
        setValue('inquiry.application.hasOffenceConvictions', event.target.value, { shouldDirty: true, shouldValidate: true });
    };

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors.errors?.length > 0) {
            backEndValidationErrors.errors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'HasOffenceConvictions':
                        setError('inquiry.application.hasOffenceConvictions', { type: 'manual', message: validationError.errorMessage });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    // check dispatch success state
    useEffect(() => {
        if (isActive === true && ajaxCallStateForPage && ajaxCallStateForPage.pageNumber === WizardStepNumber.CriminalConvictions) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                setAttemptingSave(false);
                displayBackEndValidations();
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setAttemptingSave(false);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (attemptingSave === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK && !appContext.wizardState.triggerSubmit) {
                    // IF VALID (or OK to move forward):
                    setAttemptingSave(false);
                    // refresh data set now after changes saved
                    dispatch(appActions.GetApplication());

                    // Move wizard forward to next step pending whether we are navigating normally or for the resubmit flow
                    if (appContext.wizardState.wizardMode === WizardMode.New) {
                        MoveWizardForward(appContext, isValid);
                    } else {
                        MoveWizardForwardForResubmit(appContext, isValid);
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const submitForm = (data: any) => {
        if (isValid) {
            // check if valid and selection of Yes to question 'type of FDR service' then we need to also check files here

            // just set hasCurrentNationalPoliceCheck on save (all file uploads mandatory)
            data.inquiry.application.hasCurrentNationalPoliceCheck = true;

            // set existing data now as we dont capture it in this page
            EnsureData(appContext, WizardStepNumber.CriminalConvictions, data, inquiry);

            setAttemptingSave(true);
            const updateOutlet = false;
            dispatch(appActions.UpdateApplication(data.inquiry.application, updateOutlet));
        } else {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    SubmitThisPageIfActive(isActive, triggerSubmit, formRef, setTriggerSubmit);

    const removeErrorsAndReValidate = () => {
        // do the trigger only once
        let runTrigger = false;
        const errorAttachments: { [key: string]: any[] } = get(errors, 'inquiry.application.attachments');

        if (errorAttachments && errorAttachments !== undefined) {
            if (errorAttachments.nationalpolicecheck) {
                clearErrors('inquiry.application.attachments.nationalpolicecheck');
                runTrigger = true;
            }
        }

        if (runTrigger === true) {
            trigger(); // reset validation so we dont get a form isValid=false causing inability to save
        }
    };

    useEffect(() => {
        if (hasOffenceConvictions && ParseBool(hasOffenceConvictions) === true) {
            if (showConvictionsWarning === false) {
                setShowConvictionsWarning(true);
            }
        } else {
            if (showConvictionsWarning === true) {
                setShowConvictionsWarning(false);
            }
        }
    }, [hasOffenceConvictions, showConvictionsWarning]);

    // update attachments collection as that is seperate from other data application object call
    const updatedFilesList = (files: any) => {
        if (files && files.length > 0) {
            if (filesExist === false) {
                setFilesExist(true);
                // immediately remove any errors of type file not uploaded (for parent nodes)
                removeErrorsAndReValidate();
            }
        } else {
            if (filesExist === true) {
                setFilesExist(false);
            }
        }
    };

    const removedFile = (attachmentId: string) => {
        // refresh CM object/active obj
        RemoveAttachmentFromLocalInquiryObject(attachmentId, setValue, inquiry);
        trigger(); // re-validate
    };

    const addedFile = (newAttachment: IAttachmentMetadata) => {
        // refresh CM object/active obj (NOTE: CANNOT get App in case user simply adds/removes files, it will clear the form!)
        InsertAttachmentToLocalInquiryObject(newAttachment, setValue, inquiry);
        trigger(); // re-validate
    };

    /*
    const updatedFilesList = (files: any) => {
        // set flag to indicate files exist for National Police Check
        const hasPoliceCheck = files?.length > 0;
        if (
            hasPoliceCheck &&
            hasPoliceCheck === true &&
            (inquiry.application.hasCurrentNationalPoliceCheck === null || inquiry.application.hasCurrentNationalPoliceCheck === false)
        ) {
            // infinite loop as something else sets this to nul (init)
            setValue('inquiry.application.hasCurrentNationalPoliceCheck', true, { shouldDirty: true });
        } else {
            if (inquiry.application.hasCurrentNationalPoliceCheck === true) {
                setValue('inquiry.application.hasCurrentNationalPoliceCheck', false, { shouldDirty: true });
            }
        }
    }; */

    const convictionsWarningNoteDisplay = () => {
        return (
            <Row className={convictionsWarningNote}>
                <Col md='11'>
                    <NoteText displayText={NOTE_CRIMINAL_MESSAGE} displayTextP2={NOTE_CRIMINAL_MESSAGE_P2} />
                </Col>
                <Col md='1' className='justify-content-end' />
            </Row>
        );
    };

    const radioOptionsOffenceConviction: RadioButtonOption[] = [
        { label: 'Yes', value: true, disabled: disableField },
        { label: 'No', value: false, disabled: disableField, children: showConvictionsWarning ? convictionsWarningNoteDisplay() : null },
    ];

    return (
        <>
            <PageTitle title='Criminal convictions' description={HEADER_CRIMINALCONVICTIONS} />
            <PageWrapper
                pageName='Criminal'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={handleClickPrevious}
                hideBackButton={HideTheBackButton(appContext)}
                textForSaveContinueButton={GetTextForSaveAndContinueButton(appContext)}
            >
                <PageFieldsTitle title='Criminal convictions' />
                <PageMandatoryLabelText />
                <ErrorSummary errors={errors} />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.applicationId'
                    {...register('inquiry.application.applicationId')}
                    defaultValue={inquiry?.application.applicationId}
                />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.hasCurrentNationalPoliceCheck'
                    {...register('inquiry.application.hasCurrentNationalPoliceCheck')}
                    defaultValue={inquiry?.application.hasCurrentNationalPoliceCheck}
                />
                <RadioButtonGroupField
                    options={radioOptionsOffenceConviction}
                    id='inquiry.application.hasOffenceConvictions'
                    displayName='Previous offences'
                    fieldLabel='Have you been convicted of an offence involving violence to a person or a sex-related offence?'
                    defaultValue={inquiry?.application.hasOffenceConvictions} // only set default if value exists
                    isMandatory={true}
                    control={control}
                    register={register}
                    errorsField={errors.inquiry?.application?.hasOffenceConvictions}
                    onChange={onChangeHasOffenceConvictions}
                />
                <FileUploadField
                    id='inquiry.application.attachments.nationalpolicecheck'
                    maxFilesCount={3}
                    applicationId={inquiry?.application.applicationId}
                    authService={authService}
                    displayNameSuffix='National Police Check'
                    errorsFieldSuffix='National Police Check'
                    guidanceText='A National Police Check should be less than 4 months old'
                    documentType={AttachmentDocumentType.NationalPoliceCheck}
                    isMandatory={true}
                    updatedFilesList={updatedFilesList}
                    register={register}
                    goingBackwards={goingBackwards}
                    dispatch={dispatch}
                    errorsField={errors.inquiry?.application?.attachments}
                    removedFile={removedFile}
                    addedFile={addedFile}
                />
            </PageWrapper>
        </>
    );
};

export default CriminalConvictionsPage;
