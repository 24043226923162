import moment from 'moment';
import { isValid, parseISO } from 'date-fns';
import { EMPTY_DATE } from '../services/Constants';

const DateThisManyYearsAgo = (thisManyYearsAgo: number) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - thisManyYearsAgo);
    return new Date(date.setHours(0, 0, 0, 0));
};
export const WithinThirtyDays = (dateToCheck: Date | null) => {
    if (dateToCheck === null) {
        return false;
    }
    const today = new Date();
    // tslint:disable-next-line: prefer-const
    let todayPlus30Days = new Date();
    todayPlus30Days.setDate(today.getDate() + 30);

    if (dateToCheck >= today && dateToCheck <= todayPlus30Days) {
        return true;
    }
};
export const sixteenYearsAgo = () => {
    return DateThisManyYearsAgo(16);
};
export const eighteenYearsAgo = () => {
    return DateThisManyYearsAgo(18);
};
export const ninetyYearsAgo = () => {
    return DateThisManyYearsAgo(90);
};
export const oneHundredAndTenYearsAgo = () => {
    return DateThisManyYearsAgo(110);
};

export const IsGreaterOrEqualToToday = (value: Date) => {
    const today = new Date().setHours(0, 0, 0, 0);
    return value.setHours(0, 0, 0, 0) >= today;
};

// date-fns doesn't accept strings as date arguments. Please use `parseISO` to parse strings. See: https://git.io/fjule
export const formatDateForDateFns = (dateIn: Date | null) => {
    if (dateIn === undefined || dateIn === null) {
        dateIn = EMPTY_DATE;
    }

    if (isValid(parseISO(new Date(dateIn).toISOString()))) {
        const timePartOnly = new Date(dateIn).getTime();
        const timeZoneOffset = new Date(dateIn).getTimezoneOffset();
        const timeZoneOffsetInMilliseconds = timeZoneOffset * 60000;
        const isoDateTime = new Date(timePartOnly - timeZoneOffsetInMilliseconds).toISOString(); // returns with the Zulu-format that is then converted by parseISO()

        return parseISO(isoDateTime);
    }

    // if all else fails revert back to convert using Date() - not recommended
    return new Date(dateIn);
};

export const formatDateUTC = (dateIn?: Date | null) => {
    if (dateIn === undefined || dateIn === null || dateIn === EMPTY_DATE) {
        return undefined;
    }

    return new Date(Date.UTC(dateIn.getFullYear(), dateIn.getMonth(), dateIn.getDate()));
};

export const formatDateForDisplay = (dateIn: Date | null | undefined): string => {
    if (dateIn === undefined || dateIn === null) {
        return '';
    }
    // Passing in a string date
    const d = new Date(dateIn);
    return moment(d).format('DD/MM/YYYY');
};

export const formatDateTimeForDisplay = (dateIn: Date | null | undefined): string => {
    if (dateIn === undefined || dateIn === null) {
        return '';
    }
    // Passing in a string date
    const d = new Date(dateIn);
    return moment(d).format('DD/MM/YYYY hh:mm a');
};

export const isDateExpired = (dateIn: Date | null | undefined): boolean => {
    if (dateIn === undefined || dateIn === null) {
        return false;
    }

    // Passing in a string date
    const d = new Date(dateIn);
    const todayDate = moment();
    const dDiff = todayDate.diff(d);
    return dDiff > 0;
};

export const formatDateForDisplayAddExpiredIfInPast = (dateIn: Date | null | undefined): string => {
    if (dateIn === undefined || dateIn === null) {
        return '';
    }
    // Passing in a string date
    const d = new Date(dateIn);
    const result = moment(d).format('DD/MM/YYYY') + (isDateExpired(dateIn) === true ? ' (expired)' : '');
    return result;
};
