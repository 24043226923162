// Outlet Details-Type of Service page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the Outlet Details-Contact Details page
//  - allows navigation forward to the Outlet Details-Opening Hours page
import React, { useEffect, useState } from 'react';
import { IAddress, IOutlet } from '../../api/interfacesApi';
import { EditPageProps } from '../../pages/PageInterfaces';
import AddressComponent from '../../components/address/AddressComponent';
import {
    GUIDANCETEXT_OUTLET_PHYSICALADDRESS,
    GUIDANCETEXT_OUTLET_ROOMSTOBEARRANGED,
    MODALHEADER_ROOMS_TOBE_ARRANGED,
    MODALTEXT_ROOMS_TOBE_ARRANGED,
} from '../../services/Constants';
import { EmptyRow } from '../../controls/EmptyRow';
import ModalDialog from '../../controls/ModalDialog';
import PageMandatoryLabelText from '../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../components/fields/PageFieldsTitle';
import CheckBoxGroupField, { CheckBoxOption } from '../../components/fields/CheckBoxGroupField';
import {
    additionalDeliveryCheckBoxDisplay,
    clientGroupsCheckBoxDisplay,
    clientServicesCheckBoxDisplay,
    cultureDiversityCheckBoxDisplay,
    otherServicesCheckBoxDisplay,
    serviceDeliveryCheckBoxDisplay,
} from './OutletTypeOfServiceInputLists';
import styles from './outletdetails.module.scss';

const { childItems } = styles;

const OutletTypeOfServicePage = (props: EditPageProps) => {
    const { inquiry, setInquiryObj, register, errors, control, trigger, setValue, watch, isValid, setIsServiceDeliveryCurrentlyFaceToFace } = props;

    const [triggerValidation, setTriggerValidation] = useState(false);
    const [showRoomsToBeArrangedModal, setShowRoomsToBeArrangedModal] = useState(false);

    const isServiceDeliveryFaceToFaceWatched = watch(
        'inquiry.application.outletPractitioner.isServiceDeliveryFaceToFace',
        inquiry.application.outletPractitioner?.isServiceDeliveryFaceToFace,
    );
    /* const isServiceDeliveryPhoneWatched = watch(
        'inquiry.application.outletPractitioner.isServiceDeliveryPhone',
        inquiry.application.outletPractitioner?.isServiceDeliveryPhone,
    );
    const isServiceDeliveryOnlineWatched = watch(
        'inquiry.application.outletPractitioner.isServiceDeliveryOnline',
        inquiry.application.outletPractitioner?.isServiceDeliveryOnline,
    ); */
    const isClientGroupCulturallyDiverseWatched = watch(
        'inquiry.application.outletPractitioner.isClientGroupCulturallyDiverse',
        inquiry.application.outletPractitioner?.isClientGroupCulturallyDiverse,
    );

    const showAddressFields =
        inquiry.application.outletPractitioner !== null &&
        isServiceDeliveryFaceToFaceWatched === true &&
        inquiry.application.outletPractitioner.businessAddress !== null;

    const checkBoxOptionsIsRoomsToBeArranged: CheckBoxOption[] = [
        {
            label: 'Rooms to be arranged',
            value: false,
            disabled: false,
            toggleCheckIndex: -1,
        },
    ];

    const onChangeRoomsToBeArranged = (event: { target: { checked: boolean; id: any } }) => {
        // manage the value manually as ref did not work for the react hook form
        const isChecked: boolean = event.target.checked;
        setValue('inquiry.application.outletPractitioner.isRoomsToBeArranged', isChecked, { shouldDirty: true, shouldValidate: true });

        if (isChecked === true) {
            setShowRoomsToBeArrangedModal(true);
        }

        // surface inquiry object so we can show/hide the field
        inquiry.application.outletPractitioner!.isRoomsToBeArranged = isChecked;
        setInquiryObj(inquiry);
        // trigger(); // revalidate
    };

    const physicalAddressDisplay = (outletPractitioner: IOutlet) => {
        const fieldName = `inquiry.application.outletPractitioner.businessAddress`;
        const currItem: IAddress = outletPractitioner.businessAddress!;
        const errorFieldsThisIndex = errors.inquiry?.application?.outletPractitioner.businessAddress;

        return (
            <>
                <fieldset className={childItems} name={fieldName} key={fieldName}>
                    <legend className='sr-only'>Physical address details</legend>
                    <AddressComponent
                        key={fieldName}
                        guidanceText={GUIDANCETEXT_OUTLET_PHYSICALADDRESS}
                        currItem={currItem}
                        headerPrefix='Physical'
                        errors={errors}
                        errorFieldsPrefix={errorFieldsThisIndex}
                        fieldName={fieldName}
                        register={register}
                        inquiry={inquiry}
                        control={control}
                        setValue={setValue}
                        isValid={isValid}
                        trigger={trigger}
                        setTriggerValidation={setTriggerValidation}
                        watch={watch}
                        streetAddressMandatory={false} // not mandatory
                    />
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.isRoomsToBeArranged'
                        displayName='Rooms to be arranged'
                        guidanceText={GUIDANCETEXT_OUTLET_ROOMSTOBEARRANGED}
                        fieldLabel=''
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.isRoomsToBeArranged
                                ? inquiry.application.outletPractitioner.isRoomsToBeArranged
                                : undefined
                        } // only set default if value exists
                        isMandatory={true}
                        options={checkBoxOptionsIsRoomsToBeArranged}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.isRoomsToBeArranged}
                        onChange={onChangeRoomsToBeArranged}
                    />
                </fieldset>
            </>
        );
    };

    useEffect(() => {
        if (isServiceDeliveryFaceToFaceWatched !== undefined) {
            setIsServiceDeliveryCurrentlyFaceToFace(isServiceDeliveryFaceToFaceWatched);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isServiceDeliveryFaceToFaceWatched]);

    /* useEffect(() => {
        // re-validate as three fields are colllectively mandatory
        trigger();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isServiceDeliveryPhoneWatched, isServiceDeliveryOnlineWatched]);
    */

    useEffect(() => {
        if (triggerValidation === true) {
            setTriggerValidation(false);
            trigger();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerValidation]);

    const handleOkRoomsToBeArrangedModal = () => {
        setShowRoomsToBeArrangedModal(false);
    };

    return (
        <>
            <ModalDialog
                dataTestId='RoomsToBeArrangedModal'
                modalTitle={MODALHEADER_ROOMS_TOBE_ARRANGED}
                modalBodyText={MODALTEXT_ROOMS_TOBE_ARRANGED}
                showMe={showRoomsToBeArrangedModal}
                showOkOnly={true}
                handleClickOk={handleOkRoomsToBeArrangedModal}
            />
            <EmptyRow />
            <PageFieldsTitle title='Types of services' />
            <PageMandatoryLabelText />
            {serviceDeliveryCheckBoxDisplay(inquiry, setValue, control, register, errors)}
            <EmptyRow />
            {showAddressFields === true && physicalAddressDisplay(inquiry.application.outletPractitioner!)}
            {additionalDeliveryCheckBoxDisplay(inquiry, setValue, control, register, errors)}
            <EmptyRow />
            {clientServicesCheckBoxDisplay(inquiry, setValue, control, register, errors)}
            <EmptyRow />
            {clientGroupsCheckBoxDisplay(inquiry, setValue, trigger, control, register, errors)}
            <EmptyRow />
            {isClientGroupCulturallyDiverseWatched === true && cultureDiversityCheckBoxDisplay(inquiry, register, errors)}
            {otherServicesCheckBoxDisplay(inquiry, setValue, control, register, errors)}
            <EmptyRow />
        </>
    );
};

export default OutletTypeOfServicePage;
