import React from 'react';
import { Inquiry } from '../../../api/interfacesApi';
import { AttachmentDocumentType } from '../../../services/Enums';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import FileUploadDisplay from './fileUploadDisplay';
import { EmptyRow } from 'controls/EmptyRow';
import { GetActiveAttachmentsByType } from 'utils/AppUtils';

interface ChangeOfNameDisplayProps {
    inquiry: Inquiry;
    formatForPDF: boolean;
}

const ChangeOfNameDisplay = (props: ChangeOfNameDisplayProps) => {
    const { inquiry, formatForPDF } = props;

    if (inquiry.application.attachments === null || inquiry.application.attachments.length === 0) {
        return null;
    }

    const nameChangeAttachments = GetActiveAttachmentsByType(inquiry.application.attachments, AttachmentDocumentType.EvidenceOfNameChange);
    if (nameChangeAttachments === null || nameChangeAttachments.length === 0) {
        return null;
    }

    return (
        <>
            <PageFieldsTitle title='Change of name documentation' smallerHeader={true} underlineTitle={formatForPDF} />
            <FileUploadDisplay
                inquiry={inquiry}
                showThisDocType={AttachmentDocumentType.EvidenceOfNameChange}
                title='Change of name evidence'
                formatForPDF={formatForPDF}
            />
            <EmptyRow />
        </>
    );
};

export default ChangeOfNameDisplay;
