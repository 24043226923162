// View Complaints Mechanism page
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IApplication, InquiryDefaultObj, IUser } from '../../../../api/interfacesApi';
import AuthService from '../../../../services/AuthService';
import ApplicationActions from '../../../../actions/applicationActions';
import UserActions from '../../../../actions/userActions';
import * as types from '../../../../actions/actionTypes';
import { AjaxCallStateForPage } from '../../../PageInterfaces';
import RegistrationApplicationForm from '../../../../pages/Registration/RegistrationApplicationForm';
import {
    AccreditationStatusEnum,
    AjaxCallStateEnum,
    ComplaintsMechanismType,
    NavigationDestination,
    RelationshipToEmployerType,
} from '../../../../services/Enums';
import { toastErrorMessage, toastSuccessMessage } from '../../../../actions/toastrMessages';
import { VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../../../services/Validation';
import PageTitle from '../../../../components/pageTitle';
import {
    COMPLAINTS_MECHANISM_ALLREMOVED_P1,
    COMPLAINTS_MECHANISM_ALLREMOVED_P2,
    HEADER_VIEW_COMPLAINTSMECHANISM,
    MODALHEADER_REMOVE_COMPLAINTSMECHANISM,
    MODALHEADER_UPDATEDETAILS_OK,
    MODALTEXT_UPDATECOMPLAINTSDETAILS_OK_NEEDSREVIEW_BACKTOLANDING,
    MODALTEXT_UPDATECOMPLAINTSDETAILS_OK_NOREVIEW,
    MODALTEXT_REMOVE_COMPLAINTSMECHANISM_P1,
    MODALTEXT_REMOVE_COMPLAINTSMECHANISM_P2,
    TIP_COMPLAINTSMECHANISM_DETAILS_REMOVE,
    UNINITIALISED,
    MODALHEADER_REMOVE_INSURANCE,
    MODALTEXT_REMOVE_INSURANCE_P1,
    MODALTEXT_REMOVE_INSURANCE_P2,
    MODALTEXT_REMOVE_INSURANCE_P3,
    MODALTEXT_NOINSURANCE_TOREMOVE,
    MODALHEADER_NOINSURANCE_TOREMOVE,
    MODALHEADER_PRIVATEINSURANCE_CANNOTREMOVE,
    MODALTEXT_PRIVATEINSURANCE_CANNOTREMOVE_P1,
    MODALTEXT_PRIVATEINSURANCE_CANNOTREMOVE_P2,
    MODALHEADER_CONFIRM_ACTION,
    MODALTEXT_UPDATEMEMBERSHIPDETAILS_WARNING_P2,
    MODALTEXT_UPDATEMEMBERSHIPDETAILS_WARNING_P1,
    MODALTEXT_EDITING_PREVENTED_RELATIONSHIP_ONPANEL_P1,
    MODALTEXT_EDITING_PREVENTED_RELATIONSHIP_ONPANEL_P2,
    MODALHEADER_EDITING_NOTALLOWED,
    MODALTEXT_EDITING_PREVENTED_RELATIONSHIP_EMPLOYEE,
} from '../../../../services/Constants';
import ComplaintsMechanismFieldsDisplay from '../../../../fieldLayout/complaintsMechanismFieldsDisplay';
import Loader from '../../../../controls/Loader';
import ModalDialog from '../../../../controls/ModalDialog';
import PageWrapper from '../../../../components/pageWrapper';
import { SetCurrentComplaintsMechId, SetDestinationNavActionOnSuccess } from '../../../../components/wizard/wizardStateManager';
import { NavigateAway } from '../../../../components/wizard/wizardStepFunctions';
import AppContext, { AppContextInterface } from '../../../../stateManagement/context/AppContext';
import {
    CountOfValidComplaintsMech,
    FindIndexOfComplaint,
    GetComplaintsMechanismName,
    IsAnyComplaintGovtFunded,
    IsAnyComplaintProfAssoc_FullMembership_WithInsurance,
} from '../../../../utils/ComplaintsUtils';
import { IsMyLastComplaintMechanismFlaggedForCancellation } from '../../../../utils/AppUtils';
import { Sleep } from '../../../../utils/Common';
import TipsControl from '../../../../controls/TipsControl';
import ButtonsRegionControl from '../../../../controls/ButtonsRegionControl';

interface ViewComplaintsMechanismPageProps {
    authService: AuthService;
}

interface UIstate {
    showPreventEditDueToRelEmployee: boolean;
    showPreventEditDueToRelOnPanel: boolean;
    showMembershipDetailsWarning: boolean;
    showSubmittedDialogOK: boolean;
    showRemoveComplaintsMechDialogOK: boolean;
    showRemoveInsuranceDialogOK: boolean;
    showRemoveInsuranceNoInsuranceDialogOK: boolean;
    showRemoveInsurancePrivateInsuranceDialogOK: boolean;
    attemptingRemoveInsurance: boolean;
    modalTextUpdateOk: string;
    attemptingRemoveComplaintsMech: boolean;
    attemptingSave: boolean;
    skipToMembershipPage: boolean;
    skipToInsurancePage: boolean;
    showLastComplaintCancelled: boolean;
}

const initUiState: UIstate = {
    showPreventEditDueToRelEmployee: false,
    showPreventEditDueToRelOnPanel: false,
    showMembershipDetailsWarning: false,
    showSubmittedDialogOK: false,
    showRemoveComplaintsMechDialogOK: false,
    showRemoveInsuranceDialogOK: false,
    showRemoveInsuranceNoInsuranceDialogOK: false,
    showRemoveInsurancePrivateInsuranceDialogOK: false,
    attemptingRemoveInsurance: false,
    modalTextUpdateOk: MODALTEXT_UPDATECOMPLAINTSDETAILS_OK_NOREVIEW,
    attemptingRemoveComplaintsMech: false,
    attemptingSave: false,
    skipToMembershipPage: false,
    skipToInsurancePage: false,
    showLastComplaintCancelled: false,
};

const ViewComplaintsMechanismPage = (props: ViewComplaintsMechanismPageProps) => {
    const { authService } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const formRef = useRef();
    // tslint:disable-next-line: prefer-const (as we modify this throughout)
    let appContext = useContext<AppContextInterface>(AppContext);
    const userActions = useMemo(() => {
        return new UserActions(authService);
    }, [authService]);
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const user: IUser = useSelector((stateUser: any) => stateUser.user);
    const application: IApplication = useSelector((stateApp: any) => stateApp.application);
    const ajaxCallsInProgress: types.ICallStatus = useSelector((stateAjax: any) => stateAjax.ajaxCallsInProgress);
    const initCallStateForPage: AjaxCallStateForPage = { ajaxCallState: AjaxCallStateEnum.NotStarted, pageNumber: 0 };
    const [ajaxCallStateForPage, setAjaxCallStateForPage] = useState(initCallStateForPage);
    const [backEndValidationErrors, setBackEndValidationErrors] = useState([]);
    const applicationForm = useRef(new RegistrationApplicationForm());
    const [inquiryDefObj, setInquiry] = useState<InquiryDefaultObj>(applicationForm.current.properties.InitialData);

    const [displayState, setDisplayState] = useState(initUiState);

    useEffect(() => {
        SetDestinationNavActionOnSuccess(appContext, NavigationDestination.ManageComplaintsMech);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // get latest user details so we can map them to our inquiry object upon load of this page
        dispatch(userActions.GetUser());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(appActions.GetApplication());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (ajaxCallsInProgress && ajaxCallsInProgress.callState) {
            setAjaxCallStateForPage({ ajaxCallState: ajaxCallsInProgress.callState, pageNumber: 0 });
        }

        if (ajaxCallsInProgress && ajaxCallsInProgress.callState === AjaxCallStateEnum.CompletedWithValidation) {
            setBackEndValidationErrors(ajaxCallsInProgress.errors);
        }

        // TODO: set Errors here:
    }, [ajaxCallsInProgress]);

    useEffect(() => {
        if (displayState.attemptingSave === true || displayState.attemptingRemoveInsurance === true) {
            // nothing to do here
        } else {
            inquiryDefObj.inquiry.personalDetails = user;
            inquiryDefObj.inquiry.application = application;

            // set activeComplaintsMechanismObj
            if (appContext.currentComplaintsMechId !== undefined || appContext.currentComplaintsMechId !== UNINITIALISED) {
                const indexOfCMid = FindIndexOfComplaint(appContext.currentComplaintsMechId, inquiryDefObj.inquiry.application);

                inquiryDefObj.inquiry.application.activeComplaintsMechanismObj = inquiryDefObj.inquiry.application.complaintsMechanism[indexOfCMid];
            }

            setInquiry(inquiryDefObj);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, application, inquiryDefObj]);

    const IsOnlyComplaintsMech = () => {
        // check if we have only one CM and that is a private where we have just removed the insurance
        return CountOfValidComplaintsMech(inquiryDefObj.inquiry.application.complaintsMechanism) === 1;
    };

    const removeThisComplaintsMechanism = () => {
        // Save changes to CM (attempt save, if that fails)
        // check if we need a review
        const thisIsProfAssocMembership =
            inquiryDefObj.inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc;
        const isAnyComplaintProfAssocFullMembership = IsAnyComplaintProfAssoc_FullMembership_WithInsurance(
            inquiryDefObj.inquiry.application.complaintsMechanism,
        );
        const isAnyComplaintGovtFunded = IsAnyComplaintGovtFunded(inquiryDefObj.inquiry.application.complaintsMechanism);

        const needsReview =
            (thisIsProfAssocMembership === true && isAnyComplaintGovtFunded === false) ||
            (thisIsProfAssocMembership === false && isAnyComplaintProfAssocFullMembership === false);

        setDisplayState({
            ...displayState,
            showRemoveComplaintsMechDialogOK: false,
            attemptingRemoveComplaintsMech: true,
            modalTextUpdateOk: needsReview ? MODALTEXT_UPDATECOMPLAINTSDETAILS_OK_NEEDSREVIEW_BACKTOLANDING : MODALTEXT_UPDATECOMPLAINTSDETAILS_OK_NOREVIEW,
        });

        dispatch(appActions.RemoveComplaintsMechanism(inquiryDefObj.inquiry.application.activeComplaintsMechanismObj!.complaintsMechanismId));
    };

    const removeThisInsurance = () => {
        setDisplayState({
            ...displayState,
            attemptingRemoveInsurance: true,
            showRemoveInsuranceDialogOK: false,
            modalTextUpdateOk: MODALTEXT_UPDATECOMPLAINTSDETAILS_OK_NEEDSREVIEW_BACKTOLANDING,
        });

        // ensure clone of evidence at this point only
        inquiryDefObj.inquiry.application.activeComplaintsMechanismObj!.copyEvidenceWhenCloning = true;
        inquiryDefObj.inquiry.application.activeComplaintsMechanismObj!.copyNonInsuranceEvidenceWhenCloning = false;

        // clear all insurance fields
        inquiryDefObj.inquiry.application.activeComplaintsMechanismObj!.hasProfessionalIndemnityInsuranceViaMembership = false;
        inquiryDefObj.inquiry.application.activeComplaintsMechanismObj!.professionalIndemnityInsuranceFromDate = undefined;
        inquiryDefObj.inquiry.application.activeComplaintsMechanismObj!.professionalIndemnityInsuranceToDate = undefined;

        // set the currIndex back to UNINITIALISED as we will create a new record/clone - MOST IMPORTANT as we read the collection reverse-sorted by modifiedDate
        SetCurrentComplaintsMechId(appContext, UNINITIALISED);

        // update will create an edit copy
        dispatch(appActions.UpdateComplaintsMechanism(inquiryDefObj.inquiry.application.activeComplaintsMechanismObj!));
    };

    const redirectToReviewAndSubmitForm = () => {
        // to Review and Submit form
        // history.push('/managedetailsreviewandsubmitpage');
        window.location.href = '/managedetailsreviewandsubmitpage'; // reload all data (window.location.href removes state)
    };

    const navigateToComplaintsMechLandingPage = () => {
        setDisplayState(initUiState);

        const keepChanges = false;
        const isDirtyForcedFalse = false;
        const isValid = true;

        NavigateAway(appContext, history, keepChanges, isDirtyForcedFalse, isValid);
    };

    const ProcessPostRemoveComplaintsMechOp = () => {
        // refresh data set now after changes saved
        // dispatch(appActions.GetApplication());
        // allow seconds to see save changes dialog then go back to dashboard
        Sleep(1200).then(() => {
            if (IsMyLastComplaintMechanismFlaggedForCancellation(application) === true) {
                setDisplayState({ ...displayState, showLastComplaintCancelled: true, attemptingRemoveComplaintsMech: false });
            } else {
                // pop up success dialog and then user will decide what flow to go to next
                setDisplayState({ ...displayState, showSubmittedDialogOK: true, attemptingRemoveComplaintsMech: false });
            }
        });
    };

    // check dispatch success state
    useEffect(() => {
        if (ajaxCallStateForPage) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                setDisplayState(initUiState);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                // displayBackEndValidations();
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setDisplayState(initUiState);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (
                    displayState.attemptingRemoveComplaintsMech === true &&
                    ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK &&
                    !appContext.wizardState.triggerSubmit
                ) {
                    // IF VALID (or OK to move forward):
                    setDisplayState({ ...displayState, attemptingRemoveComplaintsMech: false });
                    ProcessPostRemoveComplaintsMechOp();
                }

                if (
                    displayState.attemptingRemoveInsurance === true &&
                    ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK &&
                    !appContext.wizardState.triggerSubmit
                ) {
                    // pop up success dialog and then user will decide what flow to go to next
                    setDisplayState({ ...displayState, showSubmittedDialogOK: true, attemptingRemoveInsurance: false });
                    toastSuccessMessage('Insurance removed');
                }

                // created Edit Copy now nav to edit UI (page 1)
                if (
                    displayState.attemptingSave === true &&
                    displayState.skipToMembershipPage === false &&
                    displayState.skipToInsurancePage === false &&
                    ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK &&
                    !appContext.wizardState.triggerSubmit
                ) {
                    setDisplayState({ ...displayState, attemptingSave: false });
                    history.push('/updatecomplaintsmechanism');
                }

                // created Edit Copy now nav to edit UI (page 2)
                if (
                    displayState.attemptingSave === true &&
                    displayState.skipToMembershipPage === true &&
                    displayState.skipToInsurancePage === false &&
                    ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK &&
                    !appContext.wizardState.triggerSubmit
                ) {
                    setDisplayState({ ...displayState, attemptingSave: false, skipToMembershipPage: false });
                    history.push('/updatecomplaintsmechanismmembership');
                }

                // created Edit Copy now nav to edit UI (page 3)
                if (
                    displayState.attemptingSave === true &&
                    displayState.skipToInsurancePage === true &&
                    ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK &&
                    !appContext.wizardState.triggerSubmit
                ) {
                    setDisplayState({ ...displayState, attemptingSave: false });
                    history.push('/updatecomplaintsmechanisminsurance');
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const handleClickPrevious = () => {
        navigateToComplaintsMechLandingPage();
    };

    const handleClickBackToManageCM = () => {
        navigateToComplaintsMechLandingPage();
    };

    const handleClickToReviewAndSubmit = () => {
        redirectToReviewAndSubmitForm();
    };

    const handleClickRemoveComplaintsMechanism = () => {
        setDisplayState({ ...displayState, showRemoveComplaintsMechDialogOK: true });
    };

    const handleRemoveComplaintsMechNoOption = () => {
        // just close the modal and remain here on Remove NO
        setDisplayState({ ...displayState, showRemoveComplaintsMechDialogOK: false });
    };

    const handleRemoveComplaintsMechYesOption = () => {
        // handle click YES for 'Remove CM'
        removeThisComplaintsMechanism();
    };

    const handleRemoveInsuranceNoOption = () => {
        // just close the modal and remain here on Remove NO
        setDisplayState({ ...displayState, showRemoveInsuranceDialogOK: false });
    };

    const handleRemoveInsuranceNoInsuranceOk = () => {
        // just close the modal and remain here on Remove where No Insurance OK
        setDisplayState({ ...displayState, showRemoveInsuranceNoInsuranceDialogOK: false });
    };

    const handleRemoveInsurancePrivateInsuranceOk = () => {
        // just close the modal and remain here on Remove where No Insurance OK
        setDisplayState({ ...displayState, showRemoveInsurancePrivateInsuranceDialogOK: false });
    };

    const handleRemoveInsuranceYesOption = () => {
        // handle click YES for 'Remove Insurance only'
        removeThisInsurance();
    };

    const handleClickPreventEditDueToRelOnPanel = () => {
        // just close the modal and remain here
        setDisplayState({ ...displayState, showPreventEditDueToRelOnPanel: false });
    };

    const updateComplaintsMechAndCreateEditCopy = (skipToInsurancePageIn: boolean) => {
        setDisplayState({ ...displayState, attemptingSave: true, skipToInsurancePage: skipToInsurancePageIn });

        // do NOT clone evidence now as we want fresh files from the userw
        inquiryDefObj.inquiry.application.activeComplaintsMechanismObj!.copyEvidenceWhenCloning = false;
        if (skipToInsurancePageIn === true) {
            inquiryDefObj.inquiry.application.activeComplaintsMechanismObj!.copyNonInsuranceEvidenceWhenCloning = true;
        }

        // set the currIndex back to UNINITIALISED as we will create a new record/clone - MOST IMPORTANT as we read the collection reverse-sorted by modifiedDate
        SetCurrentComplaintsMechId(appContext, UNINITIALISED);

        // update will create an edit copy
        dispatch(appActions.UpdateComplaintsMechanism(inquiryDefObj.inquiry.application.activeComplaintsMechanismObj!));
    };

    const handleClickEditComplaintsMechanism = () => {
        // const thisIsProfAssocMembership =
        //    inquiryDefObj.inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc;
        const thisIsOrg =
            inquiryDefObj.inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType === ComplaintsMechanismType.GovernmentFundedFDRService;
        const relationshipIsEmployee =
            inquiryDefObj.inquiry.application.activeComplaintsMechanismObj?.relationshipToOrganisation === RelationshipToEmployerType.Employee;
        const relationshipIsOnPanel =
            inquiryDefObj.inquiry.application.activeComplaintsMechanismObj?.relationshipToOrganisation === RelationshipToEmployerType.OnPanel;

        // if CM is an Org and outlet type is employee or panel member and relationship is employee, prevent edit
        if (thisIsOrg === true && relationshipIsEmployee === true) {
            setDisplayState({ ...displayState, showPreventEditDueToRelEmployee: true });
        } else {
            // if CM is an Org and outlet type is employee or panel member and relationship is On Panel, prevent edit
            if (thisIsOrg === true && relationshipIsOnPanel === true) {
                setDisplayState({ ...displayState, showPreventEditDueToRelOnPanel: true });
            } else {
                // if CM is an Private allow edit after a warning message/prompt
                setDisplayState({ ...displayState, showMembershipDetailsWarning: true, skipToMembershipPage: true });
            }
        }
    };

    const handleClickMembershipDetailsWarningYesOption = () => {
        setDisplayState({ ...displayState, showMembershipDetailsWarning: false });
        // do not skip to insurance page
        const skipToInsurancePageIn = false;
        updateComplaintsMechAndCreateEditCopy(skipToInsurancePageIn);
    };

    const handleClickMembershipDetailsWarningNoOption = () => {
        setDisplayState({ ...displayState, showMembershipDetailsWarning: false, skipToMembershipPage: false });
        // just close this dialog
    };

    const submitForm = (data: any) => {
        // skip to insurance page (since other dedicated buttons handle edit CM flows)
        const skipToInsurancePageIn = true;
        updateComplaintsMechAndCreateEditCopy(skipToInsurancePageIn);
    };

    const complaintsData = inquiryDefObj.inquiry?.application?.activeComplaintsMechanismObj;
    const nameOfCM = displayState.attemptingSave === true || complaintsData === null ? '' : GetComplaintsMechanismName(complaintsData);

    const pageTitle = `Complaints mechanism - ${nameOfCM}`;

    const displayInsuranceRegion = complaintsData?.relationshipToOrganisation === RelationshipToEmployerType.Employee ? false : true;
    const includeRemoveInsuranceButton = displayInsuranceRegion;

    // uses complaintsData var
    const handleClickRemoveInsurance = () => {
        // if Conditionally Accredited AND no insurance then show an OK only diaog otherwise allow removal
        if (
            inquiryDefObj.inquiry.application.accreditationStatus === AccreditationStatusEnum.ConditionalAccreditation &&
            complaintsData?.hasProfessionalIndemnityInsuranceViaMembership === false
        ) {
            // show OK only dialog
            setDisplayState({ ...displayState, showRemoveInsuranceNoInsuranceDialogOK: true });
        } else {
            // if Accredited, Private, has Insurance AND is the only CM, and attempting to remove then we allow with message
            if (
                inquiryDefObj.inquiry.application.accreditationStatus === AccreditationStatusEnum.Accredited &&
                complaintsData?.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc &&
                complaintsData?.hasProfessionalIndemnityInsuranceViaMembership === true &&
                IsOnlyComplaintsMech() === true
            ) {
                // allow removal choice
                setDisplayState({ ...displayState, showRemoveInsuranceDialogOK: true });
            } else {
                if (
                    inquiryDefObj.inquiry.application.accreditationStatus === AccreditationStatusEnum.Accredited &&
                    complaintsData?.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc &&
                    complaintsData?.hasProfessionalIndemnityInsuranceViaMembership === true
                ) {
                    // if Accredited, Private, has Insurance AND has another CM, and attempting to remove then we prevent that now
                    setDisplayState({ ...displayState, showRemoveInsurancePrivateInsuranceDialogOK: true });
                }
            }
        }
    };

    if (
        inquiryDefObj.inquiry === undefined ||
        inquiryDefObj.inquiry?.application.applicationId === UNINITIALISED ||
        inquiryDefObj.inquiry?.application.activeComplaintsMechanismObj === null
    ) {
        return <Loader isLoading={true} loaderText='Loading, please wait...' />;
    }

    if (displayState.attemptingSave === true) {
        return <Loader isLoading={true} loaderText='Settings things up, please wait...' />;
    }

    return (
        <>
            <PageTitle title={pageTitle} description={HEADER_VIEW_COMPLAINTSMECHANISM} />
            <PageWrapper
                pageName='ViewComplaintsMechanism'
                formRef={formRef}
                handleSubmit={submitForm}
                handleClickPrevious={handleClickPrevious}
                includeSaveContinueButton={false}
                formFieldsHalfHeight={true}
            >
                <Loader
                    isLoading={displayState.attemptingRemoveComplaintsMech === true || displayState.attemptingRemoveInsurance === true}
                    loaderText='Saving, please wait...'
                />
                <ModalDialog
                    dataTestId='RemoveComplaintsMechDialog'
                    modalTitle={MODALHEADER_REMOVE_COMPLAINTSMECHANISM}
                    modalBodyText={MODALTEXT_REMOVE_COMPLAINTSMECHANISM_P1}
                    modalBodyTextP2={MODALTEXT_REMOVE_COMPLAINTSMECHANISM_P2}
                    showMe={displayState.showRemoveComplaintsMechDialogOK}
                    handleClickYes={handleRemoveComplaintsMechYesOption}
                    handleClickNo={handleRemoveComplaintsMechNoOption}
                />
                <ModalDialog
                    dataTestId='RemoveInsuranceDialog'
                    isDestructive={true}
                    modalTitle={MODALHEADER_REMOVE_INSURANCE}
                    modalBodyText={MODALTEXT_REMOVE_INSURANCE_P1}
                    modalBodyTextP2={MODALTEXT_REMOVE_INSURANCE_P2}
                    modalBodyTextP3={MODALTEXT_REMOVE_INSURANCE_P3}
                    showMe={displayState.showRemoveInsuranceDialogOK}
                    handleClickYes={handleRemoveInsuranceYesOption}
                    handleClickNo={handleRemoveInsuranceNoOption}
                />
                <ModalDialog
                    dataTestId='RemoveInsuranceWhereNoInsuranceDialog'
                    modalTitle={MODALHEADER_NOINSURANCE_TOREMOVE}
                    modalBodyText={MODALTEXT_NOINSURANCE_TOREMOVE}
                    showMe={displayState.showRemoveInsuranceNoInsuranceDialogOK}
                    showOkOnly={true}
                    handleClickOk={handleRemoveInsuranceNoInsuranceOk}
                />
                <ModalDialog
                    dataTestId='RemoveInsurancePrivateInsuranceDialog'
                    modalTitle={MODALHEADER_PRIVATEINSURANCE_CANNOTREMOVE}
                    modalBodyText={MODALTEXT_PRIVATEINSURANCE_CANNOTREMOVE_P1}
                    modalBodyTextP2={MODALTEXT_PRIVATEINSURANCE_CANNOTREMOVE_P2}
                    showMe={displayState.showRemoveInsurancePrivateInsuranceDialogOK}
                    showOkOnly={true}
                    handleClickOk={handleRemoveInsurancePrivateInsuranceOk}
                />
                <ModalDialog
                    dataTestId='UpdateOkDialog'
                    modalTitle={MODALHEADER_UPDATEDETAILS_OK}
                    modalBodyText={displayState.modalTextUpdateOk}
                    showMe={displayState.showSubmittedDialogOK}
                    confirmLabel='Review and submit all of my changes'
                    handleClickYes={handleClickToReviewAndSubmit}
                    abortLabel='Back to Manage complaints mechanism(s) and insurance'
                    handleClickNo={handleClickBackToManageCM}
                />
                <ModalDialog
                    dataTestId='LastOneCancelledDialog'
                    modalTitle={MODALHEADER_UPDATEDETAILS_OK}
                    modalBodyText={COMPLAINTS_MECHANISM_ALLREMOVED_P1}
                    modalBodyTextP2={COMPLAINTS_MECHANISM_ALLREMOVED_P2}
                    showMe={displayState.showLastComplaintCancelled}
                    showOkOnly={true}
                    handleClickOk={handleClickBackToManageCM}
                />
                <ModalDialog
                    dataTestId='MembershipDetailsWarning'
                    modalTitle={MODALHEADER_CONFIRM_ACTION}
                    modalBodyText={MODALTEXT_UPDATEMEMBERSHIPDETAILS_WARNING_P1}
                    modalBodyTextP2={MODALTEXT_UPDATEMEMBERSHIPDETAILS_WARNING_P2}
                    showMe={displayState.showMembershipDetailsWarning}
                    handleClickYes={handleClickMembershipDetailsWarningYesOption}
                    handleClickNo={handleClickMembershipDetailsWarningNoOption}
                />
                <ModalDialog
                    dataTestId='PreventEditDueToRelEmployee'
                    modalTitle={MODALHEADER_EDITING_NOTALLOWED}
                    modalBodyText={MODALTEXT_EDITING_PREVENTED_RELATIONSHIP_EMPLOYEE}
                    showMe={displayState.showPreventEditDueToRelEmployee}
                    showOkOnly={true}
                    handleClickOk={handleClickBackToManageCM}
                />
                <ModalDialog
                    dataTestId='PreventEditDueToRelOnPanel'
                    modalTitle={MODALHEADER_EDITING_NOTALLOWED}
                    modalBodyText={MODALTEXT_EDITING_PREVENTED_RELATIONSHIP_ONPANEL_P1}
                    modalBodyTextP2={MODALTEXT_EDITING_PREVENTED_RELATIONSHIP_ONPANEL_P2}
                    showMe={displayState.showPreventEditDueToRelOnPanel}
                    showOkOnly={true}
                    handleClickOk={handleClickPreventEditDueToRelOnPanel}
                />
                <ComplaintsMechanismFieldsDisplay inquiry={inquiryDefObj.inquiry} />
                <TipsControl tipString={TIP_COMPLAINTSMECHANISM_DETAILS_REMOVE} extraWideLayout={true} />
                <ButtonsRegionControl
                    key='manageComplaintsMech'
                    title='Complaints mechanism'
                    description='Use these buttons to manage your complaints mechanism(s).'
                    primaryButtonText='Update complaints mechanism'
                    primaryButtonClickEvent={handleClickEditComplaintsMechanism}
                    showSecondaryButton1={true}
                    secondaryButton1Text='Remove complaints mechanism'
                    secondaryButton1IsDestructive={true}
                    secondaryButton1ClickEvent={handleClickRemoveComplaintsMechanism}
                />
                {displayInsuranceRegion === true && (
                    <ButtonsRegionControl
                        key='manageInsurance'
                        title='Insurance'
                        description='Use these buttons to manage the insurance associated with your complaints mechanism.'
                        primaryButtonText='Update insurance'
                        primaryButtonClickEvent={submitForm}
                        showSecondaryButton1={includeRemoveInsuranceButton}
                        secondaryButton1Text='Remove insurance only'
                        secondaryButton1IsDestructive={true}
                        secondaryButton1ClickEvent={handleClickRemoveInsurance}
                    />
                )}
            </PageWrapper>
        </>
    );
};

export default ViewComplaintsMechanismPage;
