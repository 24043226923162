import React from 'react';
import { validateAndSecureURL } from '../utils/Common';

interface NavLinkProps {
    hrefLink: string;
    displayText: string;
    className?: string;
    openInNewTab?: boolean;
    handleOnClick?: any;
    dataTestId?: string;
    disabled?: boolean;
    children?: any;
}

const NavLink = (props: NavLinkProps) => {
    const { hrefLink, displayText, className, openInNewTab, handleOnClick, dataTestId, disabled, children } = props;

    const hrefString = validateAndSecureURL(hrefLink.toString());
    let classNameModded = className ? className : '';
    classNameModded = classNameModded + (disabled === true ? ' disabled' : '');

    const attributes = {
        href: hrefString !== '' ? hrefString : undefined,
        target: '_self', // default
    };

    if (openInNewTab) {
        attributes.target = '_blank';
    }

    const dataTestIdString = dataTestId ?? '';

    if (handleOnClick) {
        // if children then MUST have handleOnClick
        if (children) {
            return (
                <a {...attributes} className={classNameModded} rel='noreferrer' onClick={handleOnClick} data-testid={dataTestIdString}>
                    {children}
                </a>
            );
        }
        return (
            <a {...attributes} className={classNameModded} rel='noreferrer' onClick={handleOnClick} data-testid={dataTestIdString}>
                {displayText}
            </a>
        );
    }

    return (
        <a {...attributes} className={classNameModded} rel='noreferrer' data-testid={dataTestId}>
            {displayText}
        </a>
    );
};

export default NavLink;
