import React from 'react';
import AuthService from '../../services/AuthService';

interface LogoutProps {
    authService: AuthService;
}

function Logout(props: LogoutProps) {
    const { authService } = props;

    authService.SignOut();

    return (
        <div>
            <p>You are being signed out...</p>
        </div>
    );
}

export default Logout;
