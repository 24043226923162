// Manage Details Review and Submit page
import React, { useContext, useRef, useState, useMemo, useEffect } from 'react';
// tslint:disable-next-line: no-submodule-imports
import { renderToString } from 'react-dom/server';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IApplication, IApplicationSubmit, Inquiry } from '../../api/interfacesApi';
import { NavigationDestination } from '../../services/Enums';
import AuthService from '../../services/AuthService';
import { VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../services/Validation';
import {
    MODALHEADER_CHANGES_PREVENTED_COMPLAINTS_INVALID,
    MODALTEXT_CHANGES_PREVENTED_COMPLAINTS_INVALID,
    MODALBUTTON_NO_DISCARDCHANGES,
    MODALBUTTON_YES_DISCARDCHANGES,
    MODALHEADER_DISCARDCHANGES,
    MODALHEADER_UPDATEOUTLET_OK,
    MODALTEXT_DISCARDCHANGES_EDITPERSONALANDCM,
} from '../../services/Constants';
import { AccreditedApplicationIsActionRequired, ErrorOnSubmitForm } from '../../utils/AppUtils';
import { IsObjectEmpty, Sleep } from '../../utils/Common';
import PersonalDetailsNameDiff from '../../fieldLayout/personalDetailsNameDiff';
import AppContext from '../../stateManagement/context/AppContext';
import ApplicationActions from '../../actions/applicationActions';
import { toastErrorMessage, toastSuccessMessage } from '../../actions/toastrMessages';
import { EmptyRow } from '../../controls/EmptyRow';
import Loader from '../../controls/Loader';
import { ModalDialogConfirmable } from '../../controls/ModalDialogConfirmable';
import ModalDialog from '../../controls/ModalDialog';
import LabelField from '../../components/fields/LabelField';
// import CheckBoxGroupField, { CheckBoxOption } from '../../components/fields/CheckBoxGroupField';
import PageFieldsTitle from '../../components/fields/PageFieldsTitle';
import PageWrapper from '../../components/pageWrapper';
import ManageDetailsRegions from './manageDetailsRegions';
import { SetDestinationNavActionOnSuccessEditForm } from './EditFormStateManager';
import { formatDateForDisplay } from 'utils/Dates';
import { GetAddedComplaintsMechanismCollection, GetRemovedComplaintsMechanismCollection, GetUpdatedComplaintsMechanismCollection } from 'utils/ComplaintsUtils';
import DiffComplaintsMechanismDisplay from './complaintsMechanism/pages/diffComplaintsMechanismDisplay';
import ConsentDisplay from 'pages/Registration/ReviewAndSubmit/ConsentDisplay';

interface ManageDetailsReviewAndSubmitPageProps {
    // formData: any;
    // setForm: any;
    // navigation: any;
    // user: IUser;
    // application: IApplication;
    isLoadingChanges: boolean;
    inquiry: Inquiry; // | undefined;
    refreshReviewAndSubmit: any;
    setInquiryObj?: any;
    // attachments?: IAttachmentMetadata[];
    // goingBackwards?: boolean;
    authService: AuthService;
    dispatch: any;
    // handleClickPrevious: any;
    handleSubmit: any;
    register: any;
    // unregister?: any;
    trigger?: any; // validation trigger
    setValue: any;
    getValues?: any;
    setError: any;
    watch: any;
    // clearErrors: any;
    reset?: any;
    control: any;
    errors: any;
    isValid: boolean;
    // isActive: boolean;
    isDirty?: boolean;
    // ajaxCallStateForPage?: AjaxCallStateForPage;
    // backEndValidationErrors?: any;
    // triggerSubmit: boolean;
    // setTriggerSubmit?: any;
    navigateBackToDashboard: any;
    handleDiscardAllChanges: any;
}

const ManageDetailsReviewAndSubmitPage = (props: ManageDetailsReviewAndSubmitPageProps) => {
    const {
        isLoadingChanges,
        inquiry,
        refreshReviewAndSubmit,
        // setInquiryObj,
        authService,
        dispatch,
        handleSubmit,
        // register,
        // trigger,
        navigateBackToDashboard,
        handleDiscardAllChanges,
    } = props;

    const formRef = useRef();
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);

    const application: IApplication = useSelector((stateApp: any) => stateApp.application);
    const appSubmit: IApplicationSubmit = useSelector((stateAppSubmit: any) => stateAppSubmit.applicationSubmit);
    const appContext = useContext(AppContext);

    const [attemptingSubmitChanges, setAttemptingSubmitChanges] = useState(false);
    const [includesAnInvalidComplaintMech, setIncludesAnInvalidComplaintMech] = useState(false);
    const [showPreventSubmitDialog, setShowPreventSubmitDialog] = useState(false);

    const showDiscardAllChangesButton = !AccreditedApplicationIsActionRequired(application);

    useEffect(() => {
        if (appSubmit && appSubmit.htmlSummary !== '') {
            setAttemptingSubmitChanges(false);
            // setAttemptingDiscardChanges(false);

            toastSuccessMessage(MODALHEADER_UPDATEOUTLET_OK);

            // go back to dashboard
            Sleep(3000).then(() => {
                navigateBackToDashboard();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appSubmit]);

    const applicationSummaryContent = () => {
        const formatForPDF = true;
        const today = new Date();
        const submittedDate = formatDateForDisplay(today);

        const addedCMcollection = GetAddedComplaintsMechanismCollection(inquiry.differencesComplaintsCollection);
        const removedCMcollection = GetRemovedComplaintsMechanismCollection(inquiry.differencesComplaintsCollection, inquiry.application.complaintsMechanism);
        const updatedCMcollection = GetUpdatedComplaintsMechanismCollection(inquiry.differencesComplaintsCollection);

        // could be undefined objects
        const userChangesDoNotExist = (IsObjectEmpty(inquiry.differencesUser) || IsObjectEmpty(inquiry.differencesUser?.differences)) ?? true;
        const cmChangesExist =
            (addedCMcollection !== null && addedCMcollection.length > 0) ||
            (removedCMcollection !== null && removedCMcollection.length > 0) ||
            (updatedCMcollection !== null && updatedCMcollection.length > 0);

        // we use the original name values not the potential changed name values as those new values are not (yet) approved!
        const preferredNameValueToUse =
            userChangesDoNotExist === true ? inquiry.personalDetails?.preferredName : inquiry.differencesUser?.originalVersion.preferredName;
        const preferredName =
            preferredNameValueToUse !== undefined && preferredNameValueToUse !== null && preferredNameValueToUse !== '' ? `(${preferredNameValueToUse}) ` : '';
        const firstNameToUse = userChangesDoNotExist === true ? inquiry.personalDetails?.firstName : inquiry.differencesUser?.originalVersion.firstName;
        const familyNameToUse = userChangesDoNotExist === true ? inquiry.personalDetails?.familyName : inquiry.differencesUser?.originalVersion.familyName;
        const applicantName = firstNameToUse + ' ' + preferredName + familyNameToUse;

        const preText = `On ${submittedDate} practitioner, ${applicantName}`;
        const noChangesPDtext = `${preText} did not submit any changes to name and/or date of birth.`;
        const changesPDtext = `${preText} submitted changes to name and/or date of birth as follows:`;
        const noChangesCMtext = `${preText} did not submit any changes to complaints mechanisms and insurance.`;
        const changesCMtext = `${preText} submitted changes to complaints mechanisms and insurance as follows:`;

        return (
            <>
                <PageFieldsTitle smallerHeader={true} title='Personal Details' underlineTitle={formatForPDF} />
                {userChangesDoNotExist === true && (
                    <Row>
                        <Col>
                            <LabelField id='noChangesPDtext' displayName='' guidanceText='' value={noChangesPDtext} isMandatory={false} />
                        </Col>
                    </Row>
                )}
                {userChangesDoNotExist === false && (
                    <>
                        <Row>
                            <Col>
                                <LabelField id='changesPDtext' displayName='' guidanceText='' value={changesPDtext} isMandatory={false} />
                            </Col>
                        </Row>
                        <EmptyRow />
                        <PersonalDetailsNameDiff inquiry={inquiry} formatForPDF={formatForPDF} />
                    </>
                )}
                <EmptyRow />
                <PageFieldsTitle smallerHeader={true} title='Complaint(s) mechanism and insurance' underlineTitle={formatForPDF} />
                {cmChangesExist === false && (
                    <Row>
                        <Col>
                            <LabelField id='noChangesCMtext' displayName='' guidanceText='' value={noChangesCMtext} isMandatory={false} />
                        </Col>
                    </Row>
                )}
                {cmChangesExist === true && (
                    <>
                        <Row>
                            <Col>
                                <LabelField id='changesCMtext' displayName='' guidanceText='' value={changesCMtext} isMandatory={false} />
                            </Col>
                        </Row>
                        <EmptyRow />
                        <DiffComplaintsMechanismDisplay
                            authService={authService}
                            dispatch={dispatch}
                            inquiry={inquiry}
                            refreshReviewAndSubmit={refreshReviewAndSubmit}
                            includesAnInvalidComplaintMech={includesAnInvalidComplaintMech}
                            addedCMcollection={addedCMcollection}
                            removedCMcollection={removedCMcollection}
                            updatedCMcollection={updatedCMcollection}
                            formatForPDF={formatForPDF}
                        />
                    </>
                )}
                <EmptyRow />
                {formatForPDF === true && (
                    <>
                        <EmptyRow />
                        <EmptyRow />
                        <EmptyRow />
                        <EmptyRow />
                        <hr />
                        <EmptyRow />
                        <EmptyRow />
                        <EmptyRow />
                        <EmptyRow />
                    </>
                )}
                {formatForPDF === true && <ConsentDisplay inquiry={inquiry} isInitialApplication={false} showDateOnFileList={true} />}
            </>
        );
    };

    const submitForm = (data: any) => {
        if (includesAnInvalidComplaintMech === true) {
            // prevent submit flow
            setShowPreventSubmitDialog(true);
        } else {
            // submit flow

            try {
                setAttemptingSubmitChanges(true);
                // output content to HTML string to be able to submit and thus generate to PDF on server-side
                const htmlOutput = renderToString(applicationSummaryContent()); // hide linkToPage component
                inquiry.applicationSubmit.htmlSummary = htmlOutput.toString(); // set the HtmlSummary field then submit the object so they line up with API
                inquiry.applicationSubmit.isResubmitted = false;

                SetDestinationNavActionOnSuccessEditForm(appContext, NavigationDestination.Dashboard);

                dispatch(appActions.SubmitChanges(inquiry.applicationSubmit));
            } catch (err) {
                console.log(err);
                setAttemptingSubmitChanges(false);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            }
        }
    };

    const handleClickDiscardChanges = async (event: any) => {
        // selecting NO remains on page
        if (
            await ModalDialogConfirmable(MODALTEXT_DISCARDCHANGES_EDITPERSONALANDCM, MODALBUTTON_YES_DISCARDCHANGES, MODALBUTTON_NO_DISCARDCHANGES, {
                title: MODALHEADER_DISCARDCHANGES,
                isDestructive: true,
                modalBodyPositionTextLeft: false,
                dataTestId: 'confirmDiscard',
            })
        ) {
            // agreed to discard changes
            handleDiscardAllChanges();
        }
    };

    const handleClickClose = () => {
        navigateBackToDashboard();
    };

    const hideSubmitPreventedDialog = () => {
        setShowPreventSubmitDialog(false);
    };

    const handleIncludesAnInvalidComplaintMech = () => {
        if (includesAnInvalidComplaintMech === false) {
            setIncludesAnInvalidComplaintMech(true);
        }
    };

    return (
        <>
            <PageWrapper
                pageName='SubmitDetails'
                textForSaveContinueButton='Submit all changes'
                textForBackButton='Close'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={handleClickClose}
                includeMiddleButton={showDiscardAllChangesButton}
                textForMiddleButton='Discard all changes'
                middleButtonClickHandler={handleClickDiscardChanges}
                middleButtonVariant='danger'
            >
                <Loader isLoading={attemptingSubmitChanges === true} loaderText='Submitting your changes. Please wait...' />
                <PageFieldsTitle title='Summary of changes' />
                <EmptyRow />
                <ModalDialog
                    modalTitle={MODALHEADER_CHANGES_PREVENTED_COMPLAINTS_INVALID}
                    modalBodyText={MODALTEXT_CHANGES_PREVENTED_COMPLAINTS_INVALID}
                    showMe={showPreventSubmitDialog}
                    showOkOnly={true}
                    handleClickOk={hideSubmitPreventedDialog}
                />
                <Row>
                    <Col>
                        <LabelField id='reviewTitleSubtext' displayName='' value='' isMandatory={false} textCapitalizeOn={false} hideFinalEmptyRow={true} />
                    </Col>
                </Row>
                <EmptyRow />
                <Loader isLoading={isLoadingChanges === true} loaderText='Loading your data. Please wait...' />
                {isLoadingChanges === false && (
                    <ManageDetailsRegions
                        authService={authService}
                        inquiry={inquiry}
                        refreshReviewAndSubmit={refreshReviewAndSubmit}
                        includesAnInvalidComplaintMech={handleIncludesAnInvalidComplaintMech}
                    />
                )}
                <EmptyRow />
            </PageWrapper>
        </>
    );
};

export default ManageDetailsReviewAndSubmitPage;
