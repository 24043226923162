import React from 'react';
import WizardMenuItems from '../../components/wizard/wizardMenuItems';
import { AppContextInterface } from '../../stateManagement/context/AppContext';
import styles from './site.module.scss';

const { desktopSiteMenu } = styles;

export interface WizardMenuContainerProps {
    appContext: AppContextInterface;
    showBackToManageComplaints?: boolean;
    throwEventToChildForm?: boolean;
    callChildFormEvent?: any;
}

// Hosts the Wizard menu items shown for Medium/Large views
const WizardMenuContainer = (props: WizardMenuContainerProps) => {
    const { appContext, showBackToManageComplaints, throwEventToChildForm, callChildFormEvent } = props;

    return (
        <>
            <div className={`${desktopSiteMenu}`}>
                <WizardMenuItems
                    wizardStepPropsArray={appContext.wizardSteps}
                    applicationNumber={appContext.applicationNumber}
                    showBackToManageComplaints={showBackToManageComplaints}
                    throwEventToChildForm={throwEventToChildForm}
                    callChildFormEvent={callChildFormEvent}
                />
            </div>
        </>
    );
};

export default WizardMenuContainer;
