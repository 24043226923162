import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { WizardStepNumber } from '../../services/Enums';
import LinkToPage from './LinkToPage';
import PageMandatoryLabelText from './PageMandatoryLabelText';
import styles from './Fields.module.scss';

const { pageFieldsTitle, linkToPage } = styles;

export interface PageFieldsTitleProps {
    title: string;
    includeMandatoryTextWarning?: boolean;
    showLinkToPage?: boolean;
    wizardStepNumber?: WizardStepNumber;
    smallerHeader?: boolean;
    linkToPageDisplayText?: string;
    linkToPageLink?: string;
    underlineTitle?: boolean;
}

const PageFieldsTitle = (props: PageFieldsTitleProps) => {
    const { title, includeMandatoryTextWarning, showLinkToPage, wizardStepNumber, smallerHeader, linkToPageDisplayText, linkToPageLink, underlineTitle } =
        props;

    const titleTags =
        underlineTitle === true && smallerHeader === true ? (
            <h3>
                <u>{title}</u>
            </h3>
        ) : underlineTitle === true ? (
            <h2>
                <u>{title}</u>
            </h2>
        ) : smallerHeader === true ? (
            <h3>{title}</h3>
        ) : (
            <h2>{title}</h2>
        );

    return (
        <>
            {title !== '' && (
                <Row className={pageFieldsTitle}>
                    <Col>{titleTags}</Col>
                </Row>
            )}
            {includeMandatoryTextWarning && <PageMandatoryLabelText />}
            {showLinkToPage && (
                <LinkToPage className={linkToPage} wizardStepNumber={wizardStepNumber} displayText={linkToPageDisplayText} pageLink={linkToPageLink} />
            )}
        </>
    );
};

export default PageFieldsTitle;
