// Edit Personal Details page
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Mode, useForm } from 'react-hook-form';
// tslint:disable-next-line: no-submodule-imports
import { yupResolver } from '@hookform/resolvers/yup';
import { IApplication, Inquiry, InquiryDefaultObj, IUser } from '../../../api/interfacesApi';
import AuthService from '../../../services/AuthService';
import { AjaxCallStateEnum, AttachmentDocumentType, EditMode, NavigationDestination } from '../../../services/Enums';
import {
    validationSchemaPersonalDetails,
    VALIDATION_ERROR_POPUP_MESSAGE,
    VALIDATION_ERROR_UNKNOWN_MESSAGE,
    VALIDATION_FILEUPLOADREQUIRED,
} from '../../../services/Validation';
import {
    MODALHEADER_UNSAVEDCHANGES_DATALOSSPOTENTIAL,
    MODALTEXT_UPDATEPERSONALDETAILS_OK_NOREVIEW,
    MODALTEXT_UPDATEPERSONALDETAILS_OK_NEEDSREVIEW,
    MODALHEADER_UPDATEDETAILS_OK,
    MODALTEXT_UNSAVEDCHANGES_DATALOSSPOTENTIAL_ANDUPLOADEDFILES,
    MODALBUTTON_YES_DISCARDCHANGES,
    MODALBUTTON_NO_DISCARDCHANGES,
    UNINITIALISED,
} from '../../../services/Constants';
import AppContext, { AppContextInterface } from '../../../stateManagement/context/AppContext';
import { SetDestinationNavActionOnSuccessEditForm, SetEditMode, SetValidationSchema } from '../EditFormStateManager';

import ApplicationActions from '../../../actions/applicationActions';
import UserActions from '../../../actions/userActions';
import AttachmentActions from '../../../actions/attachmentsActions';
import { toastErrorMessage } from '../../../actions/toastrMessages';
import * as types from '../../../actions/actionTypes';
import RegistrationApplicationForm from '../../../pages/Registration/RegistrationApplicationForm';
import { AjaxCallStateForPage } from '../../../pages/PageInterfaces';
import {
    AccreditedApplicationIsActionRequired,
    ErrorOnSubmitForm,
    GetActiveAttachmentsByType,
    HasAnyPersonalDetailsAttachments,
    IsAnyAttachmentsExistOfThisType,
    RemoveAttachmentsFromAzureIfExist,
    RemoveAttachmentsFromCollectionIfExist,
    ReworkAttachmentIsRequired,
} from '../../../utils/AppUtils';
import { Sleep } from '../../../utils/Common';
import { NavigateAway } from '../../../components/wizard/wizardStepFunctions';
import PageWrapper from '../../../components/pageWrapper';
import PageTitle from '../../../components/pageTitle';
import PageMandatoryLabelText from '../../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import PersonalDetailsNameFieldsEdit from '../../../fieldLayout/personalDetailsNameFieldsEdit';
import PersonalDetailsContactFieldsEdit from '../../../fieldLayout/personalDetailsContactFieldsEdit';
import { EmptyRow } from '../../../controls/EmptyRow';
import ErrorSummary from '../../../controls/ErrorSummary';
import { ModalDialogConfirmable } from '../../../controls/ModalDialogConfirmable';
import Loader from '../../../controls/Loader';
import ModalDialog from '../../../controls/ModalDialog';

interface EditPersonalDetailsPageProps {
    authService: AuthService;
}

const EditPersonalDetailsPage = (props: EditPersonalDetailsPageProps) => {
    const { authService } = props;

    const appContext = useContext<AppContextInterface>(AppContext);
    const dispatch = useDispatch();
    const history = useHistory();
    const formRef = useRef();

    const userActions = useMemo(() => {
        return new UserActions(authService);
    }, [authService]);
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const attachmentActions = useMemo(() => {
        return new AttachmentActions(authService);
    }, [authService]);

    const user: IUser = useSelector((stateUser: any) => stateUser.user);
    const application: IApplication = useSelector((stateApp: any) => stateApp.application);
    const differencesUser: any = useSelector((stateDifferences: any) => stateDifferences.differencesUser);
    // store the form in state as we update the steps constantly after user actions
    const applicationForm = useRef(new RegistrationApplicationForm());
    const [hasReadInApplication, setHasReadInApplication] = useState(false);
    const [inquiryDefObj, setInquiry] = useState<InquiryDefaultObj>(applicationForm.current.properties.InitialData);
    const [titleFieldValueUponFormOpen, setTitleFieldValueUponFormOpen] = useState(''); // special case as we focus this field (used for compare/cancel feature)
    const ajaxCallsInProgress: types.ICallStatus = useSelector((stateAjax: any) => stateAjax.ajaxCallsInProgress);
    const initCallStateForPage: AjaxCallStateForPage = { ajaxCallState: AjaxCallStateEnum.NotStarted, pageNumber: 0 };
    const [ajaxCallStateForPage, setAjaxCallStateForPage] = useState(initCallStateForPage);
    const [backEndValidationErrors, setBackEndValidationErrors] = useState([]);
    const [showSubmittedDialogOK, setShowSubmittedDialogOK] = useState(false);
    // even though we have redux states, we HAVE to capture the attempt due to navigation and Get and Save user calls on the page
    const [attemptingSave, setAttemptingSave] = useState(false);
    const [modalTextUpdateOk, setModalTextUpdateOk] = useState(MODALTEXT_UPDATEPERSONALDETAILS_OK_NOREVIEW);

    // this UI handles the rework-flow (resubmit required)
    const isRework = appContext.editFormState.editMode === EditMode.Resubmit;

    // used by children to set this object at parent-level
    const setInquiryObj = (inquiry: Inquiry) => {
        inquiryDefObj.inquiry = inquiry;
        setInquiry(inquiryDefObj);
    };

    // ({ match, location }: any)
    useEffect(() => {
        // location stores key-value pairs if a queryParam is used
        if (window.location && window.location.search && window.location.search.length > 0) {
            // get params collection
            const searchParams = new URLSearchParams(window.location.search);
            if (searchParams && searchParams.get('from') === 'reviewandsubmit') {
                SetDestinationNavActionOnSuccessEditForm(appContext, NavigationDestination.ManageDetailsReviewAndSubmitPage);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(appActions.GetApplication());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // process reading in of application data
    useEffect(() => {
        if (application && application.applicationId !== UNINITIALISED) {
            setHasReadInApplication(true);

            // set the resubmit flag state
            if (AccreditedApplicationIsActionRequired(application) === true && appContext?.editFormState.editMode !== EditMode.Resubmit) {
                SetEditMode(appContext, EditMode.Resubmit);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application]);

    /* REWORK NOW DELETES ON PAU PORTAL:
    useEffect(() => {
        if (isRework === true) {
            // MUST remove all attachments from Azure at this point
            ClearAttachmentDataForReworkSections(attachmentActions, dispatch, inquiryDefObj.inquiry);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRework]); */

    useEffect(() => {
        if (hasReadInApplication === true) {
            if (isRework === true) {
                // upon Rework we have only one (merged) copy of user details
                dispatch(userActions.GetUser());
            } else {
                // get latest EditCopy of user details so we can map them to our inquiry object upon load of this page
                dispatch(userActions.GetUserEditCopy());
                dispatch(userActions.GetChanges());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasReadInApplication]);

    useEffect(() => {
        if (
            differencesUser &&
            differencesUser.originalVersion &&
            differencesUser.originalVersion !== null &&
            differencesUser.originalVersion.id !== undefined
        ) {
            inquiryDefObj.inquiry.differencesUser = differencesUser;
            setInquiry(inquiryDefObj);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [differencesUser]);

    useEffect(() => {
        // if not currently attempting to update
        if (attemptingSave === false) {
            if (inquiryDefObj.inquiry && user && user.isEditCopy === true) {
                inquiryDefObj.inquiry.personalDetails = user;

                setInquiry(inquiryDefObj);
                setTitleFieldValueUponFormOpen(user.title);
                // reset would make the values update for the useForm functionality
                reset(inquiryDefObj);
            }
            if (inquiryDefObj.inquiry && user && user.isEditCopy === false && isRework === true) {
                inquiryDefObj.inquiry.personalDetails = user;

                setInquiry(inquiryDefObj);
                setTitleFieldValueUponFormOpen(user.title);
                // reset would make the values update for the useForm functionality
                reset(inquiryDefObj);
            }

            /* REWORK NOW DELETES ON PAU PORTAL:
            // ONLY if editcopy does not yet exist AND user had prior docs do we remove them (ie upon new application)
            // check if we need to remove prior document(s)
            if (application.applicationStatus !== ApplicationStatusEnum.Pending && inquiryDefObj.inquiry && user && user.isEditCopy === false) {
                // by checking the inquiryDefObj we control original uploaded attachments collection
                if (HasAnyPersonalDetailsAttachments(inquiryDefObj.inquiry.application.attachments)) {
                    // remove ALL uploaded documents to avoid potential issues in future
                    RemoveAllAttachments();
                }
            } */
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (inquiryDefObj.inquiry && application && application.applicationId !== UNINITIALISED) {
            inquiryDefObj.inquiry.application = application;

            // REDUNDANT NOW, replaced by addedFile:
            // MapComplaintsAttachmentsToComplaintsArray(inquiryDefObj.inquiry, setValue, getValues);

            setInquiry(inquiryDefObj);

            // reset would make the values update for the useForm functionality
            reset(inquiryDefObj);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application]);

    useEffect(() => {
        SetValidationSchema(appContext, validationSchemaPersonalDetails);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appContext.editFormState.validationSchema]);

    const validationSchema = appContext.editFormState.validationSchema ? yupResolver(appContext.editFormState.validationSchema) : undefined;
    const validationMode: Mode = 'all';
    /*let validationMode: Mode = appContext.wizardState.wizardMode === WizardMode.Resubmit ? 'onSubmit' : 'all';
    // some pages need validation only upon submit
    if (wizardState.formStep === WizardStepNumber.ComplaintsMechanismMember) {
        validationMode = 'onSubmit';
    } */

    // watch lets us keep track of state to keep an eye on it
    // formState lets us validate
    const {
        watch,
        register,
        // unregister,
        reset,
        trigger,
        getValues,
        setValue,
        setError,
        control,
        handleSubmit,
        formState: { errors, isValid, isDirty, dirtyFields, touchedFields },
        // clearErrors,
    } = useForm({
        mode: validationMode,
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: useMemo(() => {
            return inquiryDefObj;
        }, [inquiryDefObj]),
        /// ERROR until FIELDS MATCH: is not assignable to type 'UnpackNestedValue<DeepPartial<TFieldValues>> | undefined'.
        resolver: useMemo(() => {
            return validationSchema;
        }, [validationSchema]),
    }); // mode:all is to validate after every keypress

    const RemoveAllAttachments = () => {
        RemoveAttachmentsFromAzureIfExist(attachmentActions, dispatch, AttachmentDocumentType.EvidenceOfBirth, inquiryDefObj.inquiry.application.attachments);
        inquiryDefObj.inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(
            AttachmentDocumentType.EvidenceOfBirth,
            inquiryDefObj.inquiry.application.attachments,
        );

        RemoveAttachmentsFromAzureIfExist(
            attachmentActions,
            dispatch,
            AttachmentDocumentType.EvidenceOfNameChange,
            inquiryDefObj.inquiry.application.attachments,
        );
        inquiryDefObj.inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(
            AttachmentDocumentType.EvidenceOfNameChange,
            inquiryDefObj.inquiry.application.attachments,
        );
    };

    /*
    // update attachments collection as that is seperate from other data application object call
    const updatedFilesListProofOfBirth = (files: IAttachmentMetadata[]) => {
        inquiryDefObj.inquiry.application.attachments = AppendAttachmentsToCollection(files, inquiryDefObj.inquiry.application.attachments);
        setInquiry(inquiryDefObj);

        trigger(); // re-validate
    };

    // update attachments collection as that is seperate from other data application object call
    const updatedFilesListChangeOfName = (files: IAttachmentMetadata[]) => {
        inquiryDefObj.inquiry.application.attachments = AppendAttachmentsToCollection(files, inquiryDefObj.inquiry.application.attachments);
        setInquiry(inquiryDefObj);

        trigger(); // re-validate
    }; */

    const onChangeTitle = (event: { target: { value: any } }) => {
        // will clear errors collection
        trigger(); // re-validate
    };

    const redirectToReviewAndSubmitForm = () => {
        // to Review and Submit form
        setShowSubmittedDialogOK(false);

        const keepChanges = false;
        const isDirtyForcedFalse = false;
        NavigateAway(appContext, history, keepChanges, isDirtyForcedFalse, isValid);
    };

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors && backEndValidationErrors?.length > 0) {
            backEndValidationErrors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'InvalidName':
                        setError('inquiry.application.outletPractitioner.name', { type: 'manual', message: validationError.errorMessage });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    useEffect(() => {
        if (ajaxCallsInProgress && ajaxCallsInProgress.callState) {
            setAjaxCallStateForPage({ ajaxCallState: ajaxCallsInProgress.callState, pageNumber: 0 });
        }

        if (ajaxCallsInProgress && ajaxCallsInProgress.callState === AjaxCallStateEnum.CompletedWithValidation) {
            setBackEndValidationErrors(ajaxCallsInProgress.errors);
        }

        // TODO: set Errors here:
    }, [ajaxCallsInProgress]);

    // check dispatch success state
    useEffect(() => {
        if (ajaxCallStateForPage) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                setAttemptingSave(false);
                displayBackEndValidations();
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setAttemptingSave(false);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (attemptingSave === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK && !appContext.wizardState.triggerSubmit) {
                    setAttemptingSave(false);

                    // IF VALID (or OK to move forward):
                    SetDestinationNavActionOnSuccessEditForm(appContext, NavigationDestination.ManageDetailsReviewAndSubmitPage);
                    // refresh data set now after changes saved
                    // dispatch(appActions.GetApplication());
                    // allow two seconds to see save changes dialog then go back to dashboard
                    Sleep(2000).then(() => {
                        // pop up success dialog and then decide what flow to go to next
                        setShowSubmittedDialogOK(true);
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const changeOfNameDocumentIsEmpty = (data: any) => {
        return GetActiveAttachmentsByType(inquiryDefObj.inquiry.application.attachments, AttachmentDocumentType.EvidenceOfNameChange)?.length === 0;
    };

    const proofOfBirthDocumentIsEmpty = (data: any) => {
        return GetActiveAttachmentsByType(inquiryDefObj.inquiry.application.attachments, AttachmentDocumentType.EvidenceOfBirth)?.length === 0;
    };

    // compare against original DB version
    const nameFieldsDifferFromStoredVersion = () => {
        if (isRework === true) {
            return false;
        }

        const currFirstName = getValues('inquiry.personalDetails.firstName') ?? '';
        const currMiddleName = getValues('inquiry.personalDetails.middleName') ?? '';
        const currFamilyName = getValues('inquiry.personalDetails.familyName') ?? '';
        const origFirstName = inquiryDefObj.inquiry.differencesUser?.originalVersion.firstName ?? '';
        const origMiddleName = inquiryDefObj.inquiry.differencesUser?.originalVersion.middleName ?? '';
        const origFamilyName = inquiryDefObj.inquiry.differencesUser?.originalVersion.familyName ?? '';

        return currFirstName !== origFirstName || currMiddleName !== origMiddleName || currFamilyName !== origFamilyName;
    };

    // compare against original DB version
    const dateOfBirthDiffersFromStoredVersion = () => {
        if (isRework === true) {
            return false;
        }

        const currDOB = getValues('inquiry.personalDetails.dateOfBirth');
        const diffDOB = inquiryDefObj.inquiry.differencesUser?.originalVersion.dateOfBirth;
        return currDOB !== null && diffDOB !== null && currDOB.toString() !== diffDOB.toString();
    };

    const changeOfNameDocumentIsRequired = () => {
        // if any of first name, middle name, or family name have changed then we return TRUE otherwise FALSE
        return (
            nameFieldsDifferFromStoredVersion() ||
            (isRework === true && ReworkAttachmentIsRequired(inquiryDefObj.inquiry, AttachmentDocumentType.EvidenceOfNameChange))
        );
    };

    const proofOfBirthDocumentIsRequired = () => {
        // if Date of Birth have changed then we return TRUE otherwise FALSE
        return (
            dateOfBirthDiffersFromStoredVersion() ||
            (isRework === true && ReworkAttachmentIsRequired(inquiryDefObj.inquiry, AttachmentDocumentType.EvidenceOfBirth))
        );
    };

    const scrollToFirstError = () => {
        // scroll to position of first error (if found) - as auto did not work for yup for the check boxes
        const elementFound = document.querySelectorAll('[role="alert"]:first-of-type')[0];
        // tslint:disable-next-line: no-unused-expression
        elementFound && elementFound.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    };

    // const submitHandler = event => { (event: { preventDefault: () => void }) =>
    const submitForm = (data: any) => {
        if (isValid) {
            let saveData = true;

            if (changeOfNameDocumentIsEmpty(data) && changeOfNameDocumentIsRequired()) {
                setError('inquiry.application.attachments.changeofname', {
                    type: 'manual',
                    message: VALIDATION_FILEUPLOADREQUIRED('change of name'),
                });
                saveData = false;
            }

            if (proofOfBirthDocumentIsEmpty(data) && proofOfBirthDocumentIsRequired()) {
                setError('inquiry.application.attachments.proofofbirth', {
                    type: 'manual',
                    message: VALIDATION_FILEUPLOADREQUIRED('proof of birth'),
                });
                saveData = false;
            }

            if (saveData) {
                // Save changes to application (attempt save, if that fails we do NOT move the Wizard step)
                setAttemptingSave(true);

                // check if we need a review
                const needsReview = dateOfBirthDiffersFromStoredVersion() || nameFieldsDifferFromStoredVersion() || isRework === true;
                setModalTextUpdateOk(needsReview ? MODALTEXT_UPDATEPERSONALDETAILS_OK_NEEDSREVIEW : MODALTEXT_UPDATEPERSONALDETAILS_OK_NOREVIEW);

                if (isRework === true) {
                    // copy data if a rework as the dropdown values are not making it to the data (form) as expected
                    if (data.inquiry?.personalDetails.title === undefined) {
                        data.inquiry.personalDetails.title = inquiryDefObj.inquiry.personalDetails.title;
                    }
                    if (data.inquiry?.personalDetails.gender === undefined) {
                        data.inquiry.personalDetails.gender = inquiryDefObj.inquiry.personalDetails.gender;
                    }
                    if (data.inquiry?.personalDetails.addresses[0]?.state === undefined) {
                        data.inquiry.personalDetails.addresses[0].state = inquiryDefObj.inquiry.personalDetails.addresses[0].state;
                    }
                }

                dispatch(userActions.SaveUser(data.inquiry.personalDetails));
            } else {
                // prevent form reload upon submit
                // event.preventDefault();
                scrollToFirstError();
                toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
            }
        } else {
            // prevent form reload upon submit
            // event.preventDefault();
            scrollToFirstError();
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    // compare against original DB version
    const addressStateFieldNotChanged = () => {
        return getValues('inquiry.personalDetails.addresses[0].state') === inquiryDefObj.inquiry.personalDetails.addresses[0].state;
    };

    // compare against when this form was open
    const titleFieldModifiedSinceFormOpen = () => {
        return getValues('inquiry.personalDetails.title') !== titleFieldValueUponFormOpen;
    };

    const isTitleFieldNotReallyChanged = () => {
        // if the dirty flag is set ONLY due to the autoFocus of TITLE FIELD then we need to ignore this
        //  for the purpose of checking whether form is dirty
        //  (long story: isDirty is set incorrectly AND dirtyFields are empty when should not be!)
        return (
            titleFieldModifiedSinceFormOpen() === false &&
            Object.keys(touchedFields.inquiry.personalDetails).length === 1 &&
            touchedFields.inquiry.personalDetails.title === true
        );
    };

    const attachmentsExistForThisUI = () => {
        return (
            IsAnyAttachmentsExistOfThisType(AttachmentDocumentType.EvidenceOfBirth, inquiryDefObj.inquiry.application.attachments) ||
            IsAnyAttachmentsExistOfThisType(AttachmentDocumentType.EvidenceOfNameChange, inquiryDefObj.inquiry.application.attachments)
        );
    };

    const cancelButtonAction = async (event: any) => {
        const keepChanges = false;
        const isDirtyForcedFalse = false;
        // the below is not working as expected, is always empty
        const isDirtyAlt = Object.keys(dirtyFields).length > 0 || Object.keys(touchedFields).length > 0;

        if (
            (!isDirtyAlt && !attachmentsExistForThisUI()) ||
            (isDirtyAlt && isTitleFieldNotReallyChanged() === true && addressStateFieldNotChanged() === true && attachmentsExistForThisUI() === false)
        ) {
            setShowSubmittedDialogOK(false);
            // back to review and submit page OR dashboard
            NavigateAway(appContext, history, keepChanges, isDirtyForcedFalse, isValid);
        } else {
            if (
                await ModalDialogConfirmable(
                    MODALTEXT_UNSAVEDCHANGES_DATALOSSPOTENTIAL_ANDUPLOADEDFILES,
                    MODALBUTTON_YES_DISCARDCHANGES,
                    MODALBUTTON_NO_DISCARDCHANGES,
                    {
                        title: MODALHEADER_UNSAVEDCHANGES_DATALOSSPOTENTIAL,
                        isDestructive: true,
                        modalBodyPositionTextLeft: false,
                        dataTestId: 'confirmClose',
                    },
                )
            ) {
                // reload from back end (make copy of object not link to it otherwise discard changes will not work)
                // inquiry.application = { ... };

                // MUST now return to dashboard (especially) upon rework must force user to re-upload a document
                SetDestinationNavActionOnSuccessEditForm(appContext, NavigationDestination.Dashboard);

                // remove ALL uploaded documents to avoid potential issues in future
                if (HasAnyPersonalDetailsAttachments(inquiryDefObj.inquiry.application.attachments)) {
                    RemoveAllAttachments();
                }

                reset(inquiryDefObj.inquiry); // discard any changes

                setShowSubmittedDialogOK(false);

                // allow time for set of next step tostore
                Sleep(200).then(() => {
                    history?.push('/'); // back to dashboard
                    // NOT WORKING: NavigateAway(appContext, history, keepChanges, isDirtyForcedFalse, isValid);
                });
            }
        }
    };

    const redirectToDashboard = () => {
        // to Dashboard
        // setDisplayState({ ...initUiState });

        history.push('/');
    };

    const handleClickBackToDashboard = () => {
        redirectToDashboard();
    };

    // as we share AddressComponent, where trigger() will clear errors collection, and we set errors manually here, thereforehave addressComponent clear it so we send a null function
    // tslint:disable-next-line: no-empty
    const triggerNull = () => {};

    const isLoading = inquiryDefObj === null || inquiryDefObj?.inquiry === null || inquiryDefObj?.inquiry?.personalDetails?.id === 0;

    return (
        <>
            <PageTitle title='Update personal details' description=''>
                <span>
                    Update your personal details on this page. When you have finished making your changes and they have been saved,{' '}
                    <u>
                        you must then <b>submit</b> them
                    </u>{' '}
                    before they will take effect. Changes to your name and/or date of birth will require review by the Practitioner Accreditation Unit and will
                    require accompanying evidence of the change.
                </span>
            </PageTitle>
            <PageWrapper
                pageName='PersonalDetailsName'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={cancelButtonAction}
                textForSaveContinueButton='Save'
                textForBackButton='Cancel'
            >
                <PageFieldsTitle title='Name details' />
                <PageMandatoryLabelText />
                <Loader isLoading={attemptingSave === true} loaderText='Saving your changes. Please wait...' />
                <Loader isLoading={isLoading === true} loaderText='Loading, please wait...' />
                <ModalDialog
                    dataTestId='UpdateOkDialog'
                    modalTitle={MODALHEADER_UPDATEDETAILS_OK}
                    modalBodyText={modalTextUpdateOk}
                    showMe={showSubmittedDialogOK}
                    confirmLabel='Review and submit all of my changes'
                    handleClickYes={redirectToReviewAndSubmitForm}
                    abortLabel='Back to Dashboard'
                    handleClickNo={handleClickBackToDashboard}
                />
                <ErrorSummary errors={errors} />
                {isLoading === false && (
                    <>
                        <PersonalDetailsNameFieldsEdit
                            inquiry={inquiryDefObj.inquiry}
                            setInquiryObj={setInquiryObj}
                            register={register}
                            control={control}
                            errors={errors}
                            watch={watch}
                            isValid={isValid}
                            isDirty={isDirty}
                            trigger={trigger}
                            setValue={setValue}
                            onChangeTitle={onChangeTitle}
                            authService={authService}
                            shownAsPartOfAllPersonalDetails={true}
                            showFormerName={true}
                            showFileUploadFields={true}
                            dispatch={dispatch}
                            // updatedFilesListChangeOfName={updatedFilesListChangeOfName}
                            // updatedFilesListProofOfBirth={updatedFilesListProofOfBirth}
                            isRework={isRework}
                        />
                        <EmptyRow />
                        <PersonalDetailsContactFieldsEdit
                            inquiry={inquiryDefObj.inquiry}
                            register={register}
                            control={control}
                            errors={errors}
                            watch={watch}
                            isValid={isValid}
                            setValue={setValue}
                            trigger={triggerNull}
                            displayPrimaryEmailNote={true}
                            isRework={isRework}
                        />
                    </>
                )}
                <EmptyRow />
            </PageWrapper>
        </>
    );
};

export default EditPersonalDetailsPage;
