// Professional Indemnity page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the Complaints mechanism-Member page
//  - allows navigation forward to the Declaration and Consent page
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { get } from 'react-hook-form';
import { RegoApplicationPageProps } from '../../PageInterfaces';
import { EnsureData } from '../RegistrationApplicationWizard/wizardHelperFunctions';
import ApplicationActions from '../../../actions/applicationActions';
import AttachmentActions from '../../../actions/attachmentsActions';
import { GetTextForSaveAndContinueButton, HideTheBackButton, SubmitThisPageIfActive } from '../../PageFunctions';
import { MoveWizardForward, MoveWizardForwardForResubmit, NavigateAway } from '../../../components/wizard/wizardStepFunctions';
import { SetDestinationNavActionOnSuccess } from '../../../components/wizard/wizardStateManager';
import { ParseBool, Sleep } from '../../../utils/Common';
import { WithinThirtyDays } from '../../../utils/Dates';
import {
    GetActiveInsuranceComplaintAttachmentIdCollection,
    IsActiveComplaintLegalAidOtherOnPanel,
    InsertAttachmentToLocalInquiryObject,
    RemoveAttachmentFromLocalInquiryObject,
    RemoveAttachmentsForInsuranceWhenChanges,
} from '../../../utils/ComplaintsUtils';
import {
    HEADER_PROFESSIONALINDEMNITYINSURANCE,
    MODALBUTTON1_DATALOSSREVERT,
    MODALBUTTON2_DATALOSSPROCEED,
    MODALHEADER_DATALOSSPOTENTIAL,
    MODALHEADER_PROFINDEMNITYINSURANCE_WARNING_LEGALAIDPANELLISTS,
    MODALTEXT_DATALOSSPOTENTIAL,
    MODALTEXT_PROFINDEMNITYINSURANCE_WARNING_LEGALAIDPANELLISTS,
    NOTE_INSURANCE_MISSING_WARNING,
    NOTE_INSURANCE_PERIOD_WARNING,
    NOTE_INSURANCE_START_DATE_IN_FUTURE,
    NOTE_PROFINDEMNITYINSURANCE_WARNING_LEGALAIDPANELLISTS,
    PLACEHOLDER_DATE,
    PROFESSIONALINDEMNITYINSURANCE_INFO,
} from '../../../services/Constants';
import { AjaxCallStateEnum, AttachmentDocumentType, NavigationDestination, WizardMode, WizardStepNumber } from '../../../services/Enums';
import { VALIDATION_ERROR_POPUP_MESSAGE, VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../../services/Validation';
import { toastErrorMessage } from '../../../actions/toastrMessages';
import { ErrorOnSubmitForm, IsAnyAttachmentsExistOfThisTypeForThisOrNonSavedComplaint } from '../../../utils/AppUtils';
import ErrorSummary from '../../../controls/ErrorSummary';
import NoteText from '../../../controls/NoteText';
import { ModalDialogConfirmable } from '../../../controls/ModalDialogConfirmable';
import AppContext from '../../../stateManagement/context/AppContext';
import PageWrapper from '../../../components/pageWrapper';
import PageTitle from '../../../components/pageTitle';
import PageMandatoryLabelText from '../../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import LabelField from '../../../components/fields/LabelField';
import FileUploadField from '../../../components/fields/FileUploadField';
import InputDateField from '../../../components/fields/InputDateField';
import RadioButtonGroupField, { RadioButtonOption } from '../../../components/fields/RadioButtonGroupField';
import styles from '../../Pages.module.scss';
import { IAttachmentMetadata } from '../../../api/interfacesApi';

const { childItems, controlMaxWidth, startDateWarningNote } = styles;

const ProfessionalIndemnityPage = (props: RegoApplicationPageProps) => {
    const {
        inquiry,
        setInquiryObj,
        authService,
        goingBackwards,
        dispatch,
        handleClickPrevious,
        handleSubmit,
        clearErrors,
        register,
        // unregister,
        trigger,
        setError,
        setValue,
        watch,
        control,
        errors,
        isValid,
        isActive,
        ajaxCallStateForPage,
        backEndValidationErrors,
        triggerSubmit,
        setTriggerSubmit,
    } = props;

    const appContext = useContext(AppContext);
    const isResubmit = appContext.wizardState.wizardMode === WizardMode.Resubmit ? true : false;
    const disableField = isResubmit;
    const history = useHistory();
    const formRef = useRef();
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const attachmentActions = useMemo(() => {
        return new AttachmentActions(authService);
    }, [authService]);
    // even though we have redux states, we HAVE to capture the attempt due to navigation and Get and Save user calls on the page
    const [attemptingSave, setAttemptingSave] = useState(false);
    const [dataInquiry, setDataInquiry] = useState(inquiry);
    const [attemptingSaveApp, setAttemptingSaveApp] = useState(false);
    const [showInsuranceDetailsFields, setShowInsuranceDetailsFields] = useState(false);
    const [filesExist, setFilesExist] = useState(false);
    const [showNoInsuranceWarning, setShowNoInsuranceWarning] = useState(false);
    const [showPeriodOfInsuranceWarning, setShowPeriodOfInsuranceWarning] = useState(false);
    const [showFromDateInFutureWarning, setShowFromDateInFutureWarning] = useState(false);
    const [showLegalAidPanellistWarningAndPreventProgress, setShowLegalAidPanellistWarningAndPreventProgress] = useState(false);

    const hasInsuranceWatched: boolean | null = watch(
        'inquiry.application.activeComplaintsMechanismObj.hasProfessionalIndemnityInsuranceViaMembership',
        inquiry.application.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership,
    );
    const insuranceFromDateWatched: Date | null = watch(
        'inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceFromDate',
        inquiry.application.activeComplaintsMechanismObj?.professionalIndemnityInsuranceFromDate,
    );
    const insuranceEndDateWatched: Date | null = watch(
        'inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceToDate',
        inquiry.application.activeComplaintsMechanismObj?.professionalIndemnityInsuranceToDate,
    );

    // initial check
    useEffect(() => {
        // show a note and PREVENT SAVE going forward if user is Legal Aid/Other AND selected is on Panel
        if (
            inquiry.application.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership === false &&
            IsActiveComplaintLegalAidOtherOnPanel(inquiry.application)
        ) {
            setShowLegalAidPanellistWarningAndPreventProgress(true);
        } else {
            setShowLegalAidPanellistWarningAndPreventProgress(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // show/hide the Note for Start Date in future
        const today = new Date();
        if (insuranceFromDateWatched && showFromDateInFutureWarning === false && insuranceFromDateWatched > today) {
            setShowFromDateInFutureWarning(true);
        }
        if (insuranceFromDateWatched && showFromDateInFutureWarning === true && insuranceFromDateWatched < today) {
            setShowFromDateInFutureWarning(false);
        }
    }, [insuranceFromDateWatched, showFromDateInFutureWarning]);

    if (hasInsuranceWatched && insuranceEndDateWatched && showPeriodOfInsuranceWarning === false && WithinThirtyDays(insuranceEndDateWatched)) {
        setShowPeriodOfInsuranceWarning(true);
    }

    if (
        showPeriodOfInsuranceWarning === true &&
        (hasInsuranceWatched === false || insuranceEndDateWatched === null || !WithinThirtyDays(insuranceEndDateWatched))
    ) {
        setShowPeriodOfInsuranceWarning(false);
    }

    if (ParseBool(hasInsuranceWatched) === true) {
        if (showNoInsuranceWarning === true) {
            setShowNoInsuranceWarning(false);
        }
        if (showInsuranceDetailsFields === false) {
            setShowInsuranceDetailsFields(true);
        }
    }

    if (hasInsuranceWatched !== undefined && hasInsuranceWatched !== null && ParseBool(hasInsuranceWatched) === false) {
        if (showNoInsuranceWarning === false) {
            setShowNoInsuranceWarning(true);
        }
        if (showInsuranceDetailsFields === true) {
            setShowInsuranceDetailsFields(false);
        }
    }

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors.errors?.length > 0) {
            backEndValidationErrors.errors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'CompleteWorkingWithChildrenCheck':
                        setError('inquiry.application.insurance', { type: 'manual', message: validationError.errorMessage });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    const LegalAidPanellistFlow = () => {
        ModalDialogConfirmable(MODALTEXT_PROFINDEMNITYINSURANCE_WARNING_LEGALAIDPANELLISTS, 'OK', 'OK', {
            title: MODALHEADER_PROFINDEMNITYINSURANCE_WARNING_LEGALAIDPANELLISTS,
            okOnly: true,
            dataTestId: 'modalWarning_LegalAidEvidenceMissing',
        });

        SetDestinationNavActionOnSuccess(appContext, NavigationDestination.Dashboard);

        // wait for 1 seconds to allow toasty message to display upon save success
        Sleep(1000).then(() => {
            const keepChanges = true;
            const isDirty = false;
            NavigateAway(appContext, history, keepChanges, isDirty, isValid);
        });
    };

    // check dispatch success state
    useEffect(() => {
        if (isActive === true && ajaxCallStateForPage && ajaxCallStateForPage.pageNumber === WizardStepNumber.ProfessionalIndemnity) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                setAttemptingSave(false);
                setAttemptingSaveApp(false);
                displayBackEndValidations();
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setAttemptingSave(false);
                setAttemptingSaveApp(false);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (attemptingSave === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK && !appContext.wizardState.triggerSubmit) {
                    setAttemptingSave(false);
                    // refresh data set now after changes saved
                    // dispatch(appActions.GetApplication());

                    // If no insurance AND Legal Aid/Other panellist then show Modal and return user to Dashboard!
                    let preventNavForward = false;
                    if (
                        hasInsuranceWatched !== undefined &&
                        hasInsuranceWatched !== null &&
                        ParseBool(hasInsuranceWatched) === false &&
                        IsActiveComplaintLegalAidOtherOnPanel(inquiry.application)
                    ) {
                        LegalAidPanellistFlow();
                        preventNavForward = true;
                    }

                    // IF VALID (or OK to move forward):
                    if (preventNavForward === false) {
                        setAttemptingSaveApp(true);
                        // so we can set the current application step (use stored data object so we update the currentStep)
                        const updateOutlet = false;
                        dispatch(appActions.UpdateApplication(dataInquiry!.application, updateOutlet));
                    }
                } else if (
                    attemptingSaveApp === true &&
                    ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK &&
                    !appContext.wizardState.triggerSubmit
                ) {
                    setAttemptingSaveApp(false);

                    // refresh data set now after changes saved
                    dispatch(appActions.GetApplication());

                    // Move wizard forward to next step pending whether we are navigating normally or for the resubmit flow
                    if (appContext.wizardState.wizardMode === WizardMode.New) {
                        MoveWizardForward(appContext, isValid);
                    } else {
                        MoveWizardForwardForResubmit(appContext, isValid);
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    /* const setFileUploadValidationError = () => {
        setError('inquiry.application.attachments.insurance', {
            type: 'manual',
            message: VALIDATION_FILEUPLOADREQUIRED('Insurance'),
        });
    }; */

    const submitForm = (data: any) => {
        if (isValid) {
            // Save changes to application (attempt save, if that fails we do NOT move the Wizard step)
            // valid, process save

            /* no longer needed as is handled by active CM obj
            // inject this object into a collection, if that exists, replacing existing collection item only
            const membershipExists = IsAnyComplaintProfAssocMember(data.inquiry.application.complaintsMechanism);

            if (membershipExists === false) {
                data.inquiry.application.complaintsMechanism.push(data.inquiry.application.activeComplaintsMechanismObj);
            } */

            // set existing data now as we dont capture it in this page
            EnsureData(appContext, WizardStepNumber.ProfessionalIndemnity, data, inquiry);

            /*
            // now we have culled (upon new application process) we can inject the values
            // set the values from this form into the object
            if (membershipExists === true) {
                data.inquiry.application.activeComplaintsMechanismObj.hasProfessionalIndemnityInsuranceViaMembership =
                    data.inquiry.application.activeComplaintsMechanismObj.hasProfessionalIndemnityInsuranceViaMembership;
                data.inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceFromDate =
                    data.inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceFromDate;
                data.inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceToDate =
                    data.inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceToDate;
            } */

            setDataInquiry(data.inquiry);

            // Save changes to application (attempt save, if that fails we do NOT move the Wizard step)
            setAttemptingSave(true);

            dispatch(appActions.UpdateComplaintsMechanism(data.inquiry.application.activeComplaintsMechanismObj));
        } else {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    SubmitThisPageIfActive(isActive, triggerSubmit, formRef, setTriggerSubmit);

    const removeErrorsAndReValidate = () => {
        // do the trigger only once
        let runTrigger = false;
        const errorAttachments: { [key: string]: any[] } = get(errors, 'inquiry.application.attachments');

        if (errorAttachments && errorAttachments !== undefined) {
            if (errorAttachments.insurance) {
                clearErrors('inquiry.application.attachments.insurance');
                runTrigger = true;
            }
        }

        if (runTrigger === true) {
            trigger(); // reset validation so we dont get a form isValid=false causing inability to save
        }
    };

    // update attachments collection as that is seperate from other data application object call
    const updatedFilesList = (files: any) => {
        if (files && files.length > 0) {
            if (filesExist === false) {
                setFilesExist(true);
                // immediately remove any errors of type file not uploaded (for parent nodes)
                removeErrorsAndReValidate();
            }
        } else {
            if (filesExist === true) {
                setFilesExist(false);
            }
        }
    };

    // based upon the original value, we remove data as the user has changed their option whilst the data already exists
    const removePriorData = () => {
        // always remove the insurance type attachments, if exists
        RemoveAttachmentsForInsuranceWhenChanges(attachmentActions, dispatch, inquiry);

        // clear this in case user switched and did not add a file but trips validation
        setValue('inquiry.application.activeComplaintsMechanismObj.activeAttachmentId', '');
        inquiry.application.activeComplaintsMechanismObj!.activeAttachmentId = '';
        setValue('inquiry.application.activeComplaintsMechanismObj.attachmentExists', '');
        inquiry.application.activeComplaintsMechanismObj!.attachmentExists = '';

        // if dates were set we clear them now too
        if (insuranceFromDateWatched !== null || insuranceEndDateWatched !== null) {
            setValue('inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceFromDate', undefined);
            inquiry.application.activeComplaintsMechanismObj!.professionalIndemnityInsuranceFromDate = undefined;
            setValue('inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceToDate', undefined);
            inquiry.application.activeComplaintsMechanismObj!.professionalIndemnityInsuranceToDate = undefined;
        }

        setInquiryObj(inquiry);
    };

    const doesPriorDataExistThatWillBeDeleted = () => {
        // ONLY handles case where change from YES to NO (as NO to YES is no data)
        return (
            insuranceFromDateWatched !== null ||
            insuranceEndDateWatched !== null ||
            IsAnyAttachmentsExistOfThisTypeForThisOrNonSavedComplaint(
                inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId,
                AttachmentDocumentType.Insurance,
                inquiry.application.attachments,
            )
        );
    };

    const setHasInsuranceRadioButton = () => {
        // must check one then!
        const radioButtons: NodeListOf<HTMLElement> = document.getElementsByName(
            'inquiry.application.activeComplaintsMechanismObj.hasProfessionalIndemnityInsuranceViaMembership',
        );
        const insuranceString: string = hasInsuranceWatched === true ? 'true' : 'false';

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < radioButtons.length; i++) {
            const radioButton: HTMLInputElement = radioButtons[i] as HTMLInputElement;
            if (radioButton.value === insuranceString!) {
                radioButton.checked = true;
            }
        }
    };

    const onChangeHasProfessionalInsurance = async (event: any) => {
        const selectedValue = ParseBool(event.target.value);

        // only check for prior data here if we change from YES to NO
        const dataExistsThatCouldBeDeleted = selectedValue === false && doesPriorDataExistThatWillBeDeleted();

        function revertSelection() {
            // Setting the target.checked property to the defaultChecked will
            // deselect the current radio button and select the radio button that
            // was previously selected.
            event.target.checked = event.target.defaultChecked;

            event.preventDefault();
            event.stopPropagation();
            // return the orig value
            setValue(
                `inquiry.application.activeComplaintsMechanismObj.hasProfessionalIndemnityInsuranceViaMembership`,
                hasInsuranceWatched,
                // NULL by here: inquiry.application.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership,
                {
                    shouldDirty: true,
                    shouldValidate: true,
                },
            );
            setHasInsuranceRadioButton();
        }

        function changeSelection() {
            // manage the value manually as ref did not work for the react hook form
            setValue('inquiry.application.activeComplaintsMechanismObj.hasProfessionalIndemnityInsuranceViaMembership', selectedValue, {
                shouldDirty: true,
                shouldValidate: true,
            });

            inquiry.application.activeComplaintsMechanismObj!.hasProfessionalIndemnityInsuranceViaMembership = selectedValue;
            setInquiryObj(inquiry);

            // change whether file is mandatory
            setValue('inquiry.application.CMInsuranceDocumentAttachmentIsMandatory', selectedValue);

            if (selectedValue === false) {
                // hide the note for period of insurance
                setShowPeriodOfInsuranceWarning(false);
            }

            // show a note and PREVENT SAVE going forward if user is Legal Aid/Other AND selected is on Panel
            if (selectedValue === false && IsActiveComplaintLegalAidOtherOnPanel(inquiry.application)) {
                setShowLegalAidPanellistWarningAndPreventProgress(true);
            } else {
                setShowLegalAidPanellistWarningAndPreventProgress(false);
            }

            Sleep(200).then(() => {
                trigger(); // refresh validation
            });
        }

        if (dataExistsThatCouldBeDeleted === true) {
            // prompt if user changes type and has previously submitted data, which will be deleted
            if (
                await ModalDialogConfirmable(MODALTEXT_DATALOSSPOTENTIAL, MODALBUTTON2_DATALOSSPROCEED, MODALBUTTON1_DATALOSSREVERT, {
                    title: MODALHEADER_DATALOSSPOTENTIAL,
                    dataTestId: 'modalPromptToRemoveData_ChangeProfInsurance',
                    isDestructive: true,
                })
            ) {
                changeSelection();
                // user has accepted that data can be removed so we remove that now
                removePriorData();
            } else {
                revertSelection();
            }
        } else {
            changeSelection();
        }
    };

    const fromDateInFutureWarningNote = () => {
        return (
            <>
                <Row className={`${startDateWarningNote} ${controlMaxWidth}`}>
                    <Col md='11'>
                        <NoteText displayText={NOTE_INSURANCE_START_DATE_IN_FUTURE} />
                    </Col>
                    <Col md='1' className='justify-content-end' />
                </Row>
                <Row className={controlMaxWidth}>
                    <Col>&nbsp;</Col>
                </Row>
            </>
        );
    };

    const removedFile = (attachmentId: string) => {
        // refresh CM object/active obj
        RemoveAttachmentFromLocalInquiryObject(attachmentId, setValue, inquiry);
        trigger(); // re-validate
    };

    const addedFile = (newAttachment: IAttachmentMetadata) => {
        // refresh CM object/active obj (NOTE: CANNOT get App in case user simply adds/removes files, it will clear the form!)
        InsertAttachmentToLocalInquiryObject(newAttachment, setValue, inquiry);
        trigger(); // re-validate
    };

    const InsuranceDetailsFileUploadDisplay = () => {
        const idFileUpload = 'inquiry.application.activeComplaintsMechanismObj.attachmentExists';
        const attachmentIdCollection = GetActiveInsuranceComplaintAttachmentIdCollection(inquiry?.application);
        // const errorFieldAttachmentThisIndex = errors.inquiry?.application?.activeComplaintsMechanismObj; // complaintsMechanism?.find((_element: any, i: number) => i === 0); // errors.inquiry?.application?.attachments?.fundedOrg?.find((_element: any, i: number) => i === index);
        // const errorFieldAttachmentInsurance = errorFieldAttachmentThisIndex && errorFieldAttachmentThisIndex?.activeattachmentid;

        return (
            <FileUploadField
                id={idFileUpload} // we need to render this when relationship is undefined or other, as when handing it the docType if it dynamically changes the FileUpload is not updating
                data-id={0}
                attachmentIdCollection={attachmentIdCollection}
                refreshIfAttachmentIdNotFound={true} // will trigger empty fileInfo if the above Id not yet uploaded/exists
                maxFilesCount={3}
                applicationId={inquiry?.application.applicationId}
                complaintsProcessId={inquiry?.application.activeComplaintsMechanismObj?.complaintsMechanismId}
                authService={authService}
                displayNameSuffix='insurance'
                errorsFieldSuffix='insurance'
                documentType={AttachmentDocumentType.Insurance}
                isMandatory={true}
                updatedFilesList={updatedFilesList}
                register={register}
                goingBackwards={goingBackwards}
                dispatch={dispatch}
                errorsField={errors.inquiry?.application?.attachments}
                removedFile={removedFile}
                addedFile={addedFile}
                // errorsField={errorFieldAttachmentInsurance}
            />
        );
    };

    const insuranceDetailsDisplay = () => {
        if (showInsuranceDetailsFields === false) {
            return null;
        }

        const today = new Date();
        // tslint:disable-next-line: prefer-const
        let nextYear = new Date();
        nextYear.setFullYear(nextYear.getFullYear() + 1);

        const openingInsuranceFromDate = insuranceFromDateWatched ? insuranceFromDateWatched : today;
        const openingInsuranceToDate = insuranceEndDateWatched ? insuranceEndDateWatched : nextYear;
        const insuranceAttachmentDefault: boolean = true;

        return (
            <Row className={childItems}>
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.CMInsuranceDocumentAttachmentIsMandatory'
                    {...register('inquiry.application.CMInsuranceDocumentAttachmentIsMandatory')}
                    defaultValue={insuranceAttachmentDefault}
                />
                <Row>
                    <Col>
                        <LabelField
                            id='periodInsuranceLabel'
                            displayName='Current insurance dates'
                            value=''
                            isMandatory={false}
                            isDisplayNameFontSizeLarger={true}
                            hideFinalEmptyRow={true}
                        />
                    </Col>
                </Row>
                <Row className={controlMaxWidth}>
                    <Col>
                        <InputDateField
                            id='inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceFromDate'
                            displayName='Start date'
                            defaultValue={
                                inquiry.application.activeComplaintsMechanismObj?.professionalIndemnityInsuranceFromDate
                                    ? inquiry.application.activeComplaintsMechanismObj?.professionalIndemnityInsuranceFromDate
                                    : undefined
                            }
                            isMandatory={true}
                            openToDate={openingInsuranceFromDate}
                            yearDropdownRange={5}
                            dropdownMode='select'
                            register={register}
                            control={control}
                            disabled={disableField}
                            placeHolder={PLACEHOLDER_DATE}
                            errorsField={errors.inquiry?.application?.activeComplaintsMechanismObj?.professionalIndemnityInsuranceFromDate}
                            hideFinalEmptyRow={true}
                        />
                    </Col>
                </Row>
                <Row className={controlMaxWidth}>
                    <Col>
                        <InputDateField
                            id='inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceToDate'
                            displayName='End date'
                            defaultValue={
                                inquiry.application.activeComplaintsMechanismObj?.professionalIndemnityInsuranceToDate
                                    ? inquiry.application.activeComplaintsMechanismObj?.professionalIndemnityInsuranceToDate
                                    : undefined
                            }
                            isMandatory={true}
                            openToDate={openingInsuranceToDate}
                            yearDropdownRange={5}
                            yearDropdownRangeStartingYear={nextYear.getFullYear()}
                            dropdownMode='select'
                            register={register}
                            control={control}
                            disabled={disableField}
                            placeHolder={PLACEHOLDER_DATE}
                            hideFinalEmptyRow={true}
                            errorsField={errors.inquiry?.application?.activeComplaintsMechanismObj?.professionalIndemnityInsuranceToDate}
                        />
                    </Col>
                </Row>
                {showFromDateInFutureWarning && fromDateInFutureWarningNote()}
                <Row className={controlMaxWidth}>
                    <Col>{InsuranceDetailsFileUploadDisplay()}</Col>
                </Row>
            </Row>
        );
    };

    const radioOptionsHasInsurance: RadioButtonOption[] = [
        {
            label: 'Through a policy of professional indemnity insurance established through membership with an insurance provider.',
            value: true,
            disabled: disableField,
            children: insuranceDetailsDisplay(),
        },
        {
            label: 'I do not yet have professional indemnity insurance and wish to apply for conditional accreditation',
            value: false,
            disabled: disableField,
        },
    ];

    return (
        <>
            <PageTitle title='Professional indemnity insurance' description={HEADER_PROFESSIONALINDEMNITYINSURANCE} />
            <PageWrapper
                pageName='Insurance'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={handleClickPrevious}
                hideBackButton={HideTheBackButton(appContext)}
                textForSaveContinueButton={GetTextForSaveAndContinueButton(appContext)}
            >
                <PageFieldsTitle title='Professional indemnity insurance' />
                <PageMandatoryLabelText />
                <ErrorSummary errors={errors} />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.applicationId'
                    {...register('inquiry.application.applicationId')}
                    defaultValue={inquiry.application.applicationId}
                />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.activeComplaintsMechanismObj.professionalAssociation.professionalAssociationId'
                    {...register('inquiry.application.activeComplaintsMechanismObj.professionalAssociation.professionalAssociationId')}
                    defaultValue={inquiry.application.activeComplaintsMechanismObj?.professionalAssociation?.professionalAssociationId}
                />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.activeComplaintsMechanismObj.outlet.outletId'
                    {...register('inquiry.application.activeComplaintsMechanismObj.outlet.outletId')}
                    defaultValue={inquiry.application.activeComplaintsMechanismObj?.outlet?.outletId}
                />
                <LabelField
                    id='infoLabel'
                    displayName='Information regarding professional indemnity insurance'
                    value={PROFESSIONALINDEMNITYINSURANCE_INFO}
                    isMandatory={false}
                />
                <RadioButtonGroupField
                    options={radioOptionsHasInsurance}
                    id='inquiry.application.activeComplaintsMechanismObj.hasProfessionalIndemnityInsuranceViaMembership'
                    displayName='Select your insurance cover'
                    fieldLabel=''
                    defaultValue={inquiry.application.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership} // only set default if value exists
                    isMandatory={true}
                    control={control}
                    register={register}
                    errorsField={errors.inquiry?.application?.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership}
                    onChange={onChangeHasProfessionalInsurance}
                />
                {showNoInsuranceWarning && !showLegalAidPanellistWarningAndPreventProgress && (
                    <Row>
                        <Col md='11'>
                            <NoteText displayText={NOTE_INSURANCE_MISSING_WARNING} />
                        </Col>
                        <Col md='1' className='justify-content-end' />
                    </Row>
                )}
                {showPeriodOfInsuranceWarning && (
                    <Row>
                        <Col md='11'>
                            <NoteText displayText={NOTE_INSURANCE_PERIOD_WARNING} />
                        </Col>
                        <Col md='1' className='justify-content-end' />
                    </Row>
                )}
                {showLegalAidPanellistWarningAndPreventProgress && (
                    <Row>
                        <Col md='11'>
                            <NoteText displayText={NOTE_PROFINDEMNITYINSURANCE_WARNING_LEGALAIDPANELLISTS} />
                        </Col>
                        <Col md='1' className='justify-content-end' />
                    </Row>
                )}
            </PageWrapper>
        </>
    );
};

export default ProfessionalIndemnityPage;
