/* generic utility class used throughout application */

export function IsObjectEmpty(obj: any) {
    // because Object.keys(new Date()).length === 0;
    // we have to do some additional check
    return (
        obj && // 👈 null and undefined check
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
    );
}

export function IsNumeric(val: any) {
    return !isNaN(+val);
}

export function ParseBool(val: string | boolean | null) {
    return val === '1' || val === 'yes' || val === 'Yes' || val === true || val === 'true' || val === 'True';
}

export function DisplayBool(val: string | boolean | null) {
    return val === '1' || val === 'yes' || val === 'Yes' || val === true || val === 'true' || val === 'True' ? 'Yes' : 'No';
}

export function PadLeadingZeros(num: number, size: number) {
    let s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
}

export function IsEnterOrSpaceKeyPressed(event: any) {
    const keycode = event.keyCode ? event.keyCode : event.which;
    return keycode === 13 || keycode === 32;
}

// sleep for N milliseconds, tip: 1000ms=1second
export const Sleep = (time: number | undefined) => new Promise(acc => setTimeout(acc, time));

export function IsJsonString(str: any) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function CapitalizeWords(name: string) {
    return name.replace(/^(.)|\s+(.)/g, (s: string) => s.toUpperCase());
    /// (\b[a-z](?!\s))/g
    // return name.replace(/\b(\w)/g, (s: string) => s.toUpperCase());
    // return name.replace(/(\b[a-z'",._$&@](?!\s))/g, (s: string) => s.toUpperCase());
}

// keep apostrophes, commas, hyphens, etc most throw out
export function SanitiseInputText(inputValue: string) {
    // DEV NOTE: (hypen) needs to be at start or end ofsequence
    // eslint-disable-next-line no-useless-escape
    return inputValue.replace(/[^a-zA-Z0-9'",._$&@ ():\/-]/g, '').replace(/[\\#+~%;*?<>[\]{}=\^]/g, '');
}

// avoid javascript: based injection attacks
export function validateAndSecureURL(url: string): string {
    if (url === undefined || url === '' || url === '#') {
        return '';
    } else {
        if (url.startsWith('undefined') || url.startsWith('/')) {
            // eslint-disable-next-line no-script-url
            return url.toLowerCase().replace('javascript:', '/javascript/:/');
        } else {
            try {
                const parsed = new URL(url);
                if (['https:', 'http:', 'mailto:'].includes(parsed.protocol)) {
                    // eslint-disable-next-line no-script-url
                    return url.toLowerCase().replace('javascript:', '/javascript/:/');
                } else {
                    // not valid
                    return '';
                }
            } catch {
                return ''; // not valid, could be we start entering abcd into web address field
            }
        }
    }
}
