import React from 'react';
import style from './Controls.module.scss';

const { guidanceTextStyle } = style;

interface GuidanceTextProps {
    guidanceText?: string;
    fieldName?: string;
}

export const GuidanceText = (props: GuidanceTextProps) => {
    const { guidanceText, fieldName } = props;

    if (!guidanceText) return null;

    const id = fieldName ? `g_${fieldName}` : `g_${guidanceText.substring(0, Math.max(guidanceText.length, 10))}`;

    return (
        <div id={id} className={guidanceTextStyle}>
            <span>{guidanceText}</span>
        </div>
    );
};

export default GuidanceText;
