// Outlet Details page
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
// below based on: import 'react-accessible-accordion/dist/fancy-example.css';
import '../../pages/OutletDetails/react-accessible-accordion.css';
import AuthService from '../../services/AuthService';
import Loader from '../../controls/Loader';
import DiffPersonalDetailsPage from '../manageDetails/personalDetails/diffPersonalDetailsPage';
import { Inquiry } from '../../api/interfacesApi';
import DiffComplaintsMechanismDisplay from './complaintsMechanism/pages/diffComplaintsMechanismDisplay';
import {
    GetAddedComplaintsMechanismCollection,
    GetRemovedComplaintsMechanismCollection,
    GetUpdatedComplaintsMechanismCollection,
} from '../../utils/ComplaintsUtils';
import { IsObjectEmpty, Sleep } from '../../utils/Common';
import { IsReworkFilesSupplied, IsValidGuidIdentifier } from '../../utils/AppUtils';
import { EmptyRow } from '../../controls/EmptyRow';

interface RegionStateItem {
    uuid: number;
    tabName: string;
    isExpanded: boolean;
    isCollapsible: boolean;
}

interface ManageDetailsRegionsProps {
    authService: AuthService;
    inquiry: Inquiry;
    refreshReviewAndSubmit: any;
    includesAnInvalidComplaintMech: any;
}

// see OutletRegions.tsx
//
const ManageDetailsRegions = (props: ManageDetailsRegionsProps) => {
    const { authService, inquiry, refreshReviewAndSubmit, includesAnInvalidComplaintMech } = props;

    const [regionState, setRegionState] = useState<RegionStateItem[]>([]);
    const [isRegionStateLoaded, setIsRegionStateLoaded] = useState<boolean>(false);
    const [isToggling, setIsToggling] = useState<boolean>(false);
    const [isExpandAll, setExpandAll] = useState<boolean | undefined>(false);
    const [accordionKey, setAccordionKey] = useState(0);

    const dispatch: Dispatch<any> | null = useDispatch();

    const addedCMcollection = GetAddedComplaintsMechanismCollection(inquiry.differencesComplaintsCollection);
    const removedCMcollection = GetRemovedComplaintsMechanismCollection(inquiry.differencesComplaintsCollection, inquiry.application.complaintsMechanism);
    const updatedCMcollection = GetUpdatedComplaintsMechanismCollection(inquiry.differencesComplaintsCollection);

    const userAttachmentsReworkUploaded = IsReworkFilesSupplied(inquiry.application);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const ToggleAllPanels = (expandAll: boolean) => {
        // setCurrentlyTogglingExpand(true);
        setExpandAll(expandAll);
        // give this a half second to process otherwise infinite loop
        Sleep(400).then(() => {
            // setCurrentlyTogglingExpand(false);
            setAccordionKey(prev => prev + 1); // to provoke UI change
        });
    };

    const setInitialTabState = () => {
        //        if (inquiry.differencesComplaintsCollection !== null && regionState.length === 0) {
        if (regionState.length === 0) {
            // View mode: expanded (if collection is set) by default and all are collapsible
            const pdIsExpanded = IsValidGuidIdentifier(inquiry.differencesUser?.originalVersion.id) || userAttachmentsReworkUploaded === true; // IsPersonalDetailsEditedButNotYetSubmitted(inquiry.application, inquiry.personalDetails);
            let cmIsExpanded = false;

            if (
                (addedCMcollection !== null && addedCMcollection.length > 0) ||
                (removedCMcollection !== null && removedCMcollection.length > 0) ||
                (updatedCMcollection !== null && updatedCMcollection.length > 0)
            ) {
                cmIsExpanded = true;
            }

            regionState.push({ uuid: 1, tabName: 'reviewPersonalDetails', isExpanded: pdIsExpanded, isCollapsible: true });
            regionState.push({ uuid: 2, tabName: 'reviewComplaintsMechanisms', isExpanded: cmIsExpanded, isCollapsible: true });
            // regionState.push({ uuid: 3, tabName: 'openingHours', isExpanded: false, isCollapsible: true });
            // regionState.push({ uuid: 4, tabName: 'serviceCoverage', isExpanded: false, isCollapsible: true });
            // regionState.push({ uuid: 5, tabName: 'serviceFees', isExpanded: false, isCollapsible: true });

            setRegionState(regionState);
            setIsRegionStateLoaded(true);
        }
    };

    /* const handleToggleExpandAll = () => {
        setIsToggling(true);
        ToggleAllPanels(!isExpandAll);
    }; */

    useEffect(() => {
        setInitialTabState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // [inquiry.differencesComplaintsCollection]);

    const getTabRegionStateItem = (tabNameIn: string) => {
        return regionState.find(({ tabName }) => tabName === tabNameIn);
    };

    useEffect(() => {
        if (regionState && regionState.length > 0 && isToggling === true) {
            if (isExpandAll === true) {
                getTabRegionStateItem('reviewPersonalDetails')!.isExpanded = true;
                getTabRegionStateItem('reviewComplaintsMechanisms')!.isExpanded = true;
                // getTabRegionStateItem('openingHours')!.isExpanded = true;
                // getTabRegionStateItem('serviceCoverage')!.isExpanded = true;
                // getTabRegionStateItem('serviceFees')!.isExpanded = true;
            } else {
                getTabRegionStateItem('reviewPersonalDetails')!.isExpanded = false;
                getTabRegionStateItem('reviewComplaintsMechanisms')!.isExpanded = false;
                // getTabRegionStateItem('openingHours')!.isExpanded = false;
                // getTabRegionStateItem('serviceCoverage')!.isExpanded = false;
                // getTabRegionStateItem('serviceFees')!.isExpanded = false;
            }

            setRegionState(regionState);
            setIsToggling(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandAll]);

    const handleOnChange = (uuids: string[]) => {
        // Accordion - onChange() - Callback which is invoked when items are expanded or collapsed. Gets passed uuids of the currently expanded AccordionItems.
        // setAllExpanded(undefined);
        // Accordion - preExpanded: string[] [optional, default: []]
        // Accepts an array of strings and any AccordionItem whose uuid prop matches any one of these strings will be expanded on mount.
    };

    /* const handleHasAnyPersonalDetailsChanges = () => {
        getTabRegionStateItem('reviewPersonalDetails')!.isExpanded = true;
        setRegionState(regionState);
    }; */

    /*const handleHasAnyComplaintsMechChanges = () => {
        getTabRegionStateItem('reviewComplaintsMechanisms')!.isExpanded = true;
        setRegionState(regionState);
    }; */

    // use this technique to be able to pre-load the accordion state with expanded items by default
    if (isRegionStateLoaded === false) {
        // when not complete return a placeholder
        return <Loader isLoading={true} loaderText='Loading your information...' />;
    }

    const displayNoUpdatesFoundMessage = () => {
        return (
            <>
                <EmptyRow />
                <Row>
                    <Col>NO UPDATES FOUND.</Col>
                </Row>
                <EmptyRow />
            </>
        );
    };

    const displayFilesUploadedMessage = () => {
        return (
            <>
                <EmptyRow />
                <Row>
                    <Col>Files have been uploaded, please submit for changes to take effect.</Col>
                </Row>
                <EmptyRow />
            </>
        );
    };

    // could be undefined objects
    const userChangesDoNotExist = (IsObjectEmpty(inquiry.differencesUser) || IsObjectEmpty(inquiry.differencesUser?.differences)) ?? true;
    const cmChangesExist =
        (addedCMcollection !== null && addedCMcollection.length > 0) ||
        (removedCMcollection !== null && removedCMcollection.length > 0) ||
        (updatedCMcollection !== null && updatedCMcollection.length > 0);

    return (
        <>
            <Accordion
                key={accordionKey}
                data-testid='editdetailsaccordion'
                allowMultipleExpanded={true}
                allowZeroExpanded={true}
                onChange={handleOnChange}
                preExpanded={regionState.map((item, _index) => (item.isExpanded === true ? item.uuid : ''))}
            >
                <AccordionItem
                    key='reviewPersonalDetails'
                    data-testid='reviewPersonalDetailsaccordion'
                    uuid={getTabRegionStateItem('reviewPersonalDetails')!.uuid}
                >
                    <AccordionItemHeading>
                        <AccordionItemButton>Personal details</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {userChangesDoNotExist === true && userAttachmentsReworkUploaded === false && displayNoUpdatesFoundMessage()}
                        {userChangesDoNotExist === true && userAttachmentsReworkUploaded === true && displayFilesUploadedMessage()}
                        {userChangesDoNotExist === false && <DiffPersonalDetailsPage inquiry={inquiry} />}
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem
                    key='reviewComplaintsMechanisms'
                    data-testid='reviewComplaintsMechanismsaccordion'
                    uuid={getTabRegionStateItem('reviewComplaintsMechanisms')!.uuid}
                >
                    <AccordionItemHeading>
                        <AccordionItemButton>Complaints mechanism(s) and insurance</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {cmChangesExist === false && displayNoUpdatesFoundMessage()}
                        {cmChangesExist === true && (
                            <DiffComplaintsMechanismDisplay
                                authService={authService}
                                dispatch={dispatch}
                                inquiry={inquiry}
                                refreshReviewAndSubmit={refreshReviewAndSubmit}
                                includesAnInvalidComplaintMech={includesAnInvalidComplaintMech}
                                addedCMcollection={addedCMcollection}
                                removedCMcollection={removedCMcollection}
                                updatedCMcollection={updatedCMcollection}
                            />
                        )}
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </>
    );
};

export default ManageDetailsRegions;

/*
            <Row>
                <Col md={10} />
                <Col md={1}>
                    <button
                        type='button'
                        data-testid='expandAllEditDetailsButton'
                        className='btn btn-light rounded-pill w-33 text-left font-weight-bold py-1'
                        style={{ fontVariant: 'normal' }}
                    >
                        {isExpandAll === true ? angleUpIcon() : angleDownIcon()}
                        &nbsp;&nbsp;
                        <NavLink
                            hrefLink='#'
                            displayText={isExpandAll === true ? 'Collapse all' : 'Expand all'}
                            className=''
                            handleOnClick={handleToggleExpandAll}
                        />
                    </button>
                </Col>
            </Row>
            <EmptyRow />

*/
