import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DisplayTextWithContactPauEmailLink } from '../utils/AppUtils';
import { exclamationIcon } from '../utils/Icons';
import { DISPLAY_FDDR_PAU_CONTACT_EMAIL } from '../services/Constants';
import style from './Controls.module.scss';

const { modalContainer, modalContents, modalHeader, modalBody, modalYesButtonPrimary, modalNoButton, warningSignConfirmable, modalYesButtonDestructive } =
    style;

interface ModalDialogProps {
    showMe: boolean;
    modalTitle: string;
    modalBodyText: string;
    modalBodyTextP2?: string;
    modalBodyTextP3?: string;
    handleClickYes?: any;
    handleClickNo?: any;
    showOkOnly?: boolean;
    handleClickOk?: any;
    confirmLabel?: string;
    abortLabel?: string;
    dataTestId?: string;
    isDestructive?: boolean;
}

// https://react-bootstrap.github.io/components/modal/
const ModalDialog = (props: ModalDialogProps) => {
    const {
        showMe,
        handleClickYes,
        handleClickNo,
        modalTitle,
        modalBodyText,
        modalBodyTextP2,
        modalBodyTextP3,
        showOkOnly,
        handleClickOk,
        confirmLabel,
        abortLabel,
        dataTestId,
        isDestructive,
    } = props;

    const yesNoButtons = () => {
        const yesButtonClass = isDestructive ? `btn ${modalYesButtonDestructive}` : `btn ${modalYesButtonPrimary}`;
        const yesButtonVariant = isDestructive ? 'danger' : 'primary';

        return (
            <>
                <div className='col' style={{ maxWidth: '50%' }}>
                    <Button className={`btn ${modalNoButton}`} variant='secondary' data-testid='modalNoButton' onClick={handleClickNo}>
                        {abortLabel ? abortLabel : 'No'}
                    </Button>
                </div>
                <div className='col' style={{ maxWidth: '50%' }}>
                    <Button className={yesButtonClass} variant={yesButtonVariant} data-testid='modalYesButton' onClick={handleClickYes}>
                        {confirmLabel ? confirmLabel : 'Yes'}
                    </Button>
                </div>
            </>
        );
    };

    const okOnlyButton = () => {
        return (
            <>
                <div className='col' style={{ maxWidth: '100%' }}>
                    <Button className={`btn ${modalYesButtonPrimary}`} variant='primary' data-testid='modalOkButton' onClick={handleClickOk}>
                        {confirmLabel ? confirmLabel : 'OK'}
                    </Button>
                </div>
            </>
        );
    };

    const showOkButton = showOkOnly && showOkOnly === true;
    const textContainsPauEmail = modalBodyTextP2 && modalBodyTextP2.includes(`${DISPLAY_FDDR_PAU_CONTACT_EMAIL}`);

    return (
        <>
            <Modal animation={false} show={showMe} backdrop='static' keyboard={false} className={modalContainer} data-testid={dataTestId}>
                <div className={`m-0 p-4 text-center ${modalContents}`}>
                    <Modal.Header closeButton={false} className={modalHeader}>
                        <Modal.Title>
                            {isDestructive && <div className={`${warningSignConfirmable} alert-danger rounded-circle`}>{exclamationIcon()}</div>}
                            {modalTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={modalBody}>
                        <p>{modalBodyText}</p>
                        {modalBodyTextP2 &&
                            (textContainsPauEmail === true ? <p>{DisplayTextWithContactPauEmailLink(modalBodyTextP2, false)}</p> : <p>{modalBodyTextP2}</p>)}
                        {modalBodyTextP3 && <p>{modalBodyTextP3}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='container'>
                            <div className='row'>{showOkButton && showOkButton === true ? okOnlyButton() : yesNoButtons()}</div>
                        </div>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
};

export default ModalDialog;
