import { isValid as isValidDate } from 'date-fns';
import { AccreditationStatusEnum, AttachmentDocumentType, ComplaintsMechanismType, ComplaintsProcessType, RelationshipToEmployerType } from '../services/Enums';
import AttachmentActions from '../actions/attachmentsActions';
import { IAddress, IApplication, IAttachmentMetadata, IComplaintMechanism, IDifferences, Inquiry, IOpeningHours } from '../api/interfacesApi';
import { GetNewComplaintMechanismObject } from '../pages/Registration/RegistrationApplicationForm';
import {
    AppendAttachmentsToCollection,
    GetComplaintsAllActiveAttachments,
    IsValidGuidIdentifier,
    RemoveAttachmentInCollection,
    RemoveAttachmentsFromAzureIfExistForThisComplaint,
    RemoveAttachmentsFromCollectionIfExistForThisComplaint,
    ReplaceOrInjectAttachmentInCollection,
} from './AppUtils';
import { UNINITIALISED } from '../services/Constants';
import { SetCurrentComplaintsMechId } from '../components/wizard/wizardStateManager';
import { AppContextInterface } from '../stateManagement/context/AppContext';
import Guid from '../services/Guid';
import { defaultOutletPractitionerData, defaultProfessionalAssociationData } from '../api/defaultData';
import { IsObjectEmpty } from './Common';
import { formatDateForDateFns, formatDateForDisplayAddExpiredIfInPast } from './Dates';

// initialise 10 objects now (could use shorthand, linter probs, https://tobiasljungstrom.net/blog/2019-07-26-array-of-sequential-numbers-in-js/)
// const cmArrayIndexes = [...Array(9).keys()];
export const ComplaintsArrayIndexes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export const AddNewComplaintMechanismObject = (app: IApplication) => {
    app.complaintsMechanism.push(GetNewComplaintMechanismObject());
};

// create empty array to host complaints objects
export const InitialiseComplaintsArray = (app: IApplication) => {
    app.complaintsMechanism = [];
    ComplaintsArrayIndexes.forEach(_index => {
        AddNewComplaintMechanismObject(app);
    });
};

export const FindIndexOfComplaint = (complaintsMechanismId: string | undefined, app: IApplication) => {
    const indexFound = app.complaintsMechanism.findIndex(cm => cm.complaintsMechanismId === complaintsMechanismId);
    return indexFound;
};

export const CopyFromComplaintToActive = (app: IApplication, complaintMech: IComplaintMechanism) => {
    // store this into application obj
    app.activeComplaintsMechanismObj = complaintMech;

    // MUSt populate outlet and profAssoc with empty Ids if null for validation
    if (complaintMech.professionalAssociation === undefined || complaintMech.professionalAssociation === null) {
        app.activeComplaintsMechanismObj.professionalAssociation = defaultProfessionalAssociationData;
    }
    if (complaintMech.outlet === undefined || complaintMech.outlet === null) {
        app.activeComplaintsMechanismObj.outlet = { ...defaultOutletPractitionerData };
    }

    // MUST set the active id fields used by validation - just set to the first in the collection
    if (complaintMech.evidence?.length > 0) {
        app.activeComplaintsMechanismObj.attachmentExists = complaintMech.evidence[0].attachmentId;
        app.activeComplaintsMechanismObj.activeAttachmentId = complaintMech.evidence[0].attachmentId;
    }

    // // finally find the index and update our active index for CM record
    // const indexOfCMid = findIndexOfComplaint(app.activeComplaintsMechanismObj?.complaintsMechanismId, app);
    // app.activeComplaintsMechanismIndex = indexOfCMid;
};

export const CopyFromComplaintCollectionToActive = (app: IApplication, id: string) => {
    const indexOfCMid = FindIndexOfComplaint(id, app);

    if (app.complaintsMechanism && app.complaintsMechanism.length >= indexOfCMid && app.complaintsMechanism[indexOfCMid] !== null) {
        CopyFromComplaintToActive(app, app.complaintsMechanism[indexOfCMid]);
    } else {
        throw new Error('CopyFromComplaintCollectionToActive: Could not copy from cm for sourceIndex=' + indexOfCMid);
    }
};

export const CopyFromActiveToComplaintCollection = (app: IApplication) => {
    if (app.complaintsMechanism) {
        // if new CM return null
        if (app.complaintsMechanism.length === 0) {
            return null;
        }

        // locate the CM id in the collection and overwrite that with the activeCM object - DEV NOTE: as this is ONLY called from an Update() then it all already exists is safe assupmtion
        const indexOfCMid = FindIndexOfComplaint(app.activeComplaintsMechanismObj?.complaintsMechanismId, app);
        if (indexOfCMid !== -1 && app.activeComplaintsMechanismObj) {
            app.complaintsMechanism[indexOfCMid] = app.activeComplaintsMechanismObj;
        }
    } else {
        throw new Error('CopyFromActiveToComplaintCollection: Could not copy active cm for id=' + app.activeComplaintsMechanismObj?.complaintsMechanismId);
    }
};

export const GetComplaintsMechanismName = (data: IComplaintMechanism) => {
    if (data && data !== null && data.outlet !== null && data.outlet?.name !== '') {
        return data.outlet.name;
    }
    return data?.professionalAssociation?.name;
};

export const IsValidComplaintsMech = (data: IComplaintMechanism) => {
    return data && data.complaintsMechanismId !== null && data.complaintsMechanismId !== '' && data.complaintsMechanismId !== UNINITIALISED;
};

export const CountOfValidComplaintsMech = (list: IComplaintMechanism[]) => {
    let counter = 0;
    if (list && list.length > 0) {
        list.forEach((listItem: IComplaintMechanism) => {
            if (IsValidComplaintsMech(listItem)) {
                counter = counter + 1;
            }
        });
    }
    return counter;
};

export const IsAtLeastOneComplaintsMechanismSlotEmpty = (app: IApplication) => {
    let retValue = false;

    if (app.complaintsMechanism && app.complaintsMechanism.length >= 0) {
        app.complaintsMechanism.forEach((complaint: IComplaintMechanism) => {
            if (complaint.complaintsMechanismId === UNINITIALISED) {
                retValue = true;
            }
        });
    }

    return retValue;
};
/*
export const GetNextEmptyComplaintMechanismFromCollection = (app: IApplication) => {
    let retValue: IComplaintMechanism;
    retValue = { ...defaultComplaintsMechanismData };

    if (app.complaintsMechanism && app.complaintsMechanism.length >= 0) {
        app.complaintsMechanism.forEach((complaint: IComplaintMechanism) => {
            if (complaint.complaintsMechanismId === UNINITIALISED && retValue.complaintsMechanismId === UNINITIALISED) {
                retValue = complaint;
            }
        });
    }

    return retValue;
}

export const ActivateEmptyComplaintsMechanism = (appContext: AppContextInterface, app: IApplication) => {
    // mark the first available empty slot of complaints as active and update the id to a new (temp) GUID driven by front-end until we create a back-end record at which point it gets replaced
    let found = false;

    if (app.complaintsMechanism && app.complaintsMechanism.length >= 0) {
        app.complaintsMechanism.forEach((complaint: IComplaintMechanism, index: number) => {
            if (found === false && complaint.complaintsMechanismId === UNINITIALISED) {
                // SetCurrentComplaintsMechId(appContext, index);
                const newGuid = Guid.newGuid();
                appContext.setCurrentComplaintsMechId(newGuid);

                const emptyComplaintMech = GetNextEmptyComplaintMechanismFromCollection(app);
                emptyComplaintMech.complaintsmechanismId = newGuid;
                CopyFromComplaintToActive(app, emptyComplaintMech);
                found = true;
            }
        });
    }

    if (found === false) {
        throw new Error('ActivateEmptyComplaintsMechanism: Could not activate empty CM for CMs=' + app.complaintsMechanism);
    }
}; */

export const ActivateChosenComplaintsMechanism = (appContext: AppContextInterface, app: IApplication, id: string) => {
    // set activeComplaintsMechanismObj
    if (appContext.currentComplaintsMechId !== id) {
        // store currIndex of CM to edit
        SetCurrentComplaintsMechId(appContext, id);
    }

    CopyFromComplaintCollectionToActive(app, id);
};

export const IsOrganisationDisplay = (complaintsData: IComplaintMechanism) => {
    const profAssocExists: boolean =
        complaintsData.professionalAssociation !== null && complaintsData.professionalAssociation?.professionalAssociationId !== '';
    return profAssocExists === false;
};

export const IsRelationshipToEmployerExists = (complaintsData: IComplaintMechanism) => {
    const retValue: boolean = complaintsData.relationshipToOrganisation !== undefined && complaintsData.relationshipToOrganisation !== null;
    return retValue;
};

export const IsInsuranceMissingButRequired = (complaintsData: IComplaintMechanism) => {
    const insuranceExists: boolean =
        complaintsData.hasProfessionalIndemnityInsuranceViaMembership !== null &&
        (complaintsData.professionalIndemnityInsuranceFromDate !== undefined || complaintsData.professionalIndemnityInsuranceFromDate !== null);
    return (
        insuranceExists === false &&
        ((complaintsData.complaintsMechanismType !== ComplaintsMechanismType.GovernmentFundedFDRService &&
            complaintsData.relationshipToOrganisation !== RelationshipToEmployerType.Employee) ||
            (complaintsData.complaintsMechanismType === ComplaintsMechanismType.GovernmentFundedFDRService &&
                complaintsData.relationshipToOrganisation === RelationshipToEmployerType.OnPanel))
    );
};

// use this on display UI when we show text such as 'no insurance ...' explicitly
export const ShowInsuranceExpiryIncludingEmployee = (complaintsData: IComplaintMechanism) => {
    const isOrgDisplay = IsOrganisationDisplay(complaintsData);

    return (
        !isOrgDisplay ||
        (isOrgDisplay === true && complaintsData.relationshipToOrganisation === RelationshipToEmployerType.OnPanel) ||
        (isOrgDisplay === true && complaintsData.relationshipToOrganisation === RelationshipToEmployerType.Employee)
    );
};

// use this on display UI to hide when not employee:
//  - when a Prof Assoc
//  - when not a Prof Assoc, and Legal Aid/Other, and On Panel
//  - when not a Prof Assoc, and Govt Funded, and On Panel
export const ShowInsuranceExpiryDetails = (complaintsData: IComplaintMechanism) => {
    const isOrgDisplay = IsOrganisationDisplay(complaintsData);

    return (
        !isOrgDisplay ||
        (isOrgDisplay === true &&
            (complaintsData.complaintsProcessType === ComplaintsProcessType.LegalAidStatutoryBody ||
                complaintsData.complaintsProcessType === ComplaintsProcessType.OtherStatutoryBody) &&
            complaintsData.relationshipToOrganisation === RelationshipToEmployerType.OnPanel) ||
        (isOrgDisplay === true &&
            complaintsData.complaintsProcessType === ComplaintsProcessType.GovernmentFundedFDRService &&
            complaintsData.relationshipToOrganisation === RelationshipToEmployerType.OnPanel)
    );
};

export const InsuranceExpiryOutput = (isConditionalAccreditation: boolean, complaintsData: IComplaintMechanism) => {
    const complaintFullMembership = complaintsData.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc;
    const isOrgDisplay = IsOrganisationDisplay(complaintsData);

    return (complaintFullMembership === true && isConditionalAccreditation === true) ||
        (complaintFullMembership === true &&
            isConditionalAccreditation === false &&
            (complaintsData.professionalIndemnityInsuranceToDate === undefined || complaintsData.professionalIndemnityInsuranceToDate === null))
        ? 'No insurance details provided'
        : isOrgDisplay === true && complaintsData.relationshipToOrganisation === RelationshipToEmployerType.Employee
        ? 'Insurance by employment'
        : formatDateForDisplayAddExpiredIfInPast(complaintsData.professionalIndemnityInsuranceToDate);
};

export const OutletTypeOutput = (formatForPDF: boolean, complaintsData: IComplaintMechanism) => {
    const complaintFullMembership = complaintsData.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc;
    // const relationshipIsPanelMember = complaintsData.relationshipToOrganisation === RelationshipToEmployerType.OnPanel;
    if (formatForPDF === true) {
        return complaintFullMembership === true
            ? 'As a private practitioner with my own FDR outlet'
            : 'As an employee or external panel member of an organisation funded by the Government to provide FDR services.';
    }
    return complaintFullMembership === true ? 'Private practitioner in private practice' : 'Employee/External panel member of an organisation';
    // : relationshipIsPanelMember
    // ? 'Panel member of an organisation'
    // : 'Employee of an organisation';
};

export const OutletExistsAndIsValid = (complaintsData: IComplaintMechanism) => {
    const retValue =
        complaintsData &&
        complaintsData.outlet !== null &&
        complaintsData.outlet.outletId !== null &&
        complaintsData.outlet.outletId !== UNINITIALISED &&
        complaintsData.outlet.outletId !== '';

    return retValue;
};

export const IsValidBusinessAddress = (address: IAddress | null | undefined) => {
    const retValue =
        (address !== undefined &&
            address !== null &&
            address.addressId !== UNINITIALISED &&
            address.streetAddress !== '' &&
            address.postcode !== '' &&
            address.state !== '') ||
        (address !== undefined && address !== null && address.addressId !== UNINITIALISED && address.longitude !== -1 && address.latitude !== -1);
    return retValue;
};

export const IsValidOpeningHours = (openingHours: IOpeningHours | null) => {
    const retValue =
        openingHours !== null && openingHours.openingHoursId !== UNINITIALISED && openingHours.openTime !== null && openingHours.closeTime !== null;
    return retValue;
};

export function GetIndexOfComplaintsMechInCollection(complaintechanisms: IComplaintMechanism[], complaintMechanismId: string | undefined) {
    for (let idx = 0; idx < complaintechanisms.length; idx++) {
        if (complaintechanisms[idx].complaintsMechanismId === complaintMechanismId) {
            return idx;
        }
    }
    return -1; // not found
}

const ReplaceEmptyGuidWithComplaintsId = (newAttachment: IAttachmentMetadata, inquiry: Inquiry) => {
    if (newAttachment.complaintsProcessId === Guid.empty && IsValidGuidIdentifier(inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId)) {
        newAttachment.complaintsProcessId = inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId;
    }
};

export const IsComplaintsAttachmentType = (newAttachment: IAttachmentMetadata) => {
    return (
        newAttachment.documentType === AttachmentDocumentType.OrgEmployment ||
        newAttachment.documentType === AttachmentDocumentType.OrgPanel ||
        newAttachment.documentType === AttachmentDocumentType.AssociationMembership ||
        newAttachment.documentType === AttachmentDocumentType.Insurance
    );
};

export const InsertAttachmentToLocalInquiryObject = (newAttachment: IAttachmentMetadata, setValue: any, inquiry: Inquiry) => {
    // Added new file, so before user has clicked SAVE we need to update relevant object model entries:
    //  - inquiry.application.attachments
    //  - inquiry.application.activeComplaintsMechanismObj.evidence
    //  - inquiry.application.complaintsMechanism[index].evidence  (where index is this CM in collection)
    //  - inquiry.application.activeComplaintsMechanismObj.attachmentExists
    //  - inquiry.application.activeComplaintsMechanismObj.activeAttachmentId
    //
    const isComplaintAttachment = IsComplaintsAttachmentType(newAttachment);

    if (isComplaintAttachment === true) {
        // if we don't do this, the UpdateComplaints() call fails later
        ReplaceEmptyGuidWithComplaintsId(newAttachment, inquiry);
    }

    // (this is due to not being able to refresh whole collection due to registration process and if user in middle of form, uploading/removing, without saving whole form)
    if (inquiry) {
        // tslint:disable-next-line: prefer-const
        let currItems: IAttachmentMetadata[] = inquiry.application.attachments;

        if (currItems === undefined || currItems === null) {
            currItems = [];
        }

        // check if not already added
        if (currItems.findIndex(item => item.attachmentId === newAttachment.attachmentId) === -1) {
            // assign item to current collection
            currItems.push(newAttachment);
            setValue('inquiry.application.attachments', currItems, { shouldValidate: false });
            inquiry.application.attachments = currItems;
        }
    }

    if (inquiry.application.activeComplaintsMechanismObj && isComplaintAttachment === true) {
        const complaintsMechanismId = inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId;
        const indexOfCM = GetIndexOfComplaintsMechInCollection(inquiry.application.complaintsMechanism, complaintsMechanismId);
        if (indexOfCM !== -1) {
            ReplaceOrInjectAttachmentInCollection(inquiry.application.complaintsMechanism[indexOfCM]?.evidence, newAttachment);
            setValue('inquiry.application.complaintsMechanism', inquiry.application.complaintsMechanism);
        }

        ReplaceOrInjectAttachmentInCollection(inquiry.application.activeComplaintsMechanismObj?.evidence, newAttachment);
        setValue('inquiry.application.activeComplaintsMechanismObj.evidence', inquiry.application.activeComplaintsMechanismObj?.evidence);

        inquiry.application.activeComplaintsMechanismObj.attachmentExists = newAttachment.attachmentId;
        setValue(`inquiry.application.activeComplaintsMechanismObj.attachmentExists`, inquiry.application.activeComplaintsMechanismObj.attachmentExists);

        inquiry.application.activeComplaintsMechanismObj.activeAttachmentId = newAttachment.attachmentId;
        setValue(`inquiry.application.activeComplaintsMechanismObj.activeAttachmentId`, inquiry.application.activeComplaintsMechanismObj.activeAttachmentId);
    }
};

export const RemoveAttachmentFromLocalInquiryObject = (attachmentId: string, setValue: any, inquiry: Inquiry) => {
    // Removed a file, so before user has clicked SAVE we need to update relevant object model entries:
    //  - inquiry.application.attachments
    //  - inquiry.application.activeComplaintsMechanismObj.evidence
    //  - inquiry.application.complaintsMechanism[index].evidence  (where index is this CM in collection)
    //  - inquiry.application.activeComplaintsMechanismObj.attachmentExists
    //  - inquiry.application.activeComplaintsMechanismObj.activeAttachmentId
    //

    // (this is due to not being able to refresh whole collection due to registration process and if user in middle of form, uploading/removing, without saving whole form)
    if (inquiry) {
        RemoveAttachmentInCollection(inquiry.application.attachments, attachmentId);
        setValue('inquiry.application.attachments', inquiry.application.attachments, { shouldValidate: false });
    }

    // (this is due to not being able to refresh whole collection due to registration process and if user in middle of form, uploading/removing, without saving whole form)
    const complaintsMechanismId = inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId;
    const indexOfCM = GetIndexOfComplaintsMechInCollection(inquiry.application.complaintsMechanism, complaintsMechanismId);
    if (indexOfCM !== -1) {
        RemoveAttachmentInCollection(inquiry.application.complaintsMechanism[indexOfCM]?.evidence, attachmentId);
        setValue('inquiry.application.complaintsMechanism', inquiry.application.complaintsMechanism);
    }
    RemoveAttachmentInCollection(inquiry.application.activeComplaintsMechanismObj?.evidence, attachmentId);
    setValue('inquiry.application.activeComplaintsMechanismObj.evidence', inquiry.application.activeComplaintsMechanismObj?.evidence);

    if (inquiry.application.activeComplaintsMechanismObj) {
        inquiry.application.activeComplaintsMechanismObj.attachmentExists = '';
        setValue(`inquiry.application.activeComplaintsMechanismObj.attachmentExists`, '');

        inquiry.application.activeComplaintsMechanismObj.activeAttachmentId = '';
        setValue(`inquiry.application.activeComplaintsMechanismObj.activeAttachmentId`, '');
    }
};

export const KeepOriginalCMsAndNewCMsOnly = (complaintMechanisms: IComplaintMechanism[]) => {
    // Find all EditCM items in the collection, then remove it from collection, and leave only those originals OR those that have an active EditCopy (but NOT the EditCopy itself)

    // tslint:disable-next-line: prefer-const
    let returnCMs: IComplaintMechanism[] = [];

    // get the ones superceded only by an editcopy, for the review and submit page, so then we can get its changes using /allchanges
    const allSupercededNotEditCopyCMs = complaintMechanisms.filter(item => item.isEditCopy === false && IsValidGuidIdentifier(item.supercedesId) === true);
    allSupercededNotEditCopyCMs.forEach(supercededNotEditCopyCM => {
        const supercededByEditCopyCMs = complaintMechanisms.filter(
            item => item.complaintsMechanismId === supercededNotEditCopyCM.supercededById && item.isEditCopy === true,
        );
        supercededByEditCopyCMs.forEach(supercededByEditCopyCM => {
            returnCMs.push(supercededByEditCopyCM);
        });
    });

    // also map those items without a supercedesId or supercededById (i.e. the non-editcopy items as well as originals without any superceded)
    const nonSupercededAndNonEditCMs = complaintMechanisms.filter(item => IsValidGuidIdentifier(item.supercedesId) === false);
    nonSupercededAndNonEditCMs.forEach(nonSupercededAndNonEditCM => {
        returnCMs.push(nonSupercededAndNonEditCM);
    });

    return returnCMs;
};

export const MergeOriginalsIntoEditCopyCM = (complaintMechanisms: IComplaintMechanism[]) => {
    // Find all EditCopy items in the collection, then for each one find its original
    //  using SupercedesId of the EditCopy matching to the id of the original
    // tslint:disable-next-line: prefer-const
    let returnCMs: IComplaintMechanism[] = [];

    const editCopyCMs = complaintMechanisms.filter(item => item.isEditCopy === true);

    editCopyCMs.forEach(editCopyCM => {
        const origCM = complaintMechanisms.filter(item => item.complaintsMechanismId === editCopyCM.supercedesId);
        if (origCM.length === 1) {
            // merging data is REDUNDANT NOW as the EditCopy clone has already happened
            // const newCM = MapOrigCMintoEditCopyCM(origCM[0], editCopyCM);
            // returnCMs.push(newCM);
            returnCMs.push(editCopyCM);
        }
    });

    // also map those items without a supercedesId or supercededById (i.e. the non-editcopy items)
    const nonEditCopyCMs = complaintMechanisms.filter(
        item => (item.supercededById === null && item.supercedesId === null) || (item.supercededById === undefined && item.supercedesId === undefined),
    );
    nonEditCopyCMs.forEach(nonEditCopyCM => {
        returnCMs.push(nonEditCopyCM);
    });

    // TODO: Tidy up this and GetLatestComplaintsMechanismCollection having to work together for the complete set
    // we need to keep those items where the supercedesId is not null, the isEditCopy=0, and the supercededById no longer exists in this collection (as others have already been stripped out by GetLatestComplaintsMechanismCollection)
    const supercededNotEditCopyCMs = complaintMechanisms.filter(item => item.isEditCopy === false && IsValidGuidIdentifier(item.supercedesId) === true);
    supercededNotEditCopyCMs.forEach(supercededNotEditCopyCM => {
        const supercededCM = complaintMechanisms.filter(item => item.complaintsMechanismId === supercededNotEditCopyCM.supercededById);
        if (supercededCM.length === 0) {
            returnCMs.push(supercededNotEditCopyCM);
        }
    });

    return returnCMs;
};

/*
// when initial application is about to be submitted, there is only ever 1 complaint mechanism
export const GetFirstComplaint = (inquiryOrApplicationObj: Inquiry | IApplication) => {
    if (!inquiryOrApplicationObj) {
        return null;
    }

    // overloaded
    if (inquiryOrApplicationObj as Inquiry) {
        const app = (inquiryOrApplicationObj as Inquiry)?.application;
        if (!app || !app.complaintsMechanism || app.complaintsMechanism.length > 0) {
            return null;
        }

        return app.activeComplaintsMechanismObj;
    }

    if (inquiryOrApplicationObj as IApplication) {
        const app = inquiryOrApplicationObj as IApplication;
        if (!app || !app.complaintsMechanism || app.complaintsMechanism.length > 0) {
            return null;
        }

        return app.activeComplaintsMechanismObj;
    }
}; */
// tslint:disable-next-line: variable-name
const IsComplaintLegalAidOther_OnPanel = (complaintsMechanismIn: IComplaintMechanism) => {
    return (
        (complaintsMechanismIn.complaintsProcessType === ComplaintsProcessType.LegalAidStatutoryBody ||
            complaintsMechanismIn.complaintsProcessType === ComplaintsProcessType.OtherStatutoryBody) &&
        complaintsMechanismIn.relationshipToOrganisation === RelationshipToEmployerType.OnPanel
    );
};

export function IsActiveComplaintLegalAidOtherOnPanel(app: IApplication): boolean {
    if (!app.activeComplaintsMechanismObj || app.activeComplaintsMechanismObj === null) {
        return false;
    }

    return IsComplaintLegalAidOther_OnPanel(app.activeComplaintsMechanismObj);
}

export function IsAnyComplaintLegalAidOtherOnPanel(complaintsMechanisms: IComplaintMechanism[]): boolean {
    let retValue: boolean = false;
    if (!complaintsMechanisms || complaintsMechanisms.length <= 0) {
        return retValue;
    }

    complaintsMechanisms.forEach((complaint: IComplaintMechanism) => {
        // there can be only one
        if (IsComplaintLegalAidOther_OnPanel(complaint)) {
            retValue = true;
        }
    });
    return retValue;
}

// tslint:disable-next-line: variable-name
const IsComplaintGovtFunded_OnPanel = (complaintsMechanismIn: IComplaintMechanism) => {
    return (
        complaintsMechanismIn.complaintsProcessType === ComplaintsProcessType.GovernmentFundedFDRService &&
        complaintsMechanismIn.relationshipToOrganisation === RelationshipToEmployerType.OnPanel
    );
};

export function IsAnyComplaintGovtFundedOnPanel(complaintsMechanisms: IComplaintMechanism[]): boolean {
    let retValue: boolean = false;
    if (!complaintsMechanisms || complaintsMechanisms.length <= 0) {
        return retValue;
    }

    complaintsMechanisms.forEach((complaint: IComplaintMechanism) => {
        // there can be only one
        if (IsComplaintGovtFunded_OnPanel(complaint)) {
            retValue = true;
        }
    });
    return retValue;
}

// tslint:disable-next-line: variable-name
const IsComplaintGovtFundedForEmployee = (complaintsMechanismIn: IComplaintMechanism) => {
    return (
        complaintsMechanismIn.complaintsProcessType === ComplaintsProcessType.GovernmentFundedFDRService &&
        complaintsMechanismIn.relationshipToOrganisation === RelationshipToEmployerType.Employee
    );
};

const IsComplaintLegalAidOrOtherForEmployee = (complaintsMechanismIn: IComplaintMechanism) => {
    return (
        (complaintsMechanismIn.complaintsProcessType === ComplaintsProcessType.LegalAidStatutoryBody ||
            complaintsMechanismIn.complaintsProcessType === ComplaintsProcessType.OtherStatutoryBody) &&
        complaintsMechanismIn.relationshipToOrganisation === RelationshipToEmployerType.Employee
    );
};

export function IsActiveComplaintOnGovtFundedEmployee(app: IApplication): boolean {
    if (!app.activeComplaintsMechanismObj || app.activeComplaintsMechanismObj === null) {
        return false;
    }

    return IsComplaintGovtFundedForEmployee(app.activeComplaintsMechanismObj);
}

export function IsAnyComplaintOnGovtFundedAsEmployee(complaintsMechanisms: IComplaintMechanism[]): boolean {
    let retValue: boolean = false;
    if (!complaintsMechanisms || complaintsMechanisms.length <= 0) {
        return retValue;
    }

    complaintsMechanisms.forEach((complaint: IComplaintMechanism) => {
        // there can be only one
        if (IsComplaintGovtFundedForEmployee(complaint)) {
            retValue = true;
        }
    });
    return retValue;
}

export function IsAnyComplaintOnLegalAidOrOtherAsEmployee(complaintsMechanisms: IComplaintMechanism[]): boolean {
    let retValue: boolean = false;
    if (!complaintsMechanisms || complaintsMechanisms.length <= 0) {
        return retValue;
    }

    complaintsMechanisms.forEach((complaint: IComplaintMechanism) => {
        // there can be only one
        if (IsComplaintLegalAidOrOtherForEmployee(complaint)) {
            retValue = true;
        }
    });
    return retValue;
}

// tslint:disable-next-line: variable-name
const IsComplaintProfAssocMember = (complaintsMechanismIn: IComplaintMechanism) => {
    return complaintsMechanismIn.complaintsProcessType === ComplaintsProcessType.ProfessionalAssociationMembership;
};

export function IsActiveComplaintProfAssocMember(app: IApplication): boolean {
    if (!app.activeComplaintsMechanismObj || app.activeComplaintsMechanismObj === null) {
        return false;
    }

    return IsComplaintProfAssocMember(app.activeComplaintsMechanismObj);
}

export function IsAnyComplaintProfAssocMember(complaintsMechanisms: IComplaintMechanism[]): boolean {
    let retValue: boolean = false;
    if (!complaintsMechanisms || complaintsMechanisms.length <= 0) {
        return retValue;
    }

    complaintsMechanisms.forEach((complaint: IComplaintMechanism) => {
        // there can be only one
        if (IsComplaintProfAssocMember(complaint)) {
            retValue = true;
        }
    });
    return retValue;
}

// tslint:disable-next-line: variable-name
const IsComplaintProfAssocMember_FullMember_WithInsurance = (complaintsMechanismIn: IComplaintMechanism) => {
    return (
        complaintsMechanismIn.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc &&
        complaintsMechanismIn.hasProfessionalIndemnityInsuranceViaMembership === true
    );
};

export function IsAnyComplaintProfAssoc_FullMembership_WithInsurance(complaintsMechanisms: IComplaintMechanism[]): boolean {
    let retValue: boolean = false;
    if (!complaintsMechanisms || complaintsMechanisms.length <= 0) {
        return retValue;
    }

    complaintsMechanisms.forEach((complaint: IComplaintMechanism) => {
        // there can be only one
        if (IsComplaintProfAssocMember_FullMember_WithInsurance(complaint)) {
            retValue = true;
        }
    });
    return retValue;
}

// tslint:disable-next-line: variable-name
const IsComplaintGovtFunded = (complaintsMechanismIn: IComplaintMechanism) => {
    return complaintsMechanismIn.complaintsProcessType === ComplaintsProcessType.GovernmentFundedFDRService;
};

export function IsAnyComplaintGovtFunded(complaintsMechanisms: IComplaintMechanism[]): boolean {
    let retValue: boolean = false;
    if (!complaintsMechanisms || complaintsMechanisms.length <= 0) {
        return retValue;
    }

    complaintsMechanisms.forEach((complaint: IComplaintMechanism) => {
        // there can be many
        if (IsComplaintGovtFunded(complaint)) {
            retValue = true;
        }
    });
    return retValue;
}

export function IsThisComplaintMarkedForCancellation(complaintsMechanism: IComplaintMechanism): boolean {
    const today = new Date().setHours(0, 0, 0, 0);
    let retValue: boolean = false;
    if (!complaintsMechanism || complaintsMechanism === null) {
        return retValue;
    }

    if (
        complaintsMechanism.recordActiveToDate !== undefined &&
        complaintsMechanism.recordActiveToDate !== null &&
        complaintsMechanism.recordActiveToDate!.setHours(0, 0, 0, 0) <= today
    ) {
        retValue = true;
    }

    return retValue;
}

export function IsAtLeastOneComplaintActive(complaintsMechanisms: IComplaintMechanism[]): boolean {
    const today = new Date().setHours(0, 0, 0, 0);
    let retValue: boolean = false;
    if (!complaintsMechanisms || complaintsMechanisms.length <= 0) {
        return retValue;
    }
    complaintsMechanisms.forEach((complaint: IComplaintMechanism) => {
        // there can be 0..many
        if (
            (complaint.recordActiveToDate === undefined ||
                complaint.recordActiveToDate === null ||
                complaint.recordActiveToDate.setHours(0, 0, 0, 0) > today) &&
            complaint.complaintsMechanismId !== UNINITIALISED
        ) {
            retValue = true;
        }
    });
    return retValue;
}

export function IsAnyComplaintMarkedForEditCopy(complaintsMechanisms: IComplaintMechanism[]): boolean {
    let retValue: boolean = false;
    if (!complaintsMechanisms || complaintsMechanisms.length <= 0) {
        return retValue;
    }

    complaintsMechanisms.forEach((complaint: IComplaintMechanism) => {
        // there can be 0..many
        if (complaint.isEditCopy === true) {
            retValue = true;
        }
    });
    return retValue;
}

export function IsAnyComplaintMarkedForCancellation(complaintsMechanisms: IComplaintMechanism[]): boolean {
    let retValue: boolean = false;
    if (!complaintsMechanisms || complaintsMechanisms.length <= 0) {
        return retValue;
    }

    complaintsMechanisms.forEach((complaint: IComplaintMechanism) => {
        // there can be 0..many
        if (IsThisComplaintMarkedForCancellation(complaint)) {
            retValue = true;
        }
    });
    return retValue;
}

export function IsAnyComplaintsInsuranceViaMembershipFalse(complaintsMechanisms: IComplaintMechanism[]): boolean {
    let retValue: boolean = false;
    if (!complaintsMechanisms || complaintsMechanisms.length <= 0) {
        return retValue;
    }

    complaintsMechanisms.forEach((complaint: IComplaintMechanism) => {
        // there can be only one
        if (complaint.hasProfessionalIndemnityInsuranceViaMembership === false) {
            retValue = true;
        }
    });
    return retValue;
}

export function GetComplaintsMembership(complaintsMechanisms: IComplaintMechanism[]): IComplaintMechanism | null {
    let retValue = null;

    if (!complaintsMechanisms || complaintsMechanisms.length <= 0) {
        return retValue;
    }

    complaintsMechanisms.forEach((complaint: IComplaintMechanism) => {
        // there can be only one
        if (complaint.complaintsProcessType === ComplaintsProcessType.ProfessionalAssociationMembership) {
            retValue = complaint;
        }
    });

    return retValue;
}

export function GetComplaintsEmployeeCollection(complaintsMechanisms: IComplaintMechanism[]): IComplaintMechanism[] | [] {
    // tslint:disable-next-line: prefer-const
    let retValue: IComplaintMechanism[] = [];

    if (!complaintsMechanisms || complaintsMechanisms.length <= 0) {
        return retValue;
    }

    // init array
    complaintsMechanisms.forEach((complaint: IComplaintMechanism) => {
        if (
            complaint.complaintsProcessType === ComplaintsProcessType.GovernmentFundedFDRService ||
            complaint.complaintsProcessType === ComplaintsProcessType.LegalAidStatutoryBody ||
            complaint.complaintsProcessType === ComplaintsProcessType.OtherStatutoryBody
        ) {
            retValue.push(complaint);
        }
    });

    return retValue;
}

function GetActiveComplaintsEvidenceByType(application: IApplication, attachmentDocumentType: AttachmentDocumentType) {
    // check empty
    if (!application.activeComplaintsMechanismObj?.evidence || application.activeComplaintsMechanismObj?.evidence.length <= 0) {
        return [];
    }

    const allActiveEvidenceByType: IAttachmentMetadata[] = application.activeComplaintsMechanismObj?.evidence.filter((item: IAttachmentMetadata) => {
        if (item.documentType === attachmentDocumentType && item.isDeleted === false) {
            return item;
        } else {
            return null;
        }
    });

    return allActiveEvidenceByType;
}

function GetActiveComplaintAttachmentIdCollection(activeAttachments: IAttachmentMetadata[]) {
    if (!activeAttachments || activeAttachments.length <= 0) {
        return [];
    }

    const attachmentIdCollection: string[] = activeAttachments.map(item => {
        return item.attachmentId;
    });
    return attachmentIdCollection;
}

export function GetActiveInsuranceComplaintAttachmentIdCollection(application: IApplication) {
    const activeEvidenceOfThisType = GetActiveComplaintsEvidenceByType(application, AttachmentDocumentType.Insurance);
    return GetActiveComplaintAttachmentIdCollection(activeEvidenceOfThisType);

    // const activeAttachmentsOfThisType = GetComplaintsInsuranceActiveAttachments(application.attachments);
    // return GetActiveComplaintAttachmentId(application, activeAttachmentsOfThisType);
}

export function GetActiveGovtFundedComplaintAttachmentIdCollection(application: IApplication) {
    let activeEvidenceOfThisType: IAttachmentMetadata[] = [];
    activeEvidenceOfThisType = GetActiveComplaintsEvidenceByType(application, AttachmentDocumentType.OrgEmployment);
    const orgPanelAttachments = GetActiveComplaintsEvidenceByType(application, AttachmentDocumentType.OrgPanel);
    activeEvidenceOfThisType = AppendAttachmentsToCollection(orgPanelAttachments, activeEvidenceOfThisType);
    return GetActiveComplaintAttachmentIdCollection(activeEvidenceOfThisType);

    // const activeAttachmentsOfThisType = GetComplaintsGovtFundedActiveAttachments(application.attachments);
    // return GetActiveComplaintAttachmentId(application, activeAttachmentsOfThisType);
}

export function GetActiveMembershipComplaintAttachmentIdCollection(application: IApplication) {
    const activeEvidenceOfThisType = GetActiveComplaintsEvidenceByType(application, AttachmentDocumentType.AssociationMembership);
    return GetActiveComplaintAttachmentIdCollection(activeEvidenceOfThisType);

    // const activeAttachmentsOfThisType = GetComplaintsMembershipActiveAttachments(application.attachments);
    // return GetActiveComplaintAttachmentId(application, activeAttachmentsOfThisType);
}

export function GetAddedComplaintsMechanismCollection(differencesComplaintsCollection: IDifferences<IComplaintMechanism>[] | null) {
    if (differencesComplaintsCollection === null || differencesComplaintsCollection.length === 0) {
        return null;
    }

    // Added when the following is true:
    // .differences = {}
    // .originalVersion.IsDeleted=F   .isEditCopy=T  .supercededById=null  .supercedesId=NULL recordActiveToDate=NULL
    const addedComplaintsMechanismCollection: IDifferences<IComplaintMechanism>[] = [];
    differencesComplaintsCollection.forEach((diffObj: IDifferences<IComplaintMechanism>) => {
        if (
            IsObjectEmpty(diffObj.differences) === true && // TRUE
            IsObjectEmpty(diffObj.originalVersion) === false && // FALSE
            diffObj.originalVersion.isDeleted === false && // FALSE
            diffObj.originalVersion.isEditCopy === true && // TRUE
            diffObj.originalVersion.supercedesId === null &&
            diffObj.originalVersion.supercededById === null &&
            (diffObj.originalVersion.recordActiveToDate === undefined || diffObj.originalVersion.recordActiveToDate === null)
        ) {
            addedComplaintsMechanismCollection.push(diffObj);
        }
    });

    return addedComplaintsMechanismCollection;
}

const CopyFieldsFromDiffObjectToOrigObjectForDisplay = (complaintMechanisms: IComplaintMechanism[], diffObj: IDifferences<IComplaintMechanism>) => {
    const foundItem: IComplaintMechanism[] | undefined = complaintMechanisms.filter(
        (item: IComplaintMechanism) => item.complaintsMechanismId === diffObj.originalVersion.complaintsMechanismId,
    );
    if (foundItem && foundItem.length === 1) {
        if (diffObj.originalVersion.outlet === null) {
            diffObj.originalVersion.outlet = foundItem[0].outlet;
        }
        if (diffObj.originalVersion.complaintsProcessType === undefined || diffObj.originalVersion.complaintsProcessType === null) {
            diffObj.originalVersion.complaintsProcessType = foundItem[0].complaintsProcessType;
        }
        if (diffObj.originalVersion.professionalMembershipType === null) {
            diffObj.originalVersion.professionalMembershipType = foundItem[0].professionalMembershipType;
        }
        if (diffObj.originalVersion.membershipFromDate === null) {
            diffObj.originalVersion.membershipFromDate = foundItem[0].membershipFromDate;
        }
        if (diffObj.originalVersion.membershipToDate === null) {
            diffObj.originalVersion.membershipToDate = foundItem[0].membershipToDate;
        }
        if (diffObj.originalVersion.professionalAssociation === null) {
            diffObj.originalVersion.professionalAssociation = foundItem[0].professionalAssociation;
        }
        if (diffObj.originalVersion.evidence === null || diffObj.originalVersion.evidence.length === 0) {
            diffObj.originalVersion.evidence = foundItem[0].evidence;
        }
        // keep activeAttachmentId value
        // keep attachmentExists value
        if (diffObj.originalVersion.insuranceEvidenceType === null) {
            diffObj.originalVersion.insuranceEvidenceType = foundItem[0].insuranceEvidenceType;
        }
        if (diffObj.originalVersion.relationshipToOrganisation === undefined || diffObj.originalVersion.relationshipToOrganisation === null) {
            diffObj.originalVersion.relationshipToOrganisation = foundItem[0].relationshipToOrganisation;
        }
        if (diffObj.originalVersion.hasProfessionalIndemnityInsuranceViaMembership === null) {
            diffObj.originalVersion.hasProfessionalIndemnityInsuranceViaMembership = foundItem[0].hasProfessionalIndemnityInsuranceViaMembership;
        }
        if (diffObj.originalVersion.professionalIndemnityInsuranceFromDate === null) {
            diffObj.originalVersion.professionalIndemnityInsuranceFromDate = foundItem[0].professionalIndemnityInsuranceFromDate;
        }
        if (diffObj.originalVersion.professionalIndemnityInsuranceToDate === null) {
            diffObj.originalVersion.professionalIndemnityInsuranceToDate = foundItem[0].professionalIndemnityInsuranceToDate;
        }
        if (diffObj.originalVersion.complaintsMechanismType === null) {
            diffObj.originalVersion.complaintsMechanismType = foundItem[0].complaintsMechanismType;
        }
        if (diffObj.originalVersion.recordActiveFromDate === undefined) {
            diffObj.originalVersion.recordActiveFromDate = foundItem[0].recordActiveFromDate;
        }
        if (diffObj.originalVersion.recordActiveToDate === undefined) {
            diffObj.originalVersion.recordActiveToDate = foundItem[0].recordActiveToDate;
        }
        // keep supercededById value
        // keep supercedesId value
        // keep isEditCopy value
        if (diffObj.differences.membershipToDate !== undefined) {
            diffObj.originalVersion.membershipToDate = diffObj.differences.membershipToDate;
        }
        if (diffObj.differences.professionalIndemnityInsuranceToDate !== undefined) {
            diffObj.originalVersion.professionalIndemnityInsuranceToDate = diffObj.differences.professionalIndemnityInsuranceToDate;
        }
        if (diffObj.differences.recordActiveFromDate !== undefined) {
            diffObj.originalVersion.recordActiveFromDate = diffObj.differences.recordActiveFromDate;
        }
        if (diffObj.differences.recordActiveToDate !== undefined) {
            diffObj.originalVersion.recordActiveToDate = diffObj.differences.recordActiveToDate;
        }
    }
};

/* If any of the CMs are attempted to be removed, we have the diff and MUST merge the data from the original with the diff as the diff is mostly empty of useful data */
export function GetRemovedComplaintsMechanismCollection(
    differencesComplaintsCollection: IDifferences<IComplaintMechanism>[] | null,
    complaintMechanisms: IComplaintMechanism[],
) {
    if (differencesComplaintsCollection === null || differencesComplaintsCollection.length === 0) {
        return null;
    }

    const isRemoveInDifferencesObject = (diffObj: IDifferences<IComplaintMechanism>) => {
        return (
            IsObjectEmpty(diffObj.differences) === false && // FALSE
            (diffObj.differences.isDeleted === undefined || diffObj.differences.isDeleted === false) && // FALSE or undefined
            diffObj.differences.isEditCopy === true && // TRUE
            // diffObj.differences.recordActiveFromDate === null && // empty Active From date
            diffObj.differences.recordActiveToDate !== undefined && // non-empty Active To date
            diffObj.differences.recordActiveToDate !== null &&
            diffObj.differences.supercedesId !== null && // non-empty SupercedesId
            diffObj.differences.supercedesId !== '' &&
            diffObj.differences.supercededById === null && // empty SupercededById
            IsObjectEmpty(diffObj.originalVersion) === false && // FALSE
            diffObj.originalVersion.isDeleted === false && // FALSE
            diffObj.originalVersion.isEditCopy === false && // FALSE
            diffObj.originalVersion.supercedesId === null && // empty SupercedesId
            diffObj.originalVersion.supercededById !== '' &&
            diffObj.originalVersion.supercededById !== null // non-empty SupercededById
        );
    };

    const isRemoveInOriginalVersionObject = (diffObj: IDifferences<IComplaintMechanism>) => {
        return (
            IsObjectEmpty(diffObj.differences) === true && // TRUE
            IsObjectEmpty(diffObj.originalVersion) === false && // FALSE
            diffObj.originalVersion.isDeleted === false && // FALSE
            diffObj.originalVersion.isEditCopy === true && // TRUE
            // iffObj.originalVersion.recordActiveFromDate === null && // empty Active From date
            diffObj.originalVersion.recordActiveToDate !== undefined && // non-empty Active To date
            diffObj.originalVersion.recordActiveToDate !== null &&
            diffObj.originalVersion.supercedesId !== null && // non-empty SupercedesId
            diffObj.originalVersion.supercededId !== '' &&
            diffObj.originalVersion.supercededById === null // empty SupercededById
        );
    };

    // Removed when the following is true (we clone for an EditCopy record upon remove):
    // .differences.IsDeleted=F   .isEditCopy=T  .supercededById=null  .supercedesId=<GUID> recordActiveToDate=<DATE>
    // .originalVersion.IsDeleted=F   .isEditCopy=T  .supercededById=<GUID>>  .supercedesId=null
    const removedComplaintsMechanismCollection: IDifferences<IComplaintMechanism>[] = [];
    differencesComplaintsCollection.forEach((diffObj: IDifferences<IComplaintMechanism>) => {
        // remove has either a differences object as EditCopy or only an originalVersion with EditCopy
        if (isRemoveInDifferencesObject(diffObj) || isRemoveInOriginalVersionObject(diffObj)) {
            // merge data from the original as the diff is mostly devoid of the display data we need
            CopyFieldsFromDiffObjectToOrigObjectForDisplay(complaintMechanisms, diffObj);

            removedComplaintsMechanismCollection.push(diffObj);
        }
    });

    return removedComplaintsMechanismCollection;
}

export function GetUpdatedComplaintsMechanismCollection(differencesComplaintsCollection: IDifferences<IComplaintMechanism>[] | null) {
    if (differencesComplaintsCollection === null || differencesComplaintsCollection.length === 0) {
        return null;
    }

    // Updated when the following is true:
    // .differences = <object containing diff output> -OR- NULL (we create an editCopy)
    // .originalVersion.IsDeleted=F   .isEditCopy=T  .supercededById=null  .supercedesId=<guid>
    const updatedComplaintsMechanismCollection: IDifferences<IComplaintMechanism>[] = [];
    differencesComplaintsCollection.forEach((diffObj: IDifferences<IComplaintMechanism>) => {
        if (
            // TRUE as we create an EditCopy record and pass that in at point: IsObjectEmpty(diffObj.differences) === true && // TRUE
            IsObjectEmpty(diffObj.originalVersion) === false && // FALSE
            diffObj.originalVersion.isDeleted === false && // FALSE
            (diffObj.originalVersion.isEditCopy === true || diffObj.differences?.isEditCopy === true) && // TRUE (we make a copy now)
            ((diffObj.originalVersion.supercedesId !== null && diffObj.originalVersion.supercedesId !== '') ||
                (diffObj.differences?.supercedesId !== null && diffObj.differences?.supercedesId !== '')) && // non-empty SupercedesId
            (diffObj.originalVersion.supercededById === null || diffObj.differences?.supercededById === null) && // empty SupercededById
            ((diffObj.originalVersion.recordActiveFromDate !== undefined && diffObj.originalVersion.recordActiveFromDate !== null) ||
                (diffObj.differences?.recordActiveFromDate !== undefined && diffObj.differences?.recordActiveFromDate !== null)) && // non-empty Active From date
            (diffObj.differences?.recordActiveToDate === undefined || diffObj.differences?.recordActiveToDate === null) // empty Active To date
        ) {
            updatedComplaintsMechanismCollection.push(diffObj);
        }
    });

    return updatedComplaintsMechanismCollection;
}

/* superceded by attachmentIdCollection, if that works:
function GetActiveComplaintAttachmentId(application: IApplication, activeAttachmentsOfThisType: IAttachmentMetadata[]) {
    const activeComplaint = application.activeComplaintsMechanismObj;
    const guid = new Guid();

    if (activeAttachmentsOfThisType && activeComplaint && activeAttachmentsOfThisType.length >= 0) {
        // if the attachment and the complaints dont line up in terms of index (how it is read from DB) then we align it now
        if (activeComplaint.activeAttachmentId && activeComplaint.activeAttachmentId?.length > 0 && activeAttachmentsOfThisType.length > 0) {
            if (activeComplaint.activeAttachmentId !== activeAttachmentsOfThisType[0].attachmentId) {
                // go get the correct index for this item
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < activeAttachmentsOfThisType.length; i++) {
                    if (activeAttachmentsOfThisType[i].attachmentId === activeComplaint.activeAttachmentId) {
                        return activeAttachmentsOfThisType[i].attachmentId;
                    }
                }
            } else {
                return activeAttachmentsOfThisType[0].attachmentId;
            }
        } else {
            // user has removed an item and potentially the index of this item is greater than the count of attachments-check, so we look for it here if a valid evidenceAttachmentId
            if (activeComplaint.activeAttachmentId !== '' && activeComplaint.activeAttachmentId !== guid.empty) {
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < activeAttachmentsOfThisType.length; i++) {
                    if (activeAttachmentsOfThisType[i].attachmentId === activeComplaint.activeAttachmentId) {
                        return activeAttachmentsOfThisType[i].attachmentId;
                    }
                }
            }
        }
    }

    return ''; // default
}
*/

export function IsComplaintsArrayEqual(
    complaintsMechanismSource: IComplaintMechanism[] | undefined,
    complaintsMechanismDest: IComplaintMechanism[] | undefined,
) {
    let retValue = true;

    if (
        complaintsMechanismSource === undefined ||
        complaintsMechanismDest === undefined ||
        complaintsMechanismSource.length !== complaintsMechanismDest.length
    ) {
        retValue = false;
    }

    if (retValue === true) {
        let idx = 0;
        complaintsMechanismSource!.forEach((complaint: IComplaintMechanism) => {
            // need to compare all possible id/settings per pages used in this wizard
            if (
                complaint.complaintsMechanismId !== complaintsMechanismDest![idx].complaintsMechanismId ||
                complaint.hasProfessionalIndemnityInsuranceViaMembership !== complaintsMechanismDest![idx].hasProfessionalIndemnityInsuranceViaMembership ||
                complaint.professionalAssociation?.professionalAssociationId !==
                    complaintsMechanismDest![idx].professionalAssociation?.professionalAssociationId ||
                complaint.outlet?.outletId !== complaintsMechanismDest![idx].outlet?.outletId
            ) {
                retValue = false;
            }

            idx = idx + 1;
        });
    }

    return retValue;
}

const SetAttachmentIntoActiveComplaint = (inquiry: Inquiry, setValue: any, newAttachment: IAttachmentMetadata) => {
    if (inquiry.application.activeComplaintsMechanismObj) {
        inquiry.application.activeComplaintsMechanismObj.attachmentExists = newAttachment.attachmentId;
        inquiry.application.activeComplaintsMechanismObj.activeAttachmentId = newAttachment.attachmentId;
        setValue(`inquiry.application.activeComplaintsMechanismObj.attachmentExists`, inquiry.application.activeComplaintsMechanismObj.attachmentExists);
        setValue(`inquiry.application.activeComplaintsMechanismObj.activeAttachmentId`, inquiry.application.activeComplaintsMechanismObj.activeAttachmentId);

        // tslint:disable-next-line: prefer-const
        let currItems: IAttachmentMetadata[] = inquiry.application.activeComplaintsMechanismObj.evidence;
        if (currItems === undefined || currItems === null) {
            currItems = [];
        }
        // check if not already added
        if (currItems.findIndex(item => item.attachmentId === newAttachment.attachmentId) === -1) {
            // assign item to current collection
            currItems.push(newAttachment);
            inquiry.application.activeComplaintsMechanismObj.evidence = currItems;
            setValue('inquiry.application.activeComplaintsMechanismObj.evidence', currItems, { shouldValidate: false });
        }
    }
};

export function MapComplaintsAttachmentsToComplaintsArray(inquiry: Inquiry, setValue: any, getValues: any) {
    // if attachments are present we map those into complaintsMechanism.attachmentExists
    const allComplaintsActiveAttachments: IAttachmentMetadata[] = GetComplaintsAllActiveAttachments(inquiry.application.attachments);
    const allComplaintsAttachmentsCount = allComplaintsActiveAttachments?.length;
    const complaintsCollectionCount = inquiry?.application?.complaintsMechanism?.length;
    const guid = new Guid();

    for (let idxAttachment = 0; idxAttachment < allComplaintsAttachmentsCount; idxAttachment++) {
        const complaintAttachment = allComplaintsActiveAttachments[idxAttachment];

        // does this attachment exist in the complaints collection? Is it valid to add for this Id? If not, inject into a new complaint slot
        let doNotAddComplaint: boolean = false;
        for (let idxComplaint = 0; idxComplaint < complaintsCollectionCount; idxComplaint++) {
            const currComplaint = inquiry.application.complaintsMechanism[idxComplaint];

            // either NULL or defaults to empty attachmentId
            if (
                currComplaint.activeAttachmentId === complaintAttachment.attachmentId ||
                currComplaint.complaintsMechanismId === complaintAttachment.complaintsProcessId ||
                ((inquiry.application.accreditationStatus === AccreditationStatusEnum.Accredited ||
                    inquiry.application.accreditationStatus === AccreditationStatusEnum.ConditionalAccreditation) &&
                    complaintAttachment.complaintsProcessId !== guid.empty)
            ) {
                // rely upon back-end to set the evidence
                // // set as attachmentId lines up
                // SetAttachmentIntoCollection(inquiryDefObj, setValue, govtFundedAttachment, idxComplaint);
                doNotAddComplaint = true;
                break;
            }
        }

        if (!doNotAddComplaint) {
            // API returns CM collection - each CM contains a collection of evidence - so we have to make sure that the evidence found does not belong
            //  to another CM record (look in both active CMs and CMs lists)
            // tslint:disable-next-line: only-arrow-functions
            const listFoundActiveColl = inquiry?.application?.activeComplaintsMechanismObj?.evidence.filter(function (e) {
                return e.attachmentId === complaintAttachment.attachmentId;
            });
            if (listFoundActiveColl && listFoundActiveColl.length > 0) {
                doNotAddComplaint = true;
            }
        }

        // add the attachment if passing all tests for addition AND is not deleted
        if (!doNotAddComplaint && complaintAttachment.isDeleted === false) {
            // this attachment was not found in complaints collection so it is the current active complaint attachment
            SetAttachmentIntoActiveComplaint(inquiry, setValue, complaintAttachment);
        }
    } // govt funded attachments loop
}

export const RemoveAttachmentsForInsuranceWhenChanges = (attachmentActions: AttachmentActions, dispatch: any, inquiry: Inquiry) => {
    const complaintsMechId = inquiry.application?.activeComplaintsMechanismObj?.complaintsMechanismId ?? '';

    RemoveAttachmentsFromAzureIfExistForThisComplaint(
        complaintsMechId,
        attachmentActions,
        dispatch,
        AttachmentDocumentType.Insurance,
        inquiry.application.attachments,
    );
    inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExistForThisComplaint(
        complaintsMechId,
        AttachmentDocumentType.Insurance,
        inquiry.application.attachments,
    );
};

// now we have to do this by CM (as we may have multiple CMs at this point)
export const RemovePriorAttachmentsForOrgNameOrRelationshipToEmpChanged = (
    attachmentActions: AttachmentActions,
    dispatch: any,
    inquiry: Inquiry,
    relationshipToEmployerChangedTo?: RelationshipToEmployerType | null,
    keepInsuranceDocs?: boolean, // ie when going back on a form and only at the details step we dont knock out insurance docs if exist
) => {
    const complaintsMechId = inquiry.application?.activeComplaintsMechanismObj?.complaintsMechanismId ?? '';
    let removeOrgPanel = false;
    let removeOrgEmployee = false;

    /*if (relationshipToEmployerChangedTo !== undefined && relationshipToEmployerChangedTo !== null) {
        if (relationshipToEmployerChangedTo === RelationshipToEmployerType.Employee) {
            removeOrgPanel = true; // yes remove the opposite files that may have just been uploaded
        }
        if (relationshipToEmployerChangedTo === RelationshipToEmployerType.OnPanel) {
            removeOrgEmployee = true; // yes remove the opposite files that may have just been uploaded
        }
    } else { */
    // just remove them all
    removeOrgEmployee = true;
    removeOrgPanel = true;
    // }

    if (removeOrgPanel === true) {
        RemoveAttachmentsFromAzureIfExistForThisComplaint(
            complaintsMechId,
            attachmentActions,
            dispatch,
            AttachmentDocumentType.OrgPanel,
            inquiry.application.attachments,
        );
        inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExistForThisComplaint(
            complaintsMechId,
            AttachmentDocumentType.OrgPanel,
            inquiry.application.attachments,
        );
    }

    if (removeOrgEmployee === true) {
        // OrgEmployment or AssociationMembership
        RemoveAttachmentsFromAzureIfExistForThisComplaint(
            complaintsMechId,
            attachmentActions,
            dispatch,
            AttachmentDocumentType.AssociationMembership,
            inquiry.application.attachments,
        );
        inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExistForThisComplaint(
            complaintsMechId,
            AttachmentDocumentType.AssociationMembership,
            inquiry.application.attachments,
        );

        RemoveAttachmentsFromAzureIfExistForThisComplaint(
            complaintsMechId,
            attachmentActions,
            dispatch,
            AttachmentDocumentType.OrgEmployment,
            inquiry.application.attachments,
        );
        inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExistForThisComplaint(
            complaintsMechId,
            AttachmentDocumentType.OrgEmployment,
            inquiry.application.attachments,
        );
    }

    if (keepInsuranceDocs === true) {
        // do nothing, this is reused by going back on details UI, so we do not want to touch any existing insurance docs
    } else {
        // remove the insurance type attachments, if exists
        RemoveAttachmentsForInsuranceWhenChanges(attachmentActions, dispatch, inquiry);
    }
};

export const RemoveCMAttachmentsForThisCM = (attachmentActions: AttachmentActions, dispatch: any, inquiry: Inquiry, complaintsMechId: string) => {
    RemoveAttachmentsFromAzureIfExistForThisComplaint(
        complaintsMechId,
        attachmentActions,
        dispatch,
        AttachmentDocumentType.OrgPanel,
        inquiry.application.attachments,
    );
    inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExistForThisComplaint(
        complaintsMechId,
        AttachmentDocumentType.OrgPanel,
        inquiry.application.attachments,
    );

    // OrgEmployment or AssociationMembership
    RemoveAttachmentsFromAzureIfExistForThisComplaint(
        complaintsMechId,
        attachmentActions,
        dispatch,
        AttachmentDocumentType.AssociationMembership,
        inquiry.application.attachments,
    );
    inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExistForThisComplaint(
        complaintsMechId,
        AttachmentDocumentType.AssociationMembership,
        inquiry.application.attachments,
    );

    RemoveAttachmentsFromAzureIfExistForThisComplaint(
        complaintsMechId,
        attachmentActions,
        dispatch,
        AttachmentDocumentType.OrgEmployment,
        inquiry.application.attachments,
    );
    inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExistForThisComplaint(
        complaintsMechId,
        AttachmentDocumentType.OrgEmployment,
        inquiry.application.attachments,
    );

    RemoveAttachmentsFromAzureIfExistForThisComplaint(
        complaintsMechId,
        attachmentActions,
        dispatch,
        AttachmentDocumentType.Insurance,
        inquiry.application.attachments,
    );
    inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExistForThisComplaint(
        complaintsMechId,
        AttachmentDocumentType.Insurance,
        inquiry.application.attachments,
    );
};

export const RemovePriorDataRelationshipChanged = (
    attachmentActions: AttachmentActions,
    dispatch: any,
    inquiry: Inquiry,
    setInquiryObj: any,
    relationshipToEmployerChangedTo?: RelationshipToEmployerType,
) => {
    // remove attachments, if exist
    RemovePriorAttachmentsForOrgNameOrRelationshipToEmpChanged(attachmentActions, dispatch, inquiry, relationshipToEmployerChangedTo);

    if (inquiry.application.activeComplaintsMechanismObj) {
        inquiry.application.activeComplaintsMechanismObj.activeAttachmentId = '';
        inquiry.application.activeComplaintsMechanismObj.evidence = [];
        inquiry.application.activeComplaintsMechanismObj.attachmentExists = '';
    }
    // must refresh inquiry obj for attachment collection and active CM
    setInquiryObj(inquiry);
};

export const GetLatestComplaintsMechanismCollection = (complaintMechanisms: IComplaintMechanism[]) => {
    // for each complaint in the collection, remove it if (NOTE: back-end will strip IsDeleted ones already):
    //  - it is NOT an EditCopy, BUT has a SupercededById where the record it is superceded by is ALSO NOT Deleted and NOT EditCopy (and its supercedesId matches this one!)
    const today = new Date().setHours(0, 0, 0, 0);

    // tslint:disable-next-line: prefer-const
    let returnCMs: IComplaintMechanism[] = [];

    complaintMechanisms.forEach((cmToCheck: IComplaintMechanism) => {
        if (cmToCheck.isEditCopy === true) {
            returnCMs.push(cmToCheck); // just add EditCopy ones
        } else {
            if (cmToCheck.supercededById === undefined || cmToCheck.supercededById === null || cmToCheck.supercededById === '') {
                // is not superceded check that this is not ended, otherwise add it
                cmToCheck.recordActiveToDate = cmToCheck.recordActiveToDate && formatDateForDateFns(cmToCheck.recordActiveToDate);
                if (isValidDate(cmToCheck.recordActiveToDate) === true && cmToCheck.recordActiveToDate!.setHours(0, 0, 0, 0)! <= today) {
                    // do not add this
                } else {
                    returnCMs.push(cmToCheck); // just add non-supercededBy ones
                }
            } else {
                // check if the record superceding this one is active and not an editcopy
                const supercededCMs = complaintMechanisms.filter(
                    item =>
                        item.isDeleted !== true &&
                        item.isEditCopy !== true &&
                        IsValidGuidIdentifier(item.supercedesId) === true &&
                        item.supercedesId === cmToCheck.complaintsMechanismId, // if my Id matched by another records supercedesId
                );
                if (supercededCMs.length > 0) {
                    // do NOT add this CM as it has a match and has been superceded
                } else {
                    returnCMs.push(cmToCheck); // added as although it may have a match we may need to include edit copy records for processing
                }
            }
        }
    });

    return returnCMs;
};
