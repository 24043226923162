export const UNINITIALISED = 'UNINITIALISED';
export const EMPTY_DATE = new Date('01-01-1900');

export const DEFAULT_DATE_OF_BIRTH = new Date('1/1/1980');
export const DATE_FORMAT = 'dd/MM/yyyy'; // used by date picker

export const MAILTO_FDDR_PAU_CONTACT_EMAIL = 'mailto:fdrregistration@ag.gov.au';
export const DISPLAY_FDDR_PAU_CONTACT_EMAIL = 'fdrregistration@ag.gov.au';
export const DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER = '1800 025 255';
export const LINK_AG_FDR = 'https://www.ag.gov.au/families-and-marriage/families/family-dispute-resolution/information-family-dispute-resolution-practitioners';
export const LINK_AG_COPYRIGHT = 'https://www.ag.gov.au/copyright';
export const LINK_AG_PRIVACY = 'https://www.ag.gov.au/privacy-policy';
export const LINK_AG_BECOMINGPRACTITIONER =
    'https://www.ag.gov.au/families-and-marriage/families/family-dispute-resolution/becoming-family-dispute-resolution-practitioner';
export const LINK_AG_INFOPRACTITIONER =
    'https://www.ag.gov.au/families-and-marriage/families/family-dispute-resolution/information-family-dispute-resolution-practitioners';
export const LINK_AG_FAMILYLAW_REGULATIONS2008 = ' https://www.legislation.gov.au/Details/F2009C00158';
export const LINK_AG_FAMILYLAW_ACT1975 = 'https://www.legislation.gov.au/Details/C2020C00374';
export const LINK_FAMILY_RELATIONSHIPS = 'https://www.familyrelationships.gov.au/';
export const LINK_FAMILY_RELATIONSHIPS_ADVICELINE = 'https://www.familyrelationships.gov.au/talk-someone/advice-line';
export const LINK_FAMILYLAW_TERMFINDER = 'https://lawtermfinder.mq.edu.au/';
export const LINK_TRANSLATION_SERVICE = 'https://www.tisnational.gov.au/';
export const LINK_FEDCIRCUIT_FAMILYCOURT = 'https://www.fcfcoa.gov.au/';
export const LINK_FAMMILYCOURT_WA = 'https://www.familycourt.wa.gov.au/';

export const DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW =
    'You have made changes which require review. No further updates can be made until this review is complete.';
export const DASHBOARD_NOTIFICATION_EDITOTHERDETAILS = `To make changes to details not available on this page, including lifting of a condition (if applicable) or returning from suspension, please contact the Practitioner Accreditation Unit by email to ${DISPLAY_FDDR_PAU_CONTACT_EMAIL} or by phone on ${DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER}.`;
export const DASHBOARD_NOTIFICATION_REQUESTCANCEL = `You have submitted a request to cancel your accreditation, however if you would prefer to suspend your accreditation, please contact the Practitioner Accreditation Unit on ${DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER}.`;
// when an individual has a work item created for them, the below note will be shown
export const DASHBOARD_NOTIFICATION_SYSTEM_MAINTENANCE_INDIVIDUAL = 'The system is having a maintenance break. Please try again shortly.';
export const DASHBOARD_NOTIFICATION_CHANGESUNDERMAINTENANCE = 'The system is having a maintenance break. Please try again shortly.';

export const TIP_COMPLAINTSMECHANISM_LANDING =
    'This page displays each of the outlets associated with the FDR services you provide. By selecting the Manage button you can edit the dates of a specific complaints mechanism and the associated insurance, or you can remove the insurance or outlet completely.';
export const TIP_COMPLAINTSMECHANISM_TYPE_NOTCHANGED =
    'If your complaints mechanism type has not changed, please use the Continue button to continue. If you no longer have this complaints mechanism, please use the Back button on this page to the complaints mechanism view and remove it.  You can then add your current complaints mechanism.';
export const TIP_COMPLAINTSMECHANISM_DETAILS_REMOVE = `If you no longer use a complaints mechanism, you can remove it by using the 'Remove complaints mechanism' button. This will also remove the insurance you have recorded for that complaints mechanism, if relevant. If you only want to remove the insurance from your complaints mechanism, use the 'Remove insurance only' button.`;
export const TIP_COMPLAINTSMECHANISM_DETAILS_EMPLOYEEFILEUPLOAD =
    'Documents you upload as supporting evidence must clearly indicate that you are employed by, or included on the external panel of FDR providers for the organisation you have selected.';
export const TIP_COMPLAINTSMECHANISM_DETAILS_PRIVATEFILEUPLOAD =
    'Documents you upload as supporting evidence must clearly indicate that you are a full member of the selected professional association.';
export const TIP_COMPLAINTSMECHANISM_DETAILS_EDIT_SAVE = `If you don't need to update information on this page, please use the 'Save and Continue' button to continue.  If you no longer use this complaints mechanism, return to the previous page and delete it then add your new complaints mechanism.`;
export const TIP_COMPLAINTSMECHANISM_DETAILS_EDIT_INSURANCE = `Documents uploaded as evidence towards your current insurance cover must clearly indicate the dates the insurance policy is active for, and that you are covered under the policy.`;

export const CONTACTUS_PAGEHEADING_WITHDRAW = 'Request to Withdraw application for accreditation as a Family Dispute Resolution Practitioner';
export const CONTACTUS_PAGEHEADING_SUSPEND = 'Request to suspend accreditation as a Family Dispute Resolution Practitioner';
export const CONTACTUS_PAGEHEADING_CANCEL = 'Request to cancel accreditation as a Family Dispute Resolution Practitioner';
export const CONTACTUS_LABELTEXT_WITHDRAW = 'To withdraw your application please send a request to the Practitioner Accreditation Unit at:';
export const CONTACTUS_LABELTEXT_SUSPEND =
    'To suspend your accreditation as an FDR practitioner please send a request to the Practitioner Accreditation Unit at:';
export const CONTACTUS_LABELTEXT_CANCEL =
    'To cancel your accreditation as an FDR practitioner please send a request to the Practitioner Accreditation Unit at:';

export const HEADER_PERSONALDETAILS_NAME =
    'Your personal details are used by the Practitioner Accreditation Unit to identify and contact you about your application or accreditation status. Contact details in this section are not made public. The gender you provide will be visible if you publish outlet details on the public Register once accredited.';
export const HEADER_PERSONALDETAILS_CONTACT =
    'Your personal details are used by the Practitioner Accreditation Unit to identify and contact you about your application or accreditation status. Contact details in this section are not made public. The gender you provide will be visible if you publish outlet details on the public Register once accredited.';
export const HEADER_PASTAPPLICATIONHISTORY =
    'This information is used by the Practitioner Accreditation Unit to link any previous applications and accreditation history to your current application.';
export const HEADER_COMPETENCIES =
    'The Graduate Diploma of Family Dispute Resolution (full or partial completion) is delivered by Registered Training Organisations. Higher Education Providers may certify to our department that a postgraduate course (or units of a postgraduate qualification) it offers is equivalent to the Graduate Diploma of Family Dispute Resolution, as either a full or partial completion. If only a partial completion has been awarded towards the Graduate Diploma of Family Dispute Resolution (i.e. 6 units or the equivalent), then applicants need an appropriate qualification or current accreditation under the National Mediator Accreditation System (NMAS) to support their application.';
export const HEADER_CHILDRELATED =
    'FDR practitioners may choose to provide child-inclusive services to assist with resolving disputes. This type of service may require the practitioner to obtain a child-related employment (or vulnerable people) check, depending on the requirements in the relevant States or Territories. Being prohibited from working with children makes a person ineligible for accreditation as an FDR practitioner.';
export const HEADER_CRIMINALCONVICTIONS =
    'A person is disqualified from accreditation if the person has been convicted of: (a) an offence involving violence to a person; or (b) a sex‑related offence, including rape, sexual assault, indecent assault, unlawful sexual acts with or upon minors, child pornography, procuring or trafficking of a child for indecent purposes or being knowingly concerned with the prostitution of a child. Applicants should discuss this type of criminal history with the Practitioner Accreditation Unit.';
export const HEADER_COMPLAINTS = `As an FDR practitioner, you must maintain a suitable complaints mechanism in order to continually meet the accreditation criteria. Information about your complaints mechanism must be given to your clients prior to an FDR session.`;
export const HEADER_PROFESSIONALINDEMNITYINSURANCE =
    'Being covered by professional indemnity insurance to provide FDR services is a condition imposed on all FDR practitioners. Insurance cover can be provided through employment or established through a policy arranged through an insurance provider.';
export const HEADER_CHANGEOFNAME = '';
export const HEADER_DECLARATION = '';
export const HEADER_REVIEWANDSUBMIT = '';
export const HEADER_OUTLET_PUBLICINFO = '';
export const HEADER_OUTLET_ACCESSIBILITY = '';

export const HEADER_MANAGE_PUBLICDETAILS =
    'View your public details on this page. You can edit these or remove them from the public register using the appropriate buttons.';
export const HEADER_EDIT_OUTLETDETAILS =
    'By completing this section, you consent to your contact details and information relating to the FDR service you provide to appear on the public FDR Register. Any information entered in this section will appear publicly as long as it is relevant to the provision of FDR services. All free-text fields will require approval by the Practitioner Accreditation Unit prior to being published on the public FDR Register.';
export const HEADER_EDIT_OUTLET_OPENINGHOURS = 'Your availability should be indicated with specific times and days you are available to provide FDR services.';
export const HEADER_LANDING_COMPLAINTS = 'On this page you can view your existing complaints mechanism, remove a complaints mechanism or add a new one.';
export const HEADER_VIEW_PERSONALDETAILS = 'On this page you can view your existing personal details.';
export const HEADER_VIEW_COMPLAINTSMECHANISM = 'View your existing complaints mechanism details.';
export const HEADER_MANAGEDETAILS_REVIEWANDSUBMIT =
    'Review the changes you have made before submitting them. If you need to make further changes, use the link below each heading to return to that area on the dashboard. Alternatively, you can discard all changes if you need to.';

export const CLEANINGUP_PRIORDATA = 'Cleaning up prior data, please wait...';

export const PLACEHOLDER_DATE = 'click to open Date picker'; // 'DD/MM/YYYY';
export const PLACEHOLDER_TIME = 'click to select a time'; // 10:30 PM;
// export const PLACEHOLDER_DATE_OF_BIRTH = 'DD/MM/YYYY';
// export const PLACEHOLDER_DATE_OF_NMASEXPIRY = 'DD/MM/YYYY';
export const PLACEHOLDER_PRIMARY_PHONE = 'landline or mobile 10 digits';
export const PLACEHOLDER_SECONDARY_PHONE = 'landline or mobile 10 digits';
export const PLACEHOLDER_EMAIL = 'Example: name@domain.com or name@domain.com.au';

export const PLACEHOLDER_ADDRESS_LOOKUP = 'Start typing full address here...';
export const PLACEHOLDER_PREVIOUS_SUBMISSION_NUMBER = 'Examples: R1001885 or S1009092';
export const PLACEHOLDER_OUTLET_BUSINESSNAME = 'Enter your business name as applicable. This will appear as "Practitioner name - Business name"';
export const PLACEHOLDER_OUTLET_LANDLINE = 'Enter 10 digits including area code';
export const PLACEHOLDER_OUTLET_MOBILE = 'Enter 10 digits';
export const PLACEHOLDER_OUTLET_TOLLFREENUMBER = 'Example: 1800999999 or 1300999999';
export const PLACEHOLDER_OUTLET_WEBADDRESS = 'Example: http://www.yourwebaddress.com.au or https://www.yourwebaddress.com.au';
export const PLACEHOLDER_OUTLET_CULTUREGROUPSPECIFICDETAIL = 'Example: Pacific islander, Hispanic, Latino, Aboriginal and Torres Strait Islander';
export const PLACEHOLDER_OUTLET_LANGUAGESPECIFICDETAIL = 'Example: Italian, Greek, Spanish';
export const PLACEHOLDER_OUTLET_RELIGIONSPECIFICDETAIL = 'Example: Buddhism, Islam, Judaism';
export const PLACEHOLDER_OUTLET_TRAVELDISTANCE = '1234';

export const GUIDANCETEXT_INFOCHILDRELATED =
    'Please check your requirements in the State/Territory you intend to provide services,  including if you are providing an online or phone service.';
export const GUIDANCETEXT_COMPLAINTS_MECH_TYPE = `To comply with your obligations as an FDR practitioner, you must maintain accurate records regarding the complaint mechanism and insurance you use for your FDR service(s). Private FDR practitioners can only have one 'outlet' for their private service, whereas the records of practitioners employed by funded organisations must reflect all funded organisations through which they provide FDR services. Indicate which complaints mechanism(s) you will be using for your FDR service.`;
export const GUIDANCETEXT_OUTLET_MODESOFDELIVERY = 'Select the ways in which you deliver your services to clients';
export const GUIDANCETEXT_OUTLET_BUSINESSCONTACT = 'Provide any applicable business contacts. At least one phone number or an email address is required.';
export const GUIDANCETEXT_OUTLET_PHYSICALADDRESS =
    'Providing a physical address allows clients to find you using a location search, for example to find practitioners within 20km of their location. Practitioners who are closest to the client in this type of search are displayed higher in the list of search results. It is not mandatory to include your street address, however if you provide a suburb, state and postcode, you will been seen as a pin on the map of practitioners and will be found in location-based searches.';
export const GUIDANCETEXT_OUTLET_ROOMSTOBEARRANGED = `Select this option if you arrange different locations based on clients' needs or do not wish to make your physical address known until a client is engaged.`;
export const GUIDANCETEXT_OUTLET_OTHERSERVICES =
    'You may wish to offer supervision for fellow FDR practitioners and/or supervision of students undertaking their qualification in FDR. They will be able to search for this service offering.';
export const GUIDANCETEXT_OUTLET_CLIENTSERVICES =
    'Your listing in the register will automatically include parenting arrangements as a service. Select additional services from the following options.';
export const GUIDANCETEXT_OUTLET_ADDITIONALMODESDELIVERY = 'Select all that apply';
export const GUIDANCETEXT_OUTLET_CLIENTGROUPS = 'Select all that apply';
export const GUIDANCETEXT_OUTLET_OPERATINGHOURS =
    'You can choose standard operating hours (Mon-Fri 9am-5pm) or provide specific times you are available to offer FDR services.';
export const GUIDANCETEXT_OUTLET_APPOINTMENTTYPES = `You should indicate if you accept clients as 'walk-ins' at your physical address (if provided), or if clients need to make an appointment. By choosing 'outside listed operating hours' you are indicating that you may be more flexible than the operating hours you have specified.`;
export const GUIDANCETEXT_OUTLET_FEESFORFDRSERVICES =
    'Information about how your fees are charged, or if part of your FDR service is free, should be consistent with the information provided to clients prior to starting an FDR service.';
export const GUIDANCETEXT_EDITPERSONALDETAILS_CHANGEOFNAMEDOCS =
    'You must upload evidence that shows a legal change of name, such as an official marriage certificate or change of name document to support the change to your name.';
export const GUIDANCETEXT_EDITPERSONALDETAILS_PROOFOFBIRTHDOCS =
    'You must upload a birth certificate or other proof of birth to support the change to your date of birth.';

export const HELPHEADERTEXT_FORMERNAME = `Why can't I edit my former name?`;
export const HELPTEXT_FORMERNAME =
    'This field is only used after you have been accredited and you have changed your name. Former name can only be updated by the Practitioner Accreditation Unit upon receipt of suitable evidence.';
export const HELPHEADERTEXT_GENDER = 'Why do we ask this?';
export const HELPTEXT_GENDER =
    'Providing your gender is not mandatory, however if you will have an outlet that will appear on the FDR public register, it may help clients search the FDR Register for practitioners of a certain gender to suit their service requirements.  You can choose to provide your consent to make your gender visible after your application is approved.';
export const HELPHEADERTEXT_FULLGRADDIPLOMA = 'What is this?';
export const HELPTEXT_FULLGRADDIPLOMA = `The Graduate Diploma of Family Dispute Resolution delivered by Registered Training Organisations is made up of 10 units of competency (six core and four electives). Students of courses from Higher Education Providers should check that their course is equivalent to the full Graduate Diploma before proceeding with their application.`;
export const HELPHEADERTEXT_SIXUNITS = 'What is this?';
export const HELPTEXT_SIXUNITS =
    'Completion of the six core units of the Graduate Diploma of Family Dispute Resolution (or equivalent through a Higher Education Provider) meets part of the accreditation criteria.';
export const HELPHEADERTEXT_APPROPRIATEQUALIFICATION = 'What is this?';
export const HELPTEXT_APPROPRIATEQUALIFICATION = `An appropriate qualification is a higher education award in law, psychology, social work, conflict management, mediation, dispute resolution or in a field or discipline that is determined to be relevant to the provision of FDR. If unsure about the suitability of your qualification, contact the Practitioner Accreditation Unit by email at ${DISPLAY_FDDR_PAU_CONTACT_EMAIL}`;
export const HELPHEADERTEXT_NMAS = 'What is this?';
export const HELPTEXT_NMAS =
    'If applying to be an FDR practitioner on the basis of having accreditation under the National Mediator Accreditation System (NMAS), NMAS accreditation must be maintained in order to continue to meet the FDR accreditation criteria.';
export const HELPHEADERTEXT_THREEUNITS = 'What is this?';
export const HELPTEXT_THREEUNITS =
    'Being on the FDR register at 30 June 2009 and being assessed as competent prior to 1 July 2011 in the three specified units of the (then) Vocational Graduate Diploma of Family Dispute Resolution (or equivalent through a Higher Education Provider) meets part of the accreditation criteria.';
export const HELPHEADERTEXT_ORGANISATIONNAME = 'My organisation is not listed';
export const HELPTEXT_ORGANISATIONNAME = `If your employer organisation or the panel you are intending to provide FDR service on behalf of is not listed, contact the Practitioner Accreditation Unit by email at ${DISPLAY_FDDR_PAU_CONTACT_EMAIL}`;
export const HELPHEADERTEXT_PROFESSIONALASSOCNAME = 'My professional association is not listed';
export const HELPTEXT_PROFESSIONALASSOCNAME = `If you are a member of a professional association that is not listed, contact the Practitioner Accreditation Unit by email at ${DISPLAY_FDDR_PAU_CONTACT_EMAIL}`;
export const WHATISTHISTEXT_PROFESSIONALASSOCNAME =
    'A private practitioner must hold a full membership of a professional association. A professional association is paid membership through a body approved by the Attorney-General’s Department to manage a complaint if one was received for your FDR services. Legal practitioners may use evidence of their current practising certificate as linkage to their appropriate Legal Profession Commission.';
export const HELPHEADERTEXT_NAMEONPUBLICREGISTER = 'What is this?';
export const HELPTEXT_NAMEONPUBLICREGISTER =
    'Clients may like to verify if the practitioner they are considering, or have been referred to, is an accredited FDR practitioner. The search results will only show your name, unless you also provide private FDR services and have given more information to appear on the public search function about your FDR service. Your name will appear on the register as your first name and surname.  If you provided a preferred name, it will appear as preferred name and surname. Your business name will also be displayed, if you provide one.';
export const HELPHEADERTEXT_OUTLET_BUSINESSNAME = 'What is this?';
export const HELPHEADERTEXT_FULLMEMBERSHIP = 'What is this?';
export const HELPTEXT_OUTLET_BUSINESSNAME =
    'Your name will appear on the register as your first name and surname.  If you provided a preferred name, your listing will appear as preferred name and surname. Your business name will also be displayed, if you provide one.';
export const HELPHEADERTEXT_OUTLET_CULTUREGROUP_SPECIFIC = 'What is this?';
export const HELPTEXT_OUTLET_CULTUREGROUP_SPECIFIC =
    'It is expected that all FDR practitioners can provide services to culturally and linguistically diverse clients. However, if you have specific knowledge or skills in a particular cultural group, tradition or custom, that will help clients of that cultural group better engage in the FDR process, you can indicate which group/s you have specific knowledge of, or skills in. If you can support more than one cultural group, please use a comma to separate each (for example: Indian, Chinese, Sudanese).';
export const HELPHEADERTEXT_OUTLET_LANGUAGE_SPECIFIC = 'What is this?';
export const HELPTEXT_OUTLET_LANGUAGE_SPECIFIC =
    'If you speak a language other than English, and feel it would benefit client engagement, you may wish to indicate the particular language/s you can comfortably converse in. Clients will be able to search for specific languages entered in this field. If you speak more than one language, please use a comma to separate each (for example: Italian, Greek, Spanish).';
export const HELPHEADERTEXT_OUTLET_RELIGION_SPECIFIC = 'What is this?';
export const HELPTEXT_OUTLET_RELIGION_SPECIFIC =
    'FDR practitioners may require knowledge of specific religious traditions or beliefs in order to assist clients with developing parenting plans or engage in the FDR process. If you have skills or knowledge of specific religions or spiritual beliefs, and would like to advise potential clients, enter one or more of these using commas to separate them if there is more than one (for example: Buddhism, Islam, Judaism).';
export const HELPHEADERTEXT_PERSONALDETAILS_PRIMARYEMAIL = `Why can't I update my primary email address?`;
export const HELPTEXT_PERSONALDETAILS_PRIMARYEMAIL = 'You can only change your primary email address from the dashboard.';
export const HELPTEXT_COMPLAINTSMECHANISM_FULLMEMBERSHIP =
    'A private practitioner must hold a full membership of a professional association. A professional association is paid membership through a body approved by the Attorney-General’s Department to manage a complaint if one was received for your FDR services. Legal practitioners may use evidence of their current practising certificate as linkage to their appropriate Legal Profession Commission.';
export const HELPHEADERTEXT_GENDERONPUBLICREGISTER = 'What is this?';
export const HELPTEXT_GENDERONPUBLICREGISTER =
    'Providing your gender is not mandatory but it may help clients search the FDR Register for practitioners of a certain gender to suit their service requirements.  If you consent to your gender being made public, it will only appear if you have selected male, female or indeterminate in your personal details. You can choose to provide a gender by updating your personal details which can be done from the dashboard.';

export const NOTE_INTRODUCTION_EVIDENCE =
    'You may start an application, however without the required evidence you will not be able to progress through each step of the application.';
export const NOTE_INTRODUCTION_PRIVACY =
    'For privacy reasons, any unsubmitted documents will only be kept for a maximum of three (3) months. After this period of time, documents will be deleted. To avoid delays with the processing your application, ensure you submit your application within three (3) months of starting it.';
export const NOTE_DATEOFBIRTH_MESSAGE = 'Your age according to the Date of Birth you have entered is %%. Is this correct?';
export const NOTE_NMAS_ACCREDITATION_MESSAGE = 'If accredited on this basis, NMAS accreditation must be maintained in order to retain FDR accreditation.';
export const NOTE_PROHIBITED_CHILDREN_CHECK_MESSAGE = 'Your application cannot proceed as you have advised that you are prohibited from working with children.';
export const NOTE_PROHIBITED_CHILDREN_CHECK_MESSAGE_P2 = `You may contact the Practitioner Accreditation Unit on ${DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER} or by email at ${DISPLAY_FDDR_PAU_CONTACT_EMAIL} to discuss this exclusion.`;
export const NOTE_CRIMINAL_MESSAGE = 'Your application for accreditation as an FDR practitioner may be refused on the basis of your answer.';
export const NOTE_CRIMINAL_MESSAGE_P2 = `Please contact the FDR Practitioner Accreditation Unit on ${DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER} or by email at ${DISPLAY_FDDR_PAU_CONTACT_EMAIL} to discuss before continuing.`;
export const NOTE_INSURANCE_MISSING_WARNING = `As you don’t have professional indemnity insurance yet, your application will be assessed for conditional accreditation. Once accredited, to have the condition removed from your accreditation and allow you to commence providing FDR services you will need to ‘Update Insurance’ when selecting your complaints mechanism from the ‘Manage complaints mechanism and insurance’ page from your dashboard.`;
export const NOTE_INSURANCE_PERIOD_WARNING =
    'Your insurance cover is within 30 days of ending. You should update your policy and then update these details as soon as possible.';
export const NOTE_INSURANCE_START_DATE_IN_FUTURE =
    'The start date of your insurance is in the future. If you have evidence that your insurance is current up to and including today, upload both current and renewed membership.';
export const NOTE_MEMBERSHIP_START_DATE_IN_FUTURE =
    'The start date of your membership is in the future. If you have evidence that your membership is current up to and including today, upload both current and renewed membership.';
export const NOTE_COMPLAINTS_TYPEOFCOMPLAINTS_DEFERMESSAGE =
    'If you are employed by more than one organisation, or work for both a funded organisation and in private practice, you can add details later.';
export const NOTE_PROFINDEMNITYINSURANCE_WARNING_PROFASSOC = `It is a condition of your accreditation that you have professional indemnity insurance to cover you while delivering FDR services. If you proceed without insurance your accreditation may become conditional until you provide evidence of having insurance. If you are unsure about how to proceed, please contact the Practitioner Accreditation Unit by email to ${DISPLAY_FDDR_PAU_CONTACT_EMAIL} or by phone on ${DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER}.`;
export const NOTE_PROFINDEMNITYINSURANCE_WARNING_LEGALAIDPANELLISTS = `​External legal aid panellists must provide evidence of having professional indemnity insurance cover in order to deliver FDR services. If you are unsure about how to proceed, please contact the Practitioner Accreditation Unit by email to ${DISPLAY_FDDR_PAU_CONTACT_EMAIL} or by phone on ${DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER}.`;
export const NOTE_OUTLET_APPOINTMENTTYPE_WALKINS =
    'You have indicated that you take walk-ins. Please ensure that you have indicated that you deliver your services face to face and that you have provided a physical address.';
export const NOTE_OUTLET_TRAVELLINGCOSTS_YES =
    'As you have indicated on the Service Coverage page that you are not willing to travel to clients to deliver FDR services at your own expense, you cannot change your answer to this question.  If you want to change this, you will need to change your answer to the Travelling cost question on the Service Coverage page.';
export const NOTE_OUTLET_TRAVELLINGCOSTS_NO =
    'As you have indicated on the Service Coverage page that you are willing to travel to clients to deliver FDR services at your own expense, you cannot change your answer to this question.  If you want to change this, you will need to change your answer to the Travelling cost question on the Service Coverage page.';
export const NOTE_UNSUBMITTED_CHANGES_CLICKHERETOREVIEW = 'You have unsubmitted changes. Click here to view your changes on the Review and submit page.';
export const NOTE_MANAGEDETAILS_REWORK =
    'Your updates to your practitioner details need attention. Refer to email sent by the Practitioner Accreditation Unit then click on the applicable topics on the dashboard to make the necessary amendments.';
export const NOTE_UNSUBMITTED_EXPIRY_WARNING =
    'To avoid issues with your application, you should ensure it is submitted within three (3) months of starting it. If you do not think you can submit within three (3) months, use the Discard Application button on this page to discard it and you can start again when you will be able to submit within three (3) months.';

export const BUTTON_DISABLEDACTION_CHANGESMADE_BUTNOTYETSUBMITTED = `You cannot make any edits until previous changes are completed. To action your changes, please click the 'Action required' clickable area in the header of your dashboard.`;
// export const BUTTON_DISABLEDACTION_CHANGESMADE_BUTNOTYETSUBMITTED_OUTLETBTN =
//    'You cannot combine other update types with the changes you have made until they have been submitted and reviewed by the Practitioner Accreditation Unit.';
export const BUTTON_DISABLEDACTION_OTHERCHANGESFIRST =
    'You cannot edit your outlet information until previous changes are completed. Return to the Review and Submit page to complete or discard your current changes before attempting to change your outlet.';
export const BUTTON_DISABLEDACTION_APPLYFORACCRED_UPDATEAPP =
    'Unless you are asked to provide further information to support your application, you are unable to update your application.';
export const BUTTON_DISABLEDACTION_MAINTAINACCRED_UPDATEOUTLET_ACCREDNOTFULLMEMBER =
    'Unless you are a full member of a professional association with professional indemnity insurance, you are unable to update your outlet details.';
export const BUTTON_DISABLEDACTION_MAINTAINACCRED_UPDATEOUTLET_CONDITIONALLYACCRED = `The condition imposed on your accreditation is not compatible with adding outlet details. To have your details appear on the public FDR Register, you need to arrange for the condition to be removed from your accreditation. Email the evidence required to seek the removal of the condition from your accreditation to the Practitioner Accreditation Unit at ${DISPLAY_FDDR_PAU_CONTACT_EMAIL}`;
export const BUTTON_DISABLEDACTION_MAINTAINACCRED_SUSPENDED =
    'You cannot add details until the suspension on your accreditation is lifted. Please use the Request a suspension lift button on your dashboard.';
export const BUTTON_DISABLEDACTION_MAINTAINACCRED_REQUEST_TO_SUSPENDED = `You have submitted a request to suspend your accreditation, however if you would prefer to cancel your accreditation, please contact the Practitioner Accreditation Unit on ${DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER}.`;

export const WARNING_NOTREADYFORSUBMIT_P1 = `If you have a change marked with a red cross, it is invalid. You cannot submit changes until these are corrected. Use the ‘Revise complaints mechanism(s) and insurance changes’ link to fix this, or discard it.`;
export const WARNING_NOTREADYFORSUBMIT_P2 = 'If your changes are marked with a green tick, you can submit these for review.';

export const COMPLAINTS_MECHANISM_REMOVED_ATTEMPTTOEDIT =
    'You have removed this complaints mechanism and associated insurance – return to your dashboard to submit this change.';
export const COMPLAINTS_MECHANISM_ALLREMOVED_P1 = `You have removed all complaints mechanism and insurance cover associated with your accreditation. If you submit this change you will be non-compliant with your accreditation requirements which could result in your accreditation being cancelled. You have seven (7) days to PROVIDE/UPLOAD evidence of a replacement complaints mechanism before the Practitioner Accreditation Unit will contact you in regards to why your accreditation should not be cancelled.`;
export const COMPLAINTS_MECHANISM_ALLREMOVED_P2 = `If you have made a mistake, discard your changes on the Review and Submit page, or return to your dashboard to add a new complaints mechanism.`;

// export const PERSONALDETAILS_ADDRESS_BLURB =
//    'Residential address is NOT to be your business address, and it will NOT be made public. You must supply one of Residential or Postal (you may also supply both).';
export const COMPLAINTS_MECHANISM_BLURB_PARAGRAPH1 = `The FDR Register must hold information about each 'outlet' through which practitioners provide services. If providing private FDR services, a practitioner's records must reflect the body that deals with complaints about that FDR service.`;
export const COMPLAINTS_MECHANISM_BLURB_PARAGRAPH2 = `If employed by an organisation funded by the Government, or on a panel of FDR providers, the organisation name must be recorded in the outlet information.`;
export const COMPLAINTS_MECHANISM_BLURB_PARAGRAPH3 = `Practitioners may have a private FDR service as well as employment or panel membership - each 'outlet' must be recorded on a practitioner's records prior to providing an FDR service.`;

export const COMPLAINTS_MECHANISM_BLURB_ADDCM_PARAGRAPH1 =
    'To comply with your obligations as an FDR practitioner, you must maintain accurate records regarding the complaint mechanism and insurance you use for your FDR service(s).';
export const COMPLAINTS_MECHANISM_BLURB_ADDCM_PARAGRAPH2 = `Private FDR practitioners can only have one 'outlet' for their private service, whereas the records of practitioners employed by funded organisations must reflect all funded organisations through which they provide FDR services.`;

export const PROFESSIONALINDEMNITYINSURANCE_INFO =
    'If you apply for accreditation but are not yet covered by an insurance policy at the time of accreditation but you meet the accreditation criteria in all other ways, you may be eligible for conditional accreditation.';

export const CHANGEOFNAME_BLURB_PARAGRAPH1_PRE = 'If you have uploaded any documents during your application that carry a name other than ';
export const CHANGEOFNAME_BLURB_PARAGRAPH1_POST =
    ' you will need to upload evidence that shows a legal change of name, such as an official marriage certificate or change of name document.  If you do not provide this evidence, processing of your application will be delayed.';
export const CHANGEOFNAME_BLURB_PARAGRAPH2 = 'If all of the documents uploaded are in the same name, you can skip this step by clicking the "Skip" button.';

export const DECLARATION_PARAGRAPH1 =
    'To the best of my knowledge, the information included in my Application for Accreditation as a Family Dispute Resolution Practitioner, is accurate and complete.';
export const DECLARATION_PARAGRAPH2 =
    'The documents I have provided in support of my application are authentic and where copied, are true copies of the originals.';
export const DECLARATION_PARAGRAPH3 =
    'I have suitable personal qualities to perform the functions and duties of a Family Dispute Resolution practitioner. Examples include (but are not limited to) having good character; being regarded as honest and fair; having appropriate standing in the community and having the ability to fulfil obligations under legislation relevant to the provision of family dispute resolution services.';
export const DECLARATION_PARAGRAPH4 =
    'I have not engaged in conduct that would diminish public confidence in, or negatively impact on, the public reputation of Family Dispute Resolution practitioners, or engaged in conduct that is likely to bring family dispute resolution into disrepute.';
export const DECLARATION_PARAGRAPH5 =
    'I have provided all material information relevant to being accredited as a Family Dispute Resolution practitioner. ("Material information" is anything relevant to the application, such as disclosure of previous fines or convictions for dishonesty offences, professional misconduct, or matters related to the character of a person etc).';
export const DECLARATION_PARAGRAPH6 =
    'Once accredited, I accept that my accreditation may be suspended or cancelled if I am found not to be meeting any obligation or condition imposed on accredited Family Dispute Resolution practitioners.';

export const MODALHEADER_DISCARDCHANGE = 'Discard change';
export const MODALTEXT_DISCARDCHANGE_P1 =
    'Your change will be removed.  You can modify this change by clicking No on this page and using the "Revise complaints mechanism(s) and insurance changes" link on the Review and submit page.';
export const MODALTEXT_DISCARDCHANGE_P2 = 'Are you sure you want to discard this change?';

export const MODALHEADER_DISCARDCHANGES = 'Discard changes';
export const MODALTEXT_DISCARDCHANGES_EDITPERSONALANDCM =
    'You are about to discard changes to any and all of Personal Details and Complaints Mechanism and Insurance changes including additions, removals, and updates and any uploaded documents. Are you sure?';
export const MODALHEADER_UNSAVEDCHANGES_DATALOSSPOTENTIAL = 'Unsaved changes';
export const MODALTEXT_UNSAVEDCHANGES_DATALOSSPOTENTIAL = 'Are you sure you want to exit? Your changes will be lost.';
export const MODALTEXT_UNSAVEDCHANGES_DATALOSSPOTENTIAL_ANDUPLOADEDFILES =
    'Are you sure you want to exit? Your changes will be lost and all uploaded files will be removed.';
export const MODALHEADER_DATALOSSPOTENTIAL = 'Confirm data loss';
export const MODALTEXT_DATALOSSPOTENTIAL =
    'The data and files you have already uploaded to support your previous choice are not compatible with the new selection you have made. This will result in the loss of data if you wish to proceed.';
export const MODALTEXT_DATALOSSPOTENTIAL_COMPETENCIES =
    'Changing your competencies and/or qualifications may require you to upload different supporting documentation. Document(s) selected for your previous competency/qualification will be removed and you will need to upload them again for the new selection, if relevant.';
export const MODALTEXT_DATALOSSPOTENTIAL_COMPETENCIES_P2 = 'OK - remove the document(s) and I will upload them again.';
export const MODALTEXT_DATALOSSPOTENTIAL_COMPETENCIES_P3 = `Cancel - don't change my competencies/qualifications or remove document(s).`;
export const MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE =
    'Changing your complaints mechanism type may require you to upload different documentation in later sections of your application. We need to remove any previously selected complaints mechanism and/or insurance documents. Please upload them again if they are still relevant.';
export const MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_P2 = 'OK - remove the document(s) and I will upload them again.';
export const MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_P3 = `Cancel - don't change my complaints mechanism or remove document(s).`;
export const MODALHEADER_DATACHANGEPOTENTIAL = 'Confirm change';
export const MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_ACCREDITED =
    'Making this change may require you to upload different documentation as you progress with your update. We need to remove any previously selected complaints mechanism and/or insurance documents. Please upload them again if they are relevant.';
export const MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_ACCREDITED_P2 = 'Ok - remove the document(s) and I will upload again.';
export const MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_ACCREDITED_P3 = `Cancel - don't change my complaints mechanism or remove document(s).`;
export const MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_ACCRED_TYPE =
    'Making this change may require you to upload different documentation as you progress with your update. We need to remove any previously selected complaints mechanism and/or insurance documents. Please upload them again if they are relevant.';
export const MODALTEXT_FILEUPLOADLOSSPOTENTIAL_SKIP =
    'You have selected one or more documents for upload. If you skip this step, these documents will be removed.';
export const MODALTEXT_FILEUPLOADLOSSPOTENTIAL_BACK =
    'You have selected one or more documents for upload. If you go back now, these documents will be removed.';
export const MODALBUTTON1_DATALOSSREVERT = 'Revert my choice, and do not remove any data';
export const MODALBUTTON2_DATALOSSPROCEED = 'Proceed, and remove my prior data';
export const MODALBUTTON1_YES = 'Yes';
export const MODALBUTTON2_NO = 'No';
export const MODALBUTTON1_OK = 'OK';
export const MODALBUTTON2_CANCEL = 'Cancel';
export const MODALBUTTON_YES_DISCARDCHANGES = 'Yes discard changes';
export const MODALBUTTON_NO_DISCARDCHANGES = 'No keep changes';

export const MODALHEADER_SUBMITAPPLICATION_OK = 'Application submitted';
export const MODALTEXT_SUBMITAPPLICATION_OK = 'Your application to be an FDR practitioner has been submitted.';
export const MODALTEXT_SUBMITAPPLICATION_OK_P2 = 'You will receive an email confirmation of receipt of your application shortly.';
export const MODALHEADER_RESUBMITAPPLICATION_OK = 'Application resubmitted';
export const MODALTEXT_RESUBMITAPPLICATION_OK =
    'Updates to your application to be an FDR practitioner have been submitted. You will receive an email confirmation of receipt of your updates shortly.';
export const MODALHEADER_SUBMITAPPLICATION_ERROR = 'Application submitted - error!';
export const MODALTEXT_SUBMITAPPLICATION_ERROR = `Your application to be an FDR practitioner has been submitted, however there has been an error. Please contact the FDR Practitioner Accreditation Unit on ${DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER}.`;
export const MODALHEADER_SUBMITAPPLICATION_TIMEOUT = 'Application submitted - please check...';
export const MODALHEADER_RESUBMITAPPLICATION_TIMEOUT = 'Application resubmitted - please check...';
export const MODALTEXT_SUBMITAPPLICATION_TIMEOUT_P1 = `It's taken longer than usual to submit your application. Please return to the dashboard in a few minutes and check that your Application status shows "submitted" and your Accreditation status shows "pending".`;
export const MODALTEXT_SUBMITAPPLICATION_TIMEOUT_P2 = `If these statuses are not shown please try to submit your application again, and if the Application status is not showing as submitted, please contact us at ${DISPLAY_FDDR_PAU_CONTACT_EMAIL}  Please quote your application number in your email.`;
export const MODALTEXT_RESUBMITAPPLICATION_TIMEOUT_P1 = `It's taken longer than usual to resubmit your application. Please return to the dashboard in a few minutes and check that your Application status shows "resubmitted" and your Accreditation status shows "pending".`;
export const MODALTEXT_RESUBMITAPPLICATION_TIMEOUT_P2 = `If these statuses are not shown please try to resubmit your application again, and if the Application status is not showing as resubmitted, please contact us at ${DISPLAY_FDDR_PAU_CONTACT_EMAIL}  Please quote your application number in your email.`;
export const MODALHEADER_PRESUBMITAPPLICATION_ERROR = 'Application NOT submitted - error!';
export const MODALTEXT_PRESUBMITAPPLICATION_ERROR =
    'Your application to be an FDR practitioner has NOT been submitted, there has been an error. Please try again and then contact us.';
export const MODALHEADER_RESUBMITAPPLICATION_ERROR = 'Application resubmitted - error!';
export const MODALTEXT_RESUBMITAPPLICATION_ERROR = `Your application to be an FDR practitioner has been resubmitted, however there has been an error. Please contact the FDR Practitioner Accreditation Unit on ${DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER}.`;
export const MODALHEADER_WITHDRAW_APPLICATION_PROMPT = 'Withdraw Application';
export const MODALTEXT_WITHDRAW_APPLICATION_PROMPT = 'You are withdrawing your application for accreditation as an FDR practitioner.';
export const MODALTEXT_WITHDRAW_APPLICATION_P2_PROMPT = 'Are you sure?';
export const MODALHEADER_WITHDRAW_APPLICATION_REQUEST = 'Withdraw Application request';
export const MODALTEXT_WITHDRAW_APPLICATION_REQUEST = 'Your request to withdraw your application has been received.';
export const MODALTEXT_WITHDRAW_APPLICATION_P2_REQUEST = 'You will receive confirmation when the withdrawal of your application is complete.';
export const MODALHEADER_DISCARD_APPLICATION = 'Discard Application';
export const MODALTEXT_DISCARD_APPLICATION =
    'You have chosen to discard your application. This operation will permanently delete your application and it will not be recoverable, however discarding it will enable you to start a new application if you wish.';
export const MODALTEXT_DISCARD_APPLICATION_P2 = 'Are you sure you want to discard it?';
export const MODALHEADER_UPDATEOUTLET_OK = 'Update successful';
export const MODALHEADER_UPDATEOUTLET_OK_NOREVIEW = 'The updates to your Outlet details have been made and will be reflected on the public register.';
export const MODALHEADER_UPDATEOUTLET_OK_NEEDSREVIEW =
    'Your Outlet detail changes have been submitted for review. No further changes will be possible until you are notified that the review is complete.';
export const MODALHEADER_PROFINDEMNITYINSURANCE_WARNING_LEGALAIDPANELLISTS = 'Evidence of insurance missing';
export const MODALTEXT_PROFINDEMNITYINSURANCE_WARNING_LEGALAIDPANELLISTS = `External legal aid panellists must provide evidence of having professional indemnity insurance cover in order to deliver FDR services.  If you are unsure about how to proceed, please contact the Practitioner Accreditation Unit by email to ${DISPLAY_FDDR_PAU_CONTACT_EMAIL} or by phone on ${DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER}.`;
export const MODALHEADER_CHANGEOFNAME_FILEMISSING = 'Documentation check';
export const MODALTEXT_CHANGEOFNAME_FILEMISSING = 'You have not uploaded any change of name documents.  Do you wish to continue?';
export const MODALHEADER_ROOMS_TOBE_ARRANGED = 'Location based searches';
export const MODALTEXT_ROOMS_TOBE_ARRANGED =
    'As you have selected rooms to be arranged, you can still provide a suburb, state and postcode which may help people find you in location based searches.';
export const MODALHEADER_UPDATEDETAILS_OK = 'Update successful';
export const MODALTEXT_UPDATEPERSONALDETAILS_OK_NOREVIEW = 'Your changes have been saved but must be submitted to take effect.';
export const MODALTEXT_UPDATEPERSONALDETAILS_OK_NEEDSREVIEW =
    'Your changes have been saved but must be submitted for review by the Practitioner Accreditation Unit to take effect. If you wish to make other changes, click the Back to dashboard button.';
export const MODALTEXT_UPDATECOMPLAINTSDETAILS_OK_NOREVIEW = 'Your changes have been saved but must be submitted to take effect.';
export const MODALTEXT_UPDATECOMPLAINTSDETAILS_OK_NEEDSREVIEW_BACKTOLANDING =
    'Your changes have been saved but must be submitted for review by the Practitioner Accreditation Unit to take effect. If you wish to make other complaints mechanism or insurance changes, click the Back to Manage Complaints Mechanism(s) and Insurance button.';
export const MODALTEXT_UPDATECOMPLAINTSDETAILS_OK_NEEDSREVIEW_BACKTODASHBOARD =
    'Your changes have been saved but must be submitted for review by the Practitioner Accreditation Unit to take effect. If you wish to make other changes, click the Back to dashboard button.';
export const MODALHEADER_CONFIRM_ADD_CM = 'Confirm add';
export const MODALTEXT_CONFIRM_ADD_CM_P1 =
    'You are about to add a new outlet. You should only do this if you can’t see your relevant complaints mechanism and associated insurance on this page.';
export const MODALTEXT_CONFIRM_ADD_CM_P2 = 'Are you sure you want to add a new outlet?';
export const MODALHEADER_DELETE_OUTLET = 'Remove outlet details';
export const MODALTEXT_DELETE_OUTLET = 'You are about to remove all of your outlet details from the public FDR Register. Your name will still be visible.';
export const MODALTEXT_DELETE_OUTLET_P2 = 'Are you sure you want to remove your outlet details?';
export const MODALHEADER_CONFIRM_ACTION = 'Confirm action';
export const MODALTEXT_UPDATEMEMBERSHIPDETAILS_WARNING_P1 =
    'Renewing your membership details is a two step process. You will also need to confirm your insurance even if it has not changed or expired.';
export const MODALTEXT_UPDATEMEMBERSHIPDETAILS_WARNING_P2 = 'Do you need to make changes to your membership details?';
export const MODALHEADER_EDITING_NOTALLOWED = 'Editing not allowed';
export const MODALTEXT_EDITING_PREVENTED_RELATIONSHIP_EMPLOYEE =
    'You cannot edit this complaints mechanism. If you no longer work for this organisation please add a new complaints mechanism using the Add a new Complaints mechanism button and then remove this one.';
export const MODALTEXT_EDITING_PREVENTED_RELATIONSHIP_ONPANEL_P1 =
    'You cannot edit this complaints mechanism. If you only need to update your insurance, click the OK button and then use the Update insurance button.';
export const MODALTEXT_EDITING_PREVENTED_RELATIONSHIP_ONPANEL_P2 =
    'If you no longer work for this organisation please add a new complaints mechanism using the Add a new Complaints mechanism button and then remove this one.';

export const MODALHEADER_REMOVEINSURANCE_FROMPRIVATECM = 'Insurance cannot be removed';
export const MODALTEXT_REMOVEINSURANCE_FROMPRIVATECM_P1 =
    'If you no longer have insurance to deliver FDR services privately, remove the complaints mechanism which will also remove the insurance and any public information about your service.';
export const MODALTEXT_REMOVEINSURANCE_FROMPRIVATECM_P2 = `For assistance please contact the Practitioner Accreditation Unit by phone on ${DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER}.`;

export const MODALHEADER_CHANGEPRIMARYEMAIL = 'Change primary email';
export const MODALTEXT_CHANGEPRIMARYEMAIL =
    'Your primary email address is the same as your AGD account username and must be changed in another system. You will be redirected there shortly. As you are already signed into your FDR account with your current email address, any change to your primary email address will not be visible until the next time you sign into your account.';
export const MODALHEADER_REMOVE_COMPLAINTSMECHANISM = 'Remove complaints mechanism and insurance';
export const MODALTEXT_REMOVE_COMPLAINTSMECHANISM_P1 =
    'Your complaints mechanism and professional indemnity insurance are linked. By removing this complaints mechanism, you will also remove the associated insurance.';
export const MODALTEXT_REMOVE_COMPLAINTSMECHANISM_P2 = 'Are you sure you want to remove this complaints mechanism?';
export const MODALHEADER_REMOVE_INSURANCE = 'Remove insurance';
export const MODALTEXT_REMOVE_INSURANCE_P1 = 'Removing your insurance will delete your outlet details from the public FDR Register.';
export const MODALTEXT_REMOVE_INSURANCE_P2 =
    'If you proceed without insurance, your accreditation may become conditional and you cannot practice FDR until you provide evidence of having insurance. Your name may also be removed from the public FDR Register.';
export const MODALTEXT_REMOVE_INSURANCE_P3 = 'Are you sure you want to remove your insurance?';
export const MODALHEADER_NOINSURANCE_TOREMOVE = 'No insurance to remove';
export const MODALTEXT_NOINSURANCE_TOREMOVE = 'You cannot use this button as you do not have any insurance to remove.';
export const MODALHEADER_PRIVATEINSURANCE_CANNOTREMOVE = 'Insurance cannot be removed';
export const MODALTEXT_PRIVATEINSURANCE_CANNOTREMOVE_P1 =
    'If you no longer have insurance to deliver FDR services privately, remove the complaints mechanism which will also remove the insurance and any public information about your service.';
export const MODALTEXT_PRIVATEINSURANCE_CANNOTREMOVE_P2 = `For assistance please contact the Practitioner Accreditation Unit by phone on ${DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER}.`;

export const MODALLHEADER_MAX_COMPLAINTS = 'Cannot add Complaint Mechanism';
export const MODALTEXT_MAX_COMPLAINTS_P1 = `You have reached the maximum allowed complaints mechanisms.`;
export const MODALTEXT_MAX_COMPLAINTS_P2 = `Please contact the FDR Practitioner Accreditation Unit on ${DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER}.`;
export const MODALHEADER_CHANGES_PREVENTED_COMPLAINTS_INVALID = 'Submit prevented!';
export const MODALTEXT_CHANGES_PREVENTED_COMPLAINTS_INVALID =
    'The changes cannot be submitted as there is at least one invalid change (as indicated). Please either fix the problem or discard all changes, before attempting submit again.';
export const MODALHEADER_SUSPEND_ACCREDITATION = 'Request suspension of accreditation';
export const MODALTEXT_SUSPEND_ACCREDITATION_P1 = 'A request for suspension will be sent to the Practitioner Accreditation Unit.';
export const MODALTEXT_SUSPEND_ACCREDITATION_P2 =
    'While your accreditation is suspended, you will not be eligible to provide FDR services or issue s60I certificates. You should also not promote yourself as an FDR practitioner until the suspension has been lifted.';
export const MODALTEXT_SUSPEND_ACCREDITATION_P3 = 'Are you sure you want to suspend your accreditation?';
export const MODALHEADER_CANCEL_ACCREDITATION = 'Request cancellation of accreditation';
export const MODALTEXT_CANCEL_ACCREDITATION_P1 = 'A request for cancellation will be sent to the Practitioner Accreditation Unit.';
export const MODALTEXT_CANCEL_ACCREDITATION_P2 = 'Are you sure you want to cancel your accreditation?';

export const PersonalDetailsTitleList = [
    { value: '', text: '' },
    { value: 'Mr', text: 'Mr' },
    { value: 'Mrs', text: 'Mrs' },
    { value: 'Ms', text: 'Ms' },
    { value: 'Miss', text: 'Miss' },
    { value: 'Dr', text: 'Dr' },
    { value: 'Prof', text: 'Prof' },
];

export const PersonalDetailsGenderList = [
    { value: '', text: '' },
    { value: 'F', text: 'Female' },
    { value: 'M', text: 'Male' },
    { value: 'X', text: 'Indeterminate/Intersex/Unspecified' },
    { value: 'P', text: `I'd prefer not to say` },
];

export const AustralianStatesList = [
    { value: '', text: 'Select state...' },
    { value: 'ACT', text: 'ACT' },
    { value: 'NSW', text: 'NSW' },
    { value: 'NT', text: 'NT' },
    { value: 'QLD', text: 'QLD' },
    { value: 'SA', text: 'SA' },
    { value: 'TAS', text: 'TAS' },
    { value: 'VIC', text: 'VIC' },
    { value: 'WA', text: 'WA' },
];
