import React from 'react';
import { Modal } from 'react-bootstrap';
import { spinnerIcon } from '../utils/Icons';
import style from './Controls.module.scss';

const { loaderTextStyle } = style;

interface LoaderProps {
    isLoading: boolean;
    loaderText: string;
}

export const Loader = (props: LoaderProps) => {
    const { isLoading, loaderText } = props;

    return (
        <Modal animation={false} show={isLoading} centered={true} backdrop='static' keyboard={false}>
            <Modal.Body>
                <p className='text-center mt-3 font-weight-bold'>
                    {spinnerIcon()}
                    <span className={loaderTextStyle}>{loaderText}</span>
                </p>
            </Modal.Body>
        </Modal>
    );
};

export default Loader;
