import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { formatDateForDisplay } from '../utils/Dates';
import { DisplayGender } from '../utils/EnumMappings';
import { Inquiry } from '../api/interfacesApi';
import LabelField from '../components/fields/LabelField';
import { EmptyRow } from '../controls/EmptyRow';

import style from './fieldLayout.module.scss';
import { AttachmentDocumentType } from 'services/Enums';
import FileUploadDisplay from 'pages/Registration/ReviewAndSubmit/fileUploadDisplay';

const { altRow } = style;

interface PersonalDetailsNameDiffProps {
    inquiry: Inquiry;
    formatForPDF?: boolean;
}

const PersonalDetailsNameDiff = (props: PersonalDetailsNameDiffProps) => {
    const { inquiry, formatForPDF } = props;

    if (inquiry.differencesUser === undefined || inquiry.differencesUser === null) {
        return null;
    }

    if (formatForPDF === true) {
        return (
            <>
                <table data-testid='displayedPDList' className='list-group list-group-flush'>
                    <tr>
                        <th style={{ width: '33%', textAlign: 'left', paddingRight: '2rem' }}></th>
                        <th style={{ width: '33%', textAlign: 'left', marginRight: '0.5rem', paddingRight: '2rem' }}>Changed from</th>
                        <th style={{ width: '34%', textAlign: 'left' }}>Changed to</th>
                    </tr>
                    <tr>
                        <td>
                            <b>First name(s)</b>
                        </td>
                        <td>{inquiry.differencesUser.originalVersion.firstName}</td>
                        <td>{inquiry.differencesUser.differences.firstName}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Middle name(s)</b>
                        </td>
                        <td>{inquiry.differencesUser.originalVersion.middleName}</td>
                        <td>{inquiry.differencesUser.differences.middleName}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Preferred name(s)</b>
                        </td>
                        <td>{inquiry.differencesUser.originalVersion.preferredName}</td>
                        <td>{inquiry.differencesUser.differences.preferredName}</td>
                    </tr>
                    <tr className={altRow}>
                        <td>
                            <b>Family name</b>
                        </td>
                        <td>{inquiry.differencesUser.originalVersion.familyName}</td>
                        <td>{inquiry.differencesUser.differences.familyName}</td>
                    </tr>
                    <tr className={altRow}>
                        <td>
                            <b>Date of birth</b>
                        </td>
                        <td>{formatDateForDisplay(inquiry.differencesUser.originalVersion.dateOfBirth)}</td>
                        <td>{formatDateForDisplay(inquiry.differencesUser.differences.dateOfBirth)}</td>
                    </tr>
                </table>
                <EmptyRow />
                <FileUploadDisplay
                    inquiry={inquiry}
                    showThisDocType={AttachmentDocumentType.EvidenceOfNameChange}
                    title='Files uploaded as evidence of name change'
                    formatForPDF={formatForPDF}
                />
                <FileUploadDisplay
                    inquiry={inquiry}
                    showThisDocType={AttachmentDocumentType.EvidenceOfBirth}
                    title='Files uploaded as evidence of date of birth change'
                    formatForPDF={formatForPDF}
                />
            </>
        );
    }

    return (
        <>
            <EmptyRow />
            <Row>
                <Col />
                <Col>
                    <LabelField
                        id='changedFromLabel'
                        displayName='Changed from'
                        value=''
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                        isDisplayNameFontSizeLarger={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='changedToLabel'
                        displayName='Changed to'
                        value=''
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                        isDisplayNameFontSizeLarger={true}
                    />
                </Col>
            </Row>
            <Row className={altRow}>
                <Col>
                    <LabelField
                        id='reviewPersonalNameTitleLabel'
                        displayName='Title'
                        value=''
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalNameTitleOrig'
                        displayName=''
                        value={inquiry.differencesUser.originalVersion.title}
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalNameTitleDiff'
                        displayName=''
                        value={inquiry.differencesUser.differences.title}
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <LabelField
                        id='reviewPersonalNameFirstnameLabel'
                        displayName='First name(s)'
                        value=''
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalNameFirstnameOrig'
                        displayName=''
                        value={inquiry.differencesUser.originalVersion.firstName}
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalNameFirstnameDiff'
                        displayName=''
                        value={inquiry.differencesUser.differences.firstName}
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
            </Row>
            <Row className={altRow}>
                <Col>
                    <LabelField
                        id='reviewPersonalNamePreferrednameLabel'
                        displayName='Preferred name(s)'
                        value=''
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalNamePreferrednameOrig'
                        displayName=''
                        value={inquiry.differencesUser.originalVersion.preferredName}
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalNamePreferrednameDiff'
                        displayName=''
                        value={inquiry.differencesUser.differences.preferredName}
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <LabelField
                        id='reviewPersonalNameMiddlenameLabel'
                        displayName='Middle name(s)'
                        value=''
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalNameMiddlenameOrig'
                        displayName=''
                        value={inquiry.differencesUser.originalVersion.middleName}
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalNameMiddlenameDiff'
                        displayName=''
                        value={inquiry.differencesUser.differences.middleName}
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
            </Row>
            <Row className={altRow}>
                <Col>
                    <LabelField
                        id='reviewPersonalNameFamilynameLabel'
                        displayName='Family name'
                        value=''
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalNameFamilynameOrig'
                        displayName=''
                        value={inquiry.differencesUser.originalVersion.familyName}
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalNameFamilynameDiff'
                        displayName=''
                        value={inquiry.differencesUser.differences.familyName}
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <LabelField
                        id='reviewPersonalNameGenderLabel'
                        displayName='Gender'
                        value=''
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalNameGenderOrig'
                        displayName=''
                        value={DisplayGender(inquiry.differencesUser.originalVersion.gender)}
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalNameGenderDiff'
                        displayName=''
                        value={DisplayGender(inquiry.differencesUser.differences.gender)}
                        isMandatory={false}
                        textCapitalizeOn={true}
                        hideFinalEmptyRow={true}
                    />
                </Col>
            </Row>
            <Row className={altRow}>
                <Col>
                    <LabelField id='reviewPersonalNameDateofbirthLabel' displayName='Date of birth' value='' isMandatory={false} hideFinalEmptyRow={true} />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalNameDateofbirthOrig'
                        displayName=''
                        value={formatDateForDisplay(inquiry.differencesUser.originalVersion.dateOfBirth)}
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                    />
                </Col>
                <Col>
                    <LabelField
                        id='reviewPersonalNameDateofbirthDiff'
                        displayName=''
                        value={formatDateForDisplay(inquiry.differencesUser.differences.dateOfBirth)}
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                    />
                </Col>
            </Row>
        </>
    );
};

export default PersonalDetailsNameDiff;
