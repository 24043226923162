import React from 'react';
import UserApi from '../../api/userApi';
import { IUser } from '../../api/interfacesApi';
import { toastErrorMessage, toastSuccessMessage } from '../../actions/toastrMessages';
import { Sleep } from '../../utils/Common';
import { FormatApplicationNumber } from '../../utils/AppUtils';

export const RedirectToDashboard = () => {
    Sleep(1000).then(() => {
        window.location.href = '/';
    });
};

export const GetInputRadioButton = (onChangeEvent: any, applicationId: string, personalDetails: IUser, zNumber: number, applicationStatus: string) => {
    const fullName = `${personalDetails.firstName} ${personalDetails.familyName}`;
    const itemId = `applicationClaimProfile${applicationId}`;

    return (
        <>
            <input type='radio' id={itemId} key={applicationId} name='applicationClaimProfile' value={personalDetails.id} onChange={onChangeEvent} />
            <label htmlFor={applicationId}>
                &nbsp;&nbsp;Application ({FormatApplicationNumber(zNumber)}) - {fullName} - {applicationStatus}
            </label>
        </>
    );
};

export const DeleteAllMyData = (userApi: UserApi) => {
    userApi
        .deleteAllUserData()
        .then(() => {
            toastSuccessMessage('ALL of YOUR data has been removed!');
            RedirectToDashboard();
        })
        .catch(err => {
            toastErrorMessage('Could not remove the data! Error:' + err);
        });
};
