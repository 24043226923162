// import React from 'react';
/*
interface IAGWebValidationError<T> {
    errors: T[];
    message: string;
}*/

class AGWebValidationError {
    errors: [] = [];
    message: string = '';
    isValid: boolean = false;

    setMessage(inputValue: string) {
        this.message = inputValue;
    }
    setErrors(inputValue: []) {
        this.errors = inputValue;
    }
    setIsValid(inputValue: boolean) {
        this.isValid = inputValue;
    }
}

export default AGWebValidationError;
