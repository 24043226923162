import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import RadioGroup from '../../controls/RadioGroup';
import GuidanceText from '../../controls/GuidanceText';
import HelpText from '../../controls/HelpText';
import MandatoryLabelIndicator from './MandatoryLabelIndicator';
import stylesFields from './Fields.module.scss';

const { fieldTitle, fieldValidationError, validationErrorRow, fieldFinalRow } = stylesFields;

export interface RadioButtonOption {
    label: string;
    value: any;
    defaultValue?: any; // this is either on or off (or undefined)
    disabled: boolean;
    children?: any;
    helpHeaderText?: string;
    helpText?: string;
    navLinkText?: string;
    navLinkUrl?: string;
}

// leverages: https://learnersbucket.com/examples/react/create-radio-group-component-in-react/
export interface RadioButtonGroupFieldProps {
    id: string;
    displayName: string;
    fieldLabel: string;
    options: RadioButtonOption[];
    defaultValue?: any; // this is either on or off (or undefined)
    control: any;
    isMandatory: boolean;
    register: any;
    errorsField: any;
    autoFocus?: boolean;
    guidanceText?: string;
    helpHeaderText?: string;
    helpText?: string;
    onChange: any;
}

const RadioButtonGroupField = (props: RadioButtonGroupFieldProps) => {
    const {
        id,
        displayName,
        fieldLabel,
        options,
        defaultValue,
        control,
        isMandatory,
        // register,
        errorsField,
        guidanceText,
        helpHeaderText,
        helpText,
        onChange,
    } = props;

    return (
        <>
            <Row>
                <Col>
                    <span className={fieldTitle}>
                        {isMandatory && <MandatoryLabelIndicator />}
                        {displayName}
                    </span>
                </Col>
            </Row>
            {guidanceText && (
                <Row>
                    <Col>
                        <GuidanceText guidanceText={guidanceText} fieldName={id} />
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Controller
                        // tslint:disable-next-line: jsx-no-lambda
                        render={({ field: { ref, ...rest }, fieldState, formState }) => (
                            <RadioGroup {...rest} id={id} defaultValue={defaultValue} fieldLabel={fieldLabel} name={id} onChange={onChange} options={options} />
                        )}
                        name={id}
                        control={control}
                        defaultValue={defaultValue}
                    />
                </Col>
            </Row>
            <Row>
                <Col className={fieldValidationError}>{errorsField && <span role='alert'>{errorsField.message}</span>}</Col>
            </Row>
            <Row className={validationErrorRow}>
                <Col className={fieldValidationError}>{errorsField && errorsField.validate && <span role='alert'>{errorsField.validate.message}</span>}</Col>
            </Row>
            {helpHeaderText && (
                <Row>
                    <Col>
                        <HelpText helpHeaderText={helpHeaderText} helpText={helpText} fieldName={id} />
                    </Col>
                </Row>
            )}
            <Row className={fieldFinalRow}>
                <Col />
            </Row>
        </>
    );
};

export default RadioButtonGroupField;
