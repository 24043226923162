// Suspend Accreditation page
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import ApplicationActions from '../../../actions/applicationActions';
import { toastErrorMessage, toastSuccessMessage } from '../../../actions/toastrMessages';
import AuthService from '../../../services/AuthService';
import {
    MODALHEADER_SUSPEND_ACCREDITATION,
    MODALTEXT_SUSPEND_ACCREDITATION_P1,
    MODALTEXT_SUSPEND_ACCREDITATION_P2,
    MODALTEXT_SUSPEND_ACCREDITATION_P3,
    UNINITIALISED,
} from '../../../services/Constants';
import { ApplicationStatusEnum } from '../../../services/Enums';
import { VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../../services/Validation';
import { Sleep } from '../../../utils/Common';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import PageWrapper from '../../../components/pageWrapper';
import PageTitle from '../../../components/pageTitle';
import Loader from '../../../controls/Loader';
import ModalDialog from '../../../controls/ModalDialog';

interface SuspendAccreditationPageProps {
    authService: AuthService;
}

const SuspendAccreditationPage = (props: SuspendAccreditationPageProps) => {
    const { authService } = props;
    const history = useHistory();
    const application = useSelector((state: any) => state.application);
    const dispatch = useDispatch();
    const [showSuspendDialog, setShowSuspendDialog] = useState(false);
    const [attemptingSuspendRequest, setAttemptingSuspendRequest] = useState(false);

    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);

    useEffect(() => {
        if (application && application.applicationId !== UNINITIALISED && application.applicationStatus === ApplicationStatusEnum.PendingSuspension) {
            toastSuccessMessage('Suspend request submitted successfully.');

            setAttemptingSuspendRequest(false);

            Sleep(800).then(() => {
                redirectToDashboard();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application]);

    const showSuspendDialogModal = () => {
        setShowSuspendDialog(true);
    };

    const handleSuspendAccreditation = () => {
        setShowSuspendDialog(false);
        setAttemptingSuspendRequest(true);
        try {
            application.applicationStatus = ApplicationStatusEnum.PendingSuspension; // API will set this most likely, just for safety
            dispatch(appActions.SuspendApplication(application));
        } catch {
            setAttemptingSuspendRequest(false);
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    const handleClickBackToDashboard = () => {
        redirectToDashboard();
    };

    const redirectToDashboard = () => {
        history.push('/');
    };
    const { handleSubmit } = useForm();

    return (
        <>
            <PageTitle title='Suspend accreditation' description='' />
            <PageWrapper
                pageName='SuspendAccreditation'
                handleSubmit={handleSubmit(showSuspendDialogModal)}
                handleClickPrevious={handleClickBackToDashboard}
                textForSaveContinueButton='Request suspension'
                textForBackButton='Back'
            >
                <Loader isLoading={attemptingSuspendRequest === true} loaderText='Submitting your suspend accreditation request. Please wait...' />
                <PageFieldsTitle title='Request to suspend accreditation' />
                <p>
                    You may request the suspension of your accreditation by clicking the "Request suspension" button on this page. Your request will be
                    processed by the Practitioner Accreditation Unit and you will receive confirmation of the suspension of your accreditation by email.
                </p>
            </PageWrapper>
            <ModalDialog
                dataTestId='SuspendDialog'
                modalTitle={MODALHEADER_SUSPEND_ACCREDITATION}
                modalBodyText={MODALTEXT_SUSPEND_ACCREDITATION_P1}
                modalBodyTextP2={MODALTEXT_SUSPEND_ACCREDITATION_P2}
                modalBodyTextP3={MODALTEXT_SUSPEND_ACCREDITATION_P3}
                showMe={showSuspendDialog}
                handleClickNo={handleClickBackToDashboard}
                handleClickYes={handleSuspendAccreditation}
            />
        </>
    );
};

export default SuspendAccreditationPage;
