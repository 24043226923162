import * as types from './actionTypes';
import UserApi from '../api/userApi';
import { IDifferences, IUser } from '../api/interfacesApi';
import { beginAjaxCall, ajaxCallError, endAjaxCall } from './ajaxStatusActions';
import { toastErrorMessage, toastSuccessMessage } from './toastrMessages';
import AuthService from '../services/AuthService';
import { UNINITIALISED } from '../services/Constants';
import { VALIDATION_DISCARD_OK_POPUP_MESSAGE, VALIDATION_SUCCESS_POPUP_MESSAGE } from '../services/Validation';
import { formatDateUTC } from '../utils/Dates';
import { Sleep } from '../utils/Common';

export const RedirectToDashboard = () => {
    Sleep(1000).then(() => {
        window.location.href = '/';
    });
};

class UserActions {
    userApi: UserApi;

    constructor(authService: AuthService) {
        this.userApi = new UserApi(authService);
    }

    GetUserSuccess(user: IUser) {
        return { type: types.GET_USER_SUCCESS, user };
    }

    GetUserEditCopySuccess(user: IUser) {
        return { type: types.GET_USER_EDITCOPY_SUCCESS, user };
    }

    SaveUserSuccess(user: IUser) {
        return { type: types.SAVE_USER_SUCCESS, user };
    }

    SaveUserGenderConsentOnlySuccess(user: IUser) {
        return { type: types.SAVE_USER_GENDERCONSENTONLY_SUCCESS, user };
    }

    GetChangesSuccess(differences: IDifferences<IUser>) {
        return { type: types.GET_USER_CHANGES_SUCCESS, differences };
    }

    DiscardChangesSuccess(user: IUser) {
        return { type: types.DISCARD_CHANGES_SUCCESS, user };
    }

    AssignToMeApplicationSuccess(user: IUser) {
        return { type: types.ASSING_TO_ME_APPLICATION_SUCCESS, user };
    }

    StartNewApplicationSuccess(user: IUser) {
        return { type: types.START_NEW_APPLICATION_SUCCESS, user };
    }

    GetUser() {
        const useCurrentUser = (dispatch: any) => {
            dispatch(beginAjaxCall());
            this.userApi
                .getUser()
                .then(user => {
                    dispatch(this.GetUserSuccess(user));
                    return user;
                })
                .catch(error => {
                    dispatch(ajaxCallError(error));
                    return null;
                })
                .finally(() => {
                    dispatch(endAjaxCall());
                });
        };
        return useCurrentUser;
    }

    GetUserEditCopy() {
        const useCurrentUser = (dispatch: any) => {
            dispatch(beginAjaxCall());
            this.userApi
                .getUserEditCopy()
                .then(user => {
                    dispatch(this.GetUserEditCopySuccess(user));
                    return user;
                })
                .catch(error => {
                    dispatch(ajaxCallError(error));
                    return null;
                })
                .finally(() => {
                    dispatch(endAjaxCall());
                });
        };
        return useCurrentUser;
    }

    ModifyDataForUpdate(user: IUser) {
        // remove Address if still UNINITIALISED
        if (user.addresses.length > 0) {
            user.addresses.forEach(address => {
                if (address.addressId === UNINITIALISED) {
                    user.addresses.pop(); // remove address as it is not yet populated
                }
            });
        }

        // Correct Date for storage into UTC
        if (formatDateUTC(user.dateOfBirth)) {
            user.dateOfBirth = formatDateUTC(user.dateOfBirth)!;
        }

        return user;
    }

    SaveUser = (user: IUser) => (dispatch: any) => {
        user = this.ModifyDataForUpdate(user);

        dispatch(beginAjaxCall());
        this.userApi
            .SaveUser(user)
            .then(returnUser => {
                dispatch(this.SaveUserSuccess(returnUser));
                toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);
                return returnUser;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    SaveUserGenderConsentOnly = (user: IUser) => (dispatch: any) => {
        user = this.ModifyDataForUpdate(user);

        dispatch(beginAjaxCall());
        this.userApi
            .SaveUserGenderConsentOnly(user)
            .then(returnUser => {
                dispatch(this.SaveUserGenderConsentOnlySuccess(returnUser));
                // toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);
                return returnUser;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    GetChanges() {
        const useDifferences = (dispatch: any) => {
            dispatch(beginAjaxCall());
            this.userApi
                .getChanges()
                .then(differences => {
                    dispatch(this.GetChangesSuccess(differences));
                    return differences;
                })
                .catch(error => {
                    dispatch(ajaxCallError(error));
                    return null;
                })
                .finally(() => {
                    dispatch(endAjaxCall());
                });
        };
        return useDifferences;
    }

    DiscardChanges = (user: IUser) => (dispatch: any) => {
        // Correct Date for storage into UTC
        if (formatDateUTC(user.dateOfBirth)) {
            user.dateOfBirth = formatDateUTC(user.dateOfBirth)!;
        }

        dispatch(beginAjaxCall());
        this.userApi
            .discardChanges(user)
            .then(returnUser => {
                dispatch(this.DiscardChangesSuccess(returnUser));

                toastSuccessMessage(VALIDATION_DISCARD_OK_POPUP_MESSAGE);
                return returnUser;
            })
            .catch(err => {
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    // DEV/TEST ONLY
    StartAnotherApplication = (user: IUser) => (dispatch: any) => {
        dispatch(beginAjaxCall());
        this.userApi
            .startNewApplication(user)
            .then(returnUser => {
                dispatch(this.StartNewApplicationSuccess(returnUser));
                toastSuccessMessage('Another application has been created!');
                RedirectToDashboard();
                return returnUser;
            })
            .catch(err => {
                toastErrorMessage('Could not start another application! Error:' + err);
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    // DEV/TEST ONLY
    AssignApplicationToMe = (user: IUser) => (dispatch: any) => {
        dispatch(beginAjaxCall());
        this.userApi
            .claimProfile(user)
            .then(returnUser => {
                dispatch(this.AssignToMeApplicationSuccess(returnUser));
                toastSuccessMessage('Application has been assigned to you!');
                RedirectToDashboard();
                return returnUser;
            })
            .catch(err => {
                toastErrorMessage('Could not assign to me! Error:' + err);
                dispatch(ajaxCallError(err));
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };
}

export default UserActions;
