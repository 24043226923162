import * as types from '../../actions/actionTypes';
import { IApplicationSubmit } from '../../api/interfacesApi';

const appSubmitInit: IApplicationSubmit = {
    htmlSummary: '',
    isResubmitted: false,
};

const applicationSubmit = (state = appSubmitInit, action: types.ApplicationActionTypes) => {
    let appSubmit: IApplicationSubmit;

    switch (action.type) {
        case types.SUBMIT_CHANGES_SUCCESS:
            appSubmit = { ...action.applicationSubmit };
            return appSubmit;

        default:
            return state;
    }
};

export default applicationSubmit;
