import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { WizardStepNumber } from '../../services/Enums';
import NavLink from '../../controls/NavLink';
import AppContext from '../../stateManagement/context/AppContext';
import { backToIcon } from '../../components/wizard/wizardMenuIcons';
import { handleSectionClick } from '../../pages/PageFunctions';
import { GetActiveWizardStepIndex } from '../wizard/wizardStepFunctions';
import styles from './Fields.module.scss';

const { pageFieldsTitle, reviewPageNavLinkColumn } = styles;

export interface LinkToPageProps {
    className: string;
    wizardStepNumber?: WizardStepNumber;
    displayText?: string; // override to NOT use orig Wizard
    pageLink?: string; // override to NOT use orig Wizard
}

const LinkToPage = (props: LinkToPageProps) => {
    const { className, wizardStepNumber, displayText, pageLink } = props;

    const appContext = useContext(AppContext);
    const history = useHistory();
    const destIndex = wizardStepNumber;
    const displayName = wizardStepNumber && appContext.wizardSteps[wizardStepNumber].displayName;

    const displayTextModded = displayText !== undefined ? displayText : `Update ${displayName}`;

    const handleNavClick = (e: any) => {
        if (wizardStepNumber !== undefined) {
            // adjust the wizard step by using the CurrentStep and moving to the destination index
            const currIndex = GetActiveWizardStepIndex(appContext.wizardSteps);
            handleSectionClick(currIndex, destIndex!, appContext, e);
        } else {
            // just go to page
            pageLink && history.push(pageLink);
        }
    };

    return (
        <>
            <Row className={pageFieldsTitle}>
                <Col className={reviewPageNavLinkColumn}>
                    {backToIcon()}
                    &nbsp;&nbsp;
                    <NavLink hrefLink='' displayText={displayTextModded} className={className} handleOnClick={handleNavClick} />
                </Col>
            </Row>
        </>
    );
};

export default LinkToPage;
