// Edit fields for Personal Details Contact
import React, { useEffect, useState } from 'react';
import { IAddress, Inquiry } from '../api/interfacesApi';
import {
    PLACEHOLDER_PRIMARY_PHONE,
    PLACEHOLDER_EMAIL,
    PLACEHOLDER_SECONDARY_PHONE,
    HELPHEADERTEXT_PERSONALDETAILS_PRIMARYEMAIL,
    HELPTEXT_PERSONALDETAILS_PRIMARYEMAIL,
} from '../services/Constants';
import AddressComponent from '../components/address/AddressComponent';
import InputTextField from '../components/fields/InputTextField';

interface PersonalDetailsContactFieldsEditProps {
    inquiry: Inquiry;
    register: any;
    control: any;
    errors: any;
    watch: any;
    isValid: any;
    setValue: any;
    trigger: any;
    displayPrimaryEmailNote?: boolean;
    isRework?: boolean;
}

const PersonalDetailsContactFieldsEdit = (props: PersonalDetailsContactFieldsEditProps) => {
    const { register, inquiry, control, errors, watch, isValid, setValue, trigger, displayPrimaryEmailNote, isRework } = props;

    const isReadOnly = isRework ?? false;
    // Store indexes as the actual item will reside in these (and they can be removed/added)
    const [indexes, setIndexes] = useState([0]); // default to 1 items, should mostly be residential and postal

    // wait for the above to load initial data then load the indexes
    useEffect(() => {
        if (inquiry && inquiry.personalDetails) {
            const numberOfAddresses = inquiry.personalDetails?.addresses?.length;
            if (numberOfAddresses > 1) {
                // take the number and create an array of indexes such as [0,1,2]
                setIndexes(Array.from({ length: numberOfAddresses }, (_, i) => i));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inquiry.personalDetails.addresses]);

    const primaryEmailHelpHeaderText = displayPrimaryEmailNote === true ? HELPHEADERTEXT_PERSONALDETAILS_PRIMARYEMAIL : '';
    const primaryEmailHelpText = displayPrimaryEmailNote === true ? HELPTEXT_PERSONALDETAILS_PRIMARYEMAIL : '';

    return (
        <>
            {indexes.map(index => {
                const fieldName = `inquiry.personalDetails.addresses[${index}]`;
                // get the item using [index] as filter() returns an array of n items!
                const currItem: IAddress = inquiry?.personalDetails.addresses[index]; // .filter((item: IAddress, idx: number) => idx === index);
                const errorFieldsThisIndex = errors.inquiry?.personalDetails?.addresses?.find((_element: any, i: number) => i === index);

                return (
                    <fieldset name={fieldName} key={fieldName}>
                        <legend className='sr-only'>Postal address details</legend>
                        <AddressComponent
                            key={fieldName}
                            currItem={currItem}
                            errorFieldsPrefix={errorFieldsThisIndex}
                            fieldName={fieldName}
                            register={register}
                            inquiry={inquiry}
                            control={control}
                            setValue={setValue}
                            isValid={isValid}
                            trigger={trigger}
                            watch={watch}
                            isReadOnly={isReadOnly}
                        />
                    </fieldset>
                );
            })}
            <InputTextField
                id='inquiry.personalDetails.emailAddress'
                displayName='Primary email'
                defaultValue={inquiry?.personalDetails.emailAddress}
                isMandatory={true}
                capitaliseTextOff={true}
                isReadOnly={true}
                register={register}
                // children={displayChangeEmailAddressButton()}
                errorsField={errors.inquiry?.personalDetails?.emailAddress}
                helpHeaderText={primaryEmailHelpHeaderText}
                helpText={primaryEmailHelpText}
            />
            <InputTextField
                id='inquiry.personalDetails.secondaryEmailAddress'
                displayName='Secondary email'
                defaultValue={inquiry?.personalDetails.secondaryEmailAddress}
                isReadOnly={isReadOnly}
                placeHolder={PLACEHOLDER_EMAIL}
                isMandatory={false}
                capitaliseTextOff={true}
                register={register}
                errorsField={errors.inquiry?.personalDetails?.secondaryEmailAddress}
            />
            <InputTextField
                id='inquiry.personalDetails.primaryPhoneNumber'
                displayName='Primary phone'
                defaultValue={inquiry?.personalDetails.primaryPhoneNumber}
                isReadOnly={isReadOnly}
                placeHolder={PLACEHOLDER_PRIMARY_PHONE}
                isMandatory={true}
                register={register}
                errorsField={errors.inquiry?.personalDetails?.primaryPhoneNumber}
            />
            <InputTextField
                id='inquiry.personalDetails.secondaryPhoneNumber'
                displayName='Secondary phone'
                defaultValue={inquiry?.personalDetails.secondaryPhoneNumber}
                isReadOnly={isReadOnly}
                placeHolder={PLACEHOLDER_SECONDARY_PHONE}
                isMandatory={false}
                register={register}
                errorsField={errors.inquiry?.personalDetails?.secondaryPhoneNumber}
            />
        </>
    );
};

export default PersonalDetailsContactFieldsEdit;
