import React from 'react';
import Guid from '../services/Guid';
import {
    AccreditationStatusEnum,
    ApplicationStatusEnum,
    AttachmentDocumentType,
    OutletStatusType,
    ReworkSectionType,
    WizardStepNumber,
} from '../services/Enums';
import {
    DISPLAY_FDDR_PAU_CONTACT_EMAIL,
    NOTE_UNSUBMITTED_CHANGES_CLICKHERETOREVIEW,
    NOTE_UNSUBMITTED_EXPIRY_WARNING,
    UNINITIALISED,
} from '../services/Constants';
import NavLink from '../controls/NavLink';
import NavLinkEmailPau from '../components/fields/NavLinkEmailPau';
import { backToIcon } from '../components/wizard/wizardMenuIcons';
import {
    IAccreditationConditionDto,
    IApplication,
    IAttachmentMetadata,
    IComplaintMechanism,
    IDifferences,
    Inquiry,
    InquiryDefaultObj,
    IUser,
} from '../api/interfacesApi';
import { PadLeadingZeros, Sleep } from './Common';
import { AppContextInterface } from '../stateManagement/context/AppContext';
import { handleBackToManageComplaints, handleDashboardClick, JumpToWizardStep } from '../pages/PageFunctions';
import {
    GetFirstWizardStepForReworkSections,
    MarkAllSectionsCompleteUpToDeclaration,
    MarkReworkSections,
    MarkStepIncompleteAndUpdateStatus,
} from '../components/wizard/wizardStepFunctions';
import ApplicationActions from '../actions/applicationActions';
import AttachmentActions from '../actions/attachmentsActions';
import {
    IsAnyComplaintMarkedForCancellation,
    IsAtLeastOneComplaintActive,
    IsAnyComplaintProfAssocMember,
    IsAnyComplaintMarkedForEditCopy,
    CountOfValidComplaintsMech,
} from './ComplaintsUtils';
import { toastErrorMessage } from '../actions/toastrMessages';
import { VALIDATION_ERROR_FILEREMOVED_TITLE, VALIDATION_ERROR_POPUP_MESSAGE, VALIDATION_SUCCESS_FILEDELETED_MESSAGE } from '../services/Validation';
import { Col, Row } from 'react-bootstrap';
import NoteText from '../controls/NoteText';
import { EmptyRow } from '../controls/EmptyRow';

export const ErrorOnSubmitForm = (_errorsIn: any, _event: any) => {
    toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
};

export function ErrorFallback({ error }: any) {
    return (
        <div role='alert' style={{ textAlign: 'center' }}>
            <h1 style={{ padding: '2rem' }}>Site error</h1>
            <h2 style={{ padding: '2rem' }}>Family Dispute Resolution Register</h2>
            <p>Something went wrong! Please try again soon.</p>
            <p>Our technicians will look into the problem and recover the site as fast as possible.</p>
            <pre style={{ color: 'red' }}>{error?.message}</pre>
        </div>
    );
}

export const OnClickUrlToNewTab = (url: string): void => {
    // noopener and noreferrer remove the potential risk using _blank
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
};

export const displayBackToPortalLink = (backToPortalClass: string, appContext: AppContextInterface, backLinkUrl?: string, backLinkText?: string) => {
    const uniqueKey = Guid.newGuid();

    const handleDashboardClickEvent = (e: any) => {
        if (backLinkUrl !== undefined && backLinkUrl !== '') {
            window.location.href = backLinkUrl;
        } else {
            handleDashboardClick(appContext, e);
        }
    };

    return (
        <li data-testid='BackToDashboardLink' key={uniqueKey.toString()} className={backToPortalClass} role='menuitem'>
            {backToIcon()}
            &nbsp;&nbsp;
            <NavLink
                dataTestId='link-backtodashboard'
                hrefLink={backLinkUrl ?? '/'}
                displayText={backLinkText ?? 'Back to dashboard'}
                className=''
                handleOnClick={handleDashboardClickEvent}
            />
        </li>
    );
};

export const displayBackToManageComplaintsLink = (
    backToPortalClass: string,
    appContext: AppContextInterface,
    throwEventToChildForm?: boolean,
    callChildFormEvent?: any,
) => {
    const uniqueKey = Guid.newGuid();
    const handleBackToManageComplaintsClickEvent = (e: any) => {
        if (throwEventToChildForm === true) {
            e?.preventDefault(); // MUST prevent going backwards until check has been made/changes discarded/no change
            callChildFormEvent();
        } else {
            handleBackToManageComplaints(appContext, e);
        }
    };
    return (
        <li data-testid='BackToManageComplaintsLink' key={uniqueKey.toString()} className={backToPortalClass} role='menuitem'>
            {backToIcon()}
            &nbsp;&nbsp;
            <NavLink
                dataTestId='link-backtomanagecomplaints'
                hrefLink='/managecomplaintsmechanism?reload=true'
                displayText='Back to Manage complaints mechanisms and insurance'
                className=''
                handleOnClick={handleBackToManageComplaintsClickEvent}
            />
        </li>
    );
};

// without using a regex here as there are version changes etc, just determine if our field is set or not
export function IsValidGuidIdentifier(guidId: string | undefined | null) {
    const guid = new Guid();
    return guidId !== undefined && guidId !== null && guidId !== '' && guidId !== UNINITIALISED && guidId !== guid.empty && guidId.length === 36;
}

// this is where we generate the Z number
export function FormatApplicationNumber(appNumber: number) {
    return 'Z' + PadLeadingZeros(appNumber, 7);
}
export function FormatRegistrationNumber(appNumber: number) {
    return 'F' + PadLeadingZeros(appNumber, 7);
}

// Used for optional fields on the review page
export const DisplayFieldValueOrDefaultText = (value?: string) => {
    if (value && value.length > 0) {
        return value;
    }
    return '(Not provided)';
};

// Display the contact PAU email as a link should it exist in the text as display text
export const DisplayTextWithContactPauEmailLink = (inputTextString: string, whiteTextOnBlueBackground?: boolean) => {
    try {
        const startIndexOfLinkText = inputTextString.indexOf(`${DISPLAY_FDDR_PAU_CONTACT_EMAIL}`);
        const preLinkText = inputTextString.substring(0, startIndexOfLinkText);
        const postLinkText = inputTextString.substring(startIndexOfLinkText + `${DISPLAY_FDDR_PAU_CONTACT_EMAIL}`.length);

        return (
            <>
                {preLinkText}
                <NavLinkEmailPau whiteTextOnBlueBackground={whiteTextOnBlueBackground} />
                {postLinkText}
            </>
        );
    } catch {
        // if it all goes belly up just return normal text without clickable link within <> tags
        return <>{inputTextString}</>;
    }
};

export function IsAnyAttachmentActiveAndGovtFundedForThisComplaint(
    complaintsMechanismId: string | undefined,
    attachments?: IAttachmentMetadata[] | undefined,
): boolean {
    let retValue = false;

    if (attachments !== undefined && attachments !== null && attachments.length > 0) {
        attachments.forEach((attachment: IAttachmentMetadata) => {
            if (
                attachment.isDeleted === false &&
                (attachment.documentType === AttachmentDocumentType.OrgEmployment || attachment.documentType === AttachmentDocumentType.OrgPanel) &&
                attachment.complaintsProcessId === complaintsMechanismId
            ) {
                retValue = true;
            }
        });
    }

    return retValue;
}

export function GetActiveAttachmentsByType(attachments: IAttachmentMetadata[], docType: AttachmentDocumentType): IAttachmentMetadata[] {
    if (!attachments || attachments.length <= 0) {
        return [];
    }

    const allActiveAttachmentsByType: IAttachmentMetadata[] = attachments.filter((item: IAttachmentMetadata) => {
        // there is a chance when used for complaints evidence that the isDeleted is not supplied thus is undefined
        if (item.documentType === docType && (item.isDeleted === undefined || item.isDeleted === false)) {
            return item;
        } else {
            return null;
        }
    });

    return allActiveAttachmentsByType;
}

export function IsAttachmentTypeInCollection(attachmentTypeToFind: AttachmentDocumentType, attachmentsToSearch: IAttachmentMetadata[] | null) {
    if (attachmentsToSearch === null || attachmentsToSearch.length === 0) {
        return false;
    }
    const attachments: IAttachmentMetadata[] = attachmentsToSearch.filter(att => att.documentType === attachmentTypeToFind);
    return attachments && attachments.length > 0;
}

export function IsAttachmentInCollection(attachmentIdToFind: string, attachmentsToSearch: IAttachmentMetadata[] | null) {
    if (attachmentsToSearch === null || attachmentsToSearch.length === 0) {
        return false;
    }
    const attachments: IAttachmentMetadata[] = attachmentsToSearch.filter(att => att.attachmentId === attachmentIdToFind);
    return attachments && attachments.length > 0;
}

export const RemoveAttachmentInCollection = (sourceAttachments: IAttachmentMetadata[] | null | undefined, attachmentId: string) => {
    if (sourceAttachments === undefined || sourceAttachments === null) {
        sourceAttachments = [];
    }
    if (sourceAttachments !== null && sourceAttachments.length > 0) {
        // go backwards through collection and knock the found record out if not initialised
        for (let idx = sourceAttachments.length - 1; idx >= 0; idx--) {
            if (sourceAttachments[idx].attachmentId === attachmentId) {
                // remove this record at index
                sourceAttachments.splice(idx, 1);
            }
        }
    }
};

export const ReplaceOrInjectAttachmentInCollection = (sourceAttachments: IAttachmentMetadata[] | null | undefined, attachment: IAttachmentMetadata) => {
    RemoveAttachmentInCollection(sourceAttachments, attachment.attachmentId);

    // this will update latest with complaintsMechId value, for example
    sourceAttachments!.push(attachment);
};

export function GetActiveAttachmentsByTypeForThisOrNonSavedComplaint(
    complaintMechanismId: string,
    attachments: IAttachmentMetadata[] | null,
    docType: AttachmentDocumentType,
): IAttachmentMetadata[] {
    if (!attachments || attachments.length <= 0) {
        return [];
    }

    const allActiveAttachmentsByType: IAttachmentMetadata[] = attachments.filter((item: IAttachmentMetadata) => {
        if (
            (item.complaintsProcessId === complaintMechanismId || item.complaintsProcessId === Guid.empty) &&
            item.documentType === docType &&
            item.isDeleted === false
        ) {
            return item;
        } else {
            return null;
        }
    });

    return allActiveAttachmentsByType;
}

export const AppendAttachmentsToCollection = (attachmentsSourceCollection: IAttachmentMetadata[], attachmentsDestCollection: IAttachmentMetadata[]) => {
    if (attachmentsSourceCollection && attachmentsSourceCollection.length > 0) {
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < attachmentsSourceCollection.length; j++) {
            if (IsAttachmentInCollection(attachmentsSourceCollection[j].attachmentId, attachmentsDestCollection) === false) {
                attachmentsDestCollection.push(attachmentsSourceCollection[j]);
            }
        }
    }
    return attachmentsDestCollection;
};

export function HasAnyPersonalDetailsAttachments(attachments: IAttachmentMetadata[] | undefined): boolean {
    let activeAttachmentsOfThisType: IAttachmentMetadata[] = [];
    if (attachments === undefined || attachments.length === 0) {
        return false;
    }

    // tslint:disable-next-line: prefer-const
    activeAttachmentsOfThisType = GetActiveAttachmentsByType(attachments, AttachmentDocumentType.EvidenceOfNameChange);

    const birthAttachments = GetActiveAttachmentsByType(attachments, AttachmentDocumentType.EvidenceOfBirth);
    activeAttachmentsOfThisType = AppendAttachmentsToCollection(birthAttachments, activeAttachmentsOfThisType);

    return activeAttachmentsOfThisType.length > 0;
}

export function HasAnyCompetenciesAttachments(attachments: IAttachmentMetadata[] | undefined): boolean {
    let activeAttachmentsOfThisType: IAttachmentMetadata[] = [];
    if (attachments === undefined || attachments.length === 0) {
        return false;
    }

    // tslint:disable-next-line: prefer-const
    activeAttachmentsOfThisType = GetActiveAttachmentsByType(attachments, AttachmentDocumentType.FullGraduateDiplomaOfFDR);
    const sixUnitsAttachments = GetActiveAttachmentsByType(attachments, AttachmentDocumentType.SixUnits);
    activeAttachmentsOfThisType = AppendAttachmentsToCollection(sixUnitsAttachments, activeAttachmentsOfThisType);
    const SixUnitsAndNMASAttachments = GetActiveAttachmentsByType(attachments, AttachmentDocumentType.SixUnitsAndNMAS);
    activeAttachmentsOfThisType = AppendAttachmentsToCollection(SixUnitsAndNMASAttachments, activeAttachmentsOfThisType);
    const SixUnitsAndQualificationAttachments = GetActiveAttachmentsByType(attachments, AttachmentDocumentType.SixUnitsAndQualification);
    activeAttachmentsOfThisType = AppendAttachmentsToCollection(SixUnitsAndQualificationAttachments, activeAttachmentsOfThisType);
    const threeUnitsAttachments = GetActiveAttachmentsByType(attachments, AttachmentDocumentType.ThreeUnits);
    activeAttachmentsOfThisType = AppendAttachmentsToCollection(threeUnitsAttachments, activeAttachmentsOfThisType);

    return activeAttachmentsOfThisType.length > 0;
}

// returns active attachments of the types from the Govt Employee Complaints UI
export function GetComplaintsGovtFundedActiveAttachments(attachments: IAttachmentMetadata[] | undefined): IAttachmentMetadata[] {
    let activeAttachmentsOfThisType: IAttachmentMetadata[] = [];
    if (attachments === undefined) {
        return activeAttachmentsOfThisType;
    }

    // tslint:disable-next-line: prefer-const
    activeAttachmentsOfThisType = GetActiveAttachmentsByType(attachments, AttachmentDocumentType.OrgEmployment);
    const orgPanelAttachments = GetActiveAttachmentsByType(attachments, AttachmentDocumentType.OrgPanel);
    activeAttachmentsOfThisType = AppendAttachmentsToCollection(orgPanelAttachments, activeAttachmentsOfThisType);

    return activeAttachmentsOfThisType;
}

export function GetComplaintsInsuranceActiveAttachments(attachments: IAttachmentMetadata[] | undefined): IAttachmentMetadata[] {
    let activeAttachmentsOfThisType: IAttachmentMetadata[] = [];
    if (attachments === undefined) {
        return activeAttachmentsOfThisType;
    }

    // tslint:disable-next-line: prefer-const
    activeAttachmentsOfThisType = GetActiveAttachmentsByType(attachments, AttachmentDocumentType.Insurance);
    return activeAttachmentsOfThisType;
}

// returns active attachments of the types from BOTH prof Assoc and/or the Govt Employee Complaints UI (covers both, should really only ever have one set either "org" or "prof assoc")
export function GetComplaintsAllActiveAttachments(attachments: IAttachmentMetadata[] | undefined): IAttachmentMetadata[] {
    let activeAttachmentsOfThisType: IAttachmentMetadata[] = [];
    if (attachments === undefined) {
        return activeAttachmentsOfThisType;
    }

    // tslint:disable-next-line: prefer-const
    activeAttachmentsOfThisType = GetActiveAttachmentsByType(attachments, AttachmentDocumentType.OrgEmployment);
    const orgPanelAttachments = GetActiveAttachmentsByType(attachments, AttachmentDocumentType.OrgPanel);
    activeAttachmentsOfThisType = AppendAttachmentsToCollection(orgPanelAttachments, activeAttachmentsOfThisType);
    const assocMembershipAttachments = GetActiveAttachmentsByType(attachments, AttachmentDocumentType.AssociationMembership);
    activeAttachmentsOfThisType = AppendAttachmentsToCollection(assocMembershipAttachments, activeAttachmentsOfThisType);
    const insuranceAttachments = GetActiveAttachmentsByType(attachments, AttachmentDocumentType.Insurance);
    activeAttachmentsOfThisType = AppendAttachmentsToCollection(insuranceAttachments, activeAttachmentsOfThisType);

    return activeAttachmentsOfThisType;
}

export function GetComplaintsMembershipActiveAttachments(attachments: IAttachmentMetadata[]): IAttachmentMetadata[] {
    return GetActiveAttachmentsByType(attachments, AttachmentDocumentType.AssociationMembership);
}

export function GetAttachmentById(attachments: IAttachmentMetadata[], attachmentId: string): IAttachmentMetadata | null {
    if (!attachments || attachments.length <= 0) {
        return null;
    }

    const attachmentFound: IAttachmentMetadata[] = attachments.filter((item: IAttachmentMetadata) => {
        if (item.attachmentId === attachmentId) {
            return item;
        } else {
            return null;
        }
    });

    return attachmentFound && attachmentFound[0]; // there will be only one
}

/*
export function HasComplaintsGovtFundedAttachments(attachments: IAttachmentMetadata[]): boolean {
    let retValue: boolean = false;
    if (!attachments || attachments.length <= 0) {
        return retValue;
    }
    const allGovtFundedActiveAttachments = GetComplaintsGovtFundedActiveAttachments(attachments);
    retValue = allGovtFundedActiveAttachments && allGovtFundedActiveAttachments.length > 0;
    return retValue;
} */

export function HasComplaintsMembershipAttachments(attachments: IAttachmentMetadata[]): boolean {
    let retValue: boolean = false;
    if (!attachments || attachments.length <= 0) {
        return retValue;
    }
    const allMembershipActiveAttachments = GetComplaintsMembershipActiveAttachments(attachments);
    retValue = allMembershipActiveAttachments && allMembershipActiveAttachments.length > 0;
    return retValue;
}

export function HasOutletPractitionerPendingReview(application: IApplication): boolean {
    let hasOutletPendingReview = false;

    if (application && application.outletPractitioner && application.outletPractitioner !== null) {
        hasOutletPendingReview = application.outletPractitioner.outletStatus === OutletStatusType.PendingReview;
    }

    return hasOutletPendingReview;
}

export function IsAttachmentsArrayEqual(attachmentsSource: IAttachmentMetadata[] | undefined, attachmentsDest: IAttachmentMetadata[] | undefined) {
    let retValue = true;

    if (attachmentsSource === undefined || attachmentsDest === undefined || attachmentsSource.length !== attachmentsDest.length) {
        retValue = false;
    }

    if (retValue === true) {
        let idx = 0;
        attachmentsSource!.forEach((attachment: IAttachmentMetadata) => {
            // need to compare CM ids
            if (
                attachment.complaintsProcessId !== attachmentsDest![idx].complaintsProcessId ||
                attachment.attachmentId !== attachmentsDest![idx].attachmentId
            ) {
                retValue = false;
            }

            idx = idx + 1;
        });
    }

    return retValue;
}

const IsAttachmentDocumentTypeMatchingReworkSectionType = (attachmentDocumentType: AttachmentDocumentType, reworkSection: ReworkSectionType) => {
    switch (attachmentDocumentType) {
        case AttachmentDocumentType.EvidenceOfNameChange:
            return reworkSection === ReworkSectionType.ChangeOfName;
        case AttachmentDocumentType.FullGraduateDiplomaOfFDR:
            return reworkSection === ReworkSectionType.QualificationFullGraduateDiploma;
        case AttachmentDocumentType.SixUnits:
            return reworkSection === ReworkSectionType.QualificationSixUnits;
        case AttachmentDocumentType.SixUnitsAndQualification:
            return reworkSection === ReworkSectionType.Qualification;
        case AttachmentDocumentType.SixUnitsAndNMAS:
            return reworkSection === ReworkSectionType.QualificationNMAS;
        case AttachmentDocumentType.ThreeUnits:
            return reworkSection === ReworkSectionType.QualificationThreeUnits;
        case AttachmentDocumentType.ChildRelatedEmploymentCheck:
            return reworkSection === ReworkSectionType.WorkingWithChildrenCheck;
        case AttachmentDocumentType.NationalPoliceCheck:
            return reworkSection === ReworkSectionType.NationalPoliceCheck;
        case AttachmentDocumentType.OrgEmployment:
            return reworkSection === ReworkSectionType.ComplaintsMechanismOrganisation; // multiple types to this page
        case AttachmentDocumentType.OrgPanel:
            return reworkSection === ReworkSectionType.ComplaintsMechanismOrganisation; // multiple types to this page
        case AttachmentDocumentType.AssociationMembership:
            return reworkSection === ReworkSectionType.ComplaintsMechanismProfessionalAssociation;
        case AttachmentDocumentType.Insurance:
            return reworkSection === ReworkSectionType.ProfessionalIndemnityInsurance;
        case AttachmentDocumentType.EvidenceOfBirth:
            return reworkSection === ReworkSectionType.ProofOfBirth;
        default:
            return false;
    }
};

/*
const GetIndexOfAttachment = (attachments: IAttachmentMetadata[], reworkSection: ReworkSectionType): number => {
    let currIndex = -1;
    let found = false;

    attachments.forEach((attachment: IAttachmentMetadata) => {
        if (!found && IsAttachmentDocumentTypeMatchingReworkSectionType(attachment.documentType, reworkSection)) {
            currIndex = currIndex + 1;
            found = true;
        }
        if (!found) {
            currIndex = currIndex + 1;
        }
    });

    return found === false ? -1 : currIndex;
};*/

export const ClearAttachmentDataForReworkSections = (attachmentActions: AttachmentActions, dispatch: any, inquiry: Inquiry) => {
    // tslint:disable-next-line: prefer-const
    let processedAtachmentType: AttachmentDocumentType[] = [];

    const hasThisSectionBeenProcessed = (documentType: AttachmentDocumentType) => {
        // indexOf returns -1 if the value is not found
        return processedAtachmentType.length > 0 && processedAtachmentType.indexOf(documentType) !== -1;
    };

    if (inquiry.application?.reworkSections) {
        inquiry.application?.reworkSections.forEach((reworkSection: ReworkSectionType) => {
            // match documentType to reworkSectionType and remove the attachment from the collection
            inquiry.application.attachments.forEach((attachment: IAttachmentMetadata) => {
                // remove all of this type if (1) we have not processed it already and (2) the rework section is marked (for removal)
                if (processedAtachmentType.length === 0 || hasThisSectionBeenProcessed(attachment.documentType) === false) {
                    if (IsAttachmentDocumentTypeMatchingReworkSectionType(attachment.documentType, reworkSection)) {
                        RemoveAttachmentsFromAzureIfExist(attachmentActions, dispatch, attachment.documentType, inquiry.application.attachments);
                        inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(attachment.documentType, inquiry.application.attachments);
                        // only process each type once, as we remove all of that type
                        processedAtachmentType.push(attachment.documentType);
                    }
                }
            });
        });
    }
};

export const InitialResubmitAttachmentSetup = (
    appContext: AppContextInterface,
    dispatch: any,
    appActions: ApplicationActions,
    attachmentActions: AttachmentActions,
    inquiryDefObj: InquiryDefaultObj,
    setInquiry: any,
) => {
    const destIndex: number = GetFirstWizardStepForReworkSections(appContext, inquiryDefObj.inquiry.application.reworkSections!);

    inquiryDefObj.inquiry.application.hasDeclaredAndConsented = false;
    inquiryDefObj.inquiry.application.currentApplicationStep = destIndex; // update curr Step

    ClearAttachmentDataForReworkSections(attachmentActions, dispatch, inquiryDefObj.inquiry);

    setInquiry(inquiryDefObj); // needs to be inquiryDefObj (at the higher level/wrapper of inquiry object)
    const updateOutlet = false;
    dispatch(appActions.UpdateApplication(inquiryDefObj.inquiry.application, updateOutlet));

    // wait for 0.5 seconds to allow update to occur then we get updated details for final check on save and re-render
    Sleep(500).then(() => {
        dispatch(appActions.GetApplication());
    });
};

export const InitialResubmitWizardSetup = (
    appContext: AppContextInterface,
    dispatch: any,
    appActions: ApplicationActions,
    inquiry: Inquiry,
    setInquiry: any,
) => {
    const currIndex: number = WizardStepNumber.Introduction;
    const destIndex: number = GetFirstWizardStepForReworkSections(appContext, inquiry.application.reworkSections!);

    //  - set the wizard step icon indicators for those reworkSections
    MarkAllSectionsCompleteUpToDeclaration(appContext);
    MarkReworkSections(appContext, inquiry.application.reworkSections!);

    const isValid = false;
    MarkStepIncompleteAndUpdateStatus(appContext, isValid, WizardStepNumber.DeclarationAndConsent);
    // ?? do we ?? finally we need to update the inquiry back in the DB to set consent to OFF

    // dispatch(appActions.UpdateApplication(inquiry.application));

    //  - provide a navigation option to go forward
    // Done within each form by setting WizardMode to Resubmit

    //  - navigate the user to the first step that needs attention
    const setAllPriorStepsToComplete = false;
    JumpToWizardStep(currIndex, destIndex, appContext, setAllPriorStepsToComplete);
};

export const IsConsentToPublishOnPublicRegisterVisible = (inquiry: Inquiry) => {
    if (inquiry.application === undefined || inquiry.application === null) {
        return false;
    }
    // The ONLY time someone should not see the consent for name to be publicly searchable is when the applicant is going for
    // conditional accreditation. And now the only way you're gonna get that is if you're a prof assoc member and have no insurance.
    const isProfAssocMemberWithNoInsurance =
        IsAnyComplaintProfAssocMember(inquiry.application.complaintsMechanism) &&
        inquiry.application.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership === false;

    return isProfAssocMemberWithNoInsurance === false;

    /*
    const isEmployeeOrPanelOrOrgFundedByGovtFSRP = IsAnyComplaintGovtFunded(inquiry.application.complaintsMechanism);
    const isPanelMemberOfLegalAidOrOtherOrgsWithInsurance =
        inquiry.application.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership === true &&
        IsAnyComplaintLegalAidOtherOnPanel(inquiry.application.complaintsMechanism);
    const isEmployeeOfLegalAidOrOtherOrgs =
        IsAnyComplaintOnGovtFundedAsEmployee(inquiry.application.complaintsMechanism) ||
        IsAnyComplaintOnLegalAidOrOtherAsEmployee(inquiry.application.complaintsMechanism);
    const isPrivatePractitionerFullMemberWithInsurance =
        inquiry.application.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership === true &&
        IsAnyComplaintProfAssoc_FullMembership_WithInsurance(inquiry.application.complaintsMechanism);

    return (
        isEmployeeOrPanelOrOrgFundedByGovtFSRP ||
        isPanelMemberOfLegalAidOrOtherOrgsWithInsurance ||
        isEmployeeOfLegalAidOrOtherOrgs ||
        isPrivatePractitionerFullMemberWithInsurance
    );
    */
};

export const IsAnyAttachmentsExistOfThisTypeForThisOrNonSavedComplaint = (
    complaintsMechanismId: string | undefined,
    docType: AttachmentDocumentType,
    attachments: IAttachmentMetadata[] | null,
) => {
    if (!attachments || attachments === null || attachments.length <= 0) {
        return false;
    }

    const activeAttachmentsOfThisType = GetActiveAttachmentsByType(attachments, docType);
    const activeAttachmentsOfThisTypeByCMid = activeAttachmentsOfThisType.filter(
        (item: IAttachmentMetadata) => item.complaintsProcessId === complaintsMechanismId || item.complaintsProcessId === Guid.empty,
    );

    if (activeAttachmentsOfThisTypeByCMid && activeAttachmentsOfThisTypeByCMid !== null && activeAttachmentsOfThisTypeByCMid.length > 0) {
        return true;
    }
    return false;
};

export const IsAnyAttachmentsExistOfThisType = (docType: AttachmentDocumentType, attachments: IAttachmentMetadata[] | null) => {
    if (!attachments || attachments === null || attachments.length <= 0) {
        return false;
    }

    const activeAttachmentsOfThisType = GetActiveAttachmentsByType(attachments, docType);

    if (activeAttachmentsOfThisType && activeAttachmentsOfThisType !== null && activeAttachmentsOfThisType.length > 0) {
        return true;
    }
    return false;
};

export const ReworkAttachmentIsRequired = (inquiry: Inquiry, attachmentDocumentType: AttachmentDocumentType) => {
    let retValue = false;

    if (inquiry && inquiry.application.reworkSections) {
        inquiry.application.reworkSections.forEach(reworkSection => {
            if (IsAttachmentDocumentTypeMatchingReworkSectionType(attachmentDocumentType, reworkSection)) {
                retValue = true;
            }
        });
    }

    return retValue;
};

// Remove any file data if exists for when user changes options, to tidy up data and prevent all sorts of issues
export const RemoveAttachmentsFromAzureIfExist = (
    attachmentActions: any,
    dispatch: any,
    docType: AttachmentDocumentType,
    attachments: IAttachmentMetadata[] | null,
) => {
    if (IsAnyAttachmentsExistOfThisType(docType, attachments) === false) {
        return null;
    }

    // exists, remove
    dispatch(attachmentActions.RemoveAttachmentsByType(docType));
};

// Remove any file data if exists for when user changes options, to tidy up data and prevent all sorts of issues
export const RemoveAttachmentsFromAzureIfExistForThisComplaint = (
    complaintMechanismId: string,
    attachmentActions: AttachmentActions,
    dispatch: any,
    docType: AttachmentDocumentType,
    attachments: IAttachmentMetadata[] | null,
) => {
    if (IsAnyAttachmentsExistOfThisTypeForThisOrNonSavedComplaint(complaintMechanismId, docType, attachments) === false) {
        return null;
    }

    // exists, remove each that are for this CM
    const attachmentsToRemove = GetActiveAttachmentsByTypeForThisOrNonSavedComplaint(complaintMechanismId, attachments, docType);
    attachmentsToRemove.forEach((attachment: IAttachmentMetadata) => {
        dispatch(
            attachmentActions.RemoveAttachment(
                attachment.attachmentId,
                attachment.documentType,
                VALIDATION_SUCCESS_FILEDELETED_MESSAGE,
                VALIDATION_ERROR_FILEREMOVED_TITLE,
            ),
        );
    });
};

// Remove any file data from the local collection as we remove from Azure then local does an Update() so we need to immediately match or it will not be aligned
export const RemoveAttachmentsFromCollectionIfExist = (docType: AttachmentDocumentType, attachments: IAttachmentMetadata[]) => {
    return attachments?.filter(attachment => {
        return attachment.documentType !== docType && attachment.isDeleted !== true;
    });
};

// Remove any file data from the local collection as we remove from Azure then local does an Update() so we need to immediately match or it will have a mismatch
export const RemoveAttachmentsFromCollectionIfExistForThisComplaint = (
    complaintMechanismId: string,
    docType: AttachmentDocumentType,
    attachments: IAttachmentMetadata[],
) => {
    // return all items in collection unless (1) the docType is not the same or (2) the CM Id does not match where docType does match
    return attachments?.filter(attachment => {
        return (
            (attachment.complaintsProcessId !== complaintMechanismId && attachment.documentType === docType && attachment.isDeleted !== true) ||
            (attachment.documentType !== docType && attachment.isDeleted !== true)
        );
    });
};

export const IsApplicationBeingReviewed = (application: IApplication) => {
    return (
        application.applicationStatus === ApplicationStatusEnum.UnderReview || application.applicationStatus === ApplicationStatusEnum.AuthorisedOfficerReview
    );
};
/*
export const IsPersonalDetailsAwaitingReview = (user: IUser) => {
    console.log(user);
    // IMPORTANT NOTE: As submit causes PD changes to immediately merge EditCopy to flatten we need to check if we have a Details review WI (or maybe in diff collection)
    return false;
};

export const IsApplicationAssessedAndAnyEditCopyExists = (application: IApplication, user: IUser) => {
    return (
        application.applicationStatus === ApplicationStatusEnum.Assessed &&
        (IsAnyComplaintMarkedForEditCopy(application.complaintsMechanism) === true || IsPersonalDetailsAwaitingReview(user) === true)
    );
}; */

export const AccreditedApplicationIsAwaitingReview = (application: IApplication) => {
    return (
        (application &&
            application.registrationNumber > 0 && // IsApplicationAssessedAndAnyEditCopyExists(application, user) === true ||
            (application.applicationStatus === ApplicationStatusEnum.Submitted ||
                application.applicationStatus === ApplicationStatusEnum.Resubmitted ||
                IsApplicationBeingReviewed(application))) ||
        (application &&
            application.accreditationStatus === AccreditationStatusEnum.ConditionalAccreditation && // IsApplicationAssessedAndAnyEditCopyExists(application, user) === true ||
            (application.applicationStatus === ApplicationStatusEnum.Submitted ||
                application.applicationStatus === ApplicationStatusEnum.Resubmitted ||
                IsApplicationBeingReviewed(application)))
    );
};

export const AccreditedApplicationIsActionRequired = (application: IApplication) => {
    return (
        (application && application.registrationNumber > 0 && application.applicationStatus === ApplicationStatusEnum.ActionRequired) ||
        (application &&
            application.accreditationStatus === AccreditationStatusEnum.ConditionalAccreditation &&
            application.applicationStatus === ApplicationStatusEnum.ActionRequired)
    );
};

export const IsReworkSectionInList = (reworkSections: ReworkSectionType[], reworkSectionToFind: ReworkSectionType) => {
    let isFound = false;

    reworkSections.forEach((reworkSection: ReworkSectionType) => {
        if (reworkSection === reworkSectionToFind) {
            isFound = true;
        }
    });
    return isFound;
};

export const IsReworkFilesSupplied = (application: IApplication) => {
    const isChangeNameDocExpectedAndSupplied =
        application.reworkSections &&
        IsReworkSectionInList(application.reworkSections, ReworkSectionType.ChangeOfName) &&
        IsAttachmentTypeInCollection(AttachmentDocumentType.EvidenceOfNameChange, application.attachments);
    const isChangeDOBDocExpectedAndSupplied =
        application.reworkSections &&
        IsReworkSectionInList(application.reworkSections, ReworkSectionType.ProofOfBirth) &&
        IsAttachmentTypeInCollection(AttachmentDocumentType.EvidenceOfNameChange, application.attachments);

    return (
        application &&
        (application.accreditationStatus === AccreditationStatusEnum.Accredited ||
            application.accreditationStatus === AccreditationStatusEnum.ConditionalAccreditation) &&
        application.applicationStatus === ApplicationStatusEnum.ActionRequired &&
        (isChangeNameDocExpectedAndSupplied === true || isChangeDOBDocExpectedAndSupplied === true)
    );
};

export const IsPersonalDetailsEditedButNotYetSubmitted = (application: IApplication, user: IUser) => {
    const userEditCopyExistsWithStatus =
        application &&
        (application.accreditationStatus === AccreditationStatusEnum.Accredited ||
            application.accreditationStatus === AccreditationStatusEnum.ConditionalAccreditation) &&
        (application.applicationStatus === ApplicationStatusEnum.Pending || application.applicationStatus === ApplicationStatusEnum.Assessed) &&
        user.isEditCopy === true;
    const reworkFilesSupplied = IsReworkFilesSupplied(application);
    return userEditCopyExistsWithStatus || reworkFilesSupplied;
};

export const IsComplaintsEditedButNotYetSubmitted = (application: IApplication) => {
    return (
        application &&
        application.applicationId !== UNINITIALISED &&
        (application.accreditationStatus === AccreditationStatusEnum.Accredited ||
            application.accreditationStatus === AccreditationStatusEnum.ConditionalAccreditation) &&
        (application.applicationStatus === ApplicationStatusEnum.Pending || application.applicationStatus === ApplicationStatusEnum.Assessed) &&
        IsAnyComplaintMarkedForEditCopy(application.complaintsMechanism)
    );
    // IsAnyComplaintMarkedForCancellation(application.complaintsMechanism) ||
};

export const IsMyLastComplaintMechanismFlaggedForCancellation = (application: IApplication) => {
    // THIS METHOD WORKS FOR Application on the landing page - however will NOT work from the changes page where we need to calculate changes
    return (
        application &&
        application.complaintsMechanism &&
        application.complaintsMechanism.length > 0 &&
        IsAnyComplaintMarkedForCancellation(application.complaintsMechanism) === true &&
        IsAtLeastOneComplaintActive(application.complaintsMechanism) === false
    );
};

export const IsComplaintChangesResultingInLastOneCancelled = (
    currComplaints: IComplaintMechanism[],
    addCMs: IDifferences<IComplaintMechanism>[] | null,
    removeCMs: IDifferences<IComplaintMechanism>[] | null,
) => {
    const countOfAdded = addCMs !== null ? addCMs.length : 0;
    const countOfRemoved = removeCMs !== null ? removeCMs.length : 0;
    const countOfCurrent = CountOfValidComplaintsMech(currComplaints);

    // the collection contains CMs minus the one removed, which is why we check against 1 or more left over here
    return countOfRemoved - countOfAdded - countOfCurrent >= 1;
};

export const UserIsLoaded = (userToCheck: IUser) => {
    return userToCheck!! && userToCheck.id !== 0;
};

export const ApplicationIsLoaded = (applicationToCheck: IApplication, applicationId: string) => {
    return (
        applicationToCheck &&
        applicationToCheck !== null &&
        applicationToCheck.applicationId !== UNINITIALISED &&
        applicationToCheck.applicationId === applicationId &&
        applicationToCheck.applicationId !== Guid.empty.toString()
    );
};

export const DisplayReviewAndSubmitWarningLink = (
    application: IApplication,
    user: IUser,
    cardDeckRowNote: any,
    noteColor: any,
    noteBackgroundColor: any,
    showEmptyRow?: boolean,
) => {
    // cannot use EditCopy as we get current user on dashboard: user.isEditCopy === true;
    const showReviewAndSubmitNoteLink =
        application !== undefined &&
        application?.applicationId !== UNINITIALISED &&
        (IsComplaintsEditedButNotYetSubmitted(application) === true || IsPersonalDetailsEditedButNotYetSubmitted(application, user) === true);

    if (showReviewAndSubmitNoteLink === false) {
        return null;
    }

    return (
        <>
            <Row className={`${cardDeckRowNote}`} id='warning-note-complaintsedited' data-testid='warning-note-edited'>
                <Col md='12'>
                    <NoteText
                        title='Action required'
                        displayText={NOTE_UNSUBMITTED_CHANGES_CLICKHERETOREVIEW}
                        backgroundColor={noteBackgroundColor}
                        color={noteColor}
                        fontBold={true}
                        buttonLink={'/managedetailsreviewandsubmitpage'}
                    />
                </Col>
                <Col md='1' className='justify-content-end' />
            </Row>
            {showEmptyRow === true && <EmptyRow />}
        </>
    );
};

export const DisplayExpiryWarningNote = (application: IApplication, cardDeckRowNote: any, noteColor: any, noteBackgroundColor: any, showEmptyRow?: boolean) => {
    if (application?.applicationStatus === ApplicationStatusEnum.Pending && application.accreditationStatus === AccreditationStatusEnum.New) {
        return (
            <>
                <Row className={`${cardDeckRowNote}`} id='warning-note-expiry' data-testid='warning-note-expiry'>
                    <Col md='12'>
                        <NoteText displayText={NOTE_UNSUBMITTED_EXPIRY_WARNING} backgroundColor={noteBackgroundColor} color={noteColor} fontBold={true} />
                    </Col>
                    <Col md='1' className='justify-content-end' />
                </Row>
                {showEmptyRow === true && <EmptyRow />}
            </>
        );
    }

    return null;
};

export const outletPractitionerExists = (application: IApplication) => {
    const contactDetailExists =
        application &&
        application.outletPractitioner &&
        application.outletPractitioner !== null &&
        (application.outletPractitioner.name !== '' ||
            application.outletPractitioner.phone !== '' ||
            application.outletPractitioner.mobile !== '' ||
            application.outletPractitioner.tollFreePhone !== '' ||
            application.outletPractitioner.registerEmail !== '' ||
            application.outletPractitioner.webAddress !== '');
    const serviceDeliveryExists =
        application &&
        application.outletPractitioner &&
        application.outletPractitioner !== null &&
        (application.outletPractitioner.isServiceDeliveryFaceToFace === true ||
            application.outletPractitioner.isServiceDeliveryPhone === true ||
            application.outletPractitioner.isServiceDeliveryOnline === true);

    return contactDetailExists && serviceDeliveryExists;
};

export const NumberOfActiveConditions = (conditions: IAccreditationConditionDto[] | null) => {
    let retValue = 0;

    if (conditions !== null) {
        conditions.forEach(condition => {
            if (condition.endDate === undefined || condition.endDate === null) {
                retValue = retValue + 1;
            }
        });
    }

    return retValue;
};
