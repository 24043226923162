/* Action Card */
export enum ActionCardIndicatorEnum {
    None = 0,
    New = 1,
    InProgress = 2, // new half-baked
    Deleted = 3,
    Edited = 4, // existing but editcopy created
}

/* Wizard Mode */
export enum WizardMode {
    New = 0,
    Resubmit = 1,
}

export enum EditMode {
    New = 0,
    Resubmit = 1,
}

/* Wizard steps */
export enum WizardStepNumber {
    Introduction = 0,
    PersonalDetailsName = 1,
    PersonalDetailsContact = 2,
    PastApplicationHistory = 3,
    CompetenciesAndQuals = 4,
    ChildRelatedEmployment = 5,
    CriminalConvictions = 6,
    ComplaintsMechanismType = 7,
    ComplaintsMechanismEmployee = 8,
    ComplaintsMechanismMember = 9,
    ProfessionalIndemnity = 10,
    ChangeOfName = 11,
    DeclarationAndConsent = 12,
    ReviewAndSubmit = 13,
}

export enum WizardStepNumberComplaintsMech {
    ComplaintsMechanismType = 0,
    ComplaintsMechanismDetails = 1,
    ComplaintsMechanismInsurance = 2,
}

/* Ajax call state */
export enum AjaxCallStateEnum {
    NotStarted = 0,
    InProgress = 1,
    CompletedOK = 2,
    CompletedWithValidation = 3,
    CompletedWithError = 4,
    Timeout = 5,
}

/* Navigation */
export enum NavigationDestination {
    Home = 1,
    Dashboard = 2,
    PreviousWizardStep = 3,
    ManageComplaintsMech = 4,
    ManageDetailsReviewAndSubmitPage = 5,
}

/* Enums mapped from back-end (we can automate the generation of these given time) */
export enum ApplicationStatusEnum {
    NotStarted = 'NotStarted', // 90,
    Pending = 'Pending', // 1,
    NotPending = 'NotPending', // 2,
    Ineligible = 'Ineligible', // 3,
    Submitted = 'Submitted', // 4
    UnderReview = 'UnderReview', // 5
    ActionRequired = 'ActionRequired', // 6
    Resubmitted = 'Resubmitted', // 7
    AuthorisedOfficerReview = 'AuthorisedOfficerReview', // 8
    PreDetermination = 'PreDetermination', // 9
    Assessed = 'Assessed', // 10
    Withdrawn = 'Withdrawn', // 11
    Discarded = 'Discarded', // 12
    PendingWithdrawal = 'PendingWithdrawal', // 13
    PendingSuspension = 'PendingSuspension', // 14
    Suspended = 'Suspended', //15
    PendingCancellation = 'PendingCancellation', //16
    Cancelled = 'Cancelled', //17
    UnderMaintenance = 'UnderMaintenance', //18
}

export enum AccreditationStatusEnum {
    New = 'New', // 1
    RegisteredPublic = 'RegisteredPublic', // 2
    RegisteredNonpublic = 'RegisteredNonpublic', // 3
    Rejected = 'Rejected', // 4
    Suspended = 'Suspended', // 5
    Withdrawn = 'Withdrawn', // 10
    Lapsed = 'Lapsed', // 11
    Cancelled = 'Cancelled', // 12
    ConditionalAccreditation = 'ConditionalAccreditation', // 13
    Accredited = 'Accredited', // 14
    Pending = 'Pending', // 15
    AccreditedWithConditions = 'AccreditedWithConditions', // 16,
    NotApplicable = 'NotApplicable', // 90
}

export enum AddressTypeEnum {
    Business = 'Business', // 1,
    Postal = 'Postal', // 2,
}

export enum QualificationType {
    VocationalGradDipOrEquivalent = 'VocationalGradDipOrEquivalent', // 2,
    QualificationOrNMAS = 'QualificationOrNMAS', // 3,
    ThreeSpecifiedUnits = 'ThreeSpecifiedUnits', // 6,
}

export enum QualificationOrNMASDetailsType {
    AppropriateQualification = 'AppropriateQualification', // 1,
    CurrentNMASAccreditation = 'CurrentNMASAccreditation', // 2,
}

export enum CompleteWorkingWithChildrenCheckType {
    Required = 'Required', // true
    NotRequired = 'NotRequired', // false
}

export enum AttachmentDocumentType { // DocumentType is a node reserved type
    EvidenceOfNameChange = 'EvidenceOfNameChange', // 1,
    FullGraduateDiplomaOfFDR = 'FullGraduateDiplomaOfFDR',
    SixUnits = 'SixUnits',
    SixUnitsAndQualification = 'SixUnitsAndQualification',
    SixUnitsAndNMAS = 'SixUnitsAndNMAS',
    ThreeUnits = 'ThreeUnits',
    ChildRelatedEmploymentCheck = 'ChildRelatedEmploymentCheck',
    NationalPoliceCheck = 'NationalPoliceCheck',
    OrgEmployment = 'OrgEmployment',
    AssociationMembership = 'AssociationMembership',
    Insurance = 'Insurance',
    OrgPanel = 'OrgPanel',
    EvidenceOfBirth = 'EvidenceOfBirth',
}

export enum ComplaintsMechanismType { // mapped to DB from ComplaintsMechanismType UI
    GovernmentFundedFDRService = 'GovernmentFundedFDRService', // 1
    FullMembershipProfessionalAssoc = 'FullMembershipProfessionalAssoc', // 2
}

export enum ComplaintsProcessType { // mapped to DB from Complaints Employee and Membership UIs
    GovernmentFundedFDRService = 'GovernmentFundedFDRService', // employee page
    LegalAidStatutoryBody = 'LegalAidStatutoryBody',
    ProfessionalAssociationMembership = 'ProfessionalAssociationMembership', // membership page
    OtherStatutoryBody = 'OtherStatutoryBody',
    CommunityJusticeCentre = 'CommunityJusticeCentre',
    OtherNonStatutoryBody = 'OtherNonStatutoryBody',
}

export enum ProfessionalMembershipType {
    FullMembership = 'FullMembership',
}

export enum OrganisationFundingType {
    FRSPFunded = 'FRSPFunded', // 1,
    LegalAid = 'LegalAid',
    Other = 'Other',
}

export enum CostType {
    Free = 'Free',
    Charged = 'Charged',
    FreeAndCharged = 'FreeAndCharged',
}

// these match the Id in the back-end on Code.ServiceDeliveryType table
export enum ServiceDeliveryType {
    FaceToFace = 1,
    Telephone = 2,
    Online = 4, // need INT so we can map to back-end via ReferenceDataDto
}

export enum AdditionalServiceDeliveryType {
    Comediation = 1,
    GenderBalanced = 2,
    LegallyAssisted = 3,
    ChildInclusive = 4, // need INT so we can map to back-end via ReferenceDataDto
}

export enum ScopeType {
    ChildSupport = 1,
    Property = 2,
    TheHague = 3, // need INT so we can map to back-end via ReferenceDataDto
}

export enum ClientGroupType {
    Lgbtqi = 1,
    Carers = 2,
    Grandparents = 3,
    Aboriginal = 4,
    TorresStrait = 5,
    CulturallyDiverse = 6, // need INT so we can map to back-end via ReferenceDataDto
}

export enum OtherServiceType {
    Practitioner = 1,
    Student = 2, // need INT so we can map to back-end via ReferenceDataDto
}

export enum DayType {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7,
}

export enum ServiceCostType {
    Free = 'Free',
    Negotiable = 'Negotiable',
    Charged = 'Charged',
}

export enum RoomChargeType {
    Y = 'Y',
    M = 'M',
    N = 'N',
}

export enum InsuranceEvidenceType {
    YesEmployee = 'YesEmployee',
    YesEmployer = 'YesEmployer',
    No = 'No',
}

export enum RelationshipToEmployerType {
    Employee = 'Employee',
    OnPanel = 'OnPanel',
}

export enum AccreditationDecisionEnum {
    MeetsCriteria = 'MeetsCriteria',
    MeetsCriteriaNeedsInsurance = 'MeetsCriteriaNeedsInsurance', // Condition imposed - needs PII
    DoesNotMeetCriteria = 'DoesNotMeetCriteria', // Does not meet criteria
}

export enum OutletStatusType {
    Approved = 'Approved',
    PendingReview = 'PendingReview',
}

export enum BannerTextType {
    RegisterPortalBanner = 1,
    PractictionerPortalBanner,
}

export enum ReworkSectionType {
    NationalPoliceCheck = 'NationalPoliceCheck',
    WorkingWithChildrenCheck = 'WorkingWithChildrenCheck',
    QualificationFullGraduateDiploma = 'QualificationFullGraduateDiploma',
    QualificationSixUnits = 'QualificationSixUnits',
    Qualification = 'Qualification',
    QualificationNMAS = 'QualificationNMAS',
    QualificationThreeUnits = 'QualificationThreeUnits',
    ComplaintsMechanismOrganisation = 'ComplaintsMechanismOrganisation',
    ComplaintsMechanismProfessionalAssociation = 'ComplaintsMechanismProfessionalAssociation',
    ProfessionalIndemnityInsurance = 'ProfessionalIndemnityInsurance',
    ChangeOfName = 'ChangeOfName',
    ProofOfBirth = 'ProofOfBirth',
}

export enum NotificationType {
    IndividualNew = 1,
    Suspended,
    Accredited,
    Withdrawn,
    Cancelled,
    CancelledByPractitioner,
    AllPractitioner,
    PendingNMASExpiry,
    SuspendedByPractitioner,
    PendingComplaintsExpiry,
    PendingPIIExpiry,
    WithdrawRejected,
    WithdrawnByPAU,
    InformationRequired,
    RecommendNoConditions,
    RecommentWithConditions,
    RequestUnsuspend,
    Reaccreditation,
    OutletApproved,
    AccreditedWithConditions,
    UnsuspendedViaReapply,
    AccreditedWithConditionsViaReapply,
    NMASExpired,
    MembershipExpired,
    PIIExpired,
}
