import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from '../../services/AuthService';
import { IUser } from '../../api/interfacesApi';
import ApplicationActions from '../../actions/applicationActions';
import UserActions from '../../actions/userActions';
import BasePageContainer from '../../components/site/BasePageContainer';
import DashboardActions from './DashboardActions';
import Loader from 'controls/Loader';
import { UNINITIALISED } from 'services/Constants';

interface DashboardProps {
    authService: AuthService;
}

function Dashboard(props: DashboardProps) {
    const { authService } = props;

    const dispatch = useDispatch();
    const userActions = useMemo(() => {
        return new UserActions(authService);
    }, [authService]);
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);

    const user: IUser = useSelector((stateUser: any) => stateUser.user);
    const application = useSelector((state: any) => state.application);
    const [userObj, setUserObj] = useState(user);

    const scrollToDashboardWelcomeMessage = () => {
        const elementFound = document.getElementById('jumbotron-heading-welcome');
        // tslint:disable-next-line: no-unused-expression
        elementFound && elementFound.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    };

    useEffect(() => {
        scrollToDashboardWelcomeMessage();
    }, []);

    useEffect(() => {
        // IMPORTANT NOTE: we get the user Edit Copy here - if exists
        dispatch(userActions.GetUserEditCopy());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(appActions.GetApplication());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // set the user object to check if EditCopy, but DO NOT overwrite existing EditCopy if already read in (it has same properties plus flag set)
        if (userObj.isEditCopy === true) {
            // do nothing
        } else {
            setUserObj(user);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const isLoaded =
        user !== undefined &&
        user !== null &&
        user.id !== 0 &&
        application !== undefined &&
        application !== null &&
        application.applicationId !== UNINITIALISED;

    return (
        <BasePageContainer authService={props.authService}>
            {isLoaded === false && <Loader isLoading={true} loaderText='Loading your information...' />}
            {isLoaded === true && <DashboardActions authService={props.authService} user={userObj} application={application} />}
        </BasePageContainer>
    );
}

export default Dashboard;
