import FileApiService from './fileApi';
import AuthService from '../services/AuthService';
import { IAttachmentMetadata } from './interfacesApi';

class StorageApi {
    fileApiService: FileApiService;

    constructor(authenticationService: AuthService) {
        this.fileApiService = new FileApiService(authenticationService);
    }

    async uploadFile(metadata: IAttachmentMetadata, blob: any, onUploadProgress: any) {
        return this.fileApiService.makeStandardPostRequest<IAttachmentMetadata>('api/attachments', metadata, blob, onUploadProgress);
    }
}

export default StorageApi;
