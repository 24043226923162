import * as React from 'react';
import { AppContextInterface } from '../stateManagement/context/AppContext';
import PillButton from './PillButton';
import { handleDashboardClick } from '../pages/PageFunctions';
import { backToIcon } from '../components/wizard/wizardMenuIcons';
import Guid from '../services/Guid';
import styles from '../components/site/site.module.scss';

const { backToPortalLink } = styles;

export interface BackToDashboardPillButtonProps {
    appContext: AppContextInterface;
}

const BackToDashboardPillButton = (props: BackToDashboardPillButtonProps) => {
    const { appContext } = props;
    const uniqueKey = Guid.newGuid();

    const handleDashboardClickEvent = (e: any) => {
        handleDashboardClick(appContext, e);
    };

    return (
        <>
            <PillButton hrefLink='/' displayText='Back to dashboard' handleClickEvent={handleDashboardClickEvent}>
                <li data-testid='BackToDashboardLink' key={uniqueKey.toString()} className={backToPortalLink} role='menuitem'>
                    {backToIcon()}
                    &nbsp;&nbsp;
                    <span>Back to dashboard</span>
                </li>
            </PillButton>
        </>
    );
};

export default BackToDashboardPillButton;
