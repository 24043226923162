import * as types from '../../actions/actionTypes';
import { IApplication } from '../../api/interfacesApi';

const applications = (state = [], action: types.ApplicationActionTypes) => {
    let apps: IApplication[];

    switch (action.type) {
        case types.GET_ALL_APPLICATIONS_SUCCESS:
            apps = action.applications; // { ...action.applications };
            return apps;
        default:
            return state;
    }
};

export default applications;
