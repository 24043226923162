import React, { useEffect } from 'react';
import { ApplicationStatusEnum, NavigationDestination, WizardMode } from '../services/Enums';
import { AppContextInterface } from '../stateManagement/context/AppContext';
import {
    IsUserInWizard,
    IsWizardFormDirty,
    SetDestinationNavActionOnSuccess,
    ShowDiscardChangesDialog,
    ShowSaveChangesDialog,
    WizardStateActionTypeEnums,
} from '../components/wizard/wizardStateManager';
import { ActivateStep, SetAllPriorStepsToComplete, UpdateWizardStepsAndRepaint } from '../components/wizard/wizardStepFunctions';
import {
    IsOutletEditFormDirty,
    IsUserInOutletEditForm,
    SetDestinationNavActionOnSuccessEditForm,
    ShowSaveChangesDialogEditForm,
} from './manageDetails/EditFormStateManager';

// Used to submit the page only if the active page AND the trigger is set to do so (i.e. for Save your Changes prompt)
export function SubmitThisPageIfActive(isActive: boolean, triggerSubmit: boolean, formRef: React.MutableRefObject<undefined>, setTriggerSubmit: any) {
    useEffect(() => {
        if (isActive && triggerSubmit) {
            const formRefCurrent: any = formRef.current;

            if (formRefCurrent) {
                if (typeof formRefCurrent.requestSubmit === 'function') {
                    formRefCurrent.requestSubmit();
                } else {
                    formRefCurrent.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                }
            }

            // parent set trigger to control flow, second delay to allow processing to occur (see main wizard index timer for checks)
            setTimeout(setTriggerSubmit(false), 1000);
        }
    }, [isActive, setTriggerSubmit, triggerSubmit, formRef]);
}

// Scroll window to top ofscreen
export function ScrollToTop() {
    window?.scrollTo(0, 0);
}

// Determine if on Intro page
export function isOnIntroductionPage(formStep: number) {
    return formStep === 0;
}

// Determine if application is not yet started
export function isApplicationNotStartedOrNull(status: ApplicationStatusEnum | null) {
    return status === null || status === ApplicationStatusEnum.NotStarted;
}

// Handler for the Back to dashboard link when clicked
export function handleDashboardClick(appContext: AppContextInterface, e: { preventDefault: () => void } | undefined) {
    // Check if user has made any changes on the active form and prompt to save if they have
    if (IsUserInWizard(appContext) && IsWizardFormDirty(appContext)) {
        e?.preventDefault(); // MUST prevent going backwards until user has answered to save or not (and system saves data!)
        ShowSaveChangesDialog(appContext);
        SetDestinationNavActionOnSuccess(appContext, NavigationDestination.Dashboard);
    }
    if (IsUserInOutletEditForm(appContext) && IsOutletEditFormDirty(appContext)) {
        e?.preventDefault(); // MUST prevent going backwards until user has answered to save or not (and system saves data!)
        ShowSaveChangesDialogEditForm(appContext);
        SetDestinationNavActionOnSuccessEditForm(appContext, NavigationDestination.Dashboard);
    }
}

// Handler for the Back to Manage Complaints link when clicked
export function handleBackToManageComplaints(appContext: AppContextInterface, e: { preventDefault: () => void } | undefined) {
    // Check if user has made any changes on the active form and prompt to save if they have
    if (IsUserInWizard(appContext) && IsWizardFormDirty(appContext)) {
        e?.preventDefault(); // MUST prevent going backwards until user has answered to save or not (and system saves data!)
        ShowDiscardChangesDialog(appContext);
        SetDestinationNavActionOnSuccess(appContext, NavigationDestination.ManageComplaintsMech);
    }
}

// handler for the Back to section link when clicked
export function handleSectionClick(currIndex: number, destIndex: number, appContext: AppContextInterface, e: { preventDefault: () => void } | undefined) {
    e?.preventDefault(); // MUST prevent going backwards
    JumpToWizardStep(currIndex, destIndex, appContext, false);
}

export function JumpToWizardStep(currIndex: number, destIndex: number, appContext: AppContextInterface, setAllPriorStepsToComplete: boolean) {
    let newWizardSteps = ActivateStep(currIndex, destIndex, appContext.wizardSteps);
    if (setAllPriorStepsToComplete) {
        newWizardSteps = SetAllPriorStepsToComplete(destIndex, newWizardSteps);
    }

    appContext.dispatchWizardState({ type: WizardStateActionTypeEnums.activateStep, destinationStep: destIndex });

    UpdateWizardStepsAndRepaint(appContext, newWizardSteps);
}

export function GetTextForSaveAndContinueButton(appContext: AppContextInterface) {
    return appContext.wizardState.wizardMode === WizardMode.Resubmit ? 'Next' : 'Save and Continue';
}

export function HideTheBackButton(appContext: AppContextInterface) {
    return appContext.wizardState.wizardMode === WizardMode.Resubmit ? true : false;
}
