import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// tslint:disable-next-line: no-implicit-dependencies
import bgImage from 'images/header.png';
import AuthService from '../../services/AuthService';
import AppContext from '../../stateManagement/context/AppContext';
import UserActions from '../../actions/userActions';
import StatusHeader from '../../components/status/StatusHeader';
import Status from '../../components/status/Status';
import DashboardLinksMenu from '../../pages/Dashboard/DashboardLinksMenu';
import { ClearIsUserInEditFormFlag } from '../../pages/manageDetails/EditFormStateManager';
import { ClearIsUserInWizardFlag } from '../wizard/wizardStateManager';
import styles from './site.module.scss';

const { basePageContainerBackground } = styles;
interface BasePageContainerProps {
    authService: AuthService;
    children?: React.ReactNode;
}

const BasePageContainer: React.FC<BasePageContainerProps> = ({ ...props }) => {
    const { authService } = props;

    const dispatch = useDispatch();
    const appContext = useContext(AppContext);
    const user = useSelector((state: any) => state.user);
    const application = useSelector((state: any) => state.application);
    const userActions = useMemo(() => {
        return new UserActions(authService);
    }, [authService]);
    const [userObj, setUserObj] = useState(user);

    // set the overall application flag to indicate user is not in any form
    // trigger on component mount
    useEffect(() => {
        ClearIsUserInEditFormFlag(appContext);
        ClearIsUserInWizardFlag(appContext);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // IMPORTANT NOTE: we get the user Edit Copy here - if exists
        dispatch(userActions.GetUserEditCopy());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // set the user object to check if EditCopy, but DO NOT overwrite existing EditCopy if already read in (it has same properties plus flag set)
        if (userObj.isEditCopy === true) {
            // do nothing
        } else {
            setUserObj(user);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <div className={basePageContainerBackground}>
            <div className='jumbotron jumbotron-fluid' style={{ backgroundImage: `url("${bgImage}")`, backgroundSize: 'cover' }}>
                <div className='container ml-5'>
                    <div>
                        <StatusHeader user={userObj} />
                        <Status application={application} user={userObj} />
                    </div>
                </div>
            </div>
            <div className='row pb-5 ml-3 mr-3'>
                <div className='col-md-3'>
                    <DashboardLinksMenu accreditationStatus={application?.accreditationStatus} />
                </div>
                <div className='col-md-9'>{props.children}</div>
            </div>
        </div>
    );
};

export default BasePageContainer;
