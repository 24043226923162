import { IDifferences, IUser } from '../../api/interfacesApi';
import * as types from '../../actions/actionTypes';
import { formatDateForDateFns } from '../../utils/Dates';

const differencesInit: IDifferences<IUser> = {
    differences: {},
    originalVersion: {},
    collectionDifferences: {},
};

const differencesUser = (state = differencesInit, action: types.UserActionTypes) => {
    switch (action.type) {
        case types.GET_USER_CHANGES_SUCCESS:
            // tslint:disable-next-line: prefer-const
            let retDifferences: IDifferences<IUser> = action.differences;

            // tidy up differences object:
            const diffUser: IUser = retDifferences.differences;
            // if DOB is in format "YYYY-MM-DDTHH:MM:NN" then convert to Date object
            if (diffUser.dateOfBirth && (typeof diffUser.dateOfBirth === 'string' || diffUser.dateOfBirth instanceof String)) {
                diffUser.dateOfBirth = formatDateForDateFns(diffUser.dateOfBirth);
            }
            retDifferences.differences = diffUser;

            // tidy up originalVersion object:
            const origUser: IUser = retDifferences.originalVersion;
            // if DOB is in format "YYYY-MM-DDTHH:MM:NN" then convert to Date object
            if (origUser.dateOfBirth && (typeof origUser.dateOfBirth === 'string' || origUser.dateOfBirth instanceof String)) {
                origUser.dateOfBirth = formatDateForDateFns(origUser.dateOfBirth);
            }
            retDifferences.originalVersion = origUser;

            return retDifferences;
        default:
            return state;
    }
};

export default differencesUser;
