import ApplicationActions from '../../../../actions/applicationActions';
import AttachmentActions from '../../../../actions/attachmentsActions';
import { IComplaintMechanism, Inquiry } from '../../../../api/interfacesApi';
import { RemoveCMAttachmentsForThisCM } from '../../../../utils/ComplaintsUtils';
import { Sleep } from '../../../../utils/Common';

// this page is to seperate out the dispatch() / redux part from the main page as it is seldom needed and created errors when loaded too early
interface RemoveComplaintsMechanismProps {
    dispatch: any;
    appActions: ApplicationActions;
    attachmentActions: AttachmentActions;
    complaintMechanism: IComplaintMechanism;
    inquiry: Inquiry;
    refreshReviewAndSubmit: any;
    isRemovingOnlyChange: boolean;
}

//function RemoveComplaintsMechanism(authService: AuthService, complaintMechanism: IComplaintMechanism, inquiry: Inquiry, refreshReviewAndSubmit: any) {
const RemoveComplaintsMechanism = (props: RemoveComplaintsMechanismProps) => {
    const { dispatch, appActions, attachmentActions, complaintMechanism, inquiry, refreshReviewAndSubmit, isRemovingOnlyChange } = props;

    const IsUndefinedOrNull = (item: any) => {
        return item === undefined || item === null;
    };

    // ONLY clear the editcopy version of documents NOT the originals
    if (complaintMechanism.isEditCopy === true && IsUndefinedOrNull(complaintMechanism.evidence) !== true && complaintMechanism.evidence.length > 0) {
        RemoveCMAttachmentsForThisCM(attachmentActions, dispatch, inquiry, complaintMechanism.complaintsMechanismId);
    } // end if evidence exists

    // and also the item
    dispatch(appActions.DiscardChangesComplaintsMechanism(complaintMechanism));

    Sleep(500).then(() => {
        // throw this up to refresh all -or- returns to dash if last change
        if (isRemovingOnlyChange === true) {
            // return to home page
            window.location.href = '/';
        } else {
            refreshReviewAndSubmit();
        }
    });

    // return null;
};

export default RemoveComplaintsMechanism;
