// View Personal Details page
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IApplication, InquiryDefaultObj, IUser } from '../../../api/interfacesApi';
import AuthService from '../../../services/AuthService';
import ApplicationActions from '../../../actions/applicationActions';
import UserActions from '../../../actions/userActions';
import * as types from '../../../actions/actionTypes';
import { toastErrorMessage } from '../../../actions/toastrMessages';
import { AjaxCallStateForPage } from '../../PageInterfaces';
import RegistrationApplicationForm from '../../../pages/Registration/RegistrationApplicationForm';
import { AjaxCallStateEnum } from '../../../services/Enums';
import { VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../../services/Validation';
import { HEADER_VIEW_PERSONALDETAILS } from '../../../services/Constants';
import { Sleep } from '../../../utils/Common';
import { ApplicationIsLoaded } from '../../../utils/AppUtils';
import PageTitle from '../../../components/pageTitle';
import PersonalDetailsNameDisplay from '../../../fieldLayout/personalDetailsNameDisplay';
import PersonalDetailsContactDisplay from '../../../fieldLayout/personalDetailsContactDisplay';
import { EmptyRow } from '../../../controls/EmptyRow';
import Loader from '../../../controls/Loader';
import PageWrapper from '../../../components/pageWrapper';
import { NavigateAway } from '../../../components/wizard/wizardStepFunctions';
import AppContext, { AppContextInterface } from '../../../stateManagement/context/AppContext';

interface ViewPersonalDetailsPageProps {
    authService: AuthService;
}

const ViewPersonalDetailsPage = (props: ViewPersonalDetailsPageProps) => {
    const { authService } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const formRef = useRef();
    const appContext = useContext<AppContextInterface>(AppContext);
    const userActions = useMemo(() => {
        return new UserActions(authService);
    }, [authService]);
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const user: IUser = useSelector((stateUser: any) => stateUser.user);
    const application: IApplication = useSelector((stateApp: any) => stateApp.application);
    const ajaxCallsInProgress: types.ICallStatus = useSelector((stateAjax: any) => stateAjax.ajaxCallsInProgress);
    const initCallStateForPage: AjaxCallStateForPage = { ajaxCallState: AjaxCallStateEnum.NotStarted, pageNumber: 0 };
    const [ajaxCallStateForPage, setAjaxCallStateForPage] = useState(initCallStateForPage);
    const [backEndValidationErrors, setBackEndValidationErrors] = useState([]);
    const applicationForm = useRef(new RegistrationApplicationForm());
    const [inquiryDefObj, setInquiry] = useState<InquiryDefaultObj>(applicationForm.current.properties.InitialData);
    const [isUpdatingConsent, setIsUpdatingConsent] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const resetConsentIfSet = () => {
        if (inquiryDefObj.inquiry.application.hasDeclaredAndConsented === true) {
            // initially set declaration to false if not already
            inquiryDefObj.inquiry.application.hasDeclaredAndConsented = false;
            setInquiry(inquiryDefObj); // needs to be inquiryDefObj (at the higher level/wrapper of inquiry object)

            setIsUpdatingConsent(true);

            // update app with new value
            const updateOutlet = false;
            dispatch(appActions.UpdateApplication(inquiryDefObj.inquiry.application, updateOutlet));
        } else {
            // already unset consent, data is loaded
            Sleep(500).then(() => {
                setIsDataLoaded(true);
            });
        }
    };

    useEffect(() => {
        // get latest user details so we can map them to our inquiry object upon load of this page
        dispatch(userActions.GetUser());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(appActions.GetApplication());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        inquiryDefObj.inquiry.personalDetails = user;
        setInquiry(inquiryDefObj);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (isDataLoaded === false && ApplicationIsLoaded(application, application.applicationId)) {
            if (isUpdatingConsent === false && application.applicationId !== inquiryDefObj.inquiry.application.applicationId) {
                inquiryDefObj.inquiry.application = application;
                setInquiry(inquiryDefObj);

                if (isUpdatingConsent === false) {
                    resetConsentIfSet();
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application]);

    useEffect(() => {
        if (ajaxCallsInProgress && ajaxCallsInProgress.callState) {
            setAjaxCallStateForPage({ ajaxCallState: ajaxCallsInProgress.callState, pageNumber: 0 });
        }

        if (ajaxCallsInProgress && ajaxCallsInProgress.callState === AjaxCallStateEnum.CompletedWithValidation) {
            setBackEndValidationErrors(ajaxCallsInProgress.errors);
        }

        // TODO: set Errors here:
    }, [ajaxCallsInProgress]);

    // check dispatch success state
    useEffect(() => {
        if (ajaxCallStateForPage) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (isUpdatingConsent === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK) {
                    setIsUpdatingConsent(false);

                    dispatch(appActions.GetApplication());

                    Sleep(500).then(() => {
                        setIsDataLoaded(true);
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const handleClickPrevious = () => {
        const keepChanges = false;
        const isDirtyForcedFalse = false;
        const isValid = true;
        NavigateAway(appContext, history, keepChanges, isDirtyForcedFalse, isValid);
    };

    const submitForm = (data: any) => {
        history.push('/editpersonaldetails');
    };

    const pageTitle = `Manage personal details - ${user?.displayName}`;

    if (isDataLoaded === false) {
        return <Loader isLoading={true} loaderText='Loading, please wait...' />;
    }

    return (
        <>
            <PageTitle title={pageTitle} description={HEADER_VIEW_PERSONALDETAILS} />
            <PageWrapper
                pageName='ViewPersonalDetails'
                formRef={formRef}
                handleSubmit={submitForm}
                handleClickPrevious={handleClickPrevious}
                textForSaveContinueButton='Edit'
            >
                <PersonalDetailsNameDisplay inquiry={inquiryDefObj.inquiry} formatForPDF={true} displayFormerName={true} />
                <PersonalDetailsContactDisplay inquiry={inquiryDefObj.inquiry} formatForPDF={true} hidePageFieldsTitle={true} />
                <EmptyRow />
            </PageWrapper>
        </>
    );
};

export default ViewPersonalDetailsPage;
