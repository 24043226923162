// wizardMenuItem(s)
//  - has an indicator for progess (not started, in error, completed)
//  - has TITLE, DISPLAY NAME
//  - is higlighted on active item
//  - allows keyboard navigation and screen reader
//  - belongs to only 1 WizardMenuItem
//  - parent-child nodes are all treated as a single unit with the same parent
import React from 'react';
import { WizardStepProps } from './wizardInterfaces';
import { WizardStepCurrentStateEnum } from './wizardEnums';
import { childIcon, progressIcon } from './wizardMenuIcons';
import { WizardMenuItem } from './wizardMenuItem';
import { displayApplicationNumber, isAnyChildMenuItemActive, isAnyChildMenuItemInvalid, isAnyChildMenuItemToBeDisplayed } from './wizardFunctions';
import { displayBackToManageComplaintsLink, displayBackToPortalLink } from '../../utils/AppUtils';
import { WizardMode } from '../../services/Enums';
import AppContext from '../../stateManagement/context/AppContext';
import styles from './WizardMenuItems.module.scss';

const { wizardMenuNavBar, wizardMenuContainer, wizardMenuList, backToPortalLink, applicationNumberLink, menuItemLink, menuItemChildLink } = styles;

interface WizardMenuItemsProps {
    wizardStepPropsArray?: WizardStepProps[];
    applicationNumber: string;
    showBackToManageComplaints?: boolean;
    throwEventToChildForm?: boolean;
    callChildFormEvent?: any;
}

const WizardMenuItems = (props: WizardMenuItemsProps) => {
    const { wizardStepPropsArray, applicationNumber, showBackToManageComplaints, throwEventToChildForm, callChildFormEvent } = props;

    const appContext = React.useContext(AppContext);
    const isResubmit = appContext.wizardState.wizardMode === WizardMode.Resubmit;

    const displayMenuItem = (
        id: number,
        isChild: boolean,
        dataTestId: string,
        isDisplayed: boolean,
        currentState: WizardStepCurrentStateEnum,
        cssClassName: string,
        displayName?: string,
        isActive?: boolean,
    ) => {
        if (!isDisplayed) {
            return null;
        }

        const iconElement = isChild ? childIcon(isActive!) : progressIcon(currentState);
        const newDataTestId = isChild === true ? `${dataTestId}_child` : dataTestId;

        return (
            <li data-testid={newDataTestId} key={id} className={`${cssClassName} nav-item`} role='menuitem'>
                <WizardMenuItem iconElement={iconElement} displayName={displayName!} />
            </li>
        );
    };

    // Build the child menu group AND the next node as well, due to the forward-only approach of looping through the menu hierarchy
    const displayChildMenuItemGroup = (childMenuItems: WizardStepProps[], currentMenuItem: WizardStepProps, id: number) => {
        // as we treat the child node as a step, we contain the parent node text within the child WizardStep and build them both at once
        const isAnyChildActive = isAnyChildMenuItemActive(childMenuItems);
        const menuItemIsChild = false;
        const firstChild = childMenuItems[0];
        // for a NEW application:
        //  - for the parent of a child, if the menu item of the child is active and invalid we need to mark the parent invalid
        // for a RESUBMIT application:
        //  - for the parent of a child, the node will not initially be shown (expanded), so if the child menu item is invalid we need to mark parent invalid
        let parentState = isAnyChildActive && isAnyChildMenuItemInvalid(childMenuItems) ? WizardStepCurrentStateEnum.invalidError : firstChild.currentState;
        if (isResubmit === true) {
            parentState = isAnyChildMenuItemInvalid(childMenuItems) ? WizardStepCurrentStateEnum.invalidError : firstChild.currentState;
        }
        const parentDisplayed = isAnyChildMenuItemToBeDisplayed(childMenuItems); // parent is always displayed ONLY when at least one child is marked displayed
        const uniqueKeyId = `${firstChild.index}-${id}`;

        return (
            <React.Fragment key={uniqueKeyId}>
                {displayMenuItem(
                    firstChild.index, // use the index to uniquely identify the wizardStep for use in react Key
                    menuItemIsChild,
                    firstChild.dataTestId, // must use the first child NOT current menu item
                    parentDisplayed,
                    parentState,
                    getMenuItemLinkClassName(isAnyChildActive),
                    firstChild.parentDisplayName,
                )}
                {isAnyChildActive &&
                    childMenuItems.map((item, index) => {
                        return displayMenuItem(
                            item.index, // use the index to uniquely identify the wizardStep for use in react Key
                            true, // isChild
                            item.dataTestId,
                            item.isDisplayed,
                            item.currentState,
                            getMenuItemChildLinkClassName(item.isActive, item.isLastChild),
                            item.displayName,
                            item.isActive,
                        );
                    })}
                {displayMenuItem(
                    id,
                    menuItemIsChild,
                    currentMenuItem.dataTestId,
                    currentMenuItem.isDisplayed,
                    currentMenuItem.currentState,
                    getMenuItemLinkClassName(currentMenuItem.isActive),
                    currentMenuItem.displayName,
                )}
            </React.Fragment>
        );
    };

    const getMenuItemLinkClassName = (isActive: boolean) => {
        return `${menuItemLink} ${isActive ? styles.active : ''}`;
    };

    const getMenuItemChildLinkClassName = (isActive: boolean, isLastChild?: boolean) => {
        return `${menuItemChildLink} ${isActive ? styles.active : ''} ${isLastChild ? styles.isLastChild : ''}`;
    };

    const storeChildItem = (item: WizardStepProps) => {
        childStepsArray.push(item);
        return null;
    };

    // collect any child nodes for special treatment
    let childStepsArray: WizardStepProps[] = [];

    const displayMenuItems = () => {
        return (
            <>
                {wizardStepPropsArray &&
                    wizardStepPropsArray.map((item, index) => {
                        if (item.isChild) {
                            // we store each child node until time to process them all (which is when we hit the next parent node)
                            return storeChildItem(item);
                        } else {
                            if (childStepsArray && childStepsArray.length >= 1) {
                                // Display all child nodes, the current menu item, and then clear the child nodes store
                                // Clear the child nodes store and pass a new temp store (in case we have more than one child menu items set)
                                const tempChildStepsArray = childStepsArray.map(x => x);
                                childStepsArray = [];
                                return displayChildMenuItemGroup(tempChildStepsArray, item, index);
                            }
                            return displayMenuItem(
                                index,
                                false,
                                item.dataTestId,
                                item.isDisplayed,
                                item.currentState,
                                getMenuItemLinkClassName(item.isActive),
                                item.displayName,
                            );
                        }
                    })}
            </>
        );
    };

    // exclude navbar-expand-md so it stacks vertically by default (means we have to add our own Hamburger for mobile/small screenss)
    return (
        <nav className={`${wizardMenuNavBar} navbar`} aria-label='FDRR registration menu' role='navigation'>
            <div className={`${wizardMenuContainer}`} id='side-menu'>
                <ul className={`${wizardMenuList} navbar-nav`} id='navbarNavDropdown' role='menubar'>
                    {showBackToManageComplaints === true
                        ? displayBackToManageComplaintsLink(backToPortalLink, appContext, throwEventToChildForm, callChildFormEvent)
                        : displayBackToPortalLink(backToPortalLink, appContext)}
                    {displayApplicationNumber(applicationNumberLink, applicationNumber)}
                    {displayMenuItems()}
                </ul>
            </div>
        </nav>
    );
};

export default WizardMenuItems;
