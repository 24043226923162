import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrashAlt,
    faCaretUp,
    faCaretDown,
    faCheck,
    faTimes,
    faBomb,
    faAngleRight,
    faExclamationTriangle,
    faSync,
    faCheckCircle,
    faAngleUp,
    faAngleDown,
    faSkullCrossbones,
    faFolderPlus,
    faTimesCircle,
    faUserCheck,
} from '@fortawesome/free-solid-svg-icons';

export const trashIcon = () => {
    return <FontAwesomeIcon color='black' icon={faTrashAlt} />;
};

export const chevronUpIcon = () => {
    return <FontAwesomeIcon icon={faCaretUp} />;
};

export const chevronDownIcon = () => {
    return <FontAwesomeIcon icon={faCaretDown} />;
};

export const angleUpIcon = () => {
    return <FontAwesomeIcon icon={faAngleUp} />;
};

export const angleDownIcon = () => {
    return <FontAwesomeIcon icon={faAngleDown} />;
};

export const bombIcon = () => {
    return <FontAwesomeIcon color='black' icon={faBomb} />;
};

export const angleRightIcon = () => {
    return <FontAwesomeIcon icon={faAngleRight} />;
};

export const exclamationIcon = () => {
    return <FontAwesomeIcon icon={faExclamationTriangle} size='1x' />;
};

export const spinnerIcon = () => {
    return <FontAwesomeIcon icon={faSync} spin={true} fixedWidth={true} />;
};

export const checkCircleIcon = () => {
    return <FontAwesomeIcon icon={faCheckCircle} />;
};

export const skullCrossbonesIcon = () => {
    return <FontAwesomeIcon icon={faSkullCrossbones} />;
};

export const newFolderIcon = () => {
    return <FontAwesomeIcon color='black' icon={faFolderPlus} />;
};

export const closeIcon = () => {
    return <FontAwesomeIcon color='black' icon={faTimesCircle} />;
};

export const userCheckIcon = () => {
    return <FontAwesomeIcon color='black' icon={faUserCheck} />;
};

export const checkIcon = (className?: string) => {
    return <FontAwesomeIcon className={className} icon={faCheck} />; // 'check'
};

export const timesIcon = (className?: string) => {
    return <FontAwesomeIcon data-testid='iconTimes' className={className} icon={faTimes} />;
};
