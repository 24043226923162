import { DEFAULT_DATE_OF_BIRTH, UNINITIALISED } from '../services/Constants';
import { AddressTypeEnum, AttachmentDocumentType } from '../services/Enums';
import {
    IAddress,
    IApplication,
    IApplicationSubmit,
    IAttachmentMetadata,
    IComplaintMechanism,
    IOutlet,
    IProfessionalAssociation,
    IUser,
} from './interfacesApi';

const fullgraddiplomaevidence: IAttachmentMetadata = {
    attachmentId: '',
    fileName: '',
    documentType: AttachmentDocumentType.FullGraduateDiplomaOfFDR,
    applicationId: '',
    isDeleted: null,
    azureBlobName: '',
};

const threeunitsevidence: IAttachmentMetadata = {
    attachmentId: '',
    fileName: '',
    documentType: AttachmentDocumentType.ThreeUnits,
    applicationId: '',
    isDeleted: null,
    azureBlobName: '',
};

export const defaultAttachmentsData: IAttachmentMetadata[] = [fullgraddiplomaevidence, threeunitsevidence];

export const defaultBusinessAddressData: IAddress = {
    addressId: UNINITIALISED,
    addressType: AddressTypeEnum.Business,
    streetAddress: '',
    town: '',
    state: '',
    postcode: '',
    country: 'Australia',
    latitude: -1,
    longitude: -1,
    setByAutocomplete: false,
};

// used externally
export const defaultOutletPractitionerData: IOutlet = {
    outletId: '',
    name: '',
    organisationFundingType: null,
    phone: '',
    mobile: '',
    fax: '',
    tty: '',
    tollFreePhone: '',
    registerEmail: '',
    webAddress: '',
    isWheelchairAccessAvailable: false,
    isChildCareAvailable: false,
    isInterpreterAvailable: false,
    isParkingAvailable: false,
    isBusAvailable: false,
    isTaxiRankAvailable: false,
    isTramAvailable: false,
    isTrainAvailable: false,
    costType: null,
    additionalCostInfo: '',
    serviceDelivery: [],
    isServiceDeliveryFaceToFace: false,
    isServiceDeliveryPhone: false,
    isServiceDeliveryOnline: false,
    businessAddress: { ...defaultBusinessAddressData },
    postalAddress: null,
    isRoomsToBeArranged: null,
    additionalServiceDelivery: [],
    isAdditionalComediation: false,
    isAdditionalGenderBalanced: false,
    isAdditionalLegallyAssist: false,
    isAdditionalChildInclusive: false,
    scope: [],
    isScopeChildSupport: false,
    isScopeProperty: false,
    isScopeTheHague: false,
    clientGroup: [],
    isClientGroupLgbtqi: false,
    isClientGroupCarers: false,
    isClientGroupAboriginal: false,
    isClientGroupTorresStrait: false,
    isClientGroupCulturallyDiverse: false,
    isCultureGroupSpecific: null,
    cultureGroupSpecificDetail: '',
    isLanguageSpecific: null,
    languageSpecificDetail: '',
    isReligionSpecific: null,
    religionSpecificDetail: '',
    otherService: [],
    isOtherServicePractitioners: false,
    isOtherServiceStudents: false,
    openingHours: [],
    inHoursByAppointment: null,
    inHoursWalkIn: null,
    outsideHours: null,
    outsideHoursAdditionalCost: null,
    // serviceCoverageKm: null,
    serviceCoverageOwnExpense: null,
    intakeServiceCost: null,
    intakeServiceNotes: '',
    sessionServiceCost: null,
    sessionServiceNotes: '',
    certificateServiceCost: null,
    certificateServiceNotes: '',
    roomExpense: null,
    feeDetailOnWebsite: null,
    outletStatus: null,
    recordActiveFromDate: null,
    recordActiveToDate: null,
    isActive: true, // need to default to true
};

export const defaultApplicationData: IApplication = {
    applicationId: UNINITIALISED,
    personalDetails: null,
    applicationStatus: null,
    accreditationStatus: null,
    currentApplicationStep: 0,
    attachments: defaultAttachmentsData,
    hasPreviouslyApplied: null,
    previousApplicationIdentifier: UNINITIALISED,
    qualification: null,
    qualificationOrNMASDetails: null,
    nmasExpiryDate: undefined,
    hasOffenceConvictions: null,
    hasCurrentNationalPoliceCheck: null,
    isProhibitedFromWorkingWithChildren: null,
    completeWorkingWithChildrenCheck: null,
    isWorkingWithChildrenCheckNotApplicable: null,
    // membershipComplaint: null, // UI only
    activeComplaintsMechanismObj: null, // UI only
    complaintsMechanism: [],
    zNumber: 0,
    registrationNumber: 0,
    hasDeclaredAndConsented: null,
    consentToPublishOnPublicRegister: null,
    outletPractitioner: { ...defaultOutletPractitionerData },
    outlets: [], // or null?
    reworkSections: null,
    accreditationConditions: null,
};

export const defaultApplicationSubmitData: IApplicationSubmit = { htmlSummary: '', isResubmitted: false };

export const defaultAddressData: IAddress = {
    addressId: UNINITIALISED,
    addressType: AddressTypeEnum.Business,
    streetAddress: '',
    town: '',
    state: '',
    postcode: '',
    country: 'Australia',
    latitude: -1,
    longitude: -1,
    setByAutocomplete: false,
};

export const defaultUserData: IUser = {
    id: 0,
    userName: '',
    isAuthenticated: false,
    isProfileComplete: false,
    title: '',
    firstName: '',
    preferredName: '',
    middleName: '',
    familyName: '',
    gender: '',
    consentToPublishGenderOnRegister: null,
    dateOfBirth: DEFAULT_DATE_OF_BIRTH,
    emailAddress: '',
    secondaryEmailAddress: '',
    primaryPhoneNumber: '',
    secondaryPhoneNumber: '',
    addresses: [],
    isPostalSameAsResidential: false,
};

export const defaultProfessionalAssociationData: IProfessionalAssociation = {
    professionalAssociationId: '',
    name: '',
};

export const defaultComplaintsMechanismData: IComplaintMechanism = {
    complaintsMechanismId: UNINITIALISED,
    outlet: null,
    complaintsMechanismType: null,
    complaintsProcessType: undefined,
    professionalMembershipType: null,
    membershipFromDate: undefined, // new Date(), // undefined
    membershipToDate: undefined, // new Date(),
    professionalAssociation: defaultProfessionalAssociationData,
    evidence: [],
    copyEvidenceWhenCloning: false,
    copyNonInsuranceEvidenceWhenCloning: false,
    attachmentExists: null,
    activeAttachmentId: undefined,
    insuranceEvidenceType: null,
    relationshipToOrganisation: undefined,
    hasProfessionalIndemnityInsuranceViaMembership: null,
    professionalIndemnityInsuranceFromDate: undefined, // new Date(), // undefined
    professionalIndemnityInsuranceToDate: undefined, // new Date(),
    recordActiveToDate: undefined,
    recordActiveFromDate: undefined,
    supercededById: null,
    supercedesId: null,
    isDeleted: false,
    isEditCopy: undefined,
    modifiedDate: new Date(), // or we get eror upon first save, even though API saves it
};
