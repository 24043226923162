import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GuidanceText from '../../controls/GuidanceText';
import HelpText from '../../controls/HelpText';
import { CapitalizeWords, SanitiseInputText } from '../../utils/Common';
import MandatoryLabelIndicator from './MandatoryLabelIndicator';
import styles from './Fields.module.scss';

const { fieldTitle, fieldValidationError, validationErrorRow, fieldFinalRow, labelNextToTextInput } = styles;

export interface InputTextFieldProps {
    id: string;
    displayName: string;
    defaultValue?: string;
    maxlength?: number;
    isReadOnly?: boolean;
    isMandatory: boolean;
    capitaliseTextOff?: boolean;
    register: any;
    errorsField: any;
    autoFocus?: boolean;
    placeHolder?: string;
    guidanceText?: string;
    helpHeaderText?: string;
    helpText?: string;
    onKeyPress?: any;
    onKeyUp?: any;
    children?: any;
    labelText?: string; // supply this to place a label text field right next to the input box, on the right
    // onBlur?: any; NOTE: do NOT use onBlur() it messes up the form tech and save of the inquiry object
}

const InputTextField = (props: InputTextFieldProps) => {
    const {
        id,
        displayName,
        defaultValue,
        maxlength,
        isReadOnly,
        isMandatory,
        capitaliseTextOff,
        register,
        errorsField,
        autoFocus,
        placeHolder,
        guidanceText,
        helpHeaderText,
        helpText,
        onKeyPress,
        onKeyUp,
        children,
        labelText,
    } = props;

    // the maxlength default is 524288
    const maxinputlength = maxlength && maxlength > 0 ? maxlength : 524288;

    const changeHandler = ({ target }: any) => {
        const newValue = SanitiseInputText(target.value);
        const valueHasChanged = newValue !== target.value;
        if (valueHasChanged === true || capitaliseTextOff === false) {
            // as we modify .value property the cursor will be moved to the end of line, we must prevent this
            const cursorPosStart = target.selectionStart;
            const cursorPosEnd = target.selectionEnd;

            target.value = capitaliseTextOff ? newValue : CapitalizeWords(newValue);

            target.selectionStart = cursorPosStart;
            target.selectionEnd = cursorPosEnd;
        }
    };

    const inputTextFieldObj = () => {
        const key = `${id}_${defaultValue}`;

        const handleSelectFocus = (e: any) => {
            e.currentTarget.select();
        };

        return (
            <input
                type='text'
                id={id}
                key={key}
                name={id}
                defaultValue={defaultValue === undefined ? '' : defaultValue}
                placeholder={placeHolder}
                readOnly={isReadOnly ? true : false}
                className={`text-input form-control`}
                aria-invalid={errorsField && errorsField.type ? 'true' : 'false'}
                {...register(id)}
                maxLength={maxinputlength}
                autoFocus={autoFocus}
                onFocus={handleSelectFocus}
                onChange={changeHandler}
                onKeyPress={onKeyPress}
                onKeyUp={onKeyUp}
            />
        );
    };

    const textFieldLayout = () => {
        return (
            <Row>
                <Col>{inputTextFieldObj()}</Col>
            </Row>
        );
    };

    const textFieldWithLabelLayout = () => {
        return (
            <Row>
                <Col md={3}>{inputTextFieldObj()}</Col>
                <Col md={1}>
                    <label htmlFor={id} className={labelNextToTextInput}>
                        {labelText}
                    </label>
                </Col>
                <Col md={6} />
            </Row>
        );
    };

    return (
        <>
            <Row>
                <Col>
                    <label className={fieldTitle} htmlFor={id}>
                        {isMandatory && <MandatoryLabelIndicator />}
                        {displayName}
                    </label>
                </Col>
            </Row>
            {guidanceText && (
                <Row>
                    <Col>
                        <GuidanceText guidanceText={guidanceText} fieldName={id} />
                    </Col>
                </Row>
            )}
            {labelText && labelText !== '' ? textFieldWithLabelLayout() : textFieldLayout()}
            <Row>
                <Col className={fieldValidationError}>{errorsField && <span role='alert'>{errorsField.message}</span>}</Col>
            </Row>
            <Row className={validationErrorRow}>
                <Col className={fieldValidationError}>{errorsField && errorsField.validate && <span role='alert'>{errorsField.validate.message}</span>}</Col>
            </Row>
            {children && (
                <Row>
                    <Col>{children}</Col>
                </Row>
            )}
            {helpHeaderText && (
                <Row>
                    <Col>
                        <HelpText helpHeaderText={helpHeaderText} helpText={helpText} fieldName={id} />
                    </Col>
                </Row>
            )}
            <Row className={fieldFinalRow}>
                <Col />
            </Row>
        </>
    );
};

export default InputTextField;
