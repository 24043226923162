// personalDetails-Name page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the Introduction page
//  - allows navigation forward to the Personal details-Contact page
import React, { useContext, useEffect, useRef, useState } from 'react';
import { IAddress } from '../../../api/interfacesApi';
import { AddressTypeEnum, AjaxCallStateEnum, WizardStepNumber } from '../../../services/Enums';
import { RegoApplicationPageProps } from '../../PageInterfaces';
import { SubmitThisPageIfActive } from '../../PageFunctions';
import { SetApplicationNumber } from '../../../components/wizard/wizardStateManager';
import { MoveWizardForward } from '../../../components/wizard/wizardStepFunctions';
import UserActions from '../../../actions/userActions';
import { toastErrorMessage } from '../../../actions/toastrMessages';
import ErrorSummary from '../../../controls/ErrorSummary';
import { HEADER_PERSONALDETAILS_NAME } from '../../../services/Constants';
import { VALIDATION_ERROR_POPUP_MESSAGE, VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../../services/Validation';
import AppContext from '../../../stateManagement/context/AppContext';
import PageWrapper from '../../../components/pageWrapper';
import PageTitle from '../../../components/pageTitle';
import PageMandatoryLabelText from '../../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import { defaultAddressData } from '../../../api/defaultData';
import PersonalDetailsNameFieldsEdit from '../../../fieldLayout/personalDetailsNameFieldsEdit';
import { ErrorOnSubmitForm } from '../../../utils/AppUtils';

const PersonalDetailsNamePage = (props: RegoApplicationPageProps) => {
    const {
        inquiry,
        authService,
        dispatch,
        watch,
        handleClickPrevious,
        handleSubmit,
        register,
        setError,
        trigger,
        control,
        errors,
        isValid,
        isActive,
        isDirty,
        setValue,
        ajaxCallStateForPage,
        backEndValidationErrors,
        triggerSubmit,
        setTriggerSubmit,
    } = props;

    const appContext = useContext(AppContext);
    const formRef = useRef();
    const userActions = new UserActions(authService);
    // even though we have redux states, we HAVE to capture the attempt due to navigation and Get and Save user calls on the page
    const [attemptingSave, setAttemptingSave] = useState(false);
    /*
    const [showNoteDateOfBirthWarning, setShowNoteDateOfBirthWarning] = useState(false);
    const dateOfBirth = watch('inquiry.personalDetails.dateOfBirth', inquiry?.personalDetails.dateOfBirth);
    const dateOfBirthNumberOfYears = dateOfBirth && isValidDate(dateOfBirth) ? new Date().getFullYear() - new Date(dateOfBirth).getFullYear() : 0;
    const dateOfBirthMessage = NOTE_DATEOFBIRTH_MESSAGE.replace('%%', dateOfBirthNumberOfYears.toString());
*/

    const onChangeTitle = (event: { target: { value: any } }) => {
        trigger(); // re-validate
    };

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors.errors?.length > 0) {
            backEndValidationErrors.errors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'DateOfBirth':
                        setError('inquiry.personalDetails.dateOfBirth', { type: 'manual', message: validationError.errorMessage });
                        break;
                    case 'FirstName':
                        setError('inquiry.personalDetails.firstName', { type: 'manual', message: validationError.errorMessage });
                        break;
                    case 'FamilyName':
                        setError('inquiry.personalDetails.familyName', { type: 'manual', message: validationError.errorMessage });
                        break;
                    case 'Gender':
                        setError('inquiry.personalDetails.gender', { type: 'manual', message: validationError.errorMessage });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    // initial load of any existing data
    useEffect(() => {
        if (inquiry && inquiry.application?.zNumber !== null && inquiry.application.zNumber !== 0) {
            // can finally show the Z-number (application number on the wizard)
            SetApplicationNumber(appContext, inquiry.application.zNumber);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inquiry?.application]);

    // check dispatch success state
    useEffect(() => {
        if (isActive === true && ajaxCallStateForPage && ajaxCallStateForPage.pageNumber === WizardStepNumber.PersonalDetailsName) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                setAttemptingSave(false);
                displayBackEndValidations();
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setAttemptingSave(false);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (attemptingSave === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK && !appContext.wizardState.triggerSubmit) {
                    // IF VALID (or OK to move forward):
                    setAttemptingSave(false);
                    MoveWizardForward(appContext, isValid);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    /*
    // check if DOB is valid but inside the validation checks, and outside the desired range, place up a warning note
    useEffect(() => {
        // reset DOB flags if user changes DOB
        setShowNoteDateOfBirthWarning(false);
    }, [dateOfBirth]);

    // if DOB has changed and we are not showing the Confirm Changes dialog then we check it here
    if (dateOfBirthFieldIsValid() && isDirty && dateOfBirth && showNoteDateOfBirthWarning === false) {
        // if valid date (which confirms validation rules already)
        if (isValidDate(dateOfBirth)) {
            const dateToCheck = new Date(dateOfBirth);
            if (dateToCheck < ninetyYearsAgo() || dateToCheck > eighteenYearsAgo()) {
                setShowNoteDateOfBirthWarning(true);
            }
        }
    } */

    // either load existing or create new records for residential/postal address
    const ConfirmOrInjectAddresses = (data: any) => {
        // if new record we need to inject empty addresses collection for UI
        if (inquiry.personalDetails.addresses === undefined || inquiry.personalDetails.addresses === null) {
            inquiry.personalDetails.addresses = [];
        }

        if (inquiry.personalDetails.addresses.length === 0) {
            // const newResidentialAddress: IAddress = { ...defaultAddressData, addressType: AddressTypeEnum.Business };
            const newPostalAddress: IAddress = { ...defaultAddressData, addressType: AddressTypeEnum.Postal };

            // inquiry.personalDetails.addresses.push(newResidentialAddress);
            inquiry.personalDetails.addresses.push(newPostalAddress);
        }

        // load existing addresses and contact data if exist
        if (inquiry.personalDetails.addresses && inquiry.personalDetails.addresses.length > 0) {
            if (data.inquiry.personalDetails.addresses === undefined) {
                data.inquiry.personalDetails.addresses = [];
            }
            if (data.inquiry.personalDetails.addresses.length === 0) {
                data.inquiry.personalDetails.addresses.push(...inquiry.personalDetails.addresses);
            }

            // plus other user fields not on this form
            data.inquiry.personalDetails.secondaryEmailAddress = inquiry.personalDetails.secondaryEmailAddress;
            data.inquiry.personalDetails.primaryPhoneNumber = inquiry.personalDetails.primaryPhoneNumber;
            data.inquiry.personalDetails.secondaryPhoneNumber = inquiry.personalDetails.secondaryPhoneNumber;
        }

        // do not need to set this yet or it creates an empty address record (with history)
        /*
        // set address Guid if not already
        if (data.inquiry.personalDetails.addresses !== null) {
            if (data.inquiry.personalDetails.addresses?.length > 0) {
                data.inquiry.personalDetails.addresses.forEach((addressItem: IAddress, index: number) => {
                    if (addressItem.addressId === UNINITIALISED) {
                        const guid = new Guid();
                        data.inquiry.personalDetails.addresses[index].addressId = guid.empty;
                    }
                });
            }
        } */
    };

    const submitForm = (data: any) => {
        if (isValid) {
            // need to ensure next page draws (empty) addresses if this is a new record, we expect one Residential and one Postal
            ConfirmOrInjectAddresses(data);

            // Save changes to application (attempt save, if that fails we do NOT move the Wizard step)
            setAttemptingSave(true);

            dispatch(userActions.SaveUser(data.inquiry.personalDetails));
        } else {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    SubmitThisPageIfActive(isActive, triggerSubmit, formRef, setTriggerSubmit);

    return (
        <>
            <PageTitle title='Personal details' description={HEADER_PERSONALDETAILS_NAME} />
            <PageWrapper
                pageName='PersonalDetailsName'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={handleClickPrevious}
            >
                <PageFieldsTitle title='Name details' />
                <PageMandatoryLabelText />
                <ErrorSummary errors={errors} />
                <PersonalDetailsNameFieldsEdit
                    inquiry={inquiry}
                    register={register}
                    control={control}
                    errors={errors}
                    watch={watch}
                    isValid={isValid}
                    isDirty={isDirty}
                    trigger={trigger}
                    setValue={setValue}
                    onChangeTitle={onChangeTitle}
                />
            </PageWrapper>
        </>
    );
};

export default PersonalDetailsNamePage;
