// Outlet Details-Service Fees page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the Outlet Details-Accessibility page
//  - allows navigation forward to the Outlet Details-Declaration page
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Inquiry, IOutlet } from '../../api/interfacesApi';
import { EditPageProps } from '../../pages/PageInterfaces';
import { RoomChargeType, ServiceCostType } from '../../services/Enums';
import { GUIDANCETEXT_OUTLET_FEESFORFDRSERVICES, NOTE_OUTLET_TRAVELLINGCOSTS_NO, NOTE_OUTLET_TRAVELLINGCOSTS_YES } from '../../services/Constants';
import NoteText from '../../controls/NoteText';
import { EmptyRow } from '../../controls/EmptyRow';
import PageMandatoryLabelText from '../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../components/fields/PageFieldsTitle';
import LabelField from '../../components/fields/LabelField';
import InputTextField from '../../components/fields/InputTextField';
import RadioButtonGroupField, { RadioButtonOption } from '../../components/fields/RadioButtonGroupField';

interface UIState {
    showIntakeServiceNotesField: boolean;
    showSessionServiceNotesField: boolean;
    showCertificateServiceNotesField: boolean;
    showFeeInfoOnWebsiteField: boolean;
    showRoomHireExpensesField: boolean;
    showOutsideHoursAdditionalCostField: boolean;
    showTravellingCostsField: boolean;
    showTravellingCostsYesNote: boolean;
    showTravellingCostsNoNote: boolean;
}

const initUiState: UIState = {
    showIntakeServiceNotesField: false,
    showSessionServiceNotesField: false,
    showCertificateServiceNotesField: false,
    showFeeInfoOnWebsiteField: false,
    showRoomHireExpensesField: false,
    showOutsideHoursAdditionalCostField: false,
    showTravellingCostsField: false,
    showTravellingCostsYesNote: false,
    showTravellingCostsNoNote: false,
};

const OutletServiceFeesPage = (props: EditPageProps) => {
    const { inquiry, register, errors, control, setValue, watch } = props;

    const [displayState, setDisplayState] = useState(initUiState);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_refreshUI, doRefreshUI] = useState(0);

    const isRoomsToBeArrangedWatched = watch(
        'inquiry.application.outletPractitioner.isRoomsToBeArranged',
        inquiry.application.outletPractitioner?.isRoomsToBeArranged,
    );

    const outsideHoursWatched = watch('inquiry.application.outletPractitioner.outsideHours', inquiry.application.outletPractitioner?.outsideHours);

    const webAddressWatched = watch('inquiry.application.outletPractitioner.webAddress', inquiry.application.outletPractitioner?.webAddress);

    const serviceCoverageOwnExpenseWatched = watch(
        'inquiry.application.outletPractitioner.serviceCoverageOwnExpense',
        inquiry.application.outletPractitioner?.serviceCoverageOwnExpense,
    );

    const radioOptionsIntakeServiceCost: RadioButtonOption[] = [
        { label: 'Free', value: ServiceCostType.Free, disabled: false },
        { label: 'Negotiable based on ability to pay', value: ServiceCostType.Negotiable, disabled: false },
        { label: 'Fees charged', value: ServiceCostType.Charged, disabled: false },
    ];

    const radioOptionsSessionServiceCost: RadioButtonOption[] = [
        { label: 'Free', value: ServiceCostType.Free, disabled: false },
        { label: 'Negotiable based on ability to pay', value: ServiceCostType.Negotiable, disabled: false },
        { label: 'Fees charged', value: ServiceCostType.Charged, disabled: false },
    ];

    const radioOptionsCertificateServiceCost: RadioButtonOption[] = [
        { label: 'Free', value: ServiceCostType.Free, disabled: false },
        { label: 'Negotiable based on ability to pay', value: ServiceCostType.Negotiable, disabled: false },
        { label: 'Charged', value: ServiceCostType.Charged, disabled: false },
    ];

    const radioOptionsRoomExpense: RadioButtonOption[] = [
        { label: 'Yes', value: RoomChargeType.Y, disabled: false },
        { label: 'Maybe, depending on mediation services required', value: RoomChargeType.M, disabled: false },
        { label: 'No', value: RoomChargeType.N, disabled: false },
    ];

    const radioOptionsOutsideHoursAdditionalCost: RadioButtonOption[] = [
        { label: 'Yes', value: true, disabled: false },
        { label: 'No', value: false, disabled: false },
    ];

    const radioOptionsFeeDetailOnWebsite: RadioButtonOption[] = [
        { label: 'Yes', value: true, disabled: false },
        { label: 'No', value: false, disabled: false },
    ];

    const setInitialUIState = (inquiry: Inquiry) => {
        displayState.showIntakeServiceNotesField = inquiry.application.outletPractitioner?.intakeServiceCost === ServiceCostType.Charged;
        displayState.showSessionServiceNotesField = inquiry.application.outletPractitioner?.sessionServiceCost === ServiceCostType.Charged;
        displayState.showCertificateServiceNotesField = inquiry.application.outletPractitioner?.certificateServiceCost === ServiceCostType.Charged;
        displayState.showFeeInfoOnWebsiteField =
            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner?.webAddress.length > 0 ? true : false;
        displayState.showRoomHireExpensesField = inquiry.application.outletPractitioner?.isRoomsToBeArranged === true;
        displayState.showOutsideHoursAdditionalCostField = inquiry.application.outletPractitioner?.outsideHours === true;
        displayState.showTravellingCostsField =
            inquiry.application.outletPractitioner?.serviceCoverageOwnExpense !== undefined ||
            inquiry.application.outletPractitioner?.serviceCoverageOwnExpense !== null;
        // show the YES when user will not cover as own expense
        displayState.showTravellingCostsYesNote = inquiry.application.outletPractitioner?.serviceCoverageOwnExpense === false;
        // show the NO when user will cover as own expense
        displayState.showTravellingCostsNoNote = inquiry.application.outletPractitioner?.serviceCoverageOwnExpense === true;

        // setDisplayState(displayState);
    };

    const updatePractitionerOutletFeeFields = (inquiry: Inquiry, outlet: IOutlet | null) => {
        if (outlet === null) {
            return;
        }

        setInitialUIState(inquiry);

        // service coverage own expense field change tracking
        if (outlet && outlet.serviceCoverageOwnExpense === false && displayState.showTravellingCostsYesNote === false) {
            // show the YES when user will not cover as own expense
            displayState.showTravellingCostsYesNote = true;
            displayState.showTravellingCostsNoNote = false;
        }
        if (outlet && outlet.serviceCoverageOwnExpense === true && displayState.showTravellingCostsNoNote === false) {
            // show the NO when user will cover as own expense
            displayState.showTravellingCostsYesNote = false;
            displayState.showTravellingCostsNoNote = true;
        }

        // website address change tracking
        if (outlet && outlet.webAddress.length > 0 && displayState.showFeeInfoOnWebsiteField === false) {
            displayState.showFeeInfoOnWebsiteField = true;
        }
        if (
            outlet &&
            (outlet.webAddress === undefined || outlet.webAddress === null || outlet.webAddress === '') &&
            displayState.showFeeInfoOnWebsiteField === true
        ) {
            displayState.showFeeInfoOnWebsiteField = false;
        }

        // room hire expenses tracking
        if (outlet && outlet.isRoomsToBeArranged === true && displayState.showRoomHireExpensesField === false) {
            displayState.showRoomHireExpensesField = true;
        }
        if (outlet && (outlet.isRoomsToBeArranged === undefined || outlet.isRoomsToBeArranged === false) && displayState.showRoomHireExpensesField === true) {
            displayState.showRoomHireExpensesField = false;
        }

        // outside hours tracking
        if (outlet && outlet.outsideHours === true && displayState.showOutsideHoursAdditionalCostField === false) {
            displayState.showOutsideHoursAdditionalCostField = true;
        }
        if (outlet && (outlet.outsideHours === undefined || outlet.outsideHours === false) && displayState.showOutsideHoursAdditionalCostField === true) {
            displayState.showOutsideHoursAdditionalCostField = false;
        }

        // travelling costs tracking
        if (
            outlet &&
            outlet.serviceCoverageOwnExpense !== undefined &&
            outlet.serviceCoverageOwnExpense !== null &&
            displayState.showTravellingCostsField === false
        ) {
            displayState.showTravellingCostsField = true;
        }
        if (
            outlet &&
            (outlet.serviceCoverageOwnExpense === undefined || outlet.serviceCoverageOwnExpense === null) &&
            displayState.showTravellingCostsField === true
        ) {
            displayState.showTravellingCostsField = false;
        }

        // update state now
        setDisplayState(displayState);
    };

    // set the initial values for the display state upon initial load
    useEffect(() => {
        // setInitialUIState(inquiry);

        // watch is NOT working as expected, just call straight up from here:
        updatePractitionerOutletFeeFields(inquiry, inquiry.application.outletPractitioner);
        doRefreshUI(prev => prev + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inquiry]);

    useEffect(() => {
        if (isRoomsToBeArrangedWatched === true || isRoomsToBeArrangedWatched === false) {
            updatePractitionerOutletFeeFields(inquiry, inquiry.application.outletPractitioner);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRoomsToBeArrangedWatched]);

    useEffect(() => {
        if (outsideHoursWatched === true || outsideHoursWatched === false) {
            updatePractitionerOutletFeeFields(inquiry, inquiry.application.outletPractitioner);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outsideHoursWatched]);

    useEffect(() => {
        if (webAddressWatched !== undefined && webAddressWatched !== null) {
            updatePractitionerOutletFeeFields(inquiry, inquiry.application.outletPractitioner);
            // refresh this UI for when we clear the web address
            doRefreshUI(prev => prev + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webAddressWatched]);

    useEffect(() => {
        if (serviceCoverageOwnExpenseWatched !== undefined && serviceCoverageOwnExpenseWatched !== null) {
            updatePractitionerOutletFeeFields(inquiry, inquiry.application.outletPractitioner);
            // refresh this UI for when we clear the web address
            doRefreshUI(prev => prev + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceCoverageOwnExpenseWatched]);

    /*
    useEffect(() => {
        updatePractitionerOutletFeeFields(outletPractitionerWatched);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outletPractitionerWatched]); */

    const onChangeIntakeServiceCost = (event: { target: { value: any } }) => {
        // manage the value manually as ref did not work for the react hook form
        const valueSelected = event.target.value;
        setValue('inquiry.application.outletPractitioner.intakeServiceCost', valueSelected, { shouldDirty: true, shouldValidate: true });
        if (valueSelected === ServiceCostType.Charged) {
            if (displayState.showIntakeServiceNotesField === false) {
                displayState.showIntakeServiceNotesField = true;
            }
        } else {
            // hide extra field and remove data if exists
            if (displayState.showIntakeServiceNotesField === true) {
                displayState.showIntakeServiceNotesField = false;
                setValue('inquiry.application.outletPractitioner.intakeServiceNotes', '');
            }
        }
    };

    const onChangeSessionServiceCost = (event: { target: { value: any } }) => {
        // manage the value manually as ref did not work for the react hook form
        const valueSelected = event.target.value;
        setValue('inquiry.application.outletPractitioner.sessionServiceCost', valueSelected, { shouldDirty: true, shouldValidate: true });
        if (valueSelected === ServiceCostType.Charged) {
            if (displayState.showSessionServiceNotesField === false) {
                displayState.showSessionServiceNotesField = true;
            }
        } else {
            // hide extra field and remove data if exists
            if (displayState.showSessionServiceNotesField === true) {
                displayState.showSessionServiceNotesField = false;
                setValue('inquiry.application.outletPractitioner.sessionServiceNotes', '');
            }
        }
    };

    const onChangeCertificateServiceCost = (event: { target: { value: any } }) => {
        // manage the value manually as ref did not work for the react hook form
        const valueSelected = event.target.value;
        setValue('inquiry.application.outletPractitioner.certificateServiceCost', valueSelected, { shouldDirty: true, shouldValidate: true });
        if (valueSelected === ServiceCostType.Charged) {
            if (displayState.showCertificateServiceNotesField === false) {
                displayState.showCertificateServiceNotesField = true;
            }
        } else {
            // hide extra field and remove data if exists
            if (displayState.showCertificateServiceNotesField === true) {
                displayState.showCertificateServiceNotesField = false;
                setValue('inquiry.application.outletPractitioner.certificateServiceNotes', '');
            }
        }
    };

    const onChangeFeeDetailOnWebsite = (event: { target: { value: any } }) => {
        // manage the value manually as ref did not work for the react hook form
        const valueSelected = event.target.value;
        setValue('inquiry.application.outletPractitioner.feeDetailOnWebsite', valueSelected, { shouldDirty: true, shouldValidate: true });
    };

    const onChangeRoomExpense = (event: { target: { value: any } }) => {
        // manage the value manually as ref did not work for the react hook form
        const valueSelected = event.target.value;
        setValue('inquiry.application.outletPractitioner.roomExpense', valueSelected, { shouldDirty: true, shouldValidate: true });
    };

    const onChangeOutsideHoursAdditionalCost = (event: { target: { value: any } }) => {
        // manage the value manually as ref did not work for the react hook form
        const valueSelected = event.target.value;
        setValue('inquiry.application.outletPractitioner.outsideHoursAdditionalCost', valueSelected, { shouldDirty: true, shouldValidate: true });
    };

    const travellingCostsYesNote = () => {
        return (
            <Row>
                <Col md='11'>
                    <NoteText displayText={NOTE_OUTLET_TRAVELLINGCOSTS_YES} />
                </Col>
                <Col md='1' className='justify-content-end' />
            </Row>
        );
    };

    const travellingCostsNoNote = () => {
        return (
            <Row>
                <Col md='11'>
                    <NoteText displayText={NOTE_OUTLET_TRAVELLINGCOSTS_NO} />
                </Col>
                <Col md='1' className='justify-content-end' />
            </Row>
        );
    };

    const currentTravellingCostsValue =
        inquiry.application.outletPractitioner?.serviceCoverageOwnExpense && inquiry.application.outletPractitioner?.serviceCoverageOwnExpense === true
            ? false
            : true; // display YES if false, and NO if true (radio btn YES=true, NO=false)
    // outletPractitionerWatched.serviceCoverageOwnExpense && outletPractitionerWatched.serviceCoverageOwnExpense === true ? false : true; // display YES if false, and NO if true (radio btn YES=true, NO=false)

    return (
        <>
            <EmptyRow />
            <PageFieldsTitle title='Service fees' />
            <PageMandatoryLabelText />
            <LabelField
                id='feesForFdrServiceheaderlabel'
                displayName='Fees for FDR services'
                value=''
                isMandatory={false}
                guidanceText={GUIDANCETEXT_OUTLET_FEESFORFDRSERVICES}
            />
            <RadioButtonGroupField
                options={radioOptionsIntakeServiceCost}
                id='inquiry.application.outletPractitioner.intakeServiceCost'
                displayName='Pre-mediation/intake and assessment'
                fieldLabel=''
                defaultValue={inquiry.application.outletPractitioner?.intakeServiceCost} // only set default if value exists
                isMandatory={true}
                control={control}
                register={register}
                errorsField={errors.inquiry?.application?.outletPractitioner?.intakeServiceCost}
                onChange={onChangeIntakeServiceCost}
            />
            {displayState.showIntakeServiceNotesField && (
                <InputTextField
                    id='inquiry.application.outletPractitioner.intakeServiceNotes'
                    displayName='Pre-mediation/intake and assessment cost information'
                    defaultValue={inquiry.application.outletPractitioner?.intakeServiceNotes}
                    isMandatory={false}
                    maxlength={150}
                    capitaliseTextOff={true}
                    register={register}
                    errorsField={errors.inquiry?.application?.outletPractitioner?.intakeServiceNotes}
                />
            )}
            <RadioButtonGroupField
                options={radioOptionsSessionServiceCost}
                id='inquiry.application.outletPractitioner.sessionServiceCost'
                displayName='FDR session'
                fieldLabel=''
                defaultValue={inquiry.application.outletPractitioner?.sessionServiceCost} // only set default if value exists
                isMandatory={true}
                control={control}
                register={register}
                errorsField={errors.inquiry?.application?.outletPractitioner?.sessionServiceCost}
                onChange={onChangeSessionServiceCost}
            />
            {displayState.showSessionServiceNotesField && (
                <InputTextField
                    id='inquiry.application.outletPractitioner.sessionServiceNotes'
                    displayName='FDR session cost information'
                    defaultValue={inquiry.application.outletPractitioner?.sessionServiceNotes}
                    isMandatory={false}
                    maxlength={150}
                    capitaliseTextOff={true}
                    register={register}
                    errorsField={errors.inquiry?.application?.outletPractitioner?.sessionServiceNotes}
                />
            )}
            <RadioButtonGroupField
                options={radioOptionsCertificateServiceCost}
                id='inquiry.application.outletPractitioner.certificateServiceCost'
                displayName='Issuing certificates'
                fieldLabel=''
                defaultValue={inquiry.application.outletPractitioner?.certificateServiceCost} // only set default if value exists
                isMandatory={true}
                control={control}
                register={register}
                errorsField={errors.inquiry?.application?.outletPractitioner?.certificateServiceCost}
                onChange={onChangeCertificateServiceCost}
            />
            {displayState.showCertificateServiceNotesField && (
                <InputTextField
                    id='inquiry.application.outletPractitioner.certificateServiceNotes'
                    displayName='Certificates cost information'
                    defaultValue={inquiry.application.outletPractitioner?.certificateServiceNotes}
                    isMandatory={false}
                    maxlength={150}
                    capitaliseTextOff={true}
                    register={register}
                    errorsField={errors.inquiry?.application?.outletPractitioner?.certificateServiceNotes}
                />
            )}
            {displayState.showFeeInfoOnWebsiteField && (
                <RadioButtonGroupField
                    options={radioOptionsFeeDetailOnWebsite}
                    id='inquiry.application.outletPractitioner.feeDetailOnWebsite'
                    displayName='Fee information on website'
                    fieldLabel='Are there more details about your fees on your website?'
                    defaultValue={inquiry.application.outletPractitioner?.feeDetailOnWebsite} // only set default if value exists
                    isMandatory={true}
                    control={control}
                    register={register}
                    errorsField={errors.inquiry?.application?.outletPractitioner?.feeDetailOnWebsite}
                    onChange={onChangeFeeDetailOnWebsite}
                />
            )}
            {(displayState.showRoomHireExpensesField || displayState.showOutsideHoursAdditionalCostField || displayState.showTravellingCostsField) && (
                <LabelField id='AdditionalFeeheaderlabel' displayName='Additional fee information' value='' isMandatory={false} />
            )}
            {displayState.showRoomHireExpensesField && (
                <RadioButtonGroupField
                    options={radioOptionsRoomExpense}
                    id='inquiry.application.outletPractitioner.roomExpense'
                    displayName='Room hire expenses'
                    fieldLabel='Do you charge clients for room hire?'
                    defaultValue={inquiry.application.outletPractitioner?.roomExpense} // only set default if value exists
                    isMandatory={true}
                    control={control}
                    register={register}
                    errorsField={errors.inquiry?.application?.outletPractitioner?.roomExpense}
                    onChange={onChangeRoomExpense}
                />
            )}
            {displayState.showOutsideHoursAdditionalCostField && (
                <RadioButtonGroupField
                    options={radioOptionsOutsideHoursAdditionalCost}
                    id='inquiry.application.outletPractitioner.outsideHoursAdditionalCost'
                    displayName='Fees for appointments in non-standard business hours'
                    fieldLabel='Do you charge clients extra for out of normal business hours appointments?'
                    defaultValue={inquiry.application.outletPractitioner?.outsideHoursAdditionalCost} // only set default if value exists
                    isMandatory={true}
                    control={control}
                    register={register}
                    errorsField={errors.inquiry?.application?.outletPractitioner?.outsideHoursAdditionalCost}
                    onChange={onChangeOutsideHoursAdditionalCost}
                />
            )}
            {displayState.showTravellingCostsField && (
                <LabelField
                    id='travellingCostsLabel'
                    displayName='Travelling costs'
                    guidanceText='Do you charge clients for travel costs incurred by you to provide an FDR service?'
                    value={currentTravellingCostsValue === true ? 'Yes' : 'No'}
                    isMandatory={false}
                />
            )}
            {displayState.showTravellingCostsYesNote && travellingCostsYesNote()}
            {displayState.showTravellingCostsNoNote && travellingCostsNoNote()}
        </>
    );
};

export default OutletServiceFeesPage;
