import React, { useState } from 'react';

interface ProgressBarProps {
    progress: number;
    reset: boolean;
    className: string;
}
const ProgressBar = (props: ProgressBarProps) => {
    const { progress, reset, className } = props;
    const timeShowing = 3000; // in milliseconds

    const [showProgressBar, setShowProgressBar] = useState(true);

    if (progress === 100) {
        setTimeout(() => {
            if (progress === 100) {
                // if still 100% then make it disappear
                setShowProgressBar(false);
            }
        }, timeShowing);
    }

    if (reset === true && showProgressBar === false) {
        setShowProgressBar(true);
    }

    if (!showProgressBar) {
        return null;
    }

    return (
        <>
            <div className={`progress ${className}`}>
                <div
                    className='progress-bar progress-bar-info progress-bar-striped'
                    role='progressbar'
                    aria-valuenow={progress}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: progress + '%' }}
                >
                    {progress}%
                </div>
            </div>
        </>
    );
};

export default ProgressBar;
