// Type of Complaints mechanism page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the Criminal Convictions page
//  - allows navigation forward to the Complaints mechanism-Employee page
import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { RegoApplicationPageProps } from '../../PageInterfaces';
import { SubmitThisPageIfActive } from '../../PageFunctions';
import { EnsureData } from '../RegistrationApplicationWizard/wizardHelperFunctions';
import { ResetComplaintsMechanismCollection } from '../RegistrationApplicationForm';
import AppContext from '../../../stateManagement/context/AppContext';
import { Inquiry } from '../../../api/interfacesApi';
import { AjaxCallStateEnum, AttachmentDocumentType, ComplaintsMechanismType, WizardStepNumber } from '../../../services/Enums';
import {
    COMPLAINTS_MECHANISM_BLURB_PARAGRAPH1,
    COMPLAINTS_MECHANISM_BLURB_PARAGRAPH2,
    COMPLAINTS_MECHANISM_BLURB_PARAGRAPH3,
    GUIDANCETEXT_COMPLAINTS_MECH_TYPE,
    HEADER_COMPLAINTS,
    MODALHEADER_DATALOSSPOTENTIAL,
    MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE,
    MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_P2,
    MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_P3,
    MODALBUTTON1_OK,
    MODALBUTTON2_CANCEL,
    UNINITIALISED,
    HELPTEXT_COMPLAINTSMECHANISM_FULLMEMBERSHIP,
    HELPHEADERTEXT_FULLMEMBERSHIP,
    CLEANINGUP_PRIORDATA,
} from '../../../services/Constants';
import { VALIDATION_ERROR_POPUP_MESSAGE, VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../../services/Validation';
import Guid from '../../../services/Guid';
import { DisplayMechanismTypeInitialApplication } from '../../../utils/EnumMappings';
import { ErrorOnSubmitForm, RemoveAttachmentsFromAzureIfExist, RemoveAttachmentsFromCollectionIfExist } from '../../../utils/AppUtils';
import { Sleep } from '../../../utils/Common';
import { toastErrorMessage } from '../../../actions/toastrMessages';
import ApplicationActions from '../../../actions/applicationActions';
import AttachmentActions from '../../../actions/attachmentsActions';
import ErrorSummary from '../../../controls/ErrorSummary';
import Loader from '../../../controls/Loader';
import { ModalDialogConfirmable } from '../../../controls/ModalDialogConfirmable';
import {
    MoveWizardForward,
    SetAllFutureStepsIncomplete,
    ToggleComplaintsChildMenu,
    UpdateWizardStepsAndRepaint,
} from '../../../components/wizard/wizardStepFunctions';
import PageWrapper from '../../../components/pageWrapper';
import PageTitle from '../../../components/pageTitle';
import PageMandatoryLabelText from '../../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import RadioButtonGroupField, { RadioButtonOption } from '../../../components/fields/RadioButtonGroupField';
import LabelField from '../../../components/fields/LabelField';

interface APIstate {
    attemptingSave: boolean;
    attemptingSaveApp: boolean;
    attemptingSaveThenRemovePriorData: boolean;
    removingComplaintsData: boolean;
    refreshingAppData: boolean;
    complaintsMechanismTypeChangeOfMind?: ComplaintsMechanismType;
    dataInquiry?: Inquiry; // need to store the inquiry for when page posts back and we continue to update the app to API
}

const initApiState: APIstate = {
    attemptingSave: false,
    attemptingSaveApp: false,
    attemptingSaveThenRemovePriorData: false,
    removingComplaintsData: false,
    refreshingAppData: false,
};

const ComplaintsMechanismTypePage = (props: RegoApplicationPageProps) => {
    const {
        inquiry,
        // setInquiryObj,
        authService,
        dispatch,
        watch,
        handleClickPrevious,
        handleSubmit,
        // clearErrors,
        register,
        setError,
        setValue,
        control,
        errors,
        // trigger,
        isValid,
        isActive,
        // isDirty,
        ajaxCallStateForPage,
        backEndValidationErrors,
        triggerSubmit,
        setTriggerSubmit,
    } = props;

    const appContext = useContext(AppContext);
    const formRef = useRef();
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const attachmentActions = useMemo(() => {
        return new AttachmentActions(authService);
    }, [authService]);
    // even though we have redux states, we HAVE to capture the attempt due to navigation and Get and Save user calls on the page
    const [apiState, setApiState] = useState(initApiState);

    const complaintsMechanismTypeWatched: ComplaintsMechanismType = watch(
        'inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType',
        inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType,
    );

    // based upon the original value, we remove data as the user has changed their option whilst the data already exists
    const removePriorData = (newValue: ComplaintsMechanismType) => {
        // remove both the UI-only object and the array-based object, as well as any attachments
        RemoveAttachmentsFromAzureIfExist(attachmentActions, dispatch, AttachmentDocumentType.AssociationMembership, inquiry.application.attachments);
        inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(AttachmentDocumentType.AssociationMembership, inquiry.application.attachments);
        RemoveAttachmentsFromAzureIfExist(attachmentActions, dispatch, AttachmentDocumentType.OrgEmployment, inquiry.application.attachments);
        inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(AttachmentDocumentType.OrgEmployment, inquiry.application.attachments);
        RemoveAttachmentsFromAzureIfExist(attachmentActions, dispatch, AttachmentDocumentType.OrgPanel, inquiry.application.attachments);
        inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(AttachmentDocumentType.OrgPanel, inquiry.application.attachments);
        RemoveAttachmentsFromAzureIfExist(attachmentActions, dispatch, AttachmentDocumentType.Insurance, inquiry.application.attachments);
        inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(AttachmentDocumentType.Insurance, inquiry.application.attachments);

        // MUST remove prof association/complaint data if exist
        if (inquiry.application.activeComplaintsMechanismObj !== null) {
            inquiry.application.activeComplaintsMechanismObj = null;
            // MUST clera entire collection as we do a remove CM data call then pack the CM back in - so this will trigger an Add not an Update once all is complete
            const startAtIndexOneInstead = false;
            ResetComplaintsMechanismCollection(startAtIndexOneInstead, setValue, inquiry);
        }

        // MUST clear public value
        inquiry.application.consentToPublishOnPublicRegister = null;

        // MUST reset wizard steps
        const newWizardSteps = SetAllFutureStepsIncomplete(WizardStepNumber.ComplaintsMechanismType, appContext.wizardSteps);
        // appContext.dispatchWizardState({ type: WizardStateActionTypeEnums.activateStep, destinationStep: destIndex });
        UpdateWizardStepsAndRepaint(appContext, newWizardSteps);

        // finally set most active step otherwise user can swap between membership and employee and resume function will fail
        inquiry.application.currentApplicationStep = WizardStepNumber.ComplaintsMechanismType;

        // MUST set the new value otherwise the reload of data will just set it back to orig value! (store this for use AFTER removal of all complaints data, next step!)
        setApiState({ ...apiState, complaintsMechanismTypeChangeOfMind: newValue, attemptingSaveThenRemovePriorData: true });

        const updateOutlet = false;
        dispatch(appActions.UpdateApplication(inquiry.application, updateOutlet));
    };

    const doesPriorDataExistThatWillBeDeleted = (newValue: ComplaintsMechanismType) => {
        // check this only if:
        //  - full membership was orig value and now user now chooses GovtFunded
        //  - govt funded was orig value and now user chooses Full membership
        const isOrigValueFull =
            inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc;
        const isOrigValueGovtFunded =
            inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType === ComplaintsMechanismType.GovernmentFundedFDRService;
        const dataExists =
            (inquiry.application.activeComplaintsMechanismObj?.outlet?.outletId !== undefined &&
                inquiry.application.activeComplaintsMechanismObj?.outlet?.outletId !== '') ||
            (inquiry.application.activeComplaintsMechanismObj?.professionalAssociation?.professionalAssociationId !== undefined &&
                inquiry.application.activeComplaintsMechanismObj?.professionalAssociation?.professionalAssociationId !== '');

        if (
            (newValue === ComplaintsMechanismType.GovernmentFundedFDRService && isOrigValueFull && dataExists) ||
            (newValue === ComplaintsMechanismType.FullMembershipProfessionalAssoc && isOrigValueGovtFunded && dataExists)
        ) {
            return true;
        }
        return false;
    };

    const setComplaintsTypeRadioButton = () => {
        // must check one then!
        const radioButtons: NodeListOf<HTMLElement> = document.getElementsByName('inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < radioButtons.length; i++) {
            const radioButton: HTMLInputElement = radioButtons[i] as HTMLInputElement;
            if (radioButton.value === complaintsMechanismTypeWatched) {
                radioButton.checked = true;
            }
        }
    };

    const onChangeComplaintsType = async (event: any) => {
        const selectedValue: ComplaintsMechanismType = event.target?.value;
        const dataExistsThatCouldBeDeleted = doesPriorDataExistThatWillBeDeleted(selectedValue);

        function revertSelection() {
            // Setting the target.checked property to the defaultChecked will
            // deselect the current radio button and select the radio button that
            // was previously selected.
            event.target.checked = event.target.defaultChecked;

            event.preventDefault();
            event.stopPropagation();
            // return the orig value
            setValue('inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType', complaintsMechanismTypeWatched, {
                shouldDirty: true,
                shouldValidate: true,
            });
            setComplaintsTypeRadioButton();
            return false;
        }

        function changeSelection() {
            // manage the value manually as ref did not work for the react hook form
            setValue('inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType', selectedValue, {
                shouldDirty: true,
                shouldValidate: true,
            });
        }

        if (dataExistsThatCouldBeDeleted === true) {
            if (
                await ModalDialogConfirmable(MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE, MODALBUTTON1_OK, MODALBUTTON2_CANCEL, {
                    title: MODALHEADER_DATALOSSPOTENTIAL,
                    confirmationP2: MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_P2,
                    confirmationP3: MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_P3,
                    dataTestId: 'modalPromptToRemoveData_ChangeComplaintsType',
                })
            ) {
                // user has accepted that data can be removed so we remove that now
                removePriorData(selectedValue);
                // // delay long enough to remove all prior complaints data then re-set this to the new value selected
                // Sleep(1000).then(() => {
                //    changeSelection();
                // });
            } else {
                revertSelection();
            }
        } else {
            changeSelection();
        }
    };

    useEffect(() => {
        // upon change of selection we show/hide menu options
        if (complaintsMechanismTypeWatched && complaintsMechanismTypeWatched !== null) {
            ToggleComplaintsChildMenu(appContext, complaintsMechanismTypeWatched);
            // future pages also need to store this value
            setValue('inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType', complaintsMechanismTypeWatched);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [complaintsMechanismTypeWatched]);

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors.errors?.length > 0) {
            backEndValidationErrors.errors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'ComplaintsProcessType':
                        setError('inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType', {
                            type: 'manual',
                            message: validationError.errorMessage,
                        });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    // check dispatch success state
    useEffect(() => {
        if (isActive === true && ajaxCallStateForPage && ajaxCallStateForPage.pageNumber === WizardStepNumber.ComplaintsMechanismType) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // ONLY time we do not show these is if in the middle of switching between options AND user has changed mind to delete an option
                if (apiState.attemptingSaveThenRemovePriorData !== true) {
                    // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                    setApiState({ ...apiState, attemptingSave: false, attemptingSaveApp: false, dataInquiry: undefined });
                    displayBackEndValidations();
                }
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setApiState({ ...apiState, attemptingSave: false, attemptingSaveApp: false, attemptingSaveThenRemovePriorData: false, dataInquiry: undefined });
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK && !appContext.wizardState.triggerSubmit) {
                    if (apiState.attemptingSave === true) {
                        // IF VALID (or OK to move forward):
                        setApiState({ ...apiState, attemptingSave: false, attemptingSaveThenRemovePriorData: false, attemptingSaveApp: true }); // notice the SaveApp TRUE
                        // so we can set the current application step (use stored data object so we update the currentStep)
                        const updateOutlet = false;
                        dispatch(appActions.UpdateApplication(apiState.dataInquiry!.application, updateOutlet));
                    } else if (apiState.attemptingSaveApp === true) {
                        setApiState({ ...apiState, attemptingSaveApp: false, dataInquiry: undefined });
                        // refresh data set now after changes saved
                        dispatch(appActions.GetApplication());
                        MoveWizardForward(appContext, isValid);
                    } else if (apiState.refreshingAppData === true) {
                        // the user had agreed to remove data upon switch so we replace the selection now
                        if (apiState.complaintsMechanismTypeChangeOfMind && inquiry.application.activeComplaintsMechanismObj) {
                            inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType = apiState.complaintsMechanismTypeChangeOfMind;

                            // manage the value manually as ref did not work for the react hook form
                            setValue('inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType', apiState.complaintsMechanismTypeChangeOfMind, {
                                shouldDirty: true,
                                shouldValidate: true,
                            });
                            // inquiry.application.complaintsMechanism[inquiry.application.activeComplaintsMechanismIndex].complaintsMechanismType =
                            //    apiState.complaintsMechanismTypeChangeOfMind;
                            setApiState({ ...apiState, complaintsMechanismTypeChangeOfMind: undefined, refreshingAppData: false });
                        }
                    } else if (apiState.removingComplaintsData === true) {
                        setApiState({ ...apiState, removingComplaintsData: false, refreshingAppData: true }); // notice to removeCD TRUE

                        // refresh data set now after changes saved (this is when a user saves changes after deleting data once they changed their mind)
                        dispatch(appActions.GetApplication());
                    } else if (apiState.attemptingSaveThenRemovePriorData === true) {
                        // tiny pause only to allow application update to complete first
                        Sleep(100).then(() => {
                            // notice to removeCD TRUE
                            setApiState({
                                ...apiState,
                                attemptingSave: false,
                                attemptingSaveApp: false,
                                attemptingSaveThenRemovePriorData: false,
                                removingComplaintsData: true,
                            });

                            // remove all complaints data
                            dispatch(appActions.RemoveComplaintsData());
                        });
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const submitForm = (data: any) => {
        if (isValid) {
            // set existing data now as we dont capture it in this page
            EnsureData(appContext, WizardStepNumber.ComplaintsMechanismType, data, inquiry);

            const complaintsMechId = data.inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId;
            if (complaintsMechId === undefined || complaintsMechId === null || complaintsMechId === UNINITIALISED) {
                const guid = new Guid();
                data.inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId = guid.empty;
            }

            // Save changes to application (attempt save, if that fails we do NOT move the Wizard step)
            setApiState({ ...apiState, attemptingSave: true, dataInquiry: data.inquiry });

            // determine if a new complaints mech or update existing
            if (
                data.inquiry.application.complaintsMechanism.length === 0 &&
                data.inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId === Guid.empty
            ) {
                dispatch(appActions.AddComplaintsMechanism(data.inquiry.application.activeComplaintsMechanismObj));
            } else {
                dispatch(appActions.UpdateComplaintsMechanism(data.inquiry.application.activeComplaintsMechanismObj));
            }
            // dispatch(appActions.UpdateApplication(data.inquiry.application));
        } else {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    SubmitThisPageIfActive(isActive, triggerSubmit, formRef, setTriggerSubmit);

    const radioOptionsComplaintsMechanism: RadioButtonOption[] = [
        {
            label: DisplayMechanismTypeInitialApplication(ComplaintsMechanismType.GovernmentFundedFDRService),
            value: ComplaintsMechanismType.GovernmentFundedFDRService,
            disabled: false,
        },
        {
            label: DisplayMechanismTypeInitialApplication(ComplaintsMechanismType.FullMembershipProfessionalAssoc),
            value: ComplaintsMechanismType.FullMembershipProfessionalAssoc,
            disabled: false,
            helpHeaderText: HELPHEADERTEXT_FULLMEMBERSHIP,
            helpText: HELPTEXT_COMPLAINTSMECHANISM_FULLMEMBERSHIP,
        },
    ];

    return (
        <>
            <PageTitle title='Complaints mechanism' description={HEADER_COMPLAINTS} />
            <PageWrapper
                pageName='ComplaintsType'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={handleClickPrevious}
            >
                <PageFieldsTitle title='Complaints mechanism' />
                <PageMandatoryLabelText />
                <ErrorSummary errors={errors} />
                {/* user may change mind and remove old data, so system needs time to process the change and prevent interaction */}
                {apiState.attemptingSaveThenRemovePriorData === true && isActive === true && <Loader isLoading={true} loaderText={CLEANINGUP_PRIORDATA} />}
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.applicationId'
                    {...register('inquiry.application.applicationId')}
                    defaultValue={inquiry.application.applicationId}
                />
                <LabelField id='complaintsMechanismBlurbText1' isMandatory={false} displayName='' value={COMPLAINTS_MECHANISM_BLURB_PARAGRAPH1} />
                <LabelField id='complaintsMechanismBlurbText2' isMandatory={false} displayName='' value={COMPLAINTS_MECHANISM_BLURB_PARAGRAPH2} />
                <LabelField id='complaintsMechanismBlurbText3' isMandatory={false} displayName='' value={COMPLAINTS_MECHANISM_BLURB_PARAGRAPH3} />
                <RadioButtonGroupField
                    options={radioOptionsComplaintsMechanism}
                    id='inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType'
                    displayName='Type of complaints mechanism'
                    guidanceText={GUIDANCETEXT_COMPLAINTS_MECH_TYPE}
                    fieldLabel=''
                    defaultValue={inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType} // only set default if value exists
                    isMandatory={true}
                    control={control}
                    register={register}
                    errorsField={errors.inquiry?.application?.activeComplaintsMechanismObj?.complaintsMechanismType}
                    // autoFocus={true}
                    onChange={onChangeComplaintsType}
                />
            </PageWrapper>
        </>
    );
};

export default ComplaintsMechanismTypePage;
