// Introduction page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the portal home page
//  - allows navigation forward to the Personal details-Name page
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { RegoApplicationPageProps } from '../../PageInterfaces';
import { IApplication } from '../../../api/interfacesApi';
import ApplicationActions from '../../../actions/applicationActions';
import { toastErrorMessage } from '../../../actions/toastrMessages';
import { VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../../services/Validation';
import { NOTE_INTRODUCTION_EVIDENCE, NOTE_INTRODUCTION_PRIVACY } from '../../../services/Constants';
import { AjaxCallStateEnum, ApplicationStatusEnum, WizardStepNumber } from '../../../services/Enums';
import NoteText from '../../../controls/NoteText';
import { ErrorOnSubmitForm, IsValidGuidIdentifier } from '../../../utils/AppUtils';
import { EmptyRow } from '../../../controls/EmptyRow';
import AppContext from '../../../stateManagement/context/AppContext';
import PageTitle from '../../../components/pageTitle';
import PageWrapper from '../../../components/pageWrapper';
import { isApplicationNotStartedOrNull } from '../../PageFunctions';
import { CreateNewApplication, SetApplicationNumber } from '../../../components/wizard/wizardStateManager';
import { MoveWizardForward } from '../../../components/wizard/wizardStepFunctions';
import { IntroductionContent } from './IntroductionContent';
import { IntroductionContentPrint } from './IntroductionContentPrint';
import styles from '../../Pages.module.scss';

const { printButton, printContent } = styles;

const IntroductionPage = (props: RegoApplicationPageProps) => {
    const { inquiry, authService, dispatch, handleSubmit, isValid, isActive, ajaxCallStateForPage, backEndValidationErrors } = props;

    const appContext = useContext(AppContext);
    const componentRef = useRef(null);
    const formRef = useRef();
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const application: IApplication = useSelector((stateApp: any) => stateApp.application);
    const isLoading = useSelector((state: any) => state.ajaxCallsInProgress.loading);
    const callsInProgress = useSelector((state: any) => state.ajaxCallsInProgress.callsInProgress);
    const [attemptingGetApplication, setAttemptingGetApplication] = useState(false);
    const [attemptingCreateApplication, setAttemptingCreateApplication] = useState(false);

    // initial load of any existing data
    useEffect(() => {
        if (inquiry && inquiry.application) {
            // can finally show the Z-number (application number on the wizard)
            SetApplicationNumber(appContext, inquiry.application.zNumber);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inquiry?.application?.zNumber]);

    // check dispatch success state
    useEffect(() => {
        if (isActive === true && ajaxCallStateForPage && ajaxCallStateForPage.pageNumber === WizardStepNumber.Introduction) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                setAttemptingGetApplication(false);
                setAttemptingCreateApplication(false);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setAttemptingGetApplication(false);
                setAttemptingCreateApplication(false);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                if (
                    isLoading === false &&
                    callsInProgress === 0 &&
                    ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK &&
                    !appContext.wizardState.triggerSubmit
                ) {
                    // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                    if (attemptingCreateApplication === true) {
                        setAttemptingCreateApplication(false);
                        setAttemptingGetApplication(false);

                        // refresh data set now after changes saved (pulls in new application ZNumber)
                        dispatch(appActions.GetApplication());

                        // IF VALID (or OK to move forward):
                        MoveWizardForward(appContext, isValid);
                    }

                    if (attemptingGetApplication === true) {
                        setAttemptingGetApplication(false);

                        // fail-safe in case user had opened multiple tabs and attempted to create
                        if (isApplicationNotStartedOrNull(application?.applicationStatus)) {
                            // we set the status and the number of steps now
                            inquiry.application.applicationStatus = ApplicationStatusEnum.Pending;
                            inquiry.application.currentApplicationStep = WizardStepNumber.PersonalDetailsName;

                            setAttemptingCreateApplication(true);
                            CreateNewApplication(dispatch, authService, inquiry.application);
                        } else {
                            if (
                                appContext.wizardState?.goingBackwards === true &&
                                IsValidGuidIdentifier(inquiry.application?.applicationId) === true &&
                                inquiry.application.applicationStatus === ApplicationStatusEnum.Pending
                            ) {
                                // already created but user has gone back and forward - so we just go forward now
                                MoveWizardForward(appContext, isValid);
                            } else {
                                // to prevent malicious or accidental multiple-application records being created
                                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                            }
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const handlePrint = () => {
        // tslint:disable-next-line: no-unused-expression
        handleClickPrint && handleClickPrint();
    };

    const handleClickPrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Family Dispute Resolution Register - Application Checklist',
    });

    // override handleClickPrevious for this form only as we do NOT save any data yet
    const handleClickPreviousIntro = () => {
        window.location.href = '/';
    };

    const submitForm = () => {
        // create new application if on INTRO page only
        if (
            (isApplicationNotStartedOrNull(inquiry.application.applicationStatus) &&
                inquiry.application.currentApplicationStep <= WizardStepNumber.Introduction) ||
            (appContext.wizardState?.goingBackwards === true &&
                IsValidGuidIdentifier(inquiry.application?.applicationId) === true &&
                inquiry.application.applicationStatus === ApplicationStatusEnum.Pending)
        ) {
            setAttemptingGetApplication(true);
            // need to get latest application data to prevent if user has opened multiple tabs and clicked New App button
            dispatch(appActions.GetApplication());
        } else {
            // to prevent malicious or accidental multiple-application records being created
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    return (
        <>
            <PageTitle title='Introduction' description='You can apply for accreditation as a Family Dispute Resolution (FDR) practitioner using this form.' />
            <PageWrapper
                pageName='Introduction'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={handleClickPreviousIntro}
                textForSaveContinueButton='next'
            >
                <Row>
                    <Col>
                        <IntroductionContent />
                        <div className={printContent} aria-hidden={true}>
                            <IntroductionContentPrint ref={componentRef} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant='outline-secondary' className={`btn ${printButton}`} onClick={handlePrint} data-testid='printIntroListButton'>
                            Print this list
                        </Button>
                    </Col>
                </Row>
                <EmptyRow />
                <Row>
                    <Col md='11'>
                        <NoteText displayText={NOTE_INTRODUCTION_EVIDENCE} />
                    </Col>
                    <Col md='1' className='justify-content-end' />
                </Row>
                <EmptyRow />
                <Row className='pt-4'>
                    <Col md='11'>
                        <NoteText displayText={NOTE_INTRODUCTION_PRIVACY} />
                    </Col>
                    <Col md='1' className='justify-content-end' />
                </Row>
                <EmptyRow />
            </PageWrapper>
        </>
    );
};

export default IntroductionPage;
