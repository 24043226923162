import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
// tslint:disable-next-line: no-submodule-imports
import au from 'date-fns/locale/en-AU'; // the locale you want
import { PLACEHOLDER_TIME } from '../../services/Constants';
// tslint:disable-next-line: no-submodule-imports
import 'react-datepicker/dist/react-datepicker.css';
import styles from './Fields.module.scss';

const { fieldValidationError, validationErrorRow } = styles;

export interface InputTimeFieldProps {
    fieldName: string;
    defaultValue: Date | null;
    setValue: any;
    trigger: any;
    errorsField: any;
}

const InputTimeField = (props: InputTimeFieldProps) => {
    const { fieldName, defaultValue, setValue, trigger, errorsField } = props;

    const [inputTimeValue, setInputTimeValue] = useState<Date | null>(defaultValue);

    useEffect(() => {
        // we could set the defaultValue using a 9-5pm click
        if (defaultValue !== null) {
            setInputTimeValue(defaultValue);
        }
        if (inputTimeValue !== null && defaultValue === null) {
            setInputTimeValue(null); // rare case when user removed isOpene clear time field
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const changeOpenTime = (date: Date | null) => {
        // manage the value manually as ref did not work for the react hook form
        setValue(fieldName, date, { shouldDirty: true, shouldValidate: true });
        setInputTimeValue(date);
        trigger(); // re-validate
    };

    const handleDateChangeRaw = (e: any) => {
        // allow clear contents only
        if (e.target.value !== '') {
            e.preventDefault();
        }
        changeOpenTime(null);
    };

    // globally set the locale for our ReactDatePicker
    // setDefaultLocale('en_AU');
    registerLocale('en-AU', au); // register it with the name you want

    return (
        <>
            <ReactDatePicker
                id={fieldName}
                selected={inputTimeValue}
                placeholderText={PLACEHOLDER_TIME}
                onChange={(date: Date | null) => date && changeOpenTime(date)}
                showTimeSelect={true}
                showTimeSelectOnly={true}
                timeIntervals={30}
                timeCaption='Time'
                dateFormat='h:mm aa'
                locale='en-AU' // MOST IMPORTANT
                onChangeRaw={handleDateChangeRaw}
            />
            <Row>
                <Col className={fieldValidationError}>{errorsField && <span role='alert'>{errorsField.message}</span>}</Col>
            </Row>
            <Row className={validationErrorRow}>
                <Col className={fieldValidationError}>{errorsField && errorsField.validate && <span role='alert'>{errorsField.validate.message}</span>}</Col>
            </Row>
        </>
    );
};

export default InputTimeField;
