// Competencies and Qualifications page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the Past Application History page
//  - allows navigation forward to the Child-related employment page
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { RegoApplicationPageProps } from '../../PageInterfaces';
import AttachmentActions from '../../../actions/attachmentsActions';
import { GetTextForSaveAndContinueButton, HideTheBackButton, SubmitThisPageIfActive } from '../../PageFunctions';
import { EnsureData } from '../RegistrationApplicationWizard/wizardHelperFunctions';
import { MoveWizardForward, MoveWizardForwardForResubmit } from '../../../components/wizard/wizardStepFunctions';
import {
    AjaxCallStateEnum,
    AttachmentDocumentType,
    QualificationOrNMASDetailsType,
    QualificationType,
    WizardMode,
    WizardStepNumber,
} from '../../../services/Enums';
import { ParseBool, Sleep } from '../../../utils/Common';
import { DisplayQualification, DisplayQualificationOrNMASDetails } from '../../../utils/EnumMappings';
import {
    HEADER_COMPETENCIES,
    HELPHEADERTEXT_APPROPRIATEQUALIFICATION,
    HELPHEADERTEXT_FULLGRADDIPLOMA,
    HELPHEADERTEXT_NMAS,
    HELPHEADERTEXT_SIXUNITS,
    HELPHEADERTEXT_THREEUNITS,
    HELPTEXT_APPROPRIATEQUALIFICATION,
    HELPTEXT_FULLGRADDIPLOMA,
    HELPTEXT_NMAS,
    HELPTEXT_SIXUNITS,
    HELPTEXT_THREEUNITS,
    MODALHEADER_DATALOSSPOTENTIAL,
    MODALTEXT_DATALOSSPOTENTIAL_COMPETENCIES,
    MODALTEXT_DATALOSSPOTENTIAL_COMPETENCIES_P2,
    MODALTEXT_DATALOSSPOTENTIAL_COMPETENCIES_P3,
    MODALBUTTON1_OK,
    MODALBUTTON2_CANCEL,
    NOTE_NMAS_ACCREDITATION_MESSAGE,
    PLACEHOLDER_DATE,
    CLEANINGUP_PRIORDATA,
} from '../../../services/Constants';
import { VALIDATION_ERROR_POPUP_MESSAGE, VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../../services/Validation';
import { toastErrorMessage } from '../../../actions/toastrMessages';
import { ErrorOnSubmitForm, RemoveAttachmentsFromAzureIfExist, RemoveAttachmentsFromCollectionIfExist } from '../../../utils/AppUtils';
import ApplicationActions from '../../../actions/applicationActions';
import ErrorSummary from '../../../controls/ErrorSummary';
import Loader from '../../../controls/Loader';
import NoteText from '../../../controls/NoteText';
import { ModalDialogConfirmable } from '../../../controls/ModalDialogConfirmable';
import AppContext from '../../../stateManagement/context/AppContext';
import PageWrapper from '../../../components/pageWrapper';
import PageTitle from '../../../components/pageTitle';
import PageMandatoryLabelText from '../../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import FileUploadField from '../../../components/fields/FileUploadField';
import InputDateField from '../../../components/fields/InputDateField';
import RadioButtonGroupField, { RadioButtonOption } from '../../../components/fields/RadioButtonGroupField';
import {
    DoesPriorDataExistThatWillBeDeletedForQuals,
    DoesPriorDataExistThatWillBeDeletedForSixUnits,
    SetQualificationTypeRadioButton,
    SetQualOrNmasTypeRadioButton,
    UnregisterAndClearAttachmentsValue,
} from './competenciesUtils';
import styles from '../../Pages.module.scss';
import { InsertAttachmentToLocalInquiryObject, RemoveAttachmentFromLocalInquiryObject } from '../../../utils/ComplaintsUtils';
import { IAttachmentMetadata } from '../../../api/interfacesApi';

const { childItems, grandChildItems, grandChildItemsNoBorderLeft, controlMaxWidth } = styles;

interface UIstate {
    showChildrenFullGradDip: boolean;
    showChildrenQualificationOrNMAS: boolean;
    showChildrenThreeUnits: boolean;
    showGrandchildrenNMAS: boolean;
    showGrandchildrenAppropriateQualification: boolean;
    showNoteNMASWarning: boolean;
}

const initUiState: UIstate = {
    showChildrenFullGradDip: false,
    showChildrenQualificationOrNMAS: false,
    showChildrenThreeUnits: false,
    showGrandchildrenNMAS: false,
    showGrandchildrenAppropriateQualification: false,
    showNoteNMASWarning: false,
};

const CompetenciesAndQualsPage = (props: RegoApplicationPageProps) => {
    const {
        inquiry,
        // attachments,
        goingBackwards,
        authService,
        dispatch,
        handleClickPrevious,
        handleSubmit,
        // clearErrors,
        register,
        unregister,
        trigger,
        setError,
        setValue,
        watch,
        control,
        errors,
        isValid,
        isActive,
        ajaxCallStateForPage,
        backEndValidationErrors,
        triggerSubmit,
        setTriggerSubmit,
    } = props;

    const appContext = useContext(AppContext);
    const disableField = appContext.wizardState.wizardMode === WizardMode.Resubmit ? true : false;
    const formRef = useRef();
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const attachmentActions = useMemo(() => {
        return new AttachmentActions(authService);
    }, [authService]);
    // even though we have redux states, we HAVE to capture the attempt due to navigation and Get and Save user calls on the page
    const [attemptingSave, setAttemptingSave] = useState(false);
    const [attemptingSaveThenRemovePriorData, setAttemptingSaveThenRemovePriorData] = useState(false);
    // this page has a few parent-child-grandchild items so we track state using this object
    const [displayState, setDisplayState] = useState(initUiState);

    const nmasExpiryDateWatched = watch('inquiry.application.nmasExpiryDate', inquiry?.application.nmasExpiryDate);
    const hasPreviouslyApplied: boolean = watch('inquiry.application.hasPreviouslyApplied', inquiry?.application.hasPreviouslyApplied);
    const qualification: QualificationType = watch('inquiry.application.qualification', inquiry?.application.qualification);
    const qualificationOrNMASDetails: QualificationOrNMASDetailsType = watch(
        'inquiry.application.qualificationOrNMASDetails',
        inquiry?.application.qualificationOrNMASDetails,
    );

    // routine to determine if parent and also child level attachments exist rather than just any:
    const [filesParentLevelExist, setfilesParentLevelExist] = useState(inquiry?.application.attachments && inquiry.application.attachments.length > 0);
    const [filesChildLevelExist, setfilesChildLevelExist] = useState(inquiry?.application.attachments && inquiry.application.attachments.length > 0);

    const removePriorDataForQualificationChange = (newValue: QualificationType) => {
        // Remove any file data if exists for the other option, to help prevent all sorts of validation issues
        // clear field data
        inquiry.application.nmasExpiryDate = undefined;

        if (newValue === QualificationType.VocationalGradDipOrEquivalent || newValue === QualificationType.QualificationOrNMAS) {
            RemoveAttachmentsFromAzureIfExist(attachmentActions, dispatch, AttachmentDocumentType.ThreeUnits, inquiry.application.attachments);
            inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(AttachmentDocumentType.ThreeUnits, inquiry.application.attachments);
            UnregisterAndClearAttachmentsValue(unregister, setValue, AttachmentDocumentType.ThreeUnits);
        }

        if (newValue === QualificationType.VocationalGradDipOrEquivalent || newValue === QualificationType.ThreeSpecifiedUnits) {
            inquiry.application.qualificationOrNMASDetails = null;

            RemoveAttachmentsFromAzureIfExist(attachmentActions, dispatch, AttachmentDocumentType.SixUnits, inquiry.application.attachments);
            inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(AttachmentDocumentType.SixUnits, inquiry.application.attachments);
            UnregisterAndClearAttachmentsValue(unregister, setValue, AttachmentDocumentType.SixUnits);
            RemoveAttachmentsFromAzureIfExist(attachmentActions, dispatch, AttachmentDocumentType.SixUnitsAndQualification, inquiry.application.attachments);
            inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(
                AttachmentDocumentType.SixUnitsAndQualification,
                inquiry.application.attachments,
            );
            UnregisterAndClearAttachmentsValue(unregister, setValue, AttachmentDocumentType.SixUnitsAndQualification);
            RemoveAttachmentsFromAzureIfExist(attachmentActions, dispatch, AttachmentDocumentType.SixUnitsAndNMAS, inquiry.application.attachments);
            inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(AttachmentDocumentType.SixUnitsAndNMAS, inquiry.application.attachments);
            UnregisterAndClearAttachmentsValue(unregister, setValue, AttachmentDocumentType.SixUnitsAndNMAS);
        }

        if (newValue === QualificationType.QualificationOrNMAS || newValue === QualificationType.ThreeSpecifiedUnits) {
            RemoveAttachmentsFromAzureIfExist(attachmentActions, dispatch, AttachmentDocumentType.FullGraduateDiplomaOfFDR, inquiry.application.attachments);
            inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(
                AttachmentDocumentType.FullGraduateDiplomaOfFDR,
                inquiry.application.attachments,
            );
            UnregisterAndClearAttachmentsValue(unregister, setValue, AttachmentDocumentType.FullGraduateDiplomaOfFDR);
        }

        // MUST set to NULL as the back-end validation will kick in and give isValid error and reset value!
        inquiry.application.qualification = null;
        // manage the value manually as ref did not work for the react hook form
        // setValue('inquiry.application.qualification', newValue, { shouldDirty: true });

        /* if (
            newValue?.toString() === QualificationType.ThreeSpecifiedUnits.toString() ||
            newValue?.toString() === QualificationType.VocationalGradDipOrEquivalent.toString()
        ) {
            // set option back to NULL if we select another parent so this selection is removed
            setValue('inquiry.application.qualificationOrNMASDetails', null, { shouldDirty: true });
        } */

        const updateOutlet = false;
        dispatch(appActions.UpdateApplication(inquiry.application, updateOutlet));

        // MUST set the new value otherwise the reload of data will just set it back to orig value!
        // inquiry.application.qualification = newValue;
        // revalidate trigger, could use { shouldValidate: true }
        // trigger();
    };

    const removePriorDataForQualOrNmasChange = (newValue: QualificationOrNMASDetailsType) => {
        // Remove any file data if exists for the other option, to help prevent all sorts of validation issues
        // clear field data
        inquiry.application.nmasExpiryDate = undefined;

        // Remove any file data if exists for the other option, to help prevent all sorts of validation issues
        RemoveAttachmentsFromAzureIfExist(attachmentActions, dispatch, AttachmentDocumentType.SixUnitsAndQualification, inquiry.application.attachments);
        inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(
            AttachmentDocumentType.SixUnitsAndQualification,
            inquiry.application.attachments,
        );
        RemoveAttachmentsFromAzureIfExist(attachmentActions, dispatch, AttachmentDocumentType.SixUnitsAndNMAS, inquiry.application.attachments);
        inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(AttachmentDocumentType.SixUnitsAndNMAS, inquiry.application.attachments);

        // refresh data set now so we dont get entity issues??
        // dispatch(appActions.GetApplication());

        UnregisterAndClearAttachmentsValue(unregister, setValue, AttachmentDocumentType.SixUnitsAndNMAS);
        UnregisterAndClearAttachmentsValue(unregister, setValue, AttachmentDocumentType.SixUnitsAndQualification);

        // MUST set to NULL as the back-end validation will kick in and give isValid error and reset value!
        inquiry.application.qualificationOrNMASDetails = null;

        const updateOutlet = false;
        dispatch(appActions.UpdateApplication(inquiry.application, updateOutlet));
    };

    const onChangeQualification = async (event: any) => {
        const qualType: QualificationType = event.target.value;
        const dataExistsThatCouldBeDeleted = DoesPriorDataExistThatWillBeDeletedForQuals(inquiry, qualType);

        function revertSelection() {
            // Setting the target.checked property to the defaultChecked will
            // deselect the current radio button and select the radio button that
            // was previously selected.
            event.target.checked = event.target.defaultChecked;
            event.preventDefault();
            event.stopPropagation();
            if (inquiry.application.qualification !== null) {
                setValue('inquiry.application.qualification', inquiry.application.qualification, { shouldDirty: true, shouldValidate: true });
            }
            // return the orig value
            SetQualificationTypeRadioButton(qualification);
            return false;
        }

        function changeSelection() {
            // manage the value manually as ref did not work for the react hook form
            setValue('inquiry.application.qualification', qualType, { shouldDirty: true, shouldValidate: true });
        }

        if (dataExistsThatCouldBeDeleted === true) {
            if (
                await ModalDialogConfirmable(MODALTEXT_DATALOSSPOTENTIAL_COMPETENCIES, MODALBUTTON1_OK, MODALBUTTON2_CANCEL, {
                    title: MODALHEADER_DATALOSSPOTENTIAL,
                    confirmationP2: MODALTEXT_DATALOSSPOTENTIAL_COMPETENCIES_P2,
                    confirmationP3: MODALTEXT_DATALOSSPOTENTIAL_COMPETENCIES_P3,
                    dataTestId: 'modalPromptToRemoveData_ChangeQualification',
                })
            ) {
                setAttemptingSaveThenRemovePriorData(true);
                // user has accepted that data can be removed so we remove that now
                removePriorDataForQualificationChange(qualType);

                // allow plenty of time to run all API calls then update the UI
                Sleep(4000).then(() => {
                    setValue('inquiry.application.nmasExpiryDate', undefined);
                    changeSelection();

                    SetQualificationTypeRadioButton(qualType);
                    setAttemptingSaveThenRemovePriorData(false);
                    // finally re-validate input
                    trigger();
                });
            } else {
                revertSelection();
            }
        } else {
            changeSelection();
            SetQualificationTypeRadioButton(qualType);
        }
    };

    const onChangeQualificationOrNMASDetails = async (event: any) => {
        const qualificationOrNMASDetailsType: QualificationOrNMASDetailsType = event.target.value;
        const dataExistsThatCouldBeDeleted = DoesPriorDataExistThatWillBeDeletedForSixUnits(inquiry, qualificationOrNMASDetailsType);

        function revertSelection() {
            // Setting the target.checked property to the defaultChecked will
            // deselect the current radio button and select the radio button that
            // was previously selected.
            event.target.checked = event.target.defaultChecked;
            event.preventDefault();
            event.stopPropagation();

            if (inquiry.application.qualificationOrNMASDetails !== null) {
                setValue('inquiry.application.qualificationOrNMASDetails', inquiry.application.qualificationOrNMASDetails, {
                    shouldDirty: true,
                    shouldValidate: true,
                });
            }

            // return the orig value
            SetQualOrNmasTypeRadioButton(qualificationOrNMASDetails);
            return false;
        }

        function changeSelection() {
            // manage the value manually as ref did not work for the react hook form
            setValue('inquiry.application.qualificationOrNMASDetails', qualificationOrNMASDetailsType, { shouldDirty: true, shouldValidate: true });
        }

        if (dataExistsThatCouldBeDeleted === true) {
            if (
                await ModalDialogConfirmable(MODALTEXT_DATALOSSPOTENTIAL_COMPETENCIES, MODALBUTTON1_OK, MODALBUTTON2_CANCEL, {
                    title: MODALHEADER_DATALOSSPOTENTIAL,
                    confirmationP2: MODALTEXT_DATALOSSPOTENTIAL_COMPETENCIES_P2,
                    confirmationP3: MODALTEXT_DATALOSSPOTENTIAL_COMPETENCIES_P3,
                    dataTestId: 'modalPromptToRemoveData_ChangeQualOrNMAS',
                })
            ) {
                setAttemptingSaveThenRemovePriorData(true);

                // user has accepted that data can be removed so we remove that now
                removePriorDataForQualOrNmasChange(qualificationOrNMASDetailsType);

                // allow plenty of time to run all API calls then update the UI
                Sleep(4000).then(() => {
                    changeSelection();
                    SetQualOrNmasTypeRadioButton(qualificationOrNMASDetailsType);
                    setAttemptingSaveThenRemovePriorData(false);
                    // finally re-validate input
                    trigger();
                });
            } else {
                revertSelection();
            }
        } else {
            changeSelection();
        }
    };

    const showNMASChildrenAndWarningNote = () => {
        const newDisplaystate: UIstate = {
            ...displayState,
            showGrandchildrenNMAS: true,
            showGrandchildrenAppropriateQualification: false,
            showNoteNMASWarning: true,
        };
        setDisplayState(newDisplaystate);
        trigger(); // revalidate
    };

    const showAppropriateQualificationsChildren = () => {
        const newDisplaystate: UIstate = {
            ...displayState,
            showGrandchildrenNMAS: false,
            showGrandchildrenAppropriateQualification: true,
            showNoteNMASWarning: false,
        };
        setDisplayState(newDisplaystate);
        trigger(); // revalidate
    };

    useEffect(() => {
        if (qualification) {
            let newDisplayState: UIstate = {
                ...displayState,
                showChildrenFullGradDip: qualification.toString() === QualificationType.VocationalGradDipOrEquivalent.toString(),
                showChildrenQualificationOrNMAS: qualification.toString() === QualificationType.QualificationOrNMAS.toString(),
                showChildrenThreeUnits: qualification.toString() === QualificationType.ThreeSpecifiedUnits.toString(),
            };

            // if user choose other options we blank out options under the NMAS Accreditation
            if (qualification.toString() !== QualificationType.QualificationOrNMAS.toString()) {
                setValue('inquiry.application.qualificationOrNMASDetails', null, { shouldDirty: true });
                // hideQualificationOrNMASchildren();
                newDisplayState = {
                    ...newDisplayState,
                    showGrandchildrenNMAS: false,
                    showGrandchildrenAppropriateQualification: false,
                    showNoteNMASWarning: false,
                };
            }

            // now set the overall state so it doesnt knock out other values
            setDisplayState(newDisplayState);

            // setQualificationTypeRadioButton();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inquiry, qualification, setValue]);

    // determine if we are to show the warning note and File Upload/Date field for NMAS
    if (
        qualificationOrNMASDetails &&
        !displayState.showNoteNMASWarning &&
        qualificationOrNMASDetails.toString() === QualificationOrNMASDetailsType.CurrentNMASAccreditation.toString()
    ) {
        showNMASChildrenAndWarningNote();
    }

    // determine if we are to show the File Upload for Appropriate Qualification
    if (
        qualificationOrNMASDetails &&
        !displayState.showGrandchildrenAppropriateQualification &&
        qualificationOrNMASDetails.toString() === QualificationOrNMASDetailsType.AppropriateQualification.toString()
    ) {
        showAppropriateQualificationsChildren();
    }

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors.errors?.length > 0) {
            backEndValidationErrors.errors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'QualificationOrNMAS':
                        setError('inquiry.application.qualification', { type: 'manual', message: validationError.errorMessage });
                        break;
                    case 'QualificationOrNMASDetails':
                        setError('inquiry.application.qualificationOrNMASDetails', { type: 'manual', message: validationError.errorMessage });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    // check dispatch success state
    useEffect(() => {
        if (isActive === true && ajaxCallStateForPage && ajaxCallStateForPage.pageNumber === WizardStepNumber.CompetenciesAndQuals) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // ONLY time we do not show these is if in the middle of switching between options AND user has changed mind to delete an option
                if (attemptingSaveThenRemovePriorData !== true) {
                    // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                    setAttemptingSave(false);
                    displayBackEndValidations();
                }
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setAttemptingSave(false);
                setAttemptingSaveThenRemovePriorData(false);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (attemptingSave === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK && !appContext.wizardState.triggerSubmit) {
                    // IF VALID (or OK to move forward):
                    setAttemptingSave(false);

                    // refresh data set now after changes saved
                    dispatch(appActions.GetApplication());

                    // Move wizard forward to next step pending whether we are navigating normally or for the resubmit flow
                    if (appContext.wizardState.wizardMode === WizardMode.New) {
                        MoveWizardForward(appContext, isValid);
                    } else {
                        MoveWizardForwardForResubmit(appContext, isValid);
                    }
                } else if (
                    attemptingSaveThenRemovePriorData === true &&
                    ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK &&
                    !appContext.wizardState.triggerSubmit
                ) {
                    console.log('attemptingSaveThenRemovePriorData');
                    // Sleep(750).then(() => {                     // refresh data set now after changes saved (this is when a user saves changes after deleting data once they changed their mind)
                    //    dispatch(appActions.GetApplication());
                    // });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const submitForm = (data: any) => {
        // need to confirm if ANY data exists in the inquiry.application.attachments object as FileUploads are now mandatory
        if (isValid) {
            // set existing data now as we dont capture it in this page
            EnsureData(appContext, WizardStepNumber.CompetenciesAndQuals, data, inquiry);
            // Save changes to application (attempt save, if that fails we do NOT move the Wizard step)
            setAttemptingSave(true);
            const updateOutlet = false;
            dispatch(appActions.UpdateApplication(data.inquiry.application, updateOutlet));
        } else {
            // yup validation tripped
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    SubmitThisPageIfActive(isActive, triggerSubmit, formRef, setTriggerSubmit);

    // update attachments collection as that is seperate from other data application object call
    const updatedFilesParentLevelList = (files: any) => {
        // update inquiry.application.attachments collection
        // SyncAttachmentsLocalCollection(attachmentApi, AttachmentDocumentType.FullGraduateDiplomaOfFDR, attachments);

        if (files && files.length > 0) {
            if (filesParentLevelExist === false) {
                setfilesParentLevelExist(true);
                // immediately remove any errors of type file not uploaded (for parent nodes)
                // removeErrorsAndReValidate(true);
            }
        } else {
            if (filesParentLevelExist === true) {
                setfilesParentLevelExist(false);
            }
        }
    };

    const updatedFilesChildLevelList = (files: any) => {
        if (files && files.length > 0) {
            if (filesChildLevelExist === false) {
                setfilesChildLevelExist(true);
                // immediately remove any errors of type file not uploaded (for child nodes)
                // removeErrorsAndReValidate(false);
            }
        } else {
            if (filesChildLevelExist === true) {
                setfilesChildLevelExist(false);
            }
        }
    };

    const removedFile = (attachmentId: string) => {
        // refresh CM object/active obj
        RemoveAttachmentFromLocalInquiryObject(attachmentId, setValue, inquiry);
        trigger(); // re-validate
    };

    const addedFile = (newAttachment: IAttachmentMetadata) => {
        // refresh CM object/active obj (NOTE: CANNOT get App in case user simply adds/removes files, it will clear the form!)
        InsertAttachmentToLocalInquiryObject(newAttachment, setValue, inquiry);
        trigger(); // re-validate
    };

    const vocationalGradDipchildren = () => {
        return (
            <>
                <Row className={childItems}>
                    <Col>
                        <FileUploadField
                            id='inquiry.application.attachments.fullgraddiplomaevidence'
                            maxFilesCount={3}
                            applicationId={inquiry?.application.applicationId}
                            authService={authService}
                            displayNameSuffix='full Graduate Diploma (or equivalent)'
                            errorsFieldSuffix='full Graduate Diploma (or equivalent)'
                            documentType={AttachmentDocumentType.FullGraduateDiplomaOfFDR}
                            isMandatory={true}
                            updatedFilesList={updatedFilesParentLevelList}
                            register={register}
                            dispatch={dispatch}
                            watch={watch}
                            inquiry={inquiry}
                            goingBackwards={goingBackwards}
                            errorsField={errors.inquiry?.application?.attachments}
                            removedFile={removedFile}
                            addedFile={addedFile}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    const NMASChildren = () => {
        const today = new Date();
        const openingNmasExpiryDate = nmasExpiryDateWatched ? nmasExpiryDateWatched : today;

        return (
            <Row className={grandChildItems}>
                <Row className={controlMaxWidth}>
                    <Col>
                        <InputDateField
                            id='inquiry.application.nmasExpiryDate'
                            displayName='NMAS expiry date'
                            defaultValue={inquiry?.application.nmasExpiryDate}
                            isMandatory={true}
                            register={register}
                            openToDate={openingNmasExpiryDate}
                            yearDropdownRange={5}
                            dropdownMode='select'
                            disabled={disableField}
                            control={control}
                            placeHolder={PLACEHOLDER_DATE}
                            errorsField={errors.inquiry?.application?.nmasExpiryDate}
                        />
                    </Col>
                </Row>
                <Row className={controlMaxWidth}>
                    <Col>
                        <FileUploadField
                            id='inquiry.application.attachments.nmasevidence'
                            maxFilesCount={3}
                            applicationId={inquiry?.application.applicationId}
                            authService={authService}
                            displayNameSuffix='NMAS'
                            errorsFieldSuffix='NMAS'
                            documentType={AttachmentDocumentType.SixUnitsAndNMAS}
                            isMandatory={true}
                            updatedFilesList={updatedFilesChildLevelList}
                            register={register}
                            dispatch={dispatch}
                            watch={watch}
                            inquiry={inquiry}
                            goingBackwards={goingBackwards}
                            errorsField={errors.inquiry?.application?.attachmentsNmas}
                            removedFile={removedFile}
                            addedFile={addedFile}
                        />
                    </Col>
                </Row>
            </Row>
        );
    };

    const appropriateQualificationsChildren = () => {
        return (
            <Row className={grandChildItems}>
                <Row className={controlMaxWidth}>
                    <Col>
                        <FileUploadField
                            id='inquiry.application.attachments.appropriatequalevidence'
                            maxFilesCount={5}
                            applicationId={inquiry?.application.applicationId}
                            authService={authService}
                            displayNameSuffix='appropriate qualification'
                            errorsFieldSuffix='appropriate qualification'
                            documentType={AttachmentDocumentType.SixUnitsAndQualification}
                            isMandatory={true}
                            updatedFilesList={updatedFilesChildLevelList}
                            register={register}
                            dispatch={dispatch}
                            watch={watch}
                            inquiry={inquiry}
                            goingBackwards={goingBackwards}
                            errorsField={errors.inquiry?.application?.attachmentsAppropQual}
                            removedFile={removedFile}
                            addedFile={addedFile}
                        />
                    </Col>
                </Row>
            </Row>
        );
    };

    const radioOptionsQualOrNMAS: RadioButtonOption[] = [
        {
            label: DisplayQualificationOrNMASDetails(QualificationOrNMASDetailsType.AppropriateQualification),
            value: QualificationOrNMASDetailsType.AppropriateQualification,
            disabled: disableField,
            helpHeaderText: HELPHEADERTEXT_APPROPRIATEQUALIFICATION,
            helpText: HELPTEXT_APPROPRIATEQUALIFICATION,
            children: displayState.showGrandchildrenAppropriateQualification && appropriateQualificationsChildren(),
        },
        {
            label: DisplayQualificationOrNMASDetails(QualificationOrNMASDetailsType.CurrentNMASAccreditation),
            value: QualificationOrNMASDetailsType.CurrentNMASAccreditation,
            disabled: disableField,
            helpHeaderText: HELPHEADERTEXT_NMAS,
            helpText: HELPTEXT_NMAS,
            children: displayState.showGrandchildrenNMAS && NMASChildren(),
        },
    ];

    const qualificationOrNMASchildren = () => {
        return (
            <Row className={childItems}>
                <Row className={controlMaxWidth}>
                    <Col>
                        <FileUploadField
                            id='inquiry.application.attachments.sixunitsevidence'
                            maxFilesCount={3}
                            applicationId={inquiry?.application.applicationId}
                            authService={authService}
                            displayNameSuffix='six core units (or equivalent)'
                            errorsFieldSuffix='six core units (or equivalent)'
                            documentType={AttachmentDocumentType.SixUnits}
                            isMandatory={true}
                            updatedFilesList={updatedFilesParentLevelList}
                            register={register}
                            dispatch={dispatch}
                            watch={watch}
                            inquiry={inquiry}
                            goingBackwards={goingBackwards}
                            errorsField={errors.inquiry?.application?.attachments}
                            removedFile={removedFile}
                            addedFile={addedFile}
                        />
                    </Col>
                </Row>
                <Row className={grandChildItemsNoBorderLeft}>
                    <Row className={controlMaxWidth}>
                        <Col>
                            <RadioButtonGroupField
                                options={radioOptionsQualOrNMAS}
                                id='inquiry.application.qualificationOrNMASDetails'
                                displayName='Select one of the following'
                                fieldLabel=''
                                defaultValue={inquiry?.application.qualificationOrNMASDetails} // only set default if value exists
                                isMandatory={true}
                                control={control}
                                register={register}
                                errorsField={errors.inquiry?.application?.qualificationOrNMASDetails}
                                // autoFocus={true}
                                // guidanceText='Select one of the following:'
                                onChange={onChangeQualificationOrNMASDetails}
                            />
                        </Col>
                    </Row>
                </Row>
            </Row>
        );
    };

    const threeSpecifiedUnitschildren = () => {
        return (
            <>
                <Row className={childItems}>
                    <Col>
                        <FileUploadField
                            id='inquiry.application.attachments.threeunitsevidence'
                            maxFilesCount={3}
                            applicationId={inquiry?.application.applicationId}
                            authService={authService}
                            displayNameSuffix='three specified units'
                            errorsFieldSuffix='three specified units'
                            documentType={AttachmentDocumentType.ThreeUnits}
                            isMandatory={true}
                            updatedFilesList={updatedFilesParentLevelList}
                            register={register}
                            dispatch={dispatch}
                            watch={watch}
                            inquiry={inquiry}
                            goingBackwards={goingBackwards}
                            errorsField={errors.inquiry?.application?.attachments}
                            removedFile={removedFile}
                            addedFile={addedFile}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    const radioOptionsQualType: RadioButtonOption[] = [
        {
            label: DisplayQualification(QualificationType.VocationalGradDipOrEquivalent),
            value: QualificationType.VocationalGradDipOrEquivalent,
            disabled: disableField,
            children: displayState.showChildrenFullGradDip && vocationalGradDipchildren(),
            helpHeaderText: HELPHEADERTEXT_FULLGRADDIPLOMA,
            helpText: HELPTEXT_FULLGRADDIPLOMA,
        },
        {
            label: DisplayQualification(QualificationType.QualificationOrNMAS),
            value: QualificationType.QualificationOrNMAS,
            disabled: disableField,
            children: displayState.showChildrenQualificationOrNMAS && qualificationOrNMASchildren(),
            helpHeaderText: HELPHEADERTEXT_SIXUNITS,
            helpText: HELPTEXT_SIXUNITS,
        },
    ];

    if (hasPreviouslyApplied !== undefined && ParseBool(hasPreviouslyApplied) === true) {
        radioOptionsQualType.push({
            label: DisplayQualification(QualificationType.ThreeSpecifiedUnits),
            value: QualificationType.ThreeSpecifiedUnits,
            disabled: disableField,
            children: displayState.showChildrenThreeUnits && threeSpecifiedUnitschildren(),
            helpHeaderText: HELPHEADERTEXT_THREEUNITS,
            helpText: HELPTEXT_THREEUNITS,
        });
    }

    // validateFilesAndSubmit - handleSubmit={handleSubmit(submitForm)}
    return (
        <>
            <PageTitle title='Competencies and qualifications' description={HEADER_COMPETENCIES} />
            {/* user may change mind and remove old data, so system needs time to process the change and prevent interaction */}
            {attemptingSaveThenRemovePriorData === true && isActive === true && <Loader isLoading={true} loaderText={CLEANINGUP_PRIORDATA} />}
            <PageWrapper
                pageName='Competencies'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={handleClickPrevious}
                hideBackButton={HideTheBackButton(appContext)}
                textForSaveContinueButton={GetTextForSaveAndContinueButton(appContext)}
            >
                <PageFieldsTitle title='Competencies and qualifications' />
                <PageMandatoryLabelText />
                <ErrorSummary errors={errors} />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.applicationId'
                    {...register('inquiry.application.applicationId')}
                    defaultValue={inquiry?.application.applicationId}
                />
                <RadioButtonGroupField
                    options={radioOptionsQualType}
                    id='inquiry.application.qualification'
                    displayName='Your competencies/qualifications'
                    fieldLabel='Select one of the following ways in which you meet the accreditation criteria regarding competencies and qualifications:'
                    defaultValue={inquiry?.application.qualification}
                    isMandatory={true}
                    control={control}
                    register={register}
                    errorsField={errors.inquiry?.application?.qualification}
                    // autoFocus={true}
                    onChange={onChangeQualification}
                />
                {displayState.showNoteNMASWarning && (
                    <Row>
                        <Col md='11'>
                            <NoteText displayText={NOTE_NMAS_ACCREDITATION_MESSAGE} />
                        </Col>
                        <Col md='1' className='justify-content-end' />
                    </Row>
                )}
            </PageWrapper>
        </>
    );
};

export default CompetenciesAndQualsPage;
