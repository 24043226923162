// The FundedOrgComponent is built to do the following:
//  - Allow up to 5 entries, where 1 entry comprises of:
//       * An Organisation Name selectable list
//       * A File Uploader for evidence of the Organisation
//  - A button external to this component that allows the user to Add a new row (an entry)
//  - A button within the component to remove a row of data (an entry)
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import AppContext from '../../../stateManagement/context/AppContext';
import { IsAnyAttachmentActiveAndGovtFundedForThisComplaint } from '../../../utils/AppUtils';
import { Sleep } from '../../../utils/Common';
import {
    HELPHEADERTEXT_ORGANISATIONNAME,
    HELPTEXT_ORGANISATIONNAME,
    MODALHEADER_DATACHANGEPOTENTIAL,
    MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_ACCRED_TYPE,
    MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_P3,
    MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_P2,
    MODALBUTTON1_OK,
    MODALBUTTON2_CANCEL,
} from '../../../services/Constants';
import {
    AttachmentDocumentType,
    ComplaintsMechanismType,
    ComplaintsProcessType,
    OrganisationFundingType,
    RelationshipToEmployerType,
    WizardMode,
} from '../../../services/Enums';
import { IAttachmentMetadata, Inquiry } from '../../../api/interfacesApi';
import { defaultOutletPractitionerData } from '../../../api/defaultData';
import { GetActiveGovtFundedComplaintAttachmentIdCollection } from '../../../utils/ComplaintsUtils';
import { ToggleEmployeeOrPanelMemberChildMenu } from '../../../components/wizard/wizardStepFunctions';
import LabelField from '../../../components/fields/LabelField';
import { ModalDialogConfirmable } from '../../../controls/ModalDialogConfirmable';
import SelectListField, { SelectListOption } from '../../../components/fields/SelectListField';
import FileUploadField from '../../../components/fields/FileUploadField';
import RadioButtonGroupField, { RadioButtonOption } from '../../../components/fields/RadioButtonGroupField';
import styles from '../../Pages.module.scss';

const { fundedOrgDetailHeader, fundedOrgDetailBody, makeInvisible } = styles;

export interface FundedOrgComponentProps {
    isInitialApplication: boolean;
    // fieldName: string;
    // index: number;
    dispatch: any;
    outletsSelectList: SelectListOption[];
    register: any;
    authService: any;
    errors: any;
    inquiry: Inquiry;
    setInquiry: any;
    watch: any;
    // removeItem: any;
    control: any;
    // clearErrors: any;
    trigger: any;
    setError: any;
    goingBackwards: any;
    setValue: any;
    isVisible: boolean;
    removePriorDataRelationshipChanged: any;
    removePriorDataOrgNameChanged?: any;
    isEditableCM?: boolean;
    removedFile?: any;
    addedFile?: any;
    showDisclaimerText?: boolean;
}

const FundedOrgComponent = (props: FundedOrgComponentProps) => {
    const {
        isInitialApplication,
        // fieldName,
        // index,
        dispatch,
        outletsSelectList,
        register,
        authService,
        errors,
        inquiry,
        setInquiry,
        watch,
        // removeItem,
        // clearErrors,
        control,
        trigger,
        goingBackwards,
        setValue,
        isVisible,
        removePriorDataRelationshipChanged,
        removePriorDataOrgNameChanged,
        isEditableCM,
        removedFile,
        addedFile,
        showDisclaimerText,
    } = props;

    const appContext = useContext(AppContext);
    const disableOrgNameField = isEditableCM === true || appContext.wizardState.wizardMode === WizardMode.Resubmit ? true : false;
    const isFSRP =
        inquiry.application.activeComplaintsMechanismObj?.outlet &&
        inquiry.application.activeComplaintsMechanismObj?.outlet.organisationFundingType === OrganisationFundingType.FRSPFunded;
    const disableRelationshipField =
        (isEditableCM === true &&
            inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType === ComplaintsMechanismType.GovernmentFundedFDRService &&
            isFSRP === true) ||
        (appContext.wizardState.wizardMode === WizardMode.Resubmit ? true : false);
    const idFileUpload = 'inquiry.application.activeComplaintsMechanismObj.attachmentExists';
    const isLegalAidOther =
        inquiry.application.activeComplaintsMechanismObj?.outlet &&
        (inquiry.application.activeComplaintsMechanismObj?.outlet.organisationFundingType === OrganisationFundingType.LegalAid ||
            inquiry.application.activeComplaintsMechanismObj?.outlet.organisationFundingType === OrganisationFundingType.Other);
    const hasValueRelationshipToEmployer =
        inquiry.application.activeComplaintsMechanismObj &&
        inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation &&
        inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation !== null;
    // show if prior value is set and loaded AND is of LegalAid/Other type
    const [showRelationshipInputField, setShowRelationshipInputField] = useState(
        isEditableCM === true ? true : hasValueRelationshipToEmployer && isLegalAidOther,
    );
    const [showRelationshipLabel, setShowRelationshipLabel] = useState(isEditableCM === true ? false : hasValueRelationshipToEmployer && !isLegalAidOther);
    const relationshipToEmployerTypeWatched: RelationshipToEmployerType = watch(
        'inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation',
        inquiry.application.activeComplaintsMechanismObj?.relationshipToOrganisation,
    );
    const outletWatched = watch(
        'inquiry.application.activeComplaintsMechanismObj.outlet.outletId',
        inquiry.application.activeComplaintsMechanismObj?.outlet?.outletId,
    );

    const setRelationshipRadioButton = () => {
        // must check one then!
        const radioButtons: NodeListOf<HTMLElement> = document.getElementsByName('inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation');
        if (radioButtons) {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < radioButtons.length; i++) {
                const radioButton: HTMLInputElement = radioButtons[i] as HTMLInputElement;
                if (radioButton.value === relationshipToEmployerTypeWatched) {
                    radioButton.checked = true;
                }
            }
        }
    };

    const clearRelationshipRadioButtons = () => {
        const radioButtons: NodeListOf<HTMLElement> = document.getElementsByName('inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation');
        if (radioButtons) {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < radioButtons.length; i++) {
                const radioButton: HTMLInputElement = radioButtons[i] as HTMLInputElement;
                radioButton.checked = false;
            }
        }
    };

    // determine drop-down value if we need to show relationship field (user can load file then select an Legal Aid item then we re-render and lose context, this will help)
    const updateListSelection = () => {
        // defaultValue={`${fieldName}.outlet.outletId`}
        const orgNameListId = `inquiry.application.activeComplaintsMechanismObj.outlet.outletId`;
        const ddListBox = window.document.getElementById(orgNameListId) as HTMLSelectElement;
        // now find the list item in the list
        let foundItem = null;
        if (ddListBox) {
            if (inquiry.application.activeComplaintsMechanismObj?.outlet !== undefined && inquiry.application.activeComplaintsMechanismObj?.outlet !== null) {
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < ddListBox.options.length; i++) {
                    const inputElement = ddListBox[i] as unknown as HTMLInputElement;
                    if (
                        inquiry.application.activeComplaintsMechanismObj?.outlet !== undefined &&
                        inputElement.value === inquiry.application.activeComplaintsMechanismObj?.outlet!.outletId
                    ) {
                        foundItem = ddListBox[i];
                        break;
                    }
                }
            }
        }

        // if editing CM then ignore resetting these
        if (isEditableCM !== true) {
            const selectedType = foundItem && foundItem.getAttribute('data-option-type');

            if (selectedType === OrganisationFundingType.LegalAid.toString() || selectedType === OrganisationFundingType.Other.toString()) {
                // set to null to trip validator as required
                setShowRelationshipInputField(true);
                setShowRelationshipLabel(false);
            } else {
                setShowRelationshipLabel(true);
                setShowRelationshipInputField(false);
            }
        }
    };

    const setRelationshipOptionIfNotSelected = () => {
        // if relationship is not yet set, as user has just changed the value in drop-down, then we need to ensure if also it needs to trip validation at this point or not
        if (!IsRelationshipSet()) {
            if (
                inquiry.application.activeComplaintsMechanismObj &&
                inquiry.application.activeComplaintsMechanismObj.outlet &&
                (inquiry.application.activeComplaintsMechanismObj.outlet.organisationFundingType === OrganisationFundingType.LegalAid.toString() ||
                    inquiry.application.activeComplaintsMechanismObj.outlet.organisationFundingType === OrganisationFundingType.Other.toString())
            ) {
                // trips validator
                inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation = null;
                setValue(`inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation`, null, { shouldDirty: true, shouldValidate: true });
            }

            if (
                inquiry.application.activeComplaintsMechanismObj &&
                inquiry.application.activeComplaintsMechanismObj.outlet &&
                inquiry.application.activeComplaintsMechanismObj.outlet.organisationFundingType === OrganisationFundingType.FRSPFunded.toString()
            ) {
                // bypass validator
                inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation = undefined;
                setValue(`inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation`, undefined, { shouldDirty: true, shouldValidate: true });
            }
        }
    };

    // initial load
    useEffect(() => {
        updateListSelection();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // upon change of selection we immediately show/hide menu options (for initial application ONLY)
    useEffect(() => {
        if (relationshipToEmployerTypeWatched && isInitialApplication === true) {
            ToggleEmployeeOrPanelMemberChildMenu(appContext, relationshipToEmployerTypeWatched);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [relationshipToEmployerTypeWatched]);

    useEffect(() => {
        updateListSelection();
        setRelationshipRadioButton();
        // to trip validation or not to trip!
        setRelationshipOptionIfNotSelected();

        Sleep(1500).then(() => {
            trigger();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outletWatched, outletsSelectList]);

    // update attachments collection as that is seperate from other data application object (stored within complaintsMechanism[n])
    // max 1 files means we either add or remove a file here
    const updatedFilesList = (attachments: IAttachmentMetadata[]) => {
        /* not here causes errors: // refresh CM object/active obj
        if (inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId) {
            dispatch(appActions.GetComplaintsMechanism(inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId));
        } */

        if (attachments && attachments.length > 0) {
            trigger();
        }
    };

    // default is set to this index
    const attachmentIdCollection = GetActiveGovtFundedComplaintAttachmentIdCollection(inquiry.application);

    const radioOptionsRelationshipToEmployer: RadioButtonOption[] = [
        { label: 'employee', value: RelationshipToEmployerType.Employee, disabled: disableRelationshipField },
        { label: 'included on the external panel of FDR providers', value: RelationshipToEmployerType.OnPanel, disabled: disableRelationshipField },
    ];

    const IsRelationshipSet = () => {
        return (
            inquiry.application.activeComplaintsMechanismObj &&
            inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation !== undefined &&
            inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation !== null
        );
    };

    const IsInsuranceSet = () => {
        return (
            inquiry.application.activeComplaintsMechanismObj &&
            inquiry.application.activeComplaintsMechanismObj.hasProfessionalIndemnityInsuranceViaMembership !== undefined &&
            inquiry.application.activeComplaintsMechanismObj.hasProfessionalIndemnityInsuranceViaMembership !== null
        );
    };

    const doesPriorDataExistThatWillBeDeleted = () => {
        // we change the type so this checks simply if file attached (therefore insurance also may or may not exist) but presence of an attachment means data exists
        const attachmentsGovtFunded = IsAnyAttachmentActiveAndGovtFundedForThisComplaint(
            inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId,
            inquiry.application.activeComplaintsMechanismObj?.evidence,
        );
        return attachmentsGovtFunded || IsRelationshipSet() || IsInsuranceSet();
    };

    // reload data if going backwards
    useEffect(() => {
        if (goingBackwards && goingBackwards === true) {
            updateListSelection();
            setRelationshipRadioButton();
            // trigger list selection
            // const currValue = inquiry.application.complaintsMechanism[index].outlet!.outletId;
            // inquiry.application.complaintsMechanism[index].outlet!.outletId = '';
            // trigger outletWatched
            Sleep(1500).then(() => {
                trigger();
                // inquiry.application.complaintsMechanism[index].outlet!.outletId = currValue;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [goingBackwards]);

    const onChangeOrgName = async (event: any) => {
        const selectedValue = event.target?.value;
        const selectedType = event.target.options[event.target.selectedIndex].getAttribute('data-option-type');
        const origOutletOrgFundingType = inquiry.application.activeComplaintsMechanismObj?.outlet?.organisationFundingType;
        const hasTypeChanged = origOutletOrgFundingType !== undefined && origOutletOrgFundingType !== null && selectedType !== origOutletOrgFundingType;

        function revertSelection() {
            // Setting the target.checked property to the defaultChecked will
            // deselect the current radio button and select the radio button that
            // was previously selected.
            event.preventDefault();
            event.stopPropagation();
            // return the orig value
            setValue(`inquiry.application.activeComplaintsMechanismObj.outlet.outletId`, inquiry.application.activeComplaintsMechanismObj?.outlet!.outletId, {
                shouldDirty: true,
                shouldValidate: true,
            });

            // setRelationshipRadioButton();
            // TODO: revert drop down list selection?
            return false;
        }

        function changeSelection() {
            // change the selected option
            if (inquiry.application.activeComplaintsMechanismObj) {
                // if new outlet then create new empty outlet
                if (inquiry.application.activeComplaintsMechanismObj.outlet === null) {
                    inquiry.application.activeComplaintsMechanismObj.outlet = { ...defaultOutletPractitionerData };
                }

                // change the drop down value
                inquiry.application.activeComplaintsMechanismObj.outlet!.outletId = selectedValue;
                setValue(`inquiry.application.activeComplaintsMechanismObj.outlet.outletId`, selectedValue, { shouldDirty: true, shouldValidate: false });
                // set the org funding type
                inquiry.application.activeComplaintsMechanismObj.outlet!.organisationFundingType = selectedType;
                setValue(`inquiry.application.activeComplaintsMechanismObj.outlet.organisationFundingType`, selectedType);

                // if org is set then set the complaintsProcessType as a mapping
                if (selectedType === OrganisationFundingType.LegalAid.toString()) {
                    inquiry.application.activeComplaintsMechanismObj.complaintsProcessType = ComplaintsProcessType.LegalAidStatutoryBody;
                    setValue(`inquiry.application.activeComplaintsMechanismObj.complaintsProcessType`, ComplaintsProcessType.LegalAidStatutoryBody);
                }
                if (selectedType === OrganisationFundingType.Other.toString()) {
                    inquiry.application.activeComplaintsMechanismObj.complaintsProcessType = ComplaintsProcessType.OtherStatutoryBody;
                    setValue(`inquiry.application.activeComplaintsMechanismObj.complaintsProcessType`, ComplaintsProcessType.OtherStatutoryBody);
                }
                if (selectedType === OrganisationFundingType.FRSPFunded.toString()) {
                    inquiry.application.activeComplaintsMechanismObj.complaintsProcessType = ComplaintsProcessType.GovernmentFundedFDRService;
                    setValue(`inquiry.application.activeComplaintsMechanismObj.complaintsProcessType`, ComplaintsProcessType.GovernmentFundedFDRService);
                }

                // if org is of type Legal Aid or Other then we show extra field
                if (selectedType === OrganisationFundingType.LegalAid.toString() || selectedType === OrganisationFundingType.Other.toString()) {
                    // set to null to trip validator as required
                    setShowRelationshipInputField(true);
                    setShowRelationshipLabel(false);
                    inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation = null;
                    setValue(`inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation`, null, { shouldDirty: true, shouldValidate: false });
                    // clear the radio button
                    clearRelationshipRadioButtons();
                } else {
                    // set to Employee by default
                    setShowRelationshipInputField(false);
                    setShowRelationshipLabel(true);
                    inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation = RelationshipToEmployerType.Employee;
                    setValue(`inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation`, RelationshipToEmployerType.Employee, {
                        shouldDirty: true,
                        shouldValidate: false,
                    });
                }

                // push changes into object
                setInquiry(inquiry);
            }
        }

        if (hasTypeChanged === true) {
            if (
                await ModalDialogConfirmable(MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_ACCRED_TYPE, MODALBUTTON1_OK, MODALBUTTON2_CANCEL, {
                    title: MODALHEADER_DATACHANGEPOTENTIAL,
                    confirmationP2: MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_P2,
                    confirmationP3: MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_P3,
                    isDestructive: true,
                    dataTestId: 'modalPromptToRemoveData_ChangeOrgName',
                })
            ) {
                // proceed and remove prior data
                changeSelection();
                // user has accepted that data can be removed so we remove that now (and this item becomes index=0 as we remove ALL)
                if (removePriorDataOrgNameChanged !== undefined) {
                    // check as EDIT UI is now read only for this field
                    removePriorDataOrgNameChanged();
                }
            } else {
                // revert back and do not remove any data
                revertSelection();
            }
        } else {
            // just change drop down selection, leave data alone will match based on type
            changeSelection();
        }
    };

    const onChangeRelationshipToEmployer = async (event: any) => {
        const selectedValue = event.target?.value;
        const dataExistsThatCouldBeDeleted = doesPriorDataExistThatWillBeDeleted();

        function revertSelection() {
            // Setting the target.checked property to the defaultChecked will
            // deselect the current radio button and select the radio button that
            // was previously selected.
            event.target.checked = event.target.defaultChecked;
            event.preventDefault();
            event.stopPropagation();

            // return the orig value
            setValue(
                `inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation`,
                inquiry.application.activeComplaintsMechanismObj?.relationshipToOrganisation,
                {
                    shouldDirty: true,
                    shouldValidate: true,
                },
            );
            setRelationshipRadioButton();
            return false;
        }

        function changeSelection() {
            // change the selected option
            setValue(`inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation`, selectedValue, { shouldDirty: true, shouldValidate: true });
            // MUST set the new value otherwise the reload of data will just set it back to orig value!
            inquiry.application.activeComplaintsMechanismObj!.relationshipToOrganisation = selectedValue;
            // if we change the relationship we must delete Insurance data, if exists
            if (inquiry.application.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership !== null) {
                setValue(`inquiry.application.activeComplaintsMechanismObj.hasProfessionalIndemnityInsuranceViaMembership`, null);
                inquiry.application.activeComplaintsMechanismObj!.hasProfessionalIndemnityInsuranceViaMembership = null;
                setValue(`inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceFromDate`, null);
                inquiry.application.activeComplaintsMechanismObj!.professionalIndemnityInsuranceFromDate = undefined;
                setValue(`inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceToDate`, null);
                inquiry.application.activeComplaintsMechanismObj!.professionalIndemnityInsuranceToDate = undefined;
            }
            // push changes into object
            setInquiry(inquiry);
        }

        if (dataExistsThatCouldBeDeleted === true) {
            if (
                await ModalDialogConfirmable(MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_ACCRED_TYPE, MODALBUTTON1_OK, MODALBUTTON2_CANCEL, {
                    title: MODALHEADER_DATACHANGEPOTENTIAL,
                    confirmationP2: MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_P2,
                    confirmationP3: MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_P3,
                    isDestructive: true,
                    dataTestId: 'modalPromptToRemoveData_ChangeRelationship',
                })
            ) {
                // proceed and remove prior data
                changeSelection();

                // user has accepted that data can be removed (attachments/insurance if there) so we remove that now
                removePriorDataRelationshipChanged(selectedValue);
            } else {
                // revert back and do not remove any data
                revertSelection();
            }
        } else {
            changeSelection();
        }
    };

    const displayOutlets = () => {
        return (
            <>
                {disableOrgNameField && (
                    <input
                        type='hidden'
                        className='form-control'
                        name='inquiry.application.activeComplaintsMechanismObj.outlet.outletId'
                        {...register('inquiry.application.activeComplaintsMechanismObj.outlet.outletId')}
                        defaultValue={inquiry.application.activeComplaintsMechanismObj?.outlet?.outletId}
                    />
                )}
                <Row>
                    <Col>
                        <SelectListField
                            id={'inquiry.application.activeComplaintsMechanismObj.outlet.outletId'} // id={`${fieldName}.outlet.outletId`}
                            displayName='Organisation name'
                            guidanceText='Select the name of the organisation through which you are providing your FDR services.'
                            defaultValue={
                                inquiry.application.activeComplaintsMechanismObj?.outlet
                                    ? inquiry.application.activeComplaintsMechanismObj?.outlet!.outletId
                                    : ''
                            }
                            disabled={disableOrgNameField}
                            options={outletsSelectList}
                            isMandatory={true}
                            register={register}
                            isOneAndHalfWidth={true}
                            errorsField={errorFieldOutletName}
                            helpHeaderText={HELPHEADERTEXT_ORGANISATIONNAME}
                            helpText={HELPTEXT_ORGANISATIONNAME}
                            handleOnChange={onChangeOrgName}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    // report on any error for outlet.outletId field
    const errorFieldsThisIndex = errors.inquiry?.application?.activeComplaintsMechanismObj; // complaintsMechanism?.find((_element: any, i: number) => i === 0);
    const errorFieldOutletName = errorFieldsThisIndex && errorFieldsThisIndex?.outlet?.outletId;
    const errorRelationshipToEmployer = errorFieldsThisIndex && errorFieldsThisIndex?.relationshipToOrganisation;
    // report on any error for attachments.fundedOrg field
    // const errorFieldAttachmentThisIndex = errors.inquiry?.application?.activeComplaintsMechanismObj; // complaintsMechanism?.find((_element: any, i: number) => i === 0); // errors.inquiry?.application?.attachments?.fundedOrg?.find((_element: any, i: number) => i === index);
    // const errorFieldAttachmentFundedOrg = 'inquiry.application.attachments'; // errorFieldAttachmentThisIndex && errorFieldAttachmentThisIndex?.activeAttachmentId;

    return (
        <div id={'inquiry.application.activeComplaintsMechanismObj'} className={isVisible === true ? '' : makeInvisible}>
            <Row className={fundedOrgDetailHeader}>
                <Col>
                    <label>
                        <h3>Organisation Details</h3>
                    </label>
                </Col>
            </Row>
            <Row className={fundedOrgDetailBody}>
                <Col>
                    {disableOrgNameField === true && (
                        <input
                            type='hidden'
                            className='form-control'
                            id={`hidden.inquiry.application.activeComplaintsMechanismObj.outlet.outletId`}
                            {...register(`hidden.inquiry.application.activeComplaintsMechanismObj.outlet.outletId`)}
                            defaultValue={inquiry.application.activeComplaintsMechanismObj?.outlet?.outletId}
                        />
                    )}
                    {outletsSelectList && outletsSelectList.length > 0 && displayOutlets()}
                    {showRelationshipLabel && (
                        <Row>
                            <Col>
                                <LabelField
                                    id='relationshipLabel'
                                    displayName='Relationship to organisation'
                                    value={inquiry.application.activeComplaintsMechanismObj?.relationshipToOrganisation ?? 'N/A'}
                                    isMandatory={false}
                                />
                            </Col>
                        </Row>
                    )}
                    {showRelationshipInputField && (
                        <Row>
                            <Col>
                                <RadioButtonGroupField
                                    options={radioOptionsRelationshipToEmployer}
                                    id={`inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation`}
                                    displayName='Relationship to organisation'
                                    fieldLabel='What is your relationship to the organisation you have selected?'
                                    defaultValue={inquiry?.application.activeComplaintsMechanismObj?.relationshipToOrganisation} // only set default if value exists
                                    isMandatory={true}
                                    control={control}
                                    register={register}
                                    errorsField={errorRelationshipToEmployer}
                                    onChange={onChangeRelationshipToEmployer}
                                />
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col>
                            {inquiry.application.activeComplaintsMechanismObj?.relationshipToOrganisation === RelationshipToEmployerType.OnPanel && (
                                <FileUploadField
                                    id={idFileUpload} // we need to render this when relationship is undefined or other, as when handing it the docType if it dynamically changes the FileUpload is not updating
                                    data-id={0}
                                    attachmentIdCollection={attachmentIdCollection}
                                    refreshIfAttachmentIdNotFound={true} // will trigger empty fileInfo if the above Id not yet uploaded/exists
                                    maxFilesCount={3}
                                    applicationId={inquiry?.application.applicationId}
                                    complaintsProcessId={inquiry?.application.activeComplaintsMechanismObj?.complaintsMechanismId}
                                    authService={authService}
                                    displayNameSuffix='employment or panel inclusion'
                                    errorsFieldSuffix='organisation'
                                    documentType={AttachmentDocumentType.OrgPanel}
                                    isMandatory={true}
                                    updatedFilesList={updatedFilesList}
                                    register={register}
                                    goingBackwards={goingBackwards}
                                    dispatch={dispatch}
                                    errorsField={errors.inquiry?.application?.attachments}
                                    removedFile={removedFile}
                                    addedFile={addedFile}
                                    showDisclaimerText={showDisclaimerText}
                                />
                            )}
                            {inquiry.application.activeComplaintsMechanismObj?.relationshipToOrganisation !== RelationshipToEmployerType.OnPanel && (
                                <FileUploadField
                                    id={idFileUpload} // we need to render this when relationship is undefined or other, as when handing it the docType if it dynamically changes the FileUpload is not updating
                                    data-id={0}
                                    attachmentIdCollection={attachmentIdCollection}
                                    refreshIfAttachmentIdNotFound={true} // will trigger empty fileInfo if the above Id not yet uploaded/exists
                                    maxFilesCount={3}
                                    applicationId={inquiry?.application.applicationId}
                                    complaintsProcessId={inquiry?.application.activeComplaintsMechanismObj?.complaintsMechanismId}
                                    authService={authService}
                                    displayNameSuffix='employment or panel inclusion'
                                    errorsFieldSuffix='organisation'
                                    documentType={AttachmentDocumentType.OrgEmployment}
                                    isMandatory={true}
                                    updatedFilesList={updatedFilesList}
                                    register={register}
                                    goingBackwards={goingBackwards}
                                    dispatch={dispatch}
                                    errorsField={errors.inquiry?.application?.attachments}
                                    removedFile={removedFile}
                                    addedFile={addedFile}
                                    showDisclaimerText={showDisclaimerText}
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default FundedOrgComponent;
