import React from 'react';
import { Row, Col } from 'react-bootstrap';
// tslint:disable-next-line: no-implicit-dependencies
import { Controller } from 'react-hook-form';
import MandatoryLabelIndicator from './MandatoryLabelIndicator';
import CheckBoxGroup from '../../controls/CheckBoxGroup';
import GuidanceText from '../../controls/GuidanceText';
import HelpText from '../../controls/HelpText';
import stylesFields from './Fields.module.scss';

const { fieldTitle, fieldValidationError, validationErrorRow, fieldFinalRow } = stylesFields;

export interface CheckBoxOption {
    label: string;
    value: any;
    defaultValue?: any;
    toggleCheckIndex: number;
    setToTrue?: boolean;
    disabled: boolean;
    children?: any;
    helpHeaderText?: string;
    helpText?: string;
}

// leverages: https://learnersbucket.com/examples/react/create-checkbox-in-react/
export interface CheckBoxFieldProps {
    id: string;
    displayName: string;
    fieldLabel: string;
    options: CheckBoxOption[];
    defaultValue?: boolean; // for react-hook-form to register properly
    defaultChecked?: boolean;
    control: any;
    isMandatory: boolean;
    register: any;
    errorsField: any;
    autoFocus?: boolean;
    guidanceText?: string;
    helpHeaderText?: string;
    helpText?: string;
    onChange: any;
    hideFinalEmptyRow?: boolean;
    setAllTrue?: any;
    setAllFalse?: any;
    refreshTrueItems?: any;
}

const CheckBoxGroupField = (props: CheckBoxFieldProps) => {
    // , ref: any) => {
    const {
        id,
        displayName,
        fieldLabel,
        options,
        defaultValue,
        // defaultChecked,
        control,
        isMandatory,
        // register,
        errorsField,
        // autoFocus,
        guidanceText,
        helpHeaderText,
        helpText,
        onChange,
        hideFinalEmptyRow = false,
        setAllTrue,
        setAllFalse,
        refreshTrueItems,
    } = props;

    // forwardRef issue? see: https://github.com/react-hook-form/react-hook-form/discussions/3744
    // render={({ field: { ref, ...rest }, fieldState }) => (
    // <PhoneInput {...rest} {...fieldState} error={errors.phone} />
    // )}

    return (
        <>
            {displayName && (
                <Row>
                    <Col>
                        <span className={fieldTitle}>
                            {isMandatory && <MandatoryLabelIndicator />}
                            {displayName}
                        </span>
                    </Col>
                </Row>
            )}
            {guidanceText && (
                <Row>
                    <Col>
                        <GuidanceText guidanceText={guidanceText} fieldName={id} />
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Controller
                        // tslint:disable-next-line: jsx-no-lambda
                        render={({ field: { ref, ...rest }, fieldState, formState }) => (
                            <CheckBoxGroup
                                {...rest}
                                id={id}
                                defaultValue={defaultValue}
                                fieldLabel={fieldLabel}
                                name={id}
                                onChange={onChange}
                                options={options}
                                setAllTrue={setAllTrue}
                                setAllFalse={setAllFalse}
                                refreshTrueItems={refreshTrueItems}
                            />
                        )}
                        name={id}
                        control={control}
                        defaultValue={defaultValue}
                    />
                </Col>
            </Row>
            <Row>
                <Col className={fieldValidationError}>{errorsField && <span role='alert'>{errorsField.message}</span>}</Col>
            </Row>
            <Row className={validationErrorRow}>
                <Col className={fieldValidationError}>{errorsField && errorsField.validate && <span role='alert'>{errorsField.validate.message}</span>}</Col>
            </Row>
            {helpHeaderText && (
                <Row>
                    <Col>
                        <HelpText helpHeaderText={helpHeaderText} helpText={helpText} fieldName={id} />
                    </Col>
                </Row>
            )}
            {!hideFinalEmptyRow && (
                <Row className={fieldFinalRow}>
                    <Col />
                </Row>
            )}
        </>
    );
};

// export default CheckBoxField;
// export default React.forwardRef((props: CheckBoxFieldProps, ref: any) => CheckBoxField(props, ref));

export default CheckBoxGroupField;
