import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
// tslint:disable-next-line: no-submodule-imports
import au from 'date-fns/locale/en-AU'; // the locale you want
import { Controller } from 'react-hook-form';
import MandatoryLabelIndicator from './MandatoryLabelIndicator';
import { DATE_FORMAT } from '../../services/Constants';
import GuidanceText from '../../controls/GuidanceText';
import HelpText from '../../controls/HelpText';
// tslint:disable-next-line: no-submodule-imports
import 'react-datepicker/dist/react-datepicker.css';
import styles from './Fields.module.scss';

const { fieldTitle, fieldValidationError, datePickerRow, validationErrorRow, fieldFinalRow } = styles;

export interface InputDateFieldProps {
    id: string;
    displayName: string;
    defaultValue?: Date;
    openToDate?: Date;
    minDate?: Date;
    maxDate?: Date;
    disabled: boolean;
    isMandatory: boolean;
    register: any;
    control: any;
    errorsField: any;
    placeHolder?: string;
    guidanceText?: string;
    helpHeaderText?: string;
    helpText?: string;
    hideFinalEmptyRow?: boolean;
    yearDropdownRange?: number;
    yearDropdownRangeStartingYear?: number;
    applyYearRangeToMaxMin?: boolean;
    // onCalendarOpen: any;
    dropdownMode?: 'select' | 'scroll' | undefined;
}

const handleDateChangeRaw = (e: any) => {
    e.preventDefault();
};

const ReactDatePickerWithController = (props: InputDateFieldProps) => {
    // const ReactDatePickerWithController = React.forwardRef((props: InputDateFieldProps, ref) => {
    const {
        id,
        placeHolder,
        minDate,
        maxDate,
        disabled,
        errorsField,
        openToDate,
        control,
        defaultValue,
        yearDropdownRange,
        yearDropdownRangeStartingYear,
        applyYearRangeToMaxMin,
        dropdownMode,
    } = props;
    const today = new Date();
    const yearDropdownItemNumber = yearDropdownRange === undefined ? 20 : yearDropdownRange;

    // tslint:disable-next-line: prefer-const
    let minDateModded = minDate;
    // tslint:disable-next-line: prefer-const
    let maxDateModded = maxDate;

    // apply min/max range to the yearDropdownRangeStartingYear, or todays year if yearDropdownRangeStartingYear not supplied
    if (applyYearRangeToMaxMin === true && yearDropdownRange) {
        // if yearDropdownRangeStartingYear year is not the current year then we need to adjust the min/max year by that amount
        //  i.e. if yearDropdownRangeStartingYear year is 2024 and we are currently in 2023, then our adjustment becomes +1 for max/min
        const yearRangeAdjustment = yearDropdownRangeStartingYear
            ? today.getFullYear() === yearDropdownRangeStartingYear
                ? 0
                : yearDropdownRangeStartingYear - today.getFullYear()
            : 0;
        // tslint:disable-next-line: prefer-const
        let maxDateRanged = new Date(); // MUST be a new date
        maxDateRanged.setFullYear(today.getFullYear() + yearDropdownRange + yearRangeAdjustment);
        maxDateRanged.setMonth(11, 31); // index starts at 0, dayOfMonth as well

        maxDateModded = maxDateRanged;

        // tslint:disable-next-line: prefer-const
        let minDateRanged = new Date(); // MUST be a new date
        minDateRanged.setFullYear(today.getFullYear() - yearDropdownRange + yearRangeAdjustment);
        minDateRanged.setMonth(0, 1); // index starts at 0, dayOfMonth as well

        minDateModded = minDateRanged;
    }

    return (
        <Controller
            // tslint:disable-next-line: jsx-no-lambda
            render={({ field: { ref, ...rest }, fieldState, formState }) => (
                <ReactDatePicker
                    {...rest}
                    ref={ref}
                    id={id}
                    dateFormat={DATE_FORMAT}
                    placeholderText={placeHolder}
                    popperPlacement='right-end'
                    locale='en-AU' // MOST IMPORTANT
                    isClearable={!disabled}
                    onChangeRaw={handleDateChangeRaw}
                    minDate={minDateModded}
                    maxDate={maxDateModded}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    scrollableYearDropdown={false}
                    yearDropdownItemNumber={yearDropdownItemNumber}
                    disabled={disabled}
                    dropdownMode={dropdownMode} // this mode will prevent the Year dropdown showing a range if 'select' and scrollableYearDropdown=true
                    aria-invalid={errorsField && errorsField.type ? 'true' : 'false'}
                    onChange={e => rest.onChange(e)}
                    // onChange={(date: Date | null) => date && setSelectedDate(date)}
                    onFocus={e => e.currentTarget.select()}
                    selected={rest.value}
                    // selected={selectedDate}
                    openToDate={openToDate}
                />
            )}
            control={control}
            defaultValue={defaultValue}
            name={id}
            // {...register(id)} // this causes ForwardRef() error
        />
    );
};

const InputDateField = (props: InputDateFieldProps) => {
    const {
        id,
        displayName,
        // defaultValue,
        // openToDate,
        // minDate,
        // disabled,
        isMandatory,
        // register,
        // control,
        errorsField,
        // placeHolder,
        guidanceText,
        helpHeaderText,
        helpText,
        hideFinalEmptyRow,
    } = props;

    // globally set the locale for our ReactDatePicker
    // setDefaultLocale('en_AU');
    registerLocale('en-AU', au); // register it with the name you want

    // const ref = useRef();

    return (
        <>
            <Row>
                <Col>
                    <label className={fieldTitle} htmlFor={id}>
                        {isMandatory && <MandatoryLabelIndicator />}
                        {displayName}
                    </label>
                </Col>
            </Row>
            {guidanceText && (
                <Row>
                    <Col>
                        <GuidanceText guidanceText={guidanceText} fieldName={id} />
                    </Col>
                </Row>
            )}
            <Row className={datePickerRow}>
                <Col className='col-md-4'>
                    <ReactDatePickerWithController {...props} />
                </Col>
            </Row>
            <Row>
                <Col className={fieldValidationError}>{errorsField && <span role='alert'>{errorsField.message}</span>}</Col>
            </Row>
            <Row className={validationErrorRow}>
                <Col className={fieldValidationError}>{errorsField && errorsField.validate && <span role='alert'>{errorsField.validate.message}</span>}</Col>
            </Row>
            {helpHeaderText && (
                <Row>
                    <Col>
                        <HelpText helpHeaderText={helpHeaderText} helpText={helpText} fieldName={id} />
                    </Col>
                </Row>
            )}
            {!hideFinalEmptyRow && (
                <Row className={fieldFinalRow}>
                    <Col />
                </Row>
            )}
        </>
    );
};

export default InputDateField;
