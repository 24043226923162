import React, { useState } from 'react';
import SiteMenuListItems from './SiteMenuItems';
import { IsUserInWizard } from '../wizard/wizardStateManager';
import { AppContextInterface } from '../../stateManagement/context/AppContext';
import WizardMenuItems from '../../components/wizard/wizardMenuItems';
import { hamburgerIcon } from '../../components/wizard/wizardMenuIcons';
import styles from './site.module.scss';

const { mobileSiteMenu } = styles;

interface MobileMenuContainerProps {
    appContext: AppContextInterface;
}
// Hosts the Hamburger and Wizard Menu when in mobile or small screen view
const MobileMenuContainer = (props: MobileMenuContainerProps) => {
    const { appContext } = props;
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const HamburgerClicked = () => {
        setShowMobileMenu(!showMobileMenu);
    };

    const showSiteMenu = IsUserInWizard(appContext) === false && showMobileMenu;
    const showWizardMenu = IsUserInWizard(appContext) && showMobileMenu;

    return (
        <>
            <div className='navbar-toggler' id='navbar-toggler' data-testid='navbar-toggler'>
                <button
                    className='navbar-toggler-button'
                    type='button'
                    data-toggle='collapse'
                    data-target='#main-menu'
                    aria-controls='main-menu'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                    onClick={HamburgerClicked}
                >
                    {hamburgerIcon()}
                </button>
            </div>
            {showSiteMenu && (
                <div className={mobileSiteMenu}>
                    <SiteMenuListItems />
                </div>
            )}
            {showWizardMenu && <WizardMenuItems wizardStepPropsArray={appContext.wizardSteps} applicationNumber={appContext.applicationNumber} />}
        </>
    );
};

export default MobileMenuContainer;
