// this hosts the DropZone and FileListDisplay control
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FieldError } from 'react-hook-form';
import { IAttachmentMetadata } from '../../../api/interfacesApi';
import GuidanceText from '../../../controls/GuidanceText';
import MandatoryLabelIndicator from '../MandatoryLabelIndicator';
import DropZone from '../../../controls/DropZone';
import HelpText from '../../../controls/HelpText';
import ProgressBar from '../../../controls/ProgressBar';
import FileListDisplay from './FileListDisplay';
import styles from '../Fields.module.scss';

const { fieldTitle, fieldValidationError, validationErrorRow, fieldFinalRow, progressBar } = styles;

export interface FileUploadFieldDisplayProps {
    id: string;
    enabled: boolean;
    fileMetadataList: IAttachmentMetadata[];
    register: any;
    maxFilesCount: number;
    progress: number;
    isMandatory: boolean;
    displayNameSuffix: string; // displayName begins with 'Upload evidence of' text
    selectFile: any;
    rejectFile: any;
    deleteFile: any;
    guidanceText?: string;
    helpHeaderText?: string;
    helpText?: string;
    errorsField: any;
    errorsFieldSuffix?: string;
    showDisclaimerText?: boolean;
}

const FileUploadFieldDisplay = (props: FileUploadFieldDisplayProps) => {
    const {
        id,
        enabled,
        fileMetadataList,
        register,
        maxFilesCount,
        progress,
        isMandatory,
        displayNameSuffix,
        selectFile,
        rejectFile,
        deleteFile,
        guidanceText,
        helpHeaderText,
        helpText,
        errorsField,
        errorsFieldSuffix,
        showDisclaimerText,
    } = props;

    const showDropZone = !fileMetadataList || fileMetadataList.length < maxFilesCount;
    const showProgressBar = true; // it will hide itself based on logic
    const resetProgressBar = progress >= 0 && progress < 100;

    const getErrorMessageFromErrorsField = (fieldError: FieldError) => {
        if (fieldError === undefined || fieldError === null) {
            return '';
        }

        // properly wedded to field
        if (fieldError.ref !== undefined) {
            return fieldError.message;
        }

        // most likely to seperate message from implementation we need to look this up and match supplied suffix
        if (errorsFieldSuffix && (fieldError.message?.endsWith(errorsFieldSuffix) || fieldError.message?.endsWith(errorsFieldSuffix + '.'))) {
            return fieldError.message;
        }
    };

    // for pages where this component is deployed multiple times on the page, we need to distinguish the generic errors messages properly
    const errorMessage = getErrorMessageFromErrorsField(errorsField);
    const fileUploadWrapperDataTestId = `${id}_wrapper`;

    // <input type='hidden' className='form-control' name={id} {...register(id)} defaultValue={id} />
    return (
        <>
            <Row>
                <Col>
                    <label className={fieldTitle} htmlFor={id}>
                        {isMandatory && <MandatoryLabelIndicator />}
                        Upload evidence of {displayNameSuffix}
                    </label>
                </Col>
            </Row>
            {guidanceText && (
                <Row>
                    <Col>
                        <GuidanceText guidanceText={guidanceText} fieldName={id} />
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <div data-testid={fileUploadWrapperDataTestId}>
                        <input
                            type='text'
                            style={{ display: 'none' }}
                            id={id}
                            name={id}
                            {...register(id)}
                            defaultValue={id}
                            // name='inquiryDefObj.inquiry.application.attachments'
                            // defaultValue={inquiryDefObj.inquiry.application.attachments}
                        />
                        {showDropZone === false && <p>NOTE: Maximum files have been uploaded for this field.</p>}
                        {showDropZone === true && (
                            <DropZone
                                id='attachmentsdropzone'
                                uniqueIdForButton={id}
                                enabled={enabled}
                                acceptedFilesDropped={selectFile}
                                rejectedFilesDropped={rejectFile}
                                maxFilesCount={maxFilesCount}
                                showDisclaimerText={showDisclaimerText}
                            />
                        )}
                        {showProgressBar === true && <ProgressBar className={progressBar} progress={progress} reset={resetProgressBar} />}
                        <FileListDisplay fileMetadataList={fileMetadataList} deleteFile={deleteFile} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className={fieldValidationError}>{errorsField && <span role='alert'>{errorMessage}</span>}</Col>
            </Row>
            <Row className={validationErrorRow}>
                <Col className={fieldValidationError}>{errorsField && errorsField.validate && <span role='alert'>{errorsField.validate.message}</span>}</Col>
            </Row>
            {helpHeaderText && (
                <Row>
                    <Col>
                        <HelpText helpHeaderText={helpHeaderText} helpText={helpText} fieldName={id} />
                    </Col>
                </Row>
            )}
            <Row className={fieldFinalRow}>
                <Col />
            </Row>
        </>
    );
};

export default FileUploadFieldDisplay;
