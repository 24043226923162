// Contact Us page
import React from 'react';
import AppContext from '../../stateManagement/context/AppContext';
import BackToDashboardPillButton from '../../controls/BackToDashboardPillButton';

interface ContactUsPageProps {
    pageTitle: string;
    children: any;
}

// see: https://github.com/react-hook-form/react-hook-form/discussions
const ContactUsPage = (props: ContactUsPageProps) => {
    const { pageTitle, children } = props;
    const appContext = React.useContext(AppContext);

    return (
        <>
            <h3 className='display-6 font-weight-bold mb-0'>{pageTitle}</h3>
            <hr />
            {children}
            <hr />
            <div>
                <BackToDashboardPillButton appContext={appContext} />
            </div>
        </>
    );
};

export default ContactUsPage;
