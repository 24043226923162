import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import AppContext from '../../../stateManagement/context/AppContext';
import { DisplayFieldValueOrDefaultText } from '../../../utils/AppUtils';
import { DisplayBool } from '../../../utils/Common';
import { Inquiry } from '../../../api/interfacesApi';
import LabelField from '../../../components/fields/LabelField';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import { WizardMode, WizardStepNumber } from '../../../services/Enums';
import { EmptyRow } from 'controls/EmptyRow';

interface PastApplicationHistoryDisplayProps {
    inquiry: Inquiry;
    formatForPDF: boolean;
}

const PastApplicationHistoryDisplay = (props: PastApplicationHistoryDisplayProps) => {
    const { inquiry, formatForPDF } = props;

    const appContext = useContext(AppContext);
    const showLinkToPage = formatForPDF === true ? false : appContext.wizardState.wizardMode === WizardMode.New ? true : false;

    return (
        <>
            <PageFieldsTitle
                title='Past application history'
                showLinkToPage={showLinkToPage}
                wizardStepNumber={WizardStepNumber.PastApplicationHistory}
                smallerHeader={true}
                underlineTitle={formatForPDF}
            />
            <Row>
                <Col>
                    <LabelField
                        id='reviewHasPreviouslyApplied'
                        displayName='Have you previously applied to be an FDR practitioner?'
                        value={DisplayBool(inquiry.application.hasPreviouslyApplied)}
                        isMandatory={false}
                        isTitleHtag={formatForPDF}
                    />
                </Col>
            </Row>
            {formatForPDF === true ? <EmptyRow /> : null}
            <Row>
                <Col>
                    <LabelField
                        id='reviewPreviousRegNumber'
                        displayName='Previous registration or submission number'
                        value={DisplayFieldValueOrDefaultText(inquiry.application.previousApplicationIdentifier)}
                        isMandatory={false}
                        textCapitalizeOn={true}
                        isTitleHtag={formatForPDF}
                    />
                </Col>
            </Row>
            {formatForPDF === true ? <EmptyRow /> : null}
        </>
    );
};

export default PastApplicationHistoryDisplay;
