// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { WizardStateActionTypeEnums } from './wizardStateManager';
import { ComplaintsMechanismType, NavigationDestination, RelationshipToEmployerType, ReworkSectionType, WizardStepNumber } from '../../services/Enums';
import { ScrollToTop } from '../../pages/PageFunctions';
import { AppContextInterface } from '../../stateManagement/context/AppContext';
import { WizardStepCurrentStateEnum } from './wizardEnums';
import { WizardStepProps } from './wizardInterfaces';
import { isAllMenuItemsStatusSubStepCompletedOrCompleted, getAllMySiblings, isChild, setAllChildMenuItemsStatusCompleted } from './wizardFunctions';

export function ShowOrHidePage(currentFormStep: number, proposedNewStep: number) {
    return currentFormStep === proposedNewStep ? 'd-block' : 'd-none';
}

export function UpdateWizardStepsAndRepaint(appContext: AppContextInterface, wizardSteps: WizardStepProps[]) {
    if (appContext && wizardSteps) {
        // store changes to Wizard Steps
        appContext.setWizardSteps(wizardSteps);

        // and repaint wizard immediately
        RepaintWizard(appContext);
    }
}

export const ActivateStep = (currIndex: number, activateIndex: number, wizardSteps: WizardStepProps[]) => {
    if (currIndex > -1) {
        wizardSteps[currIndex].isActive = false;

        if (activateIndex > -1) {
            wizardSteps[activateIndex].isActive = true;
        } else {
            // we have finished
        }
    }

    return wizardSteps;
};

// used when user resumes at a particular step
export const SetAllPriorStepsToComplete = (currIndex: number, wizardSteps: WizardStepProps[]) => {
    for (let idx = 0; idx < currIndex; idx++) {
        if (wizardSteps[idx] && wizardSteps[idx].stepCompleted === false) {
            wizardSteps[idx].stepCompleted = true;
            wizardSteps[idx].currentState = WizardStepCurrentStateEnum.completed;
            if (wizardSteps[idx].loadedState) {
                wizardSteps[idx].loadedState = WizardStepCurrentStateEnum.completed;
            }
        }
    }
    return wizardSteps;
};

export const SetAllFutureStepsIncomplete = (currIndex: number, wizardSteps: WizardStepProps[]) => {
    const maxIndex = WizardStepNumber.ReviewAndSubmit;

    for (let idx = currIndex; idx <= maxIndex; idx++) {
        if (wizardSteps[idx] && wizardSteps[idx].stepCompleted === true) {
            wizardSteps[idx].stepCompleted = false;
            wizardSteps[idx].currentState = WizardStepCurrentStateEnum.initial;
            wizardSteps[idx].loadedState = WizardStepCurrentStateEnum.initial;
        }
    }
    return wizardSteps;
};

// can only move forward if the current index is an active node, and next is another node
export const IncrementAndActivateNextStep = (currIndex: number, wizardSteps: WizardStepProps[], isResubmitFlow: boolean = false) => {
    if (currIndex > -1) {
        wizardSteps[currIndex].isActive = false;

        let nextIndex = -1;
        if (isResubmitFlow === true) {
            nextIndex = FindNextIncompleteStep(wizardSteps, currIndex);
            // if no more we default to last declaration step
            if (nextIndex === -1 && currIndex !== WizardStepNumber.DeclarationAndConsent) {
                nextIndex = WizardStepNumber.DeclarationAndConsent;
            }
        } else {
            nextIndex = wizardSteps[currIndex].nextIndex;
        }

        if (nextIndex > -1) {
            wizardSteps[nextIndex].isActive = true;
        } else {
            // we have finished
        }
    }

    return wizardSteps;
};

export const DecrementAndActivatePriorStep = (currIndex: number, wizardSteps: WizardStepProps[], keepChanges: boolean) => {
    if (currIndex > -1) {
        // de-activate current step
        wizardSteps[currIndex].isActive = false;

        // disable this index // ALREADY DONE THIS: OR if user is clicking Back and Savings changes we do the currIndex-1 index instead
        const myIndex = currIndex; // keepChanges ? currIndex - 1 : currIndex;

        // find the prior wizardstep that has this index as its nextIndex value
        const idx = getIndexOfActiveNodePointingToMe(myIndex, wizardSteps);
        if (idx > -1) {
            if (!keepChanges) {
                // user has just gone backwards without a save changes YES
                wizardSteps[idx].isActive = true;
            } else {
                // user has gone backwards and saved changes YES
                // if (currIndex > WizardStepNumber.CompetenciesAndQuals) {
                //    wizardSteps[idx].isActive = true;
                // } else {
                // need to go back to the prior one again as we are now saving changes which does a submit and moves forward one page
                // except for insurance and declaration pages
                // if (currIndex === WizardStepNumber.DeclarationAndConsent) {
                // currIndex === WizardStepNumber.ProfessionalIndemnity ||
                wizardSteps[idx].isActive = true;
                /* no longer move forward then backwards so we just go back only
                } else {
                    const priorAgainIdx = getIndexOfActiveNodePointingToMe(idx, wizardSteps);
                    // fail safe
                    if (priorAgainIdx >= 0) {
                        wizardSteps[priorAgainIdx].isActive = true;
                    } else {
                        wizardSteps[idx].isActive = true;
                    }
                } */
                // }
            }
        }
        // if not changed, we have finished
    }

    return wizardSteps;
};

export const GetActiveWizardStepIndex = (wizardSteps: WizardStepProps[]) => {
    let index = 0;
    let node = wizardSteps[0];
    while (node) {
        if (node.isActive) return index;
        index += 1;
        node = wizardSteps[index];
    }
    // not found or nothing active
    return -1;
};

// Check if form (Step) is valid AND if we have any children which affects whether this node is set to Completed or not
export const GetStepStateUponCompletion = (isValid: boolean, wizardSteps: WizardStepProps[], currIndex: number, keepChanges?: boolean, errors?: any) => {
    const errorsExist = errors?.inquiry !== undefined && errors?.inquiry !== null;
    if (isValid && errorsExist === false) {
        // check if this node is Flag completed
        if (wizardSteps[currIndex].stepCompleted) {
            // check if this node is a child
            if (isChild(wizardSteps, currIndex)) {
                // check if this node has siblings AND if they are all completed (and not invalid/partial), otherwise this node will be marked In Progress (i.e dont change it)
                const allMySiblings = getAllMySiblings(wizardSteps, currIndex);
                if (isAllMenuItemsStatusSubStepCompletedOrCompleted(allMySiblings)) {
                    // now mark all Completed as the icon is set by completed status
                    // and we cannot set substepcompleted as we wont know later which are completed or in motion instead
                    setAllChildMenuItemsStatusCompleted(wizardSteps, currIndex);
                    return WizardStepCurrentStateEnum.completed;
                }

                // otherwise return substep completed
                return WizardStepCurrentStateEnum.substepcompleted; // wizardSteps[currIndex].currentState;
            }

            return WizardStepCurrentStateEnum.completed;
        }

        // user is going backwards, status remains the same
        if (!keepChanges) {
            // stored original state and revert back to that, for now just blank back to initial state
            return wizardSteps[currIndex].loadedState ?? WizardStepCurrentStateEnum.initial;
        }

        return wizardSteps[currIndex].currentState;
    } else {
        return WizardStepCurrentStateEnum.invalidError;
    }
};

export const MarkThisStepCompleted = (goingForward: boolean, wizardStep: WizardStepProps, isValid?: boolean, isDirty?: boolean, keepChanges?: boolean) => {
    if (wizardStep.stepCompleted === true) return;

    if (goingForward) {
        // this flag is used to distinguish if user navigates into a page and backwards without completing it
        wizardStep.stepCompleted = true;
    } else {
        // if going backward we only set Flag to completed IF either:
        //  - the user has made a valid change and also got prompted and agreed to save changes
        // or
        //  - the user has made a change and got prompted and discarded the changes (where originally the step was completed), so we set it back to completed here
        const setStepToCompleted = isValid && isDirty && keepChanges; // || (isValid && isDirty && !keepChanges && wizardStep.stepCompleted === true);

        if (setStepToCompleted) {
            // this flag is used to distinguish if user navigates into a page and backwards without completing it
            wizardStep.stepCompleted = true;
        }
    }
};

// If OK to move forward then move step and set wizardStep
export const MoveWizardForward = (appContext: AppContextInterface, isValid: boolean) => {
    // adjust the wizard step by using the CurrentStep and moving forward once or twice (pending whether the item is displayed or not)
    const currIndex = GetActiveWizardStepIndex(appContext.wizardSteps);

    // setFormStep(cur => cur + numberOfSteps);
    // the numberOfSteps to move forward is determined by either we are at start or the item's nextIndex minus currIndex (could be 2 away)
    const numberOfSteps = currIndex > -1 ? appContext.wizardSteps[currIndex].nextIndex - currIndex : currIndex === 0 ? 1 : 0;
    appContext.dispatchWizardState({ type: WizardStateActionTypeEnums.incrementFormStep, numberOfSteps });

    if (currIndex > -1) {
        // Set the state AND Flag to completed ONLY if the page is Valid
        if (isValid) {
            MarkThisStepCompleted(true, appContext.wizardSteps[currIndex]);
        }

        appContext.wizardSteps[currIndex].currentState = GetStepStateUponCompletion(isValid, appContext.wizardSteps, currIndex);

        const newWizardSteps = IncrementAndActivateNextStep(currIndex, appContext.wizardSteps);
        // setApplicationForm(applicationForm); // store changes to Wizard Steps
        // store changes to Wizard Steps - as we hand them around for mobile/header view
        UpdateWizardStepsAndRepaint(appContext, newWizardSteps);
    }

    ScrollToTop();
};

const FindNextIncompleteStep = (wizardSteps: WizardStepProps[], currIndex: number) => {
    // find any steps not completed and not the current index
    let nextIncompleteStepIndex = -1;

    // tslint:disable-next-line: only-arrow-functions
    const list = wizardSteps.filter(function (e) {
        return e.stepCompleted === false && e.index !== currIndex;
    });
    if (list && list.length > 0) {
        nextIncompleteStepIndex = list[0].index;
    }
    return nextIncompleteStepIndex;
};

// find next available wizard step for user to action or if all done then go straight to declaration
export const MoveWizardForwardForResubmit = (appContext: AppContextInterface, isValid: boolean) => {
    // adjust the wizard step by using the CurrentStep and moving forward n times to next available not-actioned step (also pending whether the item is displayed or not)
    const currIndex = GetActiveWizardStepIndex(appContext.wizardSteps);

    // the numberOfSteps to move forward is determined by either we are at start or the item's nextIndex minus currIndex (could be 2 away)
    let nextIndex = -1;
    nextIndex = FindNextIncompleteStep(appContext.wizardSteps, currIndex);
    // if no more we default to last declaration step
    if (nextIndex === -1 && currIndex !== WizardStepNumber.DeclarationAndConsent) {
        nextIndex = WizardStepNumber.DeclarationAndConsent;
    }
    const numberOfSteps = currIndex > -1 ? nextIndex - currIndex : currIndex === 0 ? 1 : 0;
    appContext.dispatchWizardState({ type: WizardStateActionTypeEnums.incrementFormStep, numberOfSteps });

    if (currIndex > -1) {
        // Set the state AND Flag to completed ONLY if the page is Valid
        if (isValid) {
            MarkThisStepCompleted(true, appContext.wizardSteps[currIndex]);
        }

        appContext.wizardSteps[currIndex].currentState = GetStepStateUponCompletion(isValid, appContext.wizardSteps, currIndex);

        const newWizardSteps = IncrementAndActivateNextStep(currIndex, appContext.wizardSteps, true);
        // setApplicationForm(applicationForm); // store changes to Wizard Steps
        // store changes to Wizard Steps - as we hand them around for mobile/header view
        UpdateWizardStepsAndRepaint(appContext, newWizardSteps);
    }

    ScrollToTop();
};

// first find the node that has my index as its nextIndex, starting from my node - 1 and working our way backwards
const getIndexOfActiveNodePointingToMe = (myIndex: number, wizardSteps: WizardStepProps[]) => {
    let indexPointingToMe = -1;

    for (let idx = myIndex - 1; idx >= 0; idx--) {
        if (wizardSteps[idx] && wizardSteps[idx].nextIndex === myIndex && wizardSteps[idx].isDisplayed === true) {
            indexPointingToMe = idx;
            break;
        }
    }

    return indexPointingToMe;
};

// the nodes are such that some parent/child combos are shown/hidden at different times, therefore the number of steps back
//  will be determined by the nextIndex property as well as the isDisplayed when true property
const calculateNumberOfStepsBack = (origIndex: number, wizardSteps: WizardStepProps[], keepChanges: boolean) => {
    let numberOfStepsBack = 1;
    // NOTE - Forward does NOT kick in any more so no need for this!!:
    // -----------
    let currIndex = origIndex; // if we move back twice we need to keep the origIndex for the later calc difference
    // ignore insurance and declaration pages adjustment
    if (keepChanges === true && currIndex !== WizardStepNumber.DeclarationAndConsent && currIndex !== WizardStepNumber.ProfessionalIndemnity) {
        // && currIndex !== WizardStepNumber.ProfessionalIndemnity
        // } && currIndex <= WizardStepNumber.CompetenciesAndQuals) {
        // if keepChanges, this means user has agreed to save changes and the system has moved forward once already on a submit, so we *may* need to go backwards twice
        currIndex = getIndexOfActiveNodePointingToMe(currIndex, wizardSteps);
    }
    // ------------
    const indexOfPriorNodePointingToMe = getIndexOfActiveNodePointingToMe(currIndex, wizardSteps); // currIndex, wizardSteps);

    if (indexOfPriorNodePointingToMe >= 0) {
        // add a further offset only when not hiding the CM Employee/Member panel on a DO NOT save changes dialog!
        const adjOffset =
            (keepChanges === false && currIndex === WizardStepNumber.ComplaintsMechanismMember) ||
            (keepChanges === false && currIndex === WizardStepNumber.ComplaintsMechanismEmployee) ||
            (keepChanges === true && currIndex === WizardStepNumber.ProfessionalIndemnity)
                ? 0
                : 1;
        numberOfStepsBack = origIndex - indexOfPriorNodePointingToMe - adjOffset; // we now subtract 1 more as we no longer submit and move forward once where before we did
    }

    return numberOfStepsBack;
};

// If OK to move backward then move step and set wizardStep
export const MoveWizardBackward = (appContext: AppContextInterface, keepChanges: boolean, isDirty: boolean, isValid: boolean) => {
    // adjust the wizard step by using the CurrentStep and moving backwards once or twice
    // active wizard step upon save is just the current step as we no longer move forward first upon doing the save when clicking bac button (ie submit)
    const currIndex = GetActiveWizardStepIndex(appContext.wizardSteps);

    // the number of steps backward is either back to start or the prior item's nextIndex matches the currIndex and is displayed, or the one before that (up to 3 away)
    // upon back btn where user agrees to save changes, causes submit, which saves but no longer moves the index.
    const numberOfIndexStepsToGoBack =
        currIndex <= 0
            ? 1
            : !keepChanges && appContext.wizardSteps[currIndex - 1].nextIndex === currIndex && appContext.wizardSteps[currIndex - 1].isDisplayed === true
            ? 1
            : calculateNumberOfStepsBack(currIndex, appContext.wizardSteps, keepChanges); // 2;

    // setFormStep(cur => cur - 1 or 1);
    // the number of form STEPS to go back is always 1 - as the form step doesn't move only the index - therefore we only ever go backwards from this step to the one prior
    appContext.dispatchWizardState({ type: WizardStateActionTypeEnums.decrementFormStep, numberOfSteps: numberOfIndexStepsToGoBack });

    // if we hit BACK on Employee complaints it will move us to Change of name and then back to Type of mech, so nothing else to do here but move away
    const movingFromEmployeeBackToComplaintsType = currIndex === WizardStepNumber.ComplaintsMechanismEmployee && numberOfIndexStepsToGoBack === 2;
    // if we hit BACK on Membership complaints it will move us to PII and then back to Type of mech, so nothing else to do here but move away
    const movingFromMembershipBackToComplaintsType = currIndex === WizardStepNumber.ComplaintsMechanismMember && numberOfIndexStepsToGoBack === 2;

    if (currIndex > -1 && !movingFromEmployeeBackToComplaintsType && !movingFromMembershipBackToComplaintsType) {
        // NOTE - Forward does NOT kick in any more so no need for this!!:
        // -----------
        // if going backward we only set Flag to completed IF the user has gone in and made a valid change and also got prompted and agreed to save changes AND we are at Personal Details
        const adjustedCurrIndex = currIndex;
        /* no longer moves forward ever
         // ignore some pages as they dont go forward through a submit for adjusted index
        if (keepChanges && currIndex !== WizardStepNumber.ProfessionalIndemnity && currIndex !== WizardStepNumber.DeclarationAndConsent) {
            // if user clicks Back then agrees to Save changes, we would have submitted causing wizard advance an extra step already, take it away here
            if (adjustedCurrIndex > 1) {
                adjustedCurrIndex = adjustedCurrIndex - 1;
            }
        } */

        // mark the index that we saved at before going back as completed, if keepChanges=true
        const goingForward = false;
        MarkThisStepCompleted(goingForward, appContext.wizardSteps[adjustedCurrIndex], isValid, isDirty, keepChanges);

        appContext.wizardSteps[adjustedCurrIndex].currentState = GetStepStateUponCompletion(isValid, appContext.wizardSteps, adjustedCurrIndex, keepChanges);
    }

    if (currIndex > -1) {
        // we MUST decrement the current index (if keepChanges=true then it is the one that has advanced as a result of the save)
        const newWizardSteps = DecrementAndActivatePriorStep(currIndex, appContext.wizardSteps, keepChanges);

        // store changes to Wizard Steps - as we hand them around for mobile/header view
        UpdateWizardStepsAndRepaint(appContext, newWizardSteps);
    }

    // setApplicationForm(applicationForm); // store changes to Wizard Steps
    ScrollToTop();
};

/*
const updateSiblingStepCompleted = (
    appContext: AppContextInterface,
    sibling: WizardStepProps,
    isValid: boolean,
    stepCompleted: boolean,
) => {
    const currIndex = sibling.index;
    appContext.wizardSteps[currIndex].loadedState = sibling.currentState;
    appContext.wizardSteps[currIndex].stepCompleted = stepCompleted;
    appContext.wizardSteps[currIndex].currentState = GetStepStateUponCompletion(isValid, appContext.wizardSteps, currIndex);
}; */

export const MarkStepIncompleteAndUpdateStatus = (appContext: AppContextInterface, isValid: boolean, currIndex: number, errors?: any) => {
    // store original step
    appContext.wizardSteps[currIndex].loadedState = appContext.wizardSteps[currIndex].currentState;

    appContext.wizardSteps[currIndex].stepCompleted = false;
    // show the step (as it could be a child/PII menu item)
    appContext.wizardSteps[currIndex].isDisplayed = true;
    // trigger a refresh of the menu to reflect since isValid=false as well (OR send errors thus set keepChanges to true when that occurs)
    const errorsExist = errors?.inquiry !== undefined && errors?.inquiry !== null;
    if (errorsExist === true) {
        appContext.wizardSteps[currIndex].currentState = GetStepStateUponCompletion(isValid, appContext.wizardSteps, currIndex, true, errors);
    } else {
        appContext.wizardSteps[currIndex].currentState = GetStepStateUponCompletion(isValid, appContext.wizardSteps, currIndex);
    }

    /*
    // if this is a child step, need to mark all the siblings incomplete now too!
    if (isChild(appContext.wizardSteps, currIndex)) {
        const siblings = getAllMySiblings(appContext.wizardSteps, currIndex);
        siblings?.map(sibling => updateSiblingStepCompleted(appContext, sibling, isValid, false));
    }
    */
    UpdateWizardStepsAndRepaint(appContext, appContext.wizardSteps);
};

export const ToggleComplaintsChildMenu = (appContext: AppContextInterface, complaintsMechanismType: ComplaintsMechanismType) => {
    if (complaintsMechanismType !== null) {
        const complaintsEmployee = complaintsMechanismType === ComplaintsMechanismType.GovernmentFundedFDRService;
        const complaintFullMembership = complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc;

        // show/hide child menus
        appContext.wizardSteps[WizardStepNumber.ComplaintsMechanismEmployee].isDisplayed = complaintsEmployee;
        appContext.wizardSteps[WizardStepNumber.ComplaintsMechanismMember].isDisplayed = complaintFullMembership;
        appContext.wizardSteps[WizardStepNumber.ProfessionalIndemnity].isDisplayed = complaintFullMembership;

        // toggle whether this is the last child or not
        appContext.wizardSteps[WizardStepNumber.ComplaintsMechanismEmployee].isLastChild = complaintsEmployee;
        appContext.wizardSteps[WizardStepNumber.ComplaintsMechanismMember].isLastChild = complaintFullMembership;

        // fix up the nextIndex (Membership does not change it will be either not displayed or show and move to next)
        appContext.wizardSteps[WizardStepNumber.ComplaintsMechanismType].nextIndex = complaintsEmployee
            ? WizardStepNumber.ComplaintsMechanismEmployee
            : WizardStepNumber.ComplaintsMechanismMember;
        appContext.wizardSteps[WizardStepNumber.ComplaintsMechanismEmployee].nextIndex = complaintsEmployee
            ? WizardStepNumber.ChangeOfName
            : WizardStepNumber.ComplaintsMechanismMember;

        UpdateWizardStepsAndRepaint(appContext, appContext.wizardSteps);
    }
};

// based on curr/selected value of inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation
export const ToggleEmployeeOrPanelMemberChildMenu = (appContext: AppContextInterface, relationshipToOrganisation?: RelationshipToEmployerType) => {
    if (relationshipToOrganisation !== undefined && relationshipToOrganisation !== null) {
        const includedOnPanel = relationshipToOrganisation === RelationshipToEmployerType.OnPanel;

        // show/hide child menus
        appContext.wizardSteps[WizardStepNumber.ProfessionalIndemnity].isDisplayed = includedOnPanel;

        // fix up the nextIndex (Membership does not change it will be either not displayed or show and move to next)
        appContext.wizardSteps[WizardStepNumber.ComplaintsMechanismEmployee].nextIndex = includedOnPanel
            ? WizardStepNumber.ProfessionalIndemnity
            : WizardStepNumber.ChangeOfName;

        UpdateWizardStepsAndRepaint(appContext, appContext.wizardSteps);
    }
};

export const MarkStepOriginalStatus = (appContext: AppContextInterface, isValid: boolean, currIndex: number) => {
    // stored original state and revert back to that, for now just blank back to initial state
    appContext.wizardSteps[currIndex].currentState = appContext.wizardSteps[currIndex].loadedState ?? WizardStepCurrentStateEnum.initial;

    // trigger a refresh of the menu to reflect since isValid=false as well
    appContext.wizardSteps[currIndex].currentState = GetStepStateUponCompletion(isValid, appContext.wizardSteps, currIndex);

    UpdateWizardStepsAndRepaint(appContext, appContext.wizardSteps);
};

export const RepaintWizard = (appContext: AppContextInterface) => {
    appContext.dispatchWizardState({ type: WizardStateActionTypeEnums.repaint });
};

const MoveBackToHome = (history: any) => {
    // history?.push('/');

    // MUST navigate by clearing STATE otherwise dashboard NOTE for changes will remain (due to the way multiple user is read in)
    window.location.href = '/';
};

const MoveToManageComplaintsHome = (history: any) => {
    // history?.push('/managecomplaintsmechanism?reload=true');
    window.location.href = '/managecomplaintsmechanism?reload=true'; // reload all data (window.location.href removes state)
};

const MoveToReviewAndSubmit = (history: any) => {
    history?.push('/managedetailsreviewandsubmitpage');
};

export const NavigateAway = (appContext: AppContextInterface, history: any, keepChanges: boolean, isDirty: boolean, isValid: boolean) => {
    // pull out the value and clear it
    const destination = appContext?.destinationNavActionOnSuccess;
    appContext?.setDestinationNavActionOnSuccess(null);

    // navigate to the chosen destination
    switch (destination) {
        case NavigationDestination.PreviousWizardStep:
            MoveWizardBackward(appContext, keepChanges, isDirty, isValid);
            break;
        case NavigationDestination.Home:
        case NavigationDestination.Dashboard:
            MoveBackToHome(history);
            break;
        case NavigationDestination.ManageComplaintsMech:
            MoveToManageComplaintsHome(history);
            break;
        case NavigationDestination.ManageDetailsReviewAndSubmitPage:
            MoveToReviewAndSubmit(history);
            break;
        default:
            MoveBackToHome(history);
            break;
    }
};

function GetWizardStepForReworkSection(wizardSteps: WizardStepProps[], reworkSectionToFind: ReworkSectionType): number {
    let idxFound = -1;

    wizardSteps.forEach((wizardStep: WizardStepProps) => {
        if (idxFound === -1 && wizardStep.reworkSectionType) {
            wizardStep.reworkSectionType.forEach((reworkSectionType: ReworkSectionType) => {
                if (idxFound === -1 && reworkSectionType === reworkSectionToFind) {
                    idxFound = wizardStep.index;
                }
            });
        }
    });
    return idxFound;
}

export const MarkReworkSections = (appContext: AppContextInterface, reworkSections: ReworkSectionType[]) => {
    // for each rework section mark it invalid
    const isValid = false;

    reworkSections.forEach((reworkSection: ReworkSectionType) => {
        const wizardStepIndexToInvalidate: number = GetWizardStepForReworkSection(appContext.wizardSteps, reworkSection);
        if (wizardStepIndexToInvalidate > -1) {
            MarkStepIncompleteAndUpdateStatus(appContext, isValid, wizardStepIndexToInvalidate);
        }
    });
};

export const MarkAllSectionsCompleteUpToDeclaration = (appContext: AppContextInterface) => {
    // start by setting all to complete then invalidating just those reworkSections
    const newWizardSteps: WizardStepProps[] = SetAllPriorStepsToComplete(WizardStepNumber.DeclarationAndConsent, appContext.wizardSteps);
    UpdateWizardStepsAndRepaint(appContext, newWizardSteps);
};

export const GetFirstWizardStepForReworkSections = (appContext: AppContextInterface, reworkSections: ReworkSectionType[]): number => {
    let minimalIndex = 99;
    reworkSections.forEach((reworkSection: ReworkSectionType) => {
        const wizardStepIndex: number = GetWizardStepForReworkSection(appContext.wizardSteps, reworkSection);
        if (wizardStepIndex < minimalIndex) {
            minimalIndex = wizardStepIndex;
        }
    });
    return minimalIndex === 99 ? -1 : minimalIndex;
};

export const GetSecondWizardStepForReworkSections = (currIndex: number, appContext: AppContextInterface, reworkSections: ReworkSectionType[]): number => {
    // if only one section then the next one is straight to declaration
    if (reworkSections.length === 1) {
        return WizardStepNumber.DeclarationAndConsent;
    }

    let nextHighestIndex = 99;
    reworkSections.forEach((reworkSection: ReworkSectionType) => {
        const wizardStepIndex: number = GetWizardStepForReworkSection(appContext.wizardSteps, reworkSection);
        if (wizardStepIndex !== currIndex && wizardStepIndex < nextHighestIndex) {
            nextHighestIndex = wizardStepIndex;
        }
    });

    return nextHighestIndex === 99 ? -1 : nextHighestIndex;
};
