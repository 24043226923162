import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import CheckBoxGroupField, { CheckBoxOption } from '../../../components/fields/CheckBoxGroupField';
import InputTimeField from '../../../components/fields/InputTimeField';
import styles from '../../Pages.module.scss';

const { controlSixtyPercentWidth, openingHoursIsOpen, openingHoursDayItem, openingHoursTextItem } = styles;

interface OpeningHoursDayProps {
    displayText: string;
    setValue: any;
    itemStubName: string;
    index: number;
    defaultValueIsOpen: boolean;
    defaultValueOpenTime: Date | null;
    defaultValueCloseTime: Date | null;
    control: any;
    errors: any;
    register: any;
    trigger: any;
    clearTimeForIndex: any;
}

const OpeningHoursDay = (props: OpeningHoursDayProps) => {
    const {
        displayText,
        setValue,
        itemStubName,
        index,
        defaultValueIsOpen,
        defaultValueOpenTime,
        defaultValueCloseTime,
        control,
        errors,
        register,
        trigger,
        clearTimeForIndex,
    } = props;

    const fieldNameIsOpen = `${itemStubName}[${index}].isOpen`;
    const fieldNameOpenTime = `${itemStubName}[${index}].openTime`;
    const fieldNameCloseTime = `${itemStubName}[${index}].closeTime`;

    const initCheckBoxOptionIsOpen: CheckBoxOption[] = [{ label: displayText, value: defaultValueIsOpen, disabled: false, toggleCheckIndex: 0 }];
    const [checkBoxOptionsIsOpen, setCheckBoxOptionsIsOpen] = useState<CheckBoxOption[]>(initCheckBoxOptionIsOpen);
    const [refreshTrueItems, doRefreshTrueItems] = useState(0);
    // const checkBoxIsOpenOptions: CheckBoxOption[] = [{ label: displayText, value: defaultValueIsOpen, disabled: false, toggleCheckIndex: -1 }];

    const onChangeIsOpenCheckbox = (event: { target: { checked: boolean; id: any } }) => {
        // manage the value manually as ref did not work for the react hook form
        const isChecked: boolean = event?.target?.checked;
        setValue(fieldNameIsOpen, isChecked, { shouldDirty: true, shouldValidate: false });

        // if user has de-selected the check box, we need to clear the open time and close time, if not currently empty
        if (isChecked === false) {
            clearTimeForIndex(fieldNameOpenTime);
            clearTimeForIndex(fieldNameCloseTime);
        }
        // always force re-validate on change
        trigger();
    };

    useEffect(() => {
        // if any change and the value is being set, then change it
        if (
            (defaultValueIsOpen === true && checkBoxOptionsIsOpen[0].value === false) ||
            (defaultValueIsOpen === false && checkBoxOptionsIsOpen[0].value === true)
        ) {
            setCheckBoxOptionsIsOpen([{ label: displayText, value: defaultValueIsOpen, disabled: false, toggleCheckIndex: 0, setToTrue: defaultValueIsOpen }]);
            doRefreshTrueItems(prev => prev + 1);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValueIsOpen]);

    // report on any error for openingHours field
    const errorFieldsThisIndex = errors.inquiry?.application?.outletPractitioner?.openingHours?.find((_element: any, i: number) => i === index);
    const errorFieldIsOpen = errorFieldsThisIndex && errorFieldsThisIndex?.isOpen;

    const handleIsOpenChange = (e: any) => {
        onChangeIsOpenCheckbox(e);
    };

    return (
        <Row className={controlSixtyPercentWidth}>
            <Col className={openingHoursIsOpen}>
                <CheckBoxGroupField
                    id={fieldNameIsOpen}
                    displayName=''
                    fieldLabel=''
                    defaultValue={defaultValueIsOpen} // only set default if value exists
                    isMandatory={true}
                    hideFinalEmptyRow={true}
                    options={checkBoxOptionsIsOpen}
                    control={control}
                    register={register}
                    errorsField={errorFieldIsOpen}
                    refreshTrueItems={refreshTrueItems}
                    onChange={handleIsOpenChange}
                />
            </Col>
            <Col className={openingHoursTextItem}>
                <span>from</span>
            </Col>
            <Col className={openingHoursDayItem}>
                <InputTimeField
                    key={fieldNameOpenTime}
                    fieldName={fieldNameOpenTime}
                    defaultValue={defaultValueOpenTime}
                    setValue={setValue}
                    trigger={trigger}
                    errorsField={
                        errors.inquiry?.application?.outletPractitioner?.openingHours &&
                        errors.inquiry?.application?.outletPractitioner?.openingHours[index]?.openTime
                    }
                />
            </Col>
            <Col className={openingHoursTextItem}>
                <span>to</span>
            </Col>
            <Col className={openingHoursDayItem}>
                <InputTimeField
                    key={fieldNameCloseTime}
                    fieldName={fieldNameCloseTime}
                    defaultValue={defaultValueCloseTime}
                    setValue={setValue}
                    trigger={trigger}
                    errorsField={
                        errors.inquiry?.application?.outletPractitioner?.openingHours &&
                        errors.inquiry?.application?.outletPractitioner?.openingHours[index]?.closeTime
                    }
                />
            </Col>
        </Row>
    );
};

export default OpeningHoursDay;
