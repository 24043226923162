import { IDifferences, IComplaintMechanism } from '../../api/interfacesApi';
import * as types from '../../actions/actionTypes';
import { formatDateForDateFns } from '../../utils/Dates';

const differencesInit: IDifferences<IComplaintMechanism> = {
    differences: {},
    originalVersion: {},
    collectionDifferences: {},
};

const differencesComplaintsMechanism = (state = differencesInit, action: types.ApplicationActionTypes) => {
    const SanitiseDiffCM = (differencesObj: IDifferences<IComplaintMechanism>) => {
        // tidy up differences object:
        const diffCM: IComplaintMechanism = differencesObj.differences;
        const evidence: any = differencesObj.collectionDifferences?.evidence;

        if (diffCM.membershipFromDate && (typeof diffCM.membershipFromDate === 'string' || diffCM.membershipFromDate instanceof String)) {
            diffCM.membershipFromDate = formatDateForDateFns(diffCM.membershipFromDate);
        }
        if (diffCM.membershipToDate && (typeof diffCM.membershipToDate === 'string' || diffCM.membershipToDate instanceof String)) {
            diffCM.membershipToDate = formatDateForDateFns(diffCM.membershipToDate);
        }
        if (
            diffCM.professionalIndemnityInsuranceFromDate &&
            (typeof diffCM.professionalIndemnityInsuranceFromDate === 'string' || diffCM.professionalIndemnityInsuranceFromDate instanceof String)
        ) {
            diffCM.professionalIndemnityInsuranceFromDate = formatDateForDateFns(diffCM.professionalIndemnityInsuranceFromDate);
        }
        if (
            diffCM.professionalIndemnityInsuranceToDate &&
            (typeof diffCM.professionalIndemnityInsuranceToDate === 'string' || diffCM.professionalIndemnityInsuranceToDate instanceof String)
        ) {
            diffCM.professionalIndemnityInsuranceToDate = formatDateForDateFns(diffCM.professionalIndemnityInsuranceToDate);
        }
        if (diffCM.modifiedDate && (typeof diffCM.modifiedDate === 'string' || diffCM.modifiedDate instanceof String)) {
            diffCM.modifiedDate = formatDateForDateFns(diffCM.modifiedDate);
        }
        if (diffCM.isEditCopy && typeof diffCM.isEditCopy === 'string') {
            diffCM.isEditCopy =
                diffCM.isEditCopy === 'True' || diffCM.isEditCopy === 'true'
                    ? true
                    : diffCM.isEditCopy === 'False' || diffCM.isEditCopy === 'false'
                    ? false
                    : undefined;
        }
        // capture evidence changes from the collection supplied
        if (evidence !== undefined) {
            diffCM.evidence = evidence;
        }

        differencesObj.differences = diffCM;

        // tidy up originalVersion object:
        const origCM: IComplaintMechanism = differencesObj.originalVersion;

        if (origCM.isEditCopy && typeof origCM.isEditCopy === 'string') {
            origCM.isEditCopy =
                origCM.isEditCopy === 'True' || origCM.isEditCopy === 'true'
                    ? true
                    : origCM.isEditCopy === 'False' || origCM.isEditCopy === 'false'
                    ? false
                    : undefined;
        }
        // if DOB is in format "YYYY-MM-DDTHH:MM:NN" then convert to Date object
        if (origCM.membershipFromDate && (typeof origCM.membershipFromDate === 'string' || origCM.membershipFromDate instanceof String)) {
            origCM.membershipFromDate = formatDateForDateFns(origCM.membershipFromDate);
        }
        if (origCM.membershipToDate && (typeof origCM.membershipToDate === 'string' || origCM.membershipToDate instanceof String)) {
            origCM.membershipToDate = formatDateForDateFns(origCM.membershipToDate);
        }
        if (
            origCM.professionalIndemnityInsuranceFromDate &&
            (typeof origCM.professionalIndemnityInsuranceFromDate === 'string' || origCM.professionalIndemnityInsuranceFromDate instanceof String)
        ) {
            origCM.professionalIndemnityInsuranceFromDate = formatDateForDateFns(origCM.professionalIndemnityInsuranceFromDate);
        }
        if (
            origCM.professionalIndemnityInsuranceToDate &&
            (typeof origCM.professionalIndemnityInsuranceToDate === 'string' || origCM.professionalIndemnityInsuranceToDate instanceof String)
        ) {
            origCM.professionalIndemnityInsuranceToDate = formatDateForDateFns(origCM.professionalIndemnityInsuranceToDate);
        }
        if (origCM.modifiedDate && (typeof origCM.modifiedDate === 'string' || origCM.modifiedDate instanceof String)) {
            origCM.modifiedDate = formatDateForDateFns(origCM.modifiedDate);
        }
        differencesObj.originalVersion = origCM;

        return differencesObj;
    };

    switch (action.type) {
        case types.GET_COMPLAINTSMECHANISM_CHANGES_SUCCESS:
            let retDifferences: IDifferences<IComplaintMechanism> = action.differences;
            retDifferences = SanitiseDiffCM(retDifferences);
            return retDifferences;
        case types.GET_ALL_COMPLAINTSMECHANISM_CHANGES_SUCCESS:
            // tslint:disable-next-line: prefer-const
            let retDifferencesList: IDifferences<IComplaintMechanism>[] = action.differencesList;
            // tslint:disable-next-line: no-shadowed-variable
            retDifferencesList.forEach((retDifferences: IDifferences<IComplaintMechanism>) => {
                retDifferences = SanitiseDiffCM(retDifferences);
            });
            return retDifferencesList;
        default:
            return state;
    }
};

export default differencesComplaintsMechanism;
