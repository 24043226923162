import * as yup from 'yup'; // use all from yup
import moment from 'moment';
import { DATE_FORMAT, DISPLAY_FDDR_PAU_CONTACT_EMAIL, DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER, EMPTY_DATE, UNINITIALISED } from './Constants';
import { IsNumeric } from '../utils/Common';
import { IsGreaterOrEqualToToday, oneHundredAndTenYearsAgo, sixteenYearsAgo } from '../utils/Dates';
import {
    QualificationOrNMASDetailsType,
    QualificationType,
    CompleteWorkingWithChildrenCheckType,
    AttachmentDocumentType,
    RelationshipToEmployerType,
    OrganisationFundingType,
} from './Enums';
import { IApplication, IAttachmentMetadata, IComplaintMechanism } from '../api/interfacesApi';
import Guid from './Guid';

export const validateEmail = (value: string) => {
    let errorMessage;
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        errorMessage = 'Invalid email address';
    }
    return errorMessage;
};

export const validMinLengthForOptionalField = (value: string, minLength: number) => {
    // optional field so this is OK
    if (!value || value === '' || value.length === 0) {
        return true;
    }

    return value.length >= minLength;
};

export const validEmailLocalPart = (value: string) => {
    // optional field so this is OK
    if (!value || value === '') {
        return true;
    }

    // locate first @ to get length of local part (it should be valid email by this test)
    const idxAmpersand = value.indexOf('@');
    if (idxAmpersand === -1) {
        // not found, will be invalidated per another test
        return true;
    }

    return idxAmpersand <= 64;
};

export const validEmailDomanPart = (value: string) => {
    // optional field so this is OK
    if (!value || value === '') {
        return true;
    }

    // locate first @ to get length of local part (it should be valid email by this test)
    const idxAmpersand = value.indexOf('@');
    if (idxAmpersand === -1) {
        // not found, will be invalidated per another test
        return true;
    }

    // domain part cannot contain any underscore chars
    const domain = value.slice(idxAmpersand);
    return domain.indexOf('_') === -1;
};

export const VALIDATION_PROMPT_NAVIGATE_AWAY_TITLE = 'Save changes';
export const VALIDATION_PROMPT_NAVIGATE_AWAY_MESSAGE = 'Do you want to save your changes?';
export const VALIDATION_PROMPT_RESUBMITDISCARDCHANGES_TITLE = 'Discard changes';
export const VALIDATION_PROMPT_RESUBMITDISCARDCHANGES_MESSAGE =
    'All of your data will be removed. You will be returned to the dashboard where you will need to start your updates again. Are you sure?';
export const VALIDATION_PROMPT_CHILDRELATEDPROHIBITED_AWAY_TITLE = 'Contact the FDR Practitioner Accreditation Unit';
export const VALIDATION_PROMPT_CHILDRELATEDPROHIBITED_AWAY_MESSAGE = 'You are not eligible for accreditation as an FDR Practitioner.';
export const VALIDATION_PROMPT_CHILDRELATEDPROHIBITED_AWAY_MESSAGE_P2 = `Contact the FDR Practitioner Accreditation Unit on ${DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER} or by email at ${DISPLAY_FDDR_PAU_CONTACT_EMAIL} if you wish to discuss your exclusion.`;
export const VALIDATION_SUCCESS_POPUP_TITLE = 'Save successful.';
export const VALIDATION_SUCCESS_POPUP_MESSAGE = 'Your changes have been saved.';
export const VALIDATION_DISCARD_OK_POPUP_MESSAGE = 'Your changes have been discarded.';
export const VALIDATION_SUCCESS_FILEUPLOAD_MESSAGE = 'Your file has been uploaded.';
export const VALIDATION_SUCCESS_FILEDELETED_MESSAGE = 'Your file has been removed.';
export const VALIDATION_ERROR_ORGANISATION_ADDED_TITLE = 'Maximum Organisations reached.';
export const VALIDATION_ERROR_ORGANISATION_ADDED_MESSAGE = 'The maximum number of Organisations that may be added is 5.';
export const VALIDATION_SUCCESS_ORGANISATIONS_ADDED_MESSAGE = 'You may now add up to 5 Organisations.';
export const VALIDATION_SUCCESS_ORGANISATION_REMOVED_MESSAGE = 'Your organisation has been removed. You must click Save for changes to be permanent.';
export const VALIDATION_ERROR_POPUP_TITLE = 'There was a problem with your submission.';
export const VALIDATION_ERROR_POPUP_MESSAGE = 'Errors have been highlighted below.  Nothing has been saved.';
export const VALIDATION_WARNING_POPUP_TITLE = 'There was a problem with your submission.';
export const VALIDATION_WARNING_POPUP_MESSAGE = 'Documents must be uploaded before the application can be submitted. Form progress has been saved.';
export const VALIDATION_ERROR_FILEREMOVED_TITLE = 'File removal did not succeed';
export const VALIDATION_ERROR_FILEUPLOAD_TITLE = 'File upload did not succeed';
export const VALIDATION_ERROR_FILETYPENOTSUPPORTED_MESSAGE = 'The file type that you have selected is not supported.  Nothing has been uploaded.';
export const VALIDATION_ERROR_FILETOOLARGE_MESSAGE = 'The file you have selected is too large.  Nothing has been uploaded.';
export const VALIDATION_ERROR_UNKNOWN_TITLE = 'Unknown error';
export const VALIDATION_ERROR_UNKNOWN_MESSAGE =
    'We are sorry there has been an error, which we will investigate.  Please attempt again or refresh your browser to try again.';
export const VALIDATION_ERROR_SUBMIT_TIMEOUT = `Application has been submitted, however there may be an issue.`;

const VALIDATION_FIELDREQUIRED = (fieldName: string) => {
    return `${fieldName} is required.`;
};
export const VALIDATION_FILEUPLOADREQUIRED = (fieldName: string) => {
    return `You must attach evidence for ${fieldName}.`;
};
const VALIDATION_FIELDREQUIRED_PREVIOUSREGISTRATION = 'You must indicate whether or not you have previously applied for accreditation as an FDRP.';
const VALIDATION_FIELDREQUIRED_QUALIFICATIONTYPE = 'You must make a selection of one competency or qualification.';
const VALIDATION_FIELDREQUIRED_QUALIFICATIONORNMAS = 'You must make a selection of one of the supporting evidence types (qualification OR NMAS accreditation).';
const VALIDATION_FIELDREQUIRED_ADDRESSORROOMSARRANGED = `You must make a selection of either 'Rooms to be arranged' or enter Physical address details.`;
const VALIDATION_FIELDREQUIRED_SERVICECOVERAGE_OWNEXPENSE = 'You must make a selection of Travelling costs if you have entered Travel distance.';
const VALIDATION_FIELDREQUIRED_PREMEDIATION_AND_ASSESSMENT = 'You must indicate whether or not you charge for Pre-mediation/intake and assessment.';
const VALIDATION_FIELDREQUIRED_FDR_SESSIONS = 'You must indicate whether or not you charge for FDR sessions.';
const VALIDATION_FIELDREQUIRED_ISSUING_CERTIFICATES = 'You must indicate whether or not you charge for issuing of certificates.';
const VALIDATION_FIELDREQUIRED_ROOM_HIRE_EXPENSES = `You must indicate whether additional charges apply when arranging rooms at the client's request.`;
const VALIDATION_FIELDREQUIRED_OUTSIDE_HOURS = 'You must indicate whether appointments outside normal business hours attract additional costs.';
const VALIDATION_FIELDREQUIRED_FEE_INFO_ON_WEBSITE = 'You must indicate whether details of your costs are published on your business website.';
const VALIDATION_OUTLET_ONEFIELDREQUIRED_CULTURELANGUAGERELIGION =
    'Text for at least one of "Culture group specific", "Language specific" OR "Religion specific or spiritual beliefs" is required.';

const VALIDATION_FIELDLENGTH_EXCEEDED = (fieldName: string, length: number) => {
    return `${fieldName} cannot exceed ${length} characters.`;
};
const VALIDATION_FIELDLENGTH_EXCEEDED_NUMBERS = (fieldName: string, length: number) => {
    return `${fieldName} cannot exceed ${length} numbers.`;
};
const VALIDATION_FIELDLENGTH_EXCEEDED_PHONENUMBER = (fieldName: string, length: number) => {
    return `The ${fieldName} number cannot be longer than ${length} digits. Please enter a landline number of 2 digit area code and an 8 digit phone number or a 10 digit mobile phone number.`;
};
const VALIDATION_FIELDLENGTH_NOTREACHED_PHONENUMBER = (fieldName: string, length: number) => {
    return `The ${fieldName} number cannot be shorter than ${length} digits. Please enter a landline number of 2 digit area code and an 8 digit phone number or a 10 digit mobile phone number.`;
};
const VALIDATION_FIELDLENGTH_NOTREACHED_POSTCODE = (fieldName: string, length: number) => {
    return `The ${fieldName} cannot be shorter than ${length} digits. Please enter a postcode of 4 digits, it is allowed to start with a 0.`;
};
const VALIDATION_FIELDNOTNUMERIC = (fieldName: string) => {
    return `${fieldName} should not contain numbers.`;
};
const VALIDATION_FIELDALPHAONLY = (fieldName: string) => {
    return `${fieldName} should not contain numbers or special characters, including brackets. Please re-enter the ${fieldName} using only letters A-Z, or the space character or apostrophe character.`;
};
const VALIDATION_FIELDNUMERICONLY_PHONENUMBER = (fieldName: string) => {
    return `The ${fieldName} number cannot contain letters or special characters, including brackets. Please re-enter the phone number using only numbers.`;
};
const VALIDATION_FIELDNUMERICSTARTSWITH_TOLLFREENUMBER = (fieldName: string) => {
    return `The ${fieldName} must start with 1300 or 1800 only. Please re-enter the phone number.`;
};
const VALIDATION_FIELDNUMERICONLY_POSTCODE = (fieldName: string) => {
    return `The ${fieldName} cannot contain letters or special characters, including brackets. Please enter a postcode of 4 numbers, it is allowed to start with a 0.`;
};
const VALIDATION_FIELDNUMERICONLY_TRAVELDISTANCE = (fieldName: string) => {
    return `The ${fieldName} cannot contain letters or special characters, including brackets. Please enter a travel distance of up to 4 numbers.`;
};
const VALIDATION_DATE_OF_BIRTH_NOT_IN_FUTURE = () => {
    return 'The Date of birth cannot be in the future. Please check your input and try again.';
};
const VALIDATION_DATE_OF_BIRTH_TOO_OLD = () => {
    return 'The Date of birth cannot be too long ago. Please check your input and try again.';
};
const VALIDATION_DATE_OF_BIRTH_TOO_YOUNG = () => {
    return 'The Date of birth cannot be too recent. Please check your input and try again.';
};
const VALIDATION_DATE_NMAS_EXPIRY_INVALID = () => {
    return 'The date you have entered for NMAS expiry date is not valid. Please enter the date again using the date picker.';
};
const VALIDATION_DATE_NMAS_EXPIRY_REQUIRED = () => {
    return 'You must supply an NMAS expiry date. Please enter the date again using the date picker.';
};
const VALIDATION_DATE_NMAS_EXPIRY_IN_PAST = () => {
    return 'You must have current NMAS accreditation using NMAS accreditation as a competency.';
};
const VALIDATION_EMAIL_INVALID = (fieldName: string) => {
    return `The ${fieldName} address must be in the format name@domain.xxx or name@domain.xxx.au. Please re-enter the email address.`;
};
const VALIDATION_EMAIL_LOCALPART_INVALID = (fieldName: string) => {
    return `The ${fieldName} address must be in the format name@domain.xxx or name@domain.xxx.au (where 'name' part must be less than 64 characters). Please re-enter the email address.`;
};
const VALIDATION_EMAIL_DOMAINPART_INVALID = (fieldName: string) => {
    return `The ${fieldName} address must be in the format name@domain.xxx or name@domain.xxx.au ('domain' part is invalid). Please re-enter the email address.`;
};
/* const VALIDATION_MEMBERSHIP_START_DATE_INVALID = () => {
    return 'The start date you have entered for membership expiry is not valid. Please enter the date again using the date picker.';
}; */
const VALIDATION_MEMBERSHIP_START_DATE_AFTER_END_DATE = () => {
    return 'The start date of your membership cannot be after the end date. Please enter a date that is earlier than the end date.';
};
/* const VALIDATION_MEMBERSHIP_END_DATE_INVALID = () => {
    return 'The end date you have entered for membership expiry is not valid. Please enter the date again using the date picker.';
}; */
const VALIDATION_MEMBERSHIP_END_DATE_BEFORE_START_DATE = () => {
    return 'The end date of your membership cannot be earlier than the start date. Please enter a date that is after the start date.';
};
const VALIDATION_MEMBERSHIP_END_DATE_NOT_IN_PAST = () => {
    return 'The end date of your membership cannot be in the past. Please enter a date in the future.';
};
const VALIDATION_INSURANCE_START_DATE_INVALID = () => {
    return 'The start date you have entered for your policy is not valid. Please enter the date again using the date picker.';
};
const VALIDATION_INSURANCE_START_DATE_AFTER_END_DATE = () => {
    return 'The start date of your policy cannot be after the end date. Please enter a date that is earlier than the end date.';
};
const VALIDATION_INSURANCE_END_DATE_INVALID = () => {
    return 'The end date you have entered for your policy is not valid. Please enter the date again using the date picker.';
};
const VALIDATION_INSURANCE_END_DATE_BEFORE_START_DATE = () => {
    return 'The end date of your policy cannot be earlier than the start date. Please enter a date that is after the start date.';
};
const VALIDATION_INSURANCE_END_DATE_NOT_IN_PAST = () => {
    return 'The end date of your policy cannot be in the past. Please enter a date in the future.';
};

const VALIDATION_OUTLET_WEBADDRESS_INVALID = () => {
    return 'The web address you have entered is not valid. Please enter the web address again but do not include spaces, commas nor semicolons.';
};
const VALIDATION_OUTLET_WEBADDRESS_PROTOCOL_INVALID = () => {
    return `The web address must be in the format http://www.yourwebaddress.com.au or https://www.yourwebaddress.com.au . Please re-enter the web address.`;
};
const VALIDATION_OUTLET_STARTTIME_AFTER_ENDTIME = () => {
    return 'The start time cannot be after the end time.';
};
const VALIDATION_OUTLET_ENDTIME_BEFORE_STARTTIME = () => {
    return 'The end time cannot be earlier than the start time.';
};

export const validationSchemaPersonalDetailsName = yup.object().shape({
    inquiry: yup.object().shape({
        application: yup.object().shape({
            reworkSections: yup
                .array()
                .nullable()
                .of(
                    yup.object().nullable().shape({
                        value: yup.string().trim().nullable().notRequired(),
                    }),
                ),
        }),
        personalDetails: yup.object().shape({
            title: yup.string().trim().nullable().max(50, VALIDATION_FIELDLENGTH_EXCEEDED('Title', 50)),
            firstName: yup
                .string()
                .trim()
                .matches(/^([^0-9]*)$/, VALIDATION_FIELDNOTNUMERIC('First name'))
                .max(150, VALIDATION_FIELDLENGTH_EXCEEDED('First name', 150))
                .required(VALIDATION_FIELDREQUIRED('First name')),
            preferredName: yup
                .string()
                .trim()
                .nullable()
                .matches(/^([^0-9]*)$/, VALIDATION_FIELDNOTNUMERIC('Preferred name'))
                .max(150, VALIDATION_FIELDLENGTH_EXCEEDED('Preferred name', 150)),
            middleName: yup
                .string()
                .trim()
                .nullable()
                .matches(/^([^0-9]*)$/, VALIDATION_FIELDNOTNUMERIC('Middle name'))
                .max(150, VALIDATION_FIELDLENGTH_EXCEEDED('Middle name', 150)),
            familyName: yup
                .string()
                .trim()
                .matches(/^([^0-9]*)$/, VALIDATION_FIELDNOTNUMERIC('Family name'))
                .max(150, VALIDATION_FIELDLENGTH_EXCEEDED('Family name', 150))
                .required(VALIDATION_FIELDREQUIRED('Family name')),
            gender: yup
                .string()
                .trim()
                .nullable()
                .test('not-required-if-rework', VALIDATION_FIELDREQUIRED('Gender'), function (value) {
                    const { from } = this as yup.TestContext & TestContextExtended;

                    // application object unpacked at index 2 (and in inquiry object)
                    const application: IApplication = from && from.length > 2 && from[2]?.value?.inquiry?.application;

                    if (!value && application.reworkSections && application.reworkSections.length > 0) {
                        return true;
                    }

                    return value !== null && value !== '';
                }),
            dateOfBirth: yup
                .date()
                .nullable()
                .required(VALIDATION_FIELDREQUIRED('Date of birth'))
                // .typeError(VALIDATION_DATE_OF_BIRTH_INVALID)
                // .transform((value, rawValue) => {
                //       const correctDate = moment(rawValue, [DATE_FORMAT]).toDate();
                //       return correctDate;
                //   })
                .min(oneHundredAndTenYearsAgo(), VALIDATION_DATE_OF_BIRTH_TOO_OLD)
                //   .transform((value, rawValue) => {
                //       const correctDate = moment(rawValue, [DATE_FORMAT]).toDate();
                //       return correctDate;
                //   })
                .test(
                    'date-not-in-future',
                    VALIDATION_DATE_OF_BIRTH_NOT_IN_FUTURE,
                    // tslint:disable-next-line: only-arrow-functions
                    function (value) {
                        if (!value) {
                            return true;
                        }
                        return value! < new Date();
                    },
                )
                .test(
                    'date-not-within-recent-years',
                    VALIDATION_DATE_OF_BIRTH_TOO_YOUNG,
                    // tslint:disable-next-line: only-arrow-functions
                    function (value) {
                        if (!value) {
                            return true;
                        }
                        // const myValue = value!.setHours(0, 0, 0, 0);
                        return !(value >= sixteenYearsAgo() && value! < new Date());
                    },
                ),
        }),
    }),
});

/*
const validationSchemaStreetAddressField = yup.object().shape({
    streetAddress: yup
        .string()
        .trim()
        .nullable()
        .max(150, VALIDATION_FIELDLENGTH_EXCEEDED('Street address', 150))
        .required(VALIDATION_FIELDREQUIRED('Street address')),
});

const validationSchemaTownField = yup.object().shape({
    town: yup.string().trim().nullable().max(100, VALIDATION_FIELDLENGTH_EXCEEDED('Suburb', 100)).required(VALIDATION_FIELDREQUIRED('Suburb')),
});

const validationSchemaStateField = yup.object().shape({
    state: yup.string().trim().nullable().max(100, VALIDATION_FIELDLENGTH_EXCEEDED('State', 100)).required(VALIDATION_FIELDREQUIRED('State')),
});

const validationSchemaPostcodeField = yup.object().shape({
    postcode: yup
        .string()
        .trim()
        .nullable()
        .matches(/^\d+$/, VALIDATION_FIELDNUMERICONLY('Postcode'))
        .max(4, VALIDATION_FIELDLENGTH_EXCEEDED('Postcode', 4))
        .required(VALIDATION_FIELDREQUIRED('Postcode')),
}); */

/*
const anyAddressValueExists = (addressFields: any) => {
    return (
        (addressFields.streetAddress !== null && addressFields.streetAddress.trim() !== '') ||
        (addressFields.town !== null && addressFields.town.trim() !== '') ||
        (addressFields.state !== null && addressFields.state.trim() !== '') ||
        (addressFields.postcode !== null && addressFields.postcode.trim() !== '')
    );
};

const isPostalSameAndCanBeIgnored = (from: { value: { isPostalSameAsResidential: boolean } }[], currItem: any) => {
    // overall setting
    const isCheckedPostalSameAsResidential: boolean = from[1]?.value?.isPostalSameAsResidential;
    // is Postal and check box marked then we have already validated this
    return currItem && currItem.addressType === AddressTypeEnum.Postal && isCheckedPostalSameAsResidential;
};

const isPostalAllEmptyAndCanBeIgnored = (currItem: any) => {
    // is Postal and check box marked then we have already validated this
    return (
        currItem &&
        currItem.addressType === AddressTypeEnum.Postal &&
        currItem.streetAddress.trim() === '' &&
        currItem.postcode.trim() === '' &&
        currItem.state.trim() === '' &&
        currItem.town.trim() === ''
    );
}; 

const validateBusinessAddressField = async (fieldName: string, from: { value: { addresses: any[] } }[], currItem: any, createError: any) => {
    const businessAddressFields = from[1]?.value.addresses && from[1]?.value.addresses[0];
    if (anyAddressValueExists(businessAddressFields)) {
        try {
            switch (fieldName) {
                case 'streetaddress':
                    await validationSchemaStreetAddressField.validate(currItem);
                    break;
                case 'postcode':
                    await validationSchemaPostcodeField.validate(currItem);
                    break;
                case 'state':
                    await validationSchemaStateField.validate(currItem);
                    break;
                case 'town':
                    await validationSchemaTownField.validate(currItem);
                    break;
                default:
                    await validationSchemaStreetAddressField.validate(currItem);
                    break;
            }
        } catch (e) {
            // replace path to set error properly
            const errorMsg: string = e.message === undefined ? '' : e.message;
            throw createError({
                message: errorMsg,
            });
        }
    }
}; */

/* const validatePostalAddressField = async (fieldName: string, from: { value: { addresses: any[] } }[], currItem: any, createError: any) => {
    // const postalAddressFields = from[1]?.value.addresses && from[1]?.value.addresses[1];
    // if (anyAddressValueExists(postalAddressFields)) {
    try {
        switch (fieldName) {
            case 'streetaddress':
                await validationSchemaStreetAddressField.validate(currItem);
                break;
            case 'postcode':
                await validationSchemaPostcodeField.validate(currItem);
                break;
            case 'state':
                await validationSchemaStateField.validate(currItem);
                break;
            case 'town':
                await validationSchemaTownField.validate(currItem);
                break;
            default:
                await validationSchemaStreetAddressField.validate(currItem);
                break;
        }
    } catch (e) {
        // replace path to set error properly
        const errorMsg: string = e.message === undefined ? '' : e.message;
        return createError({
            message: errorMsg,
        });
    }
    // }
}; */

// see: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/49512
interface TestContextExtended {
    from: {
        schema: any;
        value: any;
    }[];
}

// see: https://stackoverflow.com/questions/67174913/yupschema-how-validate-only-first-element-of-array-in-yupschema/69263458#69263458
/* interface ValidateOptionsExtended {
    options: {
        index: number;
    };
} */

export const validationSchemaPersonalDetailsContact = yup.object().shape({
    inquiry: yup.object().shape({
        application: yup.object().shape({
            reworkSections: yup
                .array()
                .nullable()
                .of(
                    yup.object().nullable().shape({
                        value: yup.string().trim().nullable().notRequired(),
                    }),
                ),
        }),
        personalDetails: yup.object().shape(
            {
                addresses: yup.array().of(
                    yup.object().shape({
                        streetAddress: yup
                            .string()
                            .trim()
                            .nullable()
                            .max(150, VALIDATION_FIELDLENGTH_EXCEEDED('Street address', 150))
                            .required(VALIDATION_FIELDREQUIRED('Street address')),
                        postcode: yup
                            .string()
                            .trim()
                            .nullable()
                            // .min(4, VALIDATION_FIELDLENGTH_NOTREACHED_POSTCODE('Postcode', 4)) // /^\d{4}$/
                            .max(4, VALIDATION_FIELDLENGTH_EXCEEDED_NUMBERS('Postcode', 4))
                            .required(VALIDATION_FIELDREQUIRED('Postcode'))
                            .matches(/^\d+$/, VALIDATION_FIELDNUMERICONLY_POSTCODE('Postcode'))
                            .matches(/^\d{4}$/, VALIDATION_FIELDLENGTH_NOTREACHED_POSTCODE('Postcode', 4)),
                        town: yup
                            .string()
                            .trim()
                            .nullable()
                            .max(100, VALIDATION_FIELDLENGTH_EXCEEDED('Suburb', 100))
                            .required(VALIDATION_FIELDREQUIRED('Suburb'))
                            .matches(/^([A-Za-z ']*)$/, VALIDATION_FIELDALPHAONLY('Suburb')), // NOTE: Allow space character
                        state: yup
                            .string()
                            .trim()
                            .nullable()
                            .max(100, VALIDATION_FIELDLENGTH_EXCEEDED('State', 100))
                            .test('not-required-if-rework', VALIDATION_FIELDREQUIRED('State'), function (value) {
                                const { from } = this as yup.TestContext & TestContextExtended;

                                // application object unpacked at index 2
                                const application: IApplication = from && from.length > 2 && from[2]?.value?.application;

                                if (!value && application.reworkSections && application.reworkSections.length > 0) {
                                    return true;
                                }

                                return value !== null && value !== '';
                            }),
                        // .required(VALIDATION_FIELDREQUIRED('State')),
                        longitude: yup.number().notRequired(),
                        latitude: yup.number().notRequired(),
                    }),
                ),
                emailAddress: yup
                    .string()
                    .trim()
                    .nullable(true)
                    .required(VALIDATION_FIELDREQUIRED('Primary email'))
                    .max(255, VALIDATION_FIELDLENGTH_EXCEEDED('Primary email', 255)),
                secondaryEmailAddress: yup
                    .string()
                    .trim()
                    .nullable(true)
                    .max(255, VALIDATION_FIELDLENGTH_EXCEEDED('Secondary email', 255))
                    .email(VALIDATION_EMAIL_INVALID('Secondary email'))
                    .test('check-length-of-localpart', VALIDATION_EMAIL_LOCALPART_INVALID('Secondary email'), value => validEmailLocalPart(value!))
                    .test('check-domain-valid', VALIDATION_EMAIL_DOMAINPART_INVALID('Secondary email'), value => validEmailDomanPart(value!)),
                primaryPhoneNumber: yup
                    .string()
                    .trim()
                    .nullable()
                    .max(10, VALIDATION_FIELDLENGTH_EXCEEDED_PHONENUMBER('Primary phone', 10))
                    .required(VALIDATION_FIELDREQUIRED('Primary phone'))
                    .min(10, VALIDATION_FIELDLENGTH_NOTREACHED_PHONENUMBER('Primary phone', 10))
                    .matches(/^\d+$/, VALIDATION_FIELDNUMERICONLY_PHONENUMBER('Primary phone')),
                secondaryPhoneNumber: yup
                    .string()
                    .trim()
                    .nullable(true)
                    .notRequired()
                    .when('secondaryPhoneNumber', {
                        is: (value: string | any[]) => value?.length > 0,
                        then: rule => rule.matches(/^\d+$/, VALIDATION_FIELDNUMERICONLY_PHONENUMBER('Secondary phone')),
                    })
                    .max(10, VALIDATION_FIELDLENGTH_EXCEEDED_PHONENUMBER('Secondary phone', 10))
                    .test('checkminlengthifexists', VALIDATION_FIELDLENGTH_NOTREACHED_PHONENUMBER('Secondary phone', 10), value =>
                        validMinLengthForOptionalField(value!, 10),
                    ),
            },
            [
                // Add Cyclic deps here because when require itself
                ['secondaryPhoneNumber', 'secondaryPhoneNumber'],
            ],
        ),
    }),
});

export const validationSchemaPersonalDetails = validationSchemaPersonalDetailsName.concat(validationSchemaPersonalDetailsContact);

const attachmentOfTypeExists = (attachmentsIn: any, attachmentDocumentType: AttachmentDocumentType, complaintMechanismId?: string) => {
    if (attachmentsIn) {
        const guid = new Guid();
        // if at least one exists for this type then it is OK
        const attachments = attachmentsIn as IAttachmentMetadata[];
        let isValid = false;

        // tslint:disable-next-line: no-unused-expression
        attachments &&
            !(typeof attachments === 'string' || attachments instanceof String) &&
            attachments.forEach(item => {
                if (
                    item.documentType !== undefined &&
                    item.documentType.toString() === attachmentDocumentType.toString() &&
                    item.applicationId !== UNINITIALISED
                ) {
                    // very deliberate checks if the CM id is supplied
                    if (complaintMechanismId) {
                        // could be a new item just added (record not saved) OR exists already and is loaded
                        if (
                            complaintMechanismId === guid.empty ||
                            item.complaintsProcessId === guid.empty ||
                            complaintMechanismId === item.complaintsProcessId
                        ) {
                            isValid = true; // found one! comparing CM id
                        }
                    } else {
                        isValid = true; // found one! not comparing CM id
                    }
                }
            });

        return isValid;
    } else {
        return false;
    }
};

export const validationSchemaPastApplicationHistory = yup.object().shape({
    inquiry: yup.object().shape({
        application: yup.object().shape({
            hasPreviouslyApplied: yup.boolean().nullable().required(VALIDATION_FIELDREQUIRED_PREVIOUSREGISTRATION),
            previousApplicationIdentifier: yup
                .string()
                .nullable()
                .trim()
                .max(8, VALIDATION_FIELDLENGTH_EXCEEDED('Previous registration or submission number', 8)),
        }),
    }),
});

export const validationSchemaCompetenciesAndQualifications = yup.object().shape({
    inquiry: yup.object().shape({
        application: yup.object().shape(
            {
                qualification: yup.string().nullable().required(VALIDATION_FIELDREQUIRED_QUALIFICATIONTYPE),
                qualificationOrNMASDetails: yup
                    .string()
                    .nullable()
                    .when('qualification', {
                        is: QualificationType.QualificationOrNMAS,
                        then: yup.string().nullable().required(VALIDATION_FIELDREQUIRED_QUALIFICATIONORNMAS),
                    }),
                nmasExpiryDate: yup
                    .date()
                    .nullable()
                    .typeError(VALIDATION_DATE_NMAS_EXPIRY_INVALID)
                    .when(['qualification', 'qualificationOrNMASDetails'], {
                        // tslint:disable-next-line: ban-comma-operator
                        is: (qualification: QualificationType, qualificationOrNMASDetails: QualificationOrNMASDetailsType) =>
                            qualification === QualificationType.QualificationOrNMAS &&
                            qualificationOrNMASDetails === QualificationOrNMASDetailsType.CurrentNMASAccreditation,
                        then: yup.date().nullable().required(VALIDATION_DATE_NMAS_EXPIRY_REQUIRED),
                    })
                    .test('date-not-in-past', VALIDATION_DATE_NMAS_EXPIRY_IN_PAST, function (value) {
                        const { from } = this as yup.TestContext & TestContextExtended;

                        // application object unpacked at index 2
                        const application: IApplication = from && from.length > 2 && from[2]?.value?.inquiry?.application;

                        if (
                            !value ||
                            value === EMPTY_DATE ||
                            application.qualification !== QualificationType.QualificationOrNMAS ||
                            application.qualificationOrNMASDetails !== QualificationOrNMASDetailsType.CurrentNMASAccreditation
                        ) {
                            return true;
                        }

                        return IsGreaterOrEqualToToday(value);
                    }),
                attachments: yup
                    .array()
                    .nullable()
                    .when('qualification', {
                        is: QualificationType.VocationalGradDipOrEquivalent,
                        then: yup
                            .array()
                            .nullable()
                            .test({
                                name: 'fileOfDocumentTypeExists',
                                // this suffix MUST match competencies page component
                                message: VALIDATION_FILEUPLOADREQUIRED('full Graduate Diploma (or equivalent)'),
                                async test(values: any) {
                                    return attachmentOfTypeExists(values, AttachmentDocumentType.FullGraduateDiplomaOfFDR);
                                },
                            }),
                    })
                    .when('qualification', {
                        is: QualificationType.QualificationOrNMAS,
                        then: yup
                            .array()
                            .nullable()
                            .test({
                                name: 'fileOfDocumentTypeExists',
                                // this suffix MUST match competencies page component
                                message: VALIDATION_FILEUPLOADREQUIRED('six core units (or equivalent)'),
                                async test(values: any) {
                                    return attachmentOfTypeExists(values, AttachmentDocumentType.SixUnits);
                                },
                            }),
                    })
                    .when('qualification', {
                        is: QualificationType.ThreeSpecifiedUnits,
                        then: yup
                            .array()
                            .nullable()
                            .test({
                                name: 'fileOfDocumentTypeExists',
                                // this suffix MUST match competencies page component
                                message: VALIDATION_FILEUPLOADREQUIRED('three specified units'),
                                async test(values: any) {
                                    return attachmentOfTypeExists(values, AttachmentDocumentType.ThreeUnits);
                                },
                            }),
                    }),
                attachmentsAppropQual: yup
                    .array()
                    .nullable()
                    .when('qualificationOrNMASDetails', {
                        is: QualificationOrNMASDetailsType.AppropriateQualification,
                        then: yup
                            .array()
                            .nullable()
                            .test({
                                name: 'fileOfDocumentTypeExists',
                                // this suffix MUST match competencies page component
                                message: VALIDATION_FILEUPLOADREQUIRED('appropriate qualification'),
                                async test(values: any) {
                                    const { from } = this as yup.TestContext & TestContextExtended;
                                    // application object unpacked at index 2
                                    const attachments: IAttachmentMetadata[] = from && from.length > 2 && from[2]?.value?.inquiry?.application?.attachments;
                                    return attachmentOfTypeExists(attachments, AttachmentDocumentType.SixUnitsAndQualification);
                                },
                            }),
                    }),
                attachmentsNmas: yup
                    .array()
                    .nullable()
                    .when('qualificationOrNMASDetails', {
                        is: QualificationOrNMASDetailsType.CurrentNMASAccreditation,
                        then: yup
                            .array()
                            .nullable()
                            .test({
                                name: 'fileOfDocumentTypeExists',
                                // this suffix MUST match competencies page component
                                message: VALIDATION_FILEUPLOADREQUIRED('NMAS'),
                                async test(values: any) {
                                    const { from } = this as yup.TestContext & TestContextExtended;
                                    // application object unpacked at index 2
                                    const attachments: IAttachmentMetadata[] = from && from.length > 2 && from[2]?.value?.inquiry?.application?.attachments;
                                    return attachmentOfTypeExists(attachments, AttachmentDocumentType.SixUnitsAndNMAS);
                                },
                            }),
                    }),
            },
            [['qualification', 'qualificationOrNMASDetails']],
        ),
    }),
});

export const validationSchemaCriminalConvictions = yup.object().shape({
    inquiry: yup.object().shape({
        application: yup.object().shape({
            hasOffenceConvictions: yup.boolean().nullable().required(VALIDATION_FIELDREQUIRED('Previous offences')),
            attachments: yup
                .array()
                .nullable()
                .test({
                    name: 'fileOfDocumentTypeExists',
                    // // this suffix MUST match criminal page component
                    message: VALIDATION_FILEUPLOADREQUIRED('National Police Check'),
                    async test(values: any) {
                        return attachmentOfTypeExists(values, AttachmentDocumentType.NationalPoliceCheck);
                    },
                }),
        }),
    }),
});

export const validationSchemaChildRelatedEmployment = yup.object().shape({
    inquiry: yup.object().shape({
        application: yup.object().shape({
            isProhibitedFromWorkingWithChildren: yup
                .boolean()
                .nullable()
                .required(VALIDATION_FIELDREQUIRED("A response to 'Are you prohibited under a law of a State or Territory from working with children'")),
            completeWorkingWithChildrenCheck: yup
                .string()
                .nullable()
                .required(
                    VALIDATION_FIELDREQUIRED(
                        "A response to 'For the type of FDR service you are offering, are you required to complete a child-related employment check?'",
                    ),
                ),
            isWorkingWithChildrenCheckNotApplicable: yup
                .boolean()
                .nullable()
                .when('completeWorkingWithChildrenCheck', {
                    is: CompleteWorkingWithChildrenCheckType.NotRequired,
                    then: yup
                        .boolean()
                        .nullable()
                        .required(VALIDATION_FIELDREQUIRED("A response to 'Reason for not providing a child-related employment check'"))
                        .oneOf([true], VALIDATION_FIELDREQUIRED("A response to 'Reason for not providing a child-related employment check'")),
                }),
            attachments: yup
                .array()
                .nullable()
                .when('completeWorkingWithChildrenCheck', {
                    is: CompleteWorkingWithChildrenCheckType.Required,
                    then: yup
                        .array()
                        .nullable()
                        .test({
                            name: 'fileOfDocumentTypeExists',
                            // // this suffix MUST match child-related page component
                            message: VALIDATION_FILEUPLOADREQUIRED('Working With Children'),
                            async test(values: any) {
                                return attachmentOfTypeExists(values, AttachmentDocumentType.ChildRelatedEmploymentCheck);
                            },
                        }),
                }),
        }),
    }),
});

export const validationSchemaComplaintsMechanismType = yup.object().shape({
    inquiry: yup.object().shape({
        application: yup.object().shape({
            activeComplaintsMechanismObj: yup.object().shape({
                complaintsMechanismType: yup.string().nullable().required(VALIDATION_FIELDREQUIRED('Type of complaints mechanism')),
            }),
        }),
    }),
});

export const validationSchemaComplaintsMechanismOutletType = yup.object().shape({
    inquiry: yup.object().shape({
        application: yup.object().shape({
            activeComplaintsMechanismObj: yup.object().shape({
                complaintsMechanismType: yup.string().nullable().required(VALIDATION_FIELDREQUIRED('Outlet type')),
            }),
        }),
    }),
});

/*
const noDuplicateOutlets = (complaintsMechanisms: IComplaintMechanism[], item: any) => {
    if (!complaintsMechanisms || complaintsMechanisms.length <= 1 || item === undefined || item === null) return true;

    const itemIdx = complaintsMechanisms.findIndex(c => c.outlet?.outletId === item);
    const itemOutletId = item.toLowerCase();
    const outletIds = complaintsMechanisms.map((complaintMechanism: IComplaintMechanism) => complaintMechanism.outlet?.outletId.toLowerCase());

    return outletIds.filter((outletId: any, idx: any) => idx !== itemIdx && outletId === itemOutletId).length === 0;
}; */

const isValidOutletId = (outletId: string | null | undefined) => {
    // if (outletId === undefined || outletId === null || outletId === '' || outletId.startsWith(UNINITIALISED)) {
    if (outletId === '' || outletId === null || outletId?.startsWith(UNINITIALISED)) {
        return false;
    } else {
        return true;
    }
};

const outletExists = (itemToCheck: IComplaintMechanism) => {
    if (itemToCheck && itemToCheck.outlet && itemToCheck.outlet !== null) {
        return isValidOutletId(itemToCheck.outlet!.outletId);
    }
    return false; // could not get at object or empty
};
/*
const outletExistsAtThisIndex = (complaintsMechanisms: IComplaintMechanism[], index: number) => {
    if (complaintsMechanisms && complaintsMechanisms.length > 0) {
        const itemToCheck = complaintsMechanisms[index];
        return outletExists(itemToCheck);
    }
    return false; // could not get at object or empty
}; */

/* const isValidAttachmentId = (attachmentId: string | null | undefined) => {
    if (
        attachmentId === undefined ||
        attachmentId === null ||
        attachmentId === '' ||
        attachmentId === '00000000-0000-0000-0000-000000000000' ||
        attachmentId === 'inquiry.application.activeComplaintsMechanismObj.activeAttachmentId' ||
        attachmentId.startsWith(UNINITIALISED)
    ) {
        return false;
    } else {
        return true;
    }
}; */

/*
const attachmentExists = (itemToCheck: IComplaintMechanism) => {
    if (itemToCheck) {
        return isValidAttachmentId(itemToCheck.activeAttachmentId);
    }
    return false; // could not get at object or empty
}; */

/* const attachmentExistsAtThisIndex = (complaintsMechanisms: IComplaintMechanism[], index: number) => {
    if (complaintsMechanisms && complaintsMechanisms.length > 0) {
        const itemToCheck = complaintsMechanisms[index];
        return attachmentExists(itemToCheck);
    }
    return false; // could not get at object or empty
}; */

const isValidRelationshipToEmployer = (relationshipToOrganisation: RelationshipToEmployerType | null | undefined) => {
    if (relationshipToOrganisation === null) {
        return false;
    } else {
        return true; // undefined is OK as this is conditional mandatory
    }
};

const relationshipToEmployerExistsForThisComplaint = (itemToCheck: IComplaintMechanism) => {
    if (itemToCheck) {
        return isValidRelationshipToEmployer(itemToCheck.relationshipToOrganisation);
    }
    return false; // could not get at object or empty
};

/*
const relationshipToEmployerExistsAtThisIndex = (complaintsMechanisms: IComplaintMechanism[], index: number) => {
    if (complaintsMechanisms && complaintsMechanisms.length > 0) {
        const itemToCheck = complaintsMechanisms[index];
        return relationshipToEmployerExists(itemToCheck);
    }
    return false; // could not get at object or empty
};
 
const doesRelationshipToEmployerNeedToExistAtThisIndex = (complaintsMechanisms: IComplaintMechanism[], index: number) => {
    if (complaintsMechanisms && complaintsMechanisms.length > 0) {
        const itemToCheck = complaintsMechanisms[index];
        const isLegaAidOrOther =
            itemToCheck.outlet?.organisationFundingType === OrganisationFundingType.LegalAid ||
            itemToCheck.outlet?.organisationFundingType === OrganisationFundingType.Other;
        return isLegaAidOrOther;
    }
    return true; // could not get at object or empty, MUST return TRUE to trip error (TODO: needs better design!)
}; */

const doesRelationshipToEmpoyerNeedToExistForThisComplaint = (complaintsMechanism: IComplaintMechanism) => {
    const isLegaAidOrOther =
        complaintsMechanism.outlet?.organisationFundingType === OrganisationFundingType.LegalAid ||
        complaintsMechanism.outlet?.organisationFundingType === OrganisationFundingType.Other;
    return isLegaAidOrOther;
};

// used by both CM edit forms
export const validationSchemaComplaintsMechanismEmployee = yup.object().shape({
    inquiry: yup.object().shape({
        application: yup.object().shape({
            activeComplaintsMechanismObj: yup
                .object()
                .nullable()
                .shape(
                    {
                        outlet: yup
                            .object()
                            .nullable()
                            .shape({
                                outletId: yup
                                    .string()
                                    .trim()
                                    .nullable()
                                    .test('firstOutletAlwaysMandatory', VALIDATION_FIELDREQUIRED('Organisation name'), function (item) {
                                        const { from } = this as yup.TestContext & TestContextExtended;
                                        /*
                                        const isAccredited = from && from.length > 2 && from[2]?.value.accreditationStatus === 'Accredited';

                                        // either check accredited OR only check first item at this stage as it is not yet submitted application (this validation re-used)
                                        // if (options.index > 0) {
                                        if (isAccredited === false) {
                                            //  && (indexToCheck === undefined || indexToCheck > 0)) {
                                            return true;
                                        } */

                                        // application object unpacked at index 2
                                        // const complaintsMechanisms: IComplaintMechanism[] = from && from.length > 2 && from[2]?.value.complaintsMechanism;
                                        const activeCM: IComplaintMechanism = from && from.length > 2 && from[2]?.value.activeComplaintsMechanismObj;
                                        const outletExistsHere = activeCM && outletExists(activeCM); // options.index);

                                        return outletExistsHere;
                                    }),
                                /* outlet always mandatory
                                        .test('whenAttachmentIdExistsOutletMust', VALIDATION_FIELDREQUIRED('Organisation name'), function (item) {
                                            const { from } = this as yup.TestContext & TestContextExtended;
                                            const { options } = this as yup.TestContext & ValidateOptionsExtended;
                                            // application object unpacked at index 2
                                            const complaintsMechanisms = from && from.length > 2 && from[2]?.value.complaintsMechanism;

                                            if (attachmentExistsAtThisIndex(complaintsMechanisms, options.index)) {
                                                return isValidOutletId(item);
                                            }
                                            // no attachment exists so no need to check outlet
                                            return true;
                                        }), */
                            }),
                        relationshipToOrganisation: yup
                            .string()
                            .trim()
                            .nullable()
                            /* the when function is tripped for array, not used any more
                                .when('relationshipToOrganisation', {
                                    is: (value: any | undefined) => value !== undefined,
                                    then: yup.string().trim().nullable().required(VALIDATION_FIELDREQUIRED('Relationship to organisation')),
                                    otherwise: yup.string().notRequired(),
                                }), */
                            // the below trips incorrectly sets to null/or-trips when user goes back a few times to this page
                            .test('firstRelationshipToEmployerConditionalMandatory', VALIDATION_FIELDREQUIRED('Relationship to organisation'), function (item) {
                                const { from } = this as yup.TestContext & TestContextExtended;

                                // const indexToCheck = from && from.length > 2 && from[2]?.value.application?.activeComplaintsMechanismIndex;
                                /*
                                const isAccredited = from && from.length > 2 && from[2]?.value.application?.accreditationStatus === 'Accredited';

                                // either check accredited OR only check first item at this stage as it is not yet submitted application (this validation re-used)
                                // if (options.index > 0) {
                                if (isAccredited === false) {
                                    //  && (indexToCheck === undefined || indexToCheck > 0)) {
                                    return true;
                                } */

                                // application object unpacked at index 1
                                // const complaintsMechanisms: IComplaintMechanism[] = from && from.length > 1 && from[1]?.value.complaintsMechanism;
                                const activeCM: IComplaintMechanism = from && from.length > 2 && from[2]?.value.application.activeComplaintsMechanismObj;
                                if (activeCM && doesRelationshipToEmpoyerNeedToExistForThisComplaint(activeCM)) {
                                    return relationshipToEmployerExistsForThisComplaint(activeCM);
                                }
                                /*
                                if (doesRelationshipToEmployerNeedToExistAtThisIndex(complaintsMechanisms, indexToCheck)) {
                                    return relationshipToEmployerExistsAtThisIndex(complaintsMechanisms, indexToCheck);
                                } */

                                // return TRUE as relationship only exists where the Outlet selected is Legal Aid/Other
                                return true;
                            }),
                        /* activeAttachmentId: yup
                            .string()
                            .trim()
                            .nullable()
                            .test('firstAttachmentAlwaysMandatory', VALIDATION_FILEUPLOADREQUIRED('organisation'), function (item) {
                                const { from } = this as yup.TestContext & TestContextExtended;
                                // no more array, it is stored in active obj now
                                // const { options } = this as yup.TestContext & ValidateOptionsExtended;
                                const indexToCheck = from && from.length > 2 && from[2]?.value.application?.activeComplaintsMechanismIndex;
                                const isAccredited = from && from.length > 2 && from[2]?.value.application?.accreditationStatus === 'Accredited';

                                // either check accredited OR only check first item at this stage as it is not yet submitted application (this validation re-used)
                                // if (options.index > 0) {
                                if (isAccredited === false && (indexToCheck === undefined || indexToCheck > 0)) {
                                    return true;
                                }

                                // application object unpacked at index 1
                                // const complaintsMechanisms: IComplaintMechanism[] = from && from.length > 1 && from[1]?.value.complaintsMechanism;
                                const activeCM: IComplaintMechanism = from && from.length > 2 && from[2]?.value.application.activeComplaintsMechanismObj;
                                return attachmentExists(activeCM);
                            }), */
                    },
                    [
                        ['outlet.outletId', 'activeAttachmentId'],
                        ['relationshipToOrganisation', 'relationshipToOrganisation'],
                    ],
                ),
            // evidence of organisation
            attachments: yup
                .array()
                .nullable()
                .test('isCMOrganisation-mandatory-attachment', VALIDATION_FILEUPLOADREQUIRED('organisation'), function (values: any) {
                    // const { CMInsuranceDocumentAttachmentIsMandatory } = this.parent;
                    const { from } = this as yup.TestContext & TestContextExtended;
                    let foundActiveCM = false;
                    let activeCM: IComplaintMechanism = from && from.length > 2 && from[2]?.value.inquiry.application.activeComplaintsMechanismObj;
                    if (activeCM === undefined || activeCM.complaintsMechanismId === Guid.empty) {
                        activeCM = this.parent.activeComplaintsMechanismObj;
                    }
                    foundActiveCM = activeCM !== undefined && activeCM.complaintsMechanismId !== Guid.empty;

                    // if (CMInsuranceDocumentAttachmentIsMandatory === true) {
                    if (foundActiveCM === true) {
                        if (activeCM.relationshipToOrganisation === RelationshipToEmployerType.Employee) {
                            return attachmentOfTypeExists(values, AttachmentDocumentType.OrgEmployment, activeCM.complaintsMechanismId);
                        } else {
                            if (activeCM.relationshipToOrganisation === RelationshipToEmployerType.OnPanel) {
                                return attachmentOfTypeExists(values, AttachmentDocumentType.OrgPanel, activeCM.complaintsMechanismId);
                            } else {
                                // potentially null as user might upload file before selection of relationship
                                return (
                                    attachmentOfTypeExists(values, AttachmentDocumentType.OrgEmployment, activeCM.complaintsMechanismId) ||
                                    attachmentOfTypeExists(values, AttachmentDocumentType.OrgPanel, activeCM.complaintsMechanismId)
                                );
                            }
                        }
                    }
                    return false; // not active
                    // }
                    // return true;
                }),
        }),
    }),
});

export const validationSchemaComplaintsMechanismMembership = yup.object().shape({
    inquiry: yup.object().shape({
        application: yup.object().shape({
            activeComplaintsMechanismObj: yup
                .object()
                .nullable()
                .shape(
                    {
                        professionalAssociation: yup
                            .object()
                            .nullable()
                            .shape({
                                professionalAssociationId: yup
                                    .string()
                                    .nullable()
                                    // .required(VALIDATION_FIELDREQUIRED('A professional association name'))
                                    // tslint:disable-next-line: only-arrow-functions
                                    .test('profassoc-required', VALIDATION_FIELDREQUIRED('A professional association name'), function (value) {
                                        // if (!value || value === undefined) {
                                        if (value === null || value === '') {
                                            return false;
                                        }
                                        return true;
                                    }),
                            }),
                        // set in UI now andmarked read only:
                        // professionalMembershipType: yup.string().nullable().required(VALIDATION_FIELDREQUIRED('Type of membership')),
                        membershipFromDate: yup
                            .date()
                            .nullable()
                            // .typeError(VALIDATION_MEMBERSHIP_START_DATE_INVALID)
                            .required(VALIDATION_FIELDREQUIRED('Membership start date'))
                            .transform((value, rawValue) => {
                                if (moment(rawValue).isValid() === false) {
                                    return EMPTY_DATE;
                                } else {
                                    const correctDate = moment(rawValue, [DATE_FORMAT]).toDate();
                                    return correctDate;
                                }
                            })
                            .when(
                                'membershipToDate',
                                (membershipToDate: Date) =>
                                    membershipToDate &&
                                    yup
                                        .date()
                                        .required(VALIDATION_FIELDREQUIRED('Membership start date'))
                                        .nullable()
                                        .default(undefined)
                                        // .typeError(VALIDATION_MEMBERSHIP_START_DATE_INVALID)
                                        .transform((_value: any, rawValue: moment.MomentInput) => {
                                            if (moment(rawValue).isValid() === false) {
                                                return EMPTY_DATE;
                                            } else {
                                                const correctDate = moment(rawValue, [DATE_FORMAT]).toDate();
                                                return correctDate;
                                            }
                                        })
                                        // tslint:disable-next-line: only-arrow-functions
                                        .test('date-required', VALIDATION_FIELDREQUIRED('Membership start date'), function (value) {
                                            return value !== undefined && value !== null && value !== EMPTY_DATE;
                                        })
                                        .test(
                                            'membershipToDate',
                                            VALIDATION_MEMBERSHIP_START_DATE_AFTER_END_DATE,
                                            // tslint:disable-next-line: only-arrow-functions
                                            function (value: Date | undefined | null) {
                                                const { from } = this as yup.TestContext & TestContextExtended;
                                                const currItem = value;
                                                const currMembershipToDate =
                                                    from && from.length > 1 && from[2].value?.application?.activeComplaintsMechanismObj?.membershipToDate;

                                                // if both dates supplied and are valid then we check whether To is before From date
                                                if (
                                                    moment(currMembershipToDate).isValid() &&
                                                    moment(currItem).isValid() &&
                                                    currMembershipToDate !== EMPTY_DATE
                                                ) {
                                                    // compare
                                                    return currItem! <= currMembershipToDate;
                                                }
                                                // if both or either invalid then this test is not required
                                                return true;
                                            },
                                        ),
                                // .max(membershipToDate, VALIDATION_MEMBERSHIP_START_DATE_AFTER_END_DATE),
                            ),
                        membershipToDate: yup
                            .date()
                            .nullable()
                            // .typeError(VALIDATION_MEMBERSHIP_END_DATE_INVALID)
                            .required(VALIDATION_FIELDREQUIRED('Membership end date'))
                            .transform((_value, rawValue) => {
                                const correctDate = moment(rawValue, [DATE_FORMAT]).toDate();
                                return correctDate;
                            })
                            .when(
                                'membershipFromDate',
                                // tslint:disable-next-line: no-shadowed-variable
                                (membershipFromDate: Date) =>
                                    membershipFromDate &&
                                    yup
                                        .date()
                                        .required(VALIDATION_FIELDREQUIRED('Membership end date'))
                                        .nullable()
                                        .default(undefined)
                                        // .typeError(VALIDATION_MEMBERSHIP_END_DATE_INVALID)
                                        .transform((_value: any, rawValue: moment.MomentInput) => {
                                            if (moment(rawValue).isValid() === false) {
                                                return EMPTY_DATE;
                                            } else {
                                                const correctDate = moment(rawValue, [DATE_FORMAT]).toDate();
                                                return correctDate;
                                            }
                                        })
                                        // tslint:disable-next-line: only-arrow-functions
                                        .test('date-required', VALIDATION_FIELDREQUIRED('Membership end date'), function (value) {
                                            if (!value || value === EMPTY_DATE) {
                                                return false;
                                            }
                                            return true;
                                        })
                                        .min(membershipFromDate, VALIDATION_MEMBERSHIP_END_DATE_BEFORE_START_DATE)
                                        // tslint:disable-next-line: only-arrow-functions
                                        .test('date-not-in-past', VALIDATION_MEMBERSHIP_END_DATE_NOT_IN_PAST, function (value) {
                                            if (!value || value === EMPTY_DATE) {
                                                return true;
                                            }

                                            return IsGreaterOrEqualToToday(value);
                                        }),
                            ),
                        /* // Evidence of professional association membership
                        activeAttachmentId: yup
                            .string()
                            .trim()
                            .nullable()
                            .test('firstAttachmentAlwaysMandatory', VALIDATION_FILEUPLOADREQUIRED('professional association membership'), function (item) {
                                const { from } = this as yup.TestContext & TestContextExtended;
                                // no more array, it is stored in active obj now
                                // const { options } = this as yup.TestContext & ValidateOptionsExtended;
                                const indexToCheck = from && from.length > 2 && from[2]?.value.application?.activeComplaintsMechanismIndex;
                                const isAccredited = from && from.length > 2 && from[2]?.value.application?.accreditationStatus === 'Accredited';

                                // either check accredited OR only check first item at this stage as it is not yet submitted application (this validation re-used)
                                // if (options.index > 0) {
                                if (isAccredited === false && (indexToCheck === undefined || indexToCheck > 0)) {
                                    return true;
                                }

                                // application object unpacked at index 1
                                // const complaintsMechanisms: IComplaintMechanism[] = from && from.length > 1 && from[1]?.value.complaintsMechanism;
                                const activeCM: IComplaintMechanism = from && from.length > 2 && from[2]?.value.application.activeComplaintsMechanismObj;
                                return attachmentExists(activeCM);
                            }), */
                    },
                    [['membershipFromDate', 'membershipToDate']],
                ),
            CMMdocumentAttachmentIsMandatory: yup.boolean().nullable().notRequired(),
            // evidence of professional association membership (re-used by initial application and edit CM page)
            attachments: yup
                .array()
                .nullable()
                .test('isCMM-mandatory-attachment', VALIDATION_FILEUPLOADREQUIRED('professional association membership'), function (values: any) {
                    const { CMMdocumentAttachmentIsMandatory } = this.parent;
                    // const { from } = this as yup.TestContext & TestContextExtended;

                    const activeCM: IComplaintMechanism = this.parent.activeComplaintsMechanismObj;

                    if (CMMdocumentAttachmentIsMandatory === true) {
                        return attachmentOfTypeExists(values, AttachmentDocumentType.AssociationMembership, activeCM.complaintsMechanismId);
                    }
                    return true;
                }),
        }),
    }),
});

export const validationSchemaProfessionalIndemnityPage = yup.object().shape({
    inquiry: yup.object().shape({
        application: yup.object().shape({
            activeComplaintsMechanismObj: yup
                .object()
                .nullable()
                .shape({
                    hasProfessionalIndemnityInsuranceViaMembership: yup.boolean().nullable().required(VALIDATION_FIELDREQUIRED('Insurance type')),
                    professionalIndemnityInsuranceFromDate: yup.mixed().when('hasProfessionalIndemnityInsuranceViaMembership', {
                        is: true,
                        then: yup
                            .date()
                            .nullable()
                            .defined(VALIDATION_FIELDREQUIRED('Insurance start date'))
                            .typeError(VALIDATION_INSURANCE_START_DATE_INVALID)
                            .required(VALIDATION_FIELDREQUIRED('Insurance start date'))
                            .transform((_value: any, rawValue: moment.MomentInput) => {
                                if (moment(rawValue).isValid() === false) {
                                    return EMPTY_DATE;
                                } else {
                                    const correctDate = moment(rawValue, [DATE_FORMAT]).toDate();
                                    return correctDate;
                                }
                            })
                            // tslint:disable-next-line: only-arrow-functions
                            .test('date-empty-value', VALIDATION_FIELDREQUIRED('Insurance start date'), function (value) {
                                return value !== undefined && value !== null && value !== EMPTY_DATE; // !(!value || value === EMPTY_DATE);
                            })
                            .test(
                                'professionalIndemnityInsuranceToDate',
                                VALIDATION_INSURANCE_START_DATE_AFTER_END_DATE,
                                // tslint:disable-next-line: only-arrow-functions
                                function (value: Date | undefined | null) {
                                    const { from } = this as yup.TestContext & TestContextExtended;

                                    const currItem = value;
                                    const professionalIndemnityInsuranceToDate =
                                        from &&
                                        from.length > 1 &&
                                        from[2].value?.application?.activeComplaintsMechanismObj?.professionalIndemnityInsuranceToDate;

                                    // if both dates supplied and are valid then we check whether To is before From date
                                    if (moment(professionalIndemnityInsuranceToDate).isValid() && moment(currItem).isValid()) {
                                        // compare
                                        return currItem! <= professionalIndemnityInsuranceToDate;
                                    }
                                    // if both or either invalid then this test is not required
                                    return true;
                                },
                            ),
                    }),
                    professionalIndemnityInsuranceToDate: yup.mixed().when('hasProfessionalIndemnityInsuranceViaMembership', {
                        is: true,
                        then: yup
                            .date()
                            .nullable()
                            .defined(VALIDATION_FIELDREQUIRED('Insurance end date'))
                            .typeError(VALIDATION_INSURANCE_END_DATE_INVALID)
                            .required(VALIDATION_FIELDREQUIRED('Insurance end date'))
                            // .default(undefined)
                            .transform((_value: any, rawValue: moment.MomentInput) => {
                                if (moment(rawValue).isValid() === false) {
                                    return EMPTY_DATE;
                                } else {
                                    const correctDate = moment(rawValue, [DATE_FORMAT]).toDate();
                                    return correctDate;
                                }
                            })
                            // tslint:disable-next-line: only-arrow-functions
                            .test('date-empty-value', VALIDATION_FIELDREQUIRED('Insurance end date'), function (value) {
                                return value !== undefined && value !== null && value !== EMPTY_DATE; // !(!value || value === EMPTY_DATE);
                            })
                            .test(
                                'date-not-in-past',
                                VALIDATION_INSURANCE_END_DATE_NOT_IN_PAST,
                                // tslint:disable-next-line: only-arrow-functions
                                function (value: Date | undefined | null) {
                                    const { from } = this as yup.TestContext & TestContextExtended;

                                    if (!value || value === EMPTY_DATE) {
                                        return true;
                                    }
                                    const applicationStatus =
                                        from && from.length > 1 && from[2].value?.inquiry?.application?.activeComplaintsMechanismObj?.applicationStatus;
                                    if (applicationStatus === 'ActionRequired') {
                                        return true; // ignore check for resubmit flow
                                    }

                                    return IsGreaterOrEqualToToday(value);
                                },
                            )
                            .test(
                                'professionalIndemnityInsuranceFromDate',
                                VALIDATION_INSURANCE_END_DATE_BEFORE_START_DATE,
                                // tslint:disable-next-line: only-arrow-functions
                                function (value: Date | undefined | null) {
                                    const { from } = this as yup.TestContext & TestContextExtended;

                                    const currItem = value;
                                    const professionalIndemnityInsuranceFromDate =
                                        from &&
                                        from.length > 1 &&
                                        from[2].value?.application?.activeComplaintsMechanismObj?.professionalIndemnityInsuranceFromDate;

                                    // if both dates supplied and are valid then we check whether To is before From date
                                    if (moment(professionalIndemnityInsuranceFromDate).isValid() && moment(currItem).isValid()) {
                                        // compare
                                        return currItem! >= professionalIndemnityInsuranceFromDate;
                                    }
                                    // if both or either invalid then this test is not required
                                    return true;
                                },
                            ),
                    }),
                    /*// evidence of insurance
                    activeAttachmentId: yup
                        .string()
                        .trim()
                        .nullable()
                        .test('firstAttachmentAlwaysMandatory', VALIDATION_FILEUPLOADREQUIRED('insurance'), function (item) {
                            const { from } = this as yup.TestContext & TestContextExtended;
                            // no more array, it is stored in active obj now
                            // const { options } = this as yup.TestContext & ValidateOptionsExtended;
                            const indexToCheck = from && from.length > 2 && from[2]?.value.application?.activeComplaintsMechanismIndex;
                            const isAccredited = from && from.length > 2 && from[2]?.value.application?.accreditationStatus === 'Accredited';
                            const { CMInsuranceDocumentAttachmentIsMandatory } = this.parent;

                            // either check accredited OR only check first item at this stage as it is not yet submitted application (this validation re-used)
                            // if (options.index > 0) {
                            if (isAccredited === false && (indexToCheck === undefined || indexToCheck > 0)) {
                                return true;
                            }

                            // application object unpacked at index 1
                            // const complaintsMechanisms: IComplaintMechanism[] = from && from.length > 1 && from[1]?.value.complaintsMechanism;
                            const activeCM: IComplaintMechanism = from && from.length > 2 && from[2]?.value.application.activeComplaintsMechanismObj;
                            if (CMInsuranceDocumentAttachmentIsMandatory === true) {
                                return attachmentExists(activeCM);
                            }
                            return true;
                        }), */
                }),
            CMInsuranceDocumentAttachmentIsMandatory: yup.boolean().nullable().notRequired(),
            // evidence of insurance (we do not have to check the CM id here as initially there is only one CM)
            attachments: yup
                .array()
                .nullable()
                .test('isCMInsurance-mandatory-attachment', VALIDATION_FILEUPLOADREQUIRED('insurance'), function (values: any) {
                    const { CMInsuranceDocumentAttachmentIsMandatory } = this.parent;

                    if (CMInsuranceDocumentAttachmentIsMandatory === true) {
                        return attachmentOfTypeExists(values, AttachmentDocumentType.Insurance);
                    }
                    return true;
                }),
        }),
    }),
});

const doNotValidateInsuranceFields = (parent: any) => {
    const { hasProfessionalIndemnityInsuranceViaMembership } = parent;

    if (
        hasProfessionalIndemnityInsuranceViaMembership === undefined ||
        hasProfessionalIndemnityInsuranceViaMembership === null ||
        hasProfessionalIndemnityInsuranceViaMembership === false
    ) {
        return true; // if No then no validation required
    }
    return false;
};

export const validationSchemaProfessionalIndemnityEditCMPageInsuranceIsRequired = yup.object().shape({
    inquiry: yup.object().shape({
        application: yup.object().shape({
            activeComplaintsMechanismObj: yup
                .object()
                .nullable()
                .shape({
                    hasProfessionalIndemnityInsuranceViaMembership: yup.boolean().nullable().required(VALIDATION_FIELDREQUIRED('Insurance coverage')),
                    professionalIndemnityInsuranceFromDate: yup
                        .date()
                        .nullable()
                        // .defined(VALIDATION_FIELDREQUIRED('Insurance start date'))
                        .typeError(VALIDATION_INSURANCE_START_DATE_INVALID)
                        .transform((_value: any, rawValue: moment.MomentInput) => {
                            if (moment(rawValue).isValid() === false) {
                                return EMPTY_DATE;
                            } else {
                                const correctDate = moment(rawValue, [DATE_FORMAT]).toDate();
                                return correctDate;
                            }
                        })
                        // tslint:disable-next-line: only-arrow-functions
                        .test('date-empty-value', VALIDATION_FIELDREQUIRED('Insurance start date'), function (value) {
                            /*const { hasProfessionalIndemnityInsuranceViaMembership } = this.parent;

                            if (hasProfessionalIndemnityInsuranceViaMembership === undefined || hasProfessionalIndemnityInsuranceViaMembership === 'No') {
                                return true; // if No then no validation required
                            }*/
                            if (doNotValidateInsuranceFields(this.parent)) {
                                return true; // if No to insurance then no validation required
                            }
                            return value !== undefined && value !== null && value !== EMPTY_DATE; // !(!value || value === EMPTY_DATE);
                        })
                        .test(
                            'professionalIndemnityInsuranceToDate',
                            VALIDATION_INSURANCE_START_DATE_AFTER_END_DATE,
                            // tslint:disable-next-line: only-arrow-functions
                            function (value: Date | undefined | null) {
                                const { from } = this as yup.TestContext & TestContextExtended;
                                if (doNotValidateInsuranceFields(this.parent)) {
                                    return true; // if No to insurance then no validation required
                                }

                                const currItem = value;
                                const professionalIndemnityInsuranceToDate =
                                    from && from.length > 1 && from[2].value?.application?.activeComplaintsMechanismObj?.professionalIndemnityInsuranceToDate;

                                // if both dates supplied and are valid then we check whether To is before From date
                                if (moment(professionalIndemnityInsuranceToDate).isValid() && moment(currItem).isValid()) {
                                    // compare
                                    return currItem! <= professionalIndemnityInsuranceToDate;
                                }
                                // if both or either invalid then this test is not required
                                return true;
                            },
                        ),
                    professionalIndemnityInsuranceToDate: yup
                        .date()
                        .nullable()
                        // .defined(VALIDATION_FIELDREQUIRED('Insurance end date'))
                        .typeError(VALIDATION_INSURANCE_END_DATE_INVALID)
                        // .default(undefined)
                        .transform((_value: any, rawValue: moment.MomentInput) => {
                            if (moment(rawValue).isValid() === false) {
                                return EMPTY_DATE;
                            } else {
                                const correctDate = moment(rawValue, [DATE_FORMAT]).toDate();
                                return correctDate;
                            }
                        })
                        // tslint:disable-next-line: only-arrow-functions
                        .test('date-empty-value', VALIDATION_FIELDREQUIRED('Insurance end date'), function (value) {
                            if (doNotValidateInsuranceFields(this.parent)) {
                                return true; // if No to insurance then no validation required
                            }
                            return value !== undefined && value !== null && value !== EMPTY_DATE; // !(!value || value === EMPTY_DATE);
                        })
                        .test(
                            'date-not-in-past',
                            VALIDATION_INSURANCE_END_DATE_NOT_IN_PAST,
                            // tslint:disable-next-line: only-arrow-functions
                            function (value: Date | undefined | null) {
                                const { from } = this as yup.TestContext & TestContextExtended;
                                if (doNotValidateInsuranceFields(this.parent)) {
                                    return true; // if No to insurance then no validation required
                                }

                                if (!value || value === EMPTY_DATE) {
                                    return true;
                                }
                                const applicationStatus =
                                    from && from.length > 1 && from[2].value?.inquiry?.application?.activeComplaintsMechanismObj?.applicationStatus;
                                if (applicationStatus === 'ActionRequired') {
                                    return true; // ignore check for resubmit flow
                                }

                                return IsGreaterOrEqualToToday(value);
                            },
                        )
                        .test(
                            'professionalIndemnityInsuranceFromDate',
                            VALIDATION_INSURANCE_END_DATE_BEFORE_START_DATE,
                            // tslint:disable-next-line: only-arrow-functions
                            function (value: Date | undefined | null) {
                                const { from } = this as yup.TestContext & TestContextExtended;
                                if (doNotValidateInsuranceFields(this.parent)) {
                                    return true; // if No to insurance then no validation required
                                }

                                const currItem = value;
                                const professionalIndemnityInsuranceFromDate =
                                    from && from.length > 1 && from[2].value?.application?.activeComplaintsMechanismObj?.professionalIndemnityInsuranceFromDate;

                                // if both dates supplied and are valid then we check whether To is before From date
                                if (moment(professionalIndemnityInsuranceFromDate).isValid() && moment(currItem).isValid()) {
                                    // compare
                                    return currItem! >= professionalIndemnityInsuranceFromDate;
                                }
                                // if both or either invalid then this test is not required
                                return true;
                            },
                        ),
                    /* // evidence of insurance
                    activeAttachmentId: yup
                        .string()
                        .trim()
                        .nullable()
                        .test('firstAttachmentAlwaysMandatory', VALIDATION_FILEUPLOADREQUIRED('insurance'), function (item) {
                            const { from } = this as yup.TestContext & TestContextExtended;

                            if (doNotValidateInsuranceFields(this.parent)) {
                                return true; // if No to insurance then no validation required
                            }

                            // no more array, it is stored in active obj now
                            // const { options } = this as yup.TestContext & ValidateOptionsExtended;
                            const indexToCheck = from && from.length > 2 && from[2]?.value.application?.activeComplaintsMechanismIndex;
                            const isAccredited = from && from.length > 2 && from[2]?.value.application?.accreditationStatus === 'Accredited';
                            const { CMInsuranceDocumentAttachmentIsMandatory } = this.parent;

                            // either check accredited OR only check first item at this stage as it is not yet submitted application (this validation re-used)
                            // if (options.index > 0) {
                            if (isAccredited === false && (indexToCheck === undefined || indexToCheck > 0)) {
                                return true;
                            }

                            // application object unpacked at index 1
                            // const complaintsMechanisms: IComplaintMechanism[] = from && from.length > 1 && from[1]?.value.complaintsMechanism;
                            const activeCM: IComplaintMechanism = from && from.length > 2 && from[2]?.value.application.activeComplaintsMechanismObj;
                            if (CMInsuranceDocumentAttachmentIsMandatory === true) {
                                return attachmentExists(activeCM);
                            }
                            return true;
                        }), */
                }),
            CMInsuranceDocumentAttachmentIsMandatory: yup.boolean().nullable().notRequired(),
            // evidence of insurance
            attachments: yup
                .array()
                .nullable()
                .test('isCMInsurance-mandatory-attachment', VALIDATION_FILEUPLOADREQUIRED('insurance'), function (values: any) {
                    const { CMInsuranceDocumentAttachmentIsMandatory } = this.parent;

                    const activeCM: IComplaintMechanism = this.parent.activeComplaintsMechanismObj;
                    if (doNotValidateInsuranceFields(activeCM)) {
                        return true; // if No to insurance then no validation required
                    }

                    if (CMInsuranceDocumentAttachmentIsMandatory === true) {
                        return attachmentOfTypeExists(values, AttachmentDocumentType.Insurance, activeCM.complaintsMechanismId);
                    }
                    return true;
                }),
        }),
    }),
});

export const validationSchemaProfessionalIndemnityEditCMPageInsuranceNotRequired = yup.object().shape({
    inquiry: yup.object().shape({
        application: yup.object().shape({
            activeComplaintsMechanismObj: yup.object().nullable().shape({
                hasProfessionalIndemnityInsuranceViaMembership: yup.boolean().nullable().notRequired(),
                professionalIndemnityInsuranceFromDate: yup.date().nullable().notRequired(),
                professionalIndemnityInsuranceToDate: yup.date().nullable().notRequired(),
                activeAttachmentId: yup.string().trim().nullable().notRequired(),
            }),
            CMInsuranceDocumentAttachmentIsMandatory: yup.boolean().nullable().notRequired(),
            attachments: yup.array().nullable().notRequired(),
        }),
    }),
});

export const validationSchemaChangeOfNamePage = yup.object().shape({
    inquiry: yup.object().shape({
        application: yup.object().shape({
            isResubmit: yup.boolean().nullable().notRequired(),
            documentAttachmentIsMandatory: yup.boolean().nullable().notRequired(),
            attachments: yup
                .array()
                .nullable()
                .test('isResubmit-mandatory-attachment', VALIDATION_FILEUPLOADREQUIRED('change of name'), function (values: any) {
                    const { isResubmit, documentAttachmentIsMandatory } = this.parent;

                    if (isResubmit === true && documentAttachmentIsMandatory === true) {
                        return attachmentOfTypeExists(values, AttachmentDocumentType.EvidenceOfNameChange);
                    }
                    return true;
                }),
        }),
    }),
});

/* const ShouldCheckValueOfConsentToPublish = (from: any) => {
    const inquiry = from && from.length > 2 && from[1]?.value; // 0=application, 1=inquiry
    if (!inquiry || inquiry === null) {
        return false;
    }
    // IMPORTANT NOTE: This breaks cypress as it requires a webpack definition/build:
    return IsConsentToPublishOnPublicRegisterVisible(inquiry);
}; */

export const validationSchemaDeclarationAndConsentPage = yup.object().shape({
    inquiry: yup.object().shape({
        application: yup.object().shape({
            showConsentToPublishOnPublicRegister: yup.boolean().nullable().notRequired(),
            hasDeclaredAndConsented: yup
                .boolean()
                .nullable()
                .required('You must provide your declaration and consent to continue.')
                .oneOf([true], 'You must provide your declaration and consent to continue.'),
            consentToPublishOnPublicRegister: yup
                .boolean()
                .nullable() // .required(VALIDATION_FIELDREQUIRED('Name appearing on public searchable list')),
                .test('mandatoryWhenConsentIsVisible', VALIDATION_FIELDREQUIRED('Name appearing on public searchable list'), function (fieldValue: any) {
                    if (fieldValue === undefined) {
                        return true;
                    }
                    const { showConsentToPublishOnPublicRegister } = this.parent;
                    if (showConsentToPublishOnPublicRegister === true) {
                        return fieldValue !== null; // if value is true/false (ie not empty/null) then validation is OK
                    }

                    return true; // if we dont show it, the validation is OK
                }),
            /* .test('mandatoryWhenConsentIsVisible', VALIDATION_FIELDREQUIRED('Name appearing on public searchable list'), function (fieldValue: any) {
                    if (fieldValue === undefined) {
                        return true;
                    }
                    const { from } = this as yup.TestContext & TestContextExtended;
                    if (ShouldCheckValueOfConsentToPublish(from) === true) {
                        return fieldValue !== null; // if value is true/false (ie not empty/null) then validation is OK
                    }
                    return true; // if we dont show it, the validation is OK
                }), */
        }),
    }),
});

/*
export const validationSchemaReviewAndSubmitPage = yup.object().shape({
    inquiry: yup.object().shape({
        application: yup.object().shape({
            hasDeclaredAndConsented: yup
                .boolean()
                .nullable()
                .required('You must provide your declaration and consent to continue.')
                .oneOf([true], 'You must provide your declaration and consent to continue.'),
        }),
    }),
});
*/

// application object unpacked at index 2, and is called from either address or higher up hance the check into application object
const GetOutletPractitioner = (from: any) => {
    return (
        from &&
        from.length > 2 &&
        (from[2]?.value.outletPractitioner !== undefined ? from[2]?.value.outletPractitioner : from[2]?.value.application.outletPractitioner)
    );
};

// display Physical Address when Face to Face is selected
const IsPhysicalAddressDisplayed = (from: any) => {
    const outletPractitioner = GetOutletPractitioner(from);
    return outletPractitioner.isServiceDeliveryFaceToFace === true;
};

const IsRoomsToBeArrangedSelected = (from: any) => {
    const outletPractitioner = GetOutletPractitioner(from);
    return outletPractitioner.isRoomsToBeArranged === true;
};

const MostPhysicalAddressIsValid = (from: any) => {
    const outletPractitioner = GetOutletPractitioner(from);
    return (
        outletPractitioner.businessAddress &&
        outletPractitioner.businessAddress?.town &&
        outletPractitioner.businessAddress.town !== null &&
        outletPractitioner.businessAddress.town !== '' &&
        outletPractitioner.businessAddress?.state &&
        outletPractitioner.businessAddress.state !== null &&
        outletPractitioner.businessAddress.state !== '' &&
        outletPractitioner.businessAddress?.postcode &&
        outletPractitioner.businessAddress.postcode !== null &&
        outletPractitioner.businessAddress.postcode !== ''
    );
};

// confirm one day is selected at least
const IsAtLeastOneDaySelected = (from: any) => {
    const outletPractitioner = GetOutletPractitioner(from);

    return (
        outletPractitioner.openingHours &&
        outletPractitioner.openingHours.length > 0 &&
        outletPractitioner.openingHours.filter((item: { isOpen: boolean }) => item.isOpen === true).length > 0
    );
};

const patterns: any = {
    // eslint-disable-next-line no-useless-escape
    domain: /\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/,
    // domain: /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
    punycode: /^([A-Za-z0-9](?:(?:[-A-Za-z0-9]){0,61}[A-Za-z0-9])?(?:\.[A-Za-z0-9](?:(?:[-A-Za-z0-9]){0,61}[A-Za-z0-9])?)*)(\.?)$/,
    // eslint-disable-next-line no-useless-escape
    cyrillicDomain: /^((http|https):\/\/)?[a-zа-я0-9]+([\-\.]{1}[a-zа-я0-9]+)*\.[a-zа-я]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
};
const domainRules = [patterns.domain, patterns.punycode, patterns.cyrillicDomain];

/* // Using custom test method
yup.addMethod(yup.string, 'domain', function pattern(name, message = VALIDATION_OUTLET_WEBADDRESS_INVALID) {
    const domainRules = [patterns.domain, patterns.punycode, patterns.cyrillicDomain];

    return this.test({
        message,
        test: value => value === null || value === '' || value === undefined || domainRules.some(regex => regex.test(value)),
    });
}); */

export const validationSchemaOutletDetails = yup.object().shape({
    inquiry: yup.object().shape({
        application: yup.object().shape({
            outletPractitioner: yup.object().shape(
                {
                    name: yup.string().trim().nullable().max(100, VALIDATION_FIELDLENGTH_EXCEEDED('Business/practice name', 100)),
                    phone: yup
                        .string()
                        .trim()
                        .nullable()
                        .notRequired()
                        .max(10, VALIDATION_FIELDLENGTH_EXCEEDED_NUMBERS('Landline', 10))
                        .when('phone', {
                            is: (value: string | any[]) => value?.length > 0,
                            then: rule => rule.matches(/^\d+$/, VALIDATION_FIELDNUMERICONLY_PHONENUMBER('Landline')),
                        })
                        .test(
                            'atleastonecontacttypeexists',
                            'Please supply at least one of Landline, Mobile, Toll free number or Email address.',
                            function (value) {
                                if (value !== '') {
                                    return true;
                                }
                                const { mobile, tollFreePhone, registerEmail } = this.parent;
                                if (!mobile && !tollFreePhone && !registerEmail) return false;
                                return true;
                            },
                        ),
                    mobile: yup
                        .string()
                        .trim()
                        .nullable()
                        .notRequired()
                        .max(10, VALIDATION_FIELDLENGTH_EXCEEDED_NUMBERS('Mobile', 10))
                        .when('mobile', {
                            is: (value: string | any[]) => value?.length > 0,
                            then: rule => rule.matches(/^\d+$/, VALIDATION_FIELDNUMERICONLY_PHONENUMBER('Mobile')),
                        }),
                    tollFreePhone: yup
                        .string()
                        .trim()
                        .nullable()
                        .max(10, VALIDATION_FIELDLENGTH_EXCEEDED_NUMBERS('Toll free number', 10))
                        .when('tollFreePhone', {
                            is: (value: string | any[]) => value?.length > 0,
                            then: rule => rule.matches(/^\d+$/, VALIDATION_FIELDNUMERICONLY_PHONENUMBER('Toll free number')),
                        })
                        .when('tollFreePhone', {
                            is: (value: string | any[]) => value?.length > 0,
                            then: rule => rule.matches(/(^1300|^1800)/, VALIDATION_FIELDNUMERICSTARTSWITH_TOLLFREENUMBER('Toll free number')),
                        }),
                    registerEmail: yup
                        .string()
                        .trim()
                        .nullable()
                        .max(150, VALIDATION_FIELDLENGTH_EXCEEDED('Email address', 150))
                        .email(VALIDATION_EMAIL_INVALID('email'))
                        .test('check-length-of-localpart', VALIDATION_EMAIL_LOCALPART_INVALID('email'), value => validEmailLocalPart(value!))
                        .test('check-domain-valid', VALIDATION_EMAIL_DOMAINPART_INVALID('email'), value => validEmailDomanPart(value!)),
                    webAddress: yup
                        .string()
                        .trim()
                        .nullable()
                        .notRequired()
                        // tslint:disable-next-line: only-arrow-functions
                        .test('websiteDomainIsValid', VALIDATION_OUTLET_WEBADDRESS_INVALID, function (value) {
                            if (value === null || value === '' || value === undefined) {
                                return true;
                            }
                            if (value.includes(';') || value.includes(',') || value.trim().includes(' ')) {
                                return false;
                            }
                            return domainRules.some(regex => regex.test(value));
                        })
                        .max(150, VALIDATION_FIELDLENGTH_EXCEEDED('Web address', 150))
                        .when('webAddress', {
                            is: (value: string | any) => value?.length > 0,
                            then: (rule: any) => rule.matches(/(^https?:\/\/.*)/, VALIDATION_OUTLET_WEBADDRESS_PROTOCOL_INVALID()),
                        }), // https://stackoverflow.com/
                    isServiceDeliveryFaceToFace: yup
                        .boolean()
                        .nullable()
                        .notRequired()
                        .test('atleastonemodeexists', 'At least one Mode of delivery must be selected.', function (value) {
                            const { isServiceDeliveryPhone, isServiceDeliveryOnline } = this.parent;
                            if (!value && !isServiceDeliveryPhone && !isServiceDeliveryOnline) return false;
                            return true;
                        }),
                    isServiceDeliveryPhone: yup.boolean().nullable().notRequired(),
                    isServiceDeliveryOnline: yup.boolean().nullable().notRequired(),
                    businessAddress: yup.object().shape({
                        streetAddress: yup.string().trim().nullable(),
                        // .max(150, VALIDATION_FIELDLENGTH_EXCEEDED('Street address', 150))
                        // use $ to get value from Context (i.e. it is not a sibling it is an ancestor) -- the below is NOT working as expected!
                        /*.when('$isServiceDeliveryFaceToFace', {
                                is: (isServiceDeliveryFaceToFace: boolean) => {
                                    return isServiceDeliveryFaceToFace === true;
                                },
                                then: rule => rule.required(VALIDATION_FIELDREQUIRED('Street address')),
                            })*/
                        /*// only validate when field has value and face to face is checked (true)
                            .test('mandatorywhenfacetoface1', VALIDATION_FIELDREQUIRED('Street address'), function (fieldValue: any): boolean {
                                const { from } = this as yup.TestContext & TestContextExtended;

                                if (IsRoomsToBeArrangedSelected(from) === true) {
                                    return true; // if rooms to be arranged is ticked then this field is optional
                                }
                                const isDisplayed = IsPhysicalAddressDisplayed(from);

                                const fieldHasValue = fieldValue !== undefined && fieldValue !== null && fieldValue !== '';

                                // fail this test only when face to face is true and there is no value, ignore test when face to face is not checked
                                return isDisplayed === false || (isDisplayed === true && fieldHasValue);
                            })*/
                        /*// only validate when field has value and face to face is checked (true)
                            .test('fieldlengthexceeded', VALIDATION_FIELDLENGTH_EXCEEDED('Street address', 150), function (fieldValue: any): boolean {
                                const { from } = this as yup.TestContext & TestContextExtended;

                                if (IsRoomsToBeArrangedSelected(from) === true) {
                                    return true; // if rooms to be arranged is ticked then this field is optional
                                }
                                const isDisplayed = IsPhysicalAddressDisplayed(from);

                                const fieldHasValue = fieldValue !== undefined && fieldValue !== null && fieldValue !== '';
                                const fieldLengthExceeded = fieldHasValue && fieldValue.length > 150;
                                // fail this test only when face to face is true and value is length > 150, ignore test when face to face is not checked
                                return isDisplayed === false || (isDisplayed === true && fieldHasValue && !fieldLengthExceeded);
                            }),*/
                        postcode: yup
                            .string()
                            .trim()
                            .nullable()
                            // .min(4, VALIDATION_FIELDLENGTH_NOTREACHED_POSTCODE('Postcode', 4)) // /^\d{4}$/
                            // .max(4, VALIDATION_FIELDLENGTH_EXCEEDED('Postcode', 4))
                            // .required(VALIDATION_FIELDREQUIRED('Postcode'))
                            // .matches(/^\d+$/, VALIDATION_FIELDNUMERICONLY_POSTCODE('Postcode'))
                            // .matches(/^\d{4}$/, VALIDATION_FIELDLENGTH_NOTREACHED_POSTCODE('Postcode', 4))
                            // only validate when field has value and face to face is checked (true)
                            .test('mandatorywhenfacetoface2', VALIDATION_FIELDREQUIRED('Postcode'), function (fieldValue: any): boolean {
                                const { from } = this as yup.TestContext & TestContextExtended;

                                if (IsRoomsToBeArrangedSelected(from) === true) {
                                    return true; // if rooms to be arranged is ticked then this field is optional
                                }
                                const isDisplayed = IsPhysicalAddressDisplayed(from);

                                const fieldHasValue = fieldValue !== undefined && fieldValue !== null && fieldValue !== '';

                                // fail this test only when face to face is true and there is no value, ignore test when face to face is not checked
                                return isDisplayed === false || (isDisplayed === true && fieldHasValue);
                            })
                            // only validate when field has value and face to face is checked (true)
                            .test('numericonlyandfacetoface', VALIDATION_FIELDNUMERICONLY_POSTCODE('Postcode'), function (fieldValue: any): boolean {
                                const { from } = this as yup.TestContext & TestContextExtended;

                                if (IsRoomsToBeArrangedSelected(from) === true) {
                                    return true; // if rooms to be arranged is ticked then this field is optional
                                }
                                const isDisplayed = IsPhysicalAddressDisplayed(from);

                                const fieldHasValue = fieldValue !== undefined && fieldValue !== null && fieldValue !== '';
                                const fieldIsNumeric = IsNumeric(fieldValue);
                                // fail this test only when face to face is true and value is not numeric, ignore test when face to face is not checked
                                return isDisplayed === false || (isDisplayed === true && fieldHasValue && fieldIsNumeric);
                            })
                            // only validate when field has value and face to face is checked (true)
                            .test('fieldlengthnotreached', VALIDATION_FIELDLENGTH_NOTREACHED_POSTCODE('Postcode', 4), function (fieldValue: any): boolean {
                                const { from } = this as yup.TestContext & TestContextExtended;

                                if (IsRoomsToBeArrangedSelected(from) === true) {
                                    return true; // if rooms to be arranged is ticked then this field is optional
                                }
                                const isDisplayed = IsPhysicalAddressDisplayed(from);

                                const fieldHasValue = fieldValue !== undefined && fieldValue !== null && fieldValue !== '';
                                const fieldLengthReached = fieldHasValue && fieldValue.length > 3;
                                // fail this test only when face to face is true and value is length <= 3, ignore test when face to face is not checked
                                return isDisplayed === false || (isDisplayed === true && fieldHasValue && fieldLengthReached);
                            })
                            // only validate when field has value and face to face is checked (true)
                            .test('fieldlengthexceeded', VALIDATION_FIELDLENGTH_EXCEEDED_NUMBERS('Postcode', 4), function (fieldValue: any): boolean {
                                const { from } = this as yup.TestContext & TestContextExtended;

                                if (IsRoomsToBeArrangedSelected(from) === true) {
                                    return true; // if rooms to be arranged is ticked then this field is optional
                                }
                                const isDisplayed = IsPhysicalAddressDisplayed(from);

                                const fieldHasValue = fieldValue !== undefined && fieldValue !== null && fieldValue !== '';
                                const fieldLengthExceeded = fieldHasValue && fieldValue.length > 4;
                                // fail this test only when face to face is true and value is length > 4, ignore test when face to face is not checked
                                return isDisplayed === false || (isDisplayed === true && fieldHasValue && !fieldLengthExceeded);
                            }),
                        state: yup
                            .string()
                            .trim()
                            .nullable()
                            // drop down only - .max(100, VALIDATION_FIELDLENGTH_EXCEEDED('State', 100))
                            // .required(VALIDATION_FIELDREQUIRED('State'))
                            // only validate when field has value and face to face is checked (true)
                            .test('mandatorywhenfacetoface4', VALIDATION_FIELDREQUIRED('State'), function (fieldValue: any): boolean {
                                const { from } = this as yup.TestContext & TestContextExtended;

                                if (IsRoomsToBeArrangedSelected(from) === true) {
                                    return true; // if rooms to be arranged is ticked then this field is optional
                                }

                                const isDisplayed = IsPhysicalAddressDisplayed(from);

                                const fieldHasValue = fieldValue !== undefined && fieldValue !== null && fieldValue !== '';

                                // fail this test only when face to face is true and there is no value, ignore test when face to face is not checked
                                return isDisplayed === false || (isDisplayed === true && fieldHasValue);
                            }),
                        town: yup
                            .string()
                            .trim()
                            .nullable()
                            // only validate when field has value and face to face is checked (true)
                            .test('mandatorywhenfacetoface3', VALIDATION_FIELDREQUIRED('Suburb'), function (fieldValue: any): boolean {
                                const { from } = this as yup.TestContext & TestContextExtended;

                                if (IsRoomsToBeArrangedSelected(from) === true) {
                                    return true; // if rooms to be arranged is ticked then this field is optional
                                }
                                const isDisplayed = IsPhysicalAddressDisplayed(from);

                                const fieldHasValue = fieldValue !== undefined && fieldValue !== null && fieldValue !== '';

                                // fail this test only when face to face is true and there is no value, ignore test when face to face is not checked
                                return isDisplayed === false || (isDisplayed === true && fieldHasValue);
                            })
                            // only validate when field has value and face to face is checked (true)
                            .test('fieldlengthexceeded', VALIDATION_FIELDLENGTH_EXCEEDED('Suburb', 100), function (fieldValue: any): boolean {
                                const { from } = this as yup.TestContext & TestContextExtended;

                                if (IsRoomsToBeArrangedSelected(from) === true) {
                                    return true; // if rooms to be arranged is ticked then this field is optional
                                }
                                const isDisplayed = IsPhysicalAddressDisplayed(from);

                                const fieldHasValue = fieldValue !== undefined && fieldValue !== null && fieldValue !== '';
                                const fieldLengthExceeded = fieldHasValue && fieldValue.length > 100;
                                // fail this test only when face to face is true and value is length > 100, ignore test when face to face is not checked
                                return isDisplayed === false || (isDisplayed === true && fieldHasValue && !fieldLengthExceeded);
                            })
                            // only validate when field has value and face to face is checked (true)
                            .test('fieldcontainsinvalidcharacter', VALIDATION_FIELDALPHAONLY('Suburb'), function (fieldValue: any): boolean {
                                const { from } = this as yup.TestContext & TestContextExtended;

                                if (IsRoomsToBeArrangedSelected(from) === true) {
                                    return true; // if rooms to be arranged is ticked then this field is optional
                                }
                                const isDisplayed = IsPhysicalAddressDisplayed(from);

                                const fieldHasValue = fieldValue !== undefined && fieldValue !== null && fieldValue !== '';
                                const fieldContainsOnlyValidCharacters = fieldHasValue && fieldValue.toString().match(/^([A-Za-z\s']*)$/) !== null;
                                // fail this test only when face to face is true and value is length > 100, ignore test when face to face is not checked
                                return isDisplayed === false || (isDisplayed === true && fieldHasValue && fieldContainsOnlyValidCharacters);
                            }),
                        longitude: yup.number().notRequired(),
                        latitude: yup.number().notRequired(),
                    }),
                    isRoomsToBeArranged: yup
                        .boolean()
                        .nullable()
                        .notRequired()
                        .test('mandatorywhenfacetoface5', VALIDATION_FIELDREQUIRED_ADDRESSORROOMSARRANGED, function (fieldValue: any): boolean {
                            const { from } = this as yup.TestContext & TestContextExtended;
                            if (MostPhysicalAddressIsValid(from) === true) {
                                return true;
                            }
                            const isDisplayed = IsPhysicalAddressDisplayed(from);
                            const fieldHasTrueValue = fieldValue !== undefined && fieldValue !== null && fieldValue !== false;
                            // fail this test only when face to face is true and there is no value, ignore test when face to face is not checked
                            return isDisplayed === false || (isDisplayed === true && fieldHasTrueValue);
                        }),
                    isAdditionalComediation: yup.boolean().nullable().notRequired(),
                    isAdditionalGenderBalanced: yup.boolean().nullable().notRequired(),
                    isAdditionalLegallyAssist: yup.boolean().nullable().notRequired(),
                    isAdditionalChildInclusive: yup.boolean().nullable().notRequired(),
                    isScopeChildSupport: yup.boolean().nullable().notRequired(),
                    isScopeProperty: yup.boolean().nullable().notRequired(),
                    isScopeTheHague: yup.boolean().nullable().notRequired(),
                    isClientGroupLgbtqi: yup.boolean().nullable().notRequired(),
                    isClientGroupCarers: yup.boolean().nullable().notRequired(),
                    isClientGroupAboriginal: yup.boolean().nullable().notRequired(),
                    isClientGroupTorresStrait: yup.boolean().nullable().notRequired(),
                    isClientGroupCulturallyDiverse: yup.boolean().nullable().notRequired(),
                    culturalLanguageReligion: yup
                        .string()
                        .nullable()
                        .notRequired()
                        .test('culturalCecheckedThereforeOneFieldMustExist', VALIDATION_OUTLET_ONEFIELDREQUIRED_CULTURELANGUAGERELIGION, function (value) {
                            const { isClientGroupCulturallyDiverse, cultureGroupSpecificDetail, languageSpecificDetail, religionSpecificDetail } = this.parent;

                            // if one of the check boxes is true then one of the text inputs must have a value
                            if (isClientGroupCulturallyDiverse === true) {
                                if (
                                    (cultureGroupSpecificDetail === undefined || cultureGroupSpecificDetail === null || cultureGroupSpecificDetail === '') &&
                                    (languageSpecificDetail === undefined || languageSpecificDetail === null || languageSpecificDetail === '') &&
                                    (religionSpecificDetail === undefined || religionSpecificDetail === null || religionSpecificDetail === '')
                                ) {
                                    return false; // invalid as all are empty
                                }
                                return true; // valid if one entry is OK
                            } else {
                                return true; // valid if not check boxes are set
                            }
                        }),
                    cultureGroupSpecificDetail: yup.string().trim().nullable().notRequired(),
                    languageSpecificDetail: yup.string().trim().nullable().notRequired(),
                    religionSpecificDetail: yup.string().trim().nullable().notRequired(),
                    isOtherServicePractitioners: yup.boolean().nullable().notRequired(),
                    isOtherServiceStudents: yup.boolean().nullable().notRequired(),
                    inHoursByAppointment: yup
                        .boolean()
                        .nullable()
                        .notRequired()
                        .test('atleastonappointmenttypeexists', 'At least one Appointment type must be selected.', function (value) {
                            const { inHoursWalkIn, outsideHours } = this.parent;
                            if (!value && !inHoursWalkIn && !outsideHours) {
                                return false;
                            }
                            return true;
                        }),
                    inHoursWalkIn: yup.boolean().nullable().notRequired(),
                    outsideHours: yup.boolean().nullable().notRequired(),
                    standardOperatingHours: yup
                        .boolean()
                        .nullable()
                        .notRequired()
                        .test('atleastonedayselected', 'You must select at least one day a week for Opening hours.', function (fieldValue: any): boolean {
                            const { from } = this as yup.TestContext & TestContextExtended;
                            const isOK = IsAtLeastOneDaySelected(from);

                            // fail this test if at least one day is not selected
                            return isOK;
                        }),
                    openingHours: yup
                        .array()
                        .nullable()
                        .of(
                            yup
                                .object()
                                .nullable()
                                .shape(
                                    {
                                        day: yup.number().nullable().notRequired(),
                                        openTime: yup
                                            .date()
                                            .nullable()
                                            .notRequired()
                                            .test('mandatorywhenclosetimeorisopen', VALIDATION_FIELDREQUIRED('Start time'), function (fieldValue: any) {
                                                const { closeTime, isOpen } = this.parent;
                                                if (closeTime === null && (isOpen === null || isOpen === false)) {
                                                    return true;
                                                }

                                                const fieldHasValue =
                                                    fieldValue !== undefined && fieldValue !== null && fieldValue !== '' && fieldValue !== EMPTY_DATE;

                                                return fieldHasValue;
                                            })
                                            .test('opentimeafterclosetime', VALIDATION_OUTLET_STARTTIME_AFTER_ENDTIME(), function (fieldValue: any) {
                                                const { closeTime } = this.parent;
                                                if (closeTime === null) {
                                                    return true;
                                                }

                                                const fieldHasValue =
                                                    fieldValue !== undefined && fieldValue !== null && fieldValue !== '' && fieldValue !== EMPTY_DATE;

                                                if (!fieldHasValue) {
                                                    return true;
                                                }

                                                // only check if both fields have a valid value
                                                return fieldValue <= closeTime;
                                            }),
                                        closeTime: yup
                                            .date()
                                            .nullable()
                                            .notRequired()
                                            .test('mandatorywhenopentimeorisopen', VALIDATION_FIELDREQUIRED('End time'), function (fieldValue: any) {
                                                const { openTime, isOpen } = this.parent;
                                                if (openTime === null && (isOpen === null || isOpen === false)) {
                                                    return true;
                                                }

                                                const fieldHasValue =
                                                    fieldValue !== undefined && fieldValue !== null && fieldValue !== '' && fieldValue !== EMPTY_DATE;

                                                return fieldHasValue;
                                            })
                                            .test('closetimebeforeopentime', VALIDATION_OUTLET_ENDTIME_BEFORE_STARTTIME(), function (fieldValue: any) {
                                                const { openTime } = this.parent;
                                                if (openTime === null) {
                                                    return true;
                                                }

                                                const fieldHasValue =
                                                    fieldValue !== undefined && fieldValue !== null && fieldValue !== '' && fieldValue !== EMPTY_DATE;

                                                if (!fieldHasValue) {
                                                    return true;
                                                }

                                                // only check if both fields have a valid value
                                                return fieldValue >= openTime;
                                            }),
                                        isOpen: yup
                                            .boolean()
                                            .nullable()
                                            .notRequired()
                                            .test('mandatorywhenopentimeorclosetime', VALIDATION_FIELDREQUIRED('Day selection'), function (value) {
                                                const { openTime, closeTime } = this.parent;
                                                // if value is selected then OK
                                                if (value === true) {
                                                    return true;
                                                }
                                                // if no values all OK
                                                if (!value && openTime === null && closeTime === null) {
                                                    return true;
                                                }
                                                return false;
                                            }),
                                    },
                                    [['openTime', 'closeTime']],
                                ),
                        ),
                    serviceCoverageKm: yup
                        .string()
                        .trim()
                        .nullable()
                        .notRequired()
                        .max(4, VALIDATION_FIELDLENGTH_EXCEEDED_NUMBERS('Travel distance', 4))
                        .when('serviceCoverageKm', {
                            is: (value: string | any[]) => value?.length > 0,
                            then: rule => rule.matches(/^\d+$/, VALIDATION_FIELDNUMERICONLY_TRAVELDISTANCE('Travel distance')),
                        }),
                    serviceCoverageOwnExpense: yup
                        .boolean()
                        .nullable()
                        .notRequired()
                        .when('serviceCoverageKm', {
                            is: (value: string | any[]) => value?.length > 0,
                            then: rule => rule.required(VALIDATION_FIELDREQUIRED_SERVICECOVERAGE_OWNEXPENSE),
                        }),
                    intakeServiceCost: yup.string().trim().nullable().required(VALIDATION_FIELDREQUIRED_PREMEDIATION_AND_ASSESSMENT),
                    intakeServiceNotes: yup.string().trim().nullable().notRequired(),
                    sessionServiceCost: yup.string().trim().nullable().required(VALIDATION_FIELDREQUIRED_FDR_SESSIONS),
                    sessionServiceNotes: yup.string().trim().nullable().notRequired(),
                    certificateServiceCost: yup.string().trim().nullable().required(VALIDATION_FIELDREQUIRED_ISSUING_CERTIFICATES),
                    certificateServiceNotes: yup.string().trim().nullable().notRequired(),
                    roomExpense: yup
                        .string()
                        .trim()
                        .nullable()
                        .notRequired()
                        .test('roomHireMandatoryIfRoomsToBeArranged', VALIDATION_FIELDREQUIRED_ROOM_HIRE_EXPENSES, function (value) {
                            const { isRoomsToBeArranged } = this.parent;
                            // if my value then OK
                            if (value) {
                                return true;
                            }
                            // if no value then only if Rooms to be arranged is NOT set do we throw this exception
                            if (!value && (isRoomsToBeArranged === undefined || isRoomsToBeArranged === null || isRoomsToBeArranged === false)) {
                                return true;
                            }
                            return false;
                        }),
                    outsideHoursAdditionalCost: yup
                        .boolean()
                        .nullable()
                        .test('outsideHoursAdditionalCostMandatoryIfIsOutside', VALIDATION_FIELDREQUIRED_OUTSIDE_HOURS, function (value) {
                            const { outsideHours } = this.parent;
                            // if any value selected (true or false) then OK
                            if (value === false || value === true) {
                                return true;
                            }
                            // if no value then only if outsideHours is NOT set do we throw this exception
                            if (!value && (outsideHours === undefined || outsideHours === null || outsideHours === false)) {
                                return true;
                            }
                            return false;
                        }),
                    feeDetailOnWebsite: yup
                        .boolean()
                        .nullable()
                        .notRequired()
                        .test('feeDetailMandatoryIfWebsiteExists', VALIDATION_FIELDREQUIRED_FEE_INFO_ON_WEBSITE, function (value) {
                            const { webAddress } = this.parent;
                            // if any value selected (true or false) then OK
                            if (value === false || value === true) {
                                return true;
                            }
                            // if no values all OK
                            if (!value && (webAddress === undefined || webAddress === null || webAddress === '')) {
                                return true;
                            }
                            return false;
                        }),
                },
                [
                    // Add Cyclic deps here because when require itself
                    ['phone', 'phone'],
                    ['mobile', 'mobile'],
                    ['tollFreePhone', 'tollFreePhone'],
                    ['webAddress', 'webAddress'],
                    ['cultureGroupSpecificDetail', 'isCultureGroupSpecific'],
                    ['isCultureGroupSpecific', 'cultureGroupSpecificDetail'],
                    ['languageSpecificDetail', 'isLanguageSpecific'],
                    ['isLanguageSpecific', 'languageSpecificDetail'],
                    ['religionSpecificDetail', 'isReligionSpecific'],
                    ['isReligionSpecific', 'religionSpecificDetail'],
                    ['serviceCoverageKm', 'serviceCoverageKm'],
                    ['serviceCoverageOwnExpense', 'serviceCoverageKm'],
                ],
            ),
        }),
    }),
});
