// Outlet Details page
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
// tslint:disable-next-line: no-submodule-imports
import { yupResolver } from '@hookform/resolvers/yup';
import { IApplication, Inquiry, InquiryDefaultObj, IUser } from '../../api/interfacesApi';
import {
    HEADER_EDIT_OUTLETDETAILS,
    HELPHEADERTEXT_GENDERONPUBLICREGISTER,
    HELPTEXT_GENDERONPUBLICREGISTER,
    MODALHEADER_UPDATEOUTLET_OK,
    MODALHEADER_UPDATEOUTLET_OK_NEEDSREVIEW,
    MODALHEADER_UPDATEOUTLET_OK_NOREVIEW,
    UNINITIALISED,
} from '../../services/Constants';
import { ParseBool, Sleep } from '../../utils/Common';
import { outletPractitionerExists } from '../../utils/AppUtils';
import { AjaxCallStateEnum, NavigationDestination } from '../../services/Enums';
import AuthService from '../../services/AuthService';
import {
    validationSchemaOutletDetails,
    VALIDATION_ERROR_POPUP_MESSAGE,
    VALIDATION_ERROR_UNKNOWN_MESSAGE,
    VALIDATION_PROMPT_NAVIGATE_AWAY_MESSAGE,
    VALIDATION_PROMPT_NAVIGATE_AWAY_TITLE,
} from '../../services/Validation';
import * as types from '../../actions/actionTypes';
import { toastErrorMessage } from '../../actions/toastrMessages';
import ApplicationActions from '../../actions/applicationActions';
import UserActions from '../../actions/userActions';
import ErrorSummary from '../../controls/ErrorSummary';
import ModalDialog from '../../controls/ModalDialog';
import Loader from '../../controls/Loader';
import PageTitle from '../../components/pageTitle';
import PageWrapper from '../../components/pageWrapper';
import AppContext from '../../stateManagement/context/AppContext';
import { AjaxCallStateForPage, EditPageProps } from '../PageInterfaces';
import { SubmitThisPageIfActive } from '../PageFunctions';
import OutletDetailsForm from './OutletDetailsForm';
import {
    EditFormStateActionTypeEnums,
    HideSaveChangesDialogEditForm,
    SetDestinationNavActionOnSuccessEditForm,
    SetIsUserInEditFormFlag,
    SetValidationSchema,
    ShowSaveChangesDialogEditForm,
} from '../manageDetails/EditFormStateManager';
import OutletRegions from './OutletRegions';
import RadioButtonGroupField, { RadioButtonOption } from 'components/fields/RadioButtonGroupField';
// below based on: import 'react-accessible-accordion/dist/fancy-example.css';
import './react-accessible-accordion.css';

interface OutletDetailsPageProps {
    authService: AuthService;
}

// see: https://github.com/react-hook-form/react-hook-form/discussions
const OutletDetailsPage = (props: OutletDetailsPageProps) => {
    const { authService } = props;

    const appContext = useContext(AppContext);
    const dispatchEditFormState = appContext.dispatchEditFormState;
    const editFormState = appContext.editFormState;
    const formRef = useRef();
    const dispatch = useDispatch();
    // store the form in state as we update any part of the outlet details
    const applicationForm = useRef(new OutletDetailsForm());

    const userActions = useMemo(() => {
        return new UserActions(authService);
    }, [authService]);
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const user: IUser = useSelector((stateUser: any) => stateUser.user);
    const application: IApplication = useSelector((stateApp: any) => stateApp.application);
    const [inquiryDefObj, setInquiry] = useState<InquiryDefaultObj>(applicationForm.current.properties.InitialData);
    const [isErrorOnSubmit, setIsErrorOnSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // even though we have redux states, we HAVE to capture the attempt due to navigation and Get and Save user calls on the page
    const [attemptingSave, setAttemptingSave] = useState(false);
    const [attemptingSavePD, setAttemptingSavePD] = useState(false);
    const ajaxCallsInProgress: types.ICallStatus = useSelector((stateAjax: any) => stateAjax.ajaxCallsInProgress);
    const initCallStateForPage: AjaxCallStateForPage = { ajaxCallState: AjaxCallStateEnum.NotStarted, pageNumber: 0 };
    const [ajaxCallStateForPage, setAjaxCallStateForPage] = useState(initCallStateForPage);
    const [backEndValidationErrors, setBackEndValidationErrors] = useState([]);
    const [showSubmittedDialogOK, setShowSubmittedDialogOK] = useState(false);
    const [modalTextUpdateOk, setModalTextUpdateOk] = useState(MODALHEADER_UPDATEOUTLET_OK_NOREVIEW);
    const [isServiceDeliveryCurrentlyFaceToFace, setIsServiceDeliveryCurrentlyFaceToFace] = useState(false);
    const [userEditedConsentFlag, setUserEditedConsentFlag] = useState(false);
    const [consentToPublishGenderOnRegister, setConsentToPublishGenderOnRegister] = useState(null);

    const practitionerObject = inquiryDefObj.inquiry && inquiryDefObj.inquiry.application && inquiryDefObj.inquiry.application.outletPractitioner;
    const showServiceCoveragePanel = practitionerObject && isServiceDeliveryCurrentlyFaceToFace === true;

    // store original values for the check upon save
    interface OrigStateValues {
        culturalText: string | undefined;
        languageText: string | undefined;
        religionText: string | undefined;
        intakeServiceNotes: string | undefined;
        sessionServiceNotes: string | undefined;
        certificateServiceNotes: string | undefined;
    }

    const initialOrigStateValues: OrigStateValues = {
        culturalText: undefined,
        languageText: undefined,
        religionText: undefined,
        intakeServiceNotes: undefined,
        sessionServiceNotes: undefined,
        certificateServiceNotes: undefined,
    };
    const [origStateValuesHaveBeenSet, setOrigStateValuesHaveBeenSet] = useState(false);
    const [origValues, setOrigValues] = useState(initialOrigStateValues);

    // used by children to set this object at parent-level
    const setInquiryObj = (inquiry: Inquiry) => {
        inquiryDefObj.inquiry = inquiry;
        setInquiry(inquiryDefObj);
    };

    // set the overall application flag to indicate user is in edit form
    useEffect(() => {
        SetIsUserInEditFormFlag(appContext);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setConsentToPublishGenderOnRegister(null);
        setUserEditedConsentFlag(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // get latest user details so we can map them to our inquiry object upon load of this page
    useEffect(() => {
        setIsLoading(true);
        dispatch(userActions.GetUser());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(appActions.GetApplication());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        SetValidationSchema(appContext, validationSchemaOutletDetails);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editFormState.validationSchema]);

    useEffect(() => {
        // if everything is loaded remove the spinner
        if (
            isLoading === true &&
            user &&
            user.id !== undefined &&
            user.id !== null &&
            application &&
            application?.applicationId !== undefined &&
            application?.applicationId !== null &&
            application?.applicationId !== UNINITIALISED
        ) {
            setIsLoading(false);
        }

        inquiryDefObj.inquiry.personalDetails = user;
        inquiryDefObj.inquiry.application = application;

        // TODO: determine if needed
        // MapComplaintsAttachmentsToComplaintsArray(inquiryDefObj, setValue, getValues);

        setInquiry(inquiryDefObj);
        // save orig values
        if (
            origStateValuesHaveBeenSet === false &&
            inquiryDefObj.inquiry.application.outletPractitioner !== undefined &&
            inquiryDefObj.inquiry.application.outletPractitioner !== null
        ) {
            const origStateValues: OrigStateValues = {
                culturalText: inquiryDefObj.inquiry.application.outletPractitioner!.cultureGroupSpecificDetail,
                languageText: inquiryDefObj.inquiry.application.outletPractitioner!.languageSpecificDetail,
                religionText: inquiryDefObj.inquiry.application.outletPractitioner!.religionSpecificDetail,
                intakeServiceNotes: inquiryDefObj.inquiry.application.outletPractitioner!.intakeServiceNotes,
                sessionServiceNotes: inquiryDefObj.inquiry.application.outletPractitioner!.sessionServiceNotes,
                certificateServiceNotes: inquiryDefObj.inquiry.application.outletPractitioner!.certificateServiceNotes,
            };
            setOrigValues(origStateValues);
            setOrigStateValuesHaveBeenSet(true);
        }

        // reset would make the values update for the useForm functionality
        reset(inquiryDefObj);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, application, inquiryDefObj]);

    const validationSchema = editFormState.validationSchema ? yupResolver(editFormState.validationSchema) : undefined;

    // watch lets us keep track of state to keep an eye on it
    // formState lets us validate
    const {
        watch,
        register,
        // unregister,
        reset,
        trigger,
        // getValues,
        setValue,
        getValues,
        setError,
        control,
        handleSubmit,
        formState: { errors, isValid, isDirty, isSubmitting, isSubmitted, isValidating, isSubmitSuccessful }, // , isValidating },
        clearErrors,
    } = useForm({
        mode: 'all',
        criteriaMode: 'all',
        defaultValues: useMemo(() => {
            return inquiryDefObj;
        }, [inquiryDefObj]),
        resolver: useMemo(() => {
            /// ERROR until FIELDS MATCH: is not assignable to type 'UnpackNestedValue<DeepPartial<TFieldValues>> | undefined'.
            return validationSchema;
        }, [validationSchema]),
    }); // mode:all is to validate after every keypress

    const pageProps: EditPageProps = {
        // application,
        // user,
        inquiry: inquiryDefObj.inquiry,
        setInquiryObj,
        attachments: inquiryDefObj.inquiry.application.attachments,
        // goingBackwards: wizardState?.goingBackwards,
        authService,
        dispatch,
        // handleClickPrevious,
        handleSubmit,
        register,
        // unregister,
        trigger,
        setValue,
        getValues,
        // setError,
        watch,
        reset,
        clearErrors,
        control,
        errors,
        isValid,
        // isActive,
        isDirty,
        isSubmitting,
        isSubmitted,
        isValidating,
        isSubmitSuccessful,
        setIsServiceDeliveryCurrentlyFaceToFace,
        // ajaxCallStateForPage,
        // backEndValidationErrors,
        // triggerSubmit: wizardState.triggerSubmit,
        // setTriggerSubmit, // use dispatcher now
    };

    const redirectToDashboard = () => {
        setShowSubmittedDialogOK(false);
        window.location.href = '/';
    };

    const redirectToManagePublicDetailsPage = () => {
        setShowSubmittedDialogOK(false);
        window.location.href = '/managepublicdetails';
    };

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors && backEndValidationErrors?.length > 0) {
            backEndValidationErrors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'InvalidName':
                        setError('inquiry.application.outletPractitioner.name', { type: 'manual', message: validationError.errorMessage });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    // Check if page is valid, and if not valid whether it is still ok to move backward (either mark completed or invalidPartial)
    const handleClickPrevious = () => {
        // Check for isDirty flag and ask user to save changes
        if (editFormState.formIsDirty || !isValid) {
            ShowSaveChangesDialogEditForm(appContext);
            SetDestinationNavActionOnSuccessEditForm(appContext, NavigationDestination.Dashboard);
        } else {
            // can move backwards - but we MUST clearErrors in case user clicks to attempt to save without the isDirty being set
            clearErrors();
            reset();
            setOrigStateValuesHaveBeenSet(false); // reset state
            redirectToManagePublicDetailsPage();
        }
    };

    // Discards changes and navigates away
    const discardChanges = () => {
        HideSaveChangesDialogEditForm(appContext);
        // setTriggerSubmit(false);
        // also clears all errors
        reset();
        setOrigStateValuesHaveBeenSet(false); // reset state
        redirectToManagePublicDetailsPage();
    };

    const setTriggerSubmit = (triggerSubmit: boolean) => {
        if (triggerSubmit) {
            dispatchEditFormState({ type: EditFormStateActionTypeEnums.setTriggerSubmit });
        } else {
            dispatchEditFormState({ type: EditFormStateActionTypeEnums.unsetTriggerSubmit });
        }
    };

    // Attempts to save changes and either navigates away (on success) or stays (when validation fails)
    const saveChanges = () => {
        HideSaveChangesDialogEditForm(appContext);
        setTriggerSubmit(true);

        if (!isValid) {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
            appContext?.setDestinationNavActionOnSuccess(null);
        } else {
            setAttemptingSave(true);
            // wait for a few seconds to allow toasty message to display upon save success
            // Sleep(2500).then(() => {
            //    redirectToDashboard();
            // });
        }
    };

    useEffect(() => {
        if (isDirty) {
            dispatchEditFormState({ type: EditFormStateActionTypeEnums.setFormIsDirty });
        } else {
            dispatchEditFormState({ type: EditFormStateActionTypeEnums.unsetFormIsDirty });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty]);

    useEffect(() => {
        if (ajaxCallsInProgress && ajaxCallsInProgress.callState) {
            setAjaxCallStateForPage({ ajaxCallState: ajaxCallsInProgress.callState, pageNumber: 0 });
        }

        if (ajaxCallsInProgress && ajaxCallsInProgress.callState === AjaxCallStateEnum.CompletedWithValidation) {
            setBackEndValidationErrors(ajaxCallsInProgress.errors);
        }

        // TODO: set Errors here:
    }, [ajaxCallsInProgress]);

    const refreshAfterChanges = () => {
        // refresh data set now after changes saved
        setIsLoading(true);
        dispatch(userActions.GetUser());
        dispatch(appActions.GetApplication());
        // allow two seconds to see save changes dialog then go back to dashboard
        Sleep(2000).then(() => {
            // pop up success dialog and then decide what flow to go to next
            setShowSubmittedDialogOK(true);
        });
    };

    // check dispatch success state
    useEffect(() => {
        if (ajaxCallStateForPage) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                setIsLoading(false);
                setAttemptingSave(false);
                setAttemptingSavePD(false);
                displayBackEndValidations();
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setIsLoading(false);
                setAttemptingSave(false);
                setAttemptingSavePD(false);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // check for save PD then save other due to which order one is set then the other
                if (attemptingSavePD === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK) {
                    setAttemptingSavePD(false);
                    refreshAfterChanges();
                }
                if (attemptingSave === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK) {
                    setAttemptingSave(false);
                    setOrigStateValuesHaveBeenSet(false); // reset state

                    if (userEditedConsentFlag === true) {
                        // update PD
                        setAttemptingSavePD(true);
                        inquiryDefObj.inquiry.personalDetails.consentToPublishGenderOnRegister = consentToPublishGenderOnRegister;
                        dispatch(userActions.SaveUserGenderConsentOnly(inquiryDefObj.inquiry.personalDetails));
                    } else {
                        refreshAfterChanges();
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const submitForm = (data: any) => {
        setIsErrorOnSubmit(false);
        const isDataValid = outletPractitionerExists(data.inquiry?.application); // bullet-proof process

        if (isValid && isDataValid === true) {
            // Save changes to application (attempt save, if that fails we do NOT move)
            setAttemptingSave(true);

            // must map boolean "is" fields for culture, language, religion as used to be check boxes now redundant we use text boxes only
            if (
                data.inquiry?.application?.outletPractitioner?.cultureGroupSpecificDetail !== undefined &&
                data.inquiry?.application?.outletPractitioner?.cultureGroupSpecificDetail !== null &&
                data.inquiry?.application?.outletPractitioner?.cultureGroupSpecificDetail.length > 0
            ) {
                data.inquiry.application.outletPractitioner.isCultureGroupSpecific = true;
            } else {
                data.inquiry.application.outletPractitioner.isCultureGroupSpecific = false;
            }
            if (
                data.inquiry?.application?.outletPractitioner?.languageSpecificDetail !== undefined &&
                data.inquiry?.application?.outletPractitioner?.languageSpecificDetail !== null &&
                data.inquiry?.application?.outletPractitioner?.languageSpecificDetail.length > 0
            ) {
                data.inquiry.application.outletPractitioner.isLanguageSpecific = true;
            } else {
                data.inquiry.application.outletPractitioner.isLanguageSpecific = false;
            }
            if (
                data.inquiry?.application?.outletPractitioner?.religionSpecificDetail !== undefined &&
                data.inquiry?.application?.outletPractitioner?.religionSpecificDetail !== null &&
                data.inquiry?.application?.outletPractitioner?.religionSpecificDetail.length > 0
            ) {
                data.inquiry.application.outletPractitioner.isReligionSpecific = true;
            } else {
                data.inquiry.application.outletPractitioner.isReligionSpecific = false;
            }

            // if there are any changes to 'religion specific or spiritual beliefs', 'language specific', 'culture group specific',
            // OR any data in Pre-mediation text, FDR session text, Issuing Certificates text,
            // then we display a different message and a task is created in the back end
            const needsReview =
                origValues &&
                data?.inquiry?.application?.outletPractitioner &&
                data.inquiry.application.outletPractitioner !== null &&
                (origValues.culturalText !== data.inquiry.application.outletPractitioner.cultureGroupSpecificDetail ||
                    origValues.languageText !== data.inquiry.application.outletPractitioner.languageSpecificDetail ||
                    origValues.religionText !== data.inquiry.application.outletPractitioner.religionSpecificDetail ||
                    origValues.intakeServiceNotes !== data.inquiry.application.outletPractitioner.intakeServiceNotes ||
                    origValues.sessionServiceNotes !== data.inquiry.application.outletPractitioner.sessionServiceNotes ||
                    origValues.certificateServiceNotes !== data.inquiry.application.outletPractitioner.certificateServiceNotes);
            setModalTextUpdateOk(needsReview ? MODALHEADER_UPDATEOUTLET_OK_NEEDSREVIEW : MODALHEADER_UPDATEOUTLET_OK_NOREVIEW);

            // store the gender consent flag or the form value will be lost
            if (userEditedConsentFlag === true) {
                setConsentToPublishGenderOnRegister(data?.inquiry?.personalDetails?.consentToPublishGenderOnRegister);
            }

            // MUST set this flag here for Outlet updates
            const updateOutlet = true;
            dispatch(appActions.UpdateApplication(data.inquiry.application, updateOutlet));
        } else {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    // need to trap when an error on submit to place cursor and expand accordions upon a submit validation error
    const errorOnSubmitForm = (errorsIn: any, event: any) => {
        if (isErrorOnSubmit === false) {
            setIsErrorOnSubmit(true);

            Sleep(300).then(() => {
                setIsErrorOnSubmit(false); // MUST set this after we've sent the flag to child components otherwise as user types we move focus on them!
            });
            Sleep(700).then(() => {
                toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
            });
        }
    };

    // re-use submit form process from Wizard, just have to set active to true and it will check the value of triggerSubmit
    SubmitThisPageIfActive(true, editFormState.triggerSubmit, formRef, setTriggerSubmit);

    const onChangeConsentToPublishGenderOnPublicRegister = (event: any) => {
        // store this state as we need to update the Personal Details as well as Application object
        setUserEditedConsentFlag(true);
        const consentToPublishGenderOnPublicRegister: boolean = ParseBool(event.target.value);
        // manage the value manually as ref did not work for the react hook form (use event.target.value to set value here)
        setValue('inquiry.personalDetails.consentToPublishGenderOnRegister', consentToPublishGenderOnPublicRegister, {
            shouldDirty: true,
            shouldValidate: false,
        });
    };

    const radioOptions: RadioButtonOption[] = [
        { label: 'Yes', value: true, disabled: false },
        { label: 'No', value: false, disabled: false },
    ];

    const pageContent = () => {
        return (
            <>
                <ErrorSummary errors={errors} />
                <RadioButtonGroupField
                    options={radioOptions}
                    id='inquiryDefObj.inquiry.personalDetails.consentToPublishGenderOnRegister'
                    displayName='Consent to display gender'
                    fieldLabel='I consent to my gender appearing on the public register'
                    defaultValue={inquiryDefObj.inquiry.personalDetails.consentToPublishGenderOnRegister} // only set default if value exists
                    isMandatory={false}
                    control={control}
                    register={register}
                    helpHeaderText={HELPHEADERTEXT_GENDERONPUBLICREGISTER}
                    helpText={HELPTEXT_GENDERONPUBLICREGISTER}
                    errorsField={null} // {errors.inquiry?.personalDetails?.consentToPublishGenderOnRegister}
                    onChange={onChangeConsentToPublishGenderOnPublicRegister}
                />
                <OutletRegions
                    practitionerObject={practitionerObject}
                    showServiceCoveragePanel={showServiceCoveragePanel}
                    pageProps={pageProps}
                    isErrorOnSubmit={isErrorOnSubmit}
                />
            </>
        );
    };

    return (
        <>
            <PageTitle title='Outlet details' description={HEADER_EDIT_OUTLETDETAILS} />
            <PageWrapper
                pageName='OutletDetails'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, errorOnSubmitForm)}
                handleClickPrevious={handleClickPrevious}
            >
                <Loader isLoading={attemptingSave === true} loaderText='Submitting your changes. Please wait...' />
                <ModalDialog
                    dataTestId='SaveChangesDialog'
                    modalTitle={VALIDATION_PROMPT_NAVIGATE_AWAY_TITLE}
                    modalBodyText={VALIDATION_PROMPT_NAVIGATE_AWAY_MESSAGE}
                    showMe={editFormState.showSaveChangesDialog}
                    handleClickNo={discardChanges}
                    handleClickYes={saveChanges}
                />
                <ModalDialog
                    dataTestId='DiscardChangesDialog'
                    modalTitle={MODALHEADER_UPDATEOUTLET_OK}
                    modalBodyText={modalTextUpdateOk}
                    showMe={showSubmittedDialogOK}
                    showOkOnly={true}
                    handleClickOk={redirectToDashboard}
                />
                {isLoading === true && <Loader isLoading={true} loaderText='Loading your information...' />}
                {isLoading === false && pageContent()}
            </PageWrapper>
        </>
    );
};

export default OutletDetailsPage;
