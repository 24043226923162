import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { EmptyRow } from './EmptyRow';
import style from './Controls.module.scss';

const { dropZoneContainer, dropZone, disabled, dropZoneInfo, dropZoneSelectButton, dropZoneOrLabel } = style;

export interface DropzoneProps {
    id: string;
    uniqueIdForButton: string;
    enabled: boolean;
    acceptedFilesDropped: any;
    rejectedFilesDropped?: any;
    maxFilesCount: number;
    showDisclaimerText?: boolean;
}

// Allow multiple files up to 20 Mb and only JPG, PDF, DOCX.
export const DropZone = (props: DropzoneProps) => {
    const { id, uniqueIdForButton, enabled, maxFilesCount, showDisclaimerText } = props;

    const acceptedFilesCollection = 'image/jpeg,.pdf,.docx';
    const maxSizeInBytes = 10485760; // 10 Mb // 20971520 = 20 Mb

    const onDropAccepted = useCallback((acceptedFiles: any) => {
        // Do something with the files
        props.acceptedFilesDropped(acceptedFiles);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDropRejected = useCallback((rejectedFiles: any) => {
        props.rejectedFilesDropped(rejectedFiles);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getRootProps, getInputProps, open } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        maxFiles: maxFilesCount,
        maxSize: maxSizeInBytes,
        multiple: false, // allows only one file selection at a time
        accept: acceptedFilesCollection,
        onDropAccepted,
        onDropRejected,
    });

    const selectFileText = 'Select a file using the Select File button, or use your mouse to drag and drop a file into the box provided.';
    const pluralText = maxFilesCount === 1 ? 'file' : 'files';
    const maxFileText = `Only ${maxFilesCount} ${pluralText} of type *.docx, *.pdf, *.jpg will be accepted - maximum file size 10 MB.`;
    const disclaimerText =
        'By uploading a document on this page, you are declaring that it is valid and that you consent for its contents to be verified with the issuing authority.';

    return (
        <div className={dropZoneContainer}>
            <div className={dropZoneInfo}>
                <ul>
                    <li>{selectFileText}</li>
                    <li>{maxFileText}</li>
                </ul>
            </div>
            {showDisclaimerText === true && (
                <div>
                    <b>Disclaimer: </b>
                    {disclaimerText}
                </div>
            )}
            <div data-testid={uniqueIdForButton}>
                <EmptyRow />
                <Row className={dropZoneSelectButton}>
                    <Col>
                        <button id={`${id}.${uniqueIdForButton}`} className='btn btn-primary' type='button' onClick={open} disabled={!enabled}>
                            Select File to Upload
                        </button>
                    </Col>
                    <Col />
                </Row>
                <Row className={dropZoneOrLabel}>
                    <Col>
                        <p>OR</p>
                    </Col>
                    <Col />
                </Row>
            </div>
            <div className={`${dropZone} ${enabled ? '' : disabled}`} {...getRootProps()}>
                {enabled && <input {...getInputProps()} id={`${id}.${uniqueIdForButton}`} />}
                <Row>
                    <Col>
                        <label htmlFor={`${id}.${uniqueIdForButton}`}>Drag and drop your files here</label>
                    </Col>
                    <Col />
                </Row>
            </div>
        </div>
    );
};

export default DropZone;
