import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import AppContext from '../stateManagement/context/AppContext';
import { DisplayFieldValueOrDefaultText } from '../utils/AppUtils';
import { WizardMode, AddressTypeEnum, WizardStepNumber } from '../services/Enums';
import { IAddress, Inquiry } from '../api/interfacesApi';
import LabelField from '../components/fields/LabelField';
import PageFieldsTitle from '../components/fields/PageFieldsTitle';
import { EmptyRow } from '../controls/EmptyRow';

interface PersonalDetailsContactDisplayProps {
    inquiry: Inquiry;
    formatForPDF: boolean;
    hidePageFieldsTitle?: boolean;
}

const PersonalDetailsContactDisplay = (props: PersonalDetailsContactDisplayProps) => {
    const { inquiry, formatForPDF, hidePageFieldsTitle } = props;

    const appContext = useContext(AppContext);
    const showLinkToPage = formatForPDF === true ? false : appContext.wizardState.wizardMode === WizardMode.New ? true : false;
    /* if (
        inquiry.personalDetails?.addresses &&
        inquiry.personalDetails.addresses.length > 0 &&
        inquiry.personalDetails.addresses[0].addressType === AddressTypeEnum.Postal
    ) {
        // need to place business address before postal
        inquiry.personalDetails.addresses.sort((a, b) => (a.addressType === AddressTypeEnum.Postal ? 1 : -1));
    } */

    const numberOfAddresses = inquiry.personalDetails?.addresses?.length;
    // take the number and create an array of indexes such as [0,1,2]
    const indexes = Array.from({ length: numberOfAddresses }, (_, i) => i);

    return (
        <>
            {hidePageFieldsTitle !== true && (
                <PageFieldsTitle
                    title='Contact details'
                    showLinkToPage={showLinkToPage}
                    wizardStepNumber={WizardStepNumber.PersonalDetailsContact}
                    smallerHeader={true}
                    underlineTitle={formatForPDF}
                />
            )}
            {indexes.map(index => {
                const fieldName = `inquiry.personalDetails.addresses[${index}]`;
                // get the item using [index] as filter() returns an array of n items!
                const currItem: IAddress = inquiry.personalDetails.addresses[index];
                const isPostalAddress = currItem && currItem.addressType && currItem.addressType === AddressTypeEnum.Postal;
                const headerPrefix = isPostalAddress ? 'Postal' : 'Residential';

                return (
                    <div id={fieldName} key={fieldName}>
                        <Row>
                            <Col>
                                <label>
                                    <h3>{headerPrefix} address</h3>
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <LabelField
                                    id={`${fieldName}.streetAddress`}
                                    displayName={`${isPostalAddress ? 'Street address/PO Box number' : 'Street address'}`}
                                    value={currItem.streetAddress}
                                    isMandatory={false}
                                    textCapitalizeOn={true}
                                    isTitleHtag={formatForPDF}
                                />
                            </Col>
                        </Row>
                        {formatForPDF === true ? <EmptyRow /> : null}
                        <Row>
                            <Col>
                                <LabelField
                                    id={`${fieldName}.town`}
                                    displayName='Suburb'
                                    value={currItem.town}
                                    isMandatory={false}
                                    textCapitalizeOn={true}
                                    isTitleHtag={formatForPDF}
                                />
                            </Col>
                        </Row>
                        {formatForPDF === true ? <EmptyRow /> : null}
                        <Row>
                            <Col>
                                <LabelField
                                    id={`${fieldName}.state`}
                                    displayName='State'
                                    value={currItem.state}
                                    isMandatory={false}
                                    isTitleHtag={formatForPDF}
                                />
                            </Col>
                        </Row>
                        {formatForPDF === true ? <EmptyRow /> : null}
                        <Row>
                            <Col>
                                <LabelField
                                    id={`${fieldName}.postcode`}
                                    displayName='Postcode'
                                    value={currItem.postcode}
                                    isMandatory={false}
                                    isTitleHtag={formatForPDF}
                                />
                            </Col>
                        </Row>
                        {formatForPDF === true ? <EmptyRow /> : null}
                    </div>
                );
            })}
            <EmptyRow />
            <Row>
                <Col>
                    <LabelField
                        id='reviewPersonalContactPrimaryemail'
                        displayName='Primary email'
                        value={inquiry.personalDetails.emailAddress}
                        isMandatory={false}
                        isTitleHtag={formatForPDF}
                    />
                </Col>
            </Row>
            {formatForPDF === true ? <EmptyRow /> : null}
            <Row>
                <Col>
                    <LabelField
                        id='reviewPersonalContactSecondaryEmailAddress'
                        displayName='Secondary email'
                        value={DisplayFieldValueOrDefaultText(inquiry.personalDetails.secondaryEmailAddress)}
                        isMandatory={false}
                        isTitleHtag={formatForPDF}
                    />
                </Col>
            </Row>
            {formatForPDF === true ? <EmptyRow /> : null}
            <Row>
                <Col>
                    <LabelField
                        id='reviewPersonalContactPrimaryPhoneNumber'
                        displayName='Primary phone'
                        value={inquiry.personalDetails.primaryPhoneNumber}
                        isMandatory={false}
                        isTitleHtag={formatForPDF}
                    />
                </Col>
            </Row>
            {formatForPDF === true ? <EmptyRow /> : null}
            <Row>
                <Col>
                    <LabelField
                        id='reviewPersonalContactSecondaryphone'
                        displayName='Secondary phone'
                        value={DisplayFieldValueOrDefaultText(inquiry.personalDetails.secondaryPhoneNumber)}
                        isMandatory={false}
                        isTitleHtag={formatForPDF}
                    />
                </Col>
            </Row>
            <EmptyRow />
        </>
    );
};

export default PersonalDetailsContactDisplay;
