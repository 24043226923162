import 'whatwg-fetch';
import ApiService from './commonApi';
import AuthService from '../services/AuthService';
import { IOutlet } from './interfacesApi';

class OutletApi {
    apiService: ApiService;

    constructor(authenticationService: AuthService) {
        this.apiService = new ApiService(authenticationService);
    }

    // API call - get Outlets
    async getAllOutlets() {
        const request = this.apiService.setRequest('api/outlets', 'GET', null);
        return this.apiService.makeFetchRequest<IOutlet[]>(request);
    }
}

export default OutletApi;
