import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import style from './Controls.module.scss';
import { userCheckIcon, bombIcon, closeIcon, newFolderIcon, skullCrossbonesIcon, spinnerIcon } from '../utils/Icons';

const {
    colNoPadding,
    devilsToolboxTitleRow,
    devilsToolboxSubtitleRow,
    devilsToolboxButtonStyle,
    modalContainer,
    modalContainerWide,
    modalContents,
    modalHeader,
    modalBody,
    modalBodyTextLeft,
    // modalBodyTextCenter,
    // modalYesButtonPrimary,
    // modalYesButtonDestructive,
    // modalNoButton,
    warningSignConfirmable,
} = style;

interface ModalDialogDevilsToolboxProps {
    isPre: boolean;
    isDataLoaded: boolean;
    children: any;
    handleAssignToMeButtonClick: any;
    handleDeleteAllMyDataButtonClick: any;
    handleStartAnotherAppButtonClick: any;
    handleCloseClick: any;
}

/* Control that handles children component(s) and deliberate Devils Toolbox buttons */
const ModalDialogDevilsToolbox = (props: ModalDialogDevilsToolboxProps) => {
    const { isPre, isDataLoaded, children, handleAssignToMeButtonClick, handleDeleteAllMyDataButtonClick, handleStartAnotherAppButtonClick, handleCloseClick } =
        props;

    const enableEscape = true;

    const ActionButtons = () => {
        return (
            <Row>
                {isPre !== true && (
                    <Col className={colNoPadding}>
                        <Button variant='outline-secondary' className={`btn ${devilsToolboxButtonStyle}`} onClick={handleAssignToMeButtonClick}>
                            {userCheckIcon()} Assign to me
                        </Button>
                    </Col>
                )}
                <Col className={colNoPadding}>
                    <Button
                        variant='outline-secondary'
                        id='dangerouslydeleteallmydatabutton'
                        className={`btn ${devilsToolboxButtonStyle}`}
                        onClick={handleDeleteAllMyDataButtonClick}
                    >
                        {bombIcon()} Delete all MY data!
                    </Button>
                </Col>
                {isPre !== true && (
                    <Col className={colNoPadding}>
                        <Button variant='outline-secondary' className={`btn ${devilsToolboxButtonStyle}`} onClick={handleStartAnotherAppButtonClick}>
                            {newFolderIcon()} Start another application
                        </Button>
                    </Col>
                )}
                <Col className={colNoPadding}>
                    <Button variant='outline-secondary' id='devilstoolboxclosebutton' className={`btn ${devilsToolboxButtonStyle}`} onClick={handleCloseClick}>
                        {closeIcon()} Close
                    </Button>
                </Col>
            </Row>
        );
    };

    return (
        <div className='static-modal'>
            <Modal
                animation={false}
                show={true} // handle visibility outside of this control
                // dismiss={dismiss}
                // onHide={handleHide}
                backdrop={enableEscape ? true : 'static'}
                keyboard={enableEscape}
                className={`${modalContainer} ${modalContainerWide}`}
                data-testid='ModalDialogDevilsToolbox'
            >
                <div className={`m-0 p-4 text-center ${modalContents}`}>
                    <Modal.Header closeButton={false} className={modalHeader}>
                        <Modal.Title>
                            <Row className={devilsToolboxTitleRow}>
                                <Col md={1}>
                                    <div className={`${warningSignConfirmable} alert-danger rounded-circle`}>{skullCrossbonesIcon()}</div>
                                </Col>
                                <Col md={10}>
                                    <h1>YE DEVILS TOOLBOX</h1>
                                </Col>
                                <Col md={1}>
                                    <div className={`${warningSignConfirmable} alert-danger rounded-circle`}>{skullCrossbonesIcon()}</div>
                                </Col>
                            </Row>
                            <Row className={devilsToolboxSubtitleRow}>
                                <Col>BEWARE YE THE PERIL WITHIN!!</Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>
                    {isDataLoaded === false && (
                        <Modal.Body className={`${modalBody}`}>
                            {spinnerIcon()}
                            <p>Loading...please wait.</p>
                        </Modal.Body>
                    )}
                    {isDataLoaded === true && children && <Modal.Body className={`${modalBody} ${modalBodyTextLeft}`}>{children}</Modal.Body>}
                    <Modal.Footer>
                        <div className='container'>{ActionButtons()}</div>
                    </Modal.Footer>
                </div>
            </Modal>
        </div>
    );
};

export default ModalDialogDevilsToolbox;
