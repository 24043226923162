import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Inquiry } from '../../../api/interfacesApi';
import AppContext from '../../../stateManagement/context/AppContext';
import { AttachmentDocumentType, WizardMode, WizardStepNumber } from '../../../services/Enums';
import { DisplayBool, ParseBool } from '../../../utils/Common';
import { NOTE_CRIMINAL_MESSAGE, NOTE_CRIMINAL_MESSAGE_P2 } from '../../../services/Constants';
import LabelField from '../../../components/fields/LabelField';
import NoteText from '../../../controls/NoteText';
import { EmptyRow } from '../../../controls/EmptyRow';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import FileUploadDisplay from './fileUploadDisplay';
import styles from '../../Pages.module.scss';

interface CriminalConvictionsDisplayProps {
    inquiry: Inquiry;
    formatForPDF: boolean;
}

const { convictionsWarningNote } = styles;

const CriminalConvictionsDisplay = (props: CriminalConvictionsDisplayProps) => {
    const { inquiry, formatForPDF } = props;

    const appContext = useContext(AppContext);
    const showLinkToPage = formatForPDF === true ? false : appContext.wizardState.wizardMode === WizardMode.New ? true : false;

    // show note
    const convictionsWarningNoteDisplay = () => {
        return (
            <>
                <Row className={convictionsWarningNote}>
                    <Col md='11'>
                        <NoteText displayText={NOTE_CRIMINAL_MESSAGE} displayTextP2={NOTE_CRIMINAL_MESSAGE_P2} formatForPDF={formatForPDF} />
                    </Col>
                    <Col md='1' className='justify-content-end' />
                </Row>
                <EmptyRow />
            </>
        );
    };

    return (
        <>
            <PageFieldsTitle
                title='Criminal convictions'
                showLinkToPage={showLinkToPage}
                wizardStepNumber={WizardStepNumber.CriminalConvictions}
                smallerHeader={true}
                underlineTitle={formatForPDF}
            />
            <Row>
                <Col>
                    <LabelField
                        id='reviewHasOffenceConvictions'
                        displayName='Have you been convicted of an offence involving violence to a person or a sex-related offence?'
                        value={DisplayBool(inquiry.application.hasOffenceConvictions)}
                        isMandatory={false}
                        isTitleHtag={formatForPDF}
                    />
                </Col>
            </Row>
            {formatForPDF === true ? <EmptyRow /> : null}
            <FileUploadDisplay
                inquiry={inquiry}
                showThisDocType={AttachmentDocumentType.NationalPoliceCheck}
                title='National Police Check'
                formatForPDF={formatForPDF}
            />
            {ParseBool(inquiry.application.hasOffenceConvictions) === true && convictionsWarningNoteDisplay()}
        </>
    );
};

export default CriminalConvictionsDisplay;
