// Outlet Details page
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { IOutlet } from '../../api/interfacesApi';
import { Sleep } from '../../utils/Common';
import { angleDownIcon, angleUpIcon } from '../../utils/Icons';
import { EditPageProps } from '../../pages/PageInterfaces';
import NavLink from '../../controls/NavLink';
import Loader from '../../controls/Loader';
import { EmptyRow } from '../../controls/EmptyRow';
import OutletContactDetailsPage from './OutletContactDetails';
import OutletTypeOfServicePage from './OutletTypeOfService';
import OutletOpeningHoursPage from './OutletOpeningHours';
import OutletServiceFeesPage from './OutletServiceFees';
import OutletServiceCoveragePage from './OutletServiceCoverage';

// below based on: import 'react-accessible-accordion/dist/fancy-example.css';
import './react-accessible-accordion.css';

interface RegionStateItem {
    uuid: number;
    tabName: string;
    isExpanded: boolean;
    isCollapsible: boolean;
}

interface OutletRegionsProps {
    practitionerObject: IOutlet | null;
    showServiceCoveragePanel: boolean | null;
    pageProps: EditPageProps;
    isErrorOnSubmit: boolean;
}

const OutletRegions = (props: OutletRegionsProps) => {
    const { practitionerObject, showServiceCoveragePanel, pageProps, isErrorOnSubmit } = props;

    const [regionState, setRegionState] = useState<RegionStateItem[]>([]);
    const [isRegionStateLoaded, setIsRegionStateLoaded] = useState<boolean>(false);
    const [isExpandAll, setExpandAll] = useState<boolean | undefined>(false);
    const [accordionKey, setAccordionKey] = useState(0);

    const refreshThisUi = () => {
        setAccordionKey(prev => prev + 1); // to provoke UI change
    };

    const ToggleAllPanels = (expandAll: boolean) => {
        // setCurrentlyTogglingExpand(true);

        setExpandAll(expandAll);

        // give this a half second to process otherwise infinite loop
        Sleep(400).then(() => {
            // setCurrentlyTogglingExpand(false);
            refreshThisUi();
        });
    };

    const handleToggleExpandAll = () => {
        setIsRegionStateLoaded(false);
        ToggleAllPanels(!isExpandAll);
    };

    // if form is invalid we expand all so cursor can be placed in error zone for usability
    useEffect(() => {
        if (isErrorOnSubmit && pageProps.errors !== null && pageProps.errors.inquiry && pageProps.errors.inquiry !== null) {
            // show all panels
            if (isExpandAll !== true) {
                ToggleAllPanels(true);
            }

            Sleep(500).then(() => {
                // scroll to position of first error (if found) - as auto did not work for yup for the check boxes
                const elementFound = document.querySelectorAll('[role="alert"]:first-of-type')[0];
                // tslint:disable-next-line: no-unused-expression
                elementFound && elementFound.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            });

            // in a second we reset the expandAll param back to false
            // Sleep(1000).then(() => setPanelState({ ...panelState, expandAll: false }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageProps]);

    /*
    if (pageProps.errors !== null && pageProps.errors.inquiry && pageProps.errors.inquiry !== null && !pageProps.isValid && !currentlyTogglingExpand) {
        // && panelState.expandAll === false) {

        // show all panels
        if (isExpandAll !== true) {
            ToggleAllPanels(true);
        }

        Sleep(500).then(() => {
            // scroll to position of first error (if found) - as auto did not work for yup for the check boxes
            const elementFound = document.querySelectorAll('[role="alert"]:first-of-type')[0];
            // tslint:disable-next-line: no-unused-expression
            elementFound && elementFound.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        });

        // in a second we reset the expandAll param back to false
        // Sleep(1000).then(() => setPanelState({ ...panelState, expandAll: false }));
    } */

    const getTabRegionStateItem = (tabNameIn: string) => {
        return regionState.find(({ tabName }) => tabName === tabNameIn);
    };

    useEffect(() => {
        if (regionState.length === 0) {
            // View mode: all are collapsed by default and all are collapsible
            regionState.push({ uuid: 1, tabName: 'outletContactDetails', isExpanded: false, isCollapsible: true });
            regionState.push({ uuid: 2, tabName: 'typesOfServices', isExpanded: false, isCollapsible: true });
            regionState.push({ uuid: 3, tabName: 'openingHours', isExpanded: false, isCollapsible: true });
            regionState.push({ uuid: 4, tabName: 'serviceCoverage', isExpanded: false, isCollapsible: true });
            regionState.push({ uuid: 5, tabName: 'serviceFees', isExpanded: false, isCollapsible: true });

            setRegionState(regionState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isExpandAll === true) {
            getTabRegionStateItem('outletContactDetails')!.isExpanded = true;
            getTabRegionStateItem('typesOfServices')!.isExpanded = true;
            getTabRegionStateItem('openingHours')!.isExpanded = true;
            getTabRegionStateItem('serviceCoverage')!.isExpanded = true;
            getTabRegionStateItem('serviceFees')!.isExpanded = true;
        } else {
            getTabRegionStateItem('outletContactDetails')!.isExpanded = false;
            getTabRegionStateItem('typesOfServices')!.isExpanded = false;
            getTabRegionStateItem('openingHours')!.isExpanded = false;
            getTabRegionStateItem('serviceCoverage')!.isExpanded = false;
            getTabRegionStateItem('serviceFees')!.isExpanded = false;
        }

        setRegionState(regionState);

        // DEV NOTE: everything loads and behaves with this delay, if we try to get
        // fancy with the accordion click events they do not recover nor load properly
        // (when open then closed)
        Sleep(2500).then(() => {
            setIsRegionStateLoaded(true);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandAll]);

    const handleOnChange = (uuids: string[]) => {
        // Accordion - onChange() - Callback which is invoked when items are expanded or collapsed. Gets passed uuids of the currently expanded AccordionItems.
        // setAllExpanded(undefined);
        // Accordion - preExpanded: string[] [optional, default: []]
        // Accepts an array of strings and any AccordionItem whose uuid prop matches any one of these strings will be expanded on mount.
    };

    // use this technique to be able to pre-load the accordion state with expanded items by default
    if (isRegionStateLoaded === false) {
        // when not complete return a placeholder
        return <Loader isLoading={true} loaderText='Loading your information...' />;
    }

    return (
        <>
            <Row>
                <Col md={10} />
                <Col md={1}>
                    <button
                        type='button'
                        data-testid='expandAllOutletButton'
                        className='btn btn-light rounded-pill w-33 text-left font-weight-bold py-1'
                        style={{ fontVariant: 'normal' }}
                    >
                        {isExpandAll === true ? angleUpIcon() : angleDownIcon()}
                        &nbsp;&nbsp;
                        <NavLink
                            hrefLink='#'
                            displayText={isExpandAll === true ? 'Collapse all' : 'Expand all'}
                            className=''
                            handleOnClick={handleToggleExpandAll}
                        />
                    </button>
                </Col>
            </Row>
            <EmptyRow />
            <Accordion
                key={accordionKey}
                data-testid='outletdetailsaccordion'
                allowMultipleExpanded={true}
                allowZeroExpanded={true}
                onChange={handleOnChange}
                preExpanded={regionState.map((item, _index) => (item.isExpanded === true ? item.uuid : ''))}
            >
                <AccordionItem
                    key='outletcontactdetails'
                    data-testid='outletcontactdetailsaccordion'
                    uuid={getTabRegionStateItem('outletContactDetails')!.uuid}
                >
                    <AccordionItemHeading>
                        <AccordionItemButton>Outlet contact details</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>{practitionerObject && <OutletContactDetailsPage {...pageProps} />}</AccordionItemPanel>
                </AccordionItem>
                <AccordionItem key='outlettypesofservices' data-testid='outlettypesofservicesaccordion' uuid={getTabRegionStateItem('typesOfServices')!.uuid}>
                    <AccordionItemHeading>
                        <AccordionItemButton>Types of services</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>{practitionerObject && <OutletTypeOfServicePage {...pageProps} />}</AccordionItemPanel>
                </AccordionItem>
                <AccordionItem key='outletopeninghours' data-testid='outletopeninghoursaccordion' uuid={getTabRegionStateItem('openingHours')!.uuid}>
                    <AccordionItemHeading>
                        <AccordionItemButton>Opening hours</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>{practitionerObject && <OutletOpeningHoursPage {...pageProps} />}</AccordionItemPanel>
                </AccordionItem>
                {showServiceCoveragePanel === true && (
                    <AccordionItem
                        key='outletservicecoverage'
                        data-testid='outletservicecoverageaccordion'
                        uuid={getTabRegionStateItem('serviceCoverage')!.uuid}
                    >
                        <AccordionItemHeading>
                            <AccordionItemButton>Service coverage</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>{practitionerObject && <OutletServiceCoveragePage {...pageProps} />}</AccordionItemPanel>
                    </AccordionItem>
                )}
                <AccordionItem key='outletservicefees' data-testid='outletservicefeesaccordion' uuid={getTabRegionStateItem('serviceFees')!.uuid}>
                    <AccordionItemHeading>
                        <AccordionItemButton>Service fees</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>{practitionerObject && <OutletServiceFeesPage {...pageProps} />}</AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </>
    );
};

export default OutletRegions;
