import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { IAttachmentMetadata, Inquiry } from '../../../api/interfacesApi';
import { AttachmentDocumentType } from '../../../services/Enums';
import FileListDisplay from '../../../components/fields/FileUploadField/FileListDisplay';
import styles from '../../../components/fields/Fields.module.scss';
import { EmptyRow } from 'controls/EmptyRow';

const { fieldTitle, fieldFinalRow } = styles;

export interface FileUploadDisplayProps {
    inquiry: Inquiry;
    showThisDocType?: AttachmentDocumentType;
    title: string;
    attachmentIdToShow?: string | null; // for Complaints
    formatForPDF?: boolean;
    overrideToShowAllDocumentTypes?: boolean;
    showDateOnFileList?: boolean;
}

const FileUploadDisplay = (props: FileUploadDisplayProps) => {
    const { inquiry, showThisDocType, title, attachmentIdToShow, formatForPDF, overrideToShowAllDocumentTypes, showDateOnFileList } = props;

    // if there are no documents of the type expected then return empty
    const foundAttachments: IAttachmentMetadata[] = inquiry.application?.attachments?.filter((item: IAttachmentMetadata) => {
        if (attachmentIdToShow && item.attachmentId === attachmentIdToShow) {
            return item;
        } else if (!attachmentIdToShow && (overrideToShowAllDocumentTypes === true || item.documentType === showThisDocType)) {
            return item;
        } else {
            return null;
        }
    });

    if (foundAttachments === undefined || foundAttachments === null || foundAttachments.length < 1) {
        return null;
    }

    return (
        <>
            {formatForPDF === true && <EmptyRow />}
            <Row style={{ marginBottom: '0.75rem' }}>
                <Col>
                    {formatForPDF === true ? (
                        <i>
                            <label className={fieldTitle}>{title}</label>
                        </i>
                    ) : (
                        <label className={fieldTitle}>{title}</label>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <FileListDisplay fileMetadataList={foundAttachments} formatForPDF={formatForPDF} showDateOnFileList={showDateOnFileList} />
                </Col>
            </Row>
            <Row className={fieldFinalRow}>
                <Col />
            </Row>
            {formatForPDF === true && <EmptyRow />}
        </>
    );
};

export default FileUploadDisplay;
