import { combineReducers } from 'redux';
import user from './userReducer';
import application from './applicationReducer';
import applications from './applicationsReducer';
import complaintsMechanism from './complaintsMechanismReducer';
import applicationSubmit from './applicationSubmitReducer';
import attachmentsTypeArray from './attachmentsReducer';
import differencesUser from './differencesUserReducer';
import differencesComplaintsMechanism from './differencesComplaintsMechReducer';
import ajaxCallsInProgress from './ajaxStatusReducer';

export default combineReducers({
    user,
    application,
    applications,
    complaintsMechanism,
    applicationSubmit,
    attachmentsTypeArray,
    differencesUser,
    differencesComplaintsMechanism,
    ajaxCallsInProgress,
});
