import * as React from 'react';
import NavLink from './NavLink';

export interface PillButtonProps {
    hrefLink: string;
    displayText: string;
    handleClickEvent: any;
    children: any;
    dataTestId?: string;
}

const PillButton = (props: PillButtonProps) => {
    const { hrefLink, displayText, handleClickEvent, children, dataTestId } = props;

    const buttonClassModded = `btn btn-light rounded-pill w-33 text-left font-weight-bold py-1 mt-5`;

    return (
        <>
            <NavLink hrefLink={hrefLink} displayText={displayText} className='' handleOnClick={handleClickEvent} dataTestId={dataTestId}>
                <button type='button' className={buttonClassModded} style={{ fontVariant: 'normal' }}>
                    {children}
                </button>
            </NavLink>
        </>
    );
};

export default PillButton;
