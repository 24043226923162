import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';
// tslint:disable-next-line: no-submodule-imports
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(thunk),
        //    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        //    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
        // other store enhancers if any
    ),
);

export default function configureStore() {
    return store;
}
