import React from 'react';
import NavLink from '../../controls/NavLink';
import styles from './site.module.scss';

const { siteMenuItems } = styles;

const SiteMenuItems = () => {
    return (
        <>
            <ul className={`${siteMenuItems} navbar-nav mr-auto ml-auto`} id='main-nav'>
                <li className='nav-item'>
                    <NavLink className='nav-link' hrefLink='/home' openInNewTab={false} displayText='Home' dataTestId='SiteMenuHomeLink' />
                </li>
                <li>
                    <NavLink className='nav-link' hrefLink='/about' openInNewTab={false} displayText='About' dataTestId='SiteMenuAboutLink' />
                </li>
                <li>
                    <NavLink className='nav-link' hrefLink='/logout' openInNewTab={false} displayText='Log Out' dataTestId='SiteMenuLogOutLink' />
                </li>
                <li>
                    <NavLink
                        className='nav-link'
                        hrefLink={window.__RUNTIME_CONFIG__.REACT_APP_REGISTER_URL ?? 'https://fdrr.ag.gov.au/'}
                        openInNewTab={true}
                        displayText='View the FDR Register'
                        dataTestId='SiteMenuViewRegisterLink'
                    />
                </li>
            </ul>
        </>
    );
};

export default SiteMenuItems;
