// Past Application History page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the Personal details-Contact page
//  - allows navigation forward to the Competencies and Qualifications page
import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { RegoApplicationPageProps } from '../../PageInterfaces';
import { toastErrorMessage } from '../../../actions/toastrMessages';
import ApplicationActions from '../../../actions/applicationActions';
import { ParseBool } from '../../../utils/Common';
import Guid from '../../../services/Guid';
import { AjaxCallStateEnum, AttachmentDocumentType, QualificationType, WizardStepNumber } from '../../../services/Enums';
import { SubmitThisPageIfActive } from '../../PageFunctions';
import { EnsureData } from '../RegistrationApplicationWizard/wizardHelperFunctions';
import { MoveWizardForward } from '../../../components/wizard/wizardStepFunctions';
import AttachmentActions from '../../../actions/attachmentsActions';
import ErrorSummary from '../../../controls/ErrorSummary';
import { HEADER_PASTAPPLICATIONHISTORY, PLACEHOLDER_PREVIOUS_SUBMISSION_NUMBER } from '../../../services/Constants';
import { VALIDATION_ERROR_POPUP_MESSAGE, VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../../services/Validation';
import AppContext from '../../../stateManagement/context/AppContext';
import PageWrapper from '../../../components/pageWrapper';
import PageTitle from '../../../components/pageTitle';
import PageMandatoryLabelText from '../../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import InputTextField from '../../../components/fields/InputTextField';
import RadioButtonGroupField, { RadioButtonOption } from '../../../components/fields/RadioButtonGroupField';
import { ErrorOnSubmitForm } from '../../../utils/AppUtils';

const PastApplicationHistoryPage = (props: RegoApplicationPageProps) => {
    const {
        inquiry,
        authService,
        dispatch,
        handleClickPrevious,
        handleSubmit,
        // clearErrors,
        register,
        setValue,
        setError,
        watch,
        // trigger,
        control,
        errors,
        isValid,
        isActive,
        ajaxCallStateForPage,
        backEndValidationErrors,
        triggerSubmit,
        setTriggerSubmit,
    } = props;

    const appContext = useContext(AppContext);
    const formRef = useRef();
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const attachmentActions = useMemo(() => {
        return new AttachmentActions(authService);
    }, [authService]);
    // even though we have redux states, we HAVE to capture the attempt due to navigation and Get and Save user calls on the page
    const [attemptingSave, setAttemptingSave] = useState(false);

    const applicationId = watch('inquiry.application.applicationId', inquiry?.application.applicationId);
    const hasPreviouslyApplied: boolean = watch('inquiry.application.hasPreviouslyApplied', inquiry?.application.hasPreviouslyApplied);
    const [showPreviousApplicationIdentifier, setShowPreviousApplicationIdentifier] = useState(false);

    const onChangeHasPreviouslyApplied = (event: { target: { value: any } }) => {
        const hasApplied: boolean = ParseBool(event.target.value);

        // if user has previously entered Three Units on Competencies page (in future steps) then selects a NO here,
        // we need to delete that data as the business rule does not allow that data under a NO selection
        if (hasApplied === false && inquiry.application.qualification === QualificationType.ThreeSpecifiedUnits) {
            // remove that future-step data
            dispatch(attachmentActions.RemoveAttachmentsByType(AttachmentDocumentType.ThreeUnits));
            setValue('inquiry.application.attachments.sixunitsevidence', null);
            setValue('inquiry.application.qualification', null);
        }

        // manage the value manually as ref did not work for the react hook form (use event.target.value to set value here)
        setValue('inquiry.application.hasPreviouslyApplied', event.target.value, { shouldDirty: true, shouldValidate: true });
    };

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors.errors?.length > 0) {
            backEndValidationErrors.errors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'PreviousApplicationIdentifier':
                        setError('inquiry.application.previousApplicationIdentifier', { type: 'manual', message: validationError.errorMessage });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    // check dispatch success state
    useEffect(() => {
        if (isActive === true && ajaxCallStateForPage && ajaxCallStateForPage.pageNumber === WizardStepNumber.PastApplicationHistory) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                setAttemptingSave(false);
                displayBackEndValidations();
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setAttemptingSave(false);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (attemptingSave === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK && !appContext.wizardState.triggerSubmit) {
                    // IF VALID (or OK to move forward):
                    setAttemptingSave(false);
                    // refresh data set now after changes saved
                    dispatch(appActions.GetApplication());
                    MoveWizardForward(appContext, isValid);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const submitForm = (data: any) => {
        if (isValid) {
            // this may be existing or new record at this point
            const guid = new Guid();
            if (data.inquiry.application?.applicationId === undefined || data.inquiry.application?.applicationId === guid.empty) {
                data.inquiry.application.applicationId = applicationId === guid.empty ? inquiry.application.applicationId : applicationId;
            }

            // set existing data now as we dont capture it in this page
            EnsureData(appContext, WizardStepNumber.PastApplicationHistory, data, inquiry);

            // Save changes to application (attempt save, if that fails we do NOT move the Wizard step)
            setAttemptingSave(true);
            const updateOutlet = false;
            dispatch(appActions.UpdateApplication(data.inquiry.application, updateOutlet));
        } else {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    SubmitThisPageIfActive(isActive, triggerSubmit, formRef, setTriggerSubmit);

    useEffect(() => {
        if (hasPreviouslyApplied !== undefined && ParseBool(hasPreviouslyApplied) === true) {
            if (!showPreviousApplicationIdentifier) {
                setShowPreviousApplicationIdentifier(true);
            }
        }
        if (hasPreviouslyApplied === undefined || ParseBool(hasPreviouslyApplied) === false) {
            if (showPreviousApplicationIdentifier) {
                // clear the value and reset the form, could use { shouldValidate: true }
                setValue('inquiry.application.previousApplicationIdentifier', '', { shouldDirty: true, shouldValidate: true });
                setShowPreviousApplicationIdentifier(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasPreviouslyApplied, showPreviousApplicationIdentifier]);

    const radioOptions: RadioButtonOption[] = [
        { label: 'Yes', value: true, disabled: false },
        { label: 'No', value: false, disabled: false },
    ];

    return (
        <>
            <PageTitle title='Past application history' description={HEADER_PASTAPPLICATIONHISTORY} />
            <PageWrapper
                pageName='PastApplicationHistory'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={handleClickPrevious}
            >
                <PageFieldsTitle title='Past application history' />
                <PageMandatoryLabelText />
                <ErrorSummary errors={errors} />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.applicationId'
                    {...register('inquiry.application.applicationId')}
                    defaultValue={inquiry?.application.applicationId}
                />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.applicationStatus'
                    {...register('inquiry.application.applicationStatus')}
                    defaultValue={inquiry?.application.applicationStatus}
                />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.accreditationStatus'
                    {...register('inquiry.application.accreditationStatus')}
                    defaultValue={inquiry?.application.accreditationStatus}
                />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.currentApplicationStep'
                    {...register('inquiry.application.currentApplicationStep')}
                    defaultValue={inquiry?.application.currentApplicationStep}
                />
                <RadioButtonGroupField
                    options={radioOptions}
                    id='inquiry.application.hasPreviouslyApplied'
                    displayName='Previous application to be an FDR practitioner'
                    fieldLabel='Have you previously applied for registration or accreditation as an FDR practitioner?'
                    defaultValue={inquiry?.application.hasPreviouslyApplied} // only set default if value exists
                    isMandatory={true}
                    control={control}
                    register={register}
                    errorsField={errors.inquiry?.application?.hasPreviouslyApplied}
                    // autoFocus={true}
                    // onChange={(e: { target: { value: string | boolean | null } }) => onChangeHasPreviouslyApplied(ParseBool(e.target.value))}
                    onChange={onChangeHasPreviouslyApplied}
                />
                {showPreviousApplicationIdentifier && (
                    <InputTextField
                        id='inquiry.application.previousApplicationIdentifier'
                        displayName='Previous registration or submission number'
                        defaultValue={inquiry?.application.previousApplicationIdentifier}
                        isMandatory={false}
                        register={register}
                        placeHolder={PLACEHOLDER_PREVIOUS_SUBMISSION_NUMBER}
                        errorsField={errors.inquiry?.application?.previousApplicationIdentifier}
                        guidanceText='Enter your previous registration or submission number (if known)'
                    />
                )}
            </PageWrapper>
        </>
    );
};

export default PastApplicationHistoryPage;
