// Change of Name page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the Prof Insurance OR Complaints pages
//  - allows navigation forward to the Declaration page
import React, { useContext, useRef, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { RegoApplicationPageProps } from '../../PageInterfaces';
import { GetTextForSaveAndContinueButton, HideTheBackButton, SubmitThisPageIfActive } from '../../PageFunctions';
import { Sleep } from '../../../utils/Common';
import { ErrorOnSubmitForm, GetActiveAttachmentsByType } from '../../../utils/AppUtils';
import {
    CHANGEOFNAME_BLURB_PARAGRAPH1_POST,
    CHANGEOFNAME_BLURB_PARAGRAPH1_PRE,
    CHANGEOFNAME_BLURB_PARAGRAPH2,
    HEADER_CHANGEOFNAME,
    MODALBUTTON1_DATALOSSREVERT,
    MODALBUTTON1_YES,
    MODALBUTTON2_DATALOSSPROCEED,
    MODALBUTTON2_NO,
    MODALHEADER_CHANGEOFNAME_FILEMISSING,
    MODALHEADER_DATALOSSPOTENTIAL,
    MODALTEXT_CHANGEOFNAME_FILEMISSING,
    MODALTEXT_FILEUPLOADLOSSPOTENTIAL_SKIP,
    MODALTEXT_FILEUPLOADLOSSPOTENTIAL_BACK,
} from '../../../services/Constants';
import { AttachmentDocumentType, ReworkSectionType, WizardMode } from '../../../services/Enums';
import { VALIDATION_ERROR_POPUP_MESSAGE } from '../../../services/Validation';
import { toastErrorMessage } from '../../../actions/toastrMessages';
import AttachmentActions from '../../../actions/attachmentsActions';
import ErrorSummary from '../../../controls/ErrorSummary';
import { ModalDialogConfirmable } from '../../../controls/ModalDialogConfirmable';
import AppContext from '../../../stateManagement/context/AppContext';
import { MoveWizardForward } from '../../../components/wizard/wizardStepFunctions';
import PageWrapper from '../../../components/pageWrapper';
import PageTitle from '../../../components/pageTitle';
import PageMandatoryLabelText from '../../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import LabelField from '../../../components/fields/LabelField';
import FileUploadField from '../../../components/fields/FileUploadField';
import styles from '../../Pages.module.scss';
import { InsertAttachmentToLocalInquiryObject, RemoveAttachmentFromLocalInquiryObject } from '../../../utils/ComplaintsUtils';
import { IAttachmentMetadata } from '../../../api/interfacesApi';

const { childItems, controlMaxWidth } = styles;

const ChangeOfNamePage = (props: RegoApplicationPageProps) => {
    const {
        inquiry,
        authService,
        goingBackwards,
        dispatch,
        handleClickPrevious,
        handleSubmit,
        // clearErrors,
        register,
        // unregister,
        trigger,
        // setError,
        setValue,
        // watch,
        // control,
        errors,
        isValid,
        isActive,
        // ajaxCallStateForPage,
        // backEndValidationErrors,
        triggerSubmit,
        setTriggerSubmit,
    } = props;

    const appContext = useContext(AppContext);
    const formRef = useRef();
    const isResubmit = appContext.wizardState.wizardMode === WizardMode.Resubmit ? true : false;
    let documentAttachmentIsMandatory = false;
    // const [showValidationError, setShowValidationError] = useState(false);
    const attachmentActions = useMemo(() => {
        return new AttachmentActions(authService);
    }, [authService]);
    const hasAttachmentData = () => {
        const changeOfNameAttachments = GetActiveAttachmentsByType(inquiry.application.attachments, AttachmentDocumentType.EvidenceOfNameChange);
        return changeOfNameAttachments && changeOfNameAttachments.length > 0;
    };

    const doesPriorDataExistThatWillBeDeleted = () => {
        // presence of an attachment means data exists
        return hasAttachmentData() === true;
    };

    if (isResubmit === true) {
        inquiry.application?.reworkSections?.forEach((reworkSection: ReworkSectionType) => {
            if (reworkSection === ReworkSectionType.ChangeOfName) {
                documentAttachmentIsMandatory = true;
            }
        });
    }

    const submitForm = async (data: any) => {
        if (isValid) {
            // if no attachments then we prompt as they are not mandatory, just to be sure
            const changeOfNameAttachments = GetActiveAttachmentsByType(inquiry.application.attachments, AttachmentDocumentType.EvidenceOfNameChange);
            let moveForward = false;

            if (changeOfNameAttachments === null || changeOfNameAttachments.length === 0) {
                // resubmit will mean that the file upload is mandatory ONLY IF it was asked for
                if (isResubmit === true) {
                    // setShowValidationError(true);
                    // validation script will kick in
                    moveForward = false;
                } else {
                    if (
                        await ModalDialogConfirmable(MODALTEXT_CHANGEOFNAME_FILEMISSING, MODALBUTTON1_YES, MODALBUTTON2_NO, {
                            title: MODALHEADER_CHANGEOFNAME_FILEMISSING,
                            dataTestId: 'ModalChangeOfName',
                        })
                    ) {
                        moveForward = true;
                    }
                }
            } else {
                moveForward = true;
            }

            if (moveForward === true) {
                // this form has no field data to update, only attachments, which are handled already, so we simply move forward upon 'valid save'
                MoveWizardForward(appContext, isValid);
            }
        } else {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    const handleSkipButton = async (event: any) => {
        const dataExistsThatCouldBeDeleted = doesPriorDataExistThatWillBeDeleted();

        if (
            dataExistsThatCouldBeDeleted &&
            (await ModalDialogConfirmable(MODALTEXT_FILEUPLOADLOSSPOTENTIAL_SKIP, MODALBUTTON1_DATALOSSREVERT, MODALBUTTON2_DATALOSSPROCEED, {
                title: MODALHEADER_DATALOSSPOTENTIAL,
                dataTestId: 'modalPromptToRemoveData_ChangeOfName',
            }))
        ) {
            // do not remove data, stay here
            // event.preventDefault();
            // event.stopPropagation();

            return false;
        } else {
            // remove data then skip by moving forward

            // Remove any file data if exists for this type
            dispatch(attachmentActions.RemoveAttachmentsByType(AttachmentDocumentType.EvidenceOfNameChange));

            Sleep(2000).then(() => {
                // just skip and move forward as there are no fields only a file upload
                MoveWizardForward(appContext, isValid);
            });
        }
    };

    const handleBackButton = async () => {
        const dataExistsThatCouldBeDeleted = doesPriorDataExistThatWillBeDeleted();

        if (dataExistsThatCouldBeDeleted === true) {
            if (
                await ModalDialogConfirmable(MODALTEXT_FILEUPLOADLOSSPOTENTIAL_BACK, MODALBUTTON1_DATALOSSREVERT, MODALBUTTON2_DATALOSSPROCEED, {
                    title: MODALHEADER_DATALOSSPOTENTIAL,
                    dataTestId: 'modalPromptToRemoveData_ChangeOfName_Back',
                })
            ) {
                // do not remove data, stay here
                return false;
            } else {
                // Remove any file data if exists for this type, then go back
                dispatch(attachmentActions.RemoveAttachmentsByType(AttachmentDocumentType.EvidenceOfNameChange));

                Sleep(1000).then(() => {
                    // just move back as there are no fields only a file upload
                    handleClickPrevious();
                });
            }
        } else {
            // just move back as there are no fields only a file upload
            handleClickPrevious();
        }
    };

    const removedFile = (attachmentId: string) => {
        // refresh CM object/active obj
        RemoveAttachmentFromLocalInquiryObject(attachmentId, setValue, inquiry);
        trigger(); // re-validate
    };

    const addedFile = (newAttachment: IAttachmentMetadata) => {
        // refresh CM object/active obj (NOTE: CANNOT get App in case user simply adds/removes files, it will clear the form!)
        InsertAttachmentToLocalInquiryObject(newAttachment, setValue, inquiry);
        trigger(); // re-validate
    };

    SubmitThisPageIfActive(isActive, triggerSubmit, formRef, setTriggerSubmit);

    /* 
    // this should only be shown whenhen resubmit and asked for by PAU
    if (isActive === true && showValidationError === true) {
        if (errors === undefined || errors === null || Object.keys(errors).length === 0) {
            Sleep(500).then(() => {
                setError('inquiry.application.attachments.changeofname', {
                    type: 'manual',
                    message: VALIDATION_FILEUPLOADREQUIRED('Name change'),
                });
            });
        }
    } */

    const blurbText = `${CHANGEOFNAME_BLURB_PARAGRAPH1_PRE}${inquiry.personalDetails.firstName} ${inquiry.personalDetails.familyName}${CHANGEOFNAME_BLURB_PARAGRAPH1_POST}`;

    return (
        <>
            <PageTitle title='Change of name documentation' description={HEADER_CHANGEOFNAME} />
            <PageWrapper
                pageName='ChangeOfName'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={handleBackButton}
                includeMiddleButton={!isResubmit}
                middleButtonClickHandler={handleSkipButton}
                hideBackButton={HideTheBackButton(appContext)}
                textForSaveContinueButton={GetTextForSaveAndContinueButton(appContext)}
            >
                <PageFieldsTitle title='Change of name documentation' />
                <PageMandatoryLabelText />
                <ErrorSummary errors={errors} />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.applicationId'
                    {...register('inquiry.application.applicationId')}
                    defaultValue={inquiry.application.applicationId}
                />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.isResubmit'
                    {...register('inquiry.application.isResubmit')}
                    defaultValue={isResubmit}
                />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.documentAttachmentIsMandatory'
                    {...register('inquiry.application.documentAttachmentIsMandatory')}
                    defaultValue={documentAttachmentIsMandatory}
                />
                <LabelField id='infoLabel' displayName='' value={blurbText} isMandatory={false} />
                <LabelField id='infoLabel' displayName='' value={CHANGEOFNAME_BLURB_PARAGRAPH2} isMandatory={false} />
                <Row className={childItems}>
                    <Row className={controlMaxWidth}>
                        <Col>
                            <FileUploadField
                                id='inquiry.application.attachments.changeofname'
                                maxFilesCount={5}
                                applicationId={inquiry?.application.applicationId}
                                authService={authService}
                                displayNameSuffix='change of name'
                                errorsFieldSuffix='change of name'
                                documentType={AttachmentDocumentType.EvidenceOfNameChange}
                                isMandatory={false}
                                // updatedFilesList={updatedFilesList}
                                register={register}
                                dispatch={dispatch}
                                goingBackwards={goingBackwards}
                                errorsField={errors.inquiry?.application?.attachments}
                                removedFile={removedFile}
                                addedFile={addedFile}
                            />
                        </Col>
                    </Row>
                </Row>
            </PageWrapper>
        </>
    );
};

export default ChangeOfNamePage;
