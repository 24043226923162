import React from 'react';
import styles from './Fields.module.scss';

const { mandatoryIndicator } = styles;

const MandatoryLabelIndicator = () => {
    return <span className={mandatoryIndicator}>*</span>;
};

export default MandatoryLabelIndicator;
