import React from 'react';
import { Button } from 'react-bootstrap';
import { skullCrossbonesIcon } from '../utils/Icons';
import style from './Controls.module.scss';

const { helpTextContainer, devilsToolboxButtonStyle, helpButtonContainer } = style;

export interface DevilsToolboxButtonProps {
    handleButtonClick: any;
}

export const DevilsToolboxButton = (props: DevilsToolboxButtonProps) => {
    const { handleButtonClick } = props;
    const id = 'devilstoolboxbutton';

    return (
        <div id={id} className={helpTextContainer}>
            <div className={helpButtonContainer}>
                <Button variant='outline-secondary' className={`btn ${devilsToolboxButtonStyle}`} onClick={handleButtonClick}>
                    {skullCrossbonesIcon()} Devils Toolbox!
                </Button>
            </div>
        </div>
    );
};

export default DevilsToolboxButton;
