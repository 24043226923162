import { Inquiry, InquiryDefaultObj, IOutlet, IAttachmentMetadata, IComplaintMechanism } from '../../../api/interfacesApi';
import { WizardStepProps } from '../../../components/wizard/wizardInterfaces';
import { WizardStepCurrentStateEnum } from '../../../components/wizard/wizardEnums';
import { AttachmentDocumentType } from '../../../services/Enums';
import {
    defaultApplicationData,
    defaultApplicationSubmitData,
    defaultComplaintsMechanismData,
    defaultOutletPractitionerData,
    defaultUserData,
} from '../../../api/defaultData';

export interface FormPropertiesProps {
    // inquiry: any; // matches mapped data object for Wizard/all-Forms
    InitialData: InquiryDefaultObj; // DefaultObj;
    WizardSteps: WizardStepProps[];
    StepIds: number[];
    CurrentStep: WizardStepProps;
}

const defaultOutletsData: IOutlet[] = [];

// used externally
export const initAttachment: IAttachmentMetadata = {
    attachmentId: '',
    fileName: '',
    applicationId: '',
    azureBlobName: '',
    documentType: AttachmentDocumentType.OrgEmployment,
    isDeleted: false,
};

export const GetNewComplaintMechanismObject = () => {
    const newComplaint: IComplaintMechanism = {
        ...defaultComplaintsMechanismData,
        complaintsProcessType: undefined,
        outlet: { ...defaultOutletPractitionerData },
        evidence: [],
        attachmentExists: initAttachment.attachmentId,
        professionalMembershipType: null,
    };
    return newComplaint;
};

const defaultData: Inquiry = {
    // introductionText: '',
    application: defaultApplicationData,
    personalDetails: defaultUserData,
    outlets: defaultOutletsData,
    applicationSubmit: defaultApplicationSubmitData,
    differencesUser: null,
    differencesComplaintsCollection: null,
    // complaintsMechanism: defaultComplaintsMechanismData,
};
const step1: WizardStepProps = {
    index: 0,
    nextIndex: 1,
    dataTestId: 'WizardStepComplaintsType',
    displayName: 'Outlet type',
    currentState: WizardStepCurrentStateEnum.initial,
    stepCompleted: false,
    isDisplayed: true,
    isActive: true,
};
const step2: WizardStepProps = {
    index: 1,
    nextIndex: 2,
    dataTestId: 'WizardStepComplaintsDetails',
    displayName: 'Complaints mechanism for outlet',
    currentState: WizardStepCurrentStateEnum.initial,
    stepCompleted: false,
    isDisplayed: true,
    isActive: false,
};
const step3: WizardStepProps = {
    index: 2,
    nextIndex: -1,
    dataTestId: 'WizardStepComplaintsInsurance',
    displayName: 'Professional indemnity insurance',
    currentState: WizardStepCurrentStateEnum.initial,
    stepCompleted: false,
    isDisplayed: true,
    isActive: false,
};

const steps: WizardStepProps[] = [step1, step2, step3];

const inquiryData: InquiryDefaultObj = {
    inquiry: defaultData,
};

const formProperties: FormPropertiesProps = {
    // inquiry: defaultData,
    InitialData: inquiryData,
    WizardSteps: steps,
    StepIds: steps.map(s => s.index),
    CurrentStep: step1,
};

export default class ComplaintsMechanismForm {
    public properties: FormPropertiesProps = formProperties;
}
