import React from 'react';
import DevilsToolboxButtonComponent from './devilsToolboxButtonComponent';

export interface DevilsToolboxMainProps {
    authService: any;
}

/* component that shows the button or not pending environment (to make Prod most-efficient) */
export const DevilsToolboxMain = (props: DevilsToolboxMainProps) => {
    const { authService } = props;

    const env = window.__RUNTIME_CONFIG__.REACT_APP_TARGET_ENVIRONMENT;

    const isAllowed = env === 'Development' || env === 'DevIntegration' || env === 'Test' || env === 'Pre';
    const isPre = env === 'Pre';

    if (!isAllowed) {
        return null;
    }

    return <DevilsToolboxButtonComponent isPre={isPre} authService={authService} />;
};

export default DevilsToolboxMain;
