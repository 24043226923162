import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './Controls.module.scss';

const { emptyLine } = styles;

export const EmptyRow = () => {
    return (
        <Row className={emptyLine}>
            <Col>&nbsp;</Col>
        </Row>
    );
};
