import { AjaxCallStateEnum, AttachmentDocumentType } from '../services/Enums';
import { IUser, IApplication, IAttachmentMetadata, IDifferences, IApplicationSubmit, IComplaintMechanism } from '../api/interfacesApi';

// Common
export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const END_AJAX_CALL = 'END_AJAX_CALL';
export const GET_LOOKUPS_SUCCESS = 'GET_LOOKUPS_SUCCESS';

// User
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_EDITCOPY_SUCCESS = 'GET_USER_EDITCOPY_SUCCESS';
export const SAVE_USER_FORM = 'SAVE_USER_FORM';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_USER_GENDERCONSENTONLY_SUCCESS = 'SAVE_USER_GENDERCONSENTONLY_SUCCESS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const UPDATE_USER_ROLES_SUCCESS = 'UPDATE_USER_ROLES_SUCCESS';
export const GET_USER_CHANGES_SUCCESS = 'GET_USER_CHANGES_SUCCESS';
export const DISCARD_CHANGES_SUCCESS = 'DISCARD_CHANGES_SUCCESS';
export const START_NEW_APPLICATION_SUCCESS = 'START_NEW_APPLICATION_SUCCESS'; // DEV/TEST ONLy
export const ASSING_TO_ME_APPLICATION_SUCCESS = 'ASSING_TO_ME_APPLICATION_SUCCESS'; // DEV/TEST ONLy

// File/Attachment
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_LOAD_SUCCESS = 'FILE_LOAD_SUCCESS';
export const FILE_DOWNLOAD_SUCCESS = 'FILE_DOWNLOAD_SUCCESS';
export const FILE_DELETE_SUCCESS = 'FILE_DELETE_SUCCESS';
export const UPDATE_ATTACHMENT_SUCCESS = 'UPDATE_ATTACHMENT_SUCCESS';

// Application
export const SUBMIT_APPLICATION_SUCCESS = 'SUBMIT_APPLICATION_SUCCESS';
export const SUBMIT_CHANGES_SUCCESS = 'SUBMIT_CHANGES_SUCCESS';
export const DISCARD_APPLICATION_SUCCESS = 'DISCARD_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_FORM = 'UPDATE_APPLICATION_FORM';
export const RESET_APPLICATION_FORM = 'RESET_APPLICATION_FORM';
export const CREATE_APPLICATION_FORM = 'CREATE_APPLICATION_FORM';
export const CREATE_APPLICATION_SUCCESS = 'CREATE_APPLICATION_SUCCESS';
export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS';
export const GET_ALL_APPLICATIONS_SUCCESS = 'GET_ALL_APPLICATIONS_SUCCESS';
export const STATUS_CHANGE_SUCCESS = 'STATUS_CHANGE_SUCCESS';
export const GET_APPLICATION_ATTACHMENTS_SUCCESS = 'GET_APPLICATION_ATTACHMENTS_SUCCESS';
export const DELETE_COMPLAINTSDATA_SUCCESS = 'DELETE_COMPLAINTSDATA_SUCCESS';
export const GET_COMPLAINTSMECHANISM_SUCCESS = 'GET_COMPLAINTSMECHANISM_SUCCESS';
export const GET_COMPLAINTSMECHANISM_CHANGES_SUCCESS = 'GET_COMPLAINTSMECHANISM_CHANGES_SUCCESS';
export const GET_ALL_COMPLAINTSMECHANISM_CHANGES_SUCCESS = 'GET_ALL_COMPLAINTSMECHANISM_CHANGES_SUCCESS';
export const ADD_COMPLAINTSMECHANISM_SUCCESS = 'ADD_COMPLAINTSMECHANISM_SUCCESS';
export const UPDATE_COMPLAINTSMECHANISM_SUCCESS = 'UPDATE_COMPLAINTSMECHANISM_SUCCESS';
export const REMOVE_COMPLAINTSMECHANISM_SUCCESS = 'REMOVE_COMPLAINTSMECHANISM_SUCCESS';
export const DISCARDCHANGES_COMPLAINTSMECHANISM_SUCCESS = 'DISCARDCHANGES_COMPLAINTSMECHANISM_SUCCESS';
export const SUSPEND_APPLICATION_SUCCESS = 'SUSPEND_APPLICATION_SUCCESS';
export const CANCEL_APPLICATION_SUCCESS = 'CANCEL_APPLICATION_SUCCESS';
export const REMOVE_OUTLET_THENPUBLISH_SUCCESS = 'REMOVE_OUTLET_THENPUBLISH_SUCCESS';

export interface ICallStatus {
    callsInProgress: number;
    sessionTimerId: any;
    loading: boolean;
    err: { title: string; message: string };
    errors: [];
    callState: AjaxCallStateEnum;
}

export type AjaxStatusAction = {
    type: string;
    status: ICallStatus;
    payload: any; // this comes from ajaxCallError within ajaxStatusActions.tsx
    loading: boolean; // this comes from ajaxCallError within ajaxStatusActions.tsx
};

interface GetUserSuccessAction {
    type: typeof GET_USER_SUCCESS;
    user: IUser;
}

interface GetUserEditCopySuccessAction {
    type: typeof GET_USER_EDITCOPY_SUCCESS;
    user: IUser;
}

interface SaveUserAction {
    type: typeof SAVE_USER_SUCCESS;
    user: IUser;
}

interface SaveUserGenderConsentOnlySuccessAction {
    type: typeof SAVE_USER_GENDERCONSENTONLY_SUCCESS;
    user: IUser;
}

interface GetChangesSuccessAction {
    type: typeof GET_USER_CHANGES_SUCCESS;
    differences: IDifferences<IUser>;
}

interface DiscardChangesSuccessAction {
    type: typeof DISCARD_CHANGES_SUCCESS;
    user: IUser;
}

interface StartNewApplicatioccessAction {
    // DEV/TEST ONLY
    type: typeof START_NEW_APPLICATION_SUCCESS;
    user: IUser;
}

interface AssignToMeSuccessAction {
    // DEV/TEST ONLY
    type: typeof ASSING_TO_ME_APPLICATION_SUCCESS;
    user: IUser;
}

export interface GetApplicationSuccessAction {
    type: typeof GET_APPLICATION_SUCCESS;
    application: IApplication;
    doNotCopyActiveComplaint?: boolean;
    keepOriginalCMsAndNewCMsOnly?: boolean;
}
export interface GetAllApplicationSuccessAction {
    type: typeof GET_ALL_APPLICATIONS_SUCCESS;
    applications: IApplication[];
}

export interface CreateApplicationSuccessAction {
    type: typeof CREATE_APPLICATION_SUCCESS;
    application: IApplication;
}

export interface UpdateApplicationSuccessAction {
    type: typeof UPDATE_APPLICATION_SUCCESS;
    application: IApplication;
}

export interface SubmitApplicationSuccessAction {
    type: typeof SUBMIT_APPLICATION_SUCCESS;
    // application: IApplication;
}

export interface SubmitChangesSuccessAction {
    type: typeof SUBMIT_CHANGES_SUCCESS;
    applicationSubmit: IApplicationSubmit;
}

export interface DiscardApplicationSuccessAction {
    type: typeof DISCARD_APPLICATION_SUCCESS;
}

export interface StartNewApplicationSuccessAction {
    type: typeof START_NEW_APPLICATION_SUCCESS;
}

export interface AssignToMeApplicationSuccessAction {
    type: typeof ASSING_TO_ME_APPLICATION_SUCCESS;
}

export interface DeleteComplaintsDataSuccessAction {
    type: typeof DELETE_COMPLAINTSDATA_SUCCESS;
}

export interface AddComplaintsMechanismSuccessAction {
    type: typeof ADD_COMPLAINTSMECHANISM_SUCCESS;
    complaintsMechanism: IComplaintMechanism;
}

export interface UpdateComplaintsMechanismSuccessAction {
    type: typeof UPDATE_COMPLAINTSMECHANISM_SUCCESS;
    complaintsMechanism: IComplaintMechanism;
}

export interface RemoveOutletThenPublishSuccessAction {
    type: typeof REMOVE_OUTLET_THENPUBLISH_SUCCESS;
    application: IApplication;
}

export interface RemoveComplaintsMechanismSuccessAction {
    type: typeof REMOVE_COMPLAINTSMECHANISM_SUCCESS;
    complaintsMechanism: IComplaintMechanism;
}

export interface DiscardChangesComplaintsMechanismSuccessAction {
    type: typeof DISCARDCHANGES_COMPLAINTSMECHANISM_SUCCESS;
    complaintsMechanism: IComplaintMechanism;
}

export interface GetComplaintsMechanismSuccessAction {
    type: typeof GET_COMPLAINTSMECHANISM_SUCCESS;
    complaintsMechanism: IComplaintMechanism;
}

export interface GetComplaintsMechanismChangesSuccessAction {
    type: typeof GET_COMPLAINTSMECHANISM_CHANGES_SUCCESS;
    differences: IDifferences<IComplaintMechanism>;
}

export interface GetAllComplaintsMechanismChangesSuccessAction {
    type: typeof GET_ALL_COMPLAINTSMECHANISM_CHANGES_SUCCESS;
    differencesList: IDifferences<IComplaintMechanism>[];
}

export interface SuspendApplicationSuccessAction {
    type: typeof SUSPEND_APPLICATION_SUCCESS;
    application: IApplication;
}

export interface CancelApplicationSuccessAction {
    type: typeof CANCEL_APPLICATION_SUCCESS;
    application: IApplication;
}

export interface LoadAttachmentsAction {
    type: typeof FILE_LOAD_SUCCESS;
    attachments: IAttachmentMetadata[];
    documentType: AttachmentDocumentType;
    index?: number;
}
export interface RemoveAttachmentsByTypeAction {
    type: typeof FILE_DELETE_SUCCESS;
    attachments: IAttachmentMetadata[];
    documentType: AttachmentDocumentType;
    index?: number;
}

export type UserActionTypes =
    | GetUserSuccessAction
    | GetUserEditCopySuccessAction
    | SaveUserAction
    | SaveUserGenderConsentOnlySuccessAction
    | GetChangesSuccessAction
    | DiscardChangesSuccessAction
    | StartNewApplicatioccessAction
    | AssignToMeSuccessAction;
export type ApplicationActionTypes =
    | GetApplicationSuccessAction
    | GetAllApplicationSuccessAction
    | CreateApplicationSuccessAction
    | UpdateApplicationSuccessAction
    | SubmitApplicationSuccessAction
    | SubmitChangesSuccessAction
    | DiscardApplicationSuccessAction
    | DeleteComplaintsDataSuccessAction
    | AddComplaintsMechanismSuccessAction
    | UpdateComplaintsMechanismSuccessAction
    | RemoveOutletThenPublishSuccessAction
    | RemoveComplaintsMechanismSuccessAction
    | GetComplaintsMechanismSuccessAction
    | GetComplaintsMechanismChangesSuccessAction
    | GetAllComplaintsMechanismChangesSuccessAction
    | DiscardChangesComplaintsMechanismSuccessAction
    | SuspendApplicationSuccessAction
    | CancelApplicationSuccessAction;
export type AttachmentsActionTypes = LoadAttachmentsAction | RemoveAttachmentsByTypeAction;

export interface IApplicationApiReturnState {
    type: string | null;
    complaintsMechanism: IComplaintMechanism | null;
}
