import { formatDateForDateFns } from '../../utils/Dates';
import * as types from '../../actions/actionTypes';
import { IComplaintMechanism } from '../../api/interfacesApi';

const complaintsMechanismApiReturnState: types.IApplicationApiReturnState = {
    type: null,
    complaintsMechanism: null,
};

const complaintsMechanism = (state = complaintsMechanismApiReturnState, action: types.ApplicationActionTypes) => {
    const newState = { ...state };
    let complaintMechanism: IComplaintMechanism;

    const fixCMfields = (complaint: IComplaintMechanism) => {
        if (complaint) {
            complaint.membershipFromDate = complaint.membershipFromDate && formatDateForDateFns(complaint.membershipFromDate);
            complaint.membershipToDate = complaint.membershipToDate && formatDateForDateFns(complaint.membershipToDate);
            complaint.professionalIndemnityInsuranceFromDate =
                complaint.professionalIndemnityInsuranceFromDate && formatDateForDateFns(complaint.professionalIndemnityInsuranceFromDate);
            complaint.professionalIndemnityInsuranceToDate =
                complaint.professionalIndemnityInsuranceToDate && formatDateForDateFns(complaint.professionalIndemnityInsuranceToDate);
            complaint.recordActiveFromDate = complaint.recordActiveFromDate && formatDateForDateFns(complaint.recordActiveFromDate);
            complaint.recordActiveToDate = complaint.recordActiveToDate && formatDateForDateFns(complaint.recordActiveToDate);
        }
    };

    if (action.type === types.GET_COMPLAINTSMECHANISM_SUCCESS) {
        complaintMechanism = action.complaintsMechanism;
        fixCMfields(complaintMechanism);
        newState.complaintsMechanism = complaintMechanism;
        newState.type = types.GET_COMPLAINTSMECHANISM_SUCCESS;
    } else if (action.type === types.ADD_COMPLAINTSMECHANISM_SUCCESS) {
        complaintMechanism = action.complaintsMechanism;
        fixCMfields(complaintMechanism);
        newState.complaintsMechanism = complaintMechanism;
        newState.type = types.ADD_COMPLAINTSMECHANISM_SUCCESS;
    } else if (action.type === types.UPDATE_COMPLAINTSMECHANISM_SUCCESS) {
        complaintMechanism = action.complaintsMechanism;
        fixCMfields(complaintMechanism);
        newState.complaintsMechanism = complaintMechanism;
        newState.type = types.UPDATE_COMPLAINTSMECHANISM_SUCCESS;
    } else {
        newState.complaintsMechanism = null;
        newState.type = null;
    }

    return newState;
};

export default complaintsMechanism;
