import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { lockIcon } from './DashboardIcons';
import NavLinkActionCard from './NavLinkActionCard';
import { ActionCardIndicatorEnum } from '../../services/Enums';
import styles from './Dashboard.module.scss';

const {
    cardContentWrapper,
    cardImage,
    cardBody,
    cardBodyComplaintsTile,
    cardTitle,
    actionCardContainer,
    actionCardContainerInner,
    actionCardButton,
    actionCardComplaintsTile,
    actionCardColorOne,
    actionCardColorTwo,
    actionCardColorLocked,
    actionCardContent,
    actionCardContentComplaintsTile,
    pendingEditLockIcon,
    pendingEditEmptyColumn,
    actionCardErrorText,
    actionCardNewIndicator,
    actionCardInProgressIndicator,
    actionCardDeletedIndicator,
    actionCardEditedIndicator,
} = styles;

interface ActionCardProps {
    id: string;
    indicatorState?: ActionCardIndicatorEnum;
    backgoundColor?: string;
    imgSrc?: any;
    imgElement?: JSX.Element;
    title: string;
    cardTitleLargeFontOnly?: boolean;
    buttonLink: string;
    openInNewTab?: boolean;
    handleOnClick?: any;
    disabledButtonActionText?: string; // prevents click if supplied and applies lock features
    hasPendingEdits?: boolean; // determines if tile looks locked
    darkerShadeOfTile?: boolean;
    isComplaintsTile?: boolean;
    children?: any;
}

const ActionCard = (props: ActionCardProps) => {
    const {
        id,
        indicatorState,
        backgoundColor,
        imgSrc,
        imgElement,
        title,
        cardTitleLargeFontOnly,
        buttonLink,
        openInNewTab = false,
        handleOnClick,
        disabledButtonActionText,
        hasPendingEdits,
        darkerShadeOfTile,
        isComplaintsTile,
        children,
    } = props;

    const complaintsTitleLimitChars = 62;
    const [showDisabledButtonActionText, setShowDisabledButtonActionText] = React.useState(false);
    const handleDisabledButtonClick = () => {
        setShowDisabledButtonActionText(true);
    };

    let ariaProps = {};
    if (showDisabledButtonActionText === true) {
        ariaProps = {
            'aria-describedby': 'navLinkError',
            'aria-invalid': true,
        };
    }

    // const extraStyle = backgoundColor && backgoundColor !== '' ? `background-color: ${backgoundColor}` : '';
    // style={{ width: progress + '%' }}
    // const getImageOnly = imgElement;
    const getImageWithPendingEdit = () => {
        return (
            <Row>
                <Col>{imgElement}</Col>
                <Col className={pendingEditEmptyColumn} />
                <Col className={pendingEditEmptyColumn} />
                <Col data-testid={`lock_${id}`} className={pendingEditLockIcon}>
                    {lockIcon(disabledButtonActionText)}
                </Col>
            </Row>
        );
    };
    // const imageRow = hasPendingEdits === true ? getImageWithPendingEdit : getImageOnly;
    // <span style={{ color: 'blue', fontSize: '135px', width: '26rem', height: '22rem' }}>&#x2B22;</span>

    let useDisableHandler = false;
    let buttonLinkModded = buttonLink;
    if (disabledButtonActionText && disabledButtonActionText.length > 0) {
        useDisableHandler = true;
        buttonLinkModded = ''; // prevent link click in favour of text display for disabled links
    }
    const handleNavClick = (e: any) => {
        if (useDisableHandler === true) {
            handleDisabledButtonClick();
        } else {
            if (handleOnClick) {
                handleOnClick(e);
            }
        }
    };

    const TrimTitle = (titleIn: string, numberOfChars: number) => {
        return titleIn.substring(0, numberOfChars) + (titleIn.length > numberOfChars ? '...' : '');
    };

    const actionCardColor = hasPendingEdits === true ? actionCardColorLocked : darkerShadeOfTile === true ? actionCardColorTwo : actionCardColorOne;
    // for Complaints tiles we reduce all padding etc as we add text/details to the tile
    const actionCardContentClass = isComplaintsTile === true ? `${actionCardContent} ${actionCardContentComplaintsTile}` : `${actionCardContent}`;
    const cardContentWrapperClass = isComplaintsTile === true ? '' : `${cardContentWrapper}`;
    const cardBodyClass = isComplaintsTile === true ? `card-body ${cardBody} ${cardBodyComplaintsTile}` : `card-body ${cardBody}`;
    const titleModded = isComplaintsTile === true ? TrimTitle(title, complaintsTitleLimitChars) : title;
    const actionCardClass =
        isComplaintsTile === true
            ? `${actionCardContainerInner} ${actionCardColor} ${actionCardComplaintsTile}`
            : `${actionCardContainerInner} ${actionCardColor}`;
    const actionCardButtonClass = `btn ${actionCardButton}`;

    return (
        <>
            <div className={`${actionCardContainer} ${actionCardClass}`}>
                <div className={actionCardContentClass}>
                    <NavLinkActionCard
                        hrefLink={buttonLinkModded}
                        dataTestId={`dashboardAction_${id}`}
                        handleOnClick={handleNavClick}
                        openInNewTab={openInNewTab}
                        className={actionCardButtonClass}
                        altText={title}
                    >
                        <div id={id} data-testid={id} style={{ backgroundColor: `${backgoundColor}` }} {...ariaProps}>
                            <div className={cardContentWrapperClass}>
                                {indicatorState === ActionCardIndicatorEnum.New && <span className={actionCardNewIndicator}>* NEW *</span>}
                                {indicatorState === ActionCardIndicatorEnum.InProgress && <span className={actionCardInProgressIndicator}>IN PROGRESS</span>}
                                {indicatorState === ActionCardIndicatorEnum.Deleted && <span className={actionCardDeletedIndicator}>DELETED</span>}
                                {indicatorState === ActionCardIndicatorEnum.Edited && <span className={actionCardEditedIndicator}>EDITED</span>}
                                {imgSrc && <img src={imgSrc} className={`card-img-top ${cardImage}`} alt='' />}
                                {hasPendingEdits === true ? getImageWithPendingEdit() : imgElement}
                                <div className={cardBodyClass}>
                                    {cardTitleLargeFontOnly === true ? (
                                        <p className={`card-title ${cardTitle}`}>{titleModded}</p>
                                    ) : (
                                        <h3 className={`card-title ${cardTitle}`}>{titleModded}</h3>
                                    )}
                                    {children}
                                </div>
                            </div>
                        </div>
                    </NavLinkActionCard>
                </div>
            </div>
            {showDisabledButtonActionText === true && (
                <div className={`${actionCardErrorText} p-2`}>
                    <p aria-live='assertive' id='navLinkError' className='validation-error'>
                        {disabledButtonActionText}
                    </p>
                </div>
            )}
        </>
    );
};

export default ActionCard;
