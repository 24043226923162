import React, { useContext, useRef, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    COMPLAINTS_MECHANISM_BLURB_ADDCM_PARAGRAPH1,
    COMPLAINTS_MECHANISM_BLURB_ADDCM_PARAGRAPH2,
    HEADER_COMPLAINTS,
    HELPTEXT_COMPLAINTSMECHANISM_FULLMEMBERSHIP,
    MODALBUTTON1_OK,
    MODALBUTTON2_CANCEL,
    MODALBUTTON_NO_DISCARDCHANGES,
    MODALBUTTON_YES_DISCARDCHANGES,
    MODALHEADER_DATACHANGEPOTENTIAL,
    MODALHEADER_UNSAVEDCHANGES_DATALOSSPOTENTIAL,
    MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_ACCREDITED,
    MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_ACCREDITED_P2,
    MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_ACCREDITED_P3,
    MODALTEXT_UNSAVEDCHANGES_DATALOSSPOTENTIAL,
    UNINITIALISED,
} from '../../../../services/Constants';
import { AjaxCallStateEnum, ComplaintsMechanismType, ProfessionalMembershipType, WizardStepNumberComplaintsMech } from '../../../../services/Enums';
import Guid from '../../../../services/Guid';
import { VALIDATION_ERROR_POPUP_MESSAGE, VALIDATION_ERROR_UNKNOWN_MESSAGE, VALIDATION_SUCCESS_POPUP_MESSAGE } from '../../../../services/Validation';
// import { GetNewComplaintMechanismObject } from '../complaintsMechanismForm';
import { SubmitThisPageIfActive } from '../../../../pages/PageFunctions';
import { ComplaintsMechanismEditPageProps } from '../../../../pages/PageInterfaces';
import { defaultComplaintsMechanismData } from '../../../../api/defaultData';
import ApplicationActions from '../../../../actions/applicationActions';
import AttachmentActions from '../../../../actions/attachmentsActions';
import { toastErrorMessage, toastSuccessMessage } from '../../../../actions/toastrMessages';

import AppContext from '../../../../stateManagement/context/AppContext';
import { DisplayMechanismTypeOnceAccredited } from '../../../../utils/EnumMappings';
import { Sleep } from '../../../../utils/Common';
import { CopyFromComplaintToActive, RemoveCMAttachmentsForThisCM } from '../../../../utils/ComplaintsUtils';
import { ErrorOnSubmitForm, IsValidGuidIdentifier } from '../../../../utils/AppUtils';
import PageTitle from '../../../../components/pageTitle';
import PageFieldsTitle from '../../../../components/fields/PageFieldsTitle';
import PageMandatoryLabelText from '../../../../components/fields/PageMandatoryLabelText';
import PageWrapper from '../../../../components/pageWrapper';
import LabelField from '../../../../components/fields/LabelField';
import RadioButtonGroupField, { RadioButtonOption } from '../../../../components/fields/RadioButtonGroupField';
import ErrorSummary from '../../../../controls/ErrorSummary';
import { ModalDialogConfirmable } from '../../../../controls/ModalDialogConfirmable';
import Loader from '../../../../controls/Loader';
import { MoveComplaintsWizardForward, RepaintWizard, SetAllFutureStepsIncomplete, UpdateWizardStepsAndRepaint } from '../wizard/complaintsMechWizardFunctions';
import { EmptyRow } from '../../../../controls/EmptyRow';

interface UIState {
    attemptingSave: boolean;
    attemptingSaveThenRemovePriorData: boolean;
    loadUpdatedApp: boolean;
}

const initUiState: UIState = {
    attemptingSave: false,
    attemptingSaveThenRemovePriorData: false,
    loadUpdatedApp: false,
};

const ComplaintsMechanismTypePage = (props: ComplaintsMechanismEditPageProps) => {
    const {
        addNewComplaint,
        resumeAddingNew,
        inquiry,
        setInquiryObj,
        // attachments,
        // goingBackwards,
        authService,
        dispatch,
        control,
        // handleClickPrevious,
        handleSubmit,
        register,
        // unregister,
        // trigger,
        setValue,
        // getValues,
        setError,
        watch,
        ajaxCallStateForPage,
        backEndValidationErrors,
        // clearErrors,
        errors,
        isValid,
        isDirty,
        isActive,
        triggerSubmit,
        setTriggerSubmit,
        reset,
        // dirtyFields,
        triggerChildFormDiscardChanges,
        // hideBackButton,
    } = props;

    const appContext = useContext(AppContext);
    const formRef = useRef();
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const attachmentActions = useMemo(() => {
        return new AttachmentActions(authService);
    }, [authService]);
    const complaintsMechanism = useSelector((state: any) => state.complaintsMechanism?.complaintsMechanism);

    const [displayState, setDisplayState] = useState(initUiState);

    // watch so we can revert change upon change of mind
    const complaintsMechanismTypeWatched: ComplaintsMechanismType = watch(
        'inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType',
        inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType,
    );

    useEffect(() => {
        // initial load of attachments now requires a refresh of base wizard object
        setValue('inquiry.application.activeComplaintsMechanismObj', inquiry.application.activeComplaintsMechanismObj);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // repaint wizard immediately - in case user had gone backwards to landing then clicked any tile to here
        RepaintWizard(appContext);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // reset state
    useEffect(() => {
        setDisplayState(initUiState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (triggerChildFormDiscardChanges && isActive === true) {
            handleClickPreviousButton(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerChildFormDiscardChanges]);

    // now we have to do this by CM (as we may have multiple CMs at this point)
    const removePriorAttachments = () => {
        RemoveCMAttachmentsForThisCM(attachmentActions, dispatch, inquiry, inquiry.application.activeComplaintsMechanismObj!.complaintsMechanismId);
    };

    // based upon the original value, we remove data as the user has changed their option whilst the data already exists
    const removePriorData = (newValue: ComplaintsMechanismType) => {
        setDisplayState({ ...displayState, attemptingSaveThenRemovePriorData: true });

        removePriorAttachments();

        const cmId = inquiry.application?.activeComplaintsMechanismObj?.complaintsMechanismId;

        inquiry.application.activeComplaintsMechanismObj = defaultComplaintsMechanismData;
        inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType = newValue;
        inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId = cmId ?? '';
        // must refresh inquiry obj for attachment collection and active CM
        setInquiryObj(inquiry);

        // reset would make the values update for the useForm functionality (needed here for if user changes mind and we remove all existing file uploaded)
        // reset(inquiry);
        // reset({ application: inquiry.application }, { keepValues: true });
        // reset(inquiry, { keepValues: true });
        reset();

        const updateOutlet = false;
        dispatch(appActions.UpdateApplication(inquiry.application, updateOutlet));

        /*
        // MUST set the new value otherwise the reload of data will just set it back to orig value!
        if (inquiry.application.activeComplaintsMechanismObj) {
            // reset CM object
            inquiry.application.activeComplaintsMechanismObj = GetNewComplaintMechanismObject();
            // then place value selected in the Type
            inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType = newValue;
        }

        // MUST remove prof association/complaint data if exist
        if (inquiry.application.activeComplaintsMechanismObj !== null) {
            inquiry.application.activeComplaintsMechanismObj = null;
            ResetComplaintsMechanismCollection(true, setValue, inquiry);
        }
*/

        // MUST reset wizard steps
        const newWizardSteps = SetAllFutureStepsIncomplete(WizardStepNumberComplaintsMech.ComplaintsMechanismType, appContext.wizardSteps);
        // appContext.dispatchWizardState({ type: WizardStateActionTypeEnums.activateStep, destinationStep: destIndex });
        UpdateWizardStepsAndRepaint(appContext, newWizardSteps);

        // finally set most active step otherwise user can swap between membership and employee and resume function will fail
        // inquiry.application.currentApplicationStep = WizardStepNumberComplaintsMech.ComplaintsMechanismType;

        // dispatch(appActions.UpdateApplication(inquiry.application));
        // CopyFromActiveToComplaintCollection(inquiry.application);

        // force submit to save these changes
        // setTriggerSubmit(true);
    };

    const handleClickPreviousButton = async (backToLandingPage?: boolean) => {
        let goBack = false;

        if (!isDirty) {
            goBack = true;
        } else {
            if (
                await ModalDialogConfirmable(MODALTEXT_UNSAVEDCHANGES_DATALOSSPOTENTIAL, MODALBUTTON_YES_DISCARDCHANGES, MODALBUTTON_NO_DISCARDCHANGES, {
                    title: MODALHEADER_UNSAVEDCHANGES_DATALOSSPOTENTIAL,
                    isDestructive: true,
                    modalBodyPositionTextLeft: false,
                    dataTestId: 'modalPromptToRemoveData_ComplaintsTypeBack',
                })
            ) {
                goBack = true;
            }
        }

        if (goBack === true || backToLandingPage === true) {
            // update means return to the landing page now as we have created the edit copy
            window.location.href = '/managecomplaintsmechanism?reload=true'; // reload all data (window.location.href removes state)
        }
    };

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors.errors?.length > 0) {
            backEndValidationErrors.errors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'ComplaintsProcessType':
                        setError('inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType', {
                            type: 'manual',
                            message: validationError.errorMessage,
                        });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    // trap when the add/update occurs and set the activeCMobject with the newly set CM Id
    useEffect(() => {
        if (isActive === true && ajaxCallStateForPage && complaintsMechanism && IsValidGuidIdentifier(complaintsMechanism.complaintsMechanismId)) {
            // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
            const callCompleteOKorInProgress =
                ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.InProgress || ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK;

            if (displayState.attemptingSave === true && callCompleteOKorInProgress && !appContext.wizardState.triggerSubmit) {
                // need to pack the new or existing CM record which was just updated into the activeCM object now
                CopyFromComplaintToActive(inquiry.application, complaintsMechanism);
                // inquiry.application.activeComplaintsMechanismObj = complaintsMechanism;
                setInquiryObj(inquiry);
                // store the new GUID for use
                appContext.setCurrentComplaintsMechId(complaintsMechanism.complaintsMechanismId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [complaintsMechanism]);

    // check dispatch success state
    useEffect(() => {
        if (isActive === true && ajaxCallStateForPage && ajaxCallStateForPage.pageNumber === WizardStepNumberComplaintsMech.ComplaintsMechanismType) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // ONLY time we do not show these is if in the middle of switching between options AND user has changed mind to delete an option
                if (displayState.attemptingSaveThenRemovePriorData !== true) {
                    // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                    setDisplayState({ ...displayState, attemptingSave: false, loadUpdatedApp: false });
                    displayBackEndValidations();
                }
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // ONLY time we do not show these is if in the middle of switching between options AND user has changed mind to delete an option
                if (displayState.attemptingSaveThenRemovePriorData !== true) {
                    // back end error need to display, and prevent navigation
                    setDisplayState({ ...displayState, attemptingSave: false, loadUpdatedApp: false });
                    // setAttemptingSaveThenRemovePriorData(false);
                    toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                }
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK && !appContext.wizardState.triggerSubmit) {
                if (displayState.loadUpdatedApp === true) {
                    setDisplayState({ ...displayState, attemptingSave: false, attemptingSaveThenRemovePriorData: false, loadUpdatedApp: false });

                    // handles when adding new CM and it gets an Id by this page
                    setValue('inquiry.application.activeComplaintsMechanismObj', inquiry.application.activeComplaintsMechanismObj);

                    toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);

                    Sleep(100).then(() => {
                        MoveComplaintsWizardForward(appContext, isValid);
                    });
                } else if (displayState.attemptingSave === true) {
                    // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                    // IF VALID (or OK to move forward):
                    setDisplayState({ ...displayState, attemptingSave: false, attemptingSaveThenRemovePriorData: false, loadUpdatedApp: true });

                    // refresh data set now after changes saved (otherwise Ids are empty on Add flow)
                    const doNotCopyActiveComplaint = true;
                    dispatch(appActions.GetApplication(doNotCopyActiveComplaint));
                } else if (displayState.attemptingSaveThenRemovePriorData === true) {
                    // CANNOT DO THIS ANY MORE AS WE HAVE COLLECTION - TODO: see if still needed: dispatch(appActions.RemoveComplaintsData());
                    setDisplayState({ ...displayState, attemptingSave: false, attemptingSaveThenRemovePriorData: false });

                    toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);
                    /* Sleep(750).then(() => {
                        // refresh data set now after changes saved (this is when a user saves changes after deleting data once they changed their mind)
                        dispatch(appActions.GetApplication());
                    }); */
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const handleSubmitOfAddOrResumeAdd = (data: any) => {
        // set existing data now as we dont capture it in this page
        //        EnsureData(appContext, WizardStepNumberComplaintsMech.ComplaintsMechanismType, data, inquiry);
        // Save changes to active complaints mech (attempt save, if that fails we do NOT move the Wizard step)
        setDisplayState({ ...displayState, attemptingSave: true });

        const complaintsMechId = data.inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId;
        if (complaintsMechId === undefined || complaintsMechId === null || complaintsMechId === UNINITIALISED) {
            const guid = new Guid();
            data.inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId = guid.empty;
        }

        if (
            data.inquiry.application.activeComplaintsMechanismObj.professionalMembershipType === undefined ||
            data.inquiry.application.activeComplaintsMechanismObj.professionalMembershipType === null
        ) {
            data.inquiry.application.activeComplaintsMechanismObj.professionalMembershipType =
                data.inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType.toString() ===
                ComplaintsMechanismType.FullMembershipProfessionalAssoc.toString()
                    ? ProfessionalMembershipType.FullMembership
                    : null;
        }

        // we do not store the curr app step in the CM process its not long enough to warrant:
        // setDataInquiry(data.inquiry);

        // determine if a new complaints mech or update existing (or even update an addition, i.e. user half-completed an "Add")
        // uses IsValidGuidIdentifier() check as user may go forward (add a completely new record) then back then forward again (where it updates existing record)
        if (
            resumeAddingNew !== true &&
            ((addNewComplaint === true && IsValidGuidIdentifier(data.inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId) === false) ||
                (data.inquiry.application.complaintsMechanism?.length === 0 &&
                    data.inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId === Guid.empty))
        ) {
            dispatch(appActions.AddComplaintsMechanism(data.inquiry.application.activeComplaintsMechanismObj));
        } else {
            dispatch(appActions.UpdateComplaintsMechanism(data.inquiry.application.activeComplaintsMechanismObj));
        }
    };

    const submitForm = (data: any) => {
        if (isValid) {
            // if edit mode just move forward only
            if (addNewComplaint === true || resumeAddingNew === true) {
                handleSubmitOfAddOrResumeAdd(data);
            } else {
                // just go forward - is that all we need to do?
                MoveComplaintsWizardForward(appContext, isValid);
            }
        } else {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    const setComplaintsTypeRadioButton = () => {
        // must check one then!
        const radioButtons: NodeListOf<HTMLElement> = document.getElementsByName('inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < radioButtons.length; i++) {
            const radioButton: HTMLInputElement = radioButtons[i] as HTMLInputElement;
            if (radioButton.value === complaintsMechanismTypeWatched) {
                radioButton.checked = true;
            }
        }
    };

    const radioOptionsComplaintsMechanism: RadioButtonOption[] = [
        {
            label: DisplayMechanismTypeOnceAccredited(ComplaintsMechanismType.GovernmentFundedFDRService),
            value: ComplaintsMechanismType.GovernmentFundedFDRService,
            disabled: addNewComplaint !== true && resumeAddingNew !== true,
        },
        {
            label: DisplayMechanismTypeOnceAccredited(ComplaintsMechanismType.FullMembershipProfessionalAssoc),
            value: ComplaintsMechanismType.FullMembershipProfessionalAssoc,
            disabled: addNewComplaint !== true && resumeAddingNew !== true,
            helpHeaderText: 'What is this?',
            helpText: HELPTEXT_COMPLAINTSMECHANISM_FULLMEMBERSHIP,
        },
    ];

    const doesPriorDataExistThatWillBeDeleted = (newValue: ComplaintsMechanismType) => {
        // check this only if:
        //  - full membership was orig value and now user now chooses GovtFunded, and prior data exists
        //  - govt funded was orig value and now user chooses Full membership, and prior data exists
        const isOrigValueFull =
            inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc;
        const isOrigValueGovtFunded =
            inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType === ComplaintsMechanismType.GovernmentFundedFDRService;
        const priorDataExists =
            (inquiry.application.activeComplaintsMechanismObj?.outlet?.outletId !== undefined &&
                inquiry.application.activeComplaintsMechanismObj?.outlet?.outletId !== '') ||
            (inquiry.application.activeComplaintsMechanismObj?.professionalAssociation?.professionalAssociationId !== undefined &&
                inquiry.application.activeComplaintsMechanismObj?.professionalAssociation?.professionalAssociationId !== '');

        if (
            (newValue === ComplaintsMechanismType.GovernmentFundedFDRService && isOrigValueFull && priorDataExists) ||
            (newValue === ComplaintsMechanismType.FullMembershipProfessionalAssoc && isOrigValueGovtFunded && priorDataExists)
        ) {
            return true;
        }
        return false;
    };

    const onChangeComplaintsType = async (event: any) => {
        /*
          window.confirm(
                'The data and files you have already uploaded to support your previous choice are not compatible with the new selection you have made.  This will result in the loss of data if you wish to proceed. Are you sure?',
            ) === false   
          */
        const newSelectedType: ComplaintsMechanismType = event.target.value;
        const dataExistsThatCouldBeDeleted = doesPriorDataExistThatWillBeDeleted(newSelectedType);

        function revertSelection() {
            // Setting the target.checked property to the defaultChecked will
            // deselect the current radio button and select the radio button that
            // was previously selected.
            event.target.checked = event.target.defaultChecked;
            event.preventDefault();
            event.stopPropagation();
            // return the orig value
            setValue(
                'inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType',
                inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType,
                {
                    shouldDirty: true,
                    shouldValidate: true,
                },
            );
            setComplaintsTypeRadioButton();
            return false;
        }

        function changeSelection() {
            // manage the value manually as ref did not work for the react hook form
            setValue('inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType', newSelectedType, {
                shouldDirty: true,
                shouldValidate: true,
            });
        }

        if (dataExistsThatCouldBeDeleted === true) {
            if (
                await ModalDialogConfirmable(MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_ACCREDITED, MODALBUTTON1_OK, MODALBUTTON2_CANCEL, {
                    title: MODALHEADER_DATACHANGEPOTENTIAL,
                    confirmationP2: MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_ACCREDITED_P2,
                    confirmationP3: MODALTEXT_DATALOSSPOTENTIAL_COMPLAINTTYPE_ACCREDITED_P3,
                    dataTestId: 'modalPromptToRemoveData_ChangeComplaintsType',
                    isDestructive: true,
                })
            ) {
                changeSelection();
                // user has accepted that data can be removed so we remove that now
                removePriorData(newSelectedType);
            } else {
                revertSelection();
            }
        } else {
            changeSelection();
        }
    };

    const displayInputFields = () => {
        const typeFieldLabel =
            addNewComplaint === true || resumeAddingNew === true
                ? 'To add a new complaints mechanism, select the capacity in which you will be delivering your FDR services'
                : 'The capacity in which you will be delivering your FDR services.';

        return (
            <>
                <RadioButtonGroupField
                    options={radioOptionsComplaintsMechanism}
                    id='inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType'
                    displayName={typeFieldLabel}
                    guidanceText=''
                    fieldLabel=''
                    defaultValue={inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismType} // only set default if value exists
                    isMandatory={true}
                    control={control}
                    register={register}
                    errorsField={errors.inquiry?.application?.activeComplaintsMechanismObj?.complaintsMechanismType}
                    // autoFocus={true}
                    onChange={onChangeComplaintsType}
                />
            </>
        );
    };

    const pageTitleText =
        addNewComplaint === true || resumeAddingNew === true
            ? 'Add a complaints mechanism for an FDR service outlet'
            : 'Update complaints mechanism for FDR service outlet';

    SubmitThisPageIfActive(isActive, triggerSubmit, formRef, setTriggerSubmit);

    const textForSaveContinueButton = addNewComplaint === true || resumeAddingNew === true ? 'Save and Continue' : 'Continue';

    return (
        <>
            <PageTitle title={pageTitleText} description={HEADER_COMPLAINTS} />
            <PageWrapper
                pageName='ComplaintsType'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={handleClickPreviousButton}
                textForSaveContinueButton={textForSaveContinueButton}
            >
                <PageFieldsTitle title='Outlet type' />
                <PageMandatoryLabelText />
                <ErrorSummary errors={errors} />
                {displayState.attemptingSave === true && <Loader isLoading={true} loaderText='Saving, please wait...' />}
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.applicationId'
                    {...register('inquiry.application.applicationId')}
                    defaultValue={inquiry.application.applicationId}
                />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.accreditationStatus'
                    {...register('inquiry.application.accreditationStatus')}
                    defaultValue={inquiry.application.accreditationStatus}
                />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId'
                    {...register('inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId')}
                    defaultValue={inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId}
                />
                <LabelField id='complaintsMechanismBlurbText1' isMandatory={false} displayName='' value={COMPLAINTS_MECHANISM_BLURB_ADDCM_PARAGRAPH1} />
                <LabelField id='complaintsMechanismBlurbText2' isMandatory={false} displayName='' value={COMPLAINTS_MECHANISM_BLURB_ADDCM_PARAGRAPH2} />
                <EmptyRow />
                {displayInputFields()}
            </PageWrapper>
        </>
    );
};

export default ComplaintsMechanismTypePage;

// {addNewComplaint !== true && resumeAddingNew !== true && <TipsControl tipString={TIP_COMPLAINTSMECHANISM_TYPE_NOTCHANGED} />}
