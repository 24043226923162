import React from 'react';
import { Row, Col } from 'react-bootstrap';
import MandatoryLabelIndicator from './MandatoryLabelIndicator';
import GuidanceText from '../../controls/GuidanceText';
import HelpText from '../../controls/HelpText';
import styles from './Fields.module.scss';

const { fieldTitle, fieldValidationError, validationErrorRow, fieldFinalRow, selectListRow, fieldTitleHorizontal, fieldWhatIsThisText } = styles;

export interface SelectListOption {
    value: string;
    text: string;
    type?: string;
}

export interface SelectListFieldProps {
    id: string;
    displayName: string;
    isMandatory: boolean;
    options: SelectListOption[];
    defaultValue: string;
    disabled: boolean;
    register: any;
    errorsField: any;
    children?: any;
    handleOnChange?: any;
    guidanceText?: string;
    whatIsThisText?: string;
    helpHeaderText?: string;
    helpText?: string;
    isHorizontal?: boolean;
    hideFinalEmptyRow?: boolean;
    isDoubleWidth?: boolean;
    isOneAndHalfWidth?: boolean;
    capitaliseTextOff?: boolean;
    autoFocus?: boolean;
    // onBlur?: any; NOTE: do NOT use onBlur() it messes up the form tech and save of the inquiry object
}

const SelectListField = (props: SelectListFieldProps) => {
    const {
        id,
        displayName,
        isMandatory,
        options,
        defaultValue,
        disabled,
        register,
        errorsField,
        children,
        guidanceText,
        whatIsThisText,
        helpHeaderText,
        helpText,
        handleOnChange,
        isHorizontal,
        hideFinalEmptyRow,
        isDoubleWidth,
        isOneAndHalfWidth,
        capitaliseTextOff,
        autoFocus,
    } = props;

    const colWidthForListBox = isDoubleWidth === true ? '8' : isOneAndHalfWidth === true ? '6' : '4';
    const ariaInvalid = errorsField && errorsField.type ? 'true' : 'false';
    // const [defaultValueString, setDefaultValueString] = useState(defaultValue === undefined ? '' : defaultValue);
    const defaultValueString = defaultValue === undefined ? '' : defaultValue;

    /* this causes grief for selectability so just leave it alone and suffer the other way (see comments below)
    const onMouseLeave = (event: any) => {
        // to enable next click on any other element we need to remove the focus from this set of elements
        // event?.stopPropagation();
        event!.target.blur();
    }; */

    const mapOptions = () => {
        return (
            <>
                {options.map(option => {
                    return (
                        <option key={option.value} value={option.value} data-option-type={option.type}>
                            {option.text}
                        </option>
                    );
                })}
            </>
        );
    };

    // IMPORTANT NOTE: This may not use the forms *register* functionality properly so on change event may need to hook up value MANUALLY!!
    const selectOptions = () => {
        const key = `${id}_${defaultValueString}`;

        return (
            <select
                className={`form-control ${capitaliseTextOff ? '' : 'text-capitalize'}`}
                key={key}
                id={id}
                name={id}
                defaultValue={defaultValueString}
                disabled={disabled}
                aria-invalid={ariaInvalid}
                {...register(id)}
                // onChange={handleOnChangeInternal}
                // onMouseLeave={onMouseLeave}
                autoFocus={autoFocus}
            >
                {mapOptions()}
            </select>
        );
    };

    if (isHorizontal === true) {
        // no help text just simple horizontal layout
        return (
            <>
                <Row>
                    <Col md='3' className={fieldTitleHorizontal}>
                        <label className={fieldTitle} htmlFor={id}>
                            {isMandatory && <MandatoryLabelIndicator />}
                            {displayName}
                        </label>
                    </Col>
                    <Col md={colWidthForListBox}>{selectOptions()}</Col>
                </Row>
                <Row>
                    <Col md='3' />
                    <Col className={fieldValidationError}>{errorsField && <span role='alert'>{errorsField.message}</span>}</Col>
                </Row>
                <Row className={validationErrorRow}>
                    <Col md='3' />
                    <Col className={fieldValidationError}>
                        {errorsField && errorsField.validate && <span role='alert'>{errorsField.validate.message}</span>}
                    </Col>
                </Row>
                {!hideFinalEmptyRow && (
                    <Row className={fieldFinalRow}>
                        <Col />
                    </Row>
                )}
            </>
        );
    }
    const onChangeClicked = (e: any) => {
        handleOnChange(e);
    };

    // IMPORTANT NOTE: This may not use the forms *register* functionality properly so on change event may need to hook up value MANUALLY!!
    const selectOptionsWithOnChange = () => {
        return (
            <select
                className={`form-control ${capitaliseTextOff ? '' : 'text-capitalize'}`}
                id={id}
                name={id}
                defaultValue={defaultValueString}
                disabled={disabled}
                aria-invalid={ariaInvalid}
                {...register(id)}
                onChange={onChangeClicked}
                // onMouseLeave={onMouseLeave}
                autoFocus={autoFocus}
            >
                {mapOptions()}
            </select>
        );
    };

    return (
        <>
            <Row>
                <Col>
                    <label className={fieldTitle} htmlFor={id}>
                        {isMandatory && <MandatoryLabelIndicator />}
                        {displayName}
                    </label>
                </Col>
            </Row>
            {whatIsThisText && (
                <Row>
                    <Col className={fieldWhatIsThisText}>
                        <HelpText helpHeaderText='What is this?' helpText={whatIsThisText} fieldName={id} />
                    </Col>
                </Row>
            )}
            {guidanceText && (
                <Row>
                    <Col>
                        <GuidanceText guidanceText={guidanceText} fieldName={id} />
                    </Col>
                </Row>
            )}
            <Row className={selectListRow}>
                <Col className={`col-md-${colWidthForListBox}`}>{handleOnChange ? selectOptionsWithOnChange() : selectOptions()}</Col>
            </Row>
            <Row>
                <Col className={fieldValidationError}>{errorsField && <span role='alert'>{errorsField.message}</span>}</Col>
            </Row>
            <Row className={validationErrorRow}>
                <Col className={fieldValidationError}>{errorsField && errorsField.validate && <span role='alert'>{errorsField.validate.message}</span>}</Col>
            </Row>
            {helpHeaderText && (
                <Row>
                    <Col>
                        <HelpText helpHeaderText={helpHeaderText} helpText={helpText} fieldName={id} />
                    </Col>
                </Row>
            )}
            <>{children}</>
            {!hideFinalEmptyRow && (
                <Row className={fieldFinalRow}>
                    <Col />
                </Row>
            )}
        </>
    );
};

export default SelectListField;
