import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { IsEnterOrSpaceKeyPressed } from '../utils/Common';
import { RadioButtonOption } from '../components/fields/RadioButtonGroupField';
import HelpText from './HelpText';
import NavLink from './NavLink';
import stylesRadioButtonGroup from './Controls.module.scss';

const {
    radioGroupLegend,
    radioButtonRow,
    radioButtonHelpHeaderRow,
    radioButtonInput,
    radioButtonLabel,
    radioButtonLabelDisabled,
    radioButtonLabelWithHyperlink,
} = stylesRadioButtonGroup;

interface RadioGroupProps {
    id: string;
    fieldLabel: string;
    name: string;
    defaultValue: any;
    options: RadioButtonOption[];
    onChange: any;
}

// const RadioGroup = React.forwardRef((props: RadioGroupProps, ref: React.ForwardedRef<HTMLInputElement> = null) => {
const RadioGroup = (props: RadioGroupProps) => {
    const { id, fieldLabel, name, defaultValue, options, onChange } = props;

    const handleChange = (event: { target: { value: any } }) => {
        onChange(event, event.target.value);
    };

    const selectRadioOption = (idHtmlDom: string) => {
        // find by Id in Html DOM i.e. = inquiry.application.hasDeclaredAndConsented-0
        const radioButton = window.document.getElementById(idHtmlDom);
        radioButton?.click();
    };

    const constructLabelWithHyperlink = (option: RadioButtonOption) => {
        const navLinkHrefLink = option.navLinkUrl ? option.navLinkUrl : '/';
        const displayText = option.navLinkText ? option.navLinkText : '';

        return (
            <span className={radioButtonLabelWithHyperlink}>
                {option.label}&nbsp;
                <NavLink className='nav-link' hrefLink={navLinkHrefLink} openInNewTab={true} displayText={displayText} />
            </span>
        );
    };

    return (
        <fieldset>
            <legend id={id} className={radioGroupLegend}>
                {fieldLabel}
            </legend>
            <div>
                {options.map((option: RadioButtonOption, index: React.Key | null | undefined) => {
                    const indexString = `${id}-${index && index === null ? '' : index!.toString()}`;

                    let labelValue = <span>{option.label}</span>;
                    if (option.navLinkText && option.navLinkUrl && option.navLinkText !== '') {
                        labelValue = constructLabelWithHyperlink(option);
                    }

                    const handleKeypress = (e: any) => {
                        if (IsEnterOrSpaceKeyPressed(e)) {
                            e.preventDefault();
                            selectRadioOption(indexString);
                        }
                    };

                    return (
                        <div key={index}>
                            <Row key={index} className={radioButtonRow}>
                                <Col>
                                    <div>
                                        <input
                                            type='radio'
                                            id={indexString}
                                            key={indexString}
                                            tabIndex={-1} // hidden so do not receive focus
                                            name={name}
                                            value={option.value}
                                            className={radioButtonInput}
                                            defaultChecked={option.value === defaultValue} // NOTE: use defaultChecked instead of checked for forms
                                            onChange={handleChange} // TODO: Confirm isDirty is set for the form when using this!
                                            disabled={option.disabled}
                                            // ref={ref}
                                        />
                                        <label
                                            id={`${indexString}-label`}
                                            tabIndex={0}
                                            htmlFor={indexString}
                                            className={`${option.disabled ? radioButtonLabelDisabled : radioButtonLabel}`}
                                            onKeyPress={handleKeypress}
                                        >
                                            {labelValue}
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            {option.helpHeaderText && (
                                <Row className={radioButtonHelpHeaderRow}>
                                    <Col>
                                        <HelpText helpHeaderText={option.helpHeaderText} helpText={option.helpText} fieldName={indexString} />
                                    </Col>
                                </Row>
                            )}
                            <>{option.children}</>
                        </div>
                    );
                })}
            </div>
        </fieldset>
    );
    // });
};

export default RadioGroup;
