import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import { LINK_AG_COPYRIGHT, LINK_AG_PRIVACY } from '../../services/Constants';
import DevilsToolboxMain from '../../components/devilsToolbox/devilsToolboxMain';
import NavLink from '../../controls/NavLink';
import './PageFooter.scss';

interface PageFooterProps {
    authService: AuthService;
}

function PageFooter(props: PageFooterProps) {
    const { authService } = props;

    return (
        <Router>
            <footer className='footer'>
                <div className='container-fluid'>
                    <div className='row justify-content-center'>
                        <div className='col-8'>Copyright Commonwealth of Australia {new Date().getFullYear()}</div>
                        <div className='col-4'>
                            <ul className='nav'>
                                <li className='nav-item'>
                                    <NavLink
                                        className='nav-link'
                                        hrefLink={LINK_AG_COPYRIGHT}
                                        openInNewTab={true}
                                        displayText='Copyright'
                                        dataTestId='SiteMenuCopyrightLink'
                                    />
                                </li>
                                <li className='nav-item'>
                                    <NavLink
                                        className='nav-link'
                                        hrefLink='/disclaimer'
                                        openInNewTab={false}
                                        displayText='Disclaimer'
                                        dataTestId='SiteMenuDisclaimerLink'
                                    />
                                </li>
                                <li className='nav-item'>
                                    <NavLink
                                        className='nav-link'
                                        hrefLink={LINK_AG_PRIVACY}
                                        openInNewTab={true}
                                        displayText='Privacy Statement'
                                        dataTestId='SiteMenuPrivacyLink'
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <DevilsToolboxMain authService={authService} />
            </footer>
        </Router>
    );
}

export default PageFooter;
