import React from 'react';
import { Form, Container } from 'react-bootstrap';
import PageActionFooter from '../pageActionFooter';
import styles from './pageWrapper.module.scss';

const { pageContainerContent, pageFormContent, pageFieldsContent, pageContainerContentHalfHeight, pageFieldsContentHalfHeight, pageFormContentHalfHeight } =
    styles;

interface PageWrapperProps {
    pageName: string;
    formRef?: any;
    handleSubmit: any;
    handleClickPrevious: any;
    includeSaveContinueButton?: boolean;
    textForSaveContinueButton?: string;
    textForBackButton?: string;
    includeMiddleButton?: boolean;
    textForMiddleButton?: string;
    middleButtonClickHandler?: any;
    middleButtonVariant?: string;
    includeMiddleButton2?: boolean;
    textForMiddleButton2?: string;
    middleButton2ClickHandler?: any;
    includeMiddleButton3?: boolean;
    textForMiddleButton3?: string;
    middleButton3ClickHandler?: any;
    middleButton3Variant?: string;
    hideBackButton?: boolean;
    formFieldsHalfHeight?: boolean;
    children?: React.ReactNode;
}

export const PageWrapper: React.FC<PageWrapperProps> = ({ ...props }) => {
    const {
        pageName,
        formRef,
        handleSubmit,
        handleClickPrevious,
        includeSaveContinueButton,
        textForSaveContinueButton,
        textForBackButton,
        includeMiddleButton,
        textForMiddleButton,
        middleButtonClickHandler,
        middleButtonVariant,
        includeMiddleButton2,
        textForMiddleButton2,
        middleButton2ClickHandler,
        includeMiddleButton3,
        textForMiddleButton3,
        middleButton3ClickHandler,
        middleButton3Variant,
        hideBackButton,
        formFieldsHalfHeight,
    } = props;

    const pageContainerContentClass = formFieldsHalfHeight === true ? `${pageContainerContentHalfHeight}` : `${pageContainerContent}`;
    const pageFormContentClass = formFieldsHalfHeight === true ? `${pageFormContentHalfHeight}` : `${pageFormContent}`;
    const pageFieldContentClass = formFieldsHalfHeight === true ? `${pageFieldsContentHalfHeight}` : `${pageFieldsContent}`;

    return (
        <>
            <Container className={`${pageContainerContentClass} col col-md-11`}>
                <Form ref={formRef} data-testid={`Form_${pageName}`} className={`form-group ${pageFormContentClass}`} onSubmit={handleSubmit}>
                    <div className={pageFieldContentClass}>{props.children}</div>
                    <PageActionFooter
                        pageName={pageName}
                        handleClickPrevious={handleClickPrevious}
                        includeSaveContinueButton={includeSaveContinueButton}
                        textForSaveContinueButton={textForSaveContinueButton}
                        textForBackButton={textForBackButton}
                        includeMiddleButton={includeMiddleButton}
                        textForMiddleButton={textForMiddleButton}
                        middleButtonClickHandler={middleButtonClickHandler}
                        middleButtonVariant={middleButtonVariant}
                        includeMiddleButton2={includeMiddleButton2}
                        textForMiddleButton2={textForMiddleButton2}
                        middleButton2ClickHandler={middleButton2ClickHandler}
                        includeMiddleButton3={includeMiddleButton3}
                        textForMiddleButton3={textForMiddleButton3}
                        middleButton3ClickHandler={middleButton3ClickHandler}
                        middleButton3Variant={middleButton3Variant}
                        hideBackButton={hideBackButton}
                    />
                </Form>
            </Container>
        </>
    );
};

export default PageWrapper;
