import { toast, ToastOptions } from 'react-toastify';

const autoCloseTimeMilliseconds = 2500;

const toastOptions: ToastOptions = {
    position: 'top-center',
    autoClose: autoCloseTimeMilliseconds,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

// Todo: kept them seperate to configure different options for each, might want to have a single function
export const toastSuccessMessage = (message: string) => {
    toast.success(message, toastOptions);
};

export const toastInfoMessage = (message: string) => {
    toast.info(message, toastOptions);
};

export const toastWarningMessage = (message: string) => {
    toast.warn(message, toastOptions);
};

export const toastErrorMessage = (message: string) => {
    toast.error(message, toastOptions);
};
