import * as React from 'react';
import BackGroundImage from '../backgroundImage.png';
import NavLink from '../controls/NavLink';

function Welcome() {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-8 p-0'>
                    <div
                        className='jumbotron jumbotron-fluid bg-info d-flex flex-column justify-content-center d-none d-sm-none d-md-block vh-100'
                        style={{ backgroundImage: `url("${BackGroundImage}")`, backgroundSize: 'cover', marginBottom: '-1px' }}
                    >
                        <div className='w-50 mx-auto'>
                            <h1 className='display-3 font-weight-bold mb-0'>FLAIR</h1>
                            <h3 className='mb-5'>Family Law Accreditation Integrated Register</h3>
                            <p className='lead'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam bibendum enim eget ex gravida, eu venenatis diam interdum.
                                Integer tempus, ipsum at tempus efficitur, erat lectus lobortis ligula, non imperdiet purus nisl quis lectus. Integer in
                                sollicitudin purus, vitae sodales nulla. Sed pulvinar tempus congue.
                            </p>
                            <button
                                type='button'
                                className='btn btn-light rounded-pill w-100 text-left font-weight-bold py-3'
                                style={{ fontVariant: 'normal' }}
                            >
                                <img src='images/begin.png' className='mt-1 mx-3' style={{ width: '48px' }} alt='' />
                                <span className='h5'>View the Register</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 px-5 py-3' style={{ backgroundColor: 'rgb(247,247,252)' }}>
                    <nav className='nav flex-column'>
                        <NavLink className='nav-link' hrefLink='#' displayText='Login' />
                        <NavLink className='nav-link' hrefLink='http://localhost:51626' displayText='Create Account' />
                        <NavLink className='nav-link' hrefLink='#' displayText='Forgot Password' />
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Welcome;
