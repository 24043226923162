import * as types from '../../actions/actionTypes';
import { IApplication, IComplaintMechanism, IOpeningHours } from '../../api/interfacesApi';
import { ConvertDayToEnumInt } from '../../utils/EnumMappings';
import { formatDateForDateFns } from '../../utils/Dates';
import {
    AddNewComplaintMechanismObject,
    ComplaintsArrayIndexes,
    InitialiseComplaintsArray,
    CopyFromComplaintCollectionToActive,
    KeepOriginalCMsAndNewCMsOnly,
    MergeOriginalsIntoEditCopyCM,
    GetLatestComplaintsMechanismCollection,
} from '../../utils/ComplaintsUtils';
import { IsNumeric } from '../../utils/Common';
import { AdditionalServiceDeliveryType, ClientGroupType, OtherServiceType, ScopeType, ServiceDeliveryType } from '../../services/Enums';
import { defaultApplicationData, defaultBusinessAddressData, defaultOutletPractitionerData } from '../../api/defaultData';
import { UNINITIALISED } from '../../services/Constants';

const initOpeningHours: IOpeningHours = {
    openingHoursId: UNINITIALISED,
    outletId: '',
    day: null,
    openTime: null,
    closeTime: null,
    isOpen: null,
};

/*
const isValidMechanismId = (mechanismId: string | null | undefined) => {
    if (mechanismId === undefined || mechanismId === null || mechanismId === '' || mechanismId.startsWith(UNINITIALISED)) {
        return false;
    } else {
        return true;
    }
}; */

const application = (state = defaultApplicationData, action: types.ApplicationActionTypes) => {
    let app: IApplication;

    switch (action.type) {
        case types.GET_APPLICATION_SUCCESS:
            // const maxIndexOfComplaintsArray = 9;
            // const guid = new Guid();
            app = { ...action.application };

            // fix up dates
            if (app.nmasExpiryDate) {
                app.nmasExpiryDate = formatDateForDateFns(app.nmasExpiryDate);
            }
            if (app.complaintsMechanism && app.complaintsMechanism?.length > 0) {
                // first thing to do is to get the active CM set, which removes any superceded (but not EditCopy) CM records, and reverse-sorts so our Index=0 points to the latest one
                app.complaintsMechanism = GetLatestComplaintsMechanismCollection(app.complaintsMechanism);

                app.complaintsMechanism.forEach((complaint: IComplaintMechanism) => {
                    complaint.membershipFromDate = complaint.membershipFromDate && formatDateForDateFns(complaint.membershipFromDate);
                    complaint.membershipToDate = complaint.membershipToDate && formatDateForDateFns(complaint.membershipToDate);
                    complaint.professionalIndemnityInsuranceFromDate =
                        complaint.professionalIndemnityInsuranceFromDate && formatDateForDateFns(complaint.professionalIndemnityInsuranceFromDate);
                    complaint.professionalIndemnityInsuranceToDate =
                        complaint.professionalIndemnityInsuranceToDate && formatDateForDateFns(complaint.professionalIndemnityInsuranceToDate);
                    complaint.recordActiveFromDate = complaint.recordActiveFromDate && formatDateForDateFns(complaint.recordActiveFromDate);
                    complaint.recordActiveToDate = complaint.recordActiveToDate && formatDateForDateFns(complaint.recordActiveToDate);
                    complaint.modifiedDate = complaint.modifiedDate && formatDateForDateFns(complaint.modifiedDate);
                });
            }

            // Load existing record and/or attachments - noting that a user may add 1..9 attachments without saving the record to DB (ie browser exit or crash)
            const complaintsExist = app.complaintsMechanism !== undefined && app.complaintsMechanism !== null && app.complaintsMechanism.length > 0;
            // const membershipExists = complaintsExist && IsAnyComplaintProfAssocMember(app.complaintsMechanism);

            // below handles initial load with 1..9 data elements, we pad out to the 9 elements with empty ones to give user full objects to populate if they wish
            if (complaintsExist === true) {
                // Merge superceded (edit copy CMs) as that is (not yet) done on the back-end, especially for Cancelled but not submitted CMs
                // if any EditCopy CMs exist we need to knock out their orig-SupercededById line items
                const foundEditCopyCMs = app.complaintsMechanism.filter(item => item.isEditCopy === true);
                if (foundEditCopyCMs && foundEditCopyCMs.length > 0) {
                    // for Review and Submit page we need to ditch Edit CMs (as the diff object should contain entries as required), other Add CMs we keep
                    // MergeOriginalsIntoEditCopyCM - removed as EDIT UI now has a clone containing exact fields, I think it *might* only be needed for REMOVE (that clones too - should be redundant now!)
                    action.keepOriginalCMsAndNewCMsOnly === true
                        ? (app.complaintsMechanism = KeepOriginalCMsAndNewCMsOnly(app.complaintsMechanism))
                        : (app.complaintsMechanism = MergeOriginalsIntoEditCopyCM(app.complaintsMechanism));
                }

                // MUST sort by Created Date DESC so we can set active CM to index 0 (as we have modified collection based on keeping/chucking out editcopy records)
                // app.complaintsMechanism = app.complaintsMechanism.sort((a, b) => a.modifiedDate > b.modifiedDate ? -1 : 1);
                // DESC is b - a
                app.complaintsMechanism.sort((a, b) => b.modifiedDate!.getTime() - a.modifiedDate!.getTime());

                // for each of the elements not loaded from the DB we pad with empty object
                ComplaintsArrayIndexes.forEach(index => {
                    const complaint = app.complaintsMechanism[index];
                    // NOTE: if !complaint will mean this entry is empty so we HAVE to fill it regardless of type, later we move prof assoc to last
                    if (!complaint) {
                        AddNewComplaintMechanismObject(app);
                    }
                });
            } else {
                // below handles initial load with no data
                InitialiseComplaintsArray(app);
            }

            if (action.doNotCopyActiveComplaint === true) {
                // handle this outside of the return function
            } else {
                // if any complaints exist we make the first one the active one now, otherwise pack active as default
                const firstCMid = app.complaintsMechanism[0].complaintsMechanismId;
                CopyFromComplaintCollectionToActive(app, firstCMid);
            }

            /*
            if (complaintsExist) {
                // for each of the elements not loaded from the DB we pad with empty object
                ComplaintsArrayIndexes.forEach(index => {
                    const complaint = app.complaintsMechanism[index];

                    // ignore if user had saved a membership now coming back to this UI
                    if (complaint && complaint.complaintsProcessType !== ComplaintsProcessType.ProfessionalAssociationMembership) {
                        // GovtFunded complaint might not exist or if exists have we set the Id
                        if (!complaint || isValidMechanismId(complaint.complaintsMechanismId) === false) {
                            AddNewComplaintMechanismObject(app);
                        } else {
                            // there is a chance user removed a file, which clears the evidence Id, and then leaves and comes back into form
                            if (complaint.activeAttachmentId === null && complaint.evidence === null && isValidMechanismId(complaint.complaintsMechanismId)) {
                                app.complaintsMechanism[index] = {
                                    ...complaint,
                                    evidence: initAttachment,
                                    attachmentExists: initAttachment.attachmentId,
                                    activeAttachmentId: guid.empty,
                                };
                            }
                        }
                    } else if (!complaint && index > 0 && index < maxIndexOfComplaintsArray) {
                        AddNewComplaintMechanismObject(app);
                    } else if (!complaint && index === maxIndexOfComplaintsArray) {
                        // && !membershipExists) {
                        // NOTE: if !complaint will mean this entry is empty so we HAVE to fill it regardless of type, later we move prof assoc to last
                        AddNewComplaintMechanismObject(app);
                    }
                });
            }

            // below handles initial load with no data
            if (complaintsExist === false) {
                InitialiseComplaintsArray(app);
            } */

            /* NO NEED TO DO THIS ANY MORE - WE HAVE SELF-SERVICE CM NOW:
            // MUST sort to place Prof LAST as we hunt for index 0 on the Employee UI
            if (
                complaintsExist &&
                membershipExists // &&
                // app.activeComplaintsMechanismObj.complaintsProcessType === ComplaintsProcessType.ProfessionalAssociationMembership
            ) {
                // We need the array to contain membership in index=9, and complaints employee in indexes 0..8
                const tempComplaints: IComplaintMechanism[] = Array.from({ length: 10 });
                let bFoundMembershipItem = false;
                let currIndex = 0;

                app.complaintsMechanism.forEach(item => {
                    if (item.complaintsProcessType === ComplaintsProcessType.ProfessionalAssociationMembership) {
                        bFoundMembershipItem = true;
                        tempComplaints[maxIndexOfComplaintsArray] = item;
                    } else {
                        // if found then we subtract 1 from index as we push all non-membership items up one in the array
                        if (bFoundMembershipItem === true) {
                            tempComplaints[currIndex - 1] = item;
                        } else {
                            tempComplaints[currIndex] = item;
                        }
                    }
                    currIndex += 1;
                });
                app.complaintsMechanism = tempComplaints;
                // app.complaintsMechanism.reverse(); // place this element LAST
            } */

            /* not sure is needed:
            // if app.attachments have more GovtFunded attachments than records due to browser crash/user leave then we sync object now
            if (app.attachments && HasComplaintsGovtFundedAttachments(app.attachments)) {
                const allGovtFundedActiveAttachments = GetComplaintsGovtFundedActiveAttachments(app.attachments);
                if (allGovtFundedActiveAttachments && app.complaintsMechanism.length < allGovtFundedActiveAttachments.length) {
                    // add as many new complaintsMechanism objects as required
                    const diffCount = allGovtFundedActiveAttachments.length - app.complaintsMechanism.length;
                    for (let idx = app.complaintsMechanism.length; idx <= diffCount; idx++) {
                        // add 1..10 at most
                        AddNewComplaintMechanismObject(app);
                    }
                }
            } */

            /*
            // if complaints exist we need to pack the UI only activeComplaintsMechanismObj object
            // locate the membership type within complaints (if exists), or set default
            if (app.activeComplaintsMechanismObj === undefined || app.activeComplaintsMechanismObj === null) {
                app.activeComplaintsMechanismObj = GetComplaintsMembership(app.complaintsMechanism);
                // if still NULL set default now
                if (app.activeComplaintsMechanismObj === undefined || app.activeComplaintsMechanismObj === null) {
                    // map ProfMembershipType from the Type UI selection if exists
                    app.activeComplaintsMechanismObj = {
                        ...defaultComplaintsMechanismData,
                        complaintsMechanismId: guid.empty,
                        complaintsProcessType: ComplaintsProcessType.ProfessionalAssociationMembership,
                    };
                }

                // map ProfMembershipType from the Type UI selection if exists
                app.activeComplaintsMechanismObj = {
                    ...app.activeComplaintsMechanismObj,
                    professionalMembershipType:
                        app.activeComplaintsMechanismObj?.complaintsMechanismType !== null &&
                        app.activeComplaintsMechanismObj?.complaintsMechanismType?.toString() ===
                            ComplaintsMechanismType.FullMembershipProfessionalAssoc.toString()
                            ? ProfessionalMembershipType.FullMembership
                            : null,
                };
            } */

            /* if (app.activeComplaintsMechanismObj && complaintsMembership(app.activeComplaintsMechanismObj)?.professionalAssociation) {
                // set it now
                app.activeComplaintsMechanismObj.professionalAssociation = {
                    professionalAssociationId: app.activeComplaintsMechanismObj.professionalAssociation.professionalAssociationId,
                    name: app.activeComplaintsMechanismObj.professionalAssociation.name,
                };
            }*/

            const outletsExist = app.outlets !== undefined && app.outlets !== null && app.outlets.length > 0;
            // TODO: How to map the Outlet (outlets[0]) to that of a Practitioner when we receive the collection
            app.outletPractitioner = outletsExist === true ? app.outlets![0] : { ...defaultOutletPractitionerData };

            if (app.outletPractitioner && app.outletPractitioner.serviceDelivery.length > 0) {
                app.outletPractitioner.serviceDelivery.forEach(serviceDeliveryRec => {
                    if (serviceDeliveryRec.id.toString() === ServiceDeliveryType.FaceToFace.toString()) {
                        app.outletPractitioner!.isServiceDeliveryFaceToFace = true;
                    }
                    if (serviceDeliveryRec.id.toString() === ServiceDeliveryType.Telephone.toString()) {
                        app.outletPractitioner!.isServiceDeliveryPhone = true;
                    }
                    if (serviceDeliveryRec.id.toString() === ServiceDeliveryType.Online.toString()) {
                        app.outletPractitioner!.isServiceDeliveryOnline = true;
                    }
                });
            }

            if (app.outletPractitioner.businessAddress === null) {
                app.outletPractitioner.businessAddress = defaultBusinessAddressData;
            }

            if (app.outletPractitioner && app.outletPractitioner.additionalServiceDelivery.length > 0) {
                app.outletPractitioner.additionalServiceDelivery.forEach(additionalServiceDeliveryRec => {
                    if (additionalServiceDeliveryRec.id.toString() === AdditionalServiceDeliveryType.Comediation.toString()) {
                        app.outletPractitioner!.isAdditionalComediation = true;
                    }
                    if (additionalServiceDeliveryRec.id.toString() === AdditionalServiceDeliveryType.GenderBalanced.toString()) {
                        app.outletPractitioner!.isAdditionalGenderBalanced = true;
                    }
                    if (additionalServiceDeliveryRec.id.toString() === AdditionalServiceDeliveryType.LegallyAssisted.toString()) {
                        app.outletPractitioner!.isAdditionalLegallyAssist = true;
                    }
                    if (additionalServiceDeliveryRec.id.toString() === AdditionalServiceDeliveryType.ChildInclusive.toString()) {
                        app.outletPractitioner!.isAdditionalChildInclusive = true;
                    }
                });
            }

            if (app.outletPractitioner && app.outletPractitioner.scope.length > 0) {
                app.outletPractitioner.scope.forEach(scopeRec => {
                    if (scopeRec.id.toString() === ScopeType.ChildSupport.toString()) {
                        app.outletPractitioner!.isScopeChildSupport = true;
                    }
                    if (scopeRec.id.toString() === ScopeType.Property.toString()) {
                        app.outletPractitioner!.isScopeProperty = true;
                    }
                    if (scopeRec.id.toString() === ScopeType.TheHague.toString()) {
                        app.outletPractitioner!.isScopeTheHague = true;
                    }
                });
            }

            if (app.outletPractitioner && app.outletPractitioner.clientGroup.length > 0) {
                app.outletPractitioner.clientGroup.forEach(clientGroupRec => {
                    if (clientGroupRec.id.toString() === ClientGroupType.Lgbtqi.toString()) {
                        app.outletPractitioner!.isClientGroupLgbtqi = true;
                    }
                    if (clientGroupRec.id.toString() === ClientGroupType.Carers.toString()) {
                        app.outletPractitioner!.isClientGroupCarers = true;
                    }
                    if (clientGroupRec.id.toString() === ClientGroupType.Aboriginal.toString()) {
                        app.outletPractitioner!.isClientGroupAboriginal = true;
                    }
                    if (clientGroupRec.id.toString() === ClientGroupType.TorresStrait.toString()) {
                        app.outletPractitioner!.isClientGroupTorresStrait = true;
                    }
                    if (clientGroupRec.id.toString() === ClientGroupType.CulturallyDiverse.toString()) {
                        app.outletPractitioner!.isClientGroupCulturallyDiverse = true;
                    }
                });
            }

            if (app.outletPractitioner && app.outletPractitioner.otherService.length > 0) {
                app.outletPractitioner.otherService.forEach(scopeRec => {
                    if (scopeRec.id.toString() === OtherServiceType.Practitioner.toString()) {
                        app.outletPractitioner!.isOtherServicePractitioners = true;
                    }
                    if (scopeRec.id.toString() === OtherServiceType.Student.toString()) {
                        app.outletPractitioner!.isOtherServiceStudents = true;
                    }
                });
            }

            const newOpeningHours =
                app.outletPractitioner && (app.outletPractitioner.openingHours === null || app.outletPractitioner.openingHours.length === 0);
            if (app.outletPractitioner) {
                let existingOpeningHours: IOpeningHours[] = [];
                if (!newOpeningHours) {
                    // clone into new collection so we can insert back when we find that data by day
                    existingOpeningHours = [...app.outletPractitioner.openingHours]; // use square brackets to clone array
                }

                // reset (temporarily if data exists) to empty object for week worth of data
                app.outletPractitioner.openingHours = [];

                // pad out the collection and obtain either from DB or init object for UI
                for (let idx = 0; idx < 7; idx++) {
                    if (newOpeningHours) {
                        const openingHours = { ...initOpeningHours }; // need a new clone of the object each time
                        openingHours.outletId = app.outletPractitioner.outletId;
                        openingHours.day = idx + 1; // MUST use index = 1 to 7 to match day INT
                        app.outletPractitioner.openingHours.push(openingHours);
                    } else {
                        // need to check if in collection already by DAY (id from 1 to 7) or not
                        const arrFoundItem = existingOpeningHours.filter(
                            (item: IOpeningHours) => item.day && (item.day === idx + 1 || ConvertDayToEnumInt(item.day.toString()) === idx + 1),
                        );

                        if (arrFoundItem && arrFoundItem.length > 0) {
                            app.outletPractitioner.openingHours.push(arrFoundItem[0]);
                            // if numeric set Day id, otherwise we get string translate it to id
                            if (app.outletPractitioner.openingHours[idx].day && !IsNumeric(app.outletPractitioner.openingHours[idx].day)) {
                                app.outletPractitioner.openingHours[idx].day =
                                    app.outletPractitioner.openingHours[idx].day &&
                                    ConvertDayToEnumInt(app.outletPractitioner.openingHours[idx].day!.toString());
                            }
                            // Date Picker now in en-AU locale
                            // now fix the time to proper format
                            app.outletPractitioner.openingHours[idx].openTime =
                                app.outletPractitioner.openingHours[idx].openTime && formatDateForDateFns(app.outletPractitioner.openingHours[idx].openTime);
                            app.outletPractitioner.openingHours[idx].closeTime =
                                app.outletPractitioner.openingHours[idx].closeTime && formatDateForDateFns(app.outletPractitioner.openingHours[idx].closeTime);
                        } else {
                            // did not find it, initialise empty
                            const openingHours = { ...initOpeningHours }; // need a new clone of the object each time
                            openingHours.outletId = app.outletPractitioner.outletId;
                            openingHours.day = idx + 1; // MUST use index = 1 to 7 to match day INT
                            app.outletPractitioner.openingHours.push(openingHours);
                        }
                    }
                }

                // finally sort collection Monday->Sunday
                app.outletPractitioner.openingHours.sort((a, b) => (a.day && a.day < (b.day === null ? 7 : b.day) ? -1 : 1));
            }

            return app;
        case types.CREATE_APPLICATION_SUCCESS:
            app = { ...action.application };
            return app;
        case types.SUSPEND_APPLICATION_SUCCESS:
            app = { ...action.application };
            return app;
        case types.CANCEL_APPLICATION_SUCCESS:
            app = { ...action.application };
            return app;
        /* returns nothing
         case types.SUBMIT_APPLICATION_SUCCESS:
            app = { ...action.application };
            return app; */
        default:
            return state;
    }
};

export default application;
