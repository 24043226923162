// Consent Display Gender page
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
// tslint:disable-next-line: no-submodule-imports
import { yupResolver } from '@hookform/resolvers/yup';
import { InquiryDefaultObj, IUser } from '../../api/interfacesApi';
import { HELPHEADERTEXT_GENDERONPUBLICREGISTER, HELPTEXT_GENDERONPUBLICREGISTER } from '../../services/Constants';
import { ParseBool, Sleep } from '../../utils/Common';
import { AjaxCallStateEnum } from '../../services/Enums';
import AuthService from '../../services/AuthService';
import { VALIDATION_ERROR_POPUP_MESSAGE, VALIDATION_ERROR_UNKNOWN_MESSAGE, VALIDATION_SUCCESS_POPUP_MESSAGE } from '../../services/Validation';
import * as types from '../../actions/actionTypes';
import { toastErrorMessage, toastSuccessMessage } from '../../actions/toastrMessages';
import UserActions from '../../actions/userActions';
import RadioButtonGroupField, { RadioButtonOption } from '../../components/fields/RadioButtonGroupField';
import Loader from '../../controls/Loader';
import PageTitle from '../../components/pageTitle';
import PageWrapper from '../../components/pageWrapper';
import AppContext from '../../stateManagement/context/AppContext';
import { AjaxCallStateForPage } from '../PageInterfaces';
import { EmptyRow } from 'controls/EmptyRow';
import OutletDetailsForm from './OutletDetailsForm';

interface EditConsentGenderPageProps {
    authService: AuthService;
}

// see: https://github.com/react-hook-form/react-hook-form/discussions
const EditConsentGenderPage = (props: EditConsentGenderPageProps) => {
    const { authService } = props;

    const appContext = useContext(AppContext);
    // const dispatchEditFormState = appContext.dispatchEditFormState;
    const editFormState = appContext.editFormState;
    const formRef = useRef();
    const dispatch = useDispatch();
    // store the form in state as we update any part of the outlet details
    const applicationForm = useRef(new OutletDetailsForm());
    const [inquiryDefObj, setInquiry] = useState<InquiryDefaultObj>(applicationForm.current.properties.InitialData);

    const userActions = useMemo(() => {
        return new UserActions(authService);
    }, [authService]);
    const user: IUser = useSelector((stateUser: any) => stateUser.user);
    // const application: IApplication = useSelector((stateApp: any) => stateApp.application);
    const [isLoading, setIsLoading] = useState(false);

    // even though we have redux states, we HAVE to capture the attempt due to navigation and Get and Save user calls on the page
    const [attemptingSave, setAttemptingSave] = useState(false);
    const ajaxCallsInProgress: types.ICallStatus = useSelector((stateAjax: any) => stateAjax.ajaxCallsInProgress);
    const initCallStateForPage: AjaxCallStateForPage = { ajaxCallState: AjaxCallStateEnum.NotStarted, pageNumber: 0 };
    const [ajaxCallStateForPage, setAjaxCallStateForPage] = useState(initCallStateForPage);
    const [backEndValidationErrors, setBackEndValidationErrors] = useState([]);

    // get latest user details so we can map them to our inquiry object upon load of this page
    useEffect(() => {
        setIsLoading(true);
        dispatch(userActions.GetUser());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // if everything is loaded remove the spinner
        if (isLoading === true && user && user.id !== undefined && user.id !== null) {
            setIsLoading(false);
        }

        inquiryDefObj.inquiry.personalDetails = user;
        setInquiry(inquiryDefObj);

        // reset would make the values update for the useForm functionality
        reset(inquiryDefObj);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, inquiryDefObj]);

    const validationSchema = editFormState.validationSchema ? yupResolver(editFormState.validationSchema) : undefined;

    // watch lets us keep track of state to keep an eye on it
    // formState lets us validate
    const {
        // watch,
        register,
        // unregister,
        reset,
        // trigger,
        // getValues,
        setValue,
        // getValues,
        setError,
        control,
        handleSubmit,
        // formState: { errors, isValid, isDirty, isSubmitting, isSubmitted, isValidating, isSubmitSuccessful }, // , isValidating },
        formState: { isValid }, // , isValidating },
        // clearErrors,
    } = useForm({
        mode: 'all',
        criteriaMode: 'all',
        defaultValues: useMemo(() => {
            return inquiryDefObj;
        }, [inquiryDefObj]),
        resolver: useMemo(() => {
            /// ERROR until FIELDS MATCH: is not assignable to type 'UnpackNestedValue<DeepPartial<TFieldValues>> | undefined'.
            return validationSchema;
        }, [validationSchema]),
    }); // mode:all is to validate after every keypress

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors && backEndValidationErrors?.length > 0) {
            backEndValidationErrors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'InvalidName':
                        setError('inquiry.application.outletPractitioner.name', { type: 'manual', message: validationError.errorMessage });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    const redirectToManagePublicDetailsPage = () => {
        window.location.href = '/managepublicdetails';
    };

    const handleClickPrevious = () => {
        redirectToManagePublicDetailsPage();
    };

    useEffect(() => {
        if (ajaxCallsInProgress && ajaxCallsInProgress.callState) {
            setAjaxCallStateForPage({ ajaxCallState: ajaxCallsInProgress.callState, pageNumber: 0 });
        }

        if (ajaxCallsInProgress && ajaxCallsInProgress.callState === AjaxCallStateEnum.CompletedWithValidation) {
            setBackEndValidationErrors(ajaxCallsInProgress.errors);
        }

        // TODO: set Errors here:
    }, [ajaxCallsInProgress]);

    // check dispatch success state
    useEffect(() => {
        if (ajaxCallStateForPage) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                setIsLoading(false);
                setAttemptingSave(false);
                displayBackEndValidations();
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setIsLoading(false);
                setAttemptingSave(false);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                if (attemptingSave === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK) {
                    setAttemptingSave(false);
                    toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);
                    Sleep(600).then(() => {
                        redirectToManagePublicDetailsPage();
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const submitForm = (data: any) => {
        if (isValid) {
            // Save changes to application (attempt save, if that fails we do NOT move)
            setAttemptingSave(true);

            // send up the data object containing the change
            inquiryDefObj.inquiry.personalDetails.consentToPublishGenderOnRegister =
                data.inquiryDefObj.inquiry.personalDetails.consentToPublishGenderOnRegister;
            dispatch(userActions.SaveUserGenderConsentOnly(inquiryDefObj.inquiry.personalDetails));
        } else {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    const errorOnSubmitForm = (errorsIn: any, event: any) => {
        toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
    };

    const onChangeConsentToPublishGenderOnPublicRegister = (event: any) => {
        // store this state as we need to update the Personal Details as well as Application object
        const consentToPublishGenderOnPublicRegister: boolean = ParseBool(event.target.value);
        // manage the value manually as ref did not work for the react hook form (use event.target.value to set value here)
        setValue('inquiryDefObj.inquiry.personalDetails.consentToPublishGenderOnRegister', consentToPublishGenderOnPublicRegister, {
            shouldDirty: true,
            shouldValidate: false,
        });
    };

    const radioOptions: RadioButtonOption[] = [
        { label: 'Yes', value: true, disabled: false },
        { label: 'No', value: false, disabled: false },
    ];

    return (
        <>
            <PageTitle
                title='Consent details'
                description='Use this section to allow or disallow your consent to display your details on the public register.'
            />
            <PageWrapper
                pageName='ConsentDisplayGenderPage'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, errorOnSubmitForm)}
                handleClickPrevious={handleClickPrevious}
            >
                <Loader isLoading={attemptingSave === true} loaderText='Submitting your changes. Please wait...' />
                {isLoading === true && <Loader isLoading={true} loaderText='Loading your information...' />}
                {isLoading === false && (
                    <>
                        <RadioButtonGroupField
                            options={radioOptions}
                            id='inquiryDefObj.inquiry.personalDetails.consentToPublishGenderOnRegister'
                            displayName='Consent to display gender'
                            fieldLabel='I consent to my gender appearing on the public register'
                            defaultValue={inquiryDefObj.inquiry.personalDetails.consentToPublishGenderOnRegister} // only set default if value exists
                            isMandatory={false}
                            control={control}
                            register={register}
                            helpHeaderText={HELPHEADERTEXT_GENDERONPUBLICREGISTER}
                            helpText={HELPTEXT_GENDERONPUBLICREGISTER}
                            errorsField={null} // {errors.inquiry?.personalDetails?.consentToPublishGenderOnRegister}
                            onChange={onChangeConsentToPublishGenderOnPublicRegister}
                        />
                        <EmptyRow />
                    </>
                )}
            </PageWrapper>
        </>
    );
};

export default EditConsentGenderPage;
