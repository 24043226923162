// Manage complaints mechanism landing page
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import AppContext, { AppContextInterface } from '../../../stateManagement/context/AppContext';
import AuthService from '../../../services/AuthService';
import {
    COMPLAINTS_MECHANISM_ALLREMOVED_P1,
    COMPLAINTS_MECHANISM_ALLREMOVED_P2,
    COMPLAINTS_MECHANISM_REMOVED_ATTEMPTTOEDIT,
    HEADER_LANDING_COMPLAINTS,
    MODALBUTTON1_YES,
    MODALBUTTON2_NO,
    MODALHEADER_CONFIRM_ADD_CM,
    MODALLHEADER_MAX_COMPLAINTS,
    MODALTEXT_CONFIRM_ADD_CM_P1,
    MODALTEXT_CONFIRM_ADD_CM_P2,
    MODALTEXT_MAX_COMPLAINTS_P1,
    MODALTEXT_MAX_COMPLAINTS_P2,
    TIP_COMPLAINTSMECHANISM_LANDING,
    UNINITIALISED,
} from '../../../services/Constants';
import { IApplication, IComplaintMechanism, IUser } from '../../../api/interfacesApi';
import ActionCard from '../../../pages/Dashboard/ActionCard';
import { plusIcon, usercircleIcon } from '../../../pages/Dashboard/DashboardIcons';
import PageTitle from '../../../components/pageTitle';
import { SetCurrentComplaintsMechId, WizardStateActionTypeEnums } from '../../../components/wizard/wizardStateManager';
// import AddComplaintMechPillButton from '../../../controls/AddComplaintMechPillButton';
import { formatDateForDisplayAddExpiredIfInPast } from '../../../utils/Dates';
import {
    AccreditationStatusEnum,
    ActionCardIndicatorEnum,
    ComplaintsMechanismType,
    ComplaintsProcessType,
    RelationshipToEmployerType,
} from '../../../services/Enums';
import Loader from '../../../controls/Loader';
import NoteText from '../../../controls/NoteText';
import { EmptyRow } from '../../../controls/EmptyRow';
import {
    CountOfValidComplaintsMech,
    GetComplaintsMechanismName,
    IsAtLeastOneComplaintsMechanismSlotEmpty,
    IsInsuranceMissingButRequired,
    IsOrganisationDisplay,
    IsRelationshipToEmployerExists,
    IsThisComplaintMarkedForCancellation,
    IsValidComplaintsMech,
    OutletExistsAndIsValid,
} from '../../../utils/ComplaintsUtils';
import { DisplayReviewAndSubmitWarningLink, IsMyLastComplaintMechanismFlaggedForCancellation } from '../../../utils/AppUtils';
import UserActions from '../../../actions/userActions';
import ApplicationActions from '../../../actions/applicationActions';
import TipsControl from '../../../controls/TipsControl';
import ModalDialog from '../../../controls/ModalDialog';
import styles from '../../../pages/Dashboard/Dashboard.module.scss';
import { ModalDialogConfirmable } from 'controls/ModalDialogConfirmable';

const {
    cardDeckContainer,
    cardDeckContainer3,
    cardDeckContainerTitle,
    cardDeckRow,
    cardDeckRowNote,
    cardDeckColumnCM,
    cardComplaintsTileContent,
    cardComplaintsTileContentRHS,
    cardComplaintsTileContentCentre,
    cardComplaintsButton,
} = styles;

interface ManageComplaintsMechanismLandingPageProps {
    authService: AuthService;
}

const ManageComplaintsMechanismLandingPage = (props: ManageComplaintsMechanismLandingPageProps) => {
    const { authService } = props;

    const history = useHistory();
    const location = useLocation();
    const noteColor = '#18566d'; // 'cadetblue';
    const noteBackgroundColor = '#f7de92'; // brighter yellow='#eed202' '#ffc107'; // '#ffffcc'; // '#12546A';
    const appContext = useContext<AppContextInterface>(AppContext);
    const dispatchWizardState = appContext.dispatchWizardState;
    const dispatch = useDispatch();
    const userActions = useMemo(() => {
        return new UserActions(authService);
    }, [authService]);
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    // const user: IUser = useSelector((stateUser: any) => stateUser.user);
    const application: IApplication = useSelector((stateApp: any) => stateApp.application);
    const user: IUser = useSelector((stateApp: any) => stateApp.application);
    const isMyLastComplaintMechanismFlaggedForCancellation = IsMyLastComplaintMechanismFlaggedForCancellation(application);
    const [showMaxComplaintsDialog, setShowMaxComplaintsDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // MUST reset wizard state and step back to first here, in case user navs from links etc back and forth
        dispatchWizardState({ type: WizardStateActionTypeEnums.initialise });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // get latest user details so we can map them to our inquiry object upon load of this page
        dispatch(userActions.GetUser());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const doNotCopyActiveComplaint = true;
        dispatch(appActions.GetApplication(doNotCopyActiveComplaint));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const refreshData = location && location.search && location.search.length > 0 && location.search === '?reload=true';

        if (refreshData === true) {
            setIsLoading(true);
            dispatchWizardState({ type: WizardStateActionTypeEnums.initialise });
            dispatch(appActions.GetApplication());
            dispatch(userActions.GetUser());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (application && application?.applicationId !== undefined && application?.applicationId !== null && application?.applicationId !== UNINITIALISED) {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application]);

    /*
    const buttonAddCM = () => {
        return <AddComplaintMechPillButton handleAddCM={handleAddCM} />;
    }; */

    const handleOnClickEditCM = (complaintsMechanismId: string, isBeingAdded: boolean, isExistingEdited: boolean) => {
        // store currIndex of CM to edit
        SetCurrentComplaintsMechId(appContext, complaintsMechanismId);
        if (isBeingAdded === true) {
            history.push('/resumeaddingcomplaintsmechanism');
        } else {
            if (isExistingEdited === true) {
                // need one further check here to see if we skip straight to insurance step
                let navigateToInsuranceStep = false;
                const cm = application.complaintsMechanism.find(cm => cm.complaintsMechanismId === complaintsMechanismId);

                if (cm !== undefined) {
                    const thisIsOrg = cm.complaintsMechanismType === ComplaintsMechanismType.GovernmentFundedFDRService;
                    const relationshipIsOnPanel = cm.relationshipToOrganisation === RelationshipToEmployerType.OnPanel;

                    // if CM is an Org and outlet type is employee or panel member and relationship is employee, prevent edit
                    navigateToInsuranceStep = thisIsOrg === true && relationshipIsOnPanel === true;
                }

                if (navigateToInsuranceStep === true) {
                    history.push('/updatecomplaintsmechanisminsurance');
                } else {
                    history.push('/updatecomplaintsmechanism');
                }
            } else {
                history.push('/viewcomplaintsmechanism');
            }
        }
    };

    const handleAddCM = async () => {
        // must check for limit reached then continue to set active if OK
        if (IsAtLeastOneComplaintsMechanismSlotEmpty(application)) {
            // one further message box to warn users that adding is not useful would rather edit if the CM already exists, so check if user is sure
            if (
                await ModalDialogConfirmable(MODALTEXT_CONFIRM_ADD_CM_P1, MODALBUTTON1_YES, MODALBUTTON2_NO, {
                    title: MODALHEADER_CONFIRM_ADD_CM,
                    confirmationP2: MODALTEXT_CONFIRM_ADD_CM_P2,
                    modalBodyPositionTextLeft: false,
                    dataTestId: 'confirmAddCM',
                })
            ) {
                // go ahead with the add
                SetCurrentComplaintsMechId(appContext, UNINITIALISED);
                history.push('/addcomplaintsmechanism');
            }
        } else {
            setShowMaxComplaintsDialog(true);
        }
    };

    const hideMaxComplaintsDialog = () => {
        setShowMaxComplaintsDialog(false);
    };

    const getComplaintsDataOutput = (data: IComplaintMechanism) => {
        const isConditional = application.accreditationStatus === AccreditationStatusEnum.ConditionalAccreditation;
        const relationshipIsEmployee = data.relationshipToOrganisation === RelationshipToEmployerType.Employee;
        const relationshipIsPanelMember = data.relationshipToOrganisation === RelationshipToEmployerType.OnPanel;
        // const complaintsEmployee = data.complaintsMechanismType === ComplaintsMechanismType.GovernmentFundedFDRService;
        const complaintFullMembership = data.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc;
        // Legal Aid/Other
        const legalAidOther =
            data.complaintsProcessType === ComplaintsProcessType.LegalAidStatutoryBody ||
            data.complaintsProcessType === ComplaintsProcessType.OtherStatutoryBody;

        const relationshipText = complaintFullMembership ? 'Private practitioner' : relationshipIsPanelMember ? 'External panel member' : 'Employee';
        const membershipEndDate = formatDateForDisplayAddExpiredIfInPast(data.membershipToDate) ?? undefined;
        const showMembershipDetails = membershipEndDate !== undefined && complaintFullMembership;
        const insuranceExpiryDate = formatDateForDisplayAddExpiredIfInPast(data.professionalIndemnityInsuranceToDate);
        const showInsuranceDetails =
            insuranceExpiryDate !== undefined && insuranceExpiryDate !== '' && (complaintFullMembership || (legalAidOther && relationshipIsPanelMember));
        // isConditional combo if user is currently adding insurance we need to show this on the Edited tile
        const showNoInsuranceProvided =
            (!(insuranceExpiryDate !== undefined && insuranceExpiryDate !== '') && complaintFullMembership && isConditional) ||
            (!showInsuranceDetails && complaintFullMembership && IsOrganisationDisplay(data) === false && IsInsuranceMissingButRequired(data) === false);
        const showInsuranceByEmployer = !showInsuranceDetails && !showNoInsuranceProvided && relationshipIsEmployee;

        return (
            <>
                <Row>
                    <Col md={6}>
                        <p className={cardComplaintsTileContent}>Relationship : </p>
                    </Col>
                    <Col md={6}>
                        <p className={cardComplaintsTileContentRHS}>{relationshipText}</p>
                    </Col>
                </Row>
                {showMembershipDetails === true && (
                    <Row>
                        <Col md={6}>
                            <p className={cardComplaintsTileContent}>Membership end date : </p>
                        </Col>
                        <Col md={6}>
                            <p className={cardComplaintsTileContentRHS}>{membershipEndDate}</p>
                        </Col>
                    </Row>
                )}
                {showInsuranceDetails === true && (
                    <Row>
                        <Col md={6}>
                            <p className={cardComplaintsTileContent}>Insurance expiry : </p>
                        </Col>
                        <Col md={6}>
                            <p className={cardComplaintsTileContentRHS}>{insuranceExpiryDate}</p>
                        </Col>
                    </Row>
                )}
                {showNoInsuranceProvided === true && (
                    <Row>
                        <Col md={12}>
                            <p className={cardComplaintsTileContentCentre}>No insurance details provided</p>
                        </Col>
                    </Row>
                )}
                {showInsuranceByEmployer === true && (
                    <Row>
                        <Col md={12}>
                            <p className={cardComplaintsTileContentCentre}>Insurance by employer</p>
                        </Col>
                    </Row>
                )}
                {showInsuranceDetails === false && showInsuranceByEmployer === false && showNoInsuranceProvided === false && (
                    <Row>
                        <Col md={12}>
                            <p className={cardComplaintsTileContentCentre}>&nbsp;</p>
                        </Col>
                    </Row>
                )}
                {showMembershipDetails === false && (
                    <Row>
                        <Col md={12}>
                            <p className={cardComplaintsTileContentCentre}>&nbsp;</p>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col md={1} />
                    <Col md={10}>
                        <p className={cardComplaintsButton}>Manage</p>
                    </Col>
                    <Col md={1} />
                </Row>
            </>
        );
    };

    const nameIsEmptyOrNull = (name: string | undefined) => {
        return name === undefined || name === null || name === '';
    };

    const buttonEditableCM = (index: number, complaintsData: IComplaintMechanism) => {
        const id = `managecomplaints-button-updateComplaints-${index}`;
        const complaintsDataOutput = getComplaintsDataOutput(complaintsData);
        const name = GetComplaintsMechanismName(complaintsData);
        const indicatorIsInProgress =
            nameIsEmptyOrNull(name) === true ||
            (complaintsData.isEditCopy === true &&
                IsRelationshipToEmployerExists(complaintsData) === false && // just select Type but nothing else
                (complaintsData.professionalAssociation === null || complaintsData.professionalAssociation?.name === '') &&
                OutletExistsAndIsValid(complaintsData) === false) ||
            (complaintsData.isEditCopy === true &&
                IsOrganisationDisplay(complaintsData) === true &&
                IsRelationshipToEmployerExists(complaintsData) &&
                IsInsuranceMissingButRequired(complaintsData)) ||
            (complaintsData.isEditCopy === true && IsOrganisationDisplay(complaintsData) === false && IsInsuranceMissingButRequired(complaintsData)); // show IN PROGRESS in banner instead of NEW
        const indicatorIsDeleted = IsThisComplaintMarkedForCancellation(complaintsData);
        const disabledButtonActionText = indicatorIsDeleted ? COMPLAINTS_MECHANISM_REMOVED_ATTEMPTTOEDIT : '';
        const supercedesExists = !(complaintsData.supercedesId === undefined || complaintsData.supercedesId === null || complaintsData.supercedesId === '');
        const isNew =
            complaintsData.isEditCopy === true &&
            indicatorIsInProgress === false && // show IN PROGRESS in banner instead of NEW
            supercedesExists === false;
        const isBeingAdded = isNew || indicatorIsInProgress;
        const isExistingEdited =
            isNew === false &&
            indicatorIsInProgress === false &&
            indicatorIsDeleted === false &&
            complaintsData.isEditCopy === true &&
            supercedesExists === true;

        const indicatorState =
            indicatorIsDeleted === true
                ? ActionCardIndicatorEnum.Deleted
                : indicatorIsInProgress === true
                ? ActionCardIndicatorEnum.InProgress
                : isNew === true
                ? ActionCardIndicatorEnum.New
                : isExistingEdited === true
                ? ActionCardIndicatorEnum.Edited
                : ActionCardIndicatorEnum.None;

        return (
            <ActionCard
                isComplaintsTile={true}
                id={id}
                indicatorState={indicatorState}
                imgElement={usercircleIcon()}
                title={name ?? ''}
                cardTitleLargeFontOnly={true}
                buttonLink=''
                disabledButtonActionText={disabledButtonActionText}
                // tslint:disable-next-line: jsx-no-lambda
                handleOnClick={() => handleOnClickEditCM(complaintsData.complaintsMechanismId, isBeingAdded, isExistingEdited)}
                children={complaintsDataOutput}
            />
        );
    };

    const displayComplaintsMechDetailsTile = (data: IComplaintMechanism, index: number) => {
        if (IsValidComplaintsMech(data) === true) {
            return (
                <Col key={index} className={cardDeckColumnCM}>
                    {buttonEditableCM(index, data)}
                </Col>
            );
        }
    };

    const addNewTileDescAndButton = () => {
        return (
            <>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col md={1} />
                    <Col md={10}>
                        <p className={cardComplaintsTileContent}>Only use this button if you need to</p>
                    </Col>
                    <Col md={1} />
                </Row>
                <Row>
                    <Col md={1} />
                    <Col md={10}>
                        <p className={cardComplaintsTileContent}>add one that is not already displayed</p>
                    </Col>
                    <Col md={1} />
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col md={1} />
                    <Col md={10}>
                        <p className={cardComplaintsButton}>Add new</p>
                    </Col>
                    <Col md={1} />
                </Row>
            </>
        );
    };

    const displayAddNewTile = () => {
        const id = 'addCMtile';

        return (
            <Col key={id} className={cardDeckColumnCM}>
                <ActionCard
                    isComplaintsTile={true}
                    id={id}
                    // indicatorState={indicatorState}
                    imgElement={plusIcon()}
                    title={'Add new complaints mechanism'}
                    cardTitleLargeFontOnly={true}
                    buttonLink=''
                    // disabledButtonActionText={disabledButtonActionText}
                    // tslint:disable-next-line: jsx-no-lambda
                    handleOnClick={() => handleAddCM()}
                    children={addNewTileDescAndButton()}
                />
            </Col>
        );
    };

    const emptyCol = (i: number) => {
        return (
            <Col key={i} className={cardDeckColumnCM}>
                &nbsp;
            </Col>
        );
    };

    const displayComplaintsMechTiles = () => {
        if (application?.complaintsMechanism?.length === 0) return null;

        // const maxRows = 3;
        const maxTilesPerRow = 4;
        // can only ever have up to 10 CM tiles (+1 for the Add tile, so 11 max overall)
        const numberOfTiles = CountOfValidComplaintsMech(application.complaintsMechanism) + 1;
        // const numberOfRows = Math.ceil(numberOfTiles / maxRows); // 1..4

        // 0 means all 4 tiles are used, 1..3 means we have to pad 4 minus the remainder with empty columns
        const tilesRemainderInLastRow = numberOfTiles % maxTilesPerRow;
        const addThisManyEmptyCols = tilesRemainderInLastRow === 0 ? 0 : maxTilesPerRow - tilesRemainderInLastRow;

        // not working:
        // {displayEmptyCols(tilesRemainderInLastRow, maxTilesPerRow)}

        return (
            <Row className={`${cardDeckRow} card-deck`}>
                {application.complaintsMechanism.map((data, index) => displayComplaintsMechDetailsTile(data, index))}
                {displayAddNewTile()}
                {addThisManyEmptyCols >= 1 && emptyCol(1)}
                {addThisManyEmptyCols >= 2 && emptyCol(2)}
                {addThisManyEmptyCols >= 3 && emptyCol(3)}
            </Row>
        );
    };

    return (
        <>
            <PageTitle title='Complaints Mechanism(s) and Insurance' description={HEADER_LANDING_COMPLAINTS} />
            {DisplayReviewAndSubmitWarningLink(application, user, cardDeckRowNote, noteColor, noteBackgroundColor)}
            {isMyLastComplaintMechanismFlaggedForCancellation === true && (
                <>
                    <Row className={`${cardDeckRowNote}`} id='warning-note-lastcomplaintcancelled' data-testid='warning-note-lastcomplaintcancelled'>
                        <Col md='12'>
                            <NoteText
                                displayText={COMPLAINTS_MECHANISM_ALLREMOVED_P1}
                                displayTextP2={COMPLAINTS_MECHANISM_ALLREMOVED_P2}
                                backgroundColor={noteBackgroundColor}
                                color={noteColor}
                                fontBold={true}
                                // buttonLink={'/managedetailsreviewandsubmitpage'}
                            />
                        </Col>
                        <Col md='1' className='justify-content-end' />
                    </Row>
                    <EmptyRow />
                </>
            )}
            <ModalDialog
                modalTitle={MODALLHEADER_MAX_COMPLAINTS}
                modalBodyText={MODALTEXT_MAX_COMPLAINTS_P1}
                modalBodyTextP2={MODALTEXT_MAX_COMPLAINTS_P2}
                showMe={showMaxComplaintsDialog}
                showOkOnly={true}
                handleClickOk={hideMaxComplaintsDialog}
            />
            <Container className={`${cardDeckContainer} ${cardDeckContainer3}`}>
                <Row className={cardDeckContainerTitle}>
                    <Col aria-colspan={4}>
                        <h2>MANAGE COMPLAINTS MECHANISM(S) AND INSURANCE</h2>
                    </Col>
                </Row>
                <TipsControl tipString={TIP_COMPLAINTSMECHANISM_LANDING} />
                {(isLoading === true || application.applicationId === UNINITIALISED) && <Loader isLoading={true} loaderText='Loading, please wait...' />}
                {displayComplaintsMechTiles()}
            </Container>
        </>
    );
};

export default ManageComplaintsMechanismLandingPage;

/*
                <Row className={`${cardDeckRow} card-deck`}>
                    <Col>{buttonAddCM()}</Col>
                    <Col />
                    <Col />
                </Row>
*/
