// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Suspense, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import { ToastContainer } from 'react-toastify';
// tslint:disable-next-line: no-submodule-imports
import 'react-toastify/dist/ReactToastify.css';
import AuthService from './services/AuthService';
import { CONTACTUS_LABELTEXT_WITHDRAW, CONTACTUS_PAGEHEADING_WITHDRAW, UNINITIALISED } from './services/Constants';
// import UserActions from './actions/userActions';
// import ApplicationActions from './actions/applicationActions';
import Loader from './controls/Loader';
import SiteHeader from './components/header/SiteHeader';
import PageFooter from './components/footer/PageFooter';
import NavLinkEmailPau from './components/fields/NavLinkEmailPau';
import AppContext from './stateManagement/context/AppContext';
import UpdatePageContainer from './components/site/UpdatePageContainer';
import BasePageContainer from './components/site/BasePageContainer';
import Welcome from './pages/Welcome';
import About from './pages/About';
import Disclaimer from './pages/Disclaimer';
import Dashboard from './pages/Dashboard';
import ContactUsPage from './pages/ContactUs';
import Logout from './pages/Logout';
import ManageDetailsReviewAndSubmitContainer from './pages/manageDetails/manageDetailsReviewAndSubmitContainer';
import ManageComplaintsMechanismLandingPage from './pages/manageDetails/complaintsMechanism/manageComplaintsLandingPage';
import ComplaintsMechanismWizard from './pages/manageDetails/complaintsMechanism/wizard/complaintsMechanismWizard';
import RegistrationApplicationWizard from './pages/Registration/RegistrationApplicationWizard';
import { initialWizardState, reducerWizardState } from './components/wizard/wizardStateManager';
import { initialEditFormState, reducerEditFormState } from './pages/manageDetails/EditFormStateManager';
import ViewPersonalDetailsPage from './pages/manageDetails/personalDetails/viewPersonalDetailsPage';
import EditPersonalDetailsPage from './pages/manageDetails/personalDetails/editPersonalDetailsPage';
import OutletDetailsPage from './pages/OutletDetails';
import ManagePublicDetailsPage from './pages/OutletDetails/ManagePublicDetailsPage';
import ViewComplaintsMechanismPage from './pages/manageDetails/complaintsMechanism/pages/viewComplaintsMechanismPage';
import SuspendAccreditationPage from './pages/manageAccreditation/suspendAccreditation/suspendAccreditationPage';
import CancelAccreditationPage from './pages/manageAccreditation/cancelAccreditation/cancelAccreditationPage';
import LiftSuspensionPage from './pages/manageAccreditation/liftSuspension/liftSuspensionPage';
import './styles/_buttons.scss';
import './styles/_forms.scss';
import './styles/_layout.scss';
import './styles/_variables.scss'; // import this last as it includes the bootstrap styles that are overriden
// tslint:disable-next-line: no-implicit-dependencies
import { hot } from 'react-hot-loader';
import EditConsentGenderPage from 'pages/OutletDetails/EditConsentGenderPage';

interface IAppProps {
    authService: AuthService;
}

interface IRouteProps {
    authService: AuthService;
}

function NavigateToWizard(authService: AuthService, resume: boolean, isResubmit: boolean) {
    return <RegistrationApplicationWizard authService={authService} resume={resume} isResubmit={isResubmit} />;
}

function NavigateToComplaintsMechanismWizard(authService: AuthService, addNew: boolean, resumeAddingNew: boolean, skipToStepNumber?: number) {
    return (
        <ComplaintsMechanismWizard authService={authService} addNewComplaint={addNew} resumeAddingNew={resumeAddingNew} skipToStepNumber={skipToStepNumber} />
    );
}

function RouteAll(routeProps: IRouteProps) {
    const { authService } = routeProps;
    /* const userActions = useMemo(() => {
        return new UserActions(authService);
    }, [authService]);
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);

    const dispatch = useDispatch();
    const isAuth = useSelector((state: any) => state.user.isAuthenticated);

    suseEffect(() => {
        // console.log('app.tsx', isAuth);
        // If user does not exist the first GetUser() call for that user will create it in our database,
        //  which sets isAuth, which then calls GetApplication() which is the expected behaviour.
        if (isAuth == null && dispatch != null) {
            dispatch(userActions.GetUser());
        } else {
            dispatch(appActions.GetApplication());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth]); // , dispatch, appActions, userActions]); // removal here could have a ripple effect...
    */

    // routes all pages but conditional on the UserOnboarding completed profile
    // if incomplete then always display the useronboarding steps.
    // const user: IUser = useSelector((state: any) => state.user);

    return (
        <>
            <Switch>
                <Route exact={true} path={['/', '/home']}>
                    <Dashboard authService={authService} />
                </Route>
                <Route exact={true} path='/beginregistrationapplication' children={NavigateToWizard(authService, false, false)} />
                <Route exact={true} path='/resumeregistrationapplication' children={NavigateToWizard(authService, true, false)} />
                <Route exact={true} path='/resubmitregistrationapplication' children={NavigateToWizard(authService, false, true)} />
                <Route exact={true} path='/resumeresubmitregistrationapplication' children={NavigateToWizard(authService, true, true)} />
                <Route exact={true} path='/managecomplaintsmechanism'>
                    <UpdatePageContainer menuItemDisplayName='Complaints mechanism(s) and insurance'>
                        {<ManageComplaintsMechanismLandingPage authService={authService} />}
                    </UpdatePageContainer>
                </Route>
                <Route exact={true} path='/viewcomplaintsmechanism'>
                    <UpdatePageContainer menuItemDisplayName='View complaints mechanism' showBackToManageComplaints={true}>
                        {<ViewComplaintsMechanismPage authService={authService} />}
                    </UpdatePageContainer>
                </Route>
                <Route exact={true} path='/updatecomplaintsmechanism' children={NavigateToComplaintsMechanismWizard(authService, false, false)} />
                <Route exact={true} path='/updatecomplaintsmechanismmembership' children={NavigateToComplaintsMechanismWizard(authService, false, false, 2)} />
                <Route exact={true} path='/updatecomplaintsmechanisminsurance' children={NavigateToComplaintsMechanismWizard(authService, false, false, 3)} />
                <Route exact={true} path='/addcomplaintsmechanism' children={NavigateToComplaintsMechanismWizard(authService, true, false)} />
                <Route exact={true} path='/resumeaddingcomplaintsmechanism' children={NavigateToComplaintsMechanismWizard(authService, true, true)} />
                <Route exact={true} path='/managedetailsreviewandsubmitpage'>
                    <UpdatePageContainer menuItemDisplayName='Review and submit changes'>
                        {<ManageDetailsReviewAndSubmitContainer authService={authService} />}
                    </UpdatePageContainer>
                </Route>
                <Route exact={true} path='/managepublicdetails'>
                    <UpdatePageContainer menuItemDisplayName='Manage public details'>
                        {<ManagePublicDetailsPage authService={authService} />}
                    </UpdatePageContainer>
                </Route>
                <Route exact={true} path='/updateoutletdetails'>
                    <UpdatePageContainer backLinkUrl='/managepublicdetails' backLinkText='Back to Manage public details' menuItemDisplayName='Outlet details'>
                        {<OutletDetailsPage authService={authService} />}
                    </UpdatePageContainer>
                </Route>
                <Route exact={true} path='/editconsentgenderpage'>
                    <UpdatePageContainer backLinkUrl='/managepublicdetails' backLinkText='Back to Manage public details' menuItemDisplayName='Consent details'>
                        {<EditConsentGenderPage authService={authService} />}
                    </UpdatePageContainer>
                </Route>
                <Route exact={true} path='/viewpersonaldetails'>
                    <UpdatePageContainer menuItemDisplayName='Personal details'>{<ViewPersonalDetailsPage authService={authService} />}</UpdatePageContainer>
                </Route>
                <Route exact={true} path='/editpersonaldetails'>
                    <UpdatePageContainer menuItemDisplayName='Personal details'>{<EditPersonalDetailsPage authService={authService} />}</UpdatePageContainer>
                </Route>
                <Route exact={true} path='/withdrawapplication'>
                    <BasePageContainer authService={authService}>
                        {
                            <ContactUsPage pageTitle={CONTACTUS_PAGEHEADING_WITHDRAW}>
                                <p>{CONTACTUS_LABELTEXT_WITHDRAW}</p>
                                <NavLinkEmailPau />
                            </ContactUsPage>
                        }
                    </BasePageContainer>
                </Route>
                <Route exact={true} path='/liftSuspension'>
                    <UpdatePageContainer menuItemDisplayName='Request a suspension lift'>
                        {<LiftSuspensionPage authService={authService} />}
                    </UpdatePageContainer>
                </Route>
                <Route exact={true} path='/suspendaccreditation'>
                    <UpdatePageContainer menuItemDisplayName='Request suspension of accreditation'>
                        {<SuspendAccreditationPage authService={authService} />}
                    </UpdatePageContainer>
                </Route>
                <Route exact={true} path='/cancelaccreditation'>
                    <UpdatePageContainer menuItemDisplayName='Request cancellation of accreditation'>
                        {<CancelAccreditationPage authService={authService} />}
                    </UpdatePageContainer>
                </Route>
                <Route exact={true} path='/logout'>
                    <Logout authService={authService} />
                </Route>
            </Switch>
        </>
    );
}

function App(appProps: IAppProps) {
    const [wizardState, dispatchWizardState] = useReducer(reducerWizardState, initialWizardState);
    const [wizardSteps, setWizardSteps] = useState([]);
    const [isUserInWizard, setIsUserInWizard] = useState(false);
    const [destinationNavActionOnSuccess, setDestinationNavActionOnSuccess] = useState(null);
    const [applicationNumber, setApplicationNumber] = useState(UNINITIALISED);
    const [editFormState, dispatchEditFormState] = useReducer(reducerEditFormState, initialEditFormState);
    const [isUserInOutletEditForm, setIsUserInOutletEditForm] = useState(false);
    const [currentComplaintsMechId, setCurrentComplaintsMechId] = useState(UNINITIALISED);

    const appContext = {
        wizardSteps,
        setWizardSteps,
        isUserInWizard,
        setIsUserInWizard,
        wizardState,
        dispatchWizardState,
        destinationNavActionOnSuccess,
        setDestinationNavActionOnSuccess,
        applicationNumber,
        setApplicationNumber,
        editFormState,
        dispatchEditFormState,
        isUserInOutletEditForm,
        setIsUserInOutletEditForm,
        currentComplaintsMechId,
        setCurrentComplaintsMechId,
    };

    const isLoading = useSelector((state: any) => state.ajaxCallsInProgress.loading);
    const loading = <Loader isLoading={isLoading} loaderText='Loading your information...' />;

    useEffect(() => {
        // google analytics for page changes only captured in PreProd or Production
        const env = window.__RUNTIME_CONFIG__.REACT_APP_TARGET_ENVIRONMENT;
        if (env === 'Pre' || env === 'Production') {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppContext.Provider value={appContext}>
            <div className='App'>
                <header>
                    <SiteHeader authService={appProps.authService} />
                </header>
                <ToastContainer />
                <Router>
                    <Suspense fallback={loading}>
                        <Switch>
                            <Route exact={true} path='/welcome'>
                                <Welcome />
                            </Route>
                            <Route exact={true} path='/about'>
                                <About />
                            </Route>
                            <Route exact={true} path='/disclaimer'>
                                <Disclaimer />
                            </Route>
                            <Route path='/'>
                                <RouteAll authService={appProps.authService} />
                            </Route>
                        </Switch>
                    </Suspense>
                </Router>
                <PageFooter authService={appProps.authService} />
            </div>
        </AppContext.Provider>
    );
}

export default hot(module)(App);
