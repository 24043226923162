import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGraduationCap,
    faShieldAlt,
    faBullhorn,
    faBookMedical,
    faFileExport,
    faThumbsDown,
    faChartLine,
    faBolt,
    faBars,
    faFileSignature,
    faPlus,
    faLock,
    faMailBulk,
    faBan,
    faHandHolding
} from '@fortawesome/free-solid-svg-icons';
import {
    faAddressCard,
    faUserCircle,
    faIdCard,
    faHandshake,
    faMoneyBillAlt,
    faBuilding,
    faFile,
    faFileAlt,
    faFileExcel,
    faMinusSquare,
    faCalendarTimes,
    faFileArchive,
    faEdit,
} from '@fortawesome/free-regular-svg-icons';
import styles from './Dashboard.module.scss';

const { cardImage } = styles;

export const addresscardIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faAddressCard} className={`card-img-top ${cardImage}`} />;
};

export const barsIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faBars} className={`card-img-top ${cardImage}`} />;
};
export const boltIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faBolt} className={`card-img-top ${cardImage}`} />;
};

export const bookmedicalIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faBookMedical} className={`card-img-top ${cardImage}`} />;
};

export const buildingIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faBuilding} className={`card-img-top ${cardImage}`} />;
};

export const bullhornIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faBullhorn} className={`card-img-top ${cardImage}`} />;
};

export const calendarTimesIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faCalendarTimes} className={`card-img-top ${cardImage}`} />;
};

export const chartLineIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faChartLine} className={`card-img-top ${cardImage}`} />;
};

export const editIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faEdit} className={`card-img-top ${cardImage}`} />;
};

export const emailIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faMailBulk} className={`card-img-top ${cardImage}`} />;
};

export const fileArchiveIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faFileArchive} className={`card-img-top ${cardImage}`} />;
};

export const fileAltIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faFileAlt} className={`card-img-top ${cardImage}`} />;
};

export const fileExcelIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faFileExcel} className={`card-img-top ${cardImage}`} />;
};

export const fileExportIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faFileExport} className={`card-img-top ${cardImage}`} />;
};

export const fileIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faFile} className={`card-img-top ${cardImage}`} />;
};

export const fileSignatureIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faFileSignature} className={`card-img-top ${cardImage}`} />;
};

export const graduationcapIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faGraduationCap} className={`card-img-top ${cardImage}`} />;
};

export const handshakeIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faHandshake} className={`card-img-top ${cardImage}`} />;
};

export const idcardIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faIdCard} className={`card-img-top ${cardImage}`} />;
};

export const lockIcon = (title?: string) => {
    return <FontAwesomeIcon size='4x' icon={faLock} className={`card-img-top ${cardImage}`} title={title} />;
};

export const moneybillIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faMoneyBillAlt} className={`card-img-top ${cardImage}`} />;
};

export const minusSquareIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faMinusSquare} className={`card-img-top ${cardImage}`} />;
};

export const plusIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faPlus} className={`card-img-top ${cardImage}`} />;
};

export const shieldIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faShieldAlt} className={`card-img-top ${cardImage}`} />;
};

export const thumbsDownIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faThumbsDown} className={`card-img-top ${cardImage}`} />;
};

export const usercircleIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faUserCircle} className={`card-img-top ${cardImage}`} />;
};

export const banIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faBan} className={`card-img-top ${cardImage}`} />;
};

export const handIcon = () => {
    return <FontAwesomeIcon size='4x' icon={faHandHolding} className={`card-img-top ${cardImage}`} />;
}
