import { defaultApplicationData, defaultApplicationSubmitData, defaultUserData } from '../../api/defaultData';
import { IOutlet, Inquiry, InquiryDefaultObj } from '../../api/interfacesApi';

export interface EditFormPropertiesProps {
    InitialData: InquiryDefaultObj;
}

const defaultOutletsData: IOutlet[] = [];

const defaultData: Inquiry = {
    // introductionText: '',
    application: defaultApplicationData,
    personalDetails: defaultUserData,
    outlets: defaultOutletsData,
    applicationSubmit: defaultApplicationSubmitData,
    differencesUser: null,
    differencesComplaintsCollection: null,
    // complaintsMechanism: defaultComplaintsMechanismData,
};

const inquiryData: InquiryDefaultObj = {
    inquiry: defaultData,
};

const formProperties: EditFormPropertiesProps = {
    // inquiry: defaultData,
    InitialData: inquiryData,
};

export default class OutletDetailsForm {
    public properties: EditFormPropertiesProps = formProperties;
}
