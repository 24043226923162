import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError, endAjaxCall } from './ajaxStatusActions';
import AttachmentApi from '../api/attachmentApi';
import { IAttachmentMetadata } from '../api/interfacesApi';
import AuthService from '../services/AuthService';
import { AttachmentDocumentType } from '../services/Enums';
import { toastSuccessMessage } from './toastrMessages';
import { VALIDATION_SUCCESS_FILEDELETED_MESSAGE } from '../services/Validation';

class AttachmentsActions {
    attachmentApi: AttachmentApi;

    constructor(authService: AuthService) {
        this.attachmentApi = new AttachmentApi(authService);
    }

    GetAttachmentsSuccess(att: IAttachmentMetadata[]) {
        return { type: types.FILE_LOAD_SUCCESS, attachments: att };
    }

    GetAttachmentsByTypeSuccess(att: IAttachmentMetadata[], documentType: AttachmentDocumentType, index?: number) {
        return { type: types.FILE_LOAD_SUCCESS, attachments: att, documentType, index };
    }

    RemoveAttachmentSuccess(att: IAttachmentMetadata[], documentType: AttachmentDocumentType, index?: number) {
        return { type: types.FILE_DELETE_SUCCESS, attachments: att, documentType, index };
    }

    RemoveAttachmentsByTypeSuccess(att: IAttachmentMetadata[], documentType: AttachmentDocumentType, index?: number) {
        return { type: types.FILE_DELETE_SUCCESS, attachments: att, documentType, index };
    }

    GetAttachments = () => (dispatch: any) => {
        dispatch(beginAjaxCall());
        this.attachmentApi
            .getAllAttachmentsMetadata()
            .then(response => {
                const attachments = response as IAttachmentMetadata[];
                dispatch(this.GetAttachmentsSuccess(attachments));
                return attachments;
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                return null;
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    GetAttachmentsByType = (documentType: AttachmentDocumentType) => (dispatch: any) => {
        dispatch(beginAjaxCall());
        this.attachmentApi
            .getAllAttachmentsByTypeMetadata(documentType.toString())
            .then(response => {
                const attachments = response as IAttachmentMetadata[];
                dispatch(this.GetAttachmentsByTypeSuccess(attachments, documentType));
                return attachments;
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                return null;
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    RemoveAttachment = (attachmentId: string, documentType: AttachmentDocumentType, successMsg: string, errorMsgTitle: string) => (dispatch: any) => {
        dispatch(beginAjaxCall());
        this.attachmentApi
            .deleteAttachmentBlobAndMetadata(attachmentId)
            .then(() => {
                this.attachmentApi
                    .getAllAttachmentsByTypeMetadata(documentType.toString())
                    .then(response => {
                        // Success ONLY when we remove and then re-query our collection
                        const attachments = response as IAttachmentMetadata[];
                        if (successMsg && successMsg.length > 0) {
                            toastSuccessMessage(successMsg);
                        }
                        // dispatch updated collection
                        dispatch(this.RemoveAttachmentSuccess(attachments, documentType));
                        return attachments;
                    })
                    .catch(error => {
                        dispatch(ajaxCallError(error));
                        return null;
                    });
            })
            .catch(error => {
                error.title = errorMsgTitle;
                error.message = 'Could not remove the file! Error:' + error.message;
                dispatch(ajaxCallError(error));
                return null;
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };

    RemoveAttachmentsByType = (documentType: AttachmentDocumentType) => (dispatch: any) => {
        dispatch(beginAjaxCall());
        this.attachmentApi
            .getAllAttachmentsByTypeMetadata(documentType.toString())
            .then(attachments => {
                if (attachments && attachments.length > 0) {
                    const countOfAttachments = attachments.length;
                    let currAttachmentNumber = 0;

                    attachments.forEach((fileMetadata: IAttachmentMetadata) => {
                        currAttachmentNumber = currAttachmentNumber + 1;
                        this.attachmentApi
                            .deleteAttachmentBlobAndMetadata(fileMetadata.attachmentId)
                            .then(() => {
                                if (currAttachmentNumber === countOfAttachments) {
                                    // all done dispatch success
                                    toastSuccessMessage(VALIDATION_SUCCESS_FILEDELETED_MESSAGE);

                                    this.attachmentApi
                                        .getAllAttachmentsByTypeMetadata(documentType.toString())
                                        .then(response => {
                                            // Success ONLY when we remove and then re-query our collection
                                            const attachmentsLatest = response as IAttachmentMetadata[];
                                            // dispatch updated collection
                                            dispatch(this.RemoveAttachmentsByTypeSuccess(attachmentsLatest, documentType));
                                            return attachmentsLatest;
                                        })
                                        .catch(error => {
                                            dispatch(ajaxCallError(error));
                                            return null;
                                        });
                                }
                            })
                            .catch(error => {
                                dispatch(ajaxCallError(error));
                                return null;
                            });
                    });
                }
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                return null;
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };
}

export default AttachmentsActions;
