// Suspend Accreditation page
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import ApplicationActions from '../../../actions/applicationActions';
import { toastErrorMessage, toastSuccessMessage } from '../../../actions/toastrMessages';
import { Sleep } from '../../../utils/Common';
import AuthService from '../../../services/AuthService';
import { ApplicationStatusEnum } from '../../../services/Enums';
import { VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../../services/Validation';
import {
    MODALHEADER_CANCEL_ACCREDITATION,
    MODALTEXT_CANCEL_ACCREDITATION_P1,
    MODALTEXT_CANCEL_ACCREDITATION_P2,
    UNINITIALISED,
} from '../../../services/Constants';
import PageTitle from '../../../components/pageTitle';
import PageWrapper from '../../../components/pageWrapper';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import Loader from '../../../controls/Loader';
import ModalDialog from '../../../controls/ModalDialog';

interface CancelAccreditationPageProps {
    authService: AuthService;
}

const CancelAccreditationPage = (props: CancelAccreditationPageProps) => {
    const { authService } = props;

    const history = useHistory();
    const application = useSelector((state: any) => state.application);
    const dispatch = useDispatch();
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [attemptingCancelRequest, setAttemptingCancelRequest] = useState(false);

    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);

    const showSuspendDialogModal = () => {
        setShowCancelDialog(true);
    };

    useEffect(() => {
        if (application && application.applicationId !== UNINITIALISED && application.applicationStatus === ApplicationStatusEnum.PendingCancellation) {
            toastSuccessMessage('Cancel accreditation request submitted successfully.');

            setAttemptingCancelRequest(false);

            Sleep(800).then(() => {
                redirectToDashboard();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application]);

    const handleCancelAccreditation = () => {
        setShowCancelDialog(false);
        setAttemptingCancelRequest(true);
        try {
            application.applicationStatus = ApplicationStatusEnum.PendingCancellation; // API will set this most likely, just for safety
            dispatch(appActions.CancelApplication(application));
            // setAttemptingCancelRequest(false)
            // //go back to dashboard
            // Sleep(800).then(() => {
            //     redirectToDashboard();
            // });
        } catch {
            setAttemptingCancelRequest(false);
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    const handleClickBackToDashboard = () => {
        redirectToDashboard();
    };

    const redirectToDashboard = () => {
        history.push('/');
    };

    const { handleSubmit } = useForm();

    return (
        <>
            <PageTitle title='Cancel accreditation' description='' />
            <PageWrapper
                pageName='CancelAccreditation'
                handleSubmit={handleSubmit(showSuspendDialogModal)}
                handleClickPrevious={handleClickBackToDashboard}
                textForSaveContinueButton='Request cancellation'
                textForBackButton='Back'
            >
                <Loader isLoading={attemptingCancelRequest === true} loaderText='Submitting your cancel accreditation request. Please wait...' />
                <PageFieldsTitle title='Request to cancel accreditation' />
                <p>If you proceed, you acknowledge that once your request is actioned:</p>
                <ul>
                    <li>the cancellation cannot be reversed;</li>
                    <li>you must cease provision of FDR services immediately;</li>
                    <li>no further 60I certificates can be issued using the registration number you are about to cancel;</li>
                    <li>
                        you will be required to complete a new application for accreditation if you want to practice as an FDR practitioner again in the future.
                    </li>
                </ul>
            </PageWrapper>
            <ModalDialog
                dataTestId='CancelDialog'
                modalTitle={MODALHEADER_CANCEL_ACCREDITATION}
                modalBodyText={MODALTEXT_CANCEL_ACCREDITATION_P1}
                modalBodyTextP2={MODALTEXT_CANCEL_ACCREDITATION_P2}
                showMe={showCancelDialog}
                handleClickNo={handleClickBackToDashboard}
                handleClickYes={handleCancelAccreditation}
            />
        </>
    );
};

export default CancelAccreditationPage;
