// personalDetails-Contact page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the Personal details-Name page
//  - allows navigation forward to the Past application history page
import React, { useContext, useEffect, useRef, useState } from 'react';
import { RegoApplicationPageProps } from '../../PageInterfaces';
import { SubmitThisPageIfActive } from '../../PageFunctions';
import { HEADER_PERSONALDETAILS_CONTACT, UNINITIALISED } from '../../../services/Constants';
import { VALIDATION_ERROR_POPUP_MESSAGE, VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../../services/Validation';
import { AddressTypeEnum, AjaxCallStateEnum, WizardStepNumber } from '../../../services/Enums';
import Guid from '../../../services/Guid';
import { IAddress } from '../../../api/interfacesApi';
import { SetApplicationNumber } from '../../../components/wizard/wizardStateManager';
import { MoveWizardForward } from '../../../components/wizard/wizardStepFunctions';
import UserActions from '../../../actions/userActions';
import { toastErrorMessage } from '../../../actions/toastrMessages';
import ErrorSummary from '../../../controls/ErrorSummary';
import AppContext from '../../../stateManagement/context/AppContext';
import PageWrapper from '../../../components/pageWrapper';
import PageTitle from '../../../components/pageTitle';
import PageMandatoryLabelText from '../../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import PersonalDetailsContactFieldsEdit from '../../../fieldLayout/personalDetailsContactFieldsEdit';
import { defaultAddressData } from '../../../api/defaultData';
import { ErrorOnSubmitForm } from '../../../utils/AppUtils';
// import styles from '../Pages.module.scss';

// const { changePrimaryEmailButton } = styles;

const PersonalDetailsContactPage = (props: RegoApplicationPageProps) => {
    const {
        inquiry,
        authService,
        dispatch,
        watch,
        handleClickPrevious,
        handleSubmit,
        register,
        setError,
        setValue,
        trigger,
        control,
        errors,
        isValid,
        isActive,
        ajaxCallStateForPage,
        backEndValidationErrors,
        triggerSubmit,
        setTriggerSubmit,
    } = props;

    const appContext = useContext(AppContext);
    const formRef = useRef();
    const userActions = new UserActions(authService);
    // even though we have redux states, we HAVE to capture the attempt due to navigation and Get and Save user calls on the page
    const [attemptingSave, setAttemptingSave] = useState(false);
    // const [showPostalAddressFields, setShowPostalAddressFields] = useState(true);
    // const uiAddresses: IAddress[] = watch('inquiry.personalDetails.addresses', inquiry.personalDetails.addresses);

    const InitialiseAddressIfEmpty = () => {
        // if new record we need to inject empty addresses collection for UI
        if (inquiry.personalDetails.addresses === undefined || inquiry.personalDetails.addresses === null) {
            inquiry.personalDetails.addresses = [];
        }

        if (inquiry.personalDetails.addresses.length === 0) {
            // const newResidentialAddress: IAddress = { ...defaultAddressData, addressType: AddressTypeEnum.Business };
            const newPostalAddress: IAddress = { ...defaultAddressData, addressType: AddressTypeEnum.Postal };

            // inquiry.personalDetails.addresses.push(newResidentialAddress);
            inquiry.personalDetails.addresses.push(newPostalAddress);
        }
    };

    // initial load of any existing data
    useEffect(() => {
        if (inquiry) {
            // can finally show the Z-number (application number on the wizard)
            SetApplicationNumber(appContext, inquiry?.application.zNumber);
            // if the Address is empty (ie first creation of personal details) then we initialise it now
            InitialiseAddressIfEmpty();
        }

        /* // upon first load only, hide the postal address fields if this is checked (ie from DB)
        if (ParseBool(inquiry.personalDetails.isPostalSameAsResidential) === true) {
            setShowPostalAddressFields(false);
        } */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* const copyDataFromOneAddressTypeToTheOther = (sourceItem: IAddress, destData: IAddress[], destAddressType: AddressTypeEnum) => {
        // cycle through the address collection and find the destination address type (i.e. the one to copy into)
        destData.forEach((addressItem: IAddress, index: number) => {
            if (addressItem.addressType!.toString() === destAddressType.toString()) {
                addressItem.streetAddress = sourceItem.streetAddress;
                addressItem.postcode = sourceItem.postcode;
                addressItem.town = sourceItem.town;
                addressItem.state = sourceItem.state;

                // also MUST setValue() for every inquiry postal address otherwise user can save changes by going back then come forth and values will be 'missing'!
                setValue(`inquiry.personalDetails.addresses[${index}].streetAddress`, sourceItem.streetAddress);
                setValue(`inquiry.personalDetails.addresses[${index}].postcode`, sourceItem.postcode);
                setValue(`inquiry.personalDetails.addresses[${index}].town`, sourceItem.town);
                setValue(`inquiry.personalDetails.addresses[${index}].state`, sourceItem.state);
            }
        });
    };

    const togglePostalAddressFields = (isChecked: boolean) => {
        // determine if we are to show or hide Postal Address fields based on check box value in AddressComponent
        // we do this at this level as the component shows either Postal or Residential address, but we display check box only on residential
        if (isChecked === true && showPostalAddressFields === true) {
            // before we hide the postal address fields, we must ensure at least one entry in business address, otherwise copy all postal to business (for validators to trip if not)
            inquiry.personalDetails.addresses.forEach((addressItem: IAddress, index: number) => {
                // get the item using [index] as filter() returns an array of n items!
                const currItem: IAddress = uiAddresses[index];
                if (currItem.addressType === AddressTypeEnum.Business) {
                    if (
                        (!currItem.streetAddress || currItem.streetAddress === '') &&
                        (!currItem.postcode || currItem.postcode === '') &&
                        (!currItem.state || currItem.state === '') &&
                        (!currItem.town || currItem.town === '')
                    ) {
                        // copy the Postal details into the Residential details of the data object
                        const indexOfPostal = uiAddresses.findIndex(item => {
                            if (item.addressType === AddressTypeEnum.Postal) {
                                return item;
                            } else {
                                return null;
                            }
                        });
                        copyDataFromOneAddressTypeToTheOther(uiAddresses[indexOfPostal], inquiry.personalDetails.addresses, AddressTypeEnum.Business);
                    }
                }
            });

            setShowPostalAddressFields(false);
        } else if (isChecked === false && showPostalAddressFields === false) {
            setShowPostalAddressFields(true);
        }
    }; */

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors.errors?.length > 0) {
            backEndValidationErrors.errors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'StreetAddress':
                        setError('inquiry.personalDetails.address.streetAddress', { type: 'manual', message: validationError.errorMessage });
                        break;
                    case 'Town':
                        setError('inquiry.personalDetails.address.town', { type: 'manual', message: validationError.errorMessage });
                        break;
                    case 'Postcode':
                        setError('inquiry.personalDetails.address.postcode', { type: 'manual', message: validationError.errorMessage });
                        break;
                    case 'State':
                        setError('inquiry.personalDetails.address.state', { type: 'manual', message: validationError.errorMessage });
                        break;
                    case 'EmailAddress':
                        setError('inquiry.personalDetails.emailAddress', { type: 'manual', message: validationError.errorMessage });
                        break;
                    case 'PrimaryPhoneNumber':
                        setError('inquiry.personalDetails.primaryPhoneNumber', { type: 'manual', message: validationError.errorMessage });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    // check dispatch success state
    useEffect(() => {
        if (isActive === true && ajaxCallStateForPage && ajaxCallStateForPage.pageNumber === WizardStepNumber.PersonalDetailsContact) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                setAttemptingSave(false);
                displayBackEndValidations();
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setAttemptingSave(false);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (attemptingSave === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK && !appContext.wizardState.triggerSubmit) {
                    // IF VALID (or OK to move forward):
                    setAttemptingSave(false);
                    MoveWizardForward(appContext, isValid);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const updateDataForPotentialSave = (dataInput: any) => {
        // set address Guid if not already
        if (dataInput.inquiry.personalDetails.addresses !== null) {
            if (dataInput.inquiry.personalDetails.addresses?.length > 0) {
                dataInput.inquiry.personalDetails.addresses.forEach((addressItem: IAddress, index: number) => {
                    if (addressItem.addressId === UNINITIALISED) {
                        const guid = new Guid();
                        dataInput.inquiry.personalDetails.addresses[index].addressId = guid.empty;
                    }
                });
            }
        }
    };

    const submitForm = (data: any) => {
        if (isValid) {
            updateDataForPotentialSave(data);

            // Save changes to application (attempt save, if that fails we do NOT move the Wizard step)
            setAttemptingSave(true);
            dispatch(userActions.SaveUser(data.inquiry.personalDetails));
        } else {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    SubmitThisPageIfActive(isActive, triggerSubmit, formRef, setTriggerSubmit);

    /*
    const handleChangePrimaryEmail = () => {
        // navigate straight to EARS to be able to change primary email address
        const urlEars = process.env.REACT_APP_EARS_URL ?? '';
        window.location.href = urlEars;
    };

    const displayChangeEmailAddressButton = () => {
        return (
            <Button
                variant='outline-secondary'
                className={`btn ${changePrimaryEmailButton}`}
                onClick={handleChangePrimaryEmail}
                data-testid='changePrimaryEmailButton'
            >
                Change primary email address
            </Button>
        );
    };
    */

    return (
        <>
            <PageTitle title='Personal details' description={HEADER_PERSONALDETAILS_CONTACT} />
            <PageWrapper
                pageName='PersonalDetailsContact'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={handleClickPrevious}
            >
                <PageFieldsTitle title='Contact details' />
                <PageMandatoryLabelText />
                <ErrorSummary errors={errors} />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.applicationId'
                    {...register('inquiry.application.applicationId')}
                    defaultValue={inquiry?.application.applicationId}
                />
                <PersonalDetailsContactFieldsEdit
                    inquiry={inquiry}
                    register={register}
                    control={control}
                    errors={errors}
                    watch={watch}
                    isValid={isValid}
                    setValue={setValue}
                    trigger={trigger}
                />
            </PageWrapper>
        </>
    );
};

export default PersonalDetailsContactPage;
