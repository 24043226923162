// Outlet Details-Service Coverage page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the Outlet Details-Opening Hours page
//  - allows navigation forward to the Outlet Details-Accessibility page
import React from 'react';
import { EditPageProps } from '../../pages/PageInterfaces';
import { PLACEHOLDER_OUTLET_TRAVELDISTANCE } from '../../services/Constants';
import { ParseBool } from '../../utils/Common';
import { EmptyRow } from '../../controls/EmptyRow';
import PageMandatoryLabelText from '../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../components/fields/PageFieldsTitle';
import LabelField from '../../components/fields/LabelField';
import RadioButtonGroupField, { RadioButtonOption } from '../../components/fields/RadioButtonGroupField';
import InputTextField from '../../components/fields/InputTextField';

const OutletServiceCoveragePage = (props: EditPageProps) => {
    const { inquiry, setInquiryObj, register, errors, control, setValue, watch } = props;

    const serviceCoverageKmWatched: string = watch(
        'inquiry.application.outletPractitioner.serviceCoverageKm',
        inquiry.application.outletPractitioner?.serviceCoverageKm,
    );

    const radioOptionsTravellingCosts: RadioButtonOption[] = [
        { label: 'Yes', value: true, disabled: false },
        { label: 'No', value: false, disabled: false },
    ];

    const onChangeServiceCoverageOwnExpense = (event: { target: { value: boolean } }) => {
        // manage the value manually as ref did not work for the react hook form
        const isTrue: boolean = ParseBool(event?.target?.value);
        setValue('inquiry.application.outletPractitioner.serviceCoverageOwnExpense', isTrue, { shouldDirty: true, shouldValidate: true });

        // set this so other panels get the update
        inquiry.application.outletPractitioner!.serviceCoverageOwnExpense = isTrue;
        setInquiryObj(inquiry);
        // trigger(); // re-validate
    };

    // tslint:disable-next-line: radix
    const showOwnExpense = serviceCoverageKmWatched && parseInt(serviceCoverageKmWatched) > 0;

    return (
        <>
            <EmptyRow />
            <PageFieldsTitle title='Service coverage' />
            <PageMandatoryLabelText />
            <LabelField id='traveldistanceheaderlabel' displayName='Travel distance' value='' isMandatory={false} />
            <InputTextField
                id='inquiry.application.outletPractitioner.serviceCoverageKm'
                displayName='What is the furthest distance you are prepared to travel to deliver an FDR service?'
                defaultValue={inquiry.application.outletPractitioner?.serviceCoverageKm?.toString()}
                labelText='kilometres'
                isMandatory={false}
                register={register}
                placeHolder={PLACEHOLDER_OUTLET_TRAVELDISTANCE}
                errorsField={errors.inquiry?.application?.outletPractitioner?.serviceCoverageKm}
            />
            {showOwnExpense === true && (
                <>
                    <LabelField id='travellingcostsheaderlabel' displayName='Travelling costs' value='' isMandatory={false} />
                    <RadioButtonGroupField
                        options={radioOptionsTravellingCosts}
                        id='inquiry.application.outletPractitioner.serviceCoverageOwnExpense'
                        displayName='Will you travel this distance at your own expense?'
                        fieldLabel=''
                        defaultValue={inquiry.application.outletPractitioner?.serviceCoverageOwnExpense} // only set default if value exists
                        isMandatory={false}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.serviceCoverageOwnExpense}
                        onChange={onChangeServiceCoverageOwnExpense}
                    />
                </>
            )}
        </>
    );
};

export default OutletServiceCoveragePage;
