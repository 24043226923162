import { IAccreditationConditionDto } from 'api/interfacesApi';
import {
    AccreditationStatusEnum,
    ApplicationStatusEnum,
    AttachmentDocumentType,
    ComplaintsMechanismType,
    DayType,
    ProfessionalMembershipType,
    QualificationOrNMASDetailsType,
    QualificationType,
    RelationshipToEmployerType,
} from '../services/Enums';

export const DisplayUserFriendlyApplicationStatus = (applicationStatusEnumValue: ApplicationStatusEnum | null) => {
    if (applicationStatusEnumValue === null) {
        return 'Loading...please wait';
    }
    switch (applicationStatusEnumValue as ApplicationStatusEnum) {
        case ApplicationStatusEnum.NotStarted:
            return 'Not started';
        case ApplicationStatusEnum.Pending:
            return 'Started';
        case ApplicationStatusEnum.NotPending:
            return 'Not pending';
        case ApplicationStatusEnum.Ineligible:
            return 'Ineligible';
        case ApplicationStatusEnum.Submitted:
            return 'Submitted';
        case ApplicationStatusEnum.UnderReview:
        case ApplicationStatusEnum.AuthorisedOfficerReview:
            return 'Under review';
        case ApplicationStatusEnum.Resubmitted:
            return 'Resubmitted';
        case ApplicationStatusEnum.ActionRequired:
        case ApplicationStatusEnum.PreDetermination:
            return 'Action required';
        case ApplicationStatusEnum.Assessed:
            return 'Assessed';
        case ApplicationStatusEnum.Withdrawn:
            return 'Withdrawn';
        case ApplicationStatusEnum.Discarded:
            return 'Discarded';
        case ApplicationStatusEnum.PendingWithdrawal:
            return 'Pending withdrawal';
        case ApplicationStatusEnum.PendingSuspension:
            return 'Pending suspension';
        case ApplicationStatusEnum.PendingCancellation:
            return 'Pending cancellation';
        case ApplicationStatusEnum.UnderMaintenance:
            return 'Under maintenance';
        default:
            return 'Loading...please wait';
    }
};

export const DisplayUserFriendlyAccreditationStatus = (accreditationStatusEnumValue: AccreditationStatusEnum | null) => {
    switch (accreditationStatusEnumValue as AccreditationStatusEnum) {
        case AccreditationStatusEnum.New:
            return 'None';
        case AccreditationStatusEnum.RegisteredPublic:
            return 'Registered - public';
        case AccreditationStatusEnum.RegisteredNonpublic:
            return 'Registered - non-public';
        case AccreditationStatusEnum.Rejected:
            return 'Does not meet criteria';
        case AccreditationStatusEnum.Suspended:
            return 'Suspended';
        case AccreditationStatusEnum.Withdrawn:
            return 'None';
        case AccreditationStatusEnum.Lapsed:
            return 'Lapsed';
        case AccreditationStatusEnum.Cancelled:
            return 'Cancelled';
        case AccreditationStatusEnum.ConditionalAccreditation:
            return 'Conditionally accredited (insurance required)';
        case AccreditationStatusEnum.Accredited:
            return 'Accredited';
        case AccreditationStatusEnum.Pending:
            return 'Pending';
        case AccreditationStatusEnum.NotApplicable:
            return 'Not applicable';
        default:
            return 'Loading...please wait';
    }
};

export const DisplayUserFriendlyAccreditationConditions = (accreditationConditions: IAccreditationConditionDto[] | null, includeEndDated: boolean) => {
    if (accreditationConditions === undefined || accreditationConditions === null || accreditationConditions.length === 0) {
        return 'None';
    }

    let outputText = '';

    // MUST sort by ReviewDate expiring first, so ASC sort on date is a - b
    accreditationConditions
        .sort((a, b) => new Date(a.reviewDate!).getTime() - new Date(b.reviewDate!).getTime())
        .forEach(item => {
            // either include EndDated ones or not
            if (includeEndDated === true || (includeEndDated === false && (item.endDate === undefined || item.endDate === null))) {
                outputText = outputText + ', ' + item.refConditionText;
            }
        });

    // remove starting , char
    outputText = outputText.startsWith(',') ? outputText.substring(1).trim() : outputText.trim();

    return outputText;
};

export const DisplayGender = (gender: string) => {
    switch (gender) {
        case 'M':
            return 'Male';
        case 'F':
            return 'Female';
        case 'X':
            return 'Indeterminate/Intersex/Unspecified';
        case 'P':
            return `I'd prefer not to say`;
        default:
            return '';
    }
};

export const DisplayMechanismTypeInitialApplication = (complaintsMechanismType: ComplaintsMechanismType | null | undefined) => {
    if (!complaintsMechanismType || complaintsMechanismType === null) {
        return '';
    }
    switch (complaintsMechanismType) {
        case ComplaintsMechanismType.GovernmentFundedFDRService:
            return 'As an employee or external panel member of an organisation funded by the Government to provide FDR services.';
        case ComplaintsMechanismType.FullMembershipProfessionalAssoc:
            return 'Through full membership of a professional association.';
        default:
            return '';
    }
};

export const DisplayMechanismTypeOnceAccredited = (complaintsMechanismType: ComplaintsMechanismType | null) => {
    if (complaintsMechanismType === null) {
        return '';
    }
    switch (complaintsMechanismType) {
        case ComplaintsMechanismType.GovernmentFundedFDRService:
            return 'As an employee or external panel member of an organisation funded by the Government to provide FDR services.';
        case ComplaintsMechanismType.FullMembershipProfessionalAssoc:
            return 'As a private FDR practitioner with my own outlet.';
        default:
            return '';
    }
};

export const DisplayAttachmentDocumentType = (attachmentDocumentType: AttachmentDocumentType) => {
    switch (attachmentDocumentType as AttachmentDocumentType) {
        case AttachmentDocumentType.EvidenceOfNameChange:
            return 'Change of name document';
        case AttachmentDocumentType.FullGraduateDiplomaOfFDR:
            return 'Full Graduate Diploma of FDR';
        case AttachmentDocumentType.SixUnits:
            return 'Six units';
        case AttachmentDocumentType.SixUnitsAndQualification:
            return 'Qualification';
        case AttachmentDocumentType.SixUnitsAndNMAS:
            return 'NMAS';
        case AttachmentDocumentType.ThreeUnits:
            return 'Three units';
        case AttachmentDocumentType.ChildRelatedEmploymentCheck:
            return 'Child-related employment check';
        case AttachmentDocumentType.NationalPoliceCheck:
            return 'National Police Check';
        case AttachmentDocumentType.OrgEmployment:
            return 'Org employment (CM and PII)';
        case AttachmentDocumentType.AssociationMembership:
            return 'Association Membership (CM Private)';
        case AttachmentDocumentType.Insurance:
            return 'Insurance (PII Private)';
        case AttachmentDocumentType.OrgPanel:
            return 'Org Panel (CM only)';
        case AttachmentDocumentType.EvidenceOfBirth:
            return 'Proof of birth document';
        default:
            return '';
    }
};

export const DisplayQualification = (qualification: QualificationType | null) => {
    if (qualification === null) {
        return '';
    }
    switch (qualification as QualificationType) {
        case QualificationType.VocationalGradDipOrEquivalent:
            return 'Full Graduate Diploma of Family Dispute Resolution (or equivalent)';
        case QualificationType.QualificationOrNMAS:
            return 'Assessed as competent in the six core units of the Graduate Diploma of Family Dispute Resolution (or equivalent)';
        case QualificationType.ThreeSpecifiedUnits:
            return 'Assessed as competent in the three specified units of the Vocational Graduate Diploma of Family Dispute Resolution (or equivalent)';
        default:
            return '';
    }
};

export const DisplayQualificationOrNMASDetails = (qualORNMASType: QualificationOrNMASDetailsType | null) => {
    if (qualORNMASType === null) {
        return '';
    }
    switch (qualORNMASType as QualificationOrNMASDetailsType) {
        case QualificationOrNMASDetailsType.AppropriateQualification:
            return 'Appropriate qualification';
        case QualificationOrNMASDetailsType.CurrentNMASAccreditation:
            return 'NMAS';
        default:
            return '';
    }
};

export const DisplayProfessionalMembershipType = (professionalMembershipType: ProfessionalMembershipType | null) => {
    if (professionalMembershipType === null) {
        return '';
    }
    switch (professionalMembershipType as ProfessionalMembershipType) {
        case ProfessionalMembershipType.FullMembership:
            return 'Full Membership';
        default:
            return '';
    }
};

export const DisplayProfessionalIndemnityInsurance = (hasProfessionalIndemnityInsuranceViaMembership: boolean | null | undefined) => {
    if (hasProfessionalIndemnityInsuranceViaMembership === undefined && hasProfessionalIndemnityInsuranceViaMembership === null) {
        return '';
    }

    if (hasProfessionalIndemnityInsuranceViaMembership === true) {
        return 'Through a policy of professional indemnity insurance established through membership with an insurance provider.';
    }

    return 'I do not yet have professional indemnity insurance and wish to apply for conditional accreditation';
};

export const DisplayRelationshipToOrgType = (relationshipToOrganisation: RelationshipToEmployerType | null) => {
    if (relationshipToOrganisation === null) {
        return '';
    }
    switch (relationshipToOrganisation as RelationshipToEmployerType) {
        case RelationshipToEmployerType.Employee:
            return 'Employee';
        case RelationshipToEmployerType.OnPanel:
            return 'External panel member';
        default:
            return '';
    }
};

export const DisplayDayType = (dayType: DayType | null) => {
    if (dayType === null) {
        return '';
    }
    switch (dayType as DayType) {
        case DayType.Monday:
            return 'Monday';
        case DayType.Tuesday:
            return 'Tuesday';
        case DayType.Wednesday:
            return 'Wednesday';
        case DayType.Thursday:
            return 'Thursday';
        case DayType.Friday:
            return 'Friday';
        case DayType.Saturday:
            return 'Saturday';
        case DayType.Sunday:
            return 'Sunday';
        default:
            return '';
    }
};

export const ConvertDayToEnumInt = (dayString: string) => {
    if (dayString === '') {
        return null;
    }
    switch (dayString) {
        case 'Monday':
            return DayType.Monday;
        case 'Tuesday':
            return DayType.Tuesday;
        case 'Wednesday':
            return DayType.Wednesday;
        case 'Thursday':
            return DayType.Thursday;
        case 'Friday':
            return DayType.Friday;
        case 'Saturday':
            return DayType.Saturday;
        case 'Sunday':
            return DayType.Sunday;
        default:
            return null;
    }
};
