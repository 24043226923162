// Diff Personal Details page
import React from 'react';
import { Inquiry } from '../../../api/interfacesApi';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import { AttachmentDocumentType } from '../../../services/Enums';
import { EmptyRow } from '../../../controls/EmptyRow';
import PersonalDetailsContactDiff from '../../../fieldLayout/personalDetailsContactDiff';
import PersonalDetailsNameDiff from '../../../fieldLayout/personalDetailsNameDiff';
import FileUploadDisplay from '../../../pages/Registration/ReviewAndSubmit/fileUploadDisplay';

interface DiffPersonalDetailsPageProps {
    inquiry: Inquiry;
}

const DiffPersonalDetailsPage = (props: DiffPersonalDetailsPageProps) => {
    const { inquiry } = props;

    /*
    const isLoading =
        inquiryDefObj === null ||
        inquiryDefObj?.inquiry === null ||
        inquiryDefObj?.inquiry?.differencesUser === null ||
        inquiryDefObj?.inquiry?.differencesUser?.originalVersion?.id === undefined;

    // get latest user details so we can map them to our inquiry object upon load of this page
    useEffect(() => {
        dispatch(userActions.GetChanges());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (
            differencesUser &&
            differencesUser.originalVersion &&
            differencesUser.originalVersion !== null &&
            differencesUser.originalVersion.id !== undefined
        ) {
            inquiryDefObj.inquiry.differencesUser = differencesUser;
            setInquiry(inquiryDefObj);
            if (IsObjectEmpty(inquiryDefObj.inquiry.differencesUser?.differences) === false) {
                hasAnyChanges();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [differencesUser]);


    useEffect(() => {
        if (ajaxCallsInProgress && ajaxCallsInProgress.callState) {
            setAjaxCallStateForPage({ ajaxCallState: ajaxCallsInProgress.callState, pageNumber: 0 });
        }

        if (ajaxCallsInProgress && ajaxCallsInProgress.callState === AjaxCallStateEnum.CompletedWithValidation) {
            setBackEndValidationErrors(ajaxCallsInProgress.errors);
        }

        // TODO: set Errors here:
    }, [ajaxCallsInProgress]);

    // check dispatch success state
    useEffect(() => {
        if (ajaxCallStateForPage) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                setAttemptingSave(false);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                // displayBackEndValidations();
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setAttemptingSave(false);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (attemptingSave === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK && !appContext.wizardState.triggerSubmit) {
                    setAttemptingSave(false);

                    // IF VALID (or OK to move forward):
                    // SetDestinationNavActionOnSuccessEditForm(appContext, NavigationDestination.ManageDetailsReviewAndSubmitPage);
                    // refresh data set now after changes saved
                    // dispatch(appActions.GetApplication());
                    // allow two seconds to see save changes dialog then go back to dashboard
                    // Sleep(2000).then(() => {
                    //    // pop up success dialog and then decide what flow to go to next
                    //    setShowSubmittedDialogOK(true);
                    // });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

<Loader isLoading={isLoading === true} loaderText='Loading, please wait...' />
    */

    return (
        <>
            <PageFieldsTitle
                title=''
                showLinkToPage={true}
                smallerHeader={true}
                linkToPageDisplayText='Revise personal details changes'
                linkToPageLink='/editpersonaldetails?from=reviewandsubmit'
            />
            <PersonalDetailsNameDiff inquiry={inquiry} />
            <PersonalDetailsContactDiff inquiry={inquiry} />
            <EmptyRow />
            <FileUploadDisplay
                inquiry={inquiry}
                showThisDocType={AttachmentDocumentType.EvidenceOfBirth}
                title='Evidence of date of birth'
                formatForPDF={false}
            />
            <FileUploadDisplay
                inquiry={inquiry}
                showThisDocType={AttachmentDocumentType.EvidenceOfNameChange}
                title='Evidence of name change'
                formatForPDF={false}
            />
        </>
    );
};

export default DiffPersonalDetailsPage;
