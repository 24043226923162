import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { FieldErrors, FieldValues, get } from 'react-hook-form';
import style from './Controls.module.scss';

const { errorSummaryContainer, errorSummaryHeader, errorSummaryItem } = style;

type ErrorSummaryProps<T extends FieldValues> = {
    errors: FieldErrors<T>;
};

// Display errors, they will be nested as per inquiry object (containing personalDetails, application, outlets)
function ErrorSummary<T extends FieldValues>({ errors }: ErrorSummaryProps<T>) {
    if (Object.keys(errors).length === 0) {
        return null;
    }

    // const numberOfPersonalDetailsErrors = Object.keys(errors).filter(e => e.toString() === 'inquiry.personalDetails').length;
    // const numberOfAddressErrors = Object.keys(errors).filter(e => e.toString() === 'inquiry.personalDetails.address').length;

    const errorPersonalDetails: FieldErrors<T> = get(errors, 'inquiry.personalDetails'); // includes .address which we just dont show below
    const errorAddressesArray: any[] = get(errors, 'inquiry.personalDetails.addresses');
    const errorApplication: FieldErrors<T> = get(errors, 'inquiry.application');
    const errorOutlet: FieldErrors<T> = get(errors, 'inquiry.outlet');
    const errorOutletPractitioner: FieldErrors<T> = get(errors, 'inquiry.application.outletPractitioner');
    const errorOutletPractitionerOpeningHoursArray: any[] = get(errors, 'inquiry.application.outletPractitioner.openingHours');
    const errorOutletPractitionerAddresses: FieldErrors<T> = get(errors, 'inquiry.application.outletPractitioner.businessAddress'); // UI only
    const errorComplaintsArray: any[] = get(errors, 'inquiry.application.complaintsMechanism');
    const erroractiveComplaintsMechanismObj: FieldErrors<T> = get(errors, 'inquiry.application.activeComplaintsMechanismObj'); // UI only
    const erroractiveComplaintsMechanismObjAssocation: FieldErrors<T> = get(errors, 'inquiry.application.activeComplaintsMechanismObj.professionalAssociation'); // UI only
    const erroractiveComplaintsMechanismObjOutlet: FieldErrors<T> = get(errors, 'inquiry.application.activeComplaintsMechanismObj.outlet'); // UI only
    /*const erroractiveComplaintsMechanismObjRelationship: FieldErrors<T> = get(
        errors,
        'inquiry.application.activeComplaintsMechanismObj.relationshipToOrganisation',
    ); // UI only */

    const errorAttachments: any[] = get(errors, 'inquiry.application.attachments');
    const errorAttachmentsFundedOrgArray: any[] = get(errors, 'inquiry.application.attachments.fundedOrg');
    const showAddressesArrayErrorMessages = errorAddressesArray !== undefined && errorAddressesArray !== null && errorAddressesArray.length > 0;
    const showPersonalDetailsErrors = errorPersonalDetails !== undefined && errorPersonalDetails !== null;
    const showApplicationErrors = errorApplication !== undefined && errorApplication !== null;
    const showOutletErrors = errorOutlet !== undefined && errorOutlet !== null;
    const showOutletPractitionerErrors = errorOutletPractitioner !== undefined && errorOutletPractitioner !== null;
    const showactiveComplaintsMechanismObjErrors = erroractiveComplaintsMechanismObj !== undefined && erroractiveComplaintsMechanismObj !== null;
    const showComplaintsErrorsArray = errorComplaintsArray !== undefined && errorComplaintsArray !== null && errorComplaintsArray.length > 0;
    const showAttachmentsErrors = errorAttachments !== undefined && errorAttachments !== null;
    const showAttachmentsFundedOrgArray =
        errorAttachmentsFundedOrgArray !== undefined && errorAttachmentsFundedOrgArray !== null && errorAttachmentsFundedOrgArray.length > 0;

    const showAllPersonalDetailsNestedErrors = () => {
        return (
            <>
                {Object.keys(errorPersonalDetails)?.map(
                    error =>
                        error !== 'addresses' && (
                            <ErrorMessage errors={errorPersonalDetails} name={error as any} as='div' key={error} className={errorSummaryItem} />
                        ),
                )}
            </>
        );
    };

    const showAllAddressesArrayNestedErrors = () => {
        const errorMessagesAddresses: any = [];

        Object.keys(errorAddressesArray)?.forEach(index => {
            // tslint:disable-next-line: radix
            const addressErrors: FieldErrors<T> = errorAddressesArray[parseInt(index)];

            errorMessagesAddresses.push(
                Object.keys(addressErrors)?.map(error => (
                    <ErrorMessage errors={addressErrors} name={error as any} as='div' key={error} className={errorSummaryItem} />
                )),
            );
        });

        return <>{errorMessagesAddresses}</>;
    };

    const showAllApplicationNestedErrors = () => {
        return (
            <>
                {Object.keys(errorApplication)?.map(
                    error =>
                        error.toLowerCase() !== 'complaintsmechanism' &&
                        error.toLowerCase() !== 'activecomplaintsmechanismobj' &&
                        error.toLowerCase() !== 'outletpractitioner' &&
                        error.toLowerCase() !== 'attachments' && (
                            <ErrorMessage errors={errorApplication} name={error as any} as='div' key={error} className={errorSummaryItem} />
                        ),
                )}
            </>
        );
    };

    const showAllOutletNestedErrors = () => {
        return (
            <>
                {Object.keys(errorOutlet)?.map(error => (
                    <ErrorMessage errors={errorOutlet} name={error as any} as='div' key={error} className={errorSummaryItem} />
                ))}
            </>
        );
    };

    /*
    inquiry.application.outletPractitioner
        .fields
                --> FieldError (ref, message, type='required')
        .businessAddress
            .addressFields
                --> FieldError (ref, message, type='required')
        .openingHours[]
            .openTime
                --> FieldError (ref, message, type='required')
    */
    const showAllOutletPractitionerErrors = () => {
        const errorMessages: any = [];

        errorMessages.push(
            Object.keys(errorOutletPractitioner)?.map(
                error =>
                    error !== 'businessAddress' &&
                    error !== 'openingHours' && (
                        <ErrorMessage errors={errorOutletPractitioner} name={error as any} as='div' key={error} className={errorSummaryItem} />
                    ),
            ),
        );

        if (errorOutletPractitionerAddresses) {
            errorMessages.push(
                Object.keys(errorOutletPractitionerAddresses)?.map(error => (
                    <ErrorMessage errors={errorOutletPractitionerAddresses} name={error as any} as='div' key={error} className={errorSummaryItem} />
                )),
            );
        }

        if (errorOutletPractitionerOpeningHoursArray) {
            Object.keys(errorOutletPractitionerOpeningHoursArray)?.forEach(index => {
                // tslint:disable-next-line: radix
                const openingHoursErrors: FieldErrors<T> = errorOutletPractitionerOpeningHoursArray[parseInt(index)];

                errorMessages.push(
                    Object.keys(openingHoursErrors)?.map(error => (
                        <ErrorMessage errors={openingHoursErrors} name={error as any} as='div' key={error} className={errorSummaryItem} />
                    )),
                );
            });
        }

        return <>{errorMessages}</>;
    };

    /*
    inquiry.application.complaintsMechanism
	 	[0]
			.activeAttachmentId
					--> FieldError (ref, message, type='required')
			.outlet
				.outletId
					--> FieldError (ref, message, type='required')
            .relationshipToOrganisation
					--> FieldError (ref, message, type='required')
    */
    const showAllComplaintsArrayNestedErrors = () => {
        const errorMessages: any = [];

        Object.keys(errorComplaintsArray)?.forEach(index => {
            // tslint:disable-next-line: radix
            const recordComplaint = errorComplaintsArray[parseInt(index)];

            if (recordComplaint!.outlet) {
                /* if (recordComplaint.outlet?.message !== undefined && recordComplaint.outlet?.message !== null) {
                    // fail safe - should NOT occur - empty collection so message is one level higher than expected
                    errorMessages.push(
                        Object.keys(recordComplaint)?.map(
                            error =>
                                error !== 'evidence' && (
                                    <ErrorMessage errors={recordComplaint} name={error as any} as='div' key={error} className={errorSummaryItem} />
                                ),
                        ),
                    );
                } else { */
                const outletErrors: FieldErrors<T> = recordComplaint.outlet;
                errorMessages.push(
                    Object.keys(outletErrors)?.map(error => (
                        <ErrorMessage errors={outletErrors} name={error as any} as='div' key={error} className={errorSummaryItem} />
                    )),
                );
            }

            if (recordComplaint!.relationshipToOrganisation) {
                errorMessages.push(
                    Object.keys(recordComplaint)?.map(
                        error =>
                            error === 'relationshipToOrganisation' && (
                                <ErrorMessage errors={recordComplaint} name={error as any} as='div' key={error} className={errorSummaryItem} />
                            ),
                    ),
                );
            }

            if (recordComplaint!.activeAttachmentId) {
                /* if (recordComplaint.outlet?.message !== undefined && recordComplaint.outlet?.message !== null) {
                    // fail safe - should NOT occur - empty collection so message is one level higher than expected
                    errorMessages.push(
                        Object.keys(recordComplaint)?.map(
                            error =>
                                error !== 'outlet' && (
                                    <ErrorMessage errors={recordComplaint} name={error as any} as='div' key={error} className={errorSummaryItem} />
                                ),
                        ),
                    );            
                } else { */
                // const evidenceErrors: FieldErrors<T> = recordComplaint.attachmentExists;
                errorMessages.push(
                    Object.keys(recordComplaint)?.map(
                        error =>
                            error === 'activeAttachmentId' && (
                                <ErrorMessage errors={recordComplaint} name={error as any} as='div' key={error} className={errorSummaryItem} />
                            ),
                    ),
                );
            }
        });

        return <>{errorMessages}</>;
    };

    /*
    inquiry.application.activeComplaintsMechanismObj
        .complaintsMechanismId
        .outlet
            .outletId
        .professionalMembershipType
        .professionalAssociation
            .professionalAssociationId
                --> FieldError (ref, message, type='required')
    */
    const showAllactiveComplaintsMechanismObjsErrors = () => {
        const errorMessages: any = [];

        if (erroractiveComplaintsMechanismObjOutlet) {
            errorMessages.push(
                Object.keys(erroractiveComplaintsMechanismObjOutlet)?.map(error => (
                    <ErrorMessage errors={erroractiveComplaintsMechanismObjOutlet} name={error as any} as='div' key={error} className={errorSummaryItem} />
                )),
            );
        }

        if (erroractiveComplaintsMechanismObjAssocation) {
            errorMessages.push(
                Object.keys(erroractiveComplaintsMechanismObjAssocation)?.map(error => (
                    <ErrorMessage errors={erroractiveComplaintsMechanismObjAssocation} name={error as any} as='div' key={error} className={errorSummaryItem} />
                )),
            );
        }

        /*if (erroractiveComplaintsMechanismObjRelationship) {
            errorMessages.push(
                Object.keys(erroractiveComplaintsMechanismObjRelationship)?.map(error => (
                    <ErrorMessage
                        errors={erroractiveComplaintsMechanismObjRelationship}
                        name={error as any}
                        as='div'
                        key={error}
                        className={errorSummaryItem}
                    />
                )),
            );
        }*/

        // const evidenceErrors: FieldErrors<T> = recordComplaint.attachmentExists;
        errorMessages.push(
            Object.keys(erroractiveComplaintsMechanismObj)?.map(
                error =>
                    (error.toLowerCase() === 'activeattachmentid' ||
                        error.toLowerCase() === 'complaintsmechanismtype' ||
                        error.toLowerCase() === 'relationshiptoemployer' ||
                        error.toLowerCase() === 'membershipfromdate' ||
                        error.toLowerCase() === 'membershiptodate' ||
                        error.toLowerCase() === 'professionalindemnityinsurancefromdate' ||
                        error.toLowerCase() === 'professionalindemnityinsurancetodate' ||
                        error.toLowerCase() === 'hasprofessionalindemnityinsuranceviamembership') && (
                        <ErrorMessage errors={erroractiveComplaintsMechanismObj} name={error as any} as='div' key={error} className={errorSummaryItem} />
                    ),
            ),
        );

        return <>{errorMessages}</>;
    };

    /*
        errorAttachments =
            inquiry.application.attachments
                FieldError (ref, message, type='required')

                [0] --> .childrelatedemploymentcheck 
                [1] --> .childrelatedemploymentcheck FieldError (ref, message, type='required')
    */
    const showAllAttachmentsNestedErrors = () => {
        /*const errorMessages: any = [];

        Object.keys(errorAttachments)?.forEach(index => {
            // tslint:disable-next-line: radix
            const childrelatedemploymentcheckErrors: FieldErrors<T> = errorAttachments[parseInt(index)].childrelatedemploymentcheck;

            if (childrelatedemploymentcheckErrors) {
                errorMessages.push(
                    Object.keys(childrelatedemploymentcheckErrors)?.map(error => (
                        <ErrorMessage errors={childrelatedemploymentcheckErrors} name={error as any} as='div' key={error} className={errorSummaryItem} />
                    )),
                );
            }
        });

        return <>{errorMessages}</>; */

        let foundEditPersonalDetailAttachments = false;

        Object.keys(errorAttachments)?.forEach((item, index) => {
            if (item.toLowerCase() === 'proofofbirth' || item.toLowerCase() === 'changeofname') {
                foundEditPersonalDetailAttachments = true;
            }
        });

        if (foundEditPersonalDetailAttachments === false) {
            return (
                <>
                    {Object.keys(errorApplication)?.map(
                        error =>
                            error === 'attachments' && (
                                <ErrorMessage errors={errorApplication} name={error as any} as='div' key={error} className={errorSummaryItem} />
                            ),
                    )}
                </>
            );
        } else {
            return (
                <>
                    {Object.keys(errorAttachments)?.map(
                        error =>
                            (error === 'changeofname' || error === 'proofofbirth') && (
                                <ErrorMessage errors={errorAttachments} name={error as any} as='div' key={error} className={errorSummaryItem} />
                            ),
                    )}
                </>
            );
        }

        // obtain the generic attachments errors from inquiry.application.attachments error collection
        // if (errorApplication.attachments.changeofname !== undefined || errorApplication.attachments.changeofname !== undefined)
    };

    /*
        errorAttachmentsFundedOrgArray = 
            inquiry.application.attachments.fundedOrg
	            [0] --> .evidence FieldError (ref, message, type='required')
	            [1] --> .evidence FieldError (ref, message, type='required')

    */
    const showAllAttachmentsArrayFundedOrg = () => {
        const errorMessagesFundedOrg: any = [];

        Object.keys(errorAttachmentsFundedOrgArray)?.forEach(index => {
            // tslint:disable-next-line: radix
            const fundedOrgErrors: FieldErrors<T> = errorAttachmentsFundedOrgArray[parseInt(index)];

            errorMessagesFundedOrg.push(
                Object.keys(fundedOrgErrors)?.map(error => (
                    <ErrorMessage errors={fundedOrgErrors} name={error as any} as='div' key={error} className={errorSummaryItem} />
                )),
            );
        });

        return <>{errorMessagesFundedOrg}</>;
    };

    return (
        <div className={errorSummaryContainer} data-testid='ValidationSummaryContainer'>
            <div className={errorSummaryHeader}>Validation Summary</div>
            {showPersonalDetailsErrors && showAllPersonalDetailsNestedErrors()}
            {showAddressesArrayErrorMessages && showAllAddressesArrayNestedErrors()}
            {showApplicationErrors && showAllApplicationNestedErrors()}
            {showOutletErrors && showAllOutletNestedErrors()}
            {showOutletPractitionerErrors && showAllOutletPractitionerErrors()}
            {showComplaintsErrorsArray && showAllComplaintsArrayNestedErrors()}
            {showactiveComplaintsMechanismObjErrors && showAllactiveComplaintsMechanismObjsErrors()}
            {showAttachmentsErrors && showAllAttachmentsNestedErrors()}
            {showAttachmentsFundedOrgArray && showAllAttachmentsArrayFundedOrg()}
        </div>
    );
}

export default ErrorSummary;
