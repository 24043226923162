import React from 'react';
import { WizardStepCurrentStateEnum } from './wizardEnums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle as fasCircle, faCheck, faEllipsisH, faReply, faBars, faExclamation, IconDefinition, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { faCircle as farCircle, faDotCircle } from '@fortawesome/free-regular-svg-icons';
import styles from './wizardMenuIcons.module.scss';

const { progressIconContainer, exclamationContainer } = styles;

export const backToIcon = () => {
    return <FontAwesomeIcon title='' color='grey' icon={faReply} />;
};

export const hamburgerIcon = () => {
    return <FontAwesomeIcon color='black' icon={faBars} />;
};

export const lightBulbIcon = () => {
    return <FontAwesomeIcon title='' color='goldenrod' icon={faLightbulb} />;
};

const circleMenuItemBackground = (color: string) => {
    return <FontAwesomeIcon color={color} background-color={color} icon={fasCircle} size='2x' />;
};

const menuItemInnerIcon = (color: string, iconDefinition: IconDefinition, inverse?: boolean) => {
    return <FontAwesomeIcon color={color} icon={iconDefinition} size='1x' inverse={inverse} />;
};

export const progressIcon = (status: WizardStepCurrentStateEnum) => {
    const { initial, invalidPartial, invalidError, completed, substepcompleted } = WizardStepCurrentStateEnum;

    switch (status) {
        case initial:
            return (
                <div className={progressIconContainer}>
                    <span className='fa-layers fa-fw'>
                        {circleMenuItemBackground('lightgrey')}
                        {menuItemInnerIcon('#50748a', faEllipsisH)}
                    </span>
                </div>
            );
        case substepcompleted:
            return (
                <div className={progressIconContainer}>
                    <span className='fa-layers fa-fw'>
                        {circleMenuItemBackground('lightgrey')}
                        {menuItemInnerIcon('green', faEllipsisH)}
                    </span>
                </div>
            );
        case invalidPartial:
            return (
                <div className={progressIconContainer}>
                    <span className={`fa-layers fa-fw ${exclamationContainer}`}>
                        {circleMenuItemBackground('orange')}
                        {menuItemInnerIcon('white', faExclamation, true)}
                    </span>
                </div>
            );
        case invalidError:
            return (
                <div className={progressIconContainer}>
                    <span className={`fa-layers fa-fw ${exclamationContainer}`}>
                        {circleMenuItemBackground('#c20000')}
                        {menuItemInnerIcon('white', faExclamation, true)}
                    </span>
                </div>
            );
        case completed:
            // transform='shrink-6' />
            return (
                <div className={progressIconContainer}>
                    <span className='fa-layers fa-fw'>
                        {circleMenuItemBackground('green')}
                        {menuItemInnerIcon('white', faCheck, true)}
                    </span>
                </div>
            );
        default:
            return (
                <div className={progressIconContainer}>
                    <span className='fa-layers fa-fw'>
                        {circleMenuItemBackground('lightgrey')}
                        {menuItemInnerIcon('#50748a', faEllipsisH)}
                    </span>
                </div>
            );
    }
};

export const childIcon = (isActive: boolean) => {
    return isActive ? <FontAwesomeIcon color='lightgrey' icon={faDotCircle} size='1x' /> : <FontAwesomeIcon color='lightgrey' icon={farCircle} size='1x' />;
};
