// Manage Details Review and Submit page
import React, { useContext, useRef, useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../actions/actionTypes';

import { toastErrorMessage } from '../../actions/toastrMessages';
import { IApplication, IComplaintMechanism, Inquiry, InquiryDefaultObj, IUser } from '../../api/interfacesApi';
import { AjaxCallStateForPage } from '../../pages/PageInterfaces';
import AuthService from '../../services/AuthService';
import { VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../services/Validation';
import { AjaxCallStateEnum, AttachmentDocumentType } from '../../services/Enums';
import { HEADER_MANAGEDETAILS_REVIEWANDSUBMIT, UNINITIALISED } from '../../services/Constants';
import AppContext from '../../stateManagement/context/AppContext';
import ApplicationActions from '../../actions/applicationActions';
import UserActions from '../../actions/userActions';
import AttachmentActions from '../../actions/attachmentsActions';
import PageTitle from '../../components/pageTitle';
import { NavigateAway } from '../../components/wizard/wizardStepFunctions';
import RegistrationApplicationForm from '../Registration/RegistrationApplicationForm';
import ManageDetailsReviewAndSubmitPage from './manageDetailsReviewAndSubmitPage';
import Loader from '../../controls/Loader';
import { IsValidGuidIdentifier, RemoveAttachmentsFromAzureIfExist } from '../../utils/AppUtils';
import { IsObjectEmpty, Sleep } from '../../utils/Common';
import { RemoveCMAttachmentsForThisCM } from 'utils/ComplaintsUtils';

interface ManageDetailsReviewAndSubmitContainerProps {
    authService: AuthService;
}

interface UIstate {
    isLoadingFormData: boolean;
    isLoadingInitialUserEditCopy: boolean;
    isLoadingInitialApp: boolean; // need the App to then get the changes collection
    isLoadingCMchangesForView: boolean;
    isProcessingDiscardAll: boolean;
    isProcessingDiscardOne: boolean;
    discardIsLoadingEditCopyCMs: boolean;
    discardIsLoadingCMchanges: boolean;
    isDiscardingUserChanges: boolean;
    isDiscardingCMChanges: boolean;
}

const initUiState: UIstate = {
    isLoadingFormData: true, // default initial loading indicator to TRUE
    isLoadingInitialUserEditCopy: false,
    isLoadingCMchangesForView: false,
    isLoadingInitialApp: false,
    isProcessingDiscardAll: false,
    isProcessingDiscardOne: false,
    discardIsLoadingEditCopyCMs: false,
    discardIsLoadingCMchanges: false,
    isDiscardingUserChanges: false,
    isDiscardingCMChanges: false,
};

const ManageDetailsReviewAndSubmitContainer = (props: ManageDetailsReviewAndSubmitContainerProps) => {
    const { authService } = props;

    const history = useHistory();
    const dispatch = useDispatch();
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const userActions = useMemo(() => {
        return new UserActions(authService);
    }, [authService]);
    const attachmentActions = useMemo(() => {
        return new AttachmentActions(authService);
    }, [authService]);

    const user: IUser = useSelector((stateUser: any) => stateUser.user);
    const application: IApplication = useSelector((stateApp: any) => stateApp.application);
    const differencesUser: any = useSelector((stateDifferences: any) => stateDifferences.differencesUser);
    const differencesComplaintsMechanisms: any = useSelector((stateDifferences: any) => stateDifferences.differencesComplaintsMechanism);
    // store the form in state as we update the steps constantly after user actions
    const applicationForm = useRef(new RegistrationApplicationForm());
    const [inquiryDefObj, setInquiry] = useState<InquiryDefaultObj>(applicationForm.current.properties.InitialData);
    const appContext = useContext(AppContext);
    const [shouldRefresh, setRefresh] = useState(false);

    const ajaxCallsInProgress: types.ICallStatus = useSelector((stateAjax: any) => stateAjax.ajaxCallsInProgress);
    const initCallStateForPage: AjaxCallStateForPage = { ajaxCallState: AjaxCallStateEnum.NotStarted, pageNumber: 0 };
    const [ajaxCallStateForPage, setAjaxCallStateForPage] = useState(initCallStateForPage);
    const [backEndValidationErrors, setBackEndValidationErrors] = useState([]);
    const [userObj, setUserObj] = useState(user);
    const [displayState, setDisplayState] = useState(initUiState);

    const navigateBackToDashboard = () => {
        const keepChanges = false;
        const isDirtyForcedFalse = false;
        const isValidForNav = true;

        NavigateAway(appContext, history, keepChanges, isDirtyForcedFalse, isValidForNav);
    };

    // used by children to set this object at parent-level
    const setInquiryObj = (inquiry: Inquiry) => {
        inquiryDefObj.inquiry = inquiry;
        setInquiry(inquiryDefObj);
    };

    // get latest user details so we can map them to our inquiry object upon load of this page
    useEffect(() => {
        setDisplayState({ ...displayState, isLoadingInitialUserEditCopy: true });
        dispatch(userActions.GetChanges());

        // get latest user details so we can map them to our inquiry object upon load of this page
        dispatch(userActions.GetUserEditCopy()); // do this for Dispatch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (IsObjectEmpty(differencesUser) || IsObjectEmpty(differencesUser.differences)) {
            // do nothing
        } else {
            inquiryDefObj.inquiry.differencesUser = differencesUser;
            setInquiry(inquiryDefObj);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [differencesUser]);

    // when the diffComplaints is refreshed we need to refresh the UI
    useEffect(() => {
        if (IsObjectEmpty(differencesComplaintsMechanisms) || IsObjectEmpty(differencesComplaintsMechanisms.originalVersion)) {
            // do nothing
        } else {
            inquiryDefObj.inquiry.differencesComplaintsCollection = differencesComplaintsMechanisms;
            setInquiry(inquiryDefObj);
            setRefresh(!shouldRefresh);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [differencesComplaintsMechanisms]);

    const getComplaintMechanismChanges = () => {
        // tslint:disable-next-line: prefer-const
        let complaintsMechanismIds: string[] = [];
        // build up CM ids
        inquiryDefObj.inquiry.application.complaintsMechanism.forEach((complaint: IComplaintMechanism) => {
            // empty slots in collection have UNINITIALISED id
            if (IsValidGuidIdentifier(complaint.complaintsMechanismId)) {
                complaintsMechanismIds.push(complaint.complaintsMechanismId);
            }
        });
        // go get the changes
        dispatch(appActions.GetAllComplaintsMechanismChanges(complaintsMechanismIds));
    };

    useEffect(() => {
        if (inquiryDefObj.inquiry && application && application.applicationId !== UNINITIALISED) {
            inquiryDefObj.inquiry.application = application;
            setInquiry(inquiryDefObj);

            const complaintsDataExists =
                inquiryDefObj.inquiry.application.complaintsMechanism && inquiryDefObj.inquiry.application.complaintsMechanism.length >= 0;

            // obtain all CM changes VERY deliberately depending upon initial load or discard we get different sets of data!
            if (displayState.isLoadingFormData === true && displayState.isProcessingDiscardAll === false && displayState.isLoadingCMchangesForView === false) {
                if (complaintsDataExists === true) {
                    setDisplayState({ ...displayState, isLoadingCMchangesForView: true });
                    getComplaintMechanismChanges();
                }
            }
            if (displayState.isProcessingDiscardAll === true && displayState.discardIsLoadingCMchanges === false) {
                // if there are no CM data records do nothing, as there is nothing to discard
                if (complaintsDataExists === true) {
                    setDisplayState({ ...displayState, discardIsLoadingCMchanges: true });
                    getComplaintMechanismChanges();
                }
            }
            if (displayState.isProcessingDiscardOne === true) {
                // there should be at least one CM record
                if (complaintsDataExists === true) {
                    setDisplayState({ ...displayState, isProcessingDiscardOne: false });
                    getComplaintMechanismChanges();
                }
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application]);

    useEffect(() => {
        // NOTE: This is the EditCopy of user (as discardChanges needs it) IF it exists - as ANY other call to user could knock out the EditCopy version we need to check we dont overwrite EditCopy version
        // NOTE: It could be that NO EditCopy exists if only CM changes are made, btw.
        if (userObj.isEditCopy === true) {
            // do nothing
        } else {
            // definitely replace this object by this UI ONLY (otherwise we get normal not edit user copy)
            inquiryDefObj.inquiry.personalDetails = user;
            setInquiry(inquiryDefObj);

            setUserObj(user); // this is the one to discard if required
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const IsUndefinedOrNull = (item: any) => {
        return item === undefined || item === null;
    };

    const DiscardAllCMAttachmentsAndData = () => {
        // cycle through each of the edit copy CMs and remove them
        if (inquiryDefObj.inquiry.differencesComplaintsCollection) {
            inquiryDefObj.inquiry.differencesComplaintsCollection.forEach(complaintMechanismDiff => {
                const complaintMechanism: IComplaintMechanism | null =
                    IsObjectEmpty(complaintMechanismDiff.originalVersion) === false ? (complaintMechanismDiff.originalVersion as IComplaintMechanism) : null;

                // MUST remove all attachments (evidence)
                if (complaintMechanism !== null) {
                    // ONLY clear the editcopy version of documents NOT the originals
                    if (
                        complaintMechanism.isEditCopy === true &&
                        IsUndefinedOrNull(complaintMechanism.evidence) !== true &&
                        complaintMechanism.evidence.length > 0
                    ) {
                        RemoveCMAttachmentsForThisCM(attachmentActions, dispatch, inquiryDefObj.inquiry, complaintMechanism.complaintsMechanismId);
                    } // end if evidence exists

                    // and also the item
                    dispatch(appActions.DiscardChangesComplaintsMechanism(complaintMechanism));
                }
            });
        }
    };

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors && backEndValidationErrors?.length > 0) {
            backEndValidationErrors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'InvalidName':
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    const ProcessAjaxCallsCompletedOK = () => {
        if (displayState.isLoadingCMchangesForView === true) {
            // add extra wait for user edit copy is slow
            Sleep(3000).then(() => {
                setDisplayState({ ...displayState, isLoadingCMchangesForView: false, isLoadingFormData: false });
            });
        }
        if (displayState.isLoadingInitialApp === true) {
            setDisplayState({ ...displayState, isLoadingInitialApp: false });
            // handled in useEffect for Application API object returned, now get all changed CMs
        }
        if (displayState.isLoadingInitialUserEditCopy === true) {
            setDisplayState({ ...displayState, isLoadingInitialUserEditCopy: false, isLoadingInitialApp: true });
            // load application, from this data we load complaintsMech data (from that we load CM changes)
            // for Review and Submit page we need to ditch Edit CMs (as the diff object will contain entries as required), other Add CMs we keep
            const doNotCopyActiveComplaint = undefined;
            const keepOriginalCMsAndNewCMsOnly = true;
            dispatch(appActions.GetApplication(doNotCopyActiveComplaint, keepOriginalCMsAndNewCMsOnly));
        }

        // discard changes flows
        if (displayState.isDiscardingCMChanges === true) {
            Sleep(5000).then(() => {
                // mostly back to init
                setDisplayState({ ...initUiState, isLoadingFormData: false });

                // MUST navigate by clearing STATE otherwise dashboard NOTE for changes will remain (due to the way multiple user is read in)
                window.location.href = '/';
            });
        }

        if (displayState.isDiscardingUserChanges === true) {
            setDisplayState({ ...displayState, isDiscardingUserChanges: false, isDiscardingCMChanges: true });

            // allow time to load the new collection full of CM changes via SubmitPage->Items
            Sleep(200).then(() => {
                DiscardAllCMAttachmentsAndData();
            });
        }

        if (displayState.discardIsLoadingEditCopyCMs === true) {
            setDisplayState({ ...displayState, discardIsLoadingEditCopyCMs: false, isDiscardingUserChanges: true });

            // MUST remove all attachments from Azure at this point
            RemoveAttachmentsFromAzureIfExist(
                attachmentActions,
                dispatch,
                AttachmentDocumentType.EvidenceOfNameChange,
                inquiryDefObj.inquiry.application.attachments,
            );
            RemoveAttachmentsFromAzureIfExist(
                attachmentActions,
                dispatch,
                AttachmentDocumentType.EvidenceOfBirth,
                inquiryDefObj.inquiry.application.attachments,
            );

            dispatch(userActions.DiscardChanges(userObj));
        }
    };

    useEffect(() => {
        if (ajaxCallsInProgress && ajaxCallsInProgress.callState) {
            setAjaxCallStateForPage({ ajaxCallState: ajaxCallsInProgress.callState, pageNumber: 0 });
        }

        if (ajaxCallsInProgress && ajaxCallsInProgress.callState === AjaxCallStateEnum.CompletedWithValidation) {
            setBackEndValidationErrors(ajaxCallsInProgress.errors);
        }

        // TODO: set Errors here:
    }, [ajaxCallsInProgress]);

    // check dispatch success state
    useEffect(() => {
        if (ajaxCallStateForPage) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                setDisplayState(initUiState);
                displayBackEndValidations();
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setDisplayState(initUiState);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // completedOK
                if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK) {
                    ProcessAjaxCallsCompletedOK();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const handleDiscardAllChanges = () => {
        // need to obtain collection of EditCopy items then remove those (as we prior obtained a mix)
        setDisplayState({ ...displayState, isProcessingDiscardAll: true, discardIsLoadingEditCopyCMs: true });

        // first empty the diff collection
        inquiryDefObj.inquiry.differencesComplaintsCollection = null;
        inquiryDefObj.inquiry.application.complaintsMechanism = [];
        setInquiryObj(inquiryDefObj.inquiry);

        // for discard we need all Edit CMs
        dispatch(appActions.GetApplication(undefined, false));
    };

    const refreshReviewAndSubmit = () => {
        setDisplayState({ ...displayState, isProcessingDiscardOne: true });

        // first empty the diff collection
        inquiryDefObj.inquiry.differencesComplaintsCollection = null;
        inquiryDefObj.inquiry.application.complaintsMechanism = [];
        setInquiryObj(inquiryDefObj.inquiry);

        // for discard we need all Edit CMs
        dispatch(appActions.GetApplication(undefined, false));
    };

    const validationSchema = undefined; // dynamicValidationSchema ? yupResolver(dynamicValidationSchema) : undefined;

    // watch lets us keep track of state to keep an eye on it
    // formState lets us validate
    const {
        watch,
        register,
        // unregister,
        reset,
        trigger,
        getValues,
        setValue,
        setError,
        control,
        handleSubmit,
        formState: { errors, isValid, isDirty },
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: useMemo(() => {
            return inquiryDefObj;
        }, [inquiryDefObj]),
        /// ERROR until FIELDS MATCH: is not assignable to type 'UnpackNestedValue<DeepPartial<TFieldValues>> | undefined'.
        resolver: useMemo(() => {
            return validationSchema;
        }, [validationSchema]),
    }); // mode:all is to validate after every keypress

    const pageProps = {
        // application,
        // user,
        isLoadingChanges: displayState.isLoadingFormData,
        inquiry: inquiryDefObj.inquiry,
        refreshReviewAndSubmit,
        setInquiryObj,
        // attachments: inquiryDefObj.inquiry?.application?.attachments,
        // goingBackwards: wizardState?.goingBackwards,
        authService,
        dispatch,
        // handleClickPrevious,
        handleSubmit,
        register,
        // unregister,
        trigger,
        setValue,
        getValues,
        setError,
        watch,
        // clearErrors,
        reset,
        control,
        errors,
        isValid,
        // isActive,
        isDirty,
        // ajaxCallStateForPage,
        // backEndValidationErrors,
        // triggerSubmit: wizardState.triggerSubmit,
        // setTriggerSubmit, // use dispatcher now
        navigateBackToDashboard,
        handleDiscardAllChanges,
    };

    // keep the useForm() separate to the page inquiry object so we avoid the form defaultValue error on compile, and make validator dynamic in state to helpas well
    return (
        <>
            <PageTitle title='Review and submit changes' description={HEADER_MANAGEDETAILS_REVIEWANDSUBMIT} />
            {displayState.isLoadingFormData === true && <Loader isLoading={true} loaderText='Loading your information...' />}
            {(displayState.isProcessingDiscardAll === true || displayState.isDiscardingCMChanges === true) && (
                <Loader isLoading={true} loaderText='Discarding your information. Please wait...' />
            )}
            {displayState.isLoadingFormData === false && <ManageDetailsReviewAndSubmitPage {...pageProps} />}
        </>
    );
};

export default ManageDetailsReviewAndSubmitContainer;
