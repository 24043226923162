import React, { useMemo, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { IApplication, IUser } from '../../api/interfacesApi';
import UserApi from '../../api/userApi';
import ApplicationActions from '../../actions/applicationActions';
import UserActions from '../../actions/userActions';
import { EmptyRow } from '../../controls/EmptyRow';
import ModalDialogDevilsToolbox from '../../controls/ModalDialogDevilsToolbox';
import { DeleteAllMyData, GetInputRadioButton } from './devilsToolboxUtils';
import { defaultUserData } from '../../api/defaultData';

interface DevilsToolboxModalProps {
    authService: any;
    isPre: boolean;
    closeModalEvent: any;
}

export const DevilsToolboxModal = (props: DevilsToolboxModalProps) => {
    const { authService, isPre, closeModalEvent } = props;

    const dispatch = useDispatch();
    const userActions = useMemo(() => {
        return new UserActions(authService);
    }, [authService]);
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const applications: IApplication[] = useSelector((state: any) => state.applications);
    const user: IUser = useSelector((stateUser: any) => stateUser.user);
    const userApi = useMemo(() => {
        return new UserApi(authService);
    }, [authService]);

    const emptyAppsList: IApplication[] = [];
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [applicationsList, setApplicationsList] = useState(emptyAppsList);
    const [selectedAppProfileId, setSelectedAppProfileId] = useState();

    useEffect(() => {
        dispatch(appActions.GetAllApplications());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (applications !== undefined) {
            if (applications === null || applications.length === 0) {
                setApplicationsList(emptyAppsList);
            } else {
                const appsList = emptyAppsList;
                Array.from(applications).forEach(app => {
                    appsList.push(app);
                });

                /* for (let i = 0; i < applications.length; i++) {
                    appsList.push(applications[i]);
                }*/
                setApplicationsList(appsList);

                // now data is loaded
                setIsDataLoaded(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applications]);

    const handleCloseClick = () => {
        closeModalEvent();
    };

    const radioButtonOnChangeEvent = (event: any) => {
        // must store the ProfileId
        const profileId = event.target.value;
        setSelectedAppProfileId(profileId);
    };

    const childrenOfTheToolbox = () => {
        if (isPre === true) {
            return null;
        }

        if (!applicationsList || applicationsList.length === 0) {
            return (
                <>
                    <h2>List of selectable applications</h2>
                    <p>NONE FOUND.</p>
                </>
            );
        }

        const getChildInputRow = (app: IApplication) => {
            const emptyPersonalDetails: IUser = defaultUserData;

            return (
                <Row key={app.applicationId}>
                    <Col>
                        {GetInputRadioButton(
                            radioButtonOnChangeEvent,
                            app.applicationId,
                            app.personalDetails === null ? emptyPersonalDetails : app.personalDetails,
                            app.zNumber,
                            app.applicationStatus === null ? '' : app.applicationStatus,
                        )}
                    </Col>
                </Row>
            );
        };

        const arrayCheckBoxes: any = [];

        applicationsList.forEach(app => {
            arrayCheckBoxes.push(getChildInputRow(app));
        });

        // place new check box for each application
        return (
            <>
                <Row>
                    <Col>
                        <h2>List of selectable applications</h2>
                    </Col>
                </Row>
                <EmptyRow />
                {arrayCheckBoxes}
            </>
        );
    };

    const handleAssignToMeButtonClick = () => {
        if (!selectedAppProfileId) {
            alert('Please select an application first!');
        } else {
            if (window.confirm('Are you sure you wish to re-assign this application to yourself?')) {
                user.id = selectedAppProfileId!;
                dispatch(userActions.AssignApplicationToMe(user));
            }
        }
    };

    const handleDeleteAllMyDataButtonClick = () => {
        if (window.confirm('Are you sure you wish to delete ALL your user data??')) {
            DeleteAllMyData(userApi);
        }
    };

    const handleStartAnotherAppButtonClick = () => {
        if (window.confirm('Are you sure you wish to start a ANOTHER application?')) {
            dispatch(userActions.StartAnotherApplication(user));
        }
    };

    return (
        <>
            <ModalDialogDevilsToolbox
                isPre={isPre}
                isDataLoaded={isDataLoaded}
                children={childrenOfTheToolbox()}
                handleAssignToMeButtonClick={handleAssignToMeButtonClick}
                handleDeleteAllMyDataButtonClick={handleDeleteAllMyDataButtonClick}
                handleStartAnotherAppButtonClick={handleStartAnotherAppButtonClick}
                handleCloseClick={handleCloseClick}
            />
        </>
    );
};

export default DevilsToolboxModal;
