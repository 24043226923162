// Outlet Details-Opening Hours page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { IOpeningHours } from '../../api/interfacesApi';
import {
    GUIDANCETEXT_OUTLET_APPOINTMENTTYPES,
    GUIDANCETEXT_OUTLET_OPERATINGHOURS,
    HEADER_EDIT_OUTLET_OPENINGHOURS,
    NOTE_OUTLET_APPOINTMENTTYPE_WALKINS,
} from '../../services/Constants';
import { ParseBool } from '../../utils/Common';
import { DisplayDayType } from '../../utils/EnumMappings';
import NoteText from '../../controls/NoteText';
import { EmptyRow } from '../../controls/EmptyRow';
import CheckBoxGroupField, { CheckBoxOption } from '../../components/fields/CheckBoxGroupField';
import PageMandatoryLabelText from '../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../components/fields/PageFieldsTitle';
import LabelField from '../../components/fields/LabelField';
import OpeningHoursDay from './Components/OpeningHoursDay';
import { EditPageProps } from '../PageInterfaces';
import styles from '../Pages.module.scss';

const { controlMaxWidth, checkBoxGroupLegend, walkinsNote } = styles;

const OutletOpeningHoursPage = (props: EditPageProps) => {
    const { inquiry, setInquiryObj, register, errors, control, setValue, watch, trigger } = props;
    const [standardOperatingHours, setStandardOperatingHours] = useState(false);
    const [showWalkinsNote, setShowWalkinsNote] = useState(false);
    //    const [openingHoursDays, setOpeningHoursDays] = useState<any[]>();

    // need to watch ALL opening hours grid elements as one button can update the lot 9-5pm standard business hours
    const openingHoursWatched: IOpeningHours[] = watch(
        'inquiry.application.outletPractitioner.openingHours',
        inquiry.application.outletPractitioner!.openingHours,
    );

    const checkBoxOptionsInHoursByAppointment: CheckBoxOption[] = [
        { label: 'During listed operating hours, by appointment', value: false, disabled: false, toggleCheckIndex: -1 },
    ];

    const checkBoxOptionsInHoursWalkIn: CheckBoxOption[] = [
        { label: 'During listed operating hours - walk-ins accepted', value: false, disabled: false, toggleCheckIndex: -1 },
    ];

    const checkBoxOptionsOutsideHours: CheckBoxOption[] = [
        { label: 'Outside listed operating hours - as required', value: false, disabled: false, toggleCheckIndex: -1 },
    ];

    const checkBoxStandardOperatingHours: CheckBoxOption[] = [
        { label: 'Monday to Friday, standard business hours (9am until 5pm)', value: false, disabled: false, toggleCheckIndex: -1 },
    ];

    const onChangeInHoursByAppointment = (event: { target: { checked: boolean; id: any } }) => {
        // manage the value manually as ref did not work for the react hook form
        const isChecked: boolean = event?.target?.checked;
        setValue('inquiry.application.outletPractitioner.inHoursByAppointment', isChecked, { shouldDirty: true, shouldValidate: true });
    };

    const onChangeInHoursWalkIn = (event: { target: { checked: boolean; id: any } }) => {
        // manage the value manually as ref did not work for the react hook form
        const isChecked: boolean = event?.target?.checked;
        setValue('inquiry.application.outletPractitioner.inHoursWalkIn', isChecked, { shouldDirty: true, shouldValidate: true });
        if (isChecked === true && showWalkinsNote === false) {
            setShowWalkinsNote(true);
        }
        if (isChecked === false && showWalkinsNote === true) {
            setShowWalkinsNote(false);
        }
        trigger(); // re-validate required
    };

    const onChangeOutsideHours = (event: { target: { checked: boolean; id: any } }) => {
        // manage the value manually as ref did not work for the react hook form
        const isChecked: boolean = event?.target?.checked;
        setValue('inquiry.application.outletPractitioner.outsideHours', isChecked, { shouldDirty: true, shouldValidate: true });

        // set this so other panels get the update
        inquiry.application.outletPractitioner!.outsideHours = isChecked;
        setInquiryObj(inquiry);

        trigger(); // re-validate
    };

    const onChangeStandardOperatingHours = (event: { target: { checked: boolean; id: any } }) => {
        // manage the value manually as ref did not work for the react hook form
        const isChecked: boolean = event?.target?.checked;
        setValue('inquiry.application.outletPractitioner.standardHours', isChecked, { shouldDirty: true, shouldValidate: false }); // can revalidate later
        setStandardOperatingHours(isChecked);
        trigger(); // re-validate
    };

    const walkInsNote = () => {
        return (
            <Row className={walkinsNote}>
                <Col md='11'>
                    <NoteText displayText={NOTE_OUTLET_APPOINTMENTTYPE_WALKINS} />
                </Col>
                <Col md='1' className='justify-content-end' />
            </Row>
        );
    };

    const appointmentTypesDisplay = () => {
        const handleChangeAppointment = (e: any) => {
            onChangeInHoursByAppointment(e);
        };
        const handleChangeHoursWalkIn = (e: any) => {
            onChangeInHoursWalkIn(e);
        };
        const handleChangeOutsideHours = (e: any) => {
            onChangeOutsideHours(e);
        };

        return (
            <Row className={controlMaxWidth}>
                <Col className={checkBoxGroupLegend}>
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.inHoursByAppointment'
                        displayName='Appointment Types'
                        fieldLabel=''
                        guidanceText={GUIDANCETEXT_OUTLET_APPOINTMENTTYPES}
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.inHoursByAppointment
                                ? inquiry.application.outletPractitioner.inHoursByAppointment
                                : undefined
                        } // only set default if value exists
                        isMandatory={true}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsInHoursByAppointment}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.inHoursByAppointment}
                        onChange={handleChangeAppointment}
                    />
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.inHoursWalkIn'
                        displayName=''
                        fieldLabel=''
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.inHoursWalkIn
                                ? inquiry.application.outletPractitioner.inHoursWalkIn
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsInHoursWalkIn}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.inHoursWalkIn}
                        onChange={handleChangeHoursWalkIn}
                    />
                    <CheckBoxGroupField
                        id='inquiry.application.outletPractitioner.outsideHours'
                        displayName=''
                        fieldLabel=''
                        defaultValue={
                            inquiry.application.outletPractitioner && inquiry.application.outletPractitioner.outsideHours
                                ? inquiry.application.outletPractitioner.outsideHours
                                : undefined
                        } // only set default if value exists
                        isMandatory={false}
                        hideFinalEmptyRow={true}
                        options={checkBoxOptionsOutsideHours}
                        control={control}
                        register={register}
                        errorsField={errors.inquiry?.application?.outletPractitioner?.outsideHours}
                        onChange={handleChangeOutsideHours}
                    />
                    {showWalkinsNote && walkInsNote()}
                </Col>
            </Row>
        );
    };

    const getOpeningHoursDay = (defaultValueIsOpen: boolean, item: IOpeningHours, index: number) => {
        // const defaultValueIsOpen = ParseBool(openingHoursWatched[index]?.isOpen);
        const defaultValueOpenTime = openingHoursWatched[index]?.openTime;
        const defaultValueCloseTime = openingHoursWatched[index]?.closeTime;

        return (
            <OpeningHoursDay
                key={index}
                displayText={DisplayDayType(item.day)}
                setValue={setValue}
                itemStubName='inquiry.application.outletPractitioner.openingHours'
                index={index}
                defaultValueIsOpen={defaultValueIsOpen}
                defaultValueOpenTime={defaultValueOpenTime}
                defaultValueCloseTime={defaultValueCloseTime}
                control={control}
                errors={errors}
                register={register}
                trigger={trigger}
                clearTimeForIndex={clearTimeForIndex}
            />
        );
    };

    const displayOpeningHours = () => {
        const handleChangeStandardHours = (e: any) => {
            onChangeStandardOperatingHours(e);
        };

        return (
            <>
                <Row className={controlMaxWidth}>
                    <Col className={checkBoxGroupLegend}>
                        <LabelField
                            id='openingHoursInfolabel'
                            displayName='Operating hours'
                            value=''
                            isMandatory={true}
                            hideFinalEmptyRow={true}
                            guidanceText={GUIDANCETEXT_OUTLET_OPERATINGHOURS}
                        />
                        <CheckBoxGroupField
                            id='inquiry.application.outletPractitioner.standardOperatingHours'
                            displayName=''
                            fieldLabel=''
                            defaultValue={standardOperatingHours} // only set default if value exists
                            isMandatory={false}
                            hideFinalEmptyRow={true}
                            options={checkBoxStandardOperatingHours}
                            control={control}
                            register={register}
                            errorsField={errors.inquiry?.application?.outletPractitioner?.standardOperatingHours}
                            onChange={handleChangeStandardHours}
                        />
                    </Col>
                </Row>
                <EmptyRow />
                <Row>
                    <Col>
                        {inquiry.application.outletPractitioner!.openingHours.map((item, index) => {
                            return getOpeningHoursDay(ParseBool(item.isOpen), item, index);
                        })}
                    </Col>
                </Row>
            </>
        );
    };

    /* const setCheckBoxValue = (idObjectModel: string, idHtmlDom: string) => {
        setValue(idObjectModel, true, { shouldDirty: true, shouldValidate: false });
        // find by Id in Html DOM i.e. = inquiry.application.outletPractitioner.openingHours[0].isOpen-0
        const checkBox = window.document.getElementById(idHtmlDom);
        checkBox?.setAttribute('checked', '');
    }; */

    const setDropDownTimeValue = (idObjectModel: string, idHtmlDom: string, timeValue: Date | null, timeString: string) => {
        setValue(idObjectModel, timeValue, { shouldDirty: true, shouldValidate: false });
        // find by Id in Html DOM i.e. = inquiry.application.outletPractitioner.openingHours[0].isOpen-0
        const dropdownTime = window.document.getElementById(idHtmlDom);
        dropdownTime?.setAttribute('value', timeString);
    };

    // need to call this from the child control so we can set everything properly then feed null back down
    const clearTimeForIndex = (idObjectModel: string) => {
        setDropDownTimeValue(idObjectModel, idObjectModel, null, '');
    };

    /*
    // set initial value of opening hours check box collection then update it as changes are made (or make changes as Mon-Fri clicked)
    useEffect(() => {
        if (openingHoursWatched && openingHoursWatched.length > 0 && openingHoursDays === undefined) {
            // tslint:disable-next-line: prefer-const
            let existingOpeningHours: any[] = [];

            inquiry.application.outletPractitioner!.openingHours.forEach((item, index) => {
                const defaultValueIsOpen: boolean = ParseBool(openingHoursWatched[index]?.isOpen);
                const dayElement = getOpeningHoursDay(defaultValueIsOpen, item, index);

                existingOpeningHours.push(dayElement);
            });

            setOpeningHoursDays(existingOpeningHours);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openingHoursWatched]); */

    useEffect(() => {
        if (standardOperatingHours === true) {
            // collection is always sorted in order Mon=0, Fri=4
            for (let idx = 0; idx < 5; idx++) {
                // set isOpen
                // if (openingHoursDays && openingHoursDays.length >= idx) {
                //    openingHoursDays[idx] = getOpeningHoursDay(true, openingHoursWatched[idx], idx);
                // }
                setValue(`inquiry.application.outletPractitioner.openingHours[${idx}].isOpen`, true);
                inquiry.application.outletPractitioner!.openingHours[idx].isOpen = true;

                // let idObjectModel = `inquiry.application.outletPractitioner.openingHours[${idx}].isOpen`;
                // const idHtmlDom = idObjectModel + '-0';
                // setCheckBoxValue(idObjectModel, idHtmlDom);

                // set open time
                const currentDate = new Date();
                const dateOpen = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 9, 0, 0);
                let idObjectModel = `inquiry.application.outletPractitioner.openingHours[${idx}].openTime`;
                setDropDownTimeValue(idObjectModel, idObjectModel, dateOpen, '9:00 AM');
                inquiry.application.outletPractitioner!.openingHours[idx].openTime = dateOpen;

                // set close time
                const dateClose = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 17, 0, 0);
                idObjectModel = `inquiry.application.outletPractitioner.openingHours[${idx}].closeTime`;
                setDropDownTimeValue(idObjectModel, idObjectModel, dateClose, '5:00 PM');
                inquiry.application.outletPractitioner!.openingHours[idx].closeTime = dateClose;
            }

            setInquiryObj(inquiry);

            // setOpeningHoursDays(openingHoursDays);

            trigger(); // re-validate
            //  setStandardOperatingHours(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [standardOperatingHours]);

    return (
        <>
            <EmptyRow />
            <PageFieldsTitle title='Opening Hours' />
            <PageMandatoryLabelText />
            <LabelField id='openingHoursHeaderlabel' displayName='' guidanceText={HEADER_EDIT_OUTLET_OPENINGHOURS} value='' isMandatory={false} />
            {inquiry.application.outletPractitioner!.openingHours && displayOpeningHours()}
            <EmptyRow />
            {appointmentTypesDisplay()}
            <EmptyRow />
        </>
    );
};

export default OutletOpeningHoursPage;
