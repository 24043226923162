import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { DisplayAttachmentDocumentType } from '../../../utils/EnumMappings';
import { trashIcon } from '../../../utils/Icons';
import { IAttachmentMetadata } from '../../../api/interfacesApi';
import styles from '../Fields.module.scss';
import { EmptyRow } from 'controls/EmptyRow';
import { formatDateTimeForDisplay } from 'utils/Dates';

const { fileInfoHeaderRow, fileInfoDataRow, listOfFilesItem, btnDeleteFile, fileInfoButtonColumn, fileInfoTypeColumn, fileInfoNameColumn } = styles;

export interface FileListDisplayProps {
    fileMetadataList: IAttachmentMetadata[];
    deleteFile?: any;
    formatForPDF?: boolean;
    showDateOnFileList?: boolean;
}

const FileListDisplay = (props: FileListDisplayProps) => {
    const { fileMetadataList, deleteFile, formatForPDF, showDateOnFileList } = props;

    const showHeaderRow = fileMetadataList && fileMetadataList.length > 0;
    const showDeleteButton = deleteFile !== undefined;

    const handleDeleteFileClick = (file: IAttachmentMetadata) => (event: any) => {
        if (window.confirm('Are you sure you wish to delete this file?')) deleteFile(file.attachmentId);
    };

    const displaySortedFilesList = () => {
        if (fileMetadataList === undefined || fileMetadataList === null || fileMetadataList.length === 0) {
            return null;
        }

        // sort newest file to oldest
        fileMetadataList.sort((a, b) => {
            const dateCreatedA = a.createdDate ?? Date.now(); // will not be empty, fail-safe
            const dateCreatedB = b.createdDate ?? Date.now(); // will not be empty, fail-safe

            if (dateCreatedA > dateCreatedB) {
                return -1;
            }
            if (dateCreatedA < dateCreatedB) {
                return 1;
            }
            // dates equal
            return 0;
        });

        return fileMetadataList.map((file, index) => (
            <tr key={index}>
                {showDateOnFileList === true && <td>{formatDateTimeForDisplay(file.createdDate)}</td>}
                <td>{DisplayAttachmentDocumentType(file.documentType)}</td>
                <td>{file.fileName}</td>
            </tr>
        ));
    };

    if (formatForPDF === true) {
        return (
            <>
                <div>
                    <div style={{ marginBottom: '0.75rem' }}>
                        <u>List of Files/Evidence:</u>
                    </div>
                    <table data-testid='displayedFilesList' className='list-group list-group-flush' style={{ width: '100vw' }}>
                        {showHeaderRow && (
                            <tr>
                                {showDateOnFileList === true && <th style={{ textAlign: 'left', paddingRight: '0.25rem' }}>Date Submitted</th>}
                                <th style={{ textAlign: 'left', marginRight: '0.5rem', paddingRight: '0.25rem' }}>Type</th>
                                <th style={{ textAlign: 'left' }}>Name</th>
                            </tr>
                        )}
                        {displaySortedFilesList()}
                        {!showHeaderRow && (
                            <tr>
                                {showDateOnFileList === true && <td>&nbsp;</td>}
                                <td>No files have been selected</td>
                                <td>&nbsp;</td>
                            </tr>
                        )}
                    </table>
                </div>
                <EmptyRow />
            </>
        );
    }

    return (
        <div className='card'>
            <div className='card-header'>List of Files/Evidence:</div>
            <ul data-testid='displayedFilesList' className='list-group list-group-flush'>
                {showHeaderRow && (
                    <Row className={fileInfoHeaderRow}>
                        {showDeleteButton && <Col className={fileInfoButtonColumn}>Action</Col>}
                        <Col className={fileInfoTypeColumn}>Type</Col>
                        <Col className={fileInfoNameColumn}>Name</Col>
                    </Row>
                )}
                {fileMetadataList &&
                    fileMetadataList.map((file, index) => (
                        <li className={`list-group-item ${listOfFilesItem}`} key={index}>
                            <Row className={fileInfoDataRow}>
                                {showDeleteButton && (
                                    <Col className={fileInfoButtonColumn}>
                                        <Button
                                            data-testid={`displayedFilesListDeleteButton_${index}_${file.documentType}`}
                                            variant='outline-secondary'
                                            className={`btn ${btnDeleteFile}`}
                                            onClick={handleDeleteFileClick(file)}
                                        >
                                            {trashIcon()} Delete File
                                        </Button>
                                    </Col>
                                )}
                                <Col className={fileInfoTypeColumn}>{DisplayAttachmentDocumentType(file.documentType)}</Col>
                                <Col className={fileInfoNameColumn}>{file.fileName}</Col>
                            </Row>
                        </li>
                    ))}
                {!showHeaderRow && (
                    <li className={`list-group-item ${listOfFilesItem}`} key='noFiles'>
                        No files have been selected
                    </li>
                )}
            </ul>
        </div>
    );
};

export default FileListDisplay;
