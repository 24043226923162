import 'whatwg-fetch';
import ApiService from './commonApi';
import { IDifferences, IUser } from './interfacesApi';
import AuthService from '../services/AuthService';

class UserApi {
    apiService: ApiService;

    constructor(authenticationService: AuthService) {
        this.apiService = new ApiService(authenticationService);
    }

    async getUser() {
        const request = this.apiService.setRequest('api/users', 'GET', null);
        return this.apiService.makeFetchRequest<IUser>(request);
    }

    async getUserEditCopy() {
        const request = this.apiService.setRequest('api/users/geteditcopy', 'GET', null);
        return this.apiService.makeFetchRequest<IUser>(request);
    }

    // API call - delete one Attachment for this user
    async deleteAllUserData() {
        return this.apiService.makeStandardDeleteRequest(`api/users`);
    }

    SaveUser(user: IUser) {
        // let httpMethod: string;
        let request: Request;

        if (user.id < 1) {
            request = this.apiService.setRequest('api/users/add', 'POST', user);
        } else {
            // request = this.apiService.setRequest('api/users/update/' + area + '/' + user.id.toString(), 'PUT', user);
            request = this.apiService.setRequest('api/users', 'PUT', user);
        }
        return this.apiService.makeFetchRequest<IUser>(request);
    }

    SaveUserGenderConsentOnly(user: IUser) {
        let request: Request;
        request = this.apiService.setRequest('api/users/updateGenderConsent', 'PUT', user);
        return this.apiService.makeFetchRequest<IUser>(request);
    }

    /* updateRoles(data: any) {
        return this.apiService.makeStandardPutRequest('/api/Users/updateRoles', data);
    } */

    async claimProfile(user: IUser) {
        return this.apiService.makeStandardPutRequest<IUser>('/api/Users/claim', user);
    }

    async startNewApplication(user: IUser) {
        return this.apiService.makeStandardPutRequest<IUser>('/api/Users/restart', user);
    }

    async getChanges() {
        const request = this.apiService.setRequest('api/users/changes', 'GET', null);
        return this.apiService.makeFetchRequest<IDifferences<IUser>>(request);
    }

    discardChanges(user: IUser) {
        const request = this.apiService.setRequest('api/Users/discardChanges', 'POST', user);
        return this.apiService.makeFetchRequest<IUser>(request);
    }

    async makeFetchRequest<T>(request: RequestInfo): Promise<T> {
        const response = await fetch(request, { credentials: 'include' });
        if (response.ok) {
            const user = (await response.json()) as T;
            return user;
        } else {
            const err = await response.json();
            throw err;
        }
    }
}

export default UserApi;
