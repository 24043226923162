import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import {
    NOTE_PROHIBITED_CHILDREN_CHECK_MESSAGE,
    NOTE_PROHIBITED_CHILDREN_CHECK_MESSAGE_P2,
    NOTE_PROFINDEMNITYINSURANCE_WARNING_LEGALAIDPANELLISTS,
    DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW,
    DASHBOARD_NOTIFICATION_EDITOTHERDETAILS,
    NOTE_MANAGEDETAILS_REWORK,
    DASHBOARD_NOTIFICATION_SYSTEM_MAINTENANCE_INDIVIDUAL,
} from '../../services/Constants';
import { AccreditationStatusEnum, ApplicationStatusEnum } from '../../services/Enums';
import {
    AccreditedApplicationIsActionRequired,
    AccreditedApplicationIsAwaitingReview,
    DisplayReviewAndSubmitWarningLink,
    DisplayExpiryWarningNote,
    FormatApplicationNumber,
    FormatRegistrationNumber,
    HasOutletPractitionerPendingReview,
    IsReworkFilesSupplied,
    NumberOfActiveConditions,
} from '../../utils/AppUtils';
import { IsAnyComplaintLegalAidOtherOnPanel, IsAnyComplaintsInsuranceViaMembershipFalse } from '../../utils/ComplaintsUtils';
import {
    DisplayUserFriendlyApplicationStatus,
    DisplayUserFriendlyAccreditationStatus,
    DisplayUserFriendlyAccreditationConditions,
} from '../../utils/EnumMappings';
import NoteText from '../../controls/NoteText';
import { EmptyRow } from '../../controls/EmptyRow';
import { IApplication, IUser } from '../../api/interfacesApi';
import styles from './Status.module.scss';

const { cardDeckContainer, statusRow, statusRowNote, emailLink, statusConditionsText } = styles;

interface StatusProps {
    application: IApplication;
    user: IUser;
}

function Status(props: StatusProps) {
    const { application, user } = props;

    const editOtherDetailsNoteBackgroundColor = '#174a5b';
    const noteBackgroundColor = '#f7de92'; // brighter yellow='#ffc107'; // '#ffffcc'; // '#12546A';
    const noteColor = '#18566d'; // 'cadetblue';

    const isPractitionerAccredited = application?.accreditationStatus === AccreditationStatusEnum.Accredited;
    const isPractitionerAccreditedWithConditions = application?.accreditationStatus === AccreditationStatusEnum.AccreditedWithConditions;
    const isPractitionerConditionallyAccredited = application?.accreditationStatus === AccreditationStatusEnum.ConditionalAccreditation;
    const applicationStatusLabelText =
        application && (isPractitionerAccredited || isPractitionerAccreditedWithConditions || isPractitionerConditionallyAccredited)
            ? 'Details status'
            : 'Application status';
    const showProhibitedWarning = application && application.isProhibitedFromWorkingWithChildren;
    const showLegalAidPanellistWarning =
        application &&
        IsAnyComplaintsInsuranceViaMembershipFalse(application.complaintsMechanism) === true &&
        IsAnyComplaintLegalAidOtherOnPanel(application.complaintsMechanism) === true;

    const showApplicationStatus =
        application &&
        application.applicationStatus !== ApplicationStatusEnum.Assessed &&
        application.applicationStatus !== ApplicationStatusEnum.PendingCancellation &&
        application.applicationStatus !== ApplicationStatusEnum.PendingSuspension &&
        application.accreditationStatus !== AccreditationStatusEnum.Accredited &&
        application.accreditationStatus !== AccreditationStatusEnum.Suspended &&
        application.accreditationStatus !== AccreditationStatusEnum.Cancelled;
    //  show the Rego number if exists (as opposed to check accred status as that changes to pending when editing)
    const showRegistrationNumber =
        application &&
        application.registrationNumber > 0 &&
        application.accreditationStatus !== AccreditationStatusEnum.Suspended &&
        application.accreditationStatus !== AccreditationStatusEnum.Cancelled; // && application.accreditationStatus === AccreditationStatusEnum.Accredited;
    const showApplicationNumber =
        showRegistrationNumber === false &&
        application &&
        application.zNumber > 0 &&
        application.accreditationStatus !== AccreditationStatusEnum.ConditionalAccreditation &&
        application.accreditationStatus !== AccreditationStatusEnum.Suspended &&
        application.accreditationStatus !== AccreditationStatusEnum.Cancelled;

    const showConditions = application.accreditationConditions && NumberOfActiveConditions(application.accreditationConditions) > 0;
    const showUpdateOtherDetailsNote =
        (application && application.accreditationStatus === AccreditationStatusEnum.Accredited) ||
        application.accreditationStatus === AccreditationStatusEnum.ConditionalAccreditation;
    const showChangesUnderReview = HasOutletPractitionerPendingReview(application) || AccreditedApplicationIsAwaitingReview(application);
    const showManageDetailsReworkNote = AccreditedApplicationIsActionRequired(application) && !IsReworkFilesSupplied(application);
    const showIndividualMaintenanceNote = application.applicationStatus === ApplicationStatusEnum.UnderMaintenance;

    return (
        <>
            <div className='lead w-75' id='jumbotron-status-container' data-testid='jumbotron-status-container'>
                <Container className={`${cardDeckContainer}`}>
                    {DisplayReviewAndSubmitWarningLink(application, user, statusRowNote, noteColor, noteBackgroundColor, true)}
                    {showIndividualMaintenanceNote === true && (
                        <>
                            <Row className={`${statusRowNote}`} id='warning-note-individualmaintenance' data-testid='warning-note-individualmaintenance'>
                                <Col>
                                    <NoteText
                                        displayText={DASHBOARD_NOTIFICATION_SYSTEM_MAINTENANCE_INDIVIDUAL}
                                        backgroundColor={noteBackgroundColor}
                                        color={noteColor}
                                        fontBold={true}
                                        // buttonLink={'/managedetailsreviewandsubmitpage'}
                                    />
                                </Col>
                            </Row>
                            <EmptyRow />
                        </>
                    )}
                    {showManageDetailsReworkNote === true && (
                        <>
                            <Row className={`${statusRowNote}`} id='warning-note-managedetailsrework' data-testid='warning-note-managedetailsrework'>
                                <Col>
                                    <NoteText
                                        displayText={NOTE_MANAGEDETAILS_REWORK}
                                        backgroundColor={noteBackgroundColor}
                                        color={noteColor}
                                        fontBold={true}
                                        // buttonLink={'/managedetailsreviewandsubmitpage'}
                                    />
                                </Col>
                            </Row>
                            <EmptyRow />
                        </>
                    )}
                    {showApplicationStatus === true && (
                        <Row className={`${statusRow}`}>
                            <Col md={3} id='jumbotron-application-status-heading' data-testid='jumbotron-application-status-heading'>
                                {applicationStatusLabelText}:
                            </Col>
                            <Col>
                                <span className='badge badge-light' id='jumbotron-application-status-value' data-testid='jumbotron-application-status-value'>
                                    {DisplayUserFriendlyApplicationStatus(application.applicationStatus)}
                                </span>
                            </Col>
                        </Row>
                    )}
                    {showRegistrationNumber === true && (
                        <Row className={`${statusRow}`}>
                            <Col md={3} id='jumbotron-registration-number-heading' data-testid='jumbotron-registration-number-heading'>
                                Registration number:
                            </Col>
                            <Col>
                                <span className='badge badge-light' id='jumbotron-registration-number-value' data-testid='jumbotron-registration-number-value'>
                                    {FormatRegistrationNumber(application.registrationNumber)}
                                </span>
                            </Col>
                        </Row>
                    )}
                    {showApplicationNumber === true && (
                        <Row className={`${statusRow}`}>
                            <Col md={3} id='jumbotron-application-number-heading' data-testid='jumbotron-application-number-heading'>
                                Application number:
                            </Col>
                            <Col>
                                <span className='badge badge-light' id='jumbotron-application-number-value' data-testid='jumbotron-application-number-value'>
                                    {FormatApplicationNumber(application.zNumber)}
                                </span>
                            </Col>
                        </Row>
                    )}
                    <Row className={`${statusRow}`}>
                        <Col md={3} id='jumbotron-accreditation-status-heading' data-testid='jumbotron-accreditation-status-heading'>
                            Accreditation status:
                        </Col>
                        <Col md={9}>
                            <span className='badge badge-light' id='jumbotron-accreditation-status-value' data-testid='jumbotron-accreditation-status-value'>
                                {application.applicationStatus === ApplicationStatusEnum.PendingSuspension ||
                                application.applicationStatus === ApplicationStatusEnum.PendingCancellation
                                    ? DisplayUserFriendlyAccreditationStatus(application.accreditationStatus) +
                                      ' (' +
                                      DisplayUserFriendlyApplicationStatus(application.applicationStatus) +
                                      ')'
                                    : DisplayUserFriendlyAccreditationStatus(application.accreditationStatus)}
                            </span>
                        </Col>
                    </Row>
                    {showConditions === true && (
                        <Row className={`${statusRow}`}>
                            <Col md={3} id='jumbotron-accreditation-status-heading' data-testid='jumbotron-accreditation-status-heading'>
                                Conditions:
                            </Col>
                            <Col md={9}>
                                <span
                                    className={`${statusConditionsText} badge badge-light`}
                                    id='jumbotron-accreditation-status-value'
                                    data-testid='jumbotron-accreditation-status-value'
                                >
                                    {DisplayUserFriendlyAccreditationConditions(application.accreditationConditions, false)}
                                </span>
                            </Col>
                        </Row>
                    )}
                </Container>
                {showProhibitedWarning && showProhibitedWarning === true && (
                    <Row className={`${statusRowNote}`} id='jumbotron-note-childcheckprohibited' data-testid='jumbotron-note-childcheckprohibited'>
                        <Col>
                            <NoteText
                                displayText={NOTE_PROHIBITED_CHILDREN_CHECK_MESSAGE}
                                displayTextP2={NOTE_PROHIBITED_CHILDREN_CHECK_MESSAGE_P2}
                                backgroundColor={noteBackgroundColor}
                                color={noteColor}
                                fontBold={true}
                            />
                        </Col>
                    </Row>
                )}
                {showLegalAidPanellistWarning && (
                    <Row className={`${statusRowNote}`} id='jumbotron-note-legalaidpanelists' data-testid='jumbotron-note-legalaidpanelists'>
                        <Col>
                            <NoteText
                                displayText={NOTE_PROFINDEMNITYINSURANCE_WARNING_LEGALAIDPANELLISTS}
                                backgroundColor={noteBackgroundColor}
                                color={noteColor}
                                fontBold={true}
                            />
                        </Col>
                    </Row>
                )}
                {showChangesUnderReview && (
                    <Row className={`${statusRowNote}`} id='jumbotron-note-changesunderreview' data-testid='jumbotron-note-changesunderreview'>
                        <Col>
                            <NoteText
                                displayText={DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW}
                                backgroundColor={noteBackgroundColor}
                                color={noteColor}
                                fontBold={true}
                            />
                        </Col>
                    </Row>
                )}
                {showUpdateOtherDetailsNote && (
                    <>
                        <EmptyRow />
                        <Row className={`${statusRowNote}`} id='jumbotron-note-editotherdetails' data-testid='jumbotron-note-editotherdetails'>
                            <Col className={`${emailLink}`}>
                                <NoteText
                                    textBoxThinPadding={true}
                                    displayText={DASHBOARD_NOTIFICATION_EDITOTHERDETAILS}
                                    backgroundColor={editOtherDetailsNoteBackgroundColor}
                                />
                            </Col>
                        </Row>
                    </>
                )}
                {DisplayExpiryWarningNote(application, statusRowNote, noteColor, noteBackgroundColor, true)}
            </div>
        </>
    );
}

export default Status;
