import {
    AccountInfo,
    Configuration,
    AuthenticationResult,
    PublicClientApplication,
    SilentRequest,
    RedirectRequest,
    EndSessionRequest,
} from '@azure/msal-browser';
// tslint:disable-next-line: no-implicit-dependencies
import { msalConfig, loginRequest } from 'authConfig';

class AuthService {
    constructor() {
        this.msalCfg = msalConfig;
        this.msalApplication = new PublicClientApplication(this.msalCfg);
    }

    // msal application object
    msalApplication: PublicClientApplication;
    msalCfg: Configuration;

    // cached account info
    account?: AccountInfo | null;

    HandlePageLoadEvent(): Promise<void> {
        // let exceptions bubble up to the caller to handle
        return this.msalApplication.handleRedirectPromise().then((authResult: AuthenticationResult | null) => {
            this.HandleRedirectResponse(authResult);
        });
    }

    HandleRedirectResponse(authResult: AuthenticationResult | null): void {
        // if this page load is redirect from the Microsoft Identity platform then the
        // authResult will be populated. Otherwise null on other page loads.

        if (authResult !== null) {
            // save the fresh account info from the result.
            this.account = authResult.account;
        } else {
            // see if we have cached accounts.
            const currentAccounts = this.msalApplication.getAllAccounts();

            if (currentAccounts === null) {
                // no cached accounts.
                // user will need to click the sign-in button and redirect to login.
                return;
            } else if (currentAccounts.length > 1) {
                // there are some situations where the user may have multiple (different) cached logins.
                // this code sample does not cover that scenario but just logs a warning here.
                // this conditional block would need to be updated to support multiple accounts.
                // otherwise it will just grab the first one below.
                // tslint:disable-next-line: no-console
                console.warn('Multiple accounts detected in MSAL account cache.');
                this.account = currentAccounts[0];
            } else if (currentAccounts.length === 1) {
                // we have exactly 1 cached account.
                // set the account info. user may not need to sign in.
                this.account = currentAccounts[0];
            }
        }
    }

    GetToken(): Promise<AuthenticationResult> {
        const tokenRequest: SilentRequest = {
            account: this.account as AccountInfo,
            scopes: loginRequest.scopes,
            authority: this.msalCfg.auth.authority,
            redirectUri: this.msalCfg.auth.redirectUri,
            resourceRequestUri: this.msalCfg.auth.redirectUri,
        };

        // msal will return the cached token if present, or call to get a new one
        // if it is expired or near expiring.
        return this.msalApplication.acquireTokenSilent(tokenRequest);
    }

    SignIn() {
        const loginRedirectRequestPayload: RedirectRequest = {
            scopes: loginRequest.scopes,
            prompt: 'select_account',
            redirectUri: this.msalCfg.auth.redirectUri,
        };

        // this will redirect the web application to the Microsoft Identity platform sign in pages.
        // no code will execute after this point.
        this.msalApplication.loginRedirect(loginRedirectRequestPayload);
    }

    SignOut() {
        if (!this.account) {
            // no cached login to signout
            return;
        }

        const accountInfo: AccountInfo | null = this.msalApplication.getAccountByUsername(this.account?.username as string);

        if (accountInfo !== null) {
            const logoutRequestPayload: EndSessionRequest = {
                account: accountInfo,
            };

            this.msalApplication.logoutRedirect(logoutRequestPayload);
        }
    }
}

export default AuthService;
