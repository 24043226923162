import 'whatwg-fetch';
import ApiService from './commonApi';
import { IApplication, IApplicationSubmit, IComplaintMechanism, IDifferences } from './interfacesApi';
import AuthService from '../services/AuthService';

class ApplicationApi {
    apiService: ApiService;

    constructor(authenticationService: AuthService) {
        this.apiService = new ApiService(authenticationService);
    }

    async getApplication() {
        const request = this.apiService.setRequest('api/application', 'GET', null);
        return this.apiService.makeFetchRequest<IApplication>(request);
    }

    async getAllApplications() {
        const request = this.apiService.setRequest('api/application/allApps', 'GET', null);
        return this.apiService.makeFetchRequest<IApplication[]>(request);
    }

    // API call - Create Application record
    CreateApplication(formData: any) {
        return this.apiService.makeStandardPostRequest<IApplication>('api/application', formData);
    }

    UpdateApplication(data: IApplication) {
        return this.apiService.makeStandardPutRequest<IApplication>('api/application', data);
    }

    WithdrawApplication(data: IApplication) {
        return this.apiService.makeStandardPutRequest<IApplication>('api/application/withdrawrequest', data);
    }

    SuspendApplication(data: IApplication) {
        return this.apiService.makeStandardPutRequest<IApplication>('api/application/suspendrequest', data);
    }

    CancelApplication(data: IApplication) {
        return this.apiService.makeStandardPutRequest<IApplication>('api/application/cancelrequest', data);
    }

    // uses configurable timeout PUT request due to long-running CM9 operations
    SubmitApplication(data: IApplicationSubmit) {
        return this.apiService.makeConfigurableTimeoutPutRequest('api/application/submit', data);
    }

    SubmitChanges(data: IApplicationSubmit) {
        return this.apiService.makeStandardPutRequest<IApplicationSubmit>('api/application/submitChanges', data);
    }

    // API call - removeOutletThenPublish data for this user
    RemoveOutletThenPublish(applicationId: string) {
        return this.apiService.makeStandardDeleteRequestWithType<IApplication>(`api/application/removeOutletThenPublish/${applicationId}`);
    }

    // API call - delete Application for this user
    DiscardApplication() {
        return this.apiService.makeStandardDeleteRequest(`api/application`);
    }

    // API call - delete complaints data for this user
    DeleteComplaintsData() {
        return this.apiService.makeStandardDeleteRequest(`api/application/complaints`);
    }

    RemoveComplaintsMechanism(complaintMechId: string) {
        return this.apiService.makeStandardDeleteRequestWithType<IComplaintMechanism>(`api/application/complaint/${complaintMechId}`);
    }

    async GetComplaintsMechanism(complaintMechId: string) {
        const request = this.apiService.setRequest(`api/application/complaints/${complaintMechId}`, 'GET', null);
        return this.apiService.makeFetchRequest<IComplaintMechanism>(request);
    }

    async GetComplaintsMechanismChanges(complaintMechId: string) {
        const request = this.apiService.setRequest(`api/application/complaints/${complaintMechId}/changes`, 'GET', null);
        return this.apiService.makeFetchRequest<IDifferences<IComplaintMechanism>>(request);
    }

    async GetAllComplaintsMechanismChanges(data: string[]) {
        return this.apiService.makeStandardPostRequest<IDifferences<IComplaintMechanism>[]>('api/application/complaints/allchanges', data);
    }

    AddComplaintsMechanism(data: IComplaintMechanism) {
        return this.apiService.makeStandardPostRequest<IComplaintMechanism>('api/application/complaint', data);
    }

    UpdateComplaintsMechanism(data: IComplaintMechanism) {
        return this.apiService.makeStandardPutRequest<IComplaintMechanism>('api/application/complaint', data);
    }

    DiscardComplaintsMechanism(data: IComplaintMechanism) {
        return this.apiService.makeStandardPostRequest<IComplaintMechanism>('api/application/discardComplaintChanges', data);
    }
}

export default ApplicationApi;
