import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { IsEnterOrSpaceKeyPressed, ParseBool } from '../utils/Common';
import { CheckBoxOption } from '../components/fields/CheckBoxGroupField';
import stylesCheckBoxGroup from './Controls.module.scss';
import HelpText from './HelpText';

const { checkBoxLegend, checkBoxRow, checkBoxHelpHeaderRow, checkBoxInput, checkBoxLabel } = stylesCheckBoxGroup;

export interface CheckBoxGroupProps {
    id: string;
    fieldLabel: string;
    name: string;
    defaultValue: any;
    options: CheckBoxOption[];
    onChange: any;
    setAllTrue?: any;
    setAllFalse?: any;
    refreshTrueItems?: any;
}

const CheckBoxGroup = (props: CheckBoxGroupProps) => {
    // React.forwardRef((props: CheckBoxGroupProps, ref: React.ForwardedRef<HTMLInputElement> = null) => {
    const { id, fieldLabel, name, defaultValue, options, onChange, setAllTrue, setAllFalse, refreshTrueItems } = props;

    // controlled values
    const defaultValues: boolean[] = [];
    options.forEach(item => {
        defaultValues.push(ParseBool(defaultValue !== undefined ? defaultValue : item.defaultValue)); // setup initial boolean values for checked or not
    });
    const [values, setValues] = useState(defaultValues);

    const handleOnChange = (e: any, index: number) => {
        // const value = event.target.value === null || event.target.value === '' ? false : event.target.value === 'true' ? true : event.target.value;
        const value = values.slice();
        value[index] = e.target.checked;
        setValues(value);

        onChange(e, e.target.checked);
    };

    const selectCheckBox = (idHtmlDom: string) => {
        // find by Id in Html DOM i.e. = inquiry.application.hasDeclaredAndConsented-0
        const checkBox = window.document.getElementById(idHtmlDom);
        checkBox?.click();
    };

    useEffect(() => {
        /* options.forEach(item => {
            if (item.toggleCheckIndex > -1) {
                toggleCheck(item.toggleCheckIndex);
            }
        });
        */
        if (setAllTrue !== undefined && setAllTrue !== null && setAllTrue !== 0) {
            // set all values to true
            setAllCheckBoxes(true);
        }

        // const idxToggle = options.findIndex(option => option.toggleCheckIndex > -1);
        // if (idxToggle > -1) {
        //    toggleCheck(idxToggle);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setAllTrue]);

    useEffect(() => {
        if (setAllFalse !== undefined && setAllFalse !== null && setAllFalse !== 0) {
            // set all values to true
            setAllCheckBoxes(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setAllFalse]);

    /*
    useEffect(() => {
        const idxToggle = options.findIndex(option => option.toggleCheckIndex > -1);

        if (idxToggle > -1) {
            toggleCheck(idxToggle);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);
    */
    useEffect(() => {
        if (refreshTrueItems !== undefined && refreshTrueItems !== null && refreshTrueItems !== 0) {
            options.forEach(item => {
                if (item.setToTrue === true) {
                    setCheckBox(true, item.toggleCheckIndex);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshTrueItems]);

    const setAllCheckBoxes = (isTrue: boolean) => {
        values.forEach((_value, index) => {
            values[index] = isTrue;
        });
        setValues(values);
    };

    const setCheckBox = (isTrue: boolean, index: number) => {
        values[index] = isTrue;
        setValues(values);
    };

    /*
    const toggleCheck = (index: number) => {
        const value = values.slice();
        value[index] = !value[index];
        setValues(value);
        // let value = this.state.value.slice();
        // this.setState({value})
    }; */

    return (
        <fieldset>
            {fieldLabel && (
                <legend id={id} className={checkBoxLegend}>
                    {fieldLabel}
                </legend>
            )}
            <div>
                {options.map((option: CheckBoxOption, index: number) => {
                    const indexString = `${id}-${index && index === null ? '' : index!.toString()}`;

                    const keyPressed = (e: any) => {
                        if (IsEnterOrSpaceKeyPressed(e)) {
                            e.preventDefault();
                            selectCheckBox(indexString);
                        }
                    };

                    const changeHandler = (e: any) => {
                        handleOnChange(e, index);
                    };

                    return (
                        <div key={index}>
                            <Row key={index} className={checkBoxRow}>
                                <Col>
                                    <div>
                                        <input
                                            type='checkbox'
                                            id={indexString}
                                            key={indexString}
                                            tabIndex={-1} // hidden so do not receive focus
                                            name={name}
                                            // checked={ParseBool(option?.value)}  // checked forces element to readonly and thus cannot change
                                            // defaultValue={defaultValue}
                                            className={checkBoxInput}
                                            checked={values[index]}
                                            // defaultChecked={defaultValue} // controlled uses checked  {option.value === defaultValue} // NOTE: use defaultChecked instead of checked for forms
                                            // onClick={handleOnChange}
                                            onChange={changeHandler}
                                            disabled={option.disabled}
                                            // ref={ref}
                                        />
                                        <label id={`${indexString}-label`} tabIndex={0} htmlFor={indexString} className={checkBoxLabel} onKeyPress={keyPressed}>
                                            <span>{option.label}</span>
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            {option.helpHeaderText && (
                                <Row className={checkBoxHelpHeaderRow}>
                                    <Col>
                                        <HelpText helpHeaderText={option.helpHeaderText} helpText={option.helpText} fieldName={indexString} />
                                    </Col>
                                </Row>
                            )}
                            <>{option.children}</>
                        </div>
                    );
                })}
            </div>
        </fieldset>
    );
    // });
};

export default CheckBoxGroup;
