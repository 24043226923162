import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { AccreditationStatusEnum } from '../services/Enums';
import { formatDateForDisplayAddExpiredIfInPast } from '../utils/Dates';
import { DisplayFieldValueOrDefaultText } from '../utils/AppUtils';
import {
    GetComplaintsMechanismName,
    InsuranceExpiryOutput,
    IsOrganisationDisplay,
    IsRelationshipToEmployerExists,
    ShowInsuranceExpiryIncludingEmployee,
} from '../utils/ComplaintsUtils';
import { Inquiry } from '../api/interfacesApi';
import LabelField from '../components/fields/LabelField';
import PageFieldsTitle from '../components/fields/PageFieldsTitle';
import { EmptyRow } from '../controls/EmptyRow';
import Loader from '../controls/Loader';
import { DisplayRelationshipToOrgType } from '../utils/EnumMappings';

interface ComplaintsMechanismFieldsDisplayProps {
    inquiry: Inquiry;
}

const ComplaintsMechanismFieldsDisplay = (props: ComplaintsMechanismFieldsDisplayProps) => {
    const { inquiry } = props;

    const isConditionalAccreditation = inquiry.application?.accreditationStatus === AccreditationStatusEnum.ConditionalAccreditation;
    const complaintsData = inquiry.application?.activeComplaintsMechanismObj;

    if (complaintsData === undefined || complaintsData === null) {
        return <Loader isLoading={true} loaderText='Loading, please wait...' />;
    }

    const isOrgDisplay = IsOrganisationDisplay(complaintsData);

    const name = GetComplaintsMechanismName(complaintsData);
    const showRelationshipToOrg = IsRelationshipToEmployerExists(complaintsData);
    const showInsuranceExpiryDate = ShowInsuranceExpiryIncludingEmployee(complaintsData);
    const insuranceExpiryDateOutput = InsuranceExpiryOutput(isConditionalAccreditation, complaintsData);

    return (
        <>
            <PageFieldsTitle title='Complaints mechanism details' />
            <EmptyRow />
            <Row>
                <Col>
                    <LabelField
                        id='reviewNameTitle'
                        displayName={`${isOrgDisplay === true ? 'Organisation name' : 'Professional Association name'}`}
                        value={DisplayFieldValueOrDefaultText(name)}
                        isMandatory={false}
                        textCapitalizeOn={true}
                    />
                </Col>
            </Row>
            {isOrgDisplay === false && (
                <Row>
                    <Col>
                        <LabelField
                            id='reviewMembershipEndDate'
                            displayName='Current membership end date'
                            value={formatDateForDisplayAddExpiredIfInPast(complaintsData.membershipToDate)}
                            isMandatory={false}
                        />
                    </Col>
                </Row>
            )}
            {showRelationshipToOrg === true && (
                <Row>
                    <Col>
                        <LabelField
                            id='reviewRelationshipToOrg'
                            displayName='Relationship to organisation'
                            value={DisplayRelationshipToOrgType(complaintsData.relationshipToOrganisation ?? null)}
                            isMandatory={false}
                        />
                    </Col>
                </Row>
            )}
            {showInsuranceExpiryDate === true && (
                <Row>
                    <Col>
                        <LabelField
                            id='reviewInsuranceEndDate'
                            displayName='Current insurance expiry date'
                            value={insuranceExpiryDateOutput}
                            isMandatory={false}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};

export default ComplaintsMechanismFieldsDisplay;
