// Manage Details Review and Submit page
import React, { useEffect, useMemo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { checkIcon, timesIcon } from '../../../../utils/Icons';
import {
    CountOfValidComplaintsMech,
    GetComplaintsMechanismName,
    IsRelationshipToEmployerExists,
    OutletTypeOutput,
    ShowInsuranceExpiryDetails,
} from '../../../../utils/ComplaintsUtils';
import { DisplayBool } from '../../../../utils/Common';
import { formatDateForDisplay } from '../../../../utils/Dates';
import { DisplayRelationshipToOrgType } from '../../../../utils/EnumMappings';
import { IsAnyAttachmentsExistOfThisType, IsComplaintChangesResultingInLastOneCancelled } from '../../../../utils/AppUtils';
import { IAttachmentMetadata, IComplaintMechanism, IDifferences, Inquiry } from '../../../../api/interfacesApi';
import ApplicationActions from '../../../../actions/applicationActions';
import AttachmentActions from '../../../../actions/attachmentsActions';
import {
    COMPLAINTS_MECHANISM_ALLREMOVED_P1,
    COMPLAINTS_MECHANISM_ALLREMOVED_P2,
    MODALBUTTON_NO_DISCARDCHANGES,
    MODALBUTTON_YES_DISCARDCHANGES,
    MODALHEADER_DISCARDCHANGE,
    MODALTEXT_DISCARDCHANGE_P1,
    MODALTEXT_DISCARDCHANGE_P2,
    WARNING_NOTREADYFORSUBMIT_P1,
    WARNING_NOTREADYFORSUBMIT_P2,
} from '../../../../services/Constants';
import { AccreditationStatusEnum, AttachmentDocumentType, ComplaintsMechanismType, RelationshipToEmployerType } from '../../../../services/Enums';
import AuthService from '../../../../services/AuthService';
import LabelField from '../../../../components/fields/LabelField';
import PageFieldsTitle from '../../../../components/fields/PageFieldsTitle';
import LinkToPage from '../../../../components/fields/LinkToPage';
import NoteText from '../../../../controls/NoteText';
import { EmptyRow } from '../../../../controls/EmptyRow';
import FileUploadDisplay from 'pages/Registration/ReviewAndSubmit/fileUploadDisplay';
import styles from '../../../../pages/Dashboard/Dashboard.module.scss';
import stylesFields from '../../../../components/fields/Fields.module.scss';
import stylesViewLayout from './complaintsMechanism.module.scss';
import { ModalDialogConfirmable } from 'controls/ModalDialogConfirmable';
import RemoveComplaintsMechanism from './removeComplaintsMechanism';
import { toastErrorMessage } from 'actions/toastrMessages';

// const RemoveComplaintsMechanismComponent = React.lazy(() => import('./removeComplaintsMechanism'));

const { complaintsMechRowContainer, complaintsMechHeader, complaintsFieldTitleLHS, checkIconOnField, timesIconOnField, complaintsReviseLink } =
    stylesViewLayout;
const { cardDeckRowNote } = styles;
const { linkToPage } = stylesFields;

interface DiffComplaintsMechanismDisplayProps {
    authService: AuthService;
    dispatch: any;
    inquiry: Inquiry;
    refreshReviewAndSubmit: any;
    includesAnInvalidComplaintMech: any;
    addedCMcollection: IDifferences<IComplaintMechanism>[] | null;
    removedCMcollection: IDifferences<IComplaintMechanism>[] | null;
    updatedCMcollection: IDifferences<IComplaintMechanism>[] | null;
    formatForPDF?: boolean;
}

const DiffComplaintsMechanismDisplay = (props: DiffComplaintsMechanismDisplayProps) => {
    const {
        authService,
        dispatch,
        inquiry,
        refreshReviewAndSubmit,
        includesAnInvalidComplaintMech,
        addedCMcollection,
        removedCMcollection,
        updatedCMcollection,
        formatForPDF,
    } = props;

    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const attachmentActions = useMemo(() => {
        return new AttachmentActions(authService);
    }, [authService]);

    const noteColor = '#18566d'; // 'cadetblue';
    const noteBackgroundColor = '#f7de92'; // brighter yellow='#eed202' '#ffc107'; // '#ffffcc'; // '#12546A';
    const isMyLastComplaintMechanismFlaggedForCancellation = IsComplaintChangesResultingInLastOneCancelled(
        inquiry.application.complaintsMechanism,
        addedCMcollection,
        removedCMcollection,
    );
    const isFullyAccredited = inquiry.application?.accreditationStatus === AccreditationStatusEnum.Accredited;

    const IsUndefinedOrNull = (item: any) => {
        return item === undefined || item === null;
    };

    const IsOnlyComplaintsMech = () => {
        // check if we have only one CM and that is a private where we have just removed the insurance
        return CountOfValidComplaintsMech(inquiry.application.complaintsMechanism) === 1;
    };

    const IsAddPrivateNoInsuranceAndRemoveCM = (complaintsData: IComplaintMechanism) => {
        // check if we have more than one CM and have one add CM that is private NO ins and one remove CM
        let retValue = false;

        const isPrivate = complaintsData.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc;
        const isInvalidInsurance =
            IsUndefinedOrNull(complaintsData.professionalIndemnityInsuranceFromDate) || IsUndefinedOrNull(complaintsData.professionalIndemnityInsuranceToDate);
        const hasAtLeastOneAddCM = addedCMcollection !== null && addedCMcollection.length > 0;
        const hasAsManyRemoveAsAdds = hasAtLeastOneAddCM === true && removedCMcollection !== null && addedCMcollection?.length === removedCMcollection?.length;

        retValue = isPrivate === true && isInvalidInsurance === true && hasAtLeastOneAddCM === true && hasAsManyRemoveAsAdds === true;
        return retValue;
    };

    const IsAddPrivateNoInsuranceAndOthersOk = (complaintsData: IComplaintMechanism) => {
        // check if we have more than one CM and have one add CM that is private NO ins and any other CM has insurance
        let retValue = false;

        const isPrivate = complaintsData.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc;
        const isInvalidInsurance =
            IsUndefinedOrNull(complaintsData.professionalIndemnityInsuranceFromDate) || IsUndefinedOrNull(complaintsData.professionalIndemnityInsuranceToDate);
        const hasAtLeastOneAddCM = addedCMcollection !== null && addedCMcollection.length > 0;
        const hasAnyOtherCMWithInsurance =
            inquiry.application.complaintsMechanism.filter(
                cm => cm.hasProfessionalIndemnityInsuranceViaMembership === true && cm.complaintsMechanismId !== complaintsData.complaintsMechanismId,
            )?.length > 0;

        retValue = isPrivate === true && isInvalidInsurance === true && hasAtLeastOneAddCM === true && hasAnyOtherCMWithInsurance === true;
        return retValue;
    };

    const IsComplaintChangeValid = (complaintsData: IComplaintMechanism) => {
        /*
        Invalid change list:
            - selected only a complaints type (no name)
            - selected a complaints type and selected a name but have not uploaded a document (same for insurance and has not uploaded a document)
            - selected a complaints type of private outlet, entered a name of an association with membership dates and have not provided insurance information
                AND is Accredited (Cond. Accred is OK here!)
            - selected a complaints type of legal aid/other type organisation with relationship of 'panel member' and have not provided insurance information​
        EXCEPT WHEN:
            - is Private AND insurance is removed (already) AND is the only CM
             OR
            - is Private AND insurance is removed (as we are doing this update) AND adding private NO insurance 
        */
        let isValid = false;

        const name = GetComplaintsMechanismName(complaintsData);
        const attachmentExists = IsUndefinedOrNull(complaintsData.evidence) !== true && complaintsData.evidence.length > 0;
        const privateIsInvalid =
            isFullyAccredited === true &&
            complaintsData.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc &&
            complaintsData.professionalAssociation?.name !== '' &&
            IsUndefinedOrNull(complaintsData.membershipFromDate) === false &&
            IsUndefinedOrNull(complaintsData.membershipToDate) === false &&
            (IsUndefinedOrNull(complaintsData.professionalIndemnityInsuranceFromDate) ||
                IsUndefinedOrNull(complaintsData.professionalIndemnityInsuranceToDate));
        const isInvalidInsuranceCheck =
            IsUndefinedOrNull(complaintsData.professionalIndemnityInsuranceFromDate) || IsUndefinedOrNull(complaintsData.professionalIndemnityInsuranceToDate);
        const legalAidOtherPanelMemberIsInvalid =
            complaintsData.complaintsMechanismType === ComplaintsMechanismType.GovernmentFundedFDRService &&
            complaintsData.relationshipToOrganisation === RelationshipToEmployerType.OnPanel &&
            (IsUndefinedOrNull(complaintsData.professionalIndemnityInsuranceFromDate) ||
                IsUndefinedOrNull(complaintsData.professionalIndemnityInsuranceToDate));
        // if we have one attachment but need two then flag this
        const ifInsuranceDoesEvidenceExist =
            isInvalidInsuranceCheck === false ? IsAnyAttachmentsExistOfThisType(AttachmentDocumentType.Insurance, complaintsData.evidence) === true : true;
        const ifPrivateDoesEvidenceExist =
            complaintsData.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc
                ? IsAnyAttachmentsExistOfThisType(AttachmentDocumentType.AssociationMembership, complaintsData.evidence) === true
                : true;
        const isInsuranceOnlyChange =
            complaintsData.complaintsMechanismType === ComplaintsMechanismType.GovernmentFundedFDRService &&
            complaintsData.relationshipToOrganisation === RelationshipToEmployerType.OnPanel &&
            (IsAnyAttachmentsExistOfThisType(AttachmentDocumentType.OrgEmployment, complaintsData.evidence) === true ||
                IsAnyAttachmentsExistOfThisType(AttachmentDocumentType.OrgPanel, complaintsData.evidence) === true) === false;
        const ifGovtFundedDoesEvidenceExist =
            complaintsData.complaintsMechanismType === ComplaintsMechanismType.GovernmentFundedFDRService && isInsuranceOnlyChange === false
                ? IsAnyAttachmentsExistOfThisType(AttachmentDocumentType.OrgEmployment, complaintsData.evidence) === true ||
                  IsAnyAttachmentsExistOfThisType(AttachmentDocumentType.OrgPanel, complaintsData.evidence) === true
                : true;

        const attachmentsExistForEveryType =
            attachmentExists === true && ifInsuranceDoesEvidenceExist === true && ifPrivateDoesEvidenceExist === true && ifGovtFundedDoesEvidenceExist === true;

        isValid =
            name !== '' &&
            attachmentExists === true &&
            attachmentsExistForEveryType === true &&
            privateIsInvalid === false &&
            legalAidOtherPanelMemberIsInvalid === false;

        // now check if this is the ONLY insurance left at which point let it through as Practitioner will become conditional
        if (isValid === false && privateIsInvalid === true && isInvalidInsuranceCheck === true) {
            if (
                IsOnlyComplaintsMech() === true ||
                IsAddPrivateNoInsuranceAndRemoveCM(complaintsData) === true ||
                IsAddPrivateNoInsuranceAndOthersOk(complaintsData) === true
            ) {
                isValid = true; // valid now to get through (PAU can always ask for more evidence outside of the system)
            }
        }

        return isValid;
    };

    const flagAddedCMForInvalidCheck = (complaintsData: IComplaintMechanism, index: number) => {
        if (IsComplaintChangeValid(complaintsData) === false) {
            includesAnInvalidComplaintMech(); // prevents the submit
        }
    };

    const flagUpdatedCMForInvalidCheck = (complaintsData: IComplaintMechanism, index: number) => {
        const isValid = IsComplaintChangeValid(complaintsData) && IsUpdatedButHasNoChanges(complaintsData) === false;
        if (isValid === false) {
            includesAnInvalidComplaintMech(); // prevents the submit
        }
    };

    const mergeUpdatedCMdiffsToOrig = (complaintsDataOrig: IComplaintMechanism, complaintsDataDiff: IComplaintMechanism) => {
        // tslint:disable-next-line: prefer-const
        let newCM: IComplaintMechanism = complaintsDataOrig;
        newCM.complaintsMechanismId = complaintsDataDiff.complaintsMechanismId ?? newCM.complaintsMechanismId; // could be undefined
        newCM.isEditCopy = complaintsDataDiff.isEditCopy ?? newCM.isEditCopy; // could be default/undefined

        // take values from diffs only when NOT undefined (valid for NULL values to exist hence we cannot use ?? operator)
        newCM.complaintsMechanismType =
            complaintsDataDiff.complaintsMechanismType !== undefined ? complaintsDataDiff.complaintsMechanismType : newCM.complaintsMechanismType;
        newCM.evidence = complaintsDataDiff.evidence ?? newCM.evidence; // MUST take on the new evidence even if undefined! // !== undefined ? complaintsDataDiff.evidence : newCM.evidence;
        newCM.hasProfessionalIndemnityInsuranceViaMembership =
            complaintsDataDiff.hasProfessionalIndemnityInsuranceViaMembership !== undefined
                ? complaintsDataDiff.hasProfessionalIndemnityInsuranceViaMembership
                : newCM.hasProfessionalIndemnityInsuranceViaMembership;
        newCM.professionalIndemnityInsuranceFromDate =
            complaintsDataDiff.professionalIndemnityInsuranceFromDate !== undefined
                ? complaintsDataDiff.professionalIndemnityInsuranceFromDate
                : newCM.professionalIndemnityInsuranceFromDate;
        newCM.professionalIndemnityInsuranceToDate =
            complaintsDataDiff.professionalIndemnityInsuranceToDate !== undefined
                ? complaintsDataDiff.professionalIndemnityInsuranceToDate
                : newCM.professionalIndemnityInsuranceToDate;
        newCM.recordActiveFromDate =
            complaintsDataDiff.recordActiveFromDate !== undefined ? complaintsDataDiff.recordActiveFromDate : newCM.recordActiveFromDate;
        newCM.recordActiveToDate = complaintsDataDiff.recordActiveToDate !== undefined ? complaintsDataDiff.recordActiveToDate : newCM.recordActiveToDate;
        newCM.membershipFromDate = complaintsDataDiff.membershipFromDate !== undefined ? complaintsDataDiff.membershipFromDate : newCM.membershipFromDate;
        newCM.membershipToDate = complaintsDataDiff.membershipToDate !== undefined ? complaintsDataDiff.membershipToDate : newCM.membershipToDate;
        newCM.relationshipToOrganisation =
            complaintsDataDiff.relationshipToOrganisation !== undefined ? complaintsDataDiff.relationshipToOrganisation : newCM.relationshipToOrganisation;

        return newCM;
    };

    // initial check for ANY invalid CMs
    useEffect(() => {
        // MUST do this here otherwise in redner it will throw the  'cannot update component X while rendering component Y'
        const hasAddedCMs = addedCMcollection !== null && addedCMcollection.length > 0;
        if (hasAddedCMs === true) {
            addedCMcollection!.map((data, index) => flagAddedCMForInvalidCheck(data.originalVersion, index));
        }

        const hasUpdatedCMs = updatedCMcollection !== null && updatedCMcollection.length > 0;
        if (hasUpdatedCMs === true) {
            updatedCMcollection!.map((data, index) => flagUpdatedCMForInvalidCheck(mergeUpdatedCMdiffsToOrig(data.originalVersion, data.differences), index));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const notReadyForSubmitNote = () => {
        return (
            <>
                <Row id='warning-note-notreadyforsubmit' data-testid='warning-note-notreadyforsubmit'>
                    <Col md={12}>
                        <NoteText displayText={WARNING_NOTREADYFORSUBMIT_P1} displayTextP2={WARNING_NOTREADYFORSUBMIT_P2} />
                    </Col>
                </Row>
                <EmptyRow />
            </>
        );
    };

    const displayMembershipDates = (showMembershipDates: boolean, complaintsData: IComplaintMechanism) => {
        if (showMembershipDates === false) {
            return null;
        }

        return (
            <>
                <Row>
                    <Col md={5} className={complaintsFieldTitleLHS}>
                        <p>Membership dates : </p>
                    </Col>
                    <Col />
                </Row>
                <Row>
                    <Col md={5} className={complaintsFieldTitleLHS}>
                        <p>Start date : </p>
                    </Col>
                    <Col md={6}>
                        <p>{formatDateForDisplay(complaintsData.membershipFromDate)}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={5} className={complaintsFieldTitleLHS}>
                        <p>End date : </p>
                    </Col>
                    <Col md={6}>
                        <p>{formatDateForDisplay(complaintsData.membershipToDate)}</p>
                    </Col>
                </Row>
            </>
        );
    };

    const displayInsuranceDates = (showInsuranceDates: boolean, complaintsData: IComplaintMechanism) => {
        if (showInsuranceDates === false) {
            return null;
        }

        return (
            <>
                <Row>
                    <Col md={5} className={complaintsFieldTitleLHS}>
                        <p>Insurance coverage : </p>
                    </Col>
                    <Col md={6}>
                        <p>{DisplayBool(complaintsData.hasProfessionalIndemnityInsuranceViaMembership)}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={5} className={complaintsFieldTitleLHS}>
                        <p>Insurance dates : </p>
                    </Col>
                    <Col />
                </Row>
                <Row>
                    <Col md={5} className={complaintsFieldTitleLHS}>
                        <p>Start date : </p>
                    </Col>
                    <Col md={6}>
                        <p>{formatDateForDisplay(complaintsData.professionalIndemnityInsuranceFromDate)}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={5} className={complaintsFieldTitleLHS}>
                        <p>End date : </p>
                    </Col>
                    <Col md={6}>
                        <p>{formatDateForDisplay(complaintsData.professionalIndemnityInsuranceToDate)}</p>
                    </Col>
                </Row>
            </>
        );
    };

    const removeThisComplaintsMechanism = (complaintsMechanismId: string) => {
        // go and get this CM record from one of the three collections
        let complaintMechanism = addedCMcollection?.find(addedCM => addedCM.originalVersion.complaintsMechanismId === complaintsMechanismId)?.originalVersion;

        if (complaintMechanism === undefined) {
            complaintMechanism = addedCMcollection?.find(addedCM => addedCM.differences.complaintsMechanismId === complaintsMechanismId)?.differences;
        }
        if (complaintMechanism === undefined) {
            complaintMechanism = removedCMcollection?.find(
                removedCM => removedCM.originalVersion.complaintsMechanismId === complaintsMechanismId,
            )?.originalVersion;
        }
        if (complaintMechanism === undefined) {
            complaintMechanism = removedCMcollection?.find(removedCM => removedCM.differences.complaintsMechanismId === complaintsMechanismId)?.differences;
        }
        if (complaintMechanism === undefined) {
            // find this by merging te updated collection
            const updatedCMs: IComplaintMechanism[] = updatedCMcollection!.map(data => mergeUpdatedCMdiffsToOrig(data.originalVersion, data.differences));

            if (updatedCMs) {
                complaintMechanism = updatedCMs.find(updatedCM => updatedCM.complaintsMechanismId === complaintsMechanismId);
            }
        }

        const isRemovingOnlyChange =
            (addedCMcollection !== null ? addedCMcollection.length : 0) +
                (removedCMcollection !== null ? removedCMcollection.length : 0) +
                (updatedCMcollection !== null ? updatedCMcollection.length : 0) ===
            1;

        // const complaintMechanism = inquiry.application.complaintsMechanism.find(
        //    cm => cm.isEditCopy === true && cm.complaintsMechanismId === complaintsMechanismId,
        // );

        // MUST remove all attachments (evidence)
        if (complaintMechanism !== undefined) {
            RemoveComplaintsMechanism({ dispatch, appActions, attachmentActions, complaintMechanism, inquiry, refreshReviewAndSubmit, isRemovingOnlyChange });
        } else {
            toastErrorMessage('There has been a problem. Please use "Discard all changes" button below, then contact us if there are further problems.');
        }
    };

    const handleClickRemoveThisChange = async (complaintsMechanismId: string) => {
        if (
            await ModalDialogConfirmable(MODALTEXT_DISCARDCHANGE_P1, MODALBUTTON_YES_DISCARDCHANGES, MODALBUTTON_NO_DISCARDCHANGES, {
                title: MODALHEADER_DISCARDCHANGE,
                confirmationP2: MODALTEXT_DISCARDCHANGE_P2,
                isDestructive: true,
                modalBodyPositionTextLeft: false,
                dataTestId: 'modalPromptToRemoveData_ComplaintsChange',
            })
        ) {
            removeThisComplaintsMechanism(complaintsMechanismId);
        }
    };

    const displayRemoveThisChangeButton = (complaintsMechanismId: string) => {
        return (
            <>
                <Button variant='outline-danger' onClick={() => handleClickRemoveThisChange(complaintsMechanismId)} data-testid='removeThisChangeButton'>
                    Discard this change
                </Button>
            </>
        );
    };

    const displayAddedCM = (complaintsData: IComplaintMechanism, index: number) => {
        const isValid = IsComplaintChangeValid(complaintsData);
        // if (isValid === false) {
        //    includesAnInvalidComplaintMech(); // prevents the submit // DO NOT DO IN RENDER!
        // }
        const complaintFullMembership = complaintsData.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc;

        const name = GetComplaintsMechanismName(complaintsData);
        const showRelationshipToOrg = IsRelationshipToEmployerExists(complaintsData);
        const relationshipToOrg = DisplayRelationshipToOrgType(complaintsData.relationshipToOrganisation ?? null);
        const showMembershipDates = complaintFullMembership === true;
        const showInsuranceDates = ShowInsuranceExpiryDetails(complaintsData);
        const hasInsurance = complaintsData.hasProfessionalIndemnityInsuranceViaMembership === true;
        const docType =
            complaintsData.relationshipToOrganisation === RelationshipToEmployerType.OnPanel
                ? AttachmentDocumentType.OrgPanel
                : AttachmentDocumentType.OrgEmployment;

        if (formatForPDF === true) {
            const showNoInsurance = () => {
                return (
                    <Row>
                        <Col>
                            <LabelField
                                id='noInsuranceLabel'
                                displayName='Insurance coverage'
                                value={'I do not yet have professional indemnity insurance.'}
                                isMandatory={false}
                                isTitleHtag={formatForPDF}
                            />
                        </Col>
                    </Row>
                );
            };

            const showInsuranceDetails = () => {
                return (
                    <>
                        <Row>
                            <Col>
                                <LabelField
                                    id='hasInsuranceLabel'
                                    displayName='Insurance coverage'
                                    value={'Through a policy of professional indemnity insurance established through membership with an insurance provider.'}
                                    isMandatory={false}
                                    isTitleHtag={formatForPDF}
                                />
                            </Col>
                        </Row>
                        <EmptyRow />
                        <FileUploadDisplay
                            inquiry={inquiry}
                            showThisDocType={AttachmentDocumentType.Insurance}
                            title='Professional indemnity insurance evidence'
                            formatForPDF={formatForPDF}
                        />
                        <Row>
                            <Col>
                                <LabelField
                                    id='reviewProfessionalIndemnityInsFromDate'
                                    displayName='Period of insurance - Start date'
                                    value={formatDateForDisplay(complaintsData.professionalIndemnityInsuranceFromDate)}
                                    isMandatory={false}
                                    isTitleHtag={formatForPDF}
                                />
                            </Col>
                        </Row>
                        <EmptyRow />
                        <Row>
                            <Col>
                                <LabelField
                                    id='reviewProfessionalIndemnityInsToDate'
                                    displayName='Period of insurance - End date'
                                    value={formatDateForDisplay(complaintsData.professionalIndemnityInsuranceToDate)}
                                    isMandatory={false}
                                    isTitleHtag={formatForPDF}
                                />
                            </Col>
                        </Row>
                    </>
                );
            };

            const displayRelevantComplaintData = () => {
                if (complaintFullMembership === true) {
                    return (
                        <>
                            <Row>
                                <Col>
                                    <LabelField
                                        id='profAssocName'
                                        displayName='Name of professional association'
                                        value={name ?? ''}
                                        isMandatory={false}
                                        isTitleHtag={formatForPDF}
                                    />
                                </Col>
                            </Row>
                            <EmptyRow />
                            <FileUploadDisplay
                                inquiry={inquiry}
                                showThisDocType={AttachmentDocumentType.AssociationMembership}
                                title='Professional association evidence'
                                formatForPDF={formatForPDF}
                            />
                            <Row>
                                <Col>
                                    <LabelField
                                        id='reviewMembershipStartDate'
                                        displayName='Period of current membership - Start date'
                                        value={formatDateForDisplay(complaintsData.membershipFromDate)}
                                        isMandatory={false}
                                        isTitleHtag={formatForPDF}
                                    />
                                </Col>
                            </Row>
                            {formatForPDF === true ? <EmptyRow /> : null}
                            <Row>
                                <Col>
                                    <LabelField
                                        id='reviewMembershipEndDate'
                                        displayName='Period of current membership - End date'
                                        value={formatDateForDisplay(complaintsData.membershipToDate)}
                                        isMandatory={false}
                                        isTitleHtag={formatForPDF}
                                    />
                                </Col>
                            </Row>
                            <EmptyRow />
                            <PageFieldsTitle smallerHeader={true} title='Professional indemnity insurance' underlineTitle={formatForPDF} />
                            <EmptyRow />
                            {hasInsurance === true && showInsuranceDetails()}
                            {hasInsurance === false && showNoInsurance()}
                        </>
                    );
                } else {
                    return (
                        <>
                            <Row>
                                <Col>
                                    <LabelField
                                        id='orgName'
                                        displayName='Organisation name'
                                        value={name ?? ''}
                                        isMandatory={false}
                                        isTitleHtag={formatForPDF}
                                    />
                                </Col>
                            </Row>
                            <EmptyRow />
                            <Row>
                                <Col>
                                    <LabelField
                                        id='relationshipToOrg'
                                        displayName='Relationship to organisation'
                                        value={relationshipToOrg}
                                        isMandatory={false}
                                        isTitleHtag={formatForPDF}
                                    />
                                </Col>
                            </Row>
                            <EmptyRow />
                            <FileUploadDisplay
                                inquiry={inquiry}
                                // attachmentIdToShow={attachmentIdToShow}
                                showThisDocType={docType}
                                title='Evidence of employment of panel inclusion'
                                formatForPDF={formatForPDF}
                            />
                        </>
                    );
                }
            };

            return (
                <>
                    <EmptyRow />
                    <Row>
                        <Col>
                            <LabelField
                                id='typeOfCM'
                                displayName='Type of complaints mechanism'
                                value={OutletTypeOutput(formatForPDF, complaintsData)}
                                isMandatory={false}
                                isTitleHtag={formatForPDF}
                            />
                        </Col>
                    </Row>
                    <EmptyRow />
                    {displayRelevantComplaintData()}
                </>
            );
        }

        return (
            <div className={complaintsMechRowContainer} key={index}>
                <Row>
                    <Col md={2}>{isValid === true ? checkIcon(checkIconOnField) : timesIcon(timesIconOnField)}</Col>
                    <Col md={3} className={complaintsFieldTitleLHS}>
                        <p>Outlet type : </p>
                    </Col>
                    <Col md={6}>
                        <p>{OutletTypeOutput(false, complaintsData)}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>{isValid === true ? <p>&nbsp;</p> : displayRemoveThisChangeButton(complaintsData.complaintsMechanismId)}</Col>
                    <Col md={3} className={complaintsFieldTitleLHS}>
                        <p>Complaints mechanism for outlet : </p>
                    </Col>
                    <Col md={6}>
                        <p>{name}</p>
                    </Col>
                </Row>
                {showRelationshipToOrg === true && (
                    <Row>
                        <Col md={5} className={complaintsFieldTitleLHS}>
                            <p>Relationship to organisation : </p>
                        </Col>
                        <Col md={6}>
                            <p>{relationshipToOrg}</p>
                        </Col>
                    </Row>
                )}
                {displayMembershipDates(showMembershipDates, complaintsData)}
                {displayInsuranceDates(showInsuranceDates, complaintsData)}
            </div>
        );
    };

    const displayRemovedCM = (complaintsData: IComplaintMechanism, index: number) => {
        // remove ones cannot be marked invalid in case there is a problem with the data
        const isValid = true; // isMyLastComplaintMechanismFlaggedForCancellation !== true; // IsComplaintChangeValid(complaintsData);
        // if (isValid === false) {
        //    includesAnInvalidComplaintMech(); // prevents the submit // DO NOT DO IN RENDER!
        // }

        // const complaintFullMembership = complaintsData.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc;
        const name = GetComplaintsMechanismName(complaintsData);

        const showMembershipDates = false; // as per #30486 - do not show dates in Remove CM // complaintFullMembership === true;
        const showInsuranceDates = false; // as per #30486 - do not show dates in Remove CM // ShowInsuranceExpiryDetails(complaintsData);

        if (formatForPDF === true) {
            return (
                <>
                    <Row>
                        <Col>{name}</Col>
                        <Col>{formatDateForDisplay(complaintsData.membershipToDate)}</Col>
                    </Row>
                </>
            );
        }

        return (
            <div className={complaintsMechRowContainer} key={index}>
                <Row>
                    <Col md={2}>{isValid === true ? checkIcon(checkIconOnField) : timesIcon(timesIconOnField)}</Col>
                    <Col md={3} className={complaintsFieldTitleLHS}>
                        <p>Complaints mechanism for outlet : </p>
                    </Col>
                    <Col md={6}>
                        <p>{name}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>{isValid === true ? <p>&nbsp;</p> : displayRemoveThisChangeButton(complaintsData.complaintsMechanismId)}</Col>
                    <Col />
                </Row>
                {displayMembershipDates(showMembershipDates, complaintsData)}
                {displayInsuranceDates(showInsuranceDates, complaintsData)}
            </div>
        );
    };

    const FilesAreEqual = (destAttachments: IAttachmentMetadata[], srcAttachments: IAttachmentMetadata[]) => {
        let retValue = true; // unless proven otherwise, err on the side of caution

        retValue = destAttachments.length === srcAttachments.length;

        if (retValue === true) {
            // now check individual file(s) - every() function behaves exactly like forEach(), except it stops iterating through the array whenever the callback function returns a falsy value.
            destAttachments.every(destAttachment => {
                // if this attachment name differs then files are not equal
                const foundItem = srcAttachments.filter(item => item.azureBlobName === destAttachment.azureBlobName);
                if (foundItem?.length === 0) {
                    retValue = false;
                    return false;
                }
                // continue loop
                return true;
            });
        }

        return retValue;
    };

    const IsGovtFundedEmployeeWithOutletMatching = (complaintsData: IComplaintMechanism, origCM: IComplaintMechanism) => {
        let retValue = false;

        retValue =
            origCM.complaintsMechanismType === ComplaintsMechanismType.GovernmentFundedFDRService &&
            origCM.relationshipToOrganisation === RelationshipToEmployerType.Employee &&
            complaintsData.complaintsMechanismType === ComplaintsMechanismType.GovernmentFundedFDRService &&
            complaintsData.relationshipToOrganisation === RelationshipToEmployerType.Employee &&
            origCM.outlet !== null &&
            origCM.outlet?.outletId === complaintsData.outlet?.outletId &&
            origCM.evidence.length === complaintsData.evidence.length &&
            FilesAreEqual(origCM.evidence, complaintsData.evidence); // NOTE: We cannot handle if file is deleted at this point

        return retValue;
    };

    // When comparing we only check certain fields as most others are locked upon edit anyway
    const IsIdentical = (complaintsData: IComplaintMechanism, complaintMechanisms: IComplaintMechanism[]) => {
        // locate the current CM record in our original collection and do a compare
        const origCM = complaintMechanisms.filter(item => item.complaintsMechanismId === complaintsData.supercedesId);

        let retValue = false; // fail-safe, let user submit as opposed to prevent in case issues here

        if (origCM && origCM.length === 1) {
            const sourceCM: IComplaintMechanism = origCM[0];

            // if a FSRP funded AND employee AND matching Outlet then the ONLY thing that could change is the file uploads, and we CANNOT CATER FOR Deleted files so we just pass this check
            if (IsGovtFundedEmployeeWithOutletMatching(complaintsData, sourceCM) === true) {
                // do nothing, check will pass
            } else {
                retValue =
                    complaintsData.membershipFromDate?.getTime() === sourceCM.membershipFromDate?.getTime() &&
                    complaintsData.membershipToDate?.getTime() === sourceCM.membershipToDate?.getTime() &&
                    complaintsData.professionalIndemnityInsuranceFromDate?.getTime() === sourceCM.professionalIndemnityInsuranceFromDate?.getTime() &&
                    complaintsData.professionalIndemnityInsuranceToDate?.getTime() === sourceCM.professionalIndemnityInsuranceToDate?.getTime() &&
                    complaintsData.relationshipToOrganisation === sourceCM.relationshipToOrganisation &&
                    complaintsData.hasProfessionalIndemnityInsuranceViaMembership === sourceCM.hasProfessionalIndemnityInsuranceViaMembership &&
                    complaintsData.evidence.length === sourceCM.evidence.length &&
                    FilesAreEqual(complaintsData.evidence, sourceCM.evidence); // NOTE: We cannot handle if file is deleted at this point
            }
        }

        return retValue;
    };

    const IsUpdatedButHasNoChanges = (complaintsData: IComplaintMechanism) => {
        // RecordActiveFromDate is set on update
        const isAnUpdate =
            complaintsData.isEditCopy === true && complaintsData.recordActiveFromDate !== undefined && complaintsData.recordActiveFromDate !== null;

        const hasNoChanges = IsIdentical(complaintsData, inquiry.application.complaintsMechanism);

        return isAnUpdate === true && hasNoChanges === true;
    };

    const displayUpdatedCM = (complaintsData: IComplaintMechanism, index: number) => {
        const isValid = IsComplaintChangeValid(complaintsData) && IsUpdatedButHasNoChanges(complaintsData) === false;
        // if (isValid === false) {
        //    includesAnInvalidComplaintMech(); // prevents the submit // DO NOT DO IN RENDER!
        // }
        const complaintFullMembership = complaintsData.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc;
        const name = GetComplaintsMechanismName(complaintsData);

        const showMembershipDates = complaintFullMembership === true;
        const showInsuranceDates = ShowInsuranceExpiryDetails(complaintsData);

        if (formatForPDF === true) {
            return (
                <>
                    <EmptyRow />
                    <Row>
                        <Col>{name}</Col>
                    </Row>
                    <Row>
                        <Col md={5} className={complaintsFieldTitleLHS}>
                            <p>CM start date : </p>
                        </Col>
                        <Col md={6}>
                            <p>{formatDateForDisplay(complaintsData.membershipFromDate)}</p>
                        </Col>
                    </Row>
                    {showMembershipDates === true && (
                        <>
                            <Row>
                                <Col md={5} className={complaintsFieldTitleLHS}>
                                    <p>CM end date : </p>
                                </Col>
                                <Col md={6}>
                                    <p>{formatDateForDisplay(complaintsData.membershipToDate)}</p>
                                </Col>
                            </Row>
                            <FileUploadDisplay
                                inquiry={inquiry}
                                showThisDocType={AttachmentDocumentType.AssociationMembership}
                                title='Professional association evidence'
                                formatForPDF={formatForPDF}
                            />
                        </>
                    )}
                    {showInsuranceDates === true && (
                        <>
                            <Row>
                                <Col md={5} className={complaintsFieldTitleLHS}>
                                    <p>Insurance start date : </p>
                                </Col>
                                <Col md={6}>
                                    <p>{formatDateForDisplay(complaintsData.professionalIndemnityInsuranceFromDate)}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={5} className={complaintsFieldTitleLHS}>
                                    <p>Insurance expiry date : </p>
                                </Col>
                                <Col md={6}>
                                    <p>{formatDateForDisplay(complaintsData.professionalIndemnityInsuranceToDate)}</p>
                                </Col>
                            </Row>
                        </>
                    )}
                    <FileUploadDisplay
                        inquiry={inquiry}
                        showThisDocType={AttachmentDocumentType.Insurance}
                        title='Professional indemnity insurance evidence'
                        formatForPDF={formatForPDF}
                    />
                </>
            );
        }

        return (
            <div className={complaintsMechRowContainer} key={index}>
                <Row>
                    <Col md={2}>{isValid === true ? checkIcon(checkIconOnField) : timesIcon(timesIconOnField)}</Col>
                    <Col md={3} className={complaintsFieldTitleLHS}>
                        <p>Complaints mechanism for outlet : </p>
                    </Col>
                    <Col md={6}>
                        <p>{name}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>{isValid === true ? <p>&nbsp;</p> : displayRemoveThisChangeButton(complaintsData.complaintsMechanismId)}</Col>
                    <Col />
                </Row>
                {displayMembershipDates(showMembershipDates, complaintsData)}
                {displayInsuranceDates(showInsuranceDates, complaintsData)}
            </div>
        );
    };

    const displayAddedCMs = () => {
        const hasAddedCMs = addedCMcollection !== null && addedCMcollection.length > 0;

        return (
            <>
                {hasAddedCMs === true && addedCMcollection!.map((data, index) => displayAddedCM(data.originalVersion, index))}
                {hasAddedCMs === false && <p>None</p>}
            </>
        );
    };

    const displayRemovedCMs = () => {
        const hasRemovedCMs = removedCMcollection !== null && removedCMcollection.length > 0;

        return (
            <>
                {hasRemovedCMs === true && removedCMcollection!.map((data, index) => displayRemovedCM(data.originalVersion, index))}
                {hasRemovedCMs === false && <p>None</p>}
                {isMyLastComplaintMechanismFlaggedForCancellation === true && (
                    <>
                        <Row className={`${cardDeckRowNote}`} id='warning-note-lastcomplaintcancelled' data-testid='warning-note-lastcomplaintcancelled'>
                            <Col md='12'>
                                <NoteText
                                    displayText={COMPLAINTS_MECHANISM_ALLREMOVED_P1}
                                    displayTextP2={COMPLAINTS_MECHANISM_ALLREMOVED_P2}
                                    backgroundColor={noteBackgroundColor}
                                    color={noteColor}
                                    fontBold={true}
                                    // buttonLink={'/managedetailsreviewandsubmitpage'}
                                />
                            </Col>
                            <Col md='1' className='justify-content-end' />
                        </Row>
                    </>
                )}
            </>
        );
    };

    const displayUpdatedCMs = () => {
        const hasUpdatedCMs = updatedCMcollection !== null && updatedCMcollection.length > 0;

        return (
            <>
                {hasUpdatedCMs === true &&
                    updatedCMcollection!.map((data, index) => displayUpdatedCM(mergeUpdatedCMdiffsToOrig(data.originalVersion, data.differences), index))}
                {hasUpdatedCMs === false && <p>None</p>}
            </>
        );
    };

    const classLinkToPage = `${linkToPage} ${complaintsReviseLink}`;

    if (formatForPDF === true) {
        return (
            <>
                <Row>
                    <Col>
                        <b style={{ fontSize: 'larger' }}>
                            <i>Added:</i>
                        </b>
                    </Col>
                </Row>
                {displayAddedCMs()}
                <EmptyRow />
                <Row>
                    <Col>
                        <b style={{ fontSize: 'larger' }}>
                            <i>Removed:</i>
                        </b>
                    </Col>
                </Row>
                {displayRemovedCMs()}
                <EmptyRow />
                <Row>
                    <Col>
                        <b style={{ fontSize: 'larger' }}>
                            <i>Updated:</i>
                        </b>
                    </Col>
                </Row>
                {displayUpdatedCMs()}
            </>
        );
    }

    return (
        <>
            <PageFieldsTitle title='' showLinkToPage={false} smallerHeader={true} />
            <EmptyRow />
            {notReadyForSubmitNote()}
            <EmptyRow />
            <LinkToPage className={classLinkToPage} displayText='Revise complaints mechanism(s) and insurance changes' pageLink='/managecomplaintsmechanism' />
            <EmptyRow />
            <Row className={complaintsMechHeader}>
                <Col>
                    <LabelField id='headerAdded' displayName='Added:' value='' isMandatory={false} isDisplayNameFontSizeLarger={true} />
                </Col>
            </Row>
            {displayAddedCMs()}
            <Row className={complaintsMechHeader}>
                <Col>
                    <LabelField id='headerRemoved' displayName='Removed:' value='' isMandatory={false} isDisplayNameFontSizeLarger={true} />
                </Col>
            </Row>
            {displayRemovedCMs()}
            <Row className={complaintsMechHeader}>
                <Col>
                    <LabelField id='headerUpdated' displayName='Updated:' value='' isMandatory={false} isDisplayNameFontSizeLarger={true} />
                </Col>
            </Row>
            {displayUpdatedCMs()}
        </>
    );
};

// TODO: Updated but not changed show a red X and a message (ie an EditCopy = true that is NOT in the updatedCMcollection)

export default DiffComplaintsMechanismDisplay;
