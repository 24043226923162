import { IUser } from '../../api/interfacesApi';
import * as types from '../../actions/actionTypes';
import { formatDateForDateFns } from '../../utils/Dates';
import { DEFAULT_DATE_OF_BIRTH } from '../../services/Constants';

const userInit: IUser = {
    id: 0,
    title: '',
    firstName: '',
    preferredName: '',
    middleName: '',
    familyName: '',
    gender: '',
    consentToPublishGenderOnRegister: null,
    dateOfBirth: DEFAULT_DATE_OF_BIRTH,
    emailAddress: '',
    secondaryEmailAddress: '',
    primaryPhoneNumber: '',
    secondaryPhoneNumber: '',
    isAuthenticated: null,
    isProfileComplete: false,
    userName: '',
    addresses: [],
    isPostalSameAsResidential: false,
};

const user = (state = userInit, action: types.UserActionTypes) => {
    let retUser: IUser;

    switch (action.type) {
        case types.GET_USER_SUCCESS:
            retUser = action.user;
            // if DOB is in format "YYYY-MM-DDTHH:MM:NN" then convert to Date object
            if (retUser.dateOfBirth && (typeof retUser.dateOfBirth === 'string' || retUser.dateOfBirth instanceof String)) {
                retUser.dateOfBirth = formatDateForDateFns(retUser.dateOfBirth);
            }

            return retUser;
        case types.GET_USER_EDITCOPY_SUCCESS:
            retUser = action.user;
            // if DOB is in format "YYYY-MM-DDTHH:MM:NN" then convert to Date object
            if (retUser.dateOfBirth && (typeof retUser.dateOfBirth === 'string' || retUser.dateOfBirth instanceof String)) {
                retUser.dateOfBirth = formatDateForDateFns(retUser.dateOfBirth);
            }

            return retUser;
        case types.SAVE_USER_SUCCESS:
            retUser = action.user;
            retUser.dateOfBirth = formatDateForDateFns(retUser.dateOfBirth);

            return retUser;
        case types.SAVE_USER_GENDERCONSENTONLY_SUCCESS:
            retUser = action.user;
            retUser.dateOfBirth = formatDateForDateFns(retUser.dateOfBirth);

            return retUser;
        case types.DISCARD_CHANGES_SUCCESS:
            retUser = action.user;
            retUser.dateOfBirth = formatDateForDateFns(retUser.dateOfBirth);

            return retUser;
        case types.START_NEW_APPLICATION_SUCCESS:
            retUser = action.user;
            retUser.dateOfBirth = formatDateForDateFns(retUser.dateOfBirth);

            return retUser;
        case types.ASSING_TO_ME_APPLICATION_SUCCESS:
            retUser = action.user;
            retUser.dateOfBirth = formatDateForDateFns(retUser.dateOfBirth);

            return retUser;
        default:
            return state;
    }
};

export default user;
