import React from 'react';
import { Button } from 'react-bootstrap';
import styles from './pageActionFooter.module.scss';

const { pageActionFooter, clickPreviousButton, clickNextButton } = styles;

export interface PageActionFooterProps {
    pageName: string;
    handleClickPrevious: any;
    includeSaveContinueButton?: boolean;
    textForSaveContinueButton?: string;
    textForBackButton?: string;
    includeMiddleButton?: boolean;
    textForMiddleButton?: string;
    middleButtonClickHandler?: any;
    middleButtonVariant?: string;
    includeMiddleButton2?: boolean;
    textForMiddleButton2?: string;
    middleButton2ClickHandler?: any;
    includeMiddleButton3?: boolean;
    textForMiddleButton3?: string;
    middleButton3ClickHandler?: any;
    middleButton3Variant?: string;
    hideBackButton?: boolean;
}

const PageActionFooter = (props: PageActionFooterProps) => {
    const {
        pageName,
        handleClickPrevious,
        includeSaveContinueButton = true,
        textForSaveContinueButton,
        textForBackButton,
        includeMiddleButton,
        textForMiddleButton,
        middleButtonClickHandler,
        middleButtonVariant,
        includeMiddleButton2,
        textForMiddleButton2,
        middleButton2ClickHandler,
        includeMiddleButton3,
        textForMiddleButton3,
        middleButton3ClickHandler,
        middleButton3Variant,
        hideBackButton,
    } = props;

    const showBackButton = hideBackButton !== undefined && hideBackButton === true ? false : true;
    const backButtonText = textForBackButton !== undefined ? textForBackButton : 'Back';
    const nextButtonText = textForSaveContinueButton !== undefined ? textForSaveContinueButton : 'Save and Continue';
    const middleButtonText = textForMiddleButton !== undefined ? textForMiddleButton : 'Skip';
    const middleButtonVariantDef = middleButtonVariant !== undefined ? middleButtonVariant : 'primary';
    const middleButton2Text = textForMiddleButton2 !== undefined ? textForMiddleButton2 : 'Remove this';
    const middleButton3VariantDef = middleButton3Variant !== undefined ? middleButton3Variant : 'primary';
    const middleButton3Text = textForMiddleButton3 !== undefined ? textForMiddleButton3 : 'Remove';

    const handlePreviousClick = () => {
        handleClickPrevious();
    };
    const handleMiddleButtonClick = () => {
        middleButtonClickHandler();
    };
    const handleMiddleButton2Click = () => {
        middleButton2ClickHandler();
    };
    const handleMiddleButton3Click = () => {
        middleButton3ClickHandler();
    };

    return (
        <div className={pageActionFooter}>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col'>
                        {showBackButton && (
                            <Button
                                variant='outline-secondary'
                                className={`btn ${clickPreviousButton}`}
                                onClick={handlePreviousClick}
                                data-testid={`PageBackButton_${pageName}`}
                            >
                                {backButtonText}
                            </Button>
                        )}
                    </div>
                    <div className='col'>
                        {includeMiddleButton && (
                            <Button
                                variant={middleButtonVariantDef}
                                onClick={handleMiddleButtonClick}
                                className={`btn ${clickNextButton}`}
                                data-testid={`PageMiddleButton_${pageName}`}
                            >
                                {middleButtonText}
                            </Button>
                        )}
                    </div>
                    {includeMiddleButton2 && (
                        <div className='col'>
                            <Button
                                variant='primary'
                                onClick={handleMiddleButton2Click}
                                className={`btn ${clickNextButton}`}
                                data-testid={`PageMiddleButton2_${pageName}`}
                            >
                                {middleButton2Text}
                            </Button>
                        </div>
                    )}
                    {includeMiddleButton3 && (
                        <div className='col'>
                            <Button
                                variant={middleButton3VariantDef}
                                onClick={handleMiddleButton3Click}
                                className={`btn ${clickNextButton}`}
                                data-testid={`PageMiddleButton3_${pageName}`}
                            >
                                {middleButton3Text}
                            </Button>
                        </div>
                    )}
                    {includeSaveContinueButton && (
                        <div className='col'>
                            <Button variant='primary' type='submit' className={`btn ${clickNextButton}`} data-testid={`PageSubmitButton_${pageName}`}>
                                {nextButtonText}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PageActionFooter;
