import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Inquiry } from '../../../api/interfacesApi';
import { DisplayBool } from '../../../utils/Common';
import LabelField from '../../../components/fields/LabelField';
import { EmptyRow } from '../../../controls/EmptyRow';

interface ConsentPublishDisplayProps {
    inquiry: Inquiry;
    formatForPDF: boolean;
}

const ConsentPublishDisplay = (props: ConsentPublishDisplayProps) => {
    const { inquiry } = props;

    return (
        <>
            <EmptyRow />
            <EmptyRow />
            {inquiry.application.consentToPublishOnPublicRegister === null ? null : (
                <Row>
                    <Col>
                        <LabelField
                            id='reviewConsentToPublishOnPublicReg'
                            displayName='Name appearing on public searchable list'
                            guidanceText='When approved as a fully accredited FDR practitioner, do you consent to your name appearing on a public list of accredited FDR practitioners?'
                            value={
                                inquiry.application.consentToPublishOnPublicRegister === null
                                    ? ''
                                    : DisplayBool(inquiry.application.consentToPublishOnPublicRegister)
                            } // it is OK to be NULL here
                            isMandatory={false}
                            isTitleHtag={true}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};

export default ConsentPublishDisplay;
