import React from 'react';
import { UNINITIALISED } from '../../services/Constants';
import Guid from '../../services/Guid';
import { WizardStepCurrentStateEnum } from './wizardEnums';
import { WizardStepProps } from './wizardInterfaces';

export const displayApplicationNumber = (applicationNumberClass: string, applicationNumber: string) => {
    if (applicationNumber === UNINITIALISED) {
        return null;
    }

    const uniqueKey = Guid.newGuid();

    return (
        <li data-testid='WizardStepApplicationNumber' key={uniqueKey.toString()} className={applicationNumberClass} role='menuitem'>
            <span>Application Number: {applicationNumber}</span>
        </li>
    );
};

export const isAnyChildMenuItemToBeDisplayed = (childMenuItems: WizardStepProps[]) => {
    // we parse through the list once-only and forward-only
    const isAnyChildToBeDisplayed = Boolean(
        // tslint:disable-next-line: only-arrow-functions
        childMenuItems.find(function (item) {
            return item.isDisplayed;
        }),
    );

    return isAnyChildToBeDisplayed;
};

export const isAnyChildMenuItemActive = (childMenuItems: WizardStepProps[]) => {
    // as we parse through the list once-only and forward-only we need to determine if any child item is active so we can expand (add) the item to the list
    const isAnyChildActive = Boolean(
        // tslint:disable-next-line: only-arrow-functions
        childMenuItems.find(function (item) {
            return item.isActive === true && item.isDisplayed;
        }),
    );

    return isAnyChildActive;
};

export const isAnyChildMenuItemInvalid = (childMenuItems: WizardStepProps[]) => {
    const isAnyChildInvalid = Boolean(
        // tslint:disable-next-line: only-arrow-functions
        childMenuItems.find(function (item) {
            return item.currentState === WizardStepCurrentStateEnum.invalidError || item.currentState === WizardStepCurrentStateEnum.invalidPartial;
        }),
    );

    return isAnyChildInvalid;
};

export const isChild = (menuItems: WizardStepProps[], indexToCheck: number) => {
    // tslint:disable-next-line: only-arrow-functions
    const foundItems = menuItems.filter(function (e) {
        return e.index === indexToCheck;
    });
    return foundItems.length === 1 && foundItems[0].isChild;
};

const getAllChildWizardSteps = (wizardSteps: WizardStepProps[], currIndex: number) => {
    const currChildWizardStep = wizardSteps[currIndex];
    let childWizardSteps;

    if (currChildWizardStep) {
        // tslint:disable-next-line: only-arrow-functions
        childWizardSteps = wizardSteps.filter(function (e) {
            return e.parentDisplayName === currChildWizardStep.parentDisplayName;
        });
    }

    return childWizardSteps;
};

export const setAllChildMenuItemsStatusCompleted = (wizardSteps: WizardStepProps[], currIndex: number) => {
    const childWizardSteps = getAllChildWizardSteps(wizardSteps, currIndex);

    if (childWizardSteps) {
        childWizardSteps.forEach((wizardStep: WizardStepProps) => {
            wizardStep.currentState = WizardStepCurrentStateEnum.completed;
        });
    }
};

export const getAllMySiblings = (wizardSteps: WizardStepProps[], currIndex: number) => {
    let childWizardSteps = getAllChildWizardSteps(wizardSteps, currIndex);

    if (childWizardSteps) {
        // if an item in the collection is not available, as a parent might have three children but only two are available due to business rules,
        // then we need to remove the one that cannot be selected now
        if (childWizardSteps.length > 1 && childWizardSteps[0].nextIndex !== childWizardSteps[1].index) {
            childWizardSteps = childWizardSteps.slice(1, 1);
        }
        // tslint:disable-next-line: only-arrow-functions
        return childWizardSteps.filter(function (e) {
            return e.index !== currIndex;
        });
    }

    // else return nothing found
    return null;
};

export const isAllMenuItemsStatusSubStepCompletedOrCompleted = (wizardSteps: WizardStepProps[] | null) => {
    if (wizardSteps == null) return false;

    // const countTotal = wizardSteps.length;
    // tslint:disable-next-line: only-arrow-functions
    const completedStepsAndSubsteps = wizardSteps.filter(function (e) {
        return e.currentState === WizardStepCurrentStateEnum.substepcompleted || e.currentState === WizardStepCurrentStateEnum.completed;
    });
    // tslint:disable-next-line: only-arrow-functions
    const displayedStepsAndSubsteps = wizardSteps.filter(function (e) {
        return e.isDisplayed === true;
    });

    // const countOfCompletedSteps = completedSubSteps?.length;
    return displayedStepsAndSubsteps?.length === completedStepsAndSubsteps?.length;
};
