import { AjaxCallStateEnum } from '../../services/Enums';
import * as types from '../../actions/actionTypes';

const ajaxCallsInProgress: types.ICallStatus = {
    callsInProgress: 0,
    sessionTimerId: 0,
    loading: false,
    err: { title: '', message: '' },
    errors: [],
    callState: AjaxCallStateEnum.NotStarted,
};

function actionTypeEndsInSuccess(type: string) {
    return type.substring(type.length - 8) === '_SUCCESS' || type.substring(type.length - 7) === '_RESULT';
}

export default function ajaxStatusReducer(state = ajaxCallsInProgress, action: types.AjaxStatusAction) {
    const newState = { ...state };
    newState.loading = false;
    if (action.type === types.BEGIN_AJAX_CALL) {
        // stop timer on call back to server
        if (newState.sessionTimerId !== 0) {
            clearInterval(newState.sessionTimerId);
        }
        newState.callsInProgress++;
        newState.err = { title: '', message: '' };
        newState.errors = [];
        newState.loading = true;
        newState.callState = AjaxCallStateEnum.InProgress;
    } else if (action.type === types.AJAX_CALL_ERROR || actionTypeEndsInSuccess(action.type) || action.type === types.END_AJAX_CALL) {
        if (newState.callsInProgress > 0) {
            newState.callsInProgress--;
        } else {
            newState.callsInProgress = 0;
        }

        // restart timer after server call complete
        if (newState.callsInProgress === 0) {
            // tslint:disable-next-line: only-arrow-functions
            newState.sessionTimerId = setInterval(function () {
                // TODO: Check if reset timing works here or they all bank up...ie 30 mins since first call rather than every call
                window.location.href = '/#timedOut';
            }, 30 * 60 * 1000);
        }

        if (action.type === types.AJAX_CALL_ERROR) {
            if (action.payload?.isValid === false && action.payload?.message === 'Validation Failed') {
                newState.callState = AjaxCallStateEnum.CompletedWithValidation;
                newState.errors = action.payload;
                newState.err = { title: action.payload.errors[0]?.propertyName, message: action.payload.errors[0]?.errorMessage };
            } else {
                if (action.payload?.message === 'timeout') {
                    newState.callState = AjaxCallStateEnum.Timeout;
                    newState.errors = action.payload.errors;
                    newState.err = { title: 'Error', message: 'An error has occurred. Please try again.' };
                } else {
                    newState.callState = AjaxCallStateEnum.CompletedWithError;

                    if (action.payload?.isValid === false) {
                        newState.errors = action.payload;
                        newState.err = { title: action.payload.errors[0]?.propertyName, message: action.payload.errors[0]?.errorMessage };
                    } else {
                        newState.err = { title: 'Error', message: 'An error has occurred. Please try again.' };
                    }
                }
            }
        } else {
            newState.callState = AjaxCallStateEnum.CompletedOK;
        }

        newState.loading = false;
    }

    return newState;
}
