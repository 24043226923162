import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { lightBulbIcon } from '../components/wizard/wizardMenuIcons';
import styles from './Controls.module.scss';

const { tipsContainer, tipsContainerExtraWide, tipsIcon, tipsHeaderText } = styles;

interface TipsControlProps {
    tipString: string;
    extraWideLayout?: boolean;
}

const TipsControl = (props: TipsControlProps) => {
    const { tipString, extraWideLayout } = props;

    const classContainer = extraWideLayout === true ? `${tipsContainerExtraWide} ${tipsContainer}` : tipsContainer;

    return (
        <div className={classContainer}>
            <Row>
                <Col md={1} className={tipsIcon}>
                    {lightBulbIcon()}
                </Col>
                <Col md={1} className={tipsHeaderText}>
                    TIP
                </Col>
                <Col md={10}>{tipString}</Col>
            </Row>
        </div>
    );
};

export default TipsControl;
