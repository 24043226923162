import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import StorageApi from '../../../api/storageApi';
import AttachmentApi from '../../../api/attachmentApi';
import { IAttachmentMetadata, IAttachmentTypeArray, Inquiry } from '../../../api/interfacesApi';
import Guid from '../../../services/Guid';
import {
    GetActiveAttachmentsByType,
    GetActiveAttachmentsByTypeForThisOrNonSavedComplaint,
    IsAttachmentsArrayEqual,
    IsValidGuidIdentifier,
} from '../../../utils/AppUtils';
import { AttachmentDocumentType } from '../../../services/Enums';
import {
    VALIDATION_ERROR_FILETYPENOTSUPPORTED_MESSAGE,
    VALIDATION_ERROR_FILETOOLARGE_MESSAGE,
    VALIDATION_SUCCESS_FILEDELETED_MESSAGE,
    VALIDATION_ERROR_UNKNOWN_MESSAGE,
} from '../../../services/Validation';
import { toastErrorMessage, toastSuccessMessage } from '../../../actions/toastrMessages';
import AttachmentActions from '../../../actions/attachmentsActions';
import FileUploadFieldDisplay from './FileUploadFieldDisplay';

export interface FileUploadFieldProps {
    id: string;
    applicationId: string;
    complaintsProcessId?: string;
    authService: any;
    dispatch: any;
    watch?: any;
    inquiry?: Inquiry;
    displayNameSuffix: string; // displayName begins with 'Upload evidence of' text
    documentType: AttachmentDocumentType;
    maxFilesCount: number;
    defaultValue?: string;
    refreshIfAttachmentIdNotFound?: boolean;
    attachmentIdCollection?: string[];
    updatedFilesList?: any;
    isReadOnly?: boolean;
    isMandatory: boolean;
    capitaliseTextOff?: boolean;
    register: any;
    errorsField: any;
    errorsFieldSuffix?: string;
    autoFocus?: boolean;
    placeHolder?: string;
    guidanceText?: string;
    helpHeaderText?: string;
    helpText?: string;
    goingBackwards?: boolean;
    removedFile: any; // broadcast for CM to update
    addedFile: any; // broadcast for CM to update
    showDisclaimerText?: boolean;
}

const FileUploadField = (props: FileUploadFieldProps) => {
    const {
        id,
        applicationId,
        complaintsProcessId,
        authService,
        dispatch,
        // watch,
        // inquiry,
        displayNameSuffix,
        documentType,
        maxFilesCount,
        refreshIfAttachmentIdNotFound,
        attachmentIdCollection,
        updatedFilesList,
        isMandatory,
        register,
        errorsField,
        errorsFieldSuffix,
        guidanceText,
        helpHeaderText,
        helpText,
        goingBackwards,
        removedFile,
        addedFile,
        showDisclaimerText,
    } = props;

    const storageApi = useMemo(() => {
        return new StorageApi(authService);
    }, [authService]);
    const attachmentApi = useMemo(() => {
        return new AttachmentApi(authService);
    }, [authService]);
    const attachmentActions = useMemo(() => {
        return new AttachmentActions(authService);
    }, [authService]);
    const [isEnabledFileUploadFieldDisplay, setIsEnabledFileUploadFieldDisplay] = useState(true);
    const [storedDocumentType, setStoredDocumentType] = useState(documentType); // store documentType as on Employee Complaints UI two types are used where originally this component was designed for only one ever
    const [isLoading, setIsLoading] = useState(false);

    // need to watch the changes of the attachments array and dis-sect by type as this component is placed by
    //  itself on a page, multiple times on a page, and for multiple times per type on a page!
    // const attachmentsWatched: IAttachmentMetadata[] = watch('inquiry.application.attachments', inquiry?.application.attachments);
    const attachmentsTypeArray: IAttachmentTypeArray = useSelector((stateAttachmentTypeArray: any) => stateAttachmentTypeArray.attachmentsTypeArray);
    // use the below to keep all files of this type (and in the case of CMs, for this complaint) - the below is the WORKING ATTACHMENTS LIST:
    const [workingAttachmentsTypeArray, setWorkingAttachmentsTypeArray] = useState(attachmentsTypeArray);

    const [progress, setProgress] = useState(0);
    // array of {name, url} objects
    const [fileInfos, setFileInfos] = useState<IAttachmentMetadata[]>([]);

    const selectFile = (files: any) => {
        if (files && files.length > 0) {
            upload(files);
        } else {
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    // deletes a file from temp store and DB record is removed
    const deleteFile = (attachmentId: string) => {
        try {
            removeFile(attachmentId);
        } catch (err: any) {
            toastErrorMessage('There was a potential problem removing the file! Message:' + err.message);
        }
    };

    const rejectFile = (files: any) => {
        // show the toasty for files that are rejected
        if (files && files.length > 0) {
            if (files[0]?.errors && files[0].errors[0] && files[0].errors[0].code === 'file-too-large') {
                toastErrorMessage(VALIDATION_ERROR_FILETOOLARGE_MESSAGE);
            } else {
                toastErrorMessage(VALIDATION_ERROR_FILETYPENOTSUPPORTED_MESSAGE);
            }
        } else {
            // default
            toastErrorMessage(VALIDATION_ERROR_FILETYPENOTSUPPORTED_MESSAGE);
        }
    };

    const callAttachmentsApi = () => {
        dispatch(attachmentActions.GetAttachmentsByType(documentType));
    };

    // initial load of any existing data
    useEffect(() => {
        setIsLoading(true);
        callAttachmentsApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // reload data if going backwards
    useEffect(() => {
        if (goingBackwards && goingBackwards === true) {
            setIsLoading(true);
            callAttachmentsApi();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [goingBackwards]);

    useEffect(() => {
        if (storedDocumentType !== documentType) {
            setIsLoading(true);
            setStoredDocumentType(documentType);
            callAttachmentsApi();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentType]);

    const attachmentIdCollectionExists = attachmentIdCollection !== undefined && attachmentIdCollection !== null;
    const isComplaintMechanismTypeOfAttachment = (docType: AttachmentDocumentType) => {
        return (
            docType === AttachmentDocumentType.OrgEmployment ||
            docType === AttachmentDocumentType.OrgPanel ||
            docType === AttachmentDocumentType.AssociationMembership ||
            docType === AttachmentDocumentType.Insurance
        );
    };

    const handleComplaintsAttachmentUpdate = (activeAttachmentsOfThisType: IAttachmentMetadata[]) => {
        // cater for newly added file OR removed file
        // now check if index matches expected
        if (activeAttachmentsOfThisType && activeAttachmentsOfThisType.length > 0 && attachmentIdCollectionExists === true) {
            // if item does not exist for this index then remove it instead of add
            let addedAttempt = false;

            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < activeAttachmentsOfThisType.length; i++) {
                if (
                    activeAttachmentsOfThisType[i].isDeleted === false && // MUST be active
                    // isInAttachmentIdCollection(activeAttachmentsOfThisType[i].attachmentId) === true && // the collection is not being updated so we just jam this in if a CM type
                    isComplaintMechanismTypeOfAttachment(activeAttachmentsOfThisType[i].documentType) === true
                ) {
                    const attachment = activeAttachmentsOfThisType[i];
                    // if not already in collection (and is for this CM record) add it now
                    if (
                        fileInfos.find(a => a.attachmentId === attachment.attachmentId) === undefined &&
                        (complaintsProcessId === attachment.complaintsProcessId || attachment.complaintsProcessId === Guid.empty)
                    ) {
                        fileInfos.push(attachment); // singluar must be placed in as collection
                        setFileInfos(fileInfos);

                        // handle addition of file externally UNLESS we are just loading data
                        if (isLoading === false && addedFile) {
                            addedFile(attachment);
                        }
                    }

                    addedAttempt = true;
                }
            }

            // if either is not added then we have a singular removed
            if (!addedAttempt && fileInfos.length === 1) {
                // it was not found as the active collection had removed the item such that the index was not found, so clear the array for this instance of the fileUploadField
                setFileInfos([]); // singluar removed
            }
        }

        // may only have 1 item that was just removed, confirm and remove it now
        if (activeAttachmentsOfThisType && activeAttachmentsOfThisType.length === 0 && attachmentIdCollectionExists === true) {
            setFileInfos([]); // singluar removed
        }
    };

    const updateLocalFileInfoArray = (attachmentsTypeArrayIn: IAttachmentTypeArray) => {
        // each Type has a call to either load or delete so this instance of the FileUploadField component should only react to that call
        if (workingAttachmentsTypeArray.documentType === documentType) {
            // special case for Employee files as we allow up to 5
            if (isComplaintMechanismTypeOfAttachment(documentType) === true) {
                const activeAttachmentsForThisComplaintByType = GetActiveAttachmentsByTypeForThisOrNonSavedComplaint(
                    complaintsProcessId!,
                    attachmentsTypeArrayIn.attachments,
                    attachmentsTypeArrayIn.documentType!,
                );
                setFileInfos([]); // refresh as late as possible then we will refill it
                handleComplaintsAttachmentUpdate(activeAttachmentsForThisComplaintByType);
            } else {
                // update local collection, confirm this object is actually an array otherwise convert it now
                const activeAttachmentsOfThisType = GetActiveAttachmentsByType(attachmentsTypeArrayIn.attachments, documentType);
                let attachmentsArray: IAttachmentMetadata[] = [];
                const isArray = activeAttachmentsOfThisType && Array.isArray(activeAttachmentsOfThisType);
                attachmentsArray = isArray ? activeAttachmentsOfThisType : Object.values(activeAttachmentsOfThisType);
                setFileInfos(attachmentsArray);

                // handle addition of file externally UNLESS we are just loading data
                if (isLoading === false && addedFile) {
                    for (let i = 0; i < attachmentsArray.length; i++) {
                        const attachment = activeAttachmentsOfThisType[i];
                        // if not already in collection add it now
                        if (fileInfos.find(a => a.attachmentId === attachment.attachmentId) === undefined) {
                            addedFile(attachment);
                        }
                    }
                }
            }
        }
    };

    useEffect(() => {
        // only deal with our docType
        if (documentType === attachmentsTypeArray.documentType) {
            // confirm the stored array of files matches what, if anything, has changed
            if (
                attachmentsTypeArray.documentType !== workingAttachmentsTypeArray.documentType ||
                IsAttachmentsArrayEqual(attachmentsTypeArray.attachments, workingAttachmentsTypeArray.attachments) === false
            ) {
                // confirm if we have supplied a CM id then only bring in those attachments for this Complaint
                if (IsValidGuidIdentifier(complaintsProcessId)) {
                    const activeAttachmentsForThisComplaintByType = GetActiveAttachmentsByTypeForThisOrNonSavedComplaint(
                        complaintsProcessId!,
                        attachmentsTypeArray.attachments,
                        attachmentsTypeArray.documentType!,
                    );

                    const complaintsAttachmentsTypeArray: IAttachmentTypeArray = {
                        index: attachmentsTypeArray.index,
                        documentType: attachmentsTypeArray.documentType,
                        attachments: activeAttachmentsForThisComplaintByType,
                    };
                    setWorkingAttachmentsTypeArray(complaintsAttachmentsTypeArray);
                    updateLocalFileInfoArray(complaintsAttachmentsTypeArray);
                } else {
                    setWorkingAttachmentsTypeArray(attachmentsTypeArray); // store latest value so we dont process this multiple times
                    updateLocalFileInfoArray(attachmentsTypeArray);
                }
            } else {
                // as working array matches incoming array, next confirm if the fileInfos array matches the stored FileInfo array (as user can go back/forth/resume it gets nasty messy!)
                if (fileInfos.length !== attachmentsTypeArray.attachments.length) {
                    updateLocalFileInfoArray(attachmentsTypeArray);
                }
            }

            setIsLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachmentsTypeArray]);

    useEffect(() => {
        const activeAttachmentsOfThisType = GetActiveAttachmentsByType(workingAttachmentsTypeArray.attachments, documentType);
        handleComplaintsAttachmentUpdate(activeAttachmentsOfThisType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachmentIdCollection]);

    if (refreshIfAttachmentIdNotFound === true && attachmentIdCollectionExists === false) {
        // use had removed this from a higher index in Complaints UI most likely and then we replace for example 11010 becomes 11100 where 1=file, 0=deleted/empty
        if (fileInfos.length !== 0) {
            setFileInfos([]); // singular removed
        }
    }

    // update the parent with fileInfo in case it reports on fields
    useEffect(() => {
        // tslint:disable-next-line: no-unused-expression
        updatedFilesList && updatedFilesList(fileInfos);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileInfos]); // limit calling this as it causes infinite loop further downstream

    const removeFile = (attachmentId: string) => {
        // dispatch(attachmentActions.RemoveAttachment(attachmentId, documentType, VALIDATION_SUCCESS_FILEDELETED_MESSAGE, VALIDATION_ERROR_FILEREMOVED_TITLE));
        if (attachmentId === undefined || attachmentId === null) {
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }

        attachmentApi
            .deleteAttachmentBlobAndMetadata(attachmentId)
            .then(() => {
                toastSuccessMessage(VALIDATION_SUCCESS_FILEDELETED_MESSAGE);
                // handled on API side now
                // // store the attachmentId that was just removed so we can update the correct instance when used multiple times on a form
                // // and so ComplaintProcess row storing this Id is also updated/removed
                // props.removedAttachmentId = attachmentId;
                if (removedFile) {
                    removedFile(attachmentId);
                }
                callAttachmentsApi();
            })
            .catch(err => {
                toastErrorMessage('Could not remove the file! Error:' + err.message);
            });
    };

    const upload = (selectedFiles: any) => {
        if (selectedFiles === undefined || selectedFiles === null) {
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }

        if (selectedFiles) {
            const currFile: File = selectedFiles![0];
            const guid = new Guid();

            setProgress(0);

            // need to set BOTH IAttachment and IAttachmentFile objects to send
            const metadata: IAttachmentMetadata = {
                attachmentId: guid.empty,
                fileName: currFile!.name,
                applicationId,
                documentType,
                complaintsProcessId: complaintsProcessId ?? guid.empty,
                isDeleted: false, // set default
                azureBlobName: 'azureblobname', // temp, will be overwritten
                createdBy: 'me', // temp, will be overwritten
            };

            // disable further input until complete or error
            setIsEnabledFileUploadFieldDisplay(false);

            storageApi
                .uploadFile(metadata, currFile, (event: { loaded: number; total: number }) => {
                    setProgress(Math.round((100 * event.loaded) / event.total));
                })
                .then(() => {
                    // if successful, we return the file information
                    toastSuccessMessage(`${metadata.fileName} was successfully uploaded`);
                    callAttachmentsApi();
                })
                .catch(err => {
                    toastErrorMessage('Could not upload the file! Error:' + err);
                })
                .finally(() => {
                    // reset progress
                    setProgress(0);
                    setIsEnabledFileUploadFieldDisplay(true);
                });
        }
    };

    return (
        <FileUploadFieldDisplay
            id={id}
            enabled={isEnabledFileUploadFieldDisplay}
            fileMetadataList={fileInfos}
            register={register}
            maxFilesCount={maxFilesCount}
            progress={progress}
            isMandatory={isMandatory}
            displayNameSuffix={displayNameSuffix}
            selectFile={selectFile}
            rejectFile={rejectFile}
            deleteFile={deleteFile}
            guidanceText={guidanceText}
            helpHeaderText={helpHeaderText}
            helpText={helpText}
            errorsField={errorsField}
            errorsFieldSuffix={errorsFieldSuffix}
            showDisclaimerText={showDisclaimerText}
        />
    );
};

export default FileUploadField;
