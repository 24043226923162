// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { IApplication } from '../../api/interfacesApi';
import AuthService from '../../services/AuthService';
import { NavigationDestination, WizardMode } from '../../services/Enums';
import { FormatApplicationNumber } from '../../utils/AppUtils';
import ApplicationActions from '../../actions/applicationActions';
import { AppContextInterface } from '../../stateManagement/context/AppContext';
import { WizardStepProps } from './wizardInterfaces';
import { UNINITIALISED } from '../../services/Constants';

export interface WizardState {
    wizardMode: WizardMode;
    formStep: number;
    showSaveChangesDialog: boolean;
    showDiscardChangesDialog: boolean;
    goingBackwardsAndDiscarding: boolean;
    showResubmitDiscardChangesDialog: boolean;
    triggerSubmit: boolean;
    formIsDirty: boolean;
    validationSchema: any | undefined;
    goingBackwards: boolean;
}

export const initialWizardState: WizardState = {
    wizardMode: WizardMode.New,
    formStep: 0,
    showSaveChangesDialog: false,
    showDiscardChangesDialog: false,
    goingBackwardsAndDiscarding: false,
    showResubmitDiscardChangesDialog: false,
    triggerSubmit: false,
    formIsDirty: false,
    validationSchema: undefined,
    goingBackwards: false,
};

export enum WizardStateActionTypeEnums {
    initialise,
    repaint,
    activateStep,
    incrementFormStep,
    decrementFormStep,
    showSaveChangesDialog,
    hideSaveChangesDialog,
    showResubmitDiscardChangesDialog,
    hideResubmitDiscardChangesDialog,
    showDiscardChangesDialog,
    hideDiscardChangesDialog,
    goingBackwardsAndDiscarding,
    setTriggerSubmit,
    unsetTriggerSubmit,
    setFormIsDirty,
    unsetFormIsDirty,
    setValidationSchema,
    setWizardMode,
}

export function reducerWizardState(
    state: any,
    action: {
        type: WizardStateActionTypeEnums;
        wizardSteps?: WizardStepProps[];
        numberOfSteps?: number;
        destinationStep?: number;
        validationSchema: any | undefined;
        wizardMode?: WizardMode;
    },
) {
    switch (action.type) {
        case WizardStateActionTypeEnums.initialise:
            return {
                ...initialWizardState,
            };

        case WizardStateActionTypeEnums.repaint:
            return {
                ...state,
            };

        case WizardStateActionTypeEnums.activateStep:
            return {
                ...state,
                formStep: action.destinationStep!,
                goingBackwards: true, // will re-load attachments
            };

        case WizardStateActionTypeEnums.incrementFormStep:
            // this will update the UI portion to show UI elements (i.e. wizard is done separately)
            return {
                ...state,
                formStep: state.formStep + action.numberOfSteps!,
                goingBackwards: false,
            };

        case WizardStateActionTypeEnums.decrementFormStep:
            // this will update the UI portion to show UI elements (i.e. wizard is done separately)
            return {
                ...state,
                formStep: state.formStep - action.numberOfSteps!,
                goingBackwards: true,
            };

        case WizardStateActionTypeEnums.showSaveChangesDialog:
            return {
                ...state,
                showSaveChangesDialog: true,
            };

        case WizardStateActionTypeEnums.hideSaveChangesDialog:
            return {
                ...state,
                showSaveChangesDialog: false,
            };

        case WizardStateActionTypeEnums.showDiscardChangesDialog:
            return {
                ...state,
                showDiscardChangesDialog: true,
            };

        case WizardStateActionTypeEnums.hideDiscardChangesDialog:
            return {
                ...state,
                showDiscardChangesDialog: false,
            };

        case WizardStateActionTypeEnums.goingBackwardsAndDiscarding:
            return {
                ...state,
                goingBackwards: true,
            };

        case WizardStateActionTypeEnums.showResubmitDiscardChangesDialog:
            return {
                ...state,
                showResubmitDiscardChangesDialog: true,
            };

        case WizardStateActionTypeEnums.hideResubmitDiscardChangesDialog:
            return {
                ...state,
                showResubmitDiscardChangesDialog: false,
            };

        case WizardStateActionTypeEnums.setTriggerSubmit:
            return {
                ...state,
                triggerSubmit: true,
            };

        case WizardStateActionTypeEnums.unsetTriggerSubmit:
            return {
                ...state,
                triggerSubmit: false,
            };

        case WizardStateActionTypeEnums.setFormIsDirty:
            return {
                ...state,
                formIsDirty: true,
            };

        case WizardStateActionTypeEnums.unsetFormIsDirty:
            return {
                ...state,
                formIsDirty: false,
            };

        case WizardStateActionTypeEnums.setValidationSchema:
            return {
                ...state,
                validationSchema: action.validationSchema,
            };

        case WizardStateActionTypeEnums.setWizardMode:
            return {
                ...state,
                wizardMode: action.wizardMode,
            };

        default:
            return {
                ...state,
            };
    }
}

export function SetApplicationNumber(appContext: AppContextInterface, appNumber: number) {
    // properly formats the Z number coming from the SQL DB (where number is only 3, for example)
    // i.e. 3 becomes Z000003
    if (appContext !== undefined && appContext?.applicationNumber === UNINITIALISED && appNumber > 0) {
        appContext.setApplicationNumber(FormatApplicationNumber(appNumber));
    }
}

export function SetIsUserInWizardFlag(appContext: AppContextInterface) {
    // set flag to store we are now in the wizard to change the menu shown
    if (appContext !== undefined && appContext?.isUserInWizard === false) {
        appContext.setIsUserInWizard(true);
    }
}

export function ClearIsUserInWizardFlag(appContext: AppContextInterface) {
    // set flag to store we are now in the wizard to change the menu shown
    if (appContext !== undefined && appContext?.isUserInWizard === true) {
        appContext.setIsUserInWizard(false);
    }
}

export function IsUserInWizard(appContext: AppContextInterface) {
    return appContext.isUserInWizard;
}

export function CreateNewApplication(dispatch: any, authService: AuthService, application: IApplication) {
    const appActions = new ApplicationActions(authService);

    dispatch(appActions.CreateApplication(application));
}

export function IsWizardFormDirty(appContext: AppContextInterface) {
    return appContext?.wizardState.formIsDirty;
}

export function HideSaveChangesDialog(appContext: AppContextInterface) {
    appContext.dispatchWizardState({ type: WizardStateActionTypeEnums.hideSaveChangesDialog });
}

export function ShowSaveChangesDialog(appContext: AppContextInterface) {
    appContext.dispatchWizardState({ type: WizardStateActionTypeEnums.showSaveChangesDialog });
}

export function HideDiscardChangesDialog(appContext: AppContextInterface) {
    appContext.dispatchWizardState({ type: WizardStateActionTypeEnums.hideDiscardChangesDialog });
}

export function ShowDiscardChangesDialog(appContext: AppContextInterface) {
    appContext.dispatchWizardState({ type: WizardStateActionTypeEnums.showDiscardChangesDialog });
}

export function HideResubmitDiscardChangesDialog(appContext: AppContextInterface) {
    appContext.dispatchWizardState({ type: WizardStateActionTypeEnums.hideResubmitDiscardChangesDialog });
}

export function GoingBackwardsAndDiscarding(appContext: AppContextInterface) {
    appContext.dispatchWizardState({ type: WizardStateActionTypeEnums.goingBackwardsAndDiscarding });
}

export function ShowResubmitDiscardChangesDialog(appContext: AppContextInterface) {
    appContext.dispatchWizardState({ type: WizardStateActionTypeEnums.showResubmitDiscardChangesDialog });
}

export function SetDestinationNavActionOnSuccess(appContext: AppContextInterface, navigationDestination: NavigationDestination) {
    appContext?.setDestinationNavActionOnSuccess(navigationDestination);
}

export function SetValidationSchema(appContext: AppContextInterface, validationSchema: any | undefined) {
    appContext?.dispatchWizardState({ type: WizardStateActionTypeEnums.setValidationSchema, validationSchema });
}

export function SetWizardMode(appContext: AppContextInterface, wizardMode: WizardMode) {
    appContext?.dispatchWizardState({ type: WizardStateActionTypeEnums.setWizardMode, wizardMode });
}

export function SetCurrentComplaintsMechId(appContext: AppContextInterface, id: string) {
    appContext?.setCurrentComplaintsMechId(id);
}
