// Manage Public Details page
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';
import { IApplication, InquiryDefaultObj, IUser } from '../../api/interfacesApi';
import AuthService from '../../services/AuthService';
import ApplicationActions from '../../actions/applicationActions';
import UserActions from '../../actions/userActions';
import * as types from '../../actions/actionTypes';
import { toastErrorMessage, toastSuccessMessage } from '../../actions/toastrMessages';
import { AjaxCallStateForPage } from '../PageInterfaces';
import RegistrationApplicationForm from '../Registration/RegistrationApplicationForm';
import { AjaxCallStateEnum, NavigationDestination } from '../../services/Enums';
import { VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../services/Validation';
import {
    HEADER_MANAGE_PUBLICDETAILS,
    MODALBUTTON1_YES,
    MODALBUTTON2_NO,
    MODALHEADER_DELETE_OUTLET,
    MODALTEXT_DELETE_OUTLET,
    MODALTEXT_DELETE_OUTLET_P2,
} from '../../services/Constants';
import { Sleep } from '../../utils/Common';
import { ApplicationIsLoaded } from '../../utils/AppUtils';
import PageTitle from '../../components/pageTitle';
import { EmptyRow } from '../../controls/EmptyRow';
import Loader from '../../controls/Loader';
import PageWrapper from '../../components/pageWrapper';
import { NavigateAway } from '../../components/wizard/wizardStepFunctions';
import AppContext, { AppContextInterface } from '../../stateManagement/context/AppContext';
import { SetDestinationNavActionOnSuccess } from 'components/wizard/wizardStateManager';

import LabelField from 'components/fields/LabelField';
import { ModalDialogConfirmable } from 'controls/ModalDialogConfirmable';

interface ManagePublicDetailsPageProps {
    authService: AuthService;
}

const ManagePublicDetailsPage = (props: ManagePublicDetailsPageProps) => {
    const { authService } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const formRef = useRef();
    const appContext = useContext<AppContextInterface>(AppContext);
    const userActions = useMemo(() => {
        return new UserActions(authService);
    }, [authService]);
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const user: IUser = useSelector((stateUser: any) => stateUser.user);
    const application: IApplication = useSelector((stateApp: any) => stateApp.application);
    const ajaxCallsInProgress: types.ICallStatus = useSelector((stateAjax: any) => stateAjax.ajaxCallsInProgress);
    const initCallStateForPage: AjaxCallStateForPage = { ajaxCallState: AjaxCallStateEnum.NotStarted, pageNumber: 0 };
    const [ajaxCallStateForPage, setAjaxCallStateForPage] = useState(initCallStateForPage);
    const [backEndValidationErrors, setBackEndValidationErrors] = useState([]);
    const applicationForm = useRef(new RegistrationApplicationForm());
    const [inquiryDefObj, setInquiry] = useState<InquiryDefaultObj>(applicationForm.current.properties.InitialData);
    const [isDeletingOutlet, setIsDeletingOutlet] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        SetDestinationNavActionOnSuccess(appContext, NavigationDestination.Dashboard);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // get latest user details so we can map them to our inquiry object upon load of this page
    useEffect(() => {
        dispatch(userActions.GetUser());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(appActions.GetApplication());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        inquiryDefObj.inquiry.personalDetails = user;
        setInquiry(inquiryDefObj);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (isDataLoaded === false && ApplicationIsLoaded(application, application.applicationId)) {
            if (isDeletingOutlet === false && application.applicationId !== inquiryDefObj.inquiry.application.applicationId) {
                inquiryDefObj.inquiry.application = application;
                setInquiry(inquiryDefObj);

                Sleep(500).then(() => {
                    setIsDataLoaded(true);
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application]);

    useEffect(() => {
        if (ajaxCallsInProgress && ajaxCallsInProgress.callState) {
            setAjaxCallStateForPage({ ajaxCallState: ajaxCallsInProgress.callState, pageNumber: 0 });
        }

        if (ajaxCallsInProgress && ajaxCallsInProgress.callState === AjaxCallStateEnum.CompletedWithValidation) {
            setBackEndValidationErrors(ajaxCallsInProgress.errors);
        }

        // TODO: set Errors here:
    }, [ajaxCallsInProgress]);

    // check dispatch success state
    useEffect(() => {
        if (ajaxCallStateForPage) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                if (isDeletingOutlet === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK) {
                    setIsDeletingOutlet(false);

                    toastSuccessMessage('Your outlet details have been removed. Note that your name is still visible.');

                    dispatch(appActions.GetApplication());

                    Sleep(500).then(() => {
                        setIsDataLoaded(true);
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const handleClickPrevious = () => {
        const keepChanges = false;
        const isDirtyForcedFalse = false;
        const isValid = true;
        NavigateAway(appContext, history, keepChanges, isDirtyForcedFalse, isValid);
    };

    const submitForm = (data: any) => {
        history.push('/updateoutletdetails');
    };

    const handleRemoveOutlet = () => {
        setIsDeletingOutlet(true);
        dispatch(appActions.RemoveOutletThenPublish(inquiryDefObj.inquiry.application.applicationId));
    };

    const handleClickRemove = async (event: any) => {
        // selecting NO remains on page
        if (
            await ModalDialogConfirmable(MODALTEXT_DELETE_OUTLET, MODALBUTTON1_YES, MODALBUTTON2_NO, {
                title: MODALHEADER_DELETE_OUTLET,
                confirmationP2: MODALTEXT_DELETE_OUTLET_P2,
                isDestructive: true,
                modalBodyPositionTextLeft: false,
                dataTestId: 'confirmDiscard',
            })
        ) {
            // agreed to delete outlet
            handleRemoveOutlet();
        }
    };

    /* const handleEditConsentGender = () => {
        history.push('/editconsentgenderpage');
    }; */

    const handleEditOutletDetails = () => {
        history.push('/updateoutletdetails');
    };

    const pageContent = () => {
        /* Consent was moved to its own page to align back end API with simple edit page so no bugs or issues/problems 
          but business does NOT want it as a separate thing so it need to go back on the problemmatic Outlet UI which is already chock full of things going on...

                <Row>
                    <Col>
                        <LabelField
                            id='consentToPublishGenderLabelHeader'
                            displayName='Consent to display gender'
                            value='I consent to my gender appearing on the public register'
                            isMandatory={false}
                            textCapitalizeOn={false}
                            hideFinalEmptyRow={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <LabelField
                            id='consentToPublishGenderLabelValue'
                            displayName=''
                            value={
                                inquiryDefObj.inquiry.personalDetails.consentToPublishGenderOnRegister === true
                                    ? 'Yes'
                                    : inquiryDefObj.inquiry.personalDetails.consentToPublishGenderOnRegister === false
                                    ? 'No'
                                    : '(not set)'
                            }
                            isMandatory={false}
                            textCapitalizeOn={false}
                            hideFinalEmptyRow={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant='outline-secondary' onClick={handleEditConsentGender} data-testid='editConsentButton'>
                            Edit gender consent
                        </Button>
                    </Col>
                </Row>
                <EmptyRow />
                <EmptyRow />
        */

        return (
            <>
                <Row>
                    <Col>
                        <LabelField
                            id='manageOutletDetailsLabelHeader'
                            displayName='Manage outlet details'
                            value='To add or make changes to your public information, click the Edit button. To remove all of the public information about your FDR service, click the Remove button.'
                            isMandatory={false}
                            textCapitalizeOn={false}
                            hideFinalEmptyRow={true}
                        />
                    </Col>
                </Row>
                <EmptyRow />
                <Row>
                    <Col>
                        <Button variant='outline-secondary' onClick={handleEditOutletDetails} data-testid='editOutletDetailsButton'>
                            Edit outlet details
                        </Button>
                    </Col>
                    <Col>
                        <Button variant='outline-danger' onClick={handleClickRemove} data-testid='deleteOutletDetailsButton'>
                            Remove outlet details
                        </Button>
                    </Col>
                    <Col>&nbsp;</Col>
                    <Col>&nbsp;</Col>
                    <Col>&nbsp;</Col>
                </Row>
                <EmptyRow />
            </>
        );
    };

    // const errorOnSubmitForm = (errorsIn: any, event: any) => {
    //    toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
    // };

    if (isDataLoaded === false) {
        return <Loader isLoading={true} loaderText='Loading, please wait...' />;
    }

    return (
        <>
            <PageTitle title='Manage public details' description={HEADER_MANAGE_PUBLICDETAILS} />
            <PageWrapper
                pageName='ManagePublicDetails'
                includeSaveContinueButton={false}
                formRef={formRef}
                handleSubmit={submitForm}
                handleClickPrevious={handleClickPrevious}
            >
                <Loader isLoading={isDeletingOutlet === true} loaderText='Submitting your changes. Please wait...' />
                {isDeletingOutlet === false && pageContent()}
                <EmptyRow />
            </PageWrapper>
        </>
    );
};

export default ManagePublicDetailsPage;
