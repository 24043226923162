// Complaints mechanism-Employee page:
//  - contains a PageComponent, which holds the header, fields, actionFooter
//  - allows navigation back to the Complaints mechanism-Type page
//  - allows navigation forward to the Complaints mechanism-Member page
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { RegoApplicationPageProps } from '../../PageInterfaces';
import { EnsureData } from '../RegistrationApplicationWizard/wizardHelperFunctions';
import { ResetComplaintsMechanismCollection } from '../RegistrationApplicationForm';
import { GetTextForSaveAndContinueButton, HideTheBackButton, SubmitThisPageIfActive } from '../../PageFunctions';
import { MoveWizardForward, MoveWizardForwardForResubmit } from '../../../components/wizard/wizardStepFunctions';
import { ErrorOnSubmitForm, IsValidGuidIdentifier } from '../../../utils/AppUtils';
import Guid from '../../../services/Guid';
import { AjaxCallStateEnum, ComplaintsProcessType, RelationshipToEmployerType, WizardMode, WizardStepNumber } from '../../../services/Enums';
import { CLEANINGUP_PRIORDATA, HEADER_COMPLAINTS, NOTE_COMPLAINTS_TYPEOFCOMPLAINTS_DEFERMESSAGE } from '../../../services/Constants';
import { VALIDATION_ERROR_POPUP_MESSAGE, VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../../services/Validation';
import { toastErrorMessage } from '../../../actions/toastrMessages';
import ApplicationActions from '../../../actions/applicationActions';
import AttachmentActions from '../../../actions/attachmentsActions';
import OutletApi from '../../../api/outletApi';
import { IAttachmentMetadata, IOutlet } from '../../../api/interfacesApi';
import ErrorSummary from '../../../controls/ErrorSummary';
import NoteText from '../../../controls/NoteText';
import Loader from '../../../controls/Loader';
import { EmptyRow } from '../../../controls/EmptyRow';
import AppContext from '../../../stateManagement/context/AppContext';
import PageWrapper from '../../../components/pageWrapper';
import PageTitle from '../../../components/pageTitle';
import PageMandatoryLabelText from '../../../components/fields/PageMandatoryLabelText';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import { SelectListOption } from '../../../components/fields/SelectListField';
import FundedOrgComponent from './FundedOrgComponent';
import {
    InsertAttachmentToLocalInquiryObject,
    RemoveAttachmentFromLocalInquiryObject,
    RemovePriorAttachmentsForOrgNameOrRelationshipToEmpChanged,
    RemovePriorDataRelationshipChanged,
} from '../../../utils/ComplaintsUtils';

const ComplaintsMechanismEmployeePage = (props: RegoApplicationPageProps) => {
    const {
        inquiry,
        setInquiryObj,
        // attachments,
        goingBackwards,
        authService,
        dispatch,
        control,
        handleClickPrevious,
        handleSubmit,
        register,
        // unregister,
        watch,
        setValue,
        setError,
        errors,
        // clearErrors,
        trigger,
        isValid,
        isActive,
        ajaxCallStateForPage,
        backEndValidationErrors,
        triggerSubmit,
        setTriggerSubmit,
        reset,
    } = props;

    const appContext = useContext(AppContext);
    // const isResubmit = appContext.wizardState.wizardMode === WizardMode.Resubmit ? true : false;
    const formRef = useRef();
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const outletApi = useMemo(() => {
        return new OutletApi(authService);
    }, [authService]);
    const attachmentActions = useMemo(() => {
        return new AttachmentActions(authService);
    }, [authService]);
    // even though we have redux states, we HAVE to capture the attempt due to navigation and Get and Save user calls on the page
    const [attemptingSave, setAttemptingSave] = useState(false);
    const [attemptingSaveThenRemovePriorData, setAttemptingSaveThenRemovePriorData] = useState(false);
    const [dataInquiry, setDataInquiry] = useState(inquiry);
    const [attemptingSaveApp, setAttemptingSaveApp] = useState(false);
    const [outletsSelectList, setOutletsSelectList] = useState<SelectListOption[]>([]);

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors.errors?.length > 0) {
            backEndValidationErrors.errors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'Outlet':
                        setError('inquiry.application.outletId', { type: 'manual', message: validationError.errorMessage });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    // check dispatch success state
    useEffect(() => {
        if (isActive === true && ajaxCallStateForPage && ajaxCallStateForPage.pageNumber === WizardStepNumber.ComplaintsMechanismEmployee) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // ONLY time we do not show these is if in the middle of switching between options AND user has changed mind to delete an option
                if (attemptingSaveThenRemovePriorData !== true) {
                    // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                    setAttemptingSave(false);
                    setAttemptingSaveApp(false);
                    displayBackEndValidations();
                }
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setAttemptingSave(false);
                setAttemptingSaveApp(false);
                setAttemptingSaveThenRemovePriorData(false);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (attemptingSave === true && ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK && !appContext.wizardState.triggerSubmit) {
                    // IF VALID (or OK to move forward):
                    setAttemptingSave(false);
                    setAttemptingSaveThenRemovePriorData(false);

                    setAttemptingSaveApp(true);
                    // so we can set the current application step (use stored data object so we update the currentStep)
                    const updateOutlet = false;
                    dispatch(appActions.UpdateApplication(dataInquiry!.application, updateOutlet));
                } else if (
                    attemptingSaveApp === true &&
                    ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK &&
                    !appContext.wizardState.triggerSubmit
                ) {
                    setAttemptingSaveApp(false);
                    // refresh data set now after changes saved
                    dispatch(appActions.GetApplication());

                    // Move wizard forward to next step pending whether we are navigating normally or for the resubmit flow
                    if (appContext.wizardState.wizardMode === WizardMode.New) {
                        MoveWizardForward(appContext, isValid);
                    } else {
                        MoveWizardForwardForResubmit(appContext, isValid);
                    }
                } else if (
                    attemptingSaveThenRemovePriorData === true &&
                    ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK &&
                    !appContext.wizardState.triggerSubmit
                ) {
                    // no need to remove any more data just refresh now -> dispatch(appActions.RemoveComplaintsData());

                    // Sleep(750).then(() => {
                    // refresh data set now after changes saved (this is when a user saves changes after deleting data once they changed their mind)
                    dispatch(appActions.GetApplication());
                    // });
                    setAttemptingSaveThenRemovePriorData(false);
                    setAttemptingSaveApp(false);
                    setAttemptingSave(false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    /* const isValidMechanismId = (mechanismId: string | null | undefined) => {
        if (mechanismId === undefined || mechanismId === null || mechanismId === '' || mechanismId.startsWith(UNINITIALISED)) {
            return false;
        } else {
            return true;
        }
    }; */

    const submitForm = (data: any) => {
        if (isValid) {
            // reset the dates as they are not applicable when profAssoc null
            if (
                data.inquiry.application.activeComplaintsMechanismObj &&
                data.inquiry.application.activeComplaintsMechanismObj.professionalAssociation?.professionalAssociationId === ''
            ) {
                data.inquiry.application.activeComplaintsMechanismObj.professionalAssociation = null;
                data.inquiry.application.activeComplaintsMechanismObj.membershipFromDate = undefined;
                data.inquiry.application.activeComplaintsMechanismObj.membershipToDate = undefined;
            }

            // if fundedOrg component not set this already set it now
            if (
                data.inquiry.application.activeComplaintsMechanismObj &&
                (data.inquiry.application.activeComplaintsMechanismObj.complaintsProcessType === undefined ||
                    data.inquiry.application.activeComplaintsMechanismObj.complaintsProcessType === null)
            ) {
                data.inquiry.application.activeComplaintsMechanismObj.complaintsProcessType = ComplaintsProcessType.GovernmentFundedFDRService;
            }

            // for ReSubmit: if the outlet has been selected via FundedOrgComponent it is not welded to the form properly we check now and update that
            if (appContext.wizardState.wizardMode === WizardMode.Resubmit) {
                if (
                    data.inquiry.application.activeComplaintsMechanismObj?.outlet?.outletId === undefined &&
                    inquiry.application.activeComplaintsMechanismObj?.outlet !== null &&
                    inquiry.application.activeComplaintsMechanismObj?.outlet.outletId !== ''
                ) {
                    data.inquiry.application.activeComplaintsMechanismObj.outlet.outletId = inquiry.application.activeComplaintsMechanismObj?.outlet.outletId;
                }
            }

            // set existing data now as we dont capture it in this page
            EnsureData(appContext, WizardStepNumber.ComplaintsMechanismEmployee, data, inquiry);
            // Save changes to application (attempt save, if that fails we do NOT move the Wizard step)
            setAttemptingSave(true);

            setDataInquiry(data.inquiry);

            // determine if a new complaints mech or update existing
            if (
                data.inquiry.application.complaintsMechanism.length === 0 &&
                data.inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId === Guid.empty
            ) {
                dispatch(appActions.AddComplaintsMechanism(data.inquiry.application.activeComplaintsMechanismObj));
            } else {
                dispatch(appActions.UpdateComplaintsMechanism(data.inquiry.application.activeComplaintsMechanismObj));
            }
            // dispatch(appActions.UpdateApplication(data.inquiry.application));
        } else {
            // else if (!HasFileUploadErrors(data)) {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    SubmitThisPageIfActive(isActive, triggerSubmit, formRef, setTriggerSubmit);

    // initial load of any existing data
    useEffect(() => {
        if (inquiry.application?.complaintsMechanism?.length === 0) {
            // the UI is mapped so a new object is created however it is not defaulted properly so we do that first here
            ResetComplaintsMechanismCollection(true, setValue, inquiry);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // initial load of any existing data - outlet list
    useEffect(() => {
        outletApi
            .getAllOutlets()
            .then((response: any) => {
                // construct a list including empty first item which we can validate against
                const outlets = response as IOutlet[];
                const newOutlets: SelectListOption[] = [];
                newOutlets.push({ value: '', text: '' });
                outlets.map(dataIn => newOutlets.push({ value: dataIn.outletId, text: dataIn.name, type: dataIn.organisationFundingType?.toString() }));

                setOutletsSelectList(newOutlets);
            })
            .catch((error: any) => {
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removePriorDataRelationshipChanged = (relationshipToEmployerChangedTo: RelationshipToEmployerType) => {
        RemovePriorDataRelationshipChanged(attachmentActions, dispatch, inquiry, setInquiryObj, relationshipToEmployerChangedTo);

        // reset would make the values update for the useForm functionality (needed here for if user changes mind and we remove all existing file uploaded)
        // reset(inquiry);
        // reset({ application: inquiry.application }, { keepValues: true });
        // reset(inquiry, { keepValues: true });
        reset();

        // trigger validation after refresh
        trigger();
    };

    // based upon the original value, we remove data as the user has changed their option whilst the data already exists
    const removePriorDataOrgNameChanged = () => {
        // change of org name selected means knock everything out and start over
        // remove both the UI-only object and the array-based object, as well as any attachments
        setAttemptingSaveThenRemovePriorData(true);
        RemovePriorAttachmentsForOrgNameOrRelationshipToEmpChanged(attachmentActions, dispatch, inquiry);

        /* copied from Edit CM - not sure if needed:
        if (inquiry.application.activeComplaintsMechanismObj) {
            inquiry.application.activeComplaintsMechanismObj.activeAttachmentId = '';
            inquiry.application.activeComplaintsMechanismObj.evidence = [];
            inquiry.application.activeComplaintsMechanismObj.attachmentExists = '';
        }
        // must refresh inquiry obj for attachment collection and active CM
        setInquiryObj(inquiry);

        // copy values into active index in CM collection before update
        CopyFromActiveToComplaintCollection(inquiry.application);
        */

        // MUST reset complaintsMechanism collection to remove all GovernmentFundedFDRService type
        ResetComplaintsMechanismCollection(true, setValue, inquiry); // leave index=0

        // reset form data
        // reset();

        // finally set most active step otherwise user can swap between insurance with/without and resume function will fail
        inquiry.application.currentApplicationStep = WizardStepNumber.ComplaintsMechanismEmployee;

        const updateOutlet = false;
        dispatch(appActions.UpdateApplication(inquiry.application, updateOutlet));
    };

    const removedFile = (attachmentId: string) => {
        // refresh CM object/active obj
        RemoveAttachmentFromLocalInquiryObject(attachmentId, setValue, inquiry);
        trigger(); // re-validate
    };

    const addedFile = (newAttachment: IAttachmentMetadata) => {
        // refresh CM object/active obj (NOTE: CANNOT get App in case user simply adds/removes files, it will clear the form!)
        InsertAttachmentToLocalInquiryObject(newAttachment, setValue, inquiry);
        trigger(); // re-validate
    };

    const showDeferNote = () => {
        return (
            <Row>
                <Col md='11'>
                    <NoteText displayText={NOTE_COMPLAINTS_TYPEOFCOMPLAINTS_DEFERMESSAGE} />
                </Col>
                <Col md='1' className='justify-content-end' />
            </Row>
        );
    };

    // just use index=0 as we only allow 1 during initial application
    // const complaint = inquiry.application.complaintsMechanism && inquiry.application.activeComplaintsMechanismObj;
    const showFundedOrgComponent =
        inquiry.application.activeComplaintsMechanismObj &&
        IsValidGuidIdentifier(inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId) &&
        inquiry.application.activeComplaintsMechanismObj.complaintsProcessType !== ComplaintsProcessType.ProfessionalAssociationMembership;
    const fieldName = 'inquiry.application.activeComplaintsMechanismObj';

    return (
        <>
            <PageTitle title='Complaints mechanism' description={HEADER_COMPLAINTS} />
            <PageWrapper
                pageName='ComplaintsEmployee'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                handleClickPrevious={handleClickPrevious}
                hideBackButton={HideTheBackButton(appContext)}
                textForSaveContinueButton={GetTextForSaveAndContinueButton(appContext)}
            >
                <PageFieldsTitle title='Complaints mechanism - Employee or panel member' />
                <PageMandatoryLabelText />
                <ErrorSummary errors={errors} />
                {/* user may change mind and remove old data, so system needs time to process the change and prevent interaction */}
                {attemptingSaveThenRemovePriorData === true && isActive === true && <Loader isLoading={true} loaderText={CLEANINGUP_PRIORDATA} />}
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.applicationId'
                    {...register('inquiry.application.applicationId')}
                    defaultValue={inquiry.application.applicationId}
                />
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType'
                    {...register('inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType')}
                    defaultValue={inquiry.application?.activeComplaintsMechanismObj?.complaintsMechanismType}
                />
                {showFundedOrgComponent !== true && isActive === true && <Loader isLoading={true} loaderText='Loading, please wait...' />}
                {showFundedOrgComponent === true && (
                    // {[0].map(index => {
                    // {[0, 1, 2, 3, 4].map(index => {
                    // we reverse sort the Membership record so it is always in index 5 if exists
                    // const complaint = inquiry.application.complaintsMechanism[index];

                    // ignore if user had saved a membership now coming back to this UI (fail safe check)
                    // if (complaint && complaint.complaintsProcessType !== ComplaintsProcessType.ProfessionalAssociationMembership) {
                    // const fieldName = `inquiry.application.complaintsMechanism[${index}]`;

                    // return (
                    <fieldset name={fieldName} key={fieldName}>
                        <legend className='sr-only'>Select Organisation and upload evidence</legend>
                        <FundedOrgComponent
                            isInitialApplication={true}
                            key={fieldName}
                            // index={0}
                            dispatch={dispatch}
                            // fieldName={fieldName}
                            outletsSelectList={outletsSelectList}
                            register={register}
                            watch={watch}
                            authService={authService}
                            errors={errors}
                            inquiry={inquiry}
                            setInquiry={setInquiryObj}
                            // clearErrors={clearErrors}
                            control={control}
                            trigger={trigger}
                            setError={setError}
                            goingBackwards={goingBackwards}
                            setValue={setValue}
                            isVisible={true}
                            removePriorDataRelationshipChanged={removePriorDataRelationshipChanged}
                            removePriorDataOrgNameChanged={removePriorDataOrgNameChanged}
                            removedFile={removedFile}
                            addedFile={addedFile}
                        />
                        <EmptyRow />
                        {showDeferNote()}
                    </fieldset>
                    // );
                    // } else {
                    //    return null;
                    // }
                    // })}
                )}
            </PageWrapper>
        </>
    );
};

export default ComplaintsMechanismEmployeePage;
