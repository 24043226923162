// Suspend Accreditation page
import React from 'react';
import AuthService from '../../../services/AuthService';
import { LINK_AG_FDR } from '../../../services/Constants';
import NavLink from '../../../controls/NavLink';
import PageTitle from '../../../components/pageTitle';
import NavLinkEmailPau from '../../../components/fields/NavLinkEmailPau';

interface SuspendAccreditationPageProps {
    authService: AuthService;
}

const LiftSuspensionPage = (props: SuspendAccreditationPageProps) => {
    const deptWebsiteLink = () => {
        return <NavLink hrefLink={LINK_AG_FDR} openInNewTab={true} displayText='www.ag.gov.au/families-and-marriage/families/family-dispute-resolution/information-family-dispute-resolution-practitioners' dataTestId='SiteFDRLink' />;
    };

    return (
        <>
            <PageTitle title='Lifting the suspension on your accreditation' description='' />
            <h2>Requirements to Lift Suspension</h2>
            <p>
                As the department's Practitioner Accreditation Unit (the Unit) does not have power to end your suspension with retrospective effect, you must
                provide written advice of your intention to recommence FDR services with the evidence specified below, in advance of recommencing work as an FDR
                practitioner, by email to: <NavLinkEmailPau />
            </p>
            <p>
                To ensure you meet the accreditation criteria under the Family Law (Family Dispute Resolution Practitioners) Regulations 2008 at the time of
                recommencement, you will need to provide:
            </p>
            <ul>
                <li>confirmation that your contact details are up-to-date in your FDR account</li>
                <li>a current National Police Check (not older than 4 months)</li>
                <li>a current Working with Children Check (if applicable)</li>
                <li>evidence of your current complaints mechanism, and</li>
                <li>
                    evidence of your professional indemnity insurance to provide FDR services through a private outlet or a panel of an organisation funded by
                    the Government to provide FDR services.
                </li>
            </ul>
            <p>
                If you were previously accredited on the basis of having accreditation under the National Mediator Accreditation System (NMAS), you will also
                need to provide evidence of current NMAS accreditation.
            </p>
            <p>
                The department's website ({deptWebsiteLink()}) is kept up to date with requirements to be an FDR practitioner so you are encouraged to check
                that you meet these at the time you wish to have your accreditation reinstated.
            </p>
            <p>
                When the Unit receives your written advice that you intend to recommence work as an FDR practitioner as well as the evidence specified above, an
                Authorised Officer will provide written confirmation of the cessation of your suspension by email.
            </p>
        </>
    );
};

export default LiftSuspensionPage;
