import 'whatwg-fetch';
import AuthService from '../services/AuthService';
import { IsJsonString } from '../utils/Common';
import { AuthenticationResult } from '@azure/msal-browser';
import AGWebValidationError from './AGWebValidationError';
import { VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../services/Validation';
import AGWebTimeoutError from './AGWebTimeoutError';

class ApiService {
    authService: AuthService;

    constructor(authService: AuthService) {
        this.authService = authService;
    }

    setRequest = (path: string, methodVerb: string, reqContent: object | null): Request => {
        const api = '/';
        return new Request(api + path, {
            method: methodVerb,
            mode: 'cors',
            body: reqContent == null ? undefined : JSON.stringify(reqContent),
            headers: new Headers({
                'Content-Type': 'application/json;charset=utf-8',
            }),
        });
    };

    // from: https://stackoverflow.com/questions/46946380/fetch-api-request-timeout/57888548#57888548
    // If the timeout is reached before the resource is fetched then the fetch is aborted.
    // If the resource is fetched before the timeout is reached then the timeout is cleared.
    // If the input signal is aborted then the fetch is aborted and the timeout is cleared.
    fetchTimeout = (request: Request, ms: number | undefined, { ...options } = {}) => {
        const controller = new AbortController();
        const signal = controller.signal;

        const promise = fetch(request, { signal, ...options });
        if (signal && signal !== null) {
            // tslint:disable-next-line: no-unused-expression
            signal?.addEventListener('abort', () => controller.abort());
        }
        const timeout = setTimeout(() => controller.abort(), ms);
        return promise.finally(() => clearTimeout(timeout));
    };

    // e.g. document.querySelector("button.cancel").addEventListener("click", () => controller.abort());
    async makeFetchWithConfigurableTimeoutRequest(request: Request) {
        // convert configurable value to number
        const largeRequestTimeout: number = window.__RUNTIME_CONFIG__.REACT_APP_LARGE_REQUEST_TIMEOUT
            ? Number(window.__RUNTIME_CONFIG__.REACT_APP_LARGE_REQUEST_TIMEOUT)
            : 600000;
        // see: https://stackoverflow.com/questions/46946380/fetch-api-request-timeout/57888548#57888548
        const controller = new AbortController();
        // 600 second timeout:
        const timeoutId = setTimeout(() => controller.abort(), largeRequestTimeout);

        return this.authService
            .GetToken()
            .then((response: AuthenticationResult) => {
                request.headers.append('Authorization', 'Bearer ' + response.accessToken);
                return fetch(request, { credentials: 'include', signal: controller.signal })
                    .then((res: Response) => {
                        // If you only wanted to timeout the request, not the response, add:
                        clearTimeout(timeoutId);

                        // there is no object returned just an OK
                        if (res.ok) {
                            return res;
                        } else {
                            if (IsJsonString(res)) {
                                return res.json().then((err: any) => {
                                    throw err;
                                });
                            } else {
                                return res.text().then((err: any) => {
                                    // if we get IIS "System.Threading.Tasks.TaskCanceledException: The request was canceled due to the configured HttpClient.Timeout..."
                                    //  error then we can handle that a little more gracefully.
                                    if (err.includes('HttpClient.Timeout')) {
                                        throw Error('AbortError'); // caught higher up the chain and dealt with
                                    }
                                    throw err;
                                });
                            }
                        }
                    })
                    .then(result => {
                        return result;
                    })
                    .catch((error: any) => {
                        if (error.name === 'AbortError' || error.message === 'AbortError') {
                            // fetch aborted either due to timeout or due to user clicking the cancel button
                            const timeoutError = new AGWebTimeoutError();
                            timeoutError.setErrors(error);
                            throw timeoutError;
                        } else {
                            // network error or other server error
                            throw Error(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        }
                    });
            })
            .catch((error: any) => {
                if (error instanceof AGWebValidationError) {
                    throw error;
                }
                if (error instanceof AGWebTimeoutError) {
                    throw error;
                }
                throw Error('An error has occurred: ' + error);
            });
    }

    // Common - get response in json
    async makeFetchRequest<T>(request: Request): Promise<T> {
        return this.authService
            .GetToken()
            .then((response: AuthenticationResult) => {
                request.headers.append('Authorization', 'Bearer ' + response.accessToken);
                return fetch(request, { credentials: 'include' })
                    .then((res: Response) => {
                        if (res.ok) {
                            return res.json().then(j => {
                                return j as T;
                            });
                        } else {
                            // server-side validation error returned
                            if (res.status === 422) {
                                return res.json().then((err: any) => {
                                    if (err?.isValid === false) {
                                        const validationError = new AGWebValidationError();
                                        validationError.setIsValid(err.isValid);
                                        validationError.setMessage(err.message);
                                        validationError.setErrors(err.errors);
                                        throw validationError;
                                    }
                                    throw err;
                                });
                            } else {
                                if (IsJsonString(res)) {
                                    return res.json().then((err: any) => {
                                        throw err;
                                    });
                                } else {
                                    // server side error which is logged there
                                    throw Error(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                                }
                            }
                        }
                    })
                    .then(result => {
                        return result;
                    })
                    .catch((error: any) => {
                        throw error;
                    });
            })
            .catch((error: any) => {
                if (error instanceof AGWebValidationError) {
                    throw error;
                }
                if (error instanceof Error) {
                    throw Error(error.toString());
                }
                throw Error('An error has occurred: ' + error);
            });
    }

    async makeFetchDeleteRequest(request: Request) {
        return this.authService
            .GetToken()
            .then((response: AuthenticationResult) => {
                request.headers.append('Authorization', 'Bearer ' + response.accessToken);
                return fetch(request, { credentials: 'include' })
                    .then((res: Response) => {
                        if (res.ok) {
                            if (IsJsonString(res)) {
                                return res.json();
                            }
                            return res;
                        } else {
                            // server-side validation error returned
                            if (IsJsonString(res)) {
                                return res.json().then((err: any) => {
                                    throw err;
                                });
                            } else {
                                // server side error which is logged there
                                throw Error(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                            }
                        }
                    })
                    .then(result => {
                        return result;
                    })
                    .catch((error: any) => {
                        throw error;
                    });
            })
            .catch((error: any) => {
                throw Error('An error has occurred: ' + error);
            });
    }

    async makeFetchDeleteRequestWithType<T>(request: Request) {
        return this.authService
            .GetToken()
            .then((response: AuthenticationResult) => {
                request.headers.append('Authorization', 'Bearer ' + response.accessToken);
                return fetch(request, { credentials: 'include' })
                    .then((res: Response) => {
                        if (res.ok) {
                            return res.json().then(j => {
                                return j as T;
                            });
                        } else {
                            // server-side validation error returned
                            if (IsJsonString(res)) {
                                return res.json().then((err: any) => {
                                    throw err;
                                });
                            } else {
                                // server side error which is logged there
                                throw Error(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                            }
                        }
                    })
                    .then(result => {
                        return result;
                    })
                    .catch((error: any) => {
                        throw error;
                    });
            })
            .catch((error: any) => {
                throw Error('An error has occurred: ' + error);
            });
    }

    makeStandardGetRequest = (endpoint: string) => {
        const request = new Request(endpoint, {
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                'Content-Type': 'application/json;charset=utf-8',
            }),
        });
        return this.makeFetchRequest(request);
    };

    makeStandardDeleteRequest(endpoint: string) {
        const request = new Request(endpoint, {
            method: 'DELETE',
            mode: 'cors',
            headers: new Headers({
                'Content-Type': 'application/json;charset=utf-8',
            }),
        });
        return this.makeFetchDeleteRequest(request);
    }

    makeStandardDeleteRequestWithType<T>(endpoint: string) {
        const request = new Request(endpoint, {
            method: 'DELETE',
            mode: 'cors',
            headers: new Headers({
                'Content-Type': 'application/json;charset=utf-8',
            }),
        });
        return this.makeFetchDeleteRequestWithType<T>(request);
    }

    makeStandardPostRequest<T>(endpoint: string, body: object) {
        const request = new Request(endpoint, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(body),
            headers: new Headers({
                'Content-Type': 'application/json;charset=utf-8',
            }),
        });
        return this.makeFetchRequest<T>(request);
    }

    makeStandardPutRequest<T>(endpoint: string, body: object) {
        const request = new Request(endpoint, {
            method: 'PUT',
            mode: 'cors',
            body: JSON.stringify(body),
            headers: new Headers({
                'Content-Type': 'application/json;charset=utf-8',
            }),
        });
        return this.makeFetchRequest<T>(request);
    }

    makeConfigurableTimeoutPutRequest(endpoint: string, body: object) {
        const request = new Request(endpoint, {
            method: 'PUT',
            mode: 'cors',
            body: JSON.stringify(body),
            headers: new Headers({
                'Content-Type': 'application/json;charset=utf-8',
            }),
        });
        return this.makeFetchWithConfigurableTimeoutRequest(request);
    }
}

export default ApiService;
