import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Inquiry } from '../../../api/interfacesApi';
import { WizardStepNumber } from '../../../services/Enums';
import { DisplayBool, IsObjectEmpty } from '../../../utils/Common';
import { formatDateForDisplay } from '../../../utils/Dates';
import LabelField from '../../../components/fields/LabelField';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import { EmptyRow } from '../../../controls/EmptyRow';
import FileUploadDisplay from './fileUploadDisplay';

interface ConsentDisplayProps {
    inquiry: Inquiry;
    isInitialApplication: boolean;
    showDateOnFileList: boolean;
}

const ConsentDisplay = (props: ConsentDisplayProps) => {
    const { inquiry, isInitialApplication, showDateOnFileList } = props;

    const today = new Date();
    const submittedDate = formatDateForDisplay(today);

    // could be undefined objects
    const userChangesDoNotExist = (IsObjectEmpty(inquiry.differencesUser) || IsObjectEmpty(inquiry.differencesUser?.differences)) ?? true;

    // we use the original name values not the potential changed name values as those new values are not (yet) approved!
    const preferredNameValueToUse =
        userChangesDoNotExist === true ? inquiry.personalDetails?.preferredName : inquiry.differencesUser?.originalVersion.preferredName;
    const preferredName =
        preferredNameValueToUse !== undefined && preferredNameValueToUse !== null && preferredNameValueToUse !== '' ? `(${preferredNameValueToUse}) ` : '';
    const firstNameToUse = userChangesDoNotExist === true ? inquiry.personalDetails?.firstName : inquiry.differencesUser?.originalVersion.firstName;
    const familyNameToUse = userChangesDoNotExist === true ? inquiry.personalDetails?.familyName : inquiry.differencesUser?.originalVersion.familyName;
    const applicantName = firstNameToUse + ' ' + preferredName + familyNameToUse;

    const pretextInitApp = `By submitting an Application for Accreditation as an FDR practitioner on ${submittedDate} the applicant, ${applicantName} has made the following declaration:`;
    const pretextAppChanges = `By uploading and submitting changes to name or date of birth, or by uploading and submitting changes to complaint/s and insurance, FDR practitioner, ${applicantName} has consented to the Practitioner Accreditation Unit verifying any information, documents or other matters they have provided to support the changes made to their details.`;

    return (
        <>
            <PageFieldsTitle
                title='Declaration and consent'
                showLinkToPage={false}
                wizardStepNumber={WizardStepNumber.DeclarationAndConsent}
                smallerHeader={true}
                underlineTitle={true}
            />
            {isInitialApplication === true && (
                <Row>
                    <Col>
                        <LabelField id='reviewDeclarationAndConsentHeadingText' displayName='' guidanceText='' value={pretextInitApp} isMandatory={false} />
                    </Col>
                </Row>
            )}
            {isInitialApplication === false && (
                <Row>
                    <Col>
                        <LabelField id='reviewDeclarationAndConsentHeadingText' displayName='' guidanceText='' value={pretextAppChanges} isMandatory={false} />
                    </Col>
                </Row>
            )}
            {isInitialApplication === true && (
                <>
                    <EmptyRow />
                    <Row>
                        <Col>
                            <LabelField
                                id='reviewDeclarationAndConsent'
                                displayName='Declaration and consent'
                                guidanceText='I consent to the Practitioner Accreditation Unit verifying any information, documents or other matters I provide in, with, or to support, my Application for Accreditation as a Family Dispute Resolution Practitioner.'
                                value={DisplayBool(inquiry.application.hasDeclaredAndConsented)}
                                isMandatory={false}
                                isTitleHtag={true}
                            />
                        </Col>
                    </Row>
                </>
            )}
            <EmptyRow />
            <FileUploadDisplay
                inquiry={inquiry}
                overrideToShowAllDocumentTypes={true}
                title='Files uploaded as evidence towards meeting the accreditation criteria:'
                formatForPDF={true}
                showDateOnFileList={showDateOnFileList}
            />
        </>
    );
};

export default ConsentDisplay;
