import { EMPTY_DATE } from '../../../services/Constants';
import { Inquiry } from '../../../api/interfacesApi';
import { AttachmentDocumentType, QualificationOrNMASDetailsType, QualificationType } from '../../../services/Enums';
import { IsAnyAttachmentsExistOfThisType } from '../../../utils/AppUtils';

export const SetQualificationTypeRadioButton = (qualification: QualificationType) => {
    // must check one then!
    const radioButtons: NodeListOf<HTMLElement> = document.getElementsByName('inquiry.application.qualification');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < radioButtons.length; i++) {
        const radioButton: HTMLInputElement = radioButtons[i] as HTMLInputElement;
        if (radioButton.value === qualification) {
            radioButton.checked = true;
        }
    }
};

export const SetQualOrNmasTypeRadioButton = (qualificationOrNMASDetailsType: QualificationOrNMASDetailsType) => {
    // must check one then!
    const radioButtons: NodeListOf<HTMLElement> = document.getElementsByName('inquiry.application.qualificationOrNMASDetails');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < radioButtons.length; i++) {
        const radioButton: HTMLInputElement = radioButtons[i] as HTMLInputElement;
        if (radioButton.value === qualificationOrNMASDetailsType) {
            radioButton.checked = true;
        }
    }
};

export const DoesPriorDataExistThatWillBeDeletedForQuals = (inquiry: Inquiry, newValue: QualificationType) => {
    // check this only if:
    //  - orig value is Full Grad OR file exists (as a new entry will place qual as NULL), then new value is any other
    //  - orig value is Six Core Units AND either file exists or Qual/NMAS is selected (or both), then new value is any other
    //  - orig value is Three Units AND file exists, then new value is any other
    const isOrigValueFullGradAndDataExists =
        inquiry.application.qualification === QualificationType.VocationalGradDipOrEquivalent ||
        IsAnyAttachmentsExistOfThisType(AttachmentDocumentType.FullGraduateDiplomaOfFDR, inquiry.application.attachments);
    const isOrigValueSixUnitsAndDataExists =
        inquiry.application.qualification === QualificationType.QualificationOrNMAS ||
        IsAnyAttachmentsExistOfThisType(AttachmentDocumentType.SixUnits, inquiry.application.attachments) ||
        inquiry.application.qualificationOrNMASDetails !== null;
    const isOrigValueThreeUnitsAndDataExists =
        inquiry.application.qualification === QualificationType.ThreeSpecifiedUnits ||
        IsAnyAttachmentsExistOfThisType(AttachmentDocumentType.ThreeUnits, inquiry.application.attachments);

    if (
        (newValue === QualificationType.VocationalGradDipOrEquivalent && (isOrigValueSixUnitsAndDataExists || isOrigValueThreeUnitsAndDataExists)) ||
        (newValue === QualificationType.QualificationOrNMAS && (isOrigValueFullGradAndDataExists || isOrigValueThreeUnitsAndDataExists)) ||
        (newValue === QualificationType.ThreeSpecifiedUnits && (isOrigValueFullGradAndDataExists || isOrigValueSixUnitsAndDataExists))
    ) {
        return true;
    }

    return false;
};

export const DoesPriorDataExistThatWillBeDeletedForSixUnits = (inquiry: Inquiry, newValue: QualificationOrNMASDetailsType) => {
    // check this only if:
    //  - orig value is SixUnit+NMAS OR file exists and/or NMAS Expiry Date set, then new value is SixUnit+Qualification
    //  - orig value is SixUnit+Qualification OR file exists, then new value is SixUnit+MAS
    const isNmasExpiryDateSet =
        inquiry.application.nmasExpiryDate !== undefined && inquiry.application.nmasExpiryDate !== null && inquiry.application.nmasExpiryDate !== EMPTY_DATE;
    const isOrigValueSixUnitAndNMASAndDataExists =
        inquiry.application.qualificationOrNMASDetails === QualificationOrNMASDetailsType.CurrentNMASAccreditation ||
        IsAnyAttachmentsExistOfThisType(AttachmentDocumentType.SixUnitsAndNMAS, inquiry.application.attachments) ||
        isNmasExpiryDateSet;
    const isOrigValueSixUnitAndQualAndDataExists =
        inquiry.application.qualificationOrNMASDetails === QualificationOrNMASDetailsType.AppropriateQualification ||
        IsAnyAttachmentsExistOfThisType(AttachmentDocumentType.SixUnitsAndQualification, inquiry.application.attachments);

    if (
        (newValue === QualificationOrNMASDetailsType.CurrentNMASAccreditation && isOrigValueSixUnitAndQualAndDataExists) ||
        (newValue === QualificationOrNMASDetailsType.AppropriateQualification && isOrigValueSixUnitAndNMASAndDataExists)
    ) {
        return true;
    }

    return false;
};

export const UnregisterAndClearAttachmentsValue = (unregister: any, setValue: any, docType: AttachmentDocumentType) => {
    switch (docType) {
        case AttachmentDocumentType.FullGraduateDiplomaOfFDR:
            unregister('inquiry.application.attachments.fullgraddiplomaevidence');
            setValue('inquiry.application.attachments.fullgraddiplomaevidence', null);
            break;
        case AttachmentDocumentType.SixUnits:
            unregister('inquiry.application.attachments.sixunitsevidence');
            setValue('inquiry.application.attachments.sixunitsevidence', null);
            break;
        case AttachmentDocumentType.SixUnitsAndQualification:
            unregister('inquiry.application.attachments.appropriatequalevidence');
            setValue('inquiry.application.attachments.appropriatequalevidence', null);
            break;
        case AttachmentDocumentType.SixUnitsAndNMAS:
            unregister('inquiry.application.attachments.nmasevidence');
            setValue('inquiry.application.attachments.nmasevidence', null);
            break;
        case AttachmentDocumentType.ThreeUnits:
            unregister('inquiry.application.attachments.threeunitsevidence');
            setValue('inquiry.application.attachments.threeunitsevidence', null);
            break;
    }
};
