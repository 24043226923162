import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import { AccreditationStatusEnum, ApplicationStatusEnum } from '../../services/Enums';
import { VALIDATION_ERROR_UNKNOWN_MESSAGE } from '../../services/Validation';
import AuthService from '../../services/AuthService';
import {
    MODALHEADER_DISCARD_APPLICATION,
    MODALTEXT_DISCARD_APPLICATION,
    MODALTEXT_DISCARD_APPLICATION_P2,
    MODALHEADER_WITHDRAW_APPLICATION_PROMPT,
    MODALTEXT_WITHDRAW_APPLICATION_PROMPT,
    MODALTEXT_WITHDRAW_APPLICATION_P2_PROMPT,
    BUTTON_DISABLEDACTION_APPLYFORACCRED_UPDATEAPP,
    BUTTON_DISABLEDACTION_MAINTAINACCRED_UPDATEOUTLET_ACCREDNOTFULLMEMBER,
    BUTTON_DISABLEDACTION_MAINTAINACCRED_UPDATEOUTLET_CONDITIONALLYACCRED,
    DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW,
    NOTE_MANAGEDETAILS_REWORK,
    MODALHEADER_CHANGEPRIMARYEMAIL,
    MODALTEXT_CHANGEPRIMARYEMAIL,
    BUTTON_DISABLEDACTION_CHANGESMADE_BUTNOTYETSUBMITTED,
    BUTTON_DISABLEDACTION_MAINTAINACCRED_SUSPENDED,
    BUTTON_DISABLEDACTION_MAINTAINACCRED_REQUEST_TO_SUSPENDED,
    DASHBOARD_NOTIFICATION_REQUESTCANCEL,
    DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER,
    DASHBOARD_NOTIFICATION_CHANGESUNDERMAINTENANCE,
} from '../../services/Constants';
import ModalDialog from '../../controls/ModalDialog';
import { Sleep } from '../../utils/Common';
import {
    HasOutletPractitionerPendingReview,
    AccreditedApplicationIsAwaitingReview,
    AccreditedApplicationIsActionRequired,
    OnClickUrlToNewTab,
    IsComplaintsEditedButNotYetSubmitted,
    IsPersonalDetailsEditedButNotYetSubmitted,
} from '../../utils/AppUtils';
import { IsAnyComplaintProfAssoc_FullMembership_WithInsurance } from '../../utils/ComplaintsUtils';
import { toastErrorMessage } from '../../actions/toastrMessages';
import ApplicationActions from '../../actions/applicationActions';
import ActionCard from './ActionCard';
import {
    addresscardIcon,
    buildingIcon,
    fileAltIcon,
    fileExcelIcon,
    fileExportIcon,
    fileIcon,
    // chartLineIcon,
    // moneybillIcon,
    // shieldIcon,
    usercircleIcon,
    // fileSignatureIcon,
    // fileArchiveIcon,
    emailIcon,
    banIcon,
    calendarTimesIcon,
    handIcon,
} from './DashboardIcons';
import { ModalDialogConfirmable } from '../../controls/ModalDialogConfirmable';
import { IApplication, IUser } from '../../api/interfacesApi';
import styles from './Dashboard.module.scss';

const { cardDeckContainer, cardDeckContainer1, cardDeckContainer3, cardDeckContainerTitle, cardDeckRow } = styles;

interface DashboardActionsProps {
    authService: AuthService;
    user: IUser;
    application: IApplication;
}

interface AppState {
    isApplicationPending: boolean;
    isApplicationNotStarted: boolean;
    isApplicationIneligible: boolean;
    isApplicationSubmittedOrResubmitted: boolean;
    isApplicationBeingReviewed: boolean;
    isApplicationActionRequired: boolean;
    isPractitionerAccredited: boolean;
    isPractitionerAccreditedWithConditions: boolean;
    isPractitionerConditionallyAccredited: boolean;
    isPractitionerNotConditionallyAccredited: boolean;
    isPersonalDetailsSavedButNotSubmitted: boolean;
    isComplaintsMechanismSavedButNotSubmitted: boolean;
}

const initAppState: AppState = {
    isApplicationPending: false,
    isApplicationNotStarted: false,
    isApplicationIneligible: false,
    isApplicationSubmittedOrResubmitted: false,
    isApplicationBeingReviewed: false,
    isApplicationActionRequired: false,
    isPractitionerAccredited: false,
    isPractitionerAccreditedWithConditions: false,
    isPractitionerConditionallyAccredited: false,
    isPractitionerNotConditionallyAccredited: false,
    isPersonalDetailsSavedButNotSubmitted: false,
    isComplaintsMechanismSavedButNotSubmitted: false,
};

interface UiState {
    showApplyForAccredBeginOrResumeAppButton: boolean;
    showApplyForAccredDiscardAppButton: boolean;
    showApplyForAccredWithdrawAppButton: boolean;
    showApplyForAccredUpdateAppButton: boolean;
    disableButtonApplyForAccredUpdateApp: boolean;
    showRegistrationNumber: boolean;
    showApplicationNumber: boolean;
    disableButtonUpdateOutletDetailsDueToNotBeingAFullMember: boolean;
    disableButtonUpdateOutletDetailsDueToBeingConditional: boolean;
    disableButtonUpdateDueToChangesUnderReview: boolean;
    disableButtonUpdateDueToChangesUnderMaintenance: boolean;
    disableButtonUpdateIfActionRequired: boolean;
    showApplyForAccreditationSection: boolean;
    showManageMyDetailsSection: boolean;
    showMaintainAccreditationSection: boolean;
}

const initUiState: UiState = {
    showApplyForAccredBeginOrResumeAppButton: false,
    showApplyForAccredDiscardAppButton: false,
    showApplyForAccredWithdrawAppButton: false,
    showApplyForAccredUpdateAppButton: false,
    disableButtonApplyForAccredUpdateApp: false,
    showRegistrationNumber: false,
    showApplicationNumber: false,
    disableButtonUpdateOutletDetailsDueToNotBeingAFullMember: false,
    disableButtonUpdateOutletDetailsDueToBeingConditional: false,
    disableButtonUpdateDueToChangesUnderReview: false,
    disableButtonUpdateDueToChangesUnderMaintenance: false,
    disableButtonUpdateIfActionRequired: false,
    showApplyForAccreditationSection: false,
    showManageMyDetailsSection: false,
    showMaintainAccreditationSection: false,
};

const DashboardActions = (props: DashboardActionsProps) => {
    const { authService, user, application } = props;

    const dispatch = useDispatch();
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const [showDiscardDialog, setShowDiscardDialog] = useState(false);
    const [showWithdrawDialog, setShowWithdrawDialog] = useState(false);

    // tslint:disable-next-line: prefer-const
    let applicationState = {
        // ensure this is a LET not a CONST
        ...initAppState,
        isApplicationPending: application?.applicationStatus === ApplicationStatusEnum.Pending,
        isApplicationNotStarted: application?.applicationStatus === ApplicationStatusEnum.NotStarted,
        isApplicationIneligible: application?.applicationStatus === ApplicationStatusEnum.Ineligible,
        isApplicationSubmittedOrResubmitted:
            application?.applicationStatus === ApplicationStatusEnum.Submitted || application?.applicationStatus === ApplicationStatusEnum.Resubmitted,
        isApplicationBeingReviewed:
            application?.applicationStatus === ApplicationStatusEnum.UnderReview ||
            application?.applicationStatus === ApplicationStatusEnum.AuthorisedOfficerReview ||
            application?.applicationStatus === ApplicationStatusEnum.PreDetermination,
        isApplicationActionRequired: application?.applicationStatus === ApplicationStatusEnum.ActionRequired,
        isPractitionerAccredited: application?.accreditationStatus === AccreditationStatusEnum.Accredited,
        isPractitionerAccreditedWithConditions: application?.accreditationStatus === AccreditationStatusEnum.AccreditedWithConditions,
        isPractitionerConditionallyAccredited: application?.accreditationStatus === AccreditationStatusEnum.ConditionalAccreditation,
        isPractitionerNotConditionallyAccredited: application?.accreditationStatus !== AccreditationStatusEnum.ConditionalAccreditation,
        isApplicationWithdrawn: application?.applicationStatus === ApplicationStatusEnum.Withdrawn,
        isApplicationRequestedToSuspend: application?.applicationStatus === ApplicationStatusEnum.PendingSuspension,
        isPractitionerSuspended: application?.accreditationStatus === AccreditationStatusEnum.Suspended,
        isApplicationRequestedToCancel: application?.applicationStatus === ApplicationStatusEnum.PendingCancellation,
        isPractitionerCancelled: application?.accreditationStatus === AccreditationStatusEnum.Cancelled,
        // isApplicationSuspended: application?.applicationStatus === ApplicationStatusEnum.Suspended,
        // isApplicationCancelled: application?.applicationStatus === ApplicationStatusEnum.Cancelled,
    };

    // cannot use just EditCopy check as we get current user on dashboard: user.isEditCopy === true;
    applicationState.isPersonalDetailsSavedButNotSubmitted = IsPersonalDetailsEditedButNotYetSubmitted(application, user);
    applicationState.isComplaintsMechanismSavedButNotSubmitted = IsComplaintsEditedButNotYetSubmitted(application);

    // tslint:disable-next-line: prefer-const
    let displayState = {
        // ensure this is a LET not a CONST
        ...initUiState,
        showApplyForAccredBeginOrResumeAppButton:
            applicationState.isApplicationNotStarted ||
            applicationState.isApplicationPending ||
            applicationState.isApplicationWithdrawn ||
            applicationState.isPractitionerCancelled,
        showApplyForAccredDiscardAppButton: applicationState.isApplicationIneligible || applicationState.isApplicationPending,
        showApplyForAccredWithdrawAppButton:
            applicationState.isApplicationSubmittedOrResubmitted || applicationState.isApplicationBeingReviewed || applicationState.isApplicationActionRequired,
        showApplyForAccredUpdateAppButton:
            applicationState.isApplicationSubmittedOrResubmitted || applicationState.isApplicationBeingReviewed || applicationState.isApplicationActionRequired,
        disableButtonApplyForAccredUpdateApp: applicationState.isApplicationSubmittedOrResubmitted || applicationState.isApplicationBeingReviewed,
        showRegistrationNumber: application && application.registrationNumber > 0, //  show the Rego number if exists (as opposed to check accred status as that changes to pending when editing)
        disableButtonUpdateOutletDetailsDueToNotBeingAFullMember:
            applicationState.isPractitionerAccredited && IsAnyComplaintProfAssoc_FullMembership_WithInsurance(application?.complaintsMechanism) === false,
        disableButtonUpdateOutletDetailsDueToBeingConditional: applicationState.isPractitionerConditionallyAccredited,
        disableButtonUpdateDueToChangesUnderReview: HasOutletPractitionerPendingReview(application) || AccreditedApplicationIsAwaitingReview(application),
        disableButtonUpdateDueToChangesUnderMaintenance: application.applicationStatus === ApplicationStatusEnum.UnderMaintenance,
        disableButtonUpdateIfActionRequired: AccreditedApplicationIsActionRequired(application),
        disableButtonUpdateIfWithdrawn: applicationState.isApplicationWithdrawn,
    };

    displayState.showApplicationNumber =
        displayState.showRegistrationNumber === false &&
        application &&
        application.zNumber > 0 &&
        application.accreditationStatus !== AccreditationStatusEnum.ConditionalAccreditation;

    // show/hide section
    displayState.showApplyForAccreditationSection =
        (displayState.showApplicationNumber === true || applicationState.isApplicationNotStarted === true || applicationState.isPractitionerCancelled) &&
        application?.accreditationStatus !== AccreditationStatusEnum.Accredited &&
        (displayState.showApplyForAccredBeginOrResumeAppButton === true ||
            displayState.showApplyForAccredDiscardAppButton === true ||
            displayState.showApplyForAccredUpdateAppButton === true ||
            displayState.showApplyForAccredWithdrawAppButton === true);

    displayState.showManageMyDetailsSection =
        applicationState.isPractitionerAccredited ||
        applicationState.isPractitionerConditionallyAccredited ||
        applicationState.isPractitionerAccreditedWithConditions ||
        AccreditedApplicationIsAwaitingReview(application) ||
        AccreditedApplicationIsActionRequired(application) ||
        applicationState.isPractitionerSuspended;

    displayState.showMaintainAccreditationSection =
        applicationState.isPractitionerAccredited || applicationState.isPractitionerConditionallyAccredited || applicationState.isPractitionerSuspended;

    const discardThisApplication = () => {
        try {
            application.applicationStatus = ApplicationStatusEnum.Discarded;

            dispatch(appActions.DiscardApplication());
        } catch {
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        } finally {
            hideDiscardDialog();
            // refresh this page after a second
            Sleep(1000).then(() => {
                window.location.href = '/';
            });
        }
    };

    const withdrawThisApplication = () => {
        try {
            application.applicationStatus = ApplicationStatusEnum.PendingWithdrawal; // API will set this most likely, just for safety

            dispatch(appActions.WithdrawApplication(application));
        } catch {
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        } finally {
            hideWithdrawDialog();
        }
    };

    const modalOptionsOkOnly = (titleIn: string) => {
        return {
            title: titleIn,
            okOnly: true,
            isDestructive: true,
            modalBodyPositionTextLeft: true,
        };
    };

    // open a new tab and direct user to the ADFS sign in page, where they can change primary email there
    const handleChangePrimaryEmailClick = async () => {
        const linkEARS = `${window.__RUNTIME_CONFIG__.REACT_APP_EARS_URL ?? '/'}`;

        await ModalDialogConfirmable(MODALTEXT_CHANGEPRIMARYEMAIL, undefined, undefined, modalOptionsOkOnly(MODALHEADER_CHANGEPRIMARYEMAIL));

        OnClickUrlToNewTab(linkEARS);
    };

    const handleDiscardApplication = (e: { preventDefault: () => void } | undefined) => {
        e?.preventDefault(); // MUST prevent going backwards
        setShowDiscardDialog(true);
    };

    /* const handleWithdrawApplication = (e: { preventDefault: () => void } | undefined) => {
        e?.preventDefault(); // MUST prevent going backwards
        setShowWithdrawDialog(true);
    }; */

    const hideDiscardDialog = () => {
        setShowDiscardDialog(false);
    };

    const hideWithdrawDialog = () => {
        setShowWithdrawDialog(false);
    };

    const buttonApplyForAccredBeginOrResumeApplication = () => {
        let cardTitle = 'Begin application';
        let buttonLink = '/beginregistrationapplication';
        let img = fileIcon();
        let id = 'dashboard-button-beginApplication';
        let disabledButtonActionText = '';
        let hasPendingEdits = false;

        if (application?.applicationStatus === ApplicationStatusEnum.Pending) {
            cardTitle = 'Resume application';
            buttonLink = '/resumeregistrationapplication';
            img = fileAltIcon();
            id = 'dashboard-button-resumeApplication';
        }

        if (application?.applicationStatus === ApplicationStatusEnum.Withdrawn || application?.accreditationStatus === AccreditationStatusEnum.Cancelled) {
            img = fileAltIcon();
            buttonLink = '';
            disabledButtonActionText = `You cannot apply for accreditation at this time. Please contact the Practitioner Accreditation Unit on ${DISPLAY_FDDR_PAU_CONTACT_PHONENUMBER} to discuss.`;
            hasPendingEdits = true;
        }

        return (
            <Col>
                <ActionCard
                    id={id}
                    imgElement={img}
                    title={cardTitle}
                    buttonLink={buttonLink}
                    disabledButtonActionText={disabledButtonActionText}
                    hasPendingEdits={hasPendingEdits}
                />
            </Col>
        );
    };

    const buttonApplyForAccredDiscardApplication = () => {
        const id = 'dashboard-button-discardApplication';

        return (
            <Col>
                <ActionCard id={id} imgElement={fileExcelIcon()} title='Discard application' buttonLink='' handleOnClick={handleDiscardApplication} />
            </Col>
        );
    };

    const buttonApplyForAccredWithdrawApplication = () => {
        const id = 'dashboard-button-withdrawApplication';

        return (
            <Col>
                <ActionCard
                    id={id}
                    imgElement={fileExportIcon()}
                    title='Withdraw application'
                    buttonLink='/withdrawapplication'
                    // handleOnClick={handleWithdrawApplication}
                />
            </Col>
        );
    };

    const buttonApplyForAccredUpdateApplication = () => {
        const cardTitle = 'Update application';
        const buttonLink = '/resubmitregistrationapplication';
        const img = fileIcon();
        const id = 'dashboard-button-uploadFurtherEvidence';
        const disabledButtonActionText = displayState.disableButtonApplyForAccredUpdateApp === true ? BUTTON_DISABLEDACTION_APPLYFORACCRED_UPDATEAPP : '';

        return (
            <Col>
                <ActionCard id={id} imgElement={img} title={cardTitle} buttonLink={buttonLink} disabledButtonActionText={disabledButtonActionText} />
            </Col>
        );
    };

    const buttonManagePersonalDetails = () => {
        const id = 'dashboard-button-managePersonalDetails';
        const buttonLinkModded =
            AccreditedApplicationIsActionRequired(application) || applicationState.isPersonalDetailsSavedButNotSubmitted
                ? '/editpersonaldetails'
                : '/viewpersonaldetails';
        const title = 'Manage personal details' + (applicationState.isPersonalDetailsSavedButNotSubmitted ? ' (Edited)' : '');
        const disabledButtonActionText =
            displayState.disableButtonUpdateDueToChangesUnderMaintenance === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERMAINTENANCE
                : displayState.disableButtonUpdateDueToChangesUnderReview === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW
                : applicationState.isApplicationRequestedToSuspend === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW
                : applicationState.isApplicationRequestedToCancel === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW
                : '';

        return (
            <ActionCard
                id={id}
                imgElement={addresscardIcon()}
                title={title}
                buttonLink={buttonLinkModded}
                disabledButtonActionText={disabledButtonActionText}
                hasPendingEdits={
                    displayState.disableButtonUpdateDueToChangesUnderReview ||
                    displayState.disableButtonUpdateDueToChangesUnderMaintenance ||
                    applicationState.isApplicationRequestedToSuspend ||
                    applicationState.isApplicationRequestedToCancel
                }
            />
        );
    };

    const buttonUpdateComplaints = () => {
        const id = 'dashboard-button-updateComplaints';
        const title = 'Manage complaints mechanism(s) and insurance' + (applicationState.isComplaintsMechanismSavedButNotSubmitted ? ' (Edited)' : '');
        const disabledButtonActionText = displayState.disableButtonUpdateDueToChangesUnderMaintenance
            ? DASHBOARD_NOTIFICATION_CHANGESUNDERMAINTENANCE
            : displayState.disableButtonUpdateDueToChangesUnderReview
            ? DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW
            : applicationState.isApplicationRequestedToSuspend === true
            ? DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW
            : applicationState.isPractitionerSuspended === true
            ? BUTTON_DISABLEDACTION_MAINTAINACCRED_SUSPENDED
            : applicationState.isApplicationRequestedToCancel === true
            ? DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW
            : '';

        return (
            <ActionCard
                id={id}
                imgElement={usercircleIcon()}
                title={title}
                buttonLink='/managecomplaintsmechanism'
                disabledButtonActionText={disabledButtonActionText}
                hasPendingEdits={
                    displayState.disableButtonUpdateDueToChangesUnderReview ||
                    displayState.disableButtonUpdateDueToChangesUnderMaintenance ||
                    applicationState.isApplicationRequestedToSuspend ||
                    applicationState.isPractitionerSuspended ||
                    applicationState.isApplicationRequestedToCancel
                }
            />
        );
    };

    const buttonSuspendAccreditation = () => {
        const id = 'dashboard-button-suspendAccred';
        const title = 'Request suspension of accreditation';
        const disabledButtonActionText =
            applicationState.isPractitionerSuspended === true
                ? BUTTON_DISABLEDACTION_MAINTAINACCRED_SUSPENDED
                : applicationState.isPersonalDetailsSavedButNotSubmitted === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW
                : applicationState.isComplaintsMechanismSavedButNotSubmitted === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW
                : displayState.disableButtonUpdateDueToChangesUnderReview === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW
                : displayState.disableButtonUpdateDueToChangesUnderMaintenance === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERMAINTENANCE
                : applicationState.isApplicationRequestedToSuspend === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW
                : applicationState.isApplicationRequestedToCancel === true
                ? DASHBOARD_NOTIFICATION_REQUESTCANCEL
                : '';

        return (
            <ActionCard
                id={id}
                imgElement={calendarTimesIcon()}
                title={title}
                buttonLink='/suspendaccreditation'
                disabledButtonActionText={disabledButtonActionText}
                hasPendingEdits={
                    applicationState.isPractitionerSuspended ||
                    applicationState.isApplicationRequestedToSuspend ||
                    applicationState.isPersonalDetailsSavedButNotSubmitted ||
                    applicationState.isComplaintsMechanismSavedButNotSubmitted ||
                    displayState.disableButtonUpdateDueToChangesUnderReview ||
                    displayState.disableButtonUpdateDueToChangesUnderMaintenance ||
                    applicationState.isApplicationRequestedToCancel
                }
            />
        );
    };

    const buttonCancelAccreditation = () => {
        const id = 'dashboard-button-cancelAccred';
        const title = 'Request cancellation of accreditation';
        const disabledButtonActionText =
            applicationState.isApplicationRequestedToSuspend === true
                ? BUTTON_DISABLEDACTION_MAINTAINACCRED_REQUEST_TO_SUSPENDED
                : applicationState.isPersonalDetailsSavedButNotSubmitted === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW
                : applicationState.isComplaintsMechanismSavedButNotSubmitted === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW
                : applicationState.isApplicationRequestedToCancel === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW
                : displayState.disableButtonUpdateDueToChangesUnderReview === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW
                : displayState.disableButtonUpdateDueToChangesUnderMaintenance === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERMAINTENANCE
                : '';

        return (
            <ActionCard
                id={id}
                imgElement={banIcon()}
                title={title}
                buttonLink='/cancelaccreditation'
                disabledButtonActionText={disabledButtonActionText}
                hasPendingEdits={
                    applicationState.isApplicationRequestedToSuspend ||
                    applicationState.isApplicationRequestedToCancel ||
                    applicationState.isPersonalDetailsSavedButNotSubmitted ||
                    applicationState.isComplaintsMechanismSavedButNotSubmitted ||
                    displayState.disableButtonUpdateDueToChangesUnderReview ||
                    displayState.disableButtonUpdateDueToChangesUnderMaintenance
                }
            />
        );
    };

    const buttonSuspendedAccreditationLifted = () => {
        const id = 'dashboard-button-suspenedAccreditationLifted';
        const title = 'Request a suspension lift';

        return <ActionCard id={id} imgElement={handIcon()} title={title} buttonLink='/liftSuspension' />;
    };

    /*
    const buttonUpdateInsurance = () => {
        const id = 'dashboard-button-updateInsurance';

        return <ActionCard id={id} imgElement={moneybillIcon()} title='Update/Add/Remove insurance details' buttonLink='/updateinsurancedetails' />;
    }; */

    /*
    const buttonUpdateNmas = () => {
        const id = 'dashboard-button-updateNmas';

        return <ActionCard id={id} imgElement={fileSignatureIcon()} title='Update NMAS' buttonLink='/updatenmasdetails' />;
    };

    const buttonChangeNmasToQual = () => {
        const id = 'dashboard-button-changeNmasToQual';

        return <ActionCard id={id} imgElement={fileArchiveIcon()} title='Change NMAS to Qualification' buttonLink='/changenametoqualification' />;
    }; */

    /*
    const buttonViewAccredHistory = () => {
        const id = 'dashboard-button-viewAccredHistory';

        return <ActionCard id={id} imgElement={chartLineIcon()} title='View accreditation history' buttonLink='/viewaccreditationhistory' />;
    };

    const buttonUpdateProfessionalDevelopment = () => {
        const id = 'dashboard-button-updateProfessionalDev';

        return <ActionCard id={id} imgElement={shieldIcon()} title='Update professional development' buttonLink='/updateprofessionaldevelopment' />;
    };
*/

    const buttonChangePrimaryEmail = () => {
        const id = 'dashboard-button-changePrimaryEmail';

        return <ActionCard id={id} imgElement={emailIcon()} title='Change primary email address' buttonLink='' handleOnClick={handleChangePrimaryEmailClick} />;
    };

    const buttonUpdateOutletDetails = () => {
        const id = 'dashboard-button-updateOutletDetails';
        const disabledButtonActionText =
            applicationState.isComplaintsMechanismSavedButNotSubmitted === true
                ? BUTTON_DISABLEDACTION_CHANGESMADE_BUTNOTYETSUBMITTED
                : applicationState.isPersonalDetailsSavedButNotSubmitted === true
                ? BUTTON_DISABLEDACTION_CHANGESMADE_BUTNOTYETSUBMITTED
                : displayState.disableButtonUpdateDueToChangesUnderReview === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW
                : displayState.disableButtonUpdateDueToChangesUnderMaintenance === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERMAINTENANCE
                : displayState.disableButtonUpdateIfActionRequired === true
                ? NOTE_MANAGEDETAILS_REWORK
                : displayState.disableButtonUpdateOutletDetailsDueToNotBeingAFullMember === true
                ? BUTTON_DISABLEDACTION_MAINTAINACCRED_UPDATEOUTLET_ACCREDNOTFULLMEMBER
                : displayState.disableButtonUpdateOutletDetailsDueToBeingConditional === true
                ? BUTTON_DISABLEDACTION_MAINTAINACCRED_UPDATEOUTLET_CONDITIONALLYACCRED
                : applicationState.isApplicationRequestedToSuspend === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW
                : applicationState.isPractitionerSuspended === true
                ? BUTTON_DISABLEDACTION_MAINTAINACCRED_SUSPENDED
                : applicationState.isApplicationRequestedToCancel === true
                ? DASHBOARD_NOTIFICATION_CHANGESUNDERREVIEW
                : '';

        return (
            <ActionCard
                id={id}
                imgElement={buildingIcon()}
                title='Manage public details'
                buttonLink='/managepublicdetails'
                disabledButtonActionText={disabledButtonActionText}
                darkerShadeOfTile={true}
                hasPendingEdits={
                    displayState.disableButtonUpdateDueToChangesUnderReview === true ||
                    displayState.disableButtonUpdateDueToChangesUnderMaintenance === true ||
                    applicationState.isComplaintsMechanismSavedButNotSubmitted === true ||
                    applicationState.isPersonalDetailsSavedButNotSubmitted === true ||
                    applicationState.isApplicationRequestedToSuspend === true ||
                    applicationState.isPractitionerSuspended ||
                    applicationState.isApplicationRequestedToCancel
                }
            />
        );
    };

    /*
    const displayNmasDetails = () => {
        if (isNMASAccreditation === true) {
            return (
                <Row className={`${cardDeckRow} card-deck`}>
                    <Col>{buttonUpdateNmas()}</Col>
                    <Col>{buttonChangeNmasToQual()}</Col>
                    <Col />
                    <Col />
                </Row>
            );
        }
    }; */

    const displayManageMyDetailsSection = () => {
        /*if (isSuspended === true) {
            return (
                <Container
                    className={`${cardDeckContainer} ${cardDeckContainer3}`}
                    id='dashboard-updatedetails-container'
                    data-testid='dashboard-updatedetails-container'
                >
                    <Row className={cardDeckContainerTitle}>
                        <Col aria-colspan={4}>
                            <h2>UPDATE DETAILS</h2>
                        </Col>
                    </Row>
                    <Row className={`${cardDeckRow} card-deck`}>
                        <Col>{buttonCancelAccreditation()}</Col>
                        <Col />
                        <Col />
                        <Col />
                    </Row>
                </Container>
            );
        } */
        // if (isConditionallyAccredited === true || isAccredited === true) {
        return (
            <Container
                id='dashboard-managedetails-container'
                data-testid='dashboard-managedetails-container'
                className={`${cardDeckContainer} ${cardDeckContainer3}`}
            >
                <Row className={cardDeckContainerTitle}>
                    <Col aria-colspan={4}>
                        <h2>MANAGE MY DETAILS</h2>
                    </Col>
                </Row>
                <Row className={`${cardDeckRow} card-deck`}>
                    <Col>{buttonManagePersonalDetails()}</Col>
                    <Col>{buttonUpdateOutletDetails()}</Col>
                    <Col>{buttonUpdateComplaints()}</Col>
                    <Col>{buttonChangePrimaryEmail()}</Col>
                </Row>
            </Container>
        );
        /*
            <Row className={`${cardDeckRow} card-deck`}>
                <Col>{buttonManagePersonalDetails()}</Col>
                <Col>{buttonUpdateComplaints()}</Col>
                <Col>{buttonUpdateInsurance()}</Col>
                <Col />
            </Row>
                    {displayNmasDetails()}
                    <Row className={`${cardDeckRow} card-deck`}>
                        <Col>{buttonSuspendAccreditation()}</Col>
                        <Col>{buttonCancelAccreditation()}</Col>
                        <Col>{buttonViewAccredHistory()}</Col>
                        <Col />
                    </Row>
                    <Row className={`${cardDeckRow} card-deck`}>
                        <Col>{buttonUpdateProfessionalDevelopment()}</Col>
                        <Col />
                        <Col />
                        <Col />
                    </Row> */
        // }
    };

    const displayMaintainAccreditationSection = () => {
        return (
            <Container
                id='dashboard-maintain-accreditation'
                data-testid='dashboard-maintain-accreditation'
                className={`${cardDeckContainer} ${cardDeckContainer3}`}
            >
                <Row className={cardDeckContainerTitle}>
                    <Col aria-colspan={4}>
                        <h2>MAINTAIN ACCREDITATION</h2>
                    </Col>
                </Row>
                <Row className={`${cardDeckRow} card-deck`}>
                    <Col>{buttonSuspendAccreditation()}</Col>
                    <Col>{buttonCancelAccreditation()}</Col>
                    {applicationState.isPractitionerSuspended && <Col>{buttonSuspendedAccreditationLifted()}</Col>}
                </Row>
            </Container>
        );
    };

    return (
        <>
            <ModalDialog
                modalTitle={MODALHEADER_DISCARD_APPLICATION}
                modalBodyText={MODALTEXT_DISCARD_APPLICATION}
                modalBodyTextP2={MODALTEXT_DISCARD_APPLICATION_P2}
                showMe={showDiscardDialog}
                showOkOnly={false}
                handleClickYes={discardThisApplication}
                handleClickNo={hideDiscardDialog}
            />
            <ModalDialog
                modalTitle={MODALHEADER_WITHDRAW_APPLICATION_PROMPT}
                modalBodyText={MODALTEXT_WITHDRAW_APPLICATION_PROMPT}
                modalBodyTextP2={MODALTEXT_WITHDRAW_APPLICATION_P2_PROMPT}
                showMe={showWithdrawDialog}
                showOkOnly={false}
                handleClickYes={withdrawThisApplication}
                handleClickNo={hideWithdrawDialog}
            />
            {displayState.showApplyForAccreditationSection === true && (
                <Container
                    className={`${cardDeckContainer} ${cardDeckContainer1}`}
                    id='dashboard-applyforaccred-container'
                    data-testid='dashboard-applyforaccred-container'
                >
                    <Row className={cardDeckContainerTitle}>
                        <Col aria-colspan={3}>
                            <h2>APPLY FOR ACCREDITATION</h2>
                        </Col>
                    </Row>
                    <Row className='card-deck'>
                        {displayState.showApplyForAccredBeginOrResumeAppButton === true && buttonApplyForAccredBeginOrResumeApplication()}
                        {displayState.showApplyForAccredDiscardAppButton === true && buttonApplyForAccredDiscardApplication()}
                        {displayState.showApplyForAccredUpdateAppButton === true && buttonApplyForAccredUpdateApplication()}
                        {displayState.showApplyForAccredWithdrawAppButton === true && buttonApplyForAccredWithdrawApplication()}
                    </Row>
                </Container>
            )}
            {displayState.showManageMyDetailsSection === true && displayManageMyDetailsSection()}
            {displayState.showMaintainAccreditationSection === true && displayMaintainAccreditationSection()}
        </>
    );
};
// REMOVED FOR RELEASE001 - DEV DEBUG ONLY - place this under that last section:
//   {displayUpdateDetails()}

export default DashboardActions;
