class AGWebTimeoutError {
    errors: [] = [];
    message: string = 'timeout'; // this aligns with the AJAX call code to output the correct message to user
    isValid: boolean = false;

    /*setMessage(inputValue: string) {
        this.message = 'timeout';
    }*/
    setErrors(inputValue: []) {
        this.errors = inputValue;
    }
    /*setIsValid(inputValue: boolean) {
        this.isValid = inputValue;
    }*/
}

export default AGWebTimeoutError;
