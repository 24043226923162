// Edit fields for Personal Details Name
import React, { useEffect, useState } from 'react';
import { isValid as isValidDate } from 'date-fns';
import { Col, Row } from 'react-bootstrap';
import { IAttachmentMetadata, Inquiry } from '../api/interfacesApi';
import SelectListField from '../components/fields/SelectListField';
import InputTextField from '../components/fields/InputTextField';
import InputDateField from '../components/fields/InputDateField';
import LabelField from '../components/fields/LabelField';
import FileUploadField from '../components/fields/FileUploadField';
import {
    PersonalDetailsTitleList,
    PersonalDetailsGenderList,
    PLACEHOLDER_DATE,
    HELPHEADERTEXT_GENDER,
    HELPTEXT_GENDER,
    NOTE_DATEOFBIRTH_MESSAGE,
    DEFAULT_DATE_OF_BIRTH,
    HELPHEADERTEXT_FORMERNAME,
    HELPTEXT_FORMERNAME,
    GUIDANCETEXT_EDITPERSONALDETAILS_CHANGEOFNAMEDOCS,
    GUIDANCETEXT_EDITPERSONALDETAILS_PROOFOFBIRTHDOCS,
    UNINITIALISED,
} from '../services/Constants';
import { AttachmentDocumentType } from '../services/Enums';
import { eighteenYearsAgo, ninetyYearsAgo } from '../utils/Dates';
import NoteText from '../controls/NoteText';
import { EmptyRow } from '../controls/EmptyRow';
import { Loader } from '../controls/Loader';
import { DisplayFieldValueOrDefaultText } from '../utils/AppUtils';
import { Sleep } from '../utils/Common';
import { InsertAttachmentToLocalInquiryObject, RemoveAttachmentFromLocalInquiryObject } from '../utils/ComplaintsUtils';

interface PersonalDetailsNameFieldsEditProps {
    inquiry: Inquiry;
    setInquiryObj?: any;
    register: any;
    control: any;
    errors: any;
    watch: any;
    isValid: any;
    isDirty: any;
    trigger: any;
    setValue: any;
    onChangeTitle: any;
    shownAsPartOfAllPersonalDetails?: boolean;
    showFormerName?: boolean;
    showFileUploadFields?: boolean;
    authService?: any;
    dispatch?: any;
    // updatedFilesListChangeOfName?: any;
    // updatedFilesListProofOfBirth?: any;
    isRework?: boolean;
}

const PersonalDetailsNameFieldsEdit = (props: PersonalDetailsNameFieldsEditProps) => {
    const {
        register,
        inquiry,
        // setInquiryObj,
        control,
        errors,
        watch,
        isValid,
        isDirty,
        trigger,
        setValue,
        onChangeTitle,
        shownAsPartOfAllPersonalDetails,
        showFormerName,
        showFileUploadFields,
        authService,
        dispatch,
        // updatedFilesListChangeOfName,
        // updatedFilesListProofOfBirth,
        isRework,
    } = props;

    const today = new Date();
    const isReadOnly = isRework ?? false;
    const [showNoteDateOfBirthWarning, setShowNoteDateOfBirthWarning] = useState(false);
    const dateOfBirthWatched = watch('inquiry.personalDetails.dateOfBirth', inquiry?.personalDetails.dateOfBirth);
    const dateOfBirthNumberOfYears =
        dateOfBirthWatched && isValidDate(dateOfBirthWatched) ? new Date().getFullYear() - new Date(dateOfBirthWatched).getFullYear() : 0;
    const dateOfBirthMessage = NOTE_DATEOFBIRTH_MESSAGE.replace('%%', dateOfBirthNumberOfYears.toString());

    const openingDateOfBirth = dateOfBirthWatched ? dateOfBirthWatched : DEFAULT_DATE_OF_BIRTH;

    const dateOfBirthFieldIsValid = () => {
        return (
            isValid ||
            (isValid === false && (errors.inquiry?.personalDetails?.dateOfBirth === undefined || errors.inquiry?.personalDetails?.dateOfBirth === null))
        );
    };

    const shouldShowDateOfBirthWarning = (dateOfBirth: Date | null | undefined) => {
        // if valid date (which confirms validation rules already)
        if (dateOfBirth !== undefined && isValidDate(dateOfBirth)) {
            const dateToCheck = new Date(dateOfBirth!); // .setHours(0, 0, 0, 0);
            if (dateToCheck < ninetyYearsAgo() || dateToCheck > eighteenYearsAgo()) {
                setShowNoteDateOfBirthWarning(true);
            }
        }
    };

    // initial load (since edit form) check DOB
    useEffect(() => {
        // the check on showNoteDateOfBirthWarning determines whether we show the warning
        if (dateOfBirthFieldIsValid() && showNoteDateOfBirthWarning === false) {
            // needs a delay here as we set to false when watch kicks in upon initial load and thats how we manage ongoing change
            Sleep(300).then(() => {
                shouldShowDateOfBirthWarning(inquiry?.personalDetails.dateOfBirth);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // check if DOB is valid but inside the validation checks, and outside the desired range, place up a warning note
    useEffect(() => {
        // reset DOB flags if user changes DOB
        if (showNoteDateOfBirthWarning === true) {
            setShowNoteDateOfBirthWarning(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateOfBirthWatched]);

    // if DOB has changed and we are not showing the Confirm Changes dialog then we check it here
    if (dateOfBirthFieldIsValid() && isDirty && dateOfBirthWatched && showNoteDateOfBirthWarning === false) {
        shouldShowDateOfBirthWarning(dateOfBirthWatched);
    }

    const removedFile = (attachmentId: string) => {
        // refresh CM object/active obj
        RemoveAttachmentFromLocalInquiryObject(attachmentId, setValue, inquiry);
        // setInquiryObj(inquiry);
        trigger(); // re-validate
    };

    const addedFile = (newAttachment: IAttachmentMetadata) => {
        // refresh CM object/active obj (NOTE: CANNOT get App in case user simply adds/removes files, it will clear the form!)
        InsertAttachmentToLocalInquiryObject(newAttachment, setValue, inquiry);
        // setInquiryObj(inquiry);
        trigger(); // re-validate
    };

    if (inquiry === undefined || inquiry?.application.applicationId === UNINITIALISED) {
        return <Loader isLoading={true} loaderText='Loading, please wait...' />;
    }

    return (
        <>
            <input
                type='hidden'
                className='form-control'
                name='inquiry.application.applicationId'
                {...register('inquiry.application.applicationId')}
                defaultValue={inquiry?.application.applicationId}
            />
            {(shownAsPartOfAllPersonalDetails === undefined || shownAsPartOfAllPersonalDetails === false) && (
                <>
                    <input
                        type='hidden'
                        className='form-control'
                        name='inquiry.personalDetails.emailAddress'
                        {...register('inquiry.personalDetails.emailAddress')}
                        defaultValue={inquiry?.personalDetails.emailAddress}
                    />
                    <input
                        type='hidden'
                        className='form-control'
                        name='inquiry.personalDetails.secondaryEmailAddress'
                        {...register('inquiry.personalDetails.secondaryEmailAddress')}
                        defaultValue={inquiry?.personalDetails.secondaryEmailAddress}
                    />
                    <input
                        type='hidden'
                        className='form-control'
                        name='inquiry.personalDetails.primaryPhoneNumber'
                        {...register('inquiry.personalDetails.primaryPhoneNumber')}
                        defaultValue={inquiry?.personalDetails.primaryPhoneNumber}
                    />
                    <input
                        type='hidden'
                        className='form-control'
                        name='inquiry.personalDetails.secondaryPhoneNumber'
                        {...register('inquiry.personalDetails.secondaryPhoneNumber')}
                        defaultValue={inquiry?.personalDetails.secondaryPhoneNumber}
                    />
                </>
            )}
            <SelectListField
                id='inquiry.personalDetails.title'
                displayName='Title'
                defaultValue={inquiry?.personalDetails.title}
                disabled={isReadOnly}
                options={PersonalDetailsTitleList}
                isMandatory={false}
                autoFocus={true} // causes isDirty flag to be set!
                register={register}
                errorsField={errors.inquiry?.personalDetails?.title}
                handleOnChange={onChangeTitle}
            />
            <InputTextField
                id='inquiry.personalDetails.firstName'
                displayName='First name'
                defaultValue={inquiry?.personalDetails.firstName}
                isReadOnly={isReadOnly}
                isMandatory={true}
                register={register}
                errorsField={errors.inquiry?.personalDetails?.firstName}
            />
            <InputTextField
                id='inquiry.personalDetails.preferredName'
                displayName='Preferred name (if different to first name)'
                defaultValue={inquiry?.personalDetails.preferredName}
                isReadOnly={isReadOnly}
                isMandatory={false}
                register={register}
                errorsField={errors.inquiry?.personalDetails?.preferredName}
            />
            <InputTextField
                id='inquiry.personalDetails.middleName'
                displayName='Middle name(s)'
                defaultValue={inquiry?.personalDetails.middleName}
                isReadOnly={isReadOnly}
                isMandatory={false}
                register={register}
                errorsField={errors.inquiry?.personalDetails?.middleName}
            />
            <InputTextField
                id='inquiry.personalDetails.familyName'
                displayName='Family name'
                defaultValue={inquiry?.personalDetails.familyName}
                isReadOnly={isReadOnly}
                isMandatory={true}
                register={register}
                errorsField={errors.inquiry?.personalDetails?.familyName}
            />
            {showFormerName === true && (
                <LabelField
                    id='reviewPersonalNameFormername'
                    displayName='Former name'
                    value={DisplayFieldValueOrDefaultText(inquiry.personalDetails.formerName)}
                    isMandatory={false}
                    textCapitalizeOn={true}
                    helpHeaderText={HELPHEADERTEXT_FORMERNAME}
                    helpText={HELPTEXT_FORMERNAME}
                />
            )}
            {showFileUploadFields === true && (
                <>
                    <EmptyRow />
                    <FileUploadField
                        id='inquiry.application.attachments.changeofname'
                        maxFilesCount={5}
                        applicationId={inquiry?.application.applicationId}
                        authService={authService}
                        displayNameSuffix='change of name'
                        errorsFieldSuffix='change of name'
                        documentType={AttachmentDocumentType.EvidenceOfNameChange}
                        isMandatory={false}
                        // updatedFilesList={updatedFilesListChangeOfName}
                        register={register}
                        dispatch={dispatch}
                        // goingBackwards={goingBackwards}
                        errorsField={errors.inquiry?.application?.attachments?.changeofname}
                        guidanceText={GUIDANCETEXT_EDITPERSONALDETAILS_CHANGEOFNAMEDOCS}
                        removedFile={removedFile}
                        addedFile={addedFile}
                        showDisclaimerText={true}
                    />
                </>
            )}
            {isRework === true && (
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.personalDetails.gender'
                    {...register('inquiry.personalDetails.gender')}
                    defaultValue={inquiry?.personalDetails?.gender}
                />
            )}
            <SelectListField
                id='inquiry.personalDetails.gender'
                displayName='Gender'
                defaultValue={inquiry?.personalDetails.gender}
                disabled={isReadOnly}
                options={PersonalDetailsGenderList}
                isMandatory={true}
                register={register}
                errorsField={errors.inquiry?.personalDetails?.gender}
                helpHeaderText={HELPHEADERTEXT_GENDER}
                helpText={HELPTEXT_GENDER}
                // handleOnChange={(e: any) => handleOnChangeGender(e)}
            />
            <InputDateField
                id='inquiry.personalDetails.dateOfBirth'
                displayName='Date of birth'
                defaultValue={inquiry?.personalDetails.dateOfBirth}
                openToDate={openingDateOfBirth}
                isMandatory={true}
                dropdownMode='select'
                maxDate={today}
                yearDropdownRange={80}
                register={register}
                control={control}
                disabled={isReadOnly}
                placeHolder={PLACEHOLDER_DATE}
                errorsField={errors.inquiry?.personalDetails?.dateOfBirth}
            />
            {showNoteDateOfBirthWarning && (
                <>
                    <Row>
                        <Col md='11'>
                            <NoteText displayText={dateOfBirthMessage} />
                        </Col>
                        <Col md='1' className='justify-content-end' />
                    </Row>
                    <EmptyRow />
                </>
            )}
            {showFileUploadFields === true && (
                <>
                    <EmptyRow />
                    <FileUploadField
                        id='inquiry.application.attachments.proofofbirth'
                        maxFilesCount={5}
                        applicationId={inquiry?.application.applicationId}
                        authService={authService}
                        displayNameSuffix='proof of birth'
                        errorsFieldSuffix='proof of birth'
                        documentType={AttachmentDocumentType.EvidenceOfBirth}
                        isMandatory={false}
                        // updatedFilesList={updatedFilesListProofOfBirth}
                        register={register}
                        dispatch={dispatch}
                        // goingBackwards={goingBackwards}
                        errorsField={errors.inquiry?.application?.attachments?.proofofbirth}
                        guidanceText={GUIDANCETEXT_EDITPERSONALDETAILS_PROOFOFBIRTHDOCS}
                        removedFile={removedFile}
                        addedFile={addedFile}
                        showDisclaimerText={true}
                    />
                </>
            )}
        </>
    );
};

export default PersonalDetailsNameFieldsEdit;
