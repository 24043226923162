import 'whatwg-fetch';
import ApiService from './commonApi';
import AuthService from '../services/AuthService';
import { IAttachmentMetadata } from './interfacesApi';

class AttachmentApi {
    apiService: ApiService;

    constructor(authenticationService: AuthService) {
        this.apiService = new ApiService(authenticationService);
    }

    // API call - get Attachments for this user
    async getAllAttachmentsMetadata() {
        const request = this.apiService.setRequest('api/attachments', 'GET', null);
        return this.apiService.makeFetchRequest<IAttachmentMetadata[]>(request);
    }

    // API call - get Attachments for this user by type
    async getAllAttachmentsByTypeMetadata(documentType: string) {
        const request = this.apiService.setRequest(`api/attachments/${documentType}`, 'GET', null);
        return this.apiService.makeFetchRequest<IAttachmentMetadata[]>(request);
    }

    // API call - delete one Attachment for this user
    async deleteAttachmentBlobAndMetadata(attachmentId: string) {
        // const request = this.apiService.setRequest(`api/attachments/${attachmentId}`, 'DELETE', null);
        return this.apiService.makeStandardDeleteRequest(`api/attachments/${attachmentId}`);
    }
}

export default AttachmentApi;
