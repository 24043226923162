import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './Fields.module.scss';

const { mandatoryIndicator, pageMandatoryLabelText } = styles;

const PageMandatoryLabelText = () => {
    return (
        <>
            <Row className={pageMandatoryLabelText}>
                <Col>
                    <label className={mandatoryIndicator}>*</label>
                    <label> denotes mandatory field</label>
                </Col>
            </Row>
        </>
    );
};

export default PageMandatoryLabelText;
