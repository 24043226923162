import React, { useEffect, useMemo, useState } from 'react';
import { BannerTextType } from 'services/Enums';
import styles from './SiteHeader.module.scss';
import AuthService from '../../services/AuthService';
import ReferenceApi from '../../api/referenceApi';
import { toastErrorMessage } from 'actions/toastrMessages';
import { IBannerText } from 'api/interfacesApi';
import { VALIDATION_ERROR_UNKNOWN_MESSAGE } from 'services/Validation';

const { bannerText } = styles;

interface BannerComponentProps {
    authService: AuthService;
}

const BannerComponent = (props: BannerComponentProps) => {
    const { authService } = props;

    const referenceApi = useMemo(() => {
        return new ReferenceApi(authService);
    }, [authService]);

    const [isLoading, setIsLoading] = useState(true);
    const [messagePractitioner, setMessagePractitioner] = useState('');

    // initial load of any existing data - Banner list
    useEffect(() => {
        setIsLoading(true);
        // reference data load
        referenceApi
            .getAllBannerTexts()
            .then((response: any) => {
                const bannerTexts = response as IBannerText[];
                if (bannerTexts && bannerTexts.length > 0) {
                    bannerTexts.forEach((item: IBannerText) => {
                        if (item.bannerDefaultId === BannerTextType.PractictionerPortalBanner) {
                            setMessagePractitioner(item.bannerText);
                            setIsLoading(false);
                        }
                        /* if (item.bannerDefaultId === BannerTextType.RegisterPortalBanner) {
                        setMessageRegister(item.bannerText);
                    } */
                    });
                }
            })
            .catch((error: any) => {
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // determine whether a banner exists and show it if exists
    if (isLoading === false && messagePractitioner && messagePractitioner.length > 0) {
        return <div className={bannerText}>{messagePractitioner}</div>;
    }
    return null;
};

export default BannerComponent;
