import React from 'react';
import EditFormMenuContainer from './EditFormMenuContainer';
import styles from './site.module.scss';

const { pageDetails } = styles;

interface UpdatePageContainerProps {
    pageTitle?: string;
    menuItemDisplayName: string;
    showBackToManageComplaints?: boolean;
    backLinkUrl?: string;
    backLinkText?: string;
    children?: React.ReactNode;
}

const UpdatePageContainer: React.FC<UpdatePageContainerProps> = ({ ...props }) => {
    return (
        <div className='container-fluid'>
            <div className='row pb-5 ml-2'>
                <EditFormMenuContainer
                    menuItemDisplayName={props.menuItemDisplayName}
                    showBackToManageComplaints={props.showBackToManageComplaints}
                    backLinkUrl={props.backLinkUrl}
                    backLinkText={props.backLinkText}
                />
                <div className={pageDetails}>{props.children}</div>
            </div>
        </div>
    );
};

export default UpdatePageContainer;
