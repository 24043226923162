import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GuidanceText from '../../controls/GuidanceText';
import HelpText from '../../controls/HelpText';
import MandatoryLabelIndicator from './MandatoryLabelIndicator';
import styles from './Fields.module.scss';

const { fieldTitle, fieldDisplayNameFontSizeLarger, fieldFinalRow, fieldContent } = styles;

export interface LabelFieldProps {
    id: string;
    displayName: string;
    value: string;
    isMandatory: boolean;
    autoFocus?: boolean;
    guidanceText?: string;
    helpHeaderText?: string;
    helpText?: string;
    textCapitalizeOn?: boolean;
    isHorizontal?: boolean;
    hideFinalEmptyRow?: boolean;
    children?: any;
    isDisplayNameFontSizeLarger?: boolean;
    isTitleHtag?: boolean;
}

const LabelField = (props: LabelFieldProps) => {
    const {
        id,
        displayName,
        value,
        isMandatory,
        guidanceText,
        helpHeaderText,
        helpText,
        textCapitalizeOn,
        isHorizontal,
        hideFinalEmptyRow = false,
        children,
        isDisplayNameFontSizeLarger = false,
        isTitleHtag = false,
    } = props;

    if (isHorizontal === true) {
        // no help text just simple horizontal layout
        return (
            <>
                {displayName && displayName !== '' && (
                    <>
                        <Row>
                            <Col className={isDisplayNameFontSizeLarger === true ? fieldDisplayNameFontSizeLarger : undefined}>
                                <span className={fieldTitle}>
                                    {isMandatory && <MandatoryLabelIndicator />}
                                    {displayName}
                                </span>
                            </Col>
                            {value && value !== '' && (
                                <Col>
                                    <label id={id} className={`${textCapitalizeOn ? 'text-capitalize' : ''} ${fieldContent}`}>
                                        {value}
                                    </label>
                                </Col>
                            )}
                            {children && <Col>{children}</Col>}
                        </Row>
                    </>
                )}
                {!hideFinalEmptyRow && (
                    <Row className={fieldFinalRow}>
                        <Col />
                    </Row>
                )}
            </>
        );
    }

    return (
        <>
            {displayName && displayName !== '' && isTitleHtag === true && (
                <Row>
                    <Col className={isDisplayNameFontSizeLarger === true ? fieldDisplayNameFontSizeLarger : undefined}>
                        <h4 style={{ margin: '0', marginTop: '0.2rem', marginBottom: '0.2rem' }}>
                            {isMandatory && <MandatoryLabelIndicator />}
                            {displayName}
                        </h4>
                    </Col>
                </Row>
            )}
            {displayName && displayName !== '' && isTitleHtag === false && (
                <Row>
                    <Col className={isDisplayNameFontSizeLarger === true ? fieldDisplayNameFontSizeLarger : undefined}>
                        <span className={fieldTitle}>
                            {isMandatory && <MandatoryLabelIndicator />}
                            {displayName}
                        </span>
                    </Col>
                </Row>
            )}
            {guidanceText && (
                <Row>
                    <Col>
                        <GuidanceText guidanceText={guidanceText} fieldName={id} />
                    </Col>
                </Row>
            )}
            {value && value !== '' && (
                <Row>
                    <Col>
                        <label id={id} className={`${textCapitalizeOn ? 'text-capitalize' : ''}`}>
                            {value}
                        </label>
                    </Col>
                </Row>
            )}
            {children && (
                <Row>
                    <Col>{children}</Col>
                </Row>
            )}
            {helpHeaderText && (
                <Row>
                    <Col>
                        <HelpText helpHeaderText={helpHeaderText} helpText={helpText} fieldName={id} />
                    </Col>
                </Row>
            )}
            {!hideFinalEmptyRow && (
                <Row className={fieldFinalRow}>
                    <Col />
                </Row>
            )}
        </>
    );
};

export default LabelField;
