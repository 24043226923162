import React from 'react';
import styles from './WizardMenuItem.module.scss';

const { container, fixedLeftColumn, flexRightColumn } = styles;

interface WizardMenuItemProps {
    iconElement: any;
    displayName: string;
}

export const WizardMenuItem = (props: WizardMenuItemProps) => {
    return (
        <div className={container}>
            <div className={fixedLeftColumn}>{props.iconElement}</div>
            <div className={flexRightColumn}>
                <span>{props.displayName}</span>
            </div>
        </div>
    );
};
