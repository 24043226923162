import { EditMode, NavigationDestination } from '../../services/Enums';
import { AppContextInterface } from '../../stateManagement/context/AppContext';

export interface EditFormState {
    showSaveChangesDialog: boolean;
    triggerSubmit: boolean;
    formIsDirty: boolean;
    validationSchema: any | undefined;
    editMode: EditMode;
}

export const initialEditFormState: EditFormState = {
    showSaveChangesDialog: false,
    triggerSubmit: false,
    formIsDirty: false,
    validationSchema: undefined,
    editMode: EditMode.New,
};

export enum EditFormStateActionTypeEnums {
    initialise,
    repaint,
    showSaveChangesDialog,
    hideSaveChangesDialog,
    showDiscardChangesDialog,
    hideDiscardChangesDialog,
    setTriggerSubmit,
    unsetTriggerSubmit,
    setFormIsDirty,
    unsetFormIsDirty,
    setValidationSchema,
    setEditMode,
}

export function reducerEditFormState(
    state: any,
    action: {
        type: EditFormStateActionTypeEnums;
        validationSchema: any | undefined;
        editMode: EditMode;
    },
) {
    switch (action.type) {
        case EditFormStateActionTypeEnums.initialise:
            return {
                ...state,
            };

        case EditFormStateActionTypeEnums.repaint:
            return {
                ...state,
            };

        case EditFormStateActionTypeEnums.showSaveChangesDialog:
            return {
                ...state,
                showSaveChangesDialog: true,
            };

        case EditFormStateActionTypeEnums.hideSaveChangesDialog:
            return {
                ...state,
                showSaveChangesDialog: false,
            };

        case EditFormStateActionTypeEnums.showDiscardChangesDialog:
            return {
                ...state,
                showDiscardChangesDialog: true,
            };

        case EditFormStateActionTypeEnums.hideDiscardChangesDialog:
            return {
                ...state,
                showDiscardChangesDialog: false,
            };

        case EditFormStateActionTypeEnums.setTriggerSubmit:
            return {
                ...state,
                triggerSubmit: true,
            };

        case EditFormStateActionTypeEnums.unsetTriggerSubmit:
            return {
                ...state,
                triggerSubmit: false,
            };

        case EditFormStateActionTypeEnums.setFormIsDirty:
            return {
                ...state,
                formIsDirty: true,
            };

        case EditFormStateActionTypeEnums.unsetFormIsDirty:
            return {
                ...state,
                formIsDirty: false,
            };

        case EditFormStateActionTypeEnums.setValidationSchema:
            return {
                ...state,
                validationSchema: action.validationSchema,
            };

        case EditFormStateActionTypeEnums.setEditMode:
            return {
                ...state,
                editMode: action.editMode,
            };
        default:
            return {
                ...state,
            };
    }
}

export function HideSaveChangesDialogEditForm(appContext: AppContextInterface) {
    appContext.dispatchEditFormState({ type: EditFormStateActionTypeEnums.hideSaveChangesDialog });
}

export function ShowSaveChangesDialogEditForm(appContext: AppContextInterface) {
    appContext.dispatchEditFormState({ type: EditFormStateActionTypeEnums.showSaveChangesDialog });
}

export function HideDiscardChangesDialogEditForm(appContext: AppContextInterface) {
    appContext.dispatchEditFormState({ type: EditFormStateActionTypeEnums.hideDiscardChangesDialog });
}

export function ShowDiscardChangesDialogEditForm(appContext: AppContextInterface) {
    appContext.dispatchEditFormState({ type: EditFormStateActionTypeEnums.showDiscardChangesDialog });
}

export function SetValidationSchema(appContext: AppContextInterface, validationSchema: any | undefined) {
    appContext?.dispatchEditFormState({ type: EditFormStateActionTypeEnums.setValidationSchema, validationSchema });
}

export function SetIsUserInEditFormFlag(appContext: AppContextInterface) {
    // set flag to store we are now in the wizard to change the menu shown
    if (appContext !== undefined && appContext?.isUserInOutletEditForm === false) {
        appContext.setIsUserInOutletEditForm(true);
    }
}

export function ClearIsUserInEditFormFlag(appContext: AppContextInterface) {
    // set flag to store we are now in the wizard to change the menu shown
    if (appContext !== undefined && appContext?.isUserInOutletEditForm === true) {
        appContext.setIsUserInOutletEditForm(false);
    }
}

export function IsUserInOutletEditForm(appContext: AppContextInterface) {
    return appContext.isUserInOutletEditForm;
}

export function IsOutletEditFormDirty(appContext: AppContextInterface) {
    return appContext?.editFormState.formIsDirty;
}

export function SetDestinationNavActionOnSuccessEditForm(appContext: AppContextInterface, navigationDestination: NavigationDestination) {
    appContext?.setDestinationNavActionOnSuccess(navigationDestination);
}

export function SetEditMode(appContext: AppContextInterface, editMode: EditMode) {
    appContext?.dispatchEditFormState({ type: EditFormStateActionTypeEnums.setEditMode, editMode });
}
