import React, { useContext, useRef, useState, useMemo, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { get } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PageFieldsTitle from '../../../../components/fields/PageFieldsTitle';
import PageMandatoryLabelText from '../../../../components/fields/PageMandatoryLabelText';
import PageWrapper from '../../../../components/pageWrapper';
import {
    MODALBUTTON_NO_DISCARDCHANGES,
    MODALBUTTON_YES_DISCARDCHANGES,
    MODALHEADER_UNSAVEDCHANGES_DATALOSSPOTENTIAL,
    MODALHEADER_UPDATEDETAILS_OK,
    MODALTEXT_UNSAVEDCHANGES_DATALOSSPOTENTIAL,
    MODALTEXT_UPDATECOMPLAINTSDETAILS_OK_NEEDSREVIEW_BACKTOLANDING,
    NOTE_PROFINDEMNITYINSURANCE_WARNING_PROFASSOC,
    NOTE_INSURANCE_PERIOD_WARNING,
    NOTE_INSURANCE_START_DATE_IN_FUTURE,
    NOTE_PROFINDEMNITYINSURANCE_WARNING_LEGALAIDPANELLISTS,
    PLACEHOLDER_DATE,
    TIP_COMPLAINTSMECHANISM_DETAILS_EDIT_INSURANCE,
    HEADER_COMPLAINTS,
    MODALHEADER_REMOVE_INSURANCE,
    MODALTEXT_REMOVE_INSURANCE_P1,
    MODALTEXT_REMOVE_INSURANCE_P2,
    MODALTEXT_REMOVE_INSURANCE_P3,
    MODALBUTTON1_YES,
    MODALBUTTON2_NO,
    CLEANINGUP_PRIORDATA,
    UNINITIALISED,
    MODALTEXT_REMOVEINSURANCE_FROMPRIVATECM_P1,
    MODALTEXT_REMOVEINSURANCE_FROMPRIVATECM_P2,
    MODALHEADER_REMOVEINSURANCE_FROMPRIVATECM,
} from '../../../../services/Constants';
import {
    AjaxCallStateEnum,
    AttachmentDocumentType,
    ComplaintsMechanismType,
    ComplaintsProcessType,
    NavigationDestination,
    RelationshipToEmployerType,
    WizardStepNumberComplaintsMech,
} from '../../../../services/Enums';
import {
    VALIDATION_DISCARD_OK_POPUP_MESSAGE,
    VALIDATION_ERROR_POPUP_MESSAGE,
    VALIDATION_ERROR_UNKNOWN_MESSAGE,
    VALIDATION_SUCCESS_POPUP_MESSAGE,
} from '../../../../services/Validation';

import { ComplaintsMechanismEditPageProps } from '../../../../pages/PageInterfaces';
import { SubmitThisPageIfActive } from '../../../../pages/PageFunctions';
import ApplicationActions from '../../../../actions/applicationActions';
import AttachmentActions from '../../../../actions/attachmentsActions';
import { toastErrorMessage, toastSuccessMessage } from '../../../../actions/toastrMessages';
import AppContext from '../../../../stateManagement/context/AppContext';

import { ApplicationIsLoaded, ErrorOnSubmitForm, IsAnyAttachmentsExistOfThisTypeForThisOrNonSavedComplaint } from '../../../../utils/AppUtils';
import { ParseBool, Sleep } from '../../../../utils/Common';
import {
    ActivateChosenComplaintsMechanism,
    GetActiveInsuranceComplaintAttachmentIdCollection,
    InsertAttachmentToLocalInquiryObject,
    IsActiveComplaintLegalAidOtherOnPanel,
    IsActiveComplaintProfAssocMember,
    RemoveAttachmentFromLocalInquiryObject,
    RemoveAttachmentsForInsuranceWhenChanges,
    RemoveCMAttachmentsForThisCM,
} from '../../../../utils/ComplaintsUtils';
import { isDateExpired, WithinThirtyDays } from '../../../../utils/Dates';
import { IApplication, IAttachmentMetadata, IComplaintMechanism } from '../../../../api/interfacesApi';
import TipsControl from '../../../../controls/TipsControl';
import { EmptyRow } from '../../../../controls/EmptyRow';
import ErrorSummary from '../../../../controls/ErrorSummary';
import ModalDialog from '../../../../controls/ModalDialog';
import { ModalDialogConfirmable } from '../../../../controls/ModalDialogConfirmable';
import Loader from '../../../../controls/Loader';
import NoteText from '../../../../controls/NoteText';
import LabelField from '../../../../components/fields/LabelField';
import FileUploadField from '../../../../components/fields/FileUploadField';
import RadioButtonGroupField, { RadioButtonOption } from '../../../../components/fields/RadioButtonGroupField';
import PageTitle from '../../../../components/pageTitle';
import { GoingBackwardsAndDiscarding, SetDestinationNavActionOnSuccess } from '../../../../components/wizard/wizardStateManager';
import InputDateField from '../../../../components/fields/InputDateField';
import { MoveComplaintsWizardBackward } from '../wizard/complaintsMechWizardFunctions';
import Guid from '../../../../services/Guid';
import styles from '../../../../pages/Pages.module.scss';

const { childItems, controlMaxWidth, startDateWarningNote } = styles;

interface UIstate {
    showSubmittedDialogOK: boolean;
    // filesExist: boolean;
    showInsuranceDetailsFields: boolean;
    showPeriodOfInsuranceWarning: boolean;
    showFromDateInFutureWarning: boolean;
    showNoInsuranceLegalAidPanellistWarning: boolean;
    showNoInsuranceProfAssocWarning: boolean;
    attemptingSave: boolean;
    attemptingSaveThenRemovePriorData: boolean;
    attemptingRemoveInsuranceFlow: boolean;
    discardingChangesFlow: boolean;
    getAppForInitialDiscardProcess: boolean;
}

const initUiState: UIstate = {
    showSubmittedDialogOK: false,
    // filesExist: false,
    showInsuranceDetailsFields: false,
    showPeriodOfInsuranceWarning: false,
    showFromDateInFutureWarning: false,
    showNoInsuranceLegalAidPanellistWarning: false,
    showNoInsuranceProfAssocWarning: false,
    attemptingSave: false,
    attemptingSaveThenRemovePriorData: false,
    attemptingRemoveInsuranceFlow: false,
    discardingChangesFlow: false,
    getAppForInitialDiscardProcess: false,
};

const ComplaintsMechanismInsurancePage = (props: ComplaintsMechanismEditPageProps) => {
    const {
        addNewComplaint,
        resumeAddingNew,
        inquiry,
        setInquiryObj,
        // attachments,
        goingBackwards,
        authService,
        dispatch,
        control,
        // handleClickPrevious,
        handleSubmit,
        register,
        // unregister,
        trigger,
        setValue,
        // getValues,
        setError,
        watch,
        ajaxCallStateForPage,
        backEndValidationErrors,
        clearErrors,
        errors,
        isValid,
        isDirty,
        isActive,
        triggerSubmit,
        setTriggerSubmit,
        reset,
        dirtyFields,
        touchedFields,
        triggerChildFormDiscardChanges,
        // hideBackButton,
    } = props;

    // const history = useHistory();
    const appContext = useContext(AppContext);
    const formRef = useRef();
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const attachmentActions = useMemo(() => {
        return new AttachmentActions(authService);
    }, [authService]);
    const application: IApplication = useSelector((stateApp: any) => stateApp.application);
    // const complaintsMechanism = useSelector((state: any) => state.complaintsMechanism?.complaintsMechanism);

    // even though we have redux states, we HAVE to capture the attempt due to navigation and Get and Save user calls on the page
    const [displayState, setDisplayState] = useState(initUiState);
    const [filesExist, setFilesExist] = useState(false); // insurance files uploaded list
    const [isDataLoaded, setIsDataLoaded] = useState(true); // needs to be TRUE for when this page is loaded as part of wizard pages collection

    const hasInsuranceWatched: boolean | null = watch(
        'inquiry.application.activeComplaintsMechanismObj.hasProfessionalIndemnityInsuranceViaMembership',
        inquiry.application.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership,
    );
    const insuranceFromDateWatched: Date | null = watch(
        'inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceFromDate',
        inquiry.application.activeComplaintsMechanismObj?.professionalIndemnityInsuranceFromDate,
    );
    const insuranceEndDateWatched: Date | null = watch(
        'inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceToDate',
        inquiry.application.activeComplaintsMechanismObj?.professionalIndemnityInsuranceToDate,
    );

    const noInsuranceNeeded =
        inquiry.application?.activeComplaintsMechanismObj?.complaintsMechanismType === ComplaintsMechanismType.GovernmentFundedFDRService &&
        inquiry.application?.activeComplaintsMechanismObj?.relationshipToOrganisation === RelationshipToEmployerType.Employee;

    // if practitioner is Legal Aid/Other AND selected is on Panel
    const showLegalAidOrOtherNote = IsActiveComplaintLegalAidOtherOnPanel(inquiry.application);
    // if practitioner is prof assoc member
    const showProfAssocNote = IsActiveComplaintProfAssocMember(inquiry.application);

    // reset state
    useEffect(() => {
        if (isActive === true) {
            setDisplayState({ ...initUiState });
            setIsDataLoaded(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // load notes intially
    useEffect(() => {
        if (isActive === true) {
            if (
                displayState.showNoInsuranceLegalAidPanellistWarning === true &&
                inquiry.application.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership === true
            ) {
                setDisplayState({ ...displayState, showNoInsuranceLegalAidPanellistWarning: false });
            }

            if (
                displayState.showNoInsuranceLegalAidPanellistWarning !== showLegalAidOrOtherNote &&
                inquiry.application.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership === false
            ) {
                setDisplayState({ ...displayState, showNoInsuranceLegalAidPanellistWarning: showLegalAidOrOtherNote });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (triggerChildFormDiscardChanges && isActive === true) {
            handleClickPreviousButton(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerChildFormDiscardChanges]);

    useEffect(() => {
        if (isActive === true) {
            trigger(); // refresh validations
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insuranceEndDateWatched]);

    // show/hide period of insurance warning note
    if (displayState.showPeriodOfInsuranceWarning === false && hasInsuranceWatched && insuranceEndDateWatched && WithinThirtyDays(insuranceEndDateWatched)) {
        setDisplayState({ ...displayState, showPeriodOfInsuranceWarning: true });
    }

    if (
        displayState.showPeriodOfInsuranceWarning === true &&
        (hasInsuranceWatched === false || insuranceEndDateWatched === null || !WithinThirtyDays(insuranceEndDateWatched))
    ) {
        setDisplayState({ ...displayState, showPeriodOfInsuranceWarning: false });
    }

    // show/hide No Insurance - Prof Assoc member note
    if (ParseBool(hasInsuranceWatched) === true) {
        if (displayState.showNoInsuranceProfAssocWarning === true) {
            setDisplayState({ ...displayState, showNoInsuranceProfAssocWarning: false });
        }
        if (displayState.showInsuranceDetailsFields === false) {
            setDisplayState({ ...displayState, showInsuranceDetailsFields: true });
        }
    }
    // need to do this check as when user first arrives for an Add nothing is entered
    if (hasInsuranceWatched !== undefined && hasInsuranceWatched !== null && ParseBool(hasInsuranceWatched) === false) {
        if (displayState.showNoInsuranceProfAssocWarning === false && showLegalAidOrOtherNote === false) {
            setDisplayState({ ...displayState, showNoInsuranceProfAssocWarning: true });
        }
        if (displayState.showInsuranceDetailsFields === true) {
            setDisplayState({ ...displayState, showInsuranceDetailsFields: false });
        }
    }

    const displayBackEndValidations = () => {
        // could be on each of the fields
        if (backEndValidationErrors.errors?.length > 0) {
            backEndValidationErrors.errors.forEach((validationError: { propertyName: any; errorMessage: any }) => {
                switch (validationError.propertyName) {
                    case 'ComplaintsProcessType':
                        setError('inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType', {
                            type: 'manual',
                            message: validationError.errorMessage,
                        });
                        break;
                    default:
                        // should not get a validation errors set without a single error object
                        toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
                        break;
                }
            });
        } else {
            // should not get a validation errors set without a single error object
            toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
        }
    };

    const processOriginalApplicationForDiscardChanges = () => {
        //        if (displayState.getAppForInitialDiscardProcess === true && ApplicationIsLoaded(origApplication, origApplication.applicationId)) {
        // discard changes flow
        setDisplayState({ ...displayState, getAppForInitialDiscardProcess: false });

        // finally update inquiry minus any attachments and changes then go backwards
        inquiry.application = application;

        // handles when adding new CMand it gets an Id by this page
        // setValue('inquiry.application.activeComplaintsMechanismObj', inquiry.application.activeComplaintsMechanismObj);

        // if (addNewComplaint === true && resumeAddingNew === true) {
        ActivateChosenComplaintsMechanism(appContext, inquiry.application, appContext.currentComplaintsMechId);
        /* } else {
                if (addNewComplaint === true && resumeAddingNew === false) {
                    ActivateEmptyComplaintsMechanism(appContext, inquiry.application);
                } else {
                    ActivateChosenComplaintsMechanism(appContext, inquiry.application, appContext.currentComplaintsMechIndex);
                }
            } */

        setInquiryObj(inquiry); // TODO: Check CM and activeCM within this app
        setValue('inquiry', inquiry); // weld it to the form

        // reset would make the values update for the useForm functionality
        reset();

        Sleep(1200).then(() => {
            //// allow time for attachments to be removed, ... (DO NOT: then update application now with removed attachments data)
            // setDisplayState({ ...displayState, updateAppForDiscardProcess: true });
            // after removal of attachments and updating the inquiry obj we can now go backwards for discard flow and end it
            setDisplayState({
                ...displayState,
                getAppForInitialDiscardProcess: false,
                discardingChangesFlow: false,
            });

            toastSuccessMessage(VALIDATION_DISCARD_OK_POPUP_MESSAGE);
            goBackOneWizardStep();
        });
    };

    // check dispatch success state
    useEffect(() => {
        if (isActive === true && ajaxCallStateForPage && ajaxCallStateForPage.pageNumber === WizardStepNumberComplaintsMech.ComplaintsMechanismInsurance) {
            // if these are errors for this page then show them, highly unlikely as validation from front to back needs to be in sync
            if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithValidation && backEndValidationErrors) {
                // ONLY time we do not show these is if in the middle of switching between options AND user has changed mind to delete an option
                // if (attemptingSaveThenRemovePriorData !== true) {
                setDisplayState({
                    ...displayState,
                    showSubmittedDialogOK: false,
                    attemptingSave: false,
                    attemptingSaveThenRemovePriorData: false,
                    attemptingRemoveInsuranceFlow: false,
                    discardingChangesFlow: false,
                });
                setIsDataLoaded(true);
                // back end validation need to pump errors into yup and display them as per Error Summary, and prevent navigation
                displayBackEndValidations();
                // }
            } else if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedWithError) {
                // back end error need to display, and prevent navigation
                setDisplayState({
                    ...displayState,
                    showSubmittedDialogOK: false,
                    attemptingSave: false,
                    attemptingSaveThenRemovePriorData: false,
                    attemptingRemoveInsuranceFlow: false,
                    discardingChangesFlow: false,
                });
                setIsDataLoaded(true);
                toastErrorMessage(VALIDATION_ERROR_UNKNOWN_MESSAGE);
            } else {
                // ONLY move forward if we are not triggered by the Save Changes dialog (which implies moving out/backwards)
                if (ajaxCallStateForPage.ajaxCallState === AjaxCallStateEnum.CompletedOK && !appContext.wizardState.triggerSubmit) {
                    // attempting submit OK
                    if (displayState.attemptingSave === true) {
                        // IF VALID (or OK to move forward):

                        // // refresh data set now after changes saved
                        // dispatch(appActions.GetApplication(doNotCopyActiveComplaint));
                        toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);

                        // either case needs a review now so we just display it
                        // ProcessSuccessfulSaveRecordPostOperation();
                        // pop up success dialog and then user will decide what flow to go to next
                        setDisplayState({ ...displayState, showSubmittedDialogOK: true, attemptingSave: false, attemptingSaveThenRemovePriorData: false });
                    } else if (displayState.attemptingSaveThenRemovePriorData === true) {
                        // removal of prior data from change mind
                        setDisplayState({ ...displayState, showSubmittedDialogOK: false, attemptingSave: false, attemptingSaveThenRemovePriorData: false });
                        // NOTE: user changed selection so we CANNOT refresh data here!
                        toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);
                        Sleep(500).then(() => {
                            trigger(); // refresh validation
                        });
                    } else if (displayState.attemptingRemoveInsuranceFlow === true) {
                        setDisplayState({ ...displayState, attemptingRemoveInsuranceFlow: false });

                        Sleep(500).then(() => {
                            // navigate to the View UI
                            window.location.href = '/managecomplaintsmechanism?reload=true'; // reload all data (window.location.href removes state)
                        });
                    } else if (displayState.getAppForInitialDiscardProcess === true && ApplicationIsLoaded(application, application.applicationId)) {
                        // discard changes flow
                        processOriginalApplicationForDiscardChanges();
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, ajaxCallStateForPage]); // dont check other things that may change causing the timing of this to be off

    const submitForm = (data: any) => {
        if (isValid) {
            // set existing data now as we dont capture it in this page
            //  EnsureData(appContext, WizardStepNumberComplaintsMech.ComplaintsMechanismType, data, inquiry);

            // Save changes to active complaints mech (attempt save, if that fails we do NOT move the Wizard step)
            setDisplayState({ ...displayState, attemptingSave: true });

            if (
                data.inquiry.application &&
                (data.inquiry.application.activeComplaintsMechanismObj.ComplaintsProcessType === undefined ||
                    data.inquiry.application.activeComplaintsMechanismObj.ComplaintsProcessType === null)
            ) {
                data.inquiry.application.activeComplaintsMechanismObj.ComplaintsProcessType =
                    inquiry.application.activeComplaintsMechanismObj?.complaintsProcessType;
            }

            // if the outlet has been selected via FundedOrgComponent it is not welded to the form properly we check now and update that\
            if (
                data.inquiry.application.activeComplaintsMechanismObj?.outlet === null &&
                inquiry.application.activeComplaintsMechanismObj?.outlet !== null &&
                inquiry.application.activeComplaintsMechanismObj?.outlet.outletId !== ''
            ) {
                data.inquiry.application.activeComplaintsMechanismObj.outlet = inquiry.application.activeComplaintsMechanismObj?.outlet;
            }

            dispatch(appActions.UpdateComplaintsMechanism(data.inquiry?.application?.activeComplaintsMechanismObj));
        } else {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
        }
    };

    const redirectToReviewAndSubmitForm = () => {
        // to Review and Submit form
        setDisplayState({ ...initUiState });

        // history.push('/managedetailsreviewandsubmitpage');
        window.location.href = '/managedetailsreviewandsubmitpage'; // reload all data (window.location.href removes state)
    };

    const navigateToComplaintsMechLandingPage = () => {
        setDisplayState({ ...initUiState });

        // history.push('/managecomplaintsmechanism');
        window.location.href = '/managecomplaintsmechanism'; // reload all data (window.location.href removes state)
    };

    const handleClickToReviewAndSubmit = () => {
        redirectToReviewAndSubmitForm();
    };

    const handleClickBackToManageCM = () => {
        navigateToComplaintsMechLandingPage();
    };

    // based upon the original value, we remove data as the user has changed their option whilst the data already exists
    const removePriorData = () => {
        // always remove the insurance type attachments, if exists
        RemoveAttachmentsForInsuranceWhenChanges(attachmentActions, dispatch, inquiry);

        // clear this in case user switched and did not add a file but trips validation
        setValue('inquiry.application.activeComplaintsMechanismObj.activeAttachmentId', '');
        inquiry.application.activeComplaintsMechanismObj!.activeAttachmentId = '';
        setValue('inquiry.application.activeComplaintsMechanismObj.attachmentExists', '');
        inquiry.application.activeComplaintsMechanismObj!.attachmentExists = '';

        // if dates were set we clear them now too
        if (insuranceFromDateWatched !== null || insuranceEndDateWatched !== null) {
            setValue('inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceFromDate', undefined);
            inquiry.application.activeComplaintsMechanismObj!.professionalIndemnityInsuranceFromDate = undefined;
            setValue('inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceToDate', undefined);
            inquiry.application.activeComplaintsMechanismObj!.professionalIndemnityInsuranceToDate = undefined;

            setInquiryObj(inquiry);
        }
    };

    const doesPriorDataExistThatWillBeDeleted = () => {
        // ONLY handles case where change from YES to NO (as NO to YES is no data)
        return (
            insuranceFromDateWatched !== null ||
            insuranceEndDateWatched !== null ||
            IsAnyAttachmentsExistOfThisTypeForThisOrNonSavedComplaint(
                inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId,
                AttachmentDocumentType.Insurance,
                inquiry.application.attachments,
            )
        );
    };

    const setHasInsuranceRadioButton = () => {
        // must check one then!
        const radioButtons: NodeListOf<HTMLElement> = document.getElementsByName(
            'inquiry.application.activeComplaintsMechanismObj.hasProfessionalIndemnityInsuranceViaMembership',
        );
        const insuranceString: string = hasInsuranceWatched === true ? 'true' : 'false';

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < radioButtons.length; i++) {
            const radioButton: HTMLInputElement = radioButtons[i] as HTMLInputElement;
            if (radioButton.value === insuranceString!) {
                radioButton.checked = true;
            }
        }
    };

    const doesAnotherActiveCMExist = (editCopyComplaintsMechId: string | undefined) => {
        let retValue = false;

        if (inquiry.application.complaintsMechanism && inquiry.application.complaintsMechanism.length >= 0) {
            inquiry.application.complaintsMechanism.forEach((complaint: IComplaintMechanism) => {
                if (
                    complaint.complaintsMechanismId !== UNINITIALISED &&
                    complaint.complaintsMechanismId !== editCopyComplaintsMechId &&
                    complaint.supercededById !== editCopyComplaintsMechId &&
                    complaint.isDeleted === false &&
                    complaint.isEditCopy === false
                ) {
                    retValue = true;
                }
            });
        }

        return retValue;
    };

    const isPrivateCM = (complaintsMech: IComplaintMechanism) => {
        return (
            complaintsMech.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc &&
            complaintsMech.complaintsProcessType === ComplaintsProcessType.ProfessionalAssociationMembership
        );
    };

    const onChangeHasProfessionalInsurance = async (event: any) => {
        const selectedValue = ParseBool(event.target?.value);

        // prevent the removal of insurance under the following condition ONLY:
        //  - Practitioner has NO other active CMs
        //  - Practitioner is attempting to remove insurance for this edited Private CM
        const isPrivateCMRemovingInsuranceWhereNoOtherExists =
            selectedValue === false &&
            isPrivateCM(inquiry.application.activeComplaintsMechanismObj!) &&
            doesAnotherActiveCMExist(inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId) === false;
        // only check for prior data here if we change from YES to NO
        const dataExistsThatCouldBeDeleted = selectedValue === false && doesPriorDataExistThatWillBeDeleted();

        const modalOptionsOkOnly = (titleIn: string, confirmationP2?: string) => {
            return {
                title: titleIn,
                confirmationP2: confirmationP2,
                okOnly: true,
                isDestructive: true,
                modalBodyPositionTextLeft: false,
            };
        };

        function revertSelection() {
            // Setting the target.checked property to the defaultChecked will
            // deselect the current radio button and select the radio button that
            // was previously selected.
            event.target.checked = event.target.defaultChecked;

            event.preventDefault();
            event.stopPropagation();
            // return the orig value
            setValue(
                `inquiry.application.activeComplaintsMechanismObj.hasProfessionalIndemnityInsuranceViaMembership`,
                hasInsuranceWatched,
                // NULL by here: inquiry.application.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership,
                {
                    shouldDirty: true,
                    shouldValidate: true,
                },
            );
            setHasInsuranceRadioButton();
        }

        function changeSelection() {
            // manage the value manually as ref did not work for the react hook form
            setValue('inquiry.application.activeComplaintsMechanismObj.hasProfessionalIndemnityInsuranceViaMembership', selectedValue, {
                shouldDirty: true,
                shouldValidate: true,
            });

            inquiry.application.activeComplaintsMechanismObj!.hasProfessionalIndemnityInsuranceViaMembership = selectedValue;
            setInquiryObj(inquiry);

            // change whether file is mandatory
            setValue('inquiry.application.CMInsuranceDocumentAttachmentIsMandatory', selectedValue);

            // show/hide notes
            if (selectedValue === true) {
                setDisplayState({
                    ...displayState,
                    showNoInsuranceLegalAidPanellistWarning: false,
                    showNoInsuranceProfAssocWarning: false,
                });
            } else {
                // show other notes for a No value, hide others
                // always hide the note for period of insurance if No selected
                setDisplayState({
                    ...displayState,
                    showPeriodOfInsuranceWarning: false,
                    showNoInsuranceLegalAidPanellistWarning: showLegalAidOrOtherNote,
                    showNoInsuranceProfAssocWarning: showProfAssocNote,
                });
            }

            Sleep(300).then(() => {
                trigger(); // refresh validation
            });
        }

        if (isPrivateCMRemovingInsuranceWhereNoOtherExists === true) {
            // show Modal, removes EditCopy and we leave back to view UI
            await ModalDialogConfirmable(
                MODALTEXT_REMOVEINSURANCE_FROMPRIVATECM_P1,
                undefined,
                undefined,
                modalOptionsOkOnly(MODALHEADER_REMOVEINSURANCE_FROMPRIVATECM, MODALTEXT_REMOVEINSURANCE_FROMPRIVATECM_P2),
            );
            // remove this Edit Copy (and its attachments)
            const complaintMechanism = inquiry.application.activeComplaintsMechanismObj!;
            RemoveCMAttachmentsForThisCM(attachmentActions, dispatch, inquiry, complaintMechanism.complaintsMechanismId);

            // need a state to be able to move on once complete
            setDisplayState({
                ...displayState,
                attemptingRemoveInsuranceFlow: true,
            });

            dispatch(appActions.DiscardChangesComplaintsMechanism(complaintMechanism));
        } else {
            if (dataExistsThatCouldBeDeleted === true) {
                // prompt if user changes type and has previously submitted data, which will be deleted
                if (
                    await ModalDialogConfirmable(MODALTEXT_REMOVE_INSURANCE_P1, MODALBUTTON1_YES, MODALBUTTON2_NO, {
                        title: MODALHEADER_REMOVE_INSURANCE,
                        confirmationP2: MODALTEXT_REMOVE_INSURANCE_P2,
                        confirmationP3: MODALTEXT_REMOVE_INSURANCE_P3,
                        modalBodyPositionTextLeft: false,
                        dataTestId: 'modalPromptToRemoveData_ChangeProfInsurance',
                        isDestructive: true,
                    })
                ) {
                    changeSelection();
                    // user has accepted that data can be removed so we remove that now
                    removePriorData();
                } else {
                    revertSelection();
                }
            } else {
                changeSelection();
            } // end check for if other data exists and will be deleted
        } // end check for remove insurance when private CM and other exists
    };

    const removeErrorsAndReValidate = () => {
        // do the trigger only once
        let runTrigger = false;
        const errorAttachments: { [key: string]: any[] } = get(errors, 'inquiry.application.attachments');

        if (errorAttachments && errorAttachments !== undefined) {
            if (errorAttachments.insurance) {
                clearErrors('inquiry.application.attachments.insurance');
                runTrigger = true;
            }
        }

        if (runTrigger === true) {
            trigger(); // reset validation so we dont get a form isValid=false causing inability to save
        }
    };

    const fromDateInFutureWarningNote = () => {
        return (
            <>
                <Row className={`${startDateWarningNote} ${controlMaxWidth}`}>
                    <Col md='11'>
                        <NoteText displayText={NOTE_INSURANCE_START_DATE_IN_FUTURE} />
                    </Col>
                    <Col md='1' className='justify-content-end' />
                </Row>
                <Row className={controlMaxWidth}>
                    <Col>&nbsp;</Col>
                </Row>
            </>
        );
    };

    const periodOfInsuranceWarningNote = () => {
        return (
            <>
                <Row className={`${startDateWarningNote} ${controlMaxWidth}`}>
                    <Col md='11'>
                        <NoteText displayText={NOTE_INSURANCE_PERIOD_WARNING} />
                    </Col>
                    <Col md='1' className='justify-content-end' />
                </Row>
                <Row className={controlMaxWidth}>
                    <Col>&nbsp;</Col>
                </Row>
            </>
        );
    };

    // update attachments collection as that is seperate from other data application object call
    const updatedFilesList = (files: any) => {
        if (files && files.length > 0) {
            if (filesExist === false) {
                setFilesExist(true);
                // immediately remove any errors of type file not uploaded (for parent nodes)
                removeErrorsAndReValidate();
            }
        } else {
            if (filesExist === true) {
                setFilesExist(false);
            }
        }
    };

    const removedFile = (attachmentId: string) => {
        // refresh CM object/active obj
        RemoveAttachmentFromLocalInquiryObject(attachmentId, setValue, inquiry);
        trigger(); // re-validate
    };

    const addedFile = (newAttachment: IAttachmentMetadata) => {
        // refresh CM object/active obj (NOTE: CANNOT get App in case user simply adds/removes files, it will clear the form!)
        InsertAttachmentToLocalInquiryObject(newAttachment, setValue, inquiry);
        trigger(); // re-validate
    };

    const InsuranceDetailsFileUploadDisplay = () => {
        const idFileUpload = 'inquiry.application.activeComplaintsMechanismObj.attachmentExists';
        const attachmentIdCollection = GetActiveInsuranceComplaintAttachmentIdCollection(inquiry?.application);
        // const errorFieldAttachmentThisIndex = errors.inquiry?.application?.activeComplaintsMechanismObj; // complaintsMechanism?.find((_element: any, i: number) => i === 0); // errors.inquiry?.application?.attachments?.fundedOrg?.find((_element: any, i: number) => i === index);
        // const errorFieldAttachmentInsurance = errorFieldAttachmentThisIndex && errorFieldAttachmentThisIndex?.activeAttachmentId;

        return (
            <FileUploadField
                id={idFileUpload} // we need to render this when relationship is undefined or other, as when handing it the docType if it dynamically changes the FileUpload is not updating
                refreshIfAttachmentIdNotFound={true} // will trigger empty fileInfo if the above Id not yet uploaded/exists
                attachmentIdCollection={attachmentIdCollection}
                maxFilesCount={3}
                applicationId={inquiry?.application.applicationId}
                complaintsProcessId={inquiry?.application.activeComplaintsMechanismObj?.complaintsMechanismId}
                authService={authService}
                displayNameSuffix='insurance'
                errorsFieldSuffix='insurance'
                documentType={AttachmentDocumentType.Insurance}
                isMandatory={true}
                updatedFilesList={updatedFilesList}
                register={register}
                goingBackwards={goingBackwards}
                dispatch={dispatch}
                errorsField={errors.inquiry?.application?.attachments}
                removedFile={removedFile}
                addedFile={addedFile}
                showDisclaimerText={true}
            />
        );
    };

    const insuranceDetailsDisplay = () => {
        if (displayState.showInsuranceDetailsFields === false) {
            return null;
        }

        const today = new Date();
        // tslint:disable-next-line: prefer-const
        let nextYear = new Date();
        nextYear.setFullYear(nextYear.getFullYear() + 1);

        const openingInsuranceFromDate = insuranceFromDateWatched ? insuranceFromDateWatched : today;
        const openingInsuranceToDate = insuranceEndDateWatched ? insuranceEndDateWatched : nextYear;
        const insuranceAttachmentDefault: boolean = true;
        const endDateHeaderText = isDateExpired(insuranceEndDateWatched) === true ? 'End date (expired)' : 'End date';

        return (
            <Row className={childItems}>
                <input
                    type='hidden'
                    className='form-control'
                    name='inquiry.application.CMInsuranceDocumentAttachmentIsMandatory'
                    {...register('inquiry.application.CMInsuranceDocumentAttachmentIsMandatory')}
                    defaultValue={insuranceAttachmentDefault}
                />
                <Row>
                    <Col>
                        <LabelField
                            id='periodInsuranceLabel'
                            displayName='Current insurance dates'
                            value=''
                            isMandatory={false}
                            isDisplayNameFontSizeLarger={true}
                            hideFinalEmptyRow={true}
                        />
                    </Col>
                </Row>
                <Row className={controlMaxWidth}>
                    <Col>
                        <InputDateField
                            id='inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceFromDate'
                            displayName='Start date'
                            defaultValue={
                                inquiry.application.activeComplaintsMechanismObj?.professionalIndemnityInsuranceFromDate
                                    ? inquiry.application.activeComplaintsMechanismObj?.professionalIndemnityInsuranceFromDate
                                    : undefined
                            }
                            isMandatory={true}
                            openToDate={openingInsuranceFromDate}
                            yearDropdownRange={3}
                            applyYearRangeToMaxMin={true}
                            dropdownMode='select'
                            register={register}
                            control={control}
                            disabled={false}
                            placeHolder={PLACEHOLDER_DATE}
                            errorsField={errors.inquiry?.application?.activeComplaintsMechanismObj?.professionalIndemnityInsuranceFromDate}
                            hideFinalEmptyRow={true}
                        />
                    </Col>
                </Row>
                <Row className={controlMaxWidth}>
                    <Col>
                        <InputDateField
                            id='inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceToDate'
                            displayName={endDateHeaderText}
                            defaultValue={
                                inquiry.application.activeComplaintsMechanismObj?.professionalIndemnityInsuranceToDate
                                    ? inquiry.application.activeComplaintsMechanismObj?.professionalIndemnityInsuranceToDate
                                    : undefined
                            }
                            isMandatory={true}
                            openToDate={openingInsuranceToDate}
                            yearDropdownRangeStartingYear={nextYear.getFullYear()}
                            yearDropdownRange={3}
                            applyYearRangeToMaxMin={true}
                            dropdownMode='select'
                            register={register}
                            control={control}
                            disabled={false}
                            placeHolder={PLACEHOLDER_DATE}
                            hideFinalEmptyRow={true}
                            errorsField={errors.inquiry?.application?.activeComplaintsMechanismObj?.professionalIndemnityInsuranceToDate}
                        />
                    </Col>
                </Row>
                {displayState.showFromDateInFutureWarning && fromDateInFutureWarningNote()}
                {displayState.showPeriodOfInsuranceWarning && periodOfInsuranceWarningNote()}
                <Row className={controlMaxWidth}>
                    <Col>{InsuranceDetailsFileUploadDisplay()}</Col>
                </Row>
            </Row>
        );
    };

    const radioOptionsHasInsurance: RadioButtonOption[] = [
        {
            label: 'Yes',
            value: true,
            disabled: false,
            children: insuranceDetailsDisplay(),
        },
        {
            label: 'No',
            value: false,
            disabled: false,
        },
    ];

    const displayInputFields = () => {
        return (
            <>
                <TipsControl tipString={TIP_COMPLAINTSMECHANISM_DETAILS_EDIT_INSURANCE} />
                <EmptyRow />
                <RadioButtonGroupField
                    options={radioOptionsHasInsurance}
                    id='inquiry.application.activeComplaintsMechanismObj.hasProfessionalIndemnityInsuranceViaMembership'
                    displayName='Insurance coverage'
                    fieldLabel='Do you have current professional indemnity insurance?'
                    defaultValue={inquiry.application.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership} // only set default if value exists
                    isMandatory={true}
                    control={control}
                    register={register}
                    errorsField={errors.inquiry?.application?.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership}
                    onChange={onChangeHasProfessionalInsurance}
                />
                {displayState.showNoInsuranceLegalAidPanellistWarning && !displayState.showNoInsuranceProfAssocWarning && (
                    <Row>
                        <Col md='11'>
                            <NoteText displayText={NOTE_PROFINDEMNITYINSURANCE_WARNING_LEGALAIDPANELLISTS} />
                        </Col>
                        <Col md='1' className='justify-content-end' />
                    </Row>
                )}
                {displayState.showNoInsuranceProfAssocWarning && !displayState.showNoInsuranceLegalAidPanellistWarning && (
                    <Row>
                        <Col md='11'>
                            <NoteText displayText={NOTE_PROFINDEMNITYINSURANCE_WARNING_PROFASSOC} />
                        </Col>
                        <Col md='1' className='justify-content-end' />
                    </Row>
                )}
            </>
        );
    };

    const noInsuranceNeededLabel = () => {
        return (
            <Row>
                <Col>
                    <LabelField
                        id='noInsuranceNeededLabel'
                        displayName='No insurance details required for this outlet'
                        value='As you have selected that you’re an employee of a funded organisation, your insurance is provided by the organisation that employs you. There is nothing for you to complete on this page - select the Continue button to proceed.'
                        isMandatory={false}
                        isDisplayNameFontSizeLarger={true}
                        hideFinalEmptyRow={false}
                    />
                </Col>
            </Row>
        );
    };

    const displayFields = () => {
        return noInsuranceNeeded === true ? noInsuranceNeededLabel() : displayInputFields();
    };

    const goBackOneWizardStep = () => {
        // can move backwards - but we MUST clearErrors in case user clicks to attempt to save without the isDirty being set
        clearErrors();
        // dispatchWizardState({ type: WizardStateActionTypeEnums.unsetFormIsDirty });
        SetDestinationNavActionOnSuccess(appContext, NavigationDestination.PreviousWizardStep);

        // const overrideIsDirty = false; // moving back without any changes
        // const overrideIsValid = true; // moving back without any changes
        MoveComplaintsWizardBackward(appContext, appContext.wizardState.triggerSubmit);
    };

    const discardChangesAndGoBack = (backToLandingPage?: boolean) => {
        // Pattern for discard changes is based off reset() not behaving, and attachments which bake straight into the CM and activeCM object
        //  and do not get to the CMProcessAttachment table until a save is done (yes we have to cater for everything)
        //  Need to do the following upon user agreement to remove the 'old' data, as user may go to end of wizard, save some, then come back to
        //  middle page and just change one thing, click back, and so on.
        // Here goes:
        //  - put up a spinner to indicate currently removing old data
        //  - remove attachment if just added (if user removed files they are already gone!)
        //  - read the latest stored App from DB, and put that into our inquiry obj
        //  - go back a page

        // this will prevent the wizard from re-loading whilst we adjust the inquiry object
        GoingBackwardsAndDiscarding(appContext);

        setDisplayState({
            ...displayState,
            discardingChangesFlow: true,
            getAppForInitialDiscardProcess: true,
        });

        // always remove the insurance type attachments, if exists
        RemoveAttachmentsForInsuranceWhenChanges(attachmentActions, dispatch, inquiry);

        inquiry.application.activeComplaintsMechanismObj = null;
        setInquiryObj(inquiry);

        // allow time to remove any files
        Sleep(200).then(() => {
            if (backToLandingPage === true) {
                window.location.href = '/managecomplaintsmechanism?reload=true'; // reload all data (window.location.href removes state)
            } else {
                // call API that will refresh the form
                const doNotCopyActiveComplaint = false;
                // const originalApplication = await
                dispatch(appActions.GetApplication(doNotCopyActiveComplaint));
            }
        });
    };

    const handleClickPreviousButton = async (backToLandingPage?: boolean) => {
        let goBack = false;
        const isDirtyAlt = Object.keys(dirtyFields).length > 0 || Object.keys(touchedFields).length > 0;
        // DOESNT WORK ON EDIT AS FILE IS NOW COPIED IN CLONE: const hasAddedNewFileButNotSaved = IsValidGuidIdentifier(inquiry.application.activeComplaintsMechanismObj?.activeAttachmentId);

        if (!isDirty && !isDirtyAlt) {
            goBack = true;
        } else {
            if (
                await ModalDialogConfirmable(MODALTEXT_UNSAVEDCHANGES_DATALOSSPOTENTIAL, MODALBUTTON_YES_DISCARDCHANGES, MODALBUTTON_NO_DISCARDCHANGES, {
                    title: MODALHEADER_UNSAVEDCHANGES_DATALOSSPOTENTIAL,
                    isDestructive: true,
                    modalBodyPositionTextLeft: false,
                    dataTestId: 'modalPromptToRemoveData_ComplaintsInsuranceBack',
                })
            ) {
                discardChangesAndGoBack(backToLandingPage);
            }
        }

        if (goBack === true) {
            if (backToLandingPage === true) {
                window.location.href = '/managecomplaintsmechanism?reload=true'; // reload all data (window.location.href removes state)
            } else {
                goBackOneWizardStep();
            }
        }
    };

    const pageTitleText =
        addNewComplaint === true || resumeAddingNew === true
            ? 'Add a complaints mechanism for an FDR service outlet'
            : 'Update complaints mechanism for FDR service outlet';

    SubmitThisPageIfActive(isActive, triggerSubmit, formRef, setTriggerSubmit);

    const textForSaveContinueButton = noInsuranceNeeded === true ? 'Continue' : 'Save and Continue';

    if (
        isDataLoaded === false &&
        inquiry.application.activeComplaintsMechanismObj !== undefined &&
        inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId !== undefined &&
        inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId !== null &&
        inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId !== Guid.empty
    ) {
        // NOTE: we MUST check CMId of actveCM before we set this:
        setIsDataLoaded(true);
        // must refresh inquiry obj for attachment collection and active CM
        // setInquiryObj(inquiry);
        // doing this now throws up the render() error
        // // handles when adding new CMand it gets an Id by this page
        // setValue('inquiry.application.activeComplaintsMechanismObj', inquiry.application.activeComplaintsMechanismObj);
    }

    return (
        <>
            <PageTitle title={pageTitleText} description={HEADER_COMPLAINTS} />
            <PageWrapper
                pageName='ComplaintsInsurance'
                formRef={formRef}
                handleSubmit={handleSubmit(submitForm, ErrorOnSubmitForm)}
                hideBackButton={true} // we will now hide the Back button, once user is on insurance page can only go forward and not change mind
                handleClickPrevious={handleClickPreviousButton} // we will now hide the Back button, once user is on insurance page can only go forward and not change mind
                textForSaveContinueButton={textForSaveContinueButton}
            >
                <PageFieldsTitle title='Professional indemnity insurance' />
                <PageMandatoryLabelText />
                <ErrorSummary errors={errors} />
                <ModalDialog
                    dataTestId='UpdateOkDialog'
                    modalTitle={MODALHEADER_UPDATEDETAILS_OK}
                    modalBodyText={MODALTEXT_UPDATECOMPLAINTSDETAILS_OK_NEEDSREVIEW_BACKTOLANDING}
                    showMe={displayState.showSubmittedDialogOK}
                    confirmLabel='Review and submit all of my changes'
                    handleClickYes={handleClickToReviewAndSubmit}
                    abortLabel='Back to Manage complaints mechanism(s) and insurance'
                    handleClickNo={handleClickBackToManageCM}
                />
                {displayState.attemptingSave === true && <Loader isLoading={true} loaderText='Saving, please wait...' />}
                {(displayState.discardingChangesFlow === true || displayState.attemptingSaveThenRemovePriorData === true) && (
                    <Loader isLoading={true} loaderText={CLEANINGUP_PRIORDATA} />
                )}
                {isDataLoaded === false && <Loader isLoading={true} loaderText='Loading data...' />}
                {isDataLoaded === true && (
                    <>
                        <input
                            type='hidden'
                            className='form-control'
                            name='inquiry.application.applicationId'
                            {...register('inquiry.application.applicationId')}
                            defaultValue={inquiry.application.applicationId}
                        />
                        <input
                            type='hidden'
                            className='form-control'
                            name='inquiry.application.accreditationStatus'
                            {...register('inquiry.application.accreditationStatus')}
                            defaultValue={inquiry.application.accreditationStatus}
                        />
                        <input
                            type='hidden'
                            className='form-control'
                            name='inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId'
                            {...register('inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId')}
                            defaultValue={inquiry.application.activeComplaintsMechanismObj?.complaintsMechanismId}
                        />
                        <input
                            type='hidden'
                            className='form-control'
                            name='inquiry.application.activeComplaintsMechanismObj.complaintsProcessType'
                            {...register('inquiry.application.activeComplaintsMechanismObj.complaintsProcessType')}
                            defaultValue={inquiry.application.activeComplaintsMechanismObj?.complaintsProcessType}
                        />
                        <input
                            type='hidden'
                            className='form-control'
                            name='inquiry.application.activeComplaintsMechanismObj.evidence' // to track for dirty flag
                            {...register('inquiry.application.activeComplaintsMechanismObj.evidence')}
                            defaultValue={inquiry.application.activeComplaintsMechanismObj?.evidence}
                        />
                        <input
                            type='hidden'
                            className='form-control'
                            name='inquiry.application.activeComplaintsMechanismObj.professionalAssociation.professionalAssociationId'
                            {...register('inquiry.application.activeComplaintsMechanismObj.professionalAssociation.professionalAssociationId')}
                            defaultValue={inquiry.application.activeComplaintsMechanismObj?.professionalAssociation?.professionalAssociationId}
                        />
                        <input
                            type='hidden'
                            className='form-control'
                            name='inquiry.application.activeComplaintsMechanismObj.outlet.outletId'
                            {...register('inquiry.application.activeComplaintsMechanismObj.outlet.outletId')}
                            defaultValue={inquiry.application.activeComplaintsMechanismObj?.outlet?.outletId}
                        />
                        {displayFields()}
                    </>
                )}
            </PageWrapper>
        </>
    );
};

export default ComplaintsMechanismInsurancePage;
