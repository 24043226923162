import React from 'react';
import { IUser } from '../../api/interfacesApi';

interface StatusHeaderProps {
    user: IUser;
}

function StatusHeader(props: StatusHeaderProps) {
    const { user } = props;

    return (
        <>
            <h1 id='jumbotron-heading-welcome' data-testid='jumbotron-heading-welcome' className='display-6'>
                Welcome {user.displayName}
            </h1>
            <p id='jumbotron-text-welcome' data-testid='jumbotron-text-welcome' className='lead w-75'>
                This is your dashboard. From here you add and maintain your accreditation details. Next time you sign in, you may notice functionality has been
                updated to improve your user experience to give you more access to make changes. The dashboard is also where you can find resources regarding
                FDR and accreditation.
            </p>
        </>
    );
}

export default StatusHeader;
