import * as types from '../../actions/actionTypes';
import { IAttachmentTypeArray } from '../../api/interfacesApi';

const defaultAttachmentTypeArrayData: IAttachmentTypeArray = {
    attachments: [],
    documentType: null,
    index: undefined,
};

const attachmentsTypeArray = (state = defaultAttachmentTypeArrayData, action: types.AttachmentsActionTypes) => {
    let att: IAttachmentTypeArray;
    switch (action.type) {
        case types.FILE_DELETE_SUCCESS:
            att = {
                attachments: action.attachments,
                documentType: action.documentType,
                index: action.index,
            };
            return att;
        case types.FILE_LOAD_SUCCESS:
            att = {
                attachments: action.attachments,
                documentType: action.documentType,
                index: action.index,
            };
            return att;
        default:
            return state;
    }
};

export default attachmentsTypeArray;
