import { IComplaintMechanism } from '../../../api/interfacesApi';
import Guid from '../../../services/Guid';
import { ParseBool } from '../../../utils/Common';
import { IsActiveComplaintLegalAidOtherOnPanel } from '../../../utils/ComplaintsUtils';
import {
    validationSchemaPersonalDetailsName,
    validationSchemaPersonalDetailsContact,
    validationSchemaPastApplicationHistory,
    validationSchemaCompetenciesAndQualifications,
    validationSchemaCriminalConvictions,
    validationSchemaChildRelatedEmployment,
    validationSchemaComplaintsMechanismType,
    validationSchemaComplaintsMechanismEmployee,
    validationSchemaComplaintsMechanismMembership,
    validationSchemaProfessionalIndemnityPage,
    validationSchemaChangeOfNamePage,
    validationSchemaDeclarationAndConsentPage,
} from '../../../services/Validation';
import { ApplicationStatusEnum, ComplaintsMechanismType, QualificationType, WizardStepNumber } from '../../../services/Enums';
import { AppContextInterface } from '../../../stateManagement/context/AppContext';
import { MarkStepIncompleteAndUpdateStatus } from '../../../components/wizard/wizardStepFunctions';
import { UNINITIALISED } from '../../../services/Constants';

/*
function MergeComplaintsOfType(
    complaintsProcessType: ComplaintsProcessType,
    complaintsMechanismLoadedData: IComplaintMechanism[],
    complaintsMechanismModifiedData: IComplaintMechanism[],
): IComplaintMechanism[] {
    // return collection containing existing data as well as modified data for the type of Complaint
    const complaintsData: IComplaintMechanism[] = [];

    try {
        // modified data gets predecedence
        if (complaintsMechanismModifiedData !== undefined && complaintsMechanismModifiedData !== null) {
            complaintsMechanismModifiedData.forEach((item: IComplaintMechanism, index: number) => {
                complaintsData.push(item);
            });
        }

        // loaded data gets merged into modified data to form one merged set of changes
        switch (complaintsProcessType) {
            case ComplaintsProcessType.GovernmentFundedFDRService: // employee/govt funded
                if (complaintsMechanismLoadedData !== undefined && complaintsMechanismLoadedData !== null && complaintsMechanismLoadedData.length > 0) {
                    complaintsMechanismLoadedData.forEach((item: IComplaintMechanism, index: number) => {
                        if (item.complaintsProcessType === ComplaintsProcessType.ProfessionalAssociationMembership) {
                            // add if not already exists
                            const idxPAM = complaintsData.findIndex(x => x.complaintsMechanismId === item.complaintsMechanismId);
                            if (idxPAM === -1) {
                                complaintsData.push(item);
                            }
                        }
                    });
                }
                break;
            case ComplaintsProcessType.ProfessionalAssociationMembership: // membership
                if (complaintsMechanismLoadedData !== undefined && complaintsMechanismLoadedData !== null && complaintsMechanismLoadedData.length > 0) {
                    complaintsMechanismLoadedData.forEach((item: IComplaintMechanism, index: number) => {
                        if (item.complaintsProcessType === ComplaintsProcessType.GovernmentFundedFDRService) {
                            // add if not already exists
                            const idxGF = complaintsData.findIndex(x => x.complaintsMechanismId === item.complaintsMechanismId);
                            if (idxGF === -1) {
                                complaintsData.push(item);
                            }
                        }
                    });
                }
                break;
            default:
                break;
        }
        return complaintsData;
    } catch (err) {
        // tslint:disable-next-line: no-console
        console.log(err);
        return complaintsData;
    }
} 
*/

export const EnsureData = (appContext: AppContextInterface, currentStepNumber: number, data: any, inquiry: any) => {
    // set the latest step that the user has saved progress on
    if (data.inquiry.application.currentApplicationStep !== undefined || currentStepNumber === data.inquiry.application.currentApplicationStep) {
        // upon save we set the current step (ie the next one) to the next available one in the case of complaints depends upon what is chosen
        switch (currentStepNumber) {
            case WizardStepNumber.ComplaintsMechanismType:
                data.inquiry.application.currentApplicationStep =
                    data.inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType === ComplaintsMechanismType.FullMembershipProfessionalAssoc
                        ? currentStepNumber + 2 // skip from 7 to 9
                        : currentStepNumber + 1; // otherwise normal 7 to 8
                break;
            case WizardStepNumber.ComplaintsMechanismEmployee:
                if (data.inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType === ComplaintsMechanismType.GovernmentFundedFDRService) {
                    // could go to Prof Insurance page IF Legal Aid/Other AND included on panel chosen
                    const isOnPanel: boolean = IsActiveComplaintLegalAidOtherOnPanel(data.inquiry.application);
                    data.inquiry.application.currentApplicationStep = isOnPanel ? currentStepNumber + 2 : currentStepNumber + 3; // skip from 8 to 11 normally, or from 8 to 10 (insurance) if Legal Aid and on Panel
                } else {
                    data.inquiry.application.currentApplicationStep = currentStepNumber + 1;
                }
                break;
            case WizardStepNumber.ProfessionalIndemnity:
                data.inquiry.application.currentApplicationStep =
                    data.inquiry.application.activeComplaintsMechanismObj.hasProfessionalIndemnityInsuranceViaMembership === false &&
                    IsActiveComplaintLegalAidOtherOnPanel(data.inquiry.application) === true
                        ? currentStepNumber // do NOT MOVE step number (user is sent to dashboard but cannot progress until insurance supplied)
                        : currentStepNumber + 1;
                break;
            default:
                // as we are saving we move forward one for most cases
                data.inquiry.application.currentApplicationStep = currentStepNumber + 1;
        }
    }

    // special case for if current step is Personal Name, as we do not update "application" object until Past App History (which means this EnsureData() method is only called by Past App History page and onwards)
    if (data.inquiry.application.currentApplicationStep === WizardStepNumber.PersonalDetailsName) {
        if (data.inquiry.application.hasPreviouslyApplied !== undefined && data.inquiry.application.hasPreviouslyApplied !== null) {
            data.inquiry.application.currentApplicationStep = WizardStepNumber.CompetenciesAndQuals;
        }
    }

    // blocker step - if user changes from having insurance then removing it for when a Legal Aid, we block them (however the curr step saved might be already in future in DB, fix that here)
    if (currentStepNumber === WizardStepNumber.ProfessionalIndemnity) {
        // If Legal Aid/Other AND included on panel chosen
        const isOnPanel: boolean = IsActiveComplaintLegalAidOtherOnPanel(data.inquiry.application);
        if (isOnPanel === true) {
            data.inquiry.application.currentApplicationStep = WizardStepNumber.ProfessionalIndemnity;
        }
    }

    // always set these if null
    if (data.inquiry.application.zNumber === undefined || data.inquiry.application.zNumber === 0) {
        data.inquiry.application.zNumber = inquiry.application?.zNumber;
    }
    if (
        data.inquiry.application.applicationId === undefined ||
        data.inquiry.application.applicationId === null ||
        data.inquiry.application.applicationId === Guid.empty
    ) {
        data.inquiry.application.applicationId = inquiry.application?.applicationId;
    }

    // override data values with values from database ONLY if not being currently set on that page
    if (currentStepNumber !== WizardStepNumber.CompetenciesAndQuals) {
        // do NOT re-set qualification values if user has returned to Past App History and changed from Yes to No (as it should NOT set three units)
        if (
            currentStepNumber === WizardStepNumber.PastApplicationHistory &&
            ParseBool(data.inquiry.application.hasPreviouslyApplied) === false && // new value
            inquiry.application.qualification === QualificationType.ThreeSpecifiedUnits // current/old value
        ) {
            // clear the new value coming in
            inquiry.application.qualification = null;
        }

        data.inquiry.application.qualification = inquiry.application.qualification !== null ? inquiry.application.qualification : null;
        data.inquiry.application.qualificationOrNMASDetails =
            inquiry.application.qualificationOrNMASDetails !== null ? inquiry.application.qualificationOrNMASDetails : null;
        data.inquiry.application.nmasExpiryDate =
            inquiry.application.nmasExpiryDate !== undefined || inquiry.application.nmasExpiryDate !== null ? inquiry.application.nmasExpiryDate : undefined;
    }

    if (currentStepNumber !== WizardStepNumber.ChildRelatedEmployment) {
        data.inquiry.application.isProhibitedFromWorkingWithChildren =
            inquiry.application.isProhibitedFromWorkingWithChildren !== null ? inquiry.application.isProhibitedFromWorkingWithChildren : null;
        data.inquiry.application.completeWorkingWithChildrenCheck =
            inquiry.application.completeWorkingWithChildrenCheck !== null ? inquiry.application.completeWorkingWithChildrenCheck : null;
        data.inquiry.application.isWorkingWithChildrenCheckNotApplicable =
            inquiry.application.isWorkingWithChildrenCheckNotApplicable !== null ? inquiry.application.isWorkingWithChildrenCheckNotApplicable : null;
    }
    if (currentStepNumber !== WizardStepNumber.CriminalConvictions) {
        data.inquiry.application.hasOffenceConvictions = inquiry.application.hasOffenceConvictions !== null ? inquiry.application.hasOffenceConvictions : null;
        data.inquiry.application.hasCurrentNationalPoliceCheck =
            inquiry.application.hasCurrentNationalPoliceCheck !== null ? inquiry.application.hasCurrentNationalPoliceCheck : null;
    }
    if (currentStepNumber !== WizardStepNumber.ComplaintsMechanismType) {
        data.inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType =
            inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType !== null
                ? inquiry.application.activeComplaintsMechanismObj.complaintsMechanismType
                : null;
    }
    if (currentStepNumber !== WizardStepNumber.DeclarationAndConsent) {
        // if the user has consented then later gone back and changed anything, provided the application is still Pending (new)
        // then we invalidate that consent and they must do it again
        if (inquiry.application.applicationStatus === ApplicationStatusEnum.Pending && inquiry.application.hasDeclaredAndConsented === true) {
            data.inquiry.application.hasDeclaredAndConsented = false;
            // MUST set the wiard step as the part of the form is now invalid
            const isValid = false;
            MarkStepIncompleteAndUpdateStatus(appContext, isValid, WizardStepNumber.DeclarationAndConsent);
        }
    }

    /* the member page merges now....
    // NOTE: only load complaints on its own pages as is seperate collection
    if (currentStepNumber === WizardStepNumber.ComplaintsMechanismMember) {
        // load all 'employee/govt funded' complaints data
        data.inquiry.application.complaintsMechanism = MergeComplaintsOfType(
            ComplaintsProcessType.GovernmentFundedFDRService,
            inquiry.application.complaintsMechanism,
            data.inquiry.application.complaintsMechanism,
        );
    }
    */

    // THE BELOW KNOCKS OUT THE Guid Id, Outlet as Inquiry does not have what data.inquiry has, so this may not be needed any more:
    /*if (currentStepNumber === WizardStepNumber.ComplaintsMechanismEmployee) {
        // load all 'membership' complaints data
        data.inquiry.application.complaintsMechanism = MergeComplaintsOfType(
            ComplaintsProcessType.ProfessionalAssociationMembership,
            inquiry.application.complaintsMechanism,
            data.inquiry.application.complaintsMechanism,
        );
    }*/

    if (data.inquiry.application.complaintsMechanism) {
        data.inquiry.application.complaintsMechanism.forEach((complaint: IComplaintMechanism) => {
            // if ONLY Membership is supplied by this point we need to empty the Outlet as it is not needed
            if (complaint.outlet?.outletId === null) {
                complaint.outlet = null;
            }
        });
    }

    // clear the complaints data if initialised but not up to that step
    if (
        data.inquiry.application.complaintsMechanism &&
        data.inquiry.application.complaintsMechanism.length === 1 &&
        data.inquiry.application.activeComplaintsMechanismObj.complaintsMechanismId === UNINITIALISED
    ) {
        data.inquiry.application.complaintsMechanism = null;
    }

    // clear the complaints data item if up to that step and user has agreed to remove data then re-add it
    if (data.inquiry.application.complaintsMechanism && data.inquiry.application.complaintsMechanism.length >= 1) {
        for (let idx = data.inquiry.application.complaintsMechanism.length - 1; idx >= 0; idx--) {
            const dataComplaintsMechanismItem = data.inquiry.application.complaintsMechanism[idx];

            if (dataComplaintsMechanismItem.complaintsProcessType === undefined && dataComplaintsMechanismItem.complaintsMechanismId === UNINITIALISED) {
                // remove this record
                data.inquiry.application.complaintsMechanism.splice(idx, 1);
            } else {
                // upon resubmit the Id of could be hidden and thus cleared so we reset it now
                if (
                    dataComplaintsMechanismItem.outlet &&
                    dataComplaintsMechanismItem.outlet.outletId === undefined &&
                    inquiry.application.complaintsMechanism[idx].outlet &&
                    inquiry.application.complaintsMechanism[idx].outlet.outletId !== undefined &&
                    dataComplaintsMechanismItem.outlet.name === inquiry.application.complaintsMechanism[idx].outlet.name
                ) {
                    dataComplaintsMechanismItem.outlet.outletId = inquiry.application.complaintsMechanism[idx].outlet.outletId;
                }
                // if businessAddress or outlet hours are empty remove them
                if (dataComplaintsMechanismItem.outlet && dataComplaintsMechanismItem.outlet.length >= 1) {
                    for (let idxOutlet = dataComplaintsMechanismItem.outlet.length - 1; idxOutlet >= 0; idxOutlet--) {
                        if (dataComplaintsMechanismItem.outlet[idxOutlet].businessAddress?.addressId === UNINITIALISED) {
                            dataComplaintsMechanismItem.outlet[idxOutlet].businessAddress = null; // remove address as it is not yet populated
                        }
                        if (
                            dataComplaintsMechanismItem.outlet[idxOutlet].openingHours &&
                            dataComplaintsMechanismItem.outlet[idxOutlet].openingHours.length >= 1
                        ) {
                            for (let idxOH = dataComplaintsMechanismItem.outlet[idxOutlet].openingHours.length - 1; idxOH >= 0; idxOH--) {
                                if (dataComplaintsMechanismItem.outlet[idxOutlet].openingHours[idxOH].openingHoursId === UNINITIALISED) {
                                    dataComplaintsMechanismItem.outlet[idxOutlet].openingHours.splice(idxOH, 1); // remove opening hours as it not yet populated
                                }
                            }
                        }
                    }
                }
            }
        }
    }
};

export const GetValidationSchemaForCurrentPage = (currentStepNumber: number) => {
    switch (currentStepNumber) {
        case 0: // this handles the INTRO page which has no validation
            return undefined;
        case WizardStepNumber.PersonalDetailsName:
            return validationSchemaPersonalDetailsName;
        case WizardStepNumber.PersonalDetailsContact:
            return validationSchemaPersonalDetailsContact;
        case WizardStepNumber.PastApplicationHistory:
            return validationSchemaPastApplicationHistory;
        case WizardStepNumber.CompetenciesAndQuals:
            return validationSchemaCompetenciesAndQualifications;
        case WizardStepNumber.CriminalConvictions:
            return validationSchemaCriminalConvictions;
        case WizardStepNumber.ChildRelatedEmployment:
            return validationSchemaChildRelatedEmployment;
        case WizardStepNumber.ComplaintsMechanismType:
            return validationSchemaComplaintsMechanismType;
        case WizardStepNumber.ComplaintsMechanismEmployee:
            return validationSchemaComplaintsMechanismEmployee;
        case WizardStepNumber.ComplaintsMechanismMember:
            return validationSchemaComplaintsMechanismMembership;
        case WizardStepNumber.ProfessionalIndemnity:
            return validationSchemaProfessionalIndemnityPage;
        case WizardStepNumber.ChangeOfName:
            return validationSchemaChangeOfNamePage;
        case WizardStepNumber.DeclarationAndConsent:
            return validationSchemaDeclarationAndConsentPage;
        default:
            return undefined;
    }
};
