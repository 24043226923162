import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Inquiry } from '../../../api/interfacesApi';
import AppContext from '../../../stateManagement/context/AppContext';
import { AttachmentDocumentType, WizardMode, WizardStepNumber } from '../../../services/Enums';
import { ParseBool } from '../../../utils/Common';
import { formatDateForDisplay, WithinThirtyDays } from '../../../utils/Dates';
import { DisplayProfessionalIndemnityInsurance } from '../../../utils/EnumMappings';
import { NOTE_INSURANCE_MISSING_WARNING, NOTE_INSURANCE_PERIOD_WARNING } from '../../../services/Constants';
import NoteText from '../../../controls/NoteText';
import LabelField from '../../../components/fields/LabelField';
import PageFieldsTitle from '../../../components/fields/PageFieldsTitle';
import FileUploadDisplay from './fileUploadDisplay';
import { EmptyRow } from 'controls/EmptyRow';

interface ProfessionalIndemnityDisplayProps {
    inquiry: Inquiry;
    formatForPDF: boolean;
}

const ProfessionalIndemnityDisplay = (props: ProfessionalIndemnityDisplayProps) => {
    const { inquiry, formatForPDF } = props;

    const appContext = useContext(AppContext);
    const showLinkToPage = formatForPDF === true ? false : appContext.wizardState.wizardMode === WizardMode.New ? true : false;

    const showNoInsuranceWarning =
        ParseBool(
            inquiry.application.activeComplaintsMechanismObj &&
                inquiry.application.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership,
        ) === false;
    const showPeriodOfInsuranceWarning =
        inquiry.application.activeComplaintsMechanismObj &&
        inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceToDate !== undefined &&
        WithinThirtyDays(inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceToDate);

    if (inquiry.application.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership === null) {
        return null;
    }

    return (
        <>
            <PageFieldsTitle
                title='Professional indemnity insurance'
                showLinkToPage={showLinkToPage}
                wizardStepNumber={WizardStepNumber.ProfessionalIndemnity}
                smallerHeader={true}
                underlineTitle={formatForPDF}
            />
            <Row>
                <Col>
                    <LabelField
                        id='reviewHasProfessionalIndemnityInsurance'
                        displayName='Insurance coverage'
                        value={DisplayProfessionalIndemnityInsurance(
                            inquiry.application.activeComplaintsMechanismObj?.hasProfessionalIndemnityInsuranceViaMembership,
                        )}
                        isMandatory={false}
                        isTitleHtag={formatForPDF}
                    />
                </Col>
            </Row>
            {formatForPDF === true ? <EmptyRow /> : null}
            <FileUploadDisplay
                inquiry={inquiry}
                showThisDocType={AttachmentDocumentType.Insurance}
                title='Professional indemnity insurance evidence'
                formatForPDF={formatForPDF}
            />
            {inquiry.application.activeComplaintsMechanismObj?.professionalIndemnityInsuranceFromDate && (
                <>
                    <Row>
                        <Col>
                            <LabelField
                                id='reviewProfessionalIndemnityInsFromDate'
                                displayName='Period of insurance - Start date'
                                value={formatDateForDisplay(inquiry.application.activeComplaintsMechanismObj?.professionalIndemnityInsuranceFromDate)}
                                isMandatory={false}
                                isTitleHtag={formatForPDF}
                            />
                        </Col>
                    </Row>
                    {formatForPDF === true ? <EmptyRow /> : null}
                </>
            )}
            {inquiry.application.activeComplaintsMechanismObj?.professionalIndemnityInsuranceToDate && (
                <>
                    <Row>
                        <Col>
                            <LabelField
                                id='reviewProfessionalIndemnityInsToDate'
                                displayName='Period of insurance - End date'
                                value={formatDateForDisplay(inquiry.application.activeComplaintsMechanismObj.professionalIndemnityInsuranceToDate)}
                                isMandatory={false}
                                isTitleHtag={formatForPDF}
                            />
                        </Col>
                    </Row>
                    {formatForPDF === true ? <EmptyRow /> : null}
                </>
            )}
            {showNoInsuranceWarning && (
                <>
                    <Row>
                        <Col md='11'>
                            <NoteText displayText={NOTE_INSURANCE_MISSING_WARNING} formatForPDF={formatForPDF} />
                        </Col>
                        <Col md='1' className='justify-content-end' />
                    </Row>
                    {formatForPDF === true ? <EmptyRow /> : null}
                </>
            )}
            {showPeriodOfInsuranceWarning && (
                <>
                    <Row>
                        <Col md='11'>
                            <NoteText displayText={NOTE_INSURANCE_PERIOD_WARNING} formatForPDF={formatForPDF} />
                        </Col>
                        <Col md='1' className='justify-content-end' />
                    </Row>
                    {formatForPDF === true ? <EmptyRow /> : null}
                </>
            )}
        </>
    );
};

export default ProfessionalIndemnityDisplay;
