import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Mode, useForm } from 'react-hook-form';
// tslint:disable-next-line: no-submodule-imports
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as types from '../../../actions/actionTypes';
import {
    HideSaveChangesDialog,
    SetDestinationNavActionOnSuccess,
    SetIsUserInWizardFlag,
    ShowSaveChangesDialog,
    SetValidationSchema,
    WizardStateActionTypeEnums,
    SetWizardMode,
    ShowResubmitDiscardChangesDialog,
    HideResubmitDiscardChangesDialog,
} from '../../../components/wizard/wizardStateManager';
import {
    ShowOrHidePage,
    MoveWizardBackward,
    NavigateAway,
    MarkStepIncompleteAndUpdateStatus,
    MarkStepOriginalStatus,
    UpdateWizardStepsAndRepaint,
} from '../../../components/wizard/wizardStepFunctions';
import { IUser, IApplication, InquiryDefaultObj, Inquiry } from '../../../api/interfacesApi';
import { AjaxCallStateForPage } from '../../PageInterfaces';
import { toastErrorMessage, toastSuccessMessage } from '../../../actions/toastrMessages';
import UserActions from '../../../actions/userActions';
import ApplicationActions from '../../../actions/applicationActions';
import AttachmentActions from '../../../actions/attachmentsActions';
import { Sleep } from '../../../utils/Common';
import {
    HasAnyCompetenciesAttachments,
    InitialResubmitAttachmentSetup,
    InitialResubmitWizardSetup,
    RemoveAttachmentsFromAzureIfExist,
    RemoveAttachmentsFromCollectionIfExist,
} from '../../../utils/AppUtils';
import AuthService from '../../../services/AuthService';
import { AjaxCallStateEnum, AttachmentDocumentType, NavigationDestination, WizardMode, WizardStepNumber } from '../../../services/Enums';
import {
    VALIDATION_ERROR_POPUP_MESSAGE,
    VALIDATION_PROMPT_NAVIGATE_AWAY_TITLE,
    VALIDATION_PROMPT_NAVIGATE_AWAY_MESSAGE,
    VALIDATION_PROMPT_RESUBMITDISCARDCHANGES_TITLE,
    VALIDATION_PROMPT_RESUBMITDISCARDCHANGES_MESSAGE,
    VALIDATION_SUCCESS_POPUP_MESSAGE,
} from '../../../services/Validation';
import ModalDialog from '../../../controls/ModalDialog';
import Loader from '../../../controls/Loader';
// import CollapseContent from '../../controls/CollapseContent';
import { isOnIntroductionPage, JumpToWizardStep } from '../../PageFunctions';
import AppContext, { AppContextInterface } from '../../../stateManagement/context/AppContext';
import WizardMenuContainer from '../../../components/site/WizardMenuContainer';
import { WizardStepCurrentStateEnum } from '../../../components/wizard/wizardEnums';
import { GetValidationSchemaForCurrentPage } from './wizardHelperFunctions';
import styles from './RegistrationApplicationWizard.module.scss';

// pages
import RegistrationApplicationForm from '../RegistrationApplicationForm';
import IntroductionPage from '../IntroductionPage';
import PersonalDetailsNamePage from '../PersonalDetailsPage/PersonalDetailsNamePage';
import PersonalDetailsContactPage from '../PersonalDetailsPage/PersonalDetailsContactPage';
import PastApplicationHistoryPage from '../PastApplicationHistory';
import CompetenciesAndQualsPage from '../CompetenciesAndQuals';
import ChildRelatedEmploymentPage from '../ChildRelatedEmployment';
import CriminalConvictionsPage from '../CriminalConvictions';
import ComplaintsMechanismTypePage from '../ComplaintsMechanism/ComplaintsMechanismType';
import ComplaintsMechanismEmployeePage from '../ComplaintsMechanism/ComplaintsMechanismEmployee';
import ComplaintsMechanismMemberPage from '../ComplaintsMechanism/ComplaintsMechanismMember';
import ProfessionalIndemnityPage from '../ProfessionalIndemnity';
import ChangeOfNamePage from '../ChangeOfName';

import DeclarationAndConsentPage from '../DeclarationAndConsent';
import ReviewAndSubmitPage from '../ReviewAndSubmit';
// import { getProfileData, logProfileData } from '../../utils/ProfilerUtils';

const { pageDetails } = styles;

interface RegistrationApplicationWizardProps {
    authService: AuthService;
    resume: boolean;
    isResubmit: boolean;
}

const RegistrationApplicationWizard = (props: RegistrationApplicationWizardProps): JSX.Element => {
    const { authService, resume, isResubmit } = props;

    const history = useHistory();
    const appContext = useContext<AppContextInterface>(AppContext);
    const dispatchWizardState = appContext.dispatchWizardState;
    const wizardState = appContext.wizardState;
    // store the form in state as we update the steps constantly after user actions
    const applicationForm = useRef(new RegistrationApplicationForm());

    const userActions = useMemo(() => {
        return new UserActions(authService);
    }, [authService]);
    const appActions = useMemo(() => {
        return new ApplicationActions(authService);
    }, [authService]);
    const attachmentActions = useMemo(() => {
        return new AttachmentActions(authService);
    }, [authService]);

    const user: IUser = useSelector((stateUser: any) => stateUser.user);
    const application: IApplication = useSelector((stateApp: any) => stateApp.application);
    // const attachmentsTypeArray: IAttachmentTypeArray = useSelector((stateAttachmentTypeArray: any) => stateAttachmentTypeArray.attachmentsTypeArray);
    const ajaxCallsInProgress: types.ICallStatus = useSelector((stateAjax: any) => stateAjax.ajaxCallsInProgress);
    const complaintsMechanismReducer: types.ApplicationActionTypes = useSelector((cm: any) => cm.complaintsMechanism);
    const initCallStateForPage: AjaxCallStateForPage = { ajaxCallState: AjaxCallStateEnum.NotStarted, pageNumber: 0 };
    const [ajaxCallStateForPage, setAjaxCallStateForPage] = useState(initCallStateForPage);
    const [backEndValidationErrors, setBackEndValidationErrors] = useState([]);
    const [processedResumeStep, setProcessedResumeStep] = useState(false);
    const [processedRework, setProcessedRework] = useState(false);
    const [processedReworkAttachments, setProcessedReworkAttachments] = useState(false);
    const [allowEditForResubmitStep, setAllowEditForResubmitStep] = useState(false);
    const [showSuccessCMtoasty, setShowSuccessCMtoasty] = useState(false);

    // tslint:disable-next-line: no-shadowed-variable
    const [inquiryDefObj, setInquiry] = useState<InquiryDefaultObj>(applicationForm.current.properties.InitialData);
    const dispatch = useDispatch();

    // used by children to set this object at parent-level
    const setInquiryObj = (inquiry: Inquiry) => {
        inquiryDefObj.inquiry = inquiry;
        setInquiry(inquiryDefObj);
    };

    // set the overall application flag to indicate user is in wizard
    useEffect(() => {
        SetIsUserInWizardFlag(appContext);
        setShowSuccessCMtoasty(false); // and toasty reset
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // set the resumbmit flag state
    useEffect(() => {
        if (isResubmit === true && appContext?.wizardState.wizardMode !== WizardMode.Resubmit) {
            SetWizardMode(appContext, WizardMode.Resubmit);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // get latest user details so we can map them to our inquiry object upon load of this page
    useEffect(() => {
        dispatch(userActions.GetUser());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(appActions.GetApplication());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (inquiryDefObj.inquiry) {
            inquiryDefObj.inquiry.personalDetails = user;
            inquiryDefObj.inquiry.application = application;

            // REDUNDANT NOW, replaced by addedFile:
            // MapComplaintsAttachmentsToComplaintsArray(inquiryDefObj.inquiry, setValue, getValues);

            setInquiry(inquiryDefObj);

            // reset would make the values update for the useForm functionality
            reset(inquiryDefObj);

            // if resume we immediately navigate to that step
            if (resume && !processedResumeStep && application.currentApplicationStep > 0) {
                let nextIncompleteStep =
                    application.currentApplicationStep === WizardStepNumber.ReviewAndSubmit
                        ? WizardStepNumber.ReviewAndSubmit
                        : application.currentApplicationStep; // + 1;
                // special cases for Personal Details steps as we do NOT update the Application (only the User) we need a fudge here!
                // (on both Contact and Name page the current step will be PersonalDetailsName as we do not update Application!)
                if (nextIncompleteStep === WizardStepNumber.PersonalDetailsName) {
                    if (inquiryDefObj.inquiry.personalDetails.addresses?.length > 0 && inquiryDefObj.inquiry.personalDetails.addresses[0].postcode !== '') {
                        nextIncompleteStep += 2;
                    } else if (inquiryDefObj.inquiry.personalDetails.gender !== null) {
                        nextIncompleteStep += 1;
                    }
                }
                // special case for final review if user had triggered reset of consent back to FALSE
                if (nextIncompleteStep === WizardStepNumber.ReviewAndSubmit && inquiryDefObj.inquiry.application.hasDeclaredAndConsented === false) {
                    nextIncompleteStep = WizardStepNumber.DeclarationAndConsent;
                }
                const setAllPriorStepsToComplete = true;
                JumpToWizardStep(WizardStepNumber.Introduction, nextIncompleteStep, appContext, setAllPriorStepsToComplete);
                setProcessedResumeStep(true);
            }

            // if resubmit mode then we need to setup the wizard steps
            if (inquiryDefObj.inquiry && wizardState.wizardMode === WizardMode.Resubmit) {
                // given the collection of reworkSections and the state that is a resubmit, the system will:
                //  - set the wizard step icon indicators for those reworkSections
                //  - navigate the user to the first step that needs attention
                //  - un-check the Consent field and mark that section as invalid
                //  - display my previously selected options but these will not be editable
                //  - clear all files for the sections that have been indicated (not display documents I originally uploaded)
                //  - provide a navigation option to go forward
                // if (processedRework === true && processedReworkAttachments === false) {
                //    setProcessedReworkAttachments(true); // only ever done once, in order
                //    InitialResubmitWizardSetup(appContext, dispatch, appActions, inquiryDefObj.inquiry, setInquiry);
                // }

                if (
                    inquiryDefObj.inquiry.application?.reworkSections !== null &&
                    inquiryDefObj.inquiry.application.reworkSections.length > 0 &&
                    processedRework === false
                ) {
                    setProcessedRework(true); // only ever done once
                    // in here we remove file(s) so we need to show loader to user - so they cannot upload whilst we are removing - we pass this onto processedReworkAttachments to deal with when to hide
                    InitialResubmitAttachmentSetup(appContext, dispatch, appActions, attachmentActions, inquiryDefObj, setInquiry);
                }
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, application, inquiryDefObj]);

    useEffect(() => {
        // if resubmit mode then we need to setup the wizard steps
        if (inquiryDefObj.inquiry && wizardState.wizardMode === WizardMode.Resubmit) {
            if (processedRework === true && processedReworkAttachments === false) {
                setProcessedReworkAttachments(true); // only ever done once, in order
                InitialResubmitWizardSetup(appContext, dispatch, appActions, inquiryDefObj.inquiry, setInquiry);
                // this is where we hide the loading dialog (as files to be removed might take some time)
                Sleep(5000).then(() => {
                    setAllowEditForResubmitStep(true);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processedRework, inquiryDefObj]);

    useEffect(() => {
        if (ajaxCallsInProgress && ajaxCallsInProgress.callState) {
            setAjaxCallStateForPage({ ajaxCallState: ajaxCallsInProgress.callState, pageNumber: wizardState.formStep });
        }

        if (ajaxCallsInProgress && ajaxCallsInProgress.callState === AjaxCallStateEnum.CompletedWithValidation) {
            setBackEndValidationErrors(ajaxCallsInProgress.errors);
        }

        // TODO: set Errors here:
    }, [ajaxCallsInProgress, wizardState.formStep]);

    useEffect(() => {
        // CM was updated and if we were moving backwards then simply produce a green toasty - this is the ONLY way as
        // each child page unloads when moving backwards as we make the API call then lose that page in EmitPage()
        // TODO: Tidy this all up for BACK button behaviours!!
        if (
            showSuccessCMtoasty === false &&
            application.currentApplicationStep === WizardStepNumber.CriminalConvictions &&
            complaintsMechanismReducer &&
            complaintsMechanismReducer.type === 'UPDATE_COMPLAINTSMECHANISM_SUCCESS' &&
            ajaxCallsInProgress &&
            ajaxCallsInProgress.errors.length === 0 &&
            (ajaxCallsInProgress.callsInProgress === 0 || ajaxCallsInProgress.callsInProgress > 1) && // back btn calls
            ajaxCallsInProgress.loading === false &&
            ajaxCallsInProgress.callState === AjaxCallStateEnum.CompletedOK
        ) {
            // try avoid multiple toasty showing!
            Sleep(200).then(() => {
                setShowSuccessCMtoasty(true);
            });
        }

        if (showSuccessCMtoasty === true) {
            toastSuccessMessage(VALIDATION_SUCCESS_POPUP_MESSAGE);
            setShowSuccessCMtoasty(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [complaintsMechanismReducer]);

    const setTriggerSubmit = (triggerSubmit: boolean) => {
        if (triggerSubmit) {
            dispatchWizardState({ type: WizardStateActionTypeEnums.setTriggerSubmit });
        } else {
            dispatchWizardState({ type: WizardStateActionTypeEnums.unsetTriggerSubmit });
        }
    };

    useEffect(() => {
        // if (!wizardState.formIsDirty) {
        SetValidationSchema(appContext, GetValidationSchemaForCurrentPage(wizardState.formStep));

        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wizardState.formStep, wizardState.validationSchema]);

    const validationSchema = wizardState.validationSchema ? yupResolver(wizardState.validationSchema) : undefined;

    /*
    export declare type ValidationMode = {
        onBlur: 'onBlur';
        onChange: 'onChange';
        onSubmit: 'onSubmit';
        onTouched: 'onTouched';
        all: 'all';
    };
    */
    let validationMode: Mode = appContext.wizardState.wizardMode === WizardMode.Resubmit ? 'onSubmit' : 'all';
    // some pages need validation only upon submit
    if (wizardState.formStep === WizardStepNumber.ComplaintsMechanismMember) {
        validationMode = 'onSubmit';
    }

    // watch lets us keep track of state to keep an eye on it
    // formState lets us validate
    const {
        watch,
        register,
        unregister,
        reset,
        trigger,
        getValues,
        setValue,
        setError,
        control,
        handleSubmit,
        formState: { errors, isValid, isDirty, touchedFields },
        clearErrors,
    } = useForm({
        mode: validationMode,
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        shouldFocusError: wizardState.formStep !== WizardStepNumber.ProfessionalIndemnity, // disable focus only on insurance step
        // [
        defaultValues: useMemo(() => {
            return inquiryDefObj;
        }, [inquiryDefObj]),
        // useMemo(() => {
        // return inquiry;
        // }, [inquiry]),
        resolver: useMemo(() => {
            return validationSchema;
        }, [validationSchema]),
    }); // mode:all is to validate after every keypress

    useEffect(() => {
        if (isDirty) {
            dispatchWizardState({ type: WizardStateActionTypeEnums.setFormIsDirty });
        } else {
            dispatchWizardState({ type: WizardStateActionTypeEnums.unsetFormIsDirty });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty]);

    const setCurrentWizardStepToInvalid = () => {
        if (appContext && appContext.wizardSteps && appContext.wizardSteps.length > 0 && appContext.wizardState.formStep > -1) {
            const currIndex = appContext.wizardState.formStep;
            // we dont care if it is false or true as this was triggered by invalidation of form (ie the user!)
            // if (appContext.wizardSteps[currIndex].stepCompleted === true) {
            MarkStepIncompleteAndUpdateStatus(appContext, isValid, currIndex, errors);
            // }
        }
    };

    const setCurrentWizardStepToOriginalState = () => {
        if (appContext && appContext.wizardSteps && appContext.wizardSteps.length > 0 && appContext.wizardState.formStep > -1) {
            const currIndex = appContext.wizardState.formStep;
            // only set to orig if not already completed
            if (
                appContext.wizardSteps[currIndex].currentState !== WizardStepCurrentStateEnum.completed ||
                appContext.wizardSteps[currIndex].currentState !== WizardStepCurrentStateEnum.substepcompleted
            ) {
                MarkStepOriginalStatus(appContext, isValid, currIndex);
            }
        }
    };

    useEffect(() => {
        // if form is now invalid we need to mark its step incomplete
        const errorsExist = errors?.inquiry !== undefined && errors?.inquiry !== null;
        if (!isValid || errorsExist === true) {
            setCurrentWizardStepToInvalid();
        } else {
            // if form is now valid we need to mark the step back to its original state
            setCurrentWizardStepToOriginalState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid, errors]);

    const handleClickPrevious = () => {
        if (appContext.wizardState.wizardMode === WizardMode.Resubmit) {
            // show discard changes dialog
            ShowResubmitDiscardChangesDialog(appContext);
        } else {
            // Check if page is valid, and if not valid whether it is still ok to move backward (either mark completed or invalidPartial)
            // Check for isDirty flag and ask user to save changes
            const anyTouchedFields =
                touchedFields !== undefined && touchedFields !== null && touchedFields.inquiry !== undefined && touchedFields.inquiry !== null;
            if (wizardState.formIsDirty || isDirty || anyTouchedFields || !isValid) {
                ShowSaveChangesDialog(appContext);
                SetDestinationNavActionOnSuccess(appContext, NavigationDestination.PreviousWizardStep);
            } else {
                // can move backwards - but we MUST clearErrors in case user clicks to attempt to save without the isDirty being set
                clearErrors();
                MoveWizardBackward(appContext, appContext.wizardState.triggerSubmit, wizardState.formIsDirty, isValid);
            }
        }
    };

    const closeResubmitChangesDialog = () => {
        HideResubmitDiscardChangesDialog(appContext);
    };

    const discardResubmitChanges = () => {
        // tidy up then navigate back to the dashboard
        HideResubmitDiscardChangesDialog(appContext);
        setTriggerSubmit(false);
        // also clears all errors
        reset();

        SetDestinationNavActionOnSuccess(appContext, NavigationDestination.Dashboard);

        // wait for 0.5 seconds to allow toasty message to display upon save success
        Sleep(500).then(() => {
            const keepChanges = false;
            const isDirtyForcedFalse = false;
            NavigateAway(appContext, history, keepChanges, isDirtyForcedFalse, isValid);
        });
    };

    // Discards changes and navigates away
    const discardChanges = () => {
        HideSaveChangesDialog(appContext);
        setTriggerSubmit(false);

        // TODO: Throwing away changes is clearing without re-loading from back-end, which isn't working
        //  any more since data overhaul with GetUser() and GetApplication() having to be re-triggered I think
        // reset data back to original, then navigate away

        // must clear any errors as we may set them manually from back-end even and user wants to discard changes
        // clearErrors();

        // also clears all errors
        reset();

        // depending upon which step we are on, we must also remove attachments as they were just added - where user already said NO to saving changes
        if (appContext.isUserInWizard && appContext.wizardState.wizardMode === WizardMode.New && inquiryDefObj.inquiry.application.attachments?.length > 0) {
            // Competencies
            if (
                application.currentApplicationStep === WizardStepNumber.CompetenciesAndQuals &&
                HasAnyCompetenciesAttachments(inquiryDefObj.inquiry.application.attachments)
            ) {
                // Remove any file data if exists for the other option, to help prevent all sorts of validation issues
                RemoveAttachmentsFromAzureIfExist(
                    attachmentActions,
                    dispatch,
                    AttachmentDocumentType.FullGraduateDiplomaOfFDR,
                    inquiryDefObj.inquiry.application.attachments,
                );
                inquiryDefObj.inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(
                    AttachmentDocumentType.FullGraduateDiplomaOfFDR,
                    inquiryDefObj.inquiry.application.attachments,
                );
                RemoveAttachmentsFromAzureIfExist(attachmentActions, dispatch, AttachmentDocumentType.SixUnits, inquiryDefObj.inquiry.application.attachments);
                inquiryDefObj.inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(
                    AttachmentDocumentType.SixUnits,
                    inquiryDefObj.inquiry.application.attachments,
                );
                RemoveAttachmentsFromAzureIfExist(
                    attachmentActions,
                    dispatch,
                    AttachmentDocumentType.SixUnitsAndNMAS,
                    inquiryDefObj.inquiry.application.attachments,
                );
                inquiryDefObj.inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(
                    AttachmentDocumentType.SixUnitsAndNMAS,
                    inquiryDefObj.inquiry.application.attachments,
                );
                RemoveAttachmentsFromAzureIfExist(
                    attachmentActions,
                    dispatch,
                    AttachmentDocumentType.SixUnitsAndQualification,
                    inquiryDefObj.inquiry.application.attachments,
                );
                inquiryDefObj.inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(
                    AttachmentDocumentType.SixUnitsAndQualification,
                    inquiryDefObj.inquiry.application.attachments,
                );
                RemoveAttachmentsFromAzureIfExist(
                    attachmentActions,
                    dispatch,
                    AttachmentDocumentType.ThreeUnits,
                    inquiryDefObj.inquiry.application.attachments,
                );
                inquiryDefObj.inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(
                    AttachmentDocumentType.ThreeUnits,
                    inquiryDefObj.inquiry.application.attachments,
                );
            }

            // child-related (working with children check)
            if (application.currentApplicationStep === WizardStepNumber.ChildRelatedEmployment) {
                // Remove any file data if exists for the other option, to help prevent all sorts of validation issues
                RemoveAttachmentsFromAzureIfExist(
                    attachmentActions,
                    dispatch,
                    AttachmentDocumentType.ChildRelatedEmploymentCheck,
                    inquiryDefObj.inquiry.application.attachments,
                );
                inquiryDefObj.inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(
                    AttachmentDocumentType.ChildRelatedEmploymentCheck,
                    inquiryDefObj.inquiry.application.attachments,
                );
            }

            // criminal check
            if (application.currentApplicationStep === WizardStepNumber.CriminalConvictions) {
                // Remove any file data if exists for the other option, to help prevent all sorts of validation issues
                RemoveAttachmentsFromAzureIfExist(
                    attachmentActions,
                    dispatch,
                    AttachmentDocumentType.NationalPoliceCheck,
                    inquiryDefObj.inquiry.application.attachments,
                );
                inquiryDefObj.inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(
                    AttachmentDocumentType.NationalPoliceCheck,
                    inquiryDefObj.inquiry.application.attachments,
                );
            }

            // complaints - membership or employee
            if (
                application.currentApplicationStep === WizardStepNumber.ComplaintsMechanismMember ||
                application.currentApplicationStep === WizardStepNumber.ComplaintsMechanismEmployee
            ) {
                // Remove any file data if exists for the other option, to help prevent all sorts of validation issues
                // membership
                RemoveAttachmentsFromAzureIfExist(
                    attachmentActions,
                    dispatch,
                    AttachmentDocumentType.AssociationMembership,
                    inquiryDefObj.inquiry.application.attachments,
                );
                inquiryDefObj.inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(
                    AttachmentDocumentType.AssociationMembership,
                    inquiryDefObj.inquiry.application.attachments,
                );

                // employee
                // the drop down list is not refreshing upon BACK button with agreeing to not save changes
                if (inquiryDefObj.inquiry.application.complaintsMechanism && inquiryDefObj.inquiry.application.activeComplaintsMechanismObj?.outlet) {
                    inquiryDefObj.inquiry.application.activeComplaintsMechanismObj.outlet!.outletId = '';
                }
                // Remove any file data if exists for the other option, to help prevent all sorts of validation issues
                RemoveAttachmentsFromAzureIfExist(
                    attachmentActions,
                    dispatch,
                    AttachmentDocumentType.OrgEmployment,
                    inquiryDefObj.inquiry.application.attachments,
                );
                inquiryDefObj.inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(
                    AttachmentDocumentType.OrgEmployment,
                    inquiryDefObj.inquiry.application.attachments,
                );
                RemoveAttachmentsFromAzureIfExist(attachmentActions, dispatch, AttachmentDocumentType.OrgPanel, inquiryDefObj.inquiry.application.attachments);
                inquiryDefObj.inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(
                    AttachmentDocumentType.OrgPanel,
                    inquiryDefObj.inquiry.application.attachments,
                );
            }

            // Prof indmenity Insurance
            if (application.currentApplicationStep === WizardStepNumber.ProfessionalIndemnity) {
                // Remove any file data if exists for the other option, to help prevent all sorts of validation issues
                RemoveAttachmentsFromAzureIfExist(attachmentActions, dispatch, AttachmentDocumentType.Insurance, inquiryDefObj.inquiry.application.attachments);
                inquiryDefObj.inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(
                    AttachmentDocumentType.Insurance,
                    inquiryDefObj.inquiry.application.attachments,
                );
            }

            // Change of name
            if (application.currentApplicationStep === WizardStepNumber.ChangeOfName) {
                // Remove any file data if exists for the other option, to help prevent all sorts of validation issues
                RemoveAttachmentsFromAzureIfExist(
                    attachmentActions,
                    dispatch,
                    AttachmentDocumentType.EvidenceOfNameChange,
                    inquiryDefObj.inquiry.application.attachments,
                );
                inquiryDefObj.inquiry.application.attachments = RemoveAttachmentsFromCollectionIfExist(
                    AttachmentDocumentType.EvidenceOfNameChange,
                    inquiryDefObj.inquiry.application.attachments,
                );
            }
        }

        // SERIOUSLY DO I HAVE TO RESET THOSE CONTROLS LIKE RADIO BUTTONS - I use Controller<> already grrrrrr
        // setValue('inquiry.application.hasPreviouslyApplied', null);

        // reset(user);
        // reset(application);
        // reset(inquiry);

        // otherwise date control is just not being set properly
        // dispatch(userActions.GetUser());
        // dispatch(appActions.GetApplication());

        // need to give time for form validation to kick in
        const keepChanges = false; // appContext.wizardState.triggerSubmit;
        NavigateAway(appContext, history, keepChanges, isDirty, isValid);
    };

    // Attempts to save changes and either navigates away (on success) or stays (when validation fails)
    const saveChanges = () => {
        HideSaveChangesDialog(appContext);
        setTriggerSubmit(true);

        if (!isValid && !isOnIntroductionPage(wizardState.formStep)) {
            toastErrorMessage(VALIDATION_ERROR_POPUP_MESSAGE);
            appContext?.setDestinationNavActionOnSuccess(null);
        } else {
            // the below needs to occur to trigger the page reload and submitTrigger will cause SubmitThisPageIfActive() to fire on the page
            // that this saveChanges() call was invoked from. Then, the call on that page will complete and return back to the Wizard and the PRIOR
            // page, so we lose the context of that call, and show a success toasty on the new page IF the call had worked of course (otherwise red toasty!)
            Sleep(100).then(() => {
                clearErrors(); // MUST remove any validation as wizard moves forward first before moving backwards
                const keepChanges = true;
                NavigateAway(appContext, history, keepChanges, isDirty, isValid);
            });
        }
    };

    const isActive = false; // default all to inactive and activate them later
    const pageProps = {
        // application,
        // user,
        inquiry: inquiryDefObj.inquiry,
        setInquiryObj,
        attachments: inquiryDefObj.inquiry?.application?.attachments,
        goingBackwards: wizardState?.goingBackwards,
        authService,
        dispatch,
        handleClickPrevious,
        handleSubmit,
        register,
        unregister,
        trigger,
        setValue,
        getValues,
        setError,
        watch,
        clearErrors,
        reset,
        control,
        errors,
        isValid,
        isActive,
        isDirty,
        ajaxCallStateForPage,
        backEndValidationErrors,
        triggerSubmit: wizardState.triggerSubmit,
        setTriggerSubmit, // use dispatcher now
    };

    // init/update these as we hand them around for mobile/header view
    useEffect(() => {
        if (appContext.wizardSteps === undefined || (appContext.wizardSteps && appContext.wizardSteps.length === 0)) {
            UpdateWizardStepsAndRepaint(appContext, applicationForm.current.properties.WizardSteps);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*const attachmentsWatched: IAttachmentMetadata[] = watch('inquiryDefObj.inquiry.application.attachments', inquiryDefObj.inquiry.application.attachments);

    useEffect(() => {
        // update attachments under inquiry object
        inquiryDefObj.inquiry.application.attachments = attachmentsWatched;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachmentsWatched]); */

    const EmitPage = (currentFormStep: number, formStepToCheck: number, PageComponent: any) => {
        // we use the formStep and only hide a page so that we can keep the values
        // for all forms in the "register" object for of the react-hook-form

        // if we are navigating back we cannot load the 'forward' page otherwise we will get an invalid page object and then no future saves will work
        if (appContext && appContext.destinationNavActionOnSuccess === NavigationDestination.PreviousWizardStep) {
            return null;
        }

        // do not yet load page if processing resubmit
        if (isResubmit === true && allowEditForResubmitStep === false) {
            return null;
        }

        return (
            <>
                {currentFormStep >= formStepToCheck && (
                    <div className={`${ShowOrHidePage(currentFormStep, formStepToCheck)} ${pageDetails}`}>
                        <PageComponent {...pageProps} isActive={currentFormStep === formStepToCheck} triggerSubmit={wizardState.triggerSubmit} />
                    </div>
                )}
            </>
        );
    };

    /*
    place this under the container-fluid:
        <Profiler
            id='appWizardWrapper'
            // tslint:disable-next-line: jsx-no-lambda
            onRender={(...profileData) => logProfileData(getProfileData(profileData))}
        >
    */

    return (
        <div className='container-fluid'>
            <div className='row pb-5 ml-2'>
                <ModalDialog
                    dataTestId='SaveChangesDialog'
                    modalTitle={VALIDATION_PROMPT_NAVIGATE_AWAY_TITLE}
                    modalBodyText={VALIDATION_PROMPT_NAVIGATE_AWAY_MESSAGE}
                    showMe={wizardState.showSaveChangesDialog}
                    handleClickNo={discardChanges}
                    handleClickYes={saveChanges}
                />
                <ModalDialog
                    dataTestId='DiscardChangesDialog'
                    modalTitle={VALIDATION_PROMPT_RESUBMITDISCARDCHANGES_TITLE}
                    modalBodyText={VALIDATION_PROMPT_RESUBMITDISCARDCHANGES_MESSAGE}
                    showMe={wizardState.showResubmitDiscardChangesDialog}
                    handleClickNo={closeResubmitChangesDialog}
                    handleClickYes={discardResubmitChanges}
                />
                <WizardMenuContainer appContext={appContext} />
                {isResubmit === true && allowEditForResubmitStep === false && <Loader isLoading={true} loaderText='Loading your information...' />}
                {wizardState.formStep === WizardStepNumber.Introduction && (
                    <div className={`${ShowOrHidePage(wizardState.formStep, WizardStepNumber.Introduction)} ${pageDetails}`}>
                        <IntroductionPage {...pageProps} isActive={wizardState.formStep === WizardStepNumber.Introduction} />
                    </div>
                )}
                {EmitPage(wizardState.formStep, WizardStepNumber.PersonalDetailsName, PersonalDetailsNamePage)}
                {EmitPage(wizardState.formStep, WizardStepNumber.PersonalDetailsContact, PersonalDetailsContactPage)}
                {EmitPage(wizardState.formStep, WizardStepNumber.PastApplicationHistory, PastApplicationHistoryPage)}
                {EmitPage(wizardState.formStep, WizardStepNumber.CompetenciesAndQuals, CompetenciesAndQualsPage)}
                {EmitPage(wizardState.formStep, WizardStepNumber.ChildRelatedEmployment, ChildRelatedEmploymentPage)}
                {EmitPage(wizardState.formStep, WizardStepNumber.CriminalConvictions, CriminalConvictionsPage)}
                {EmitPage(wizardState.formStep, WizardStepNumber.ComplaintsMechanismType, ComplaintsMechanismTypePage)}
                {EmitPage(wizardState.formStep, WizardStepNumber.ComplaintsMechanismEmployee, ComplaintsMechanismEmployeePage)}
                {EmitPage(wizardState.formStep, WizardStepNumber.ComplaintsMechanismMember, ComplaintsMechanismMemberPage)}
                {EmitPage(wizardState.formStep, WizardStepNumber.ProfessionalIndemnity, ProfessionalIndemnityPage)}
                {EmitPage(wizardState.formStep, WizardStepNumber.ChangeOfName, ChangeOfNamePage)}
                {EmitPage(wizardState.formStep, WizardStepNumber.DeclarationAndConsent, DeclarationAndConsentPage)}
                {EmitPage(wizardState.formStep, WizardStepNumber.ReviewAndSubmit, ReviewAndSubmitPage)}
            </div>
        </div>
    );
};
// to see the form values put this immediately before the </form> closing tag:
// <pre>{JSON.stringify(watch(), null, 2)}</pre>
// shows the full inquiry object due to watch(), place this after that last EmitPage:
// <CollapseContent id='DevDataOutput' headerText='Dev-Data-Output' content={JSON.stringify(watch(), null, 2)} />

export default RegistrationApplicationWizard;
