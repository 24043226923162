import React from 'react';
import { exclamationIcon } from '../utils/Icons';
import { DisplayTextWithContactPauEmailLink } from '../utils/AppUtils';
import { DISPLAY_FDDR_PAU_CONTACT_EMAIL } from '../services/Constants';
import NavLinkActionCard from '../pages/Dashboard/NavLinkActionCard';
import style from './Controls.module.scss';

const { noteTextBox, noteTextBoxThinPadding, warningSign, noteText } = style;

interface NoteTextProps {
    title?: string;
    displayText: string;
    displayTextP2?: string;
    backgroundColor?: string;
    formatForPDF?: boolean;
    whiteTextOnBlueBackground?: boolean;
    color?: string;
    fontBold?: boolean;
    buttonLink?: string;
    textBoxThinPadding?: boolean;
}

const NoteText = (props: NoteTextProps) => {
    const { title, displayText, displayTextP2, backgroundColor, formatForPDF, whiteTextOnBlueBackground, color, fontBold, buttonLink, textBoxThinPadding } =
        props;

    const noteTitle = title !== undefined && title !== '' ? title : 'Note';
    const wrapperCss = `${noteTextBox} alert border-danger rounded ${textBoxThinPadding === true ? noteTextBoxThinPadding : ''}`;
    const backgroundColorString = backgroundColor && backgroundColor !== '' ? backgroundColor : '';
    const textContainsPauEmail = displayText && displayText.includes(`${DISPLAY_FDDR_PAU_CONTACT_EMAIL}`);
    const textP2ContainsPauEmail = displayTextP2 && displayTextP2.includes(`${DISPLAY_FDDR_PAU_CONTACT_EMAIL}`);
    const colorString = color && color !== '' ? color : '';
    const fontWeightString = fontBold && fontBold === true ? 'bold' : undefined;

    const handleNavClick = () => {
        // history?.push('/managedetailsreviewandsubmitpage');
        // handled click in NavLinkActionCard
    };

    const noteItem = () => {
        return (
            <div className={wrapperCss} style={{ backgroundColor: backgroundColorString, color: colorString, fontWeight: fontWeightString }}>
                {formatForPDF === true ? null : <div className={`${warningSign} alert-danger rounded-circle`}>{exclamationIcon()}</div>}
                <div className={noteText}>
                    {formatForPDF === true ? <b style={{ color: 'red' }}>{noteTitle}:&nbsp;</b> : <h3>{noteTitle}</h3>}
                    {textContainsPauEmail === true ? (
                        <span>{DisplayTextWithContactPauEmailLink(displayText, whiteTextOnBlueBackground)}</span>
                    ) : (
                        <span>{displayText}</span>
                    )}
                    {displayTextP2 &&
                        (textP2ContainsPauEmail === true ? (
                            <span style={{ paddingTop: '1rem' }}>{DisplayTextWithContactPauEmailLink(displayTextP2, whiteTextOnBlueBackground)}</span>
                        ) : (
                            <span style={{ paddingTop: '1rem' }}>{displayTextP2}</span>
                        ))}
                </div>
            </div>
        );
    };

    if (buttonLink) {
        return (
            <NavLinkActionCard hrefLink={buttonLink} dataTestId='noteTextWithLink' handleOnClick={handleNavClick} openInNewTab={false} className='btn'>
                {noteItem()}
            </NavLinkActionCard>
        );
    } else {
        return noteItem();
    }
};

export default NoteText;
