import 'whatwg-fetch';
import ApiService from './commonApi';
import AuthService from '../services/AuthService';
import { IBannerText, IProfessionalAssociation } from './interfacesApi';

class ReferenceApi {
    apiService: ApiService;

    constructor(authenticationService: AuthService) {
        this.apiService = new ApiService(authenticationService);
    }

    // API call - getAllProfessionalAssociations
    async getAllProfessionalAssociations() {
        const request = this.apiService.setRequest('api/reference/professionalassociations', 'GET', null);
        return this.apiService.makeFetchRequest<IProfessionalAssociation[]>(request);
    }

    // API call - getAllBannerTexts
    async getAllBannerTexts() {
        const request = this.apiService.setRequest('api/reference/bannertexts', 'GET', null);
        return this.apiService.makeFetchRequest<IBannerText[]>(request);
    }
}

export default ReferenceApi;
