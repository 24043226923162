import React from 'react';
import { DISPLAY_FDDR_PAU_CONTACT_EMAIL, MAILTO_FDDR_PAU_CONTACT_EMAIL } from '../../services/Constants';
import NavLink from '../../controls/NavLink';
import styles from './Fields.module.scss';

const { linkWhiteOnBlueBackground } = styles;

interface NavLinkEmailPauProps {
    whiteTextOnBlueBackground?: boolean;
}

const NavLinkEmailPau = (props: NavLinkEmailPauProps) => {
    const { whiteTextOnBlueBackground } = props;

    return (
        <NavLink
            className={whiteTextOnBlueBackground ? linkWhiteOnBlueBackground : ''}
            displayText={DISPLAY_FDDR_PAU_CONTACT_EMAIL}
            hrefLink={MAILTO_FDDR_PAU_CONTACT_EMAIL}
        />
    );
};

export default NavLinkEmailPau;
